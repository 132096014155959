import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UsergroupspermissionsService } from 'src/app/_services/usergroups/usergroupspermissions.service';

@Component({
  selector: 'app-leavegroupdialog',
  templateUrl: './leavegroupdialog.component.html',
  styleUrls: ['./leavegroupdialog.component.css']
})
export class LeavegroupdialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public services: UsergroupspermissionsService, private toastr: ToastrService ) { }

  ngOnInit(): void {
  }

  deleteAccount(){
    this.services.leaveGroup().subscribe(response => {
      if (response === true)
      {
        this.toastr.success("You have sucessfully been removed from group!");
        window.location.reload();
      }
      else
      {
        this.toastr.error("Failed to remove you from group!");
      }
    }, err => {
      console.log(err);
    })
  }

}
