<app-nav></app-nav>
<div class="container mt-5">
    <div>
        <div class="row justify-content-center">
            <div class="col-sm-12 col-md-4  py-5 bg-white text-black text-center border shadow-lg rounded">
                <div class=" ">
                    <div class="card-body">
                        <img src="/assets/images/confirmation.jpg" class="d-block w-100 h-50">
                        <h2 class="py-3">{{leftMessage}} </h2>        
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-8 mt-2">
                <div class="card border-primary mb-3">
                    <div class="card-body">
                      <h1>{{message}}</h1>
                      <ng-container *ngIf="showConfirm && !showEmailErrAlreadyjoinedGroup">
                        <div class="bs-component">
                            <div class="alert alert-dismissible alert-success">
                              <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                              <strong>Well done!</strong> You have successfully joined a user group account. <u>Please click the link below to continue!</u>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!showConfirm && !showEmailErrAlreadyjoinedGroup">
                        <div class="bs-component">
                            <div class="alert alert-dismissible alert-danger">
                              <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                              <p><strong>Failed to update!</strong> You have failed to confirm to join user group account. 
                              You can always try to copy the link from the <b>address bar</b>, and then try to login to your other account. Paste the link inside the <b>address bar</b>. 
                              </p>
                              <p>It could also be the fact that the account doesn't exist in our database. Please, double check to make sure you have the right email account is sent out correctly. To do this, you can always 
                              recreate the user account from your main account from inside your "user group" window, and then log out from this App. Log onto your other account on this App, and then log onto the email system from your other email account, and then click on the last link that was sent out to you, and then
                              click on "Click here to join group". <u>Please click the link below to continue!</u>   
                              </p>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="showConfirm && showEmailErrAlreadyjoinedGroup">
                        <div class="bs-component">
                            <div class="alert alert-dismissible alert-danger">
                              <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                              <strong>Failed to update!</strong> You need to disband your current group before you can join this group. 
                            </div>
                        </div>
                    </ng-container>
                    <button class="btn btn-primary" routerLink="/home">Click Here next!</button>                 
                    </div>
                </div>              
            </div>
        </div>
    </div>
</div>