<nav class="navbar navbar-light bg-primary shadow-lg">
  <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-money fa-2x" aria-hidden="true"></i> Edit Store Account</a>  
</nav>
<mat-dialog-content><form #editForm="ngForm" id="editForm" [formGroup]="formAccount" (ngSubmit)="formAccount.valid" autocomplete="off">
<div class="bs-component">
    <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
    <button type="button" class="close" data-dismiss="alert">x</button>
    <h4 class="alert-heading">Information!</h4>
    <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
    </div>
</div>
<div class="row mt-2">
    <div class="col-12">
        <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-calendar"></i> Payment Details:</h4>
        <app-text-input [formControl]='formAccount.controls["paymentAmount"]' [label]='"*Payment Amount"' [smalltext]='""' [placeholder]='"Payment Amount"'> </app-text-input>  
        <app-date-input [formControl]='formAccount.controls["datePaid"]' [label]='"*Date Paid"' [smalltext]='""' [placeholder]='"Date Paid"'> </app-date-input>
        <app-area-text [formControl]='formAccount.controls["note"]' [label]='"Note"' [smalltext]='""' [placeholder]='"note"'> </app-area-text>  
        <fieldset class="form-group">
          <div class="card border-secondary mb-3">
            <div class="card-body">
              <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-money "></i> Type of Payment:</h4>
              <div class="form-check">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" name="RdoTypePayment" [formControl]='formAccount.controls["typePayment"]' value="StoreCredit">
                  Credit
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" name="RdoTypePayment" [formControl]='formAccount.controls["typePayment"]' value="StoreDebit">
                  Debit
                </label>
              </div>
            </div>
          </div>
        </fieldset>       
    </div>
</div>
</form>

<div class="row">
<div class="col-6">
    <button [disabled]="!formAccount.valid" mat-button [mat-dialog-close]="data" class="btn btn-success btn-block btn-lg" form="editForm" (click)="onSubmit()"><i class='fas fa-check'></i>  Confirm Edit Account</button>
</div>
<div class="col-6">
    <button class="btn btn-danger btn-block btn-lg" mat-button mat-dialog-close><i class='fas fa-close'></i>  Close</button>
</div>
</div>      
</mat-dialog-content>       