<app-nav></app-nav>
<div class="container mt-5">
    <div>
        <div class="row justify-content-center">
            <div class="col-sm-12 col-md-4  py-5 bg-white text-black text-center border shadow-lg rounded">
                <div class=" ">
                    <div class="card-body">
                        <img src="/assets/images/edit.jpg" class="img-thumbnail">
                        <h2 class="py-3">{{leftMessage}} </h2>
                        <p *ngIf="defaultConfirm">
                            You made it this far! Log out and log back in. 
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-8 mt-2">
                <div class="card border-primary mb-3">
                    <div class="card-body">
                        <h1>{{message}}</h1>
                        <form [formGroup]="sendEmailForm" (ngSubmit)="sendEmailForm.valid" autocomplete="off" *ngIf="defaultConfirm && !showConfirm">                          
                                <div class="bs-component" *ngIf="showSendEmail">
                                    <div class="alert alert-dismissible alert-success">
                                      <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                                      <strong>Email Sent!</strong>  Please check your spam folder.
                                    </div>
                                </div>
                                <app-text-input [formControl]='sendEmailForm.controls["password"]' 
                                    [label]='"*Password"' 
                                    [smalltext]='"Please enter your new password here!"' 
                                    [placeholder]='"Password"'
                                    [type]='"password"'> 
                                </app-text-input>         
                                <app-text-input [formControl]='sendEmailForm.controls["retypepassword"]' 
                                [label]='"*Retype Password"' 
                                [smalltext]='"Please retype your new password here!"' 
                                [placeholder]='"Retype your new Password"'
                                [type]='"password"'> 
                            </app-text-input> 
                                <div class="card-footer text-muted">
                                    <button [disabled]="!sendEmailForm.valid" class="btn btn-success btn-block" form="editForm" (click)="sendAccountNewPassword()" >Reset Password to My Account </button>
                                </div>                                  
                       </form>
                        <div class="bs-component" *ngIf="!defaultConfirm && !showConfirm">
                            <div class="alert alert-dismissible alert-danger">
                            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                            <strong>Update Account Failed!</strong> Your token was invalid. Please try again.  
                            </div>
                        </div>
                        <div class="bs-component" *ngIf="!defaultConfirm && showConfirm">
                            <div class="alert alert-dismissible alert-success">
                            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                            <strong>Update Account Succeeded!</strong> Your account has been updated.
                            </div>
                        </div>    
                        <div *ngIf="!defaultConfirm && !showConfirm">
                            <h4>Click on the link below and try again!</h4>
                            <button class="btn btn-primary btn-block" routerLink="/receivelostemail">I like to reset my password account!</button>
                        </div>
                        <div *ngIf="!defaultConfirm && showConfirm">
                            <h4>Now you should be able to login into your account again. Try the nagivation above on the right and type in your login details.</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>