
<div class="col-12" *ngIf="services.groupDataSource">
    <!-- Just an narbar title -->
    <nav class="navbar mt-4 navbar-light bg-primary shadow-lg">
    <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Create a Member</a>
    <button type="button" class="btn btn-outline-light btn-lg mt-2 mt-md-0" (click)="toggle.toggleWindow(5)"><i class="{{toggle.$Icon[5]}}" aria-hidden="true\"></i> {{toggle.$ToggleText[5]}}</button>
    </nav>
    <div class="col-12" [hidden]="!toggle.$Toggle[5]">
        <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded">
            <div class="card-body"> 
            <h2 class="text-center text-primary mt-2">CREATE USER GROUP</h2>
            <hr>
            <form #editForm="ngForm" id="editForm" [formGroup]="userMember" (ngSubmit)="userMember.valid" autocomplete="off">     
                <div class="row">                     
                <div class="bs-component">
                    <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
                        <button type="button" class="close" data-dismiss="alert">×</button>
                        <h4 class="alert-heading">Information!</h4>
                        <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
                    </div>             
                </div>
                <div class="col-12">
                    <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i>  Personal Details:</h4>
                    <app-text-input [formControl]='userMember.controls["firstname"]' [label]='"*First Name"' [smalltext]='""' [placeholder]='"First Name"'> </app-text-input>  
                    <app-text-input [formControl]='userMember.controls["lastname"]' [label]='"*Last Name"' [smalltext]='""' [placeholder]='"Last Name"'> </app-text-input>                      
                    <span class="badge bg-warning"><strong>You should create a profile account before submitting this form!</strong></span>
                    <app-text-input [formControl]='userMember.controls["email"]' [label]='"*Email"' [smalltext]='""' [placeholder]='"Email"'> </app-text-input>      
                    <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i>  Choose Group:</h4>  
                    <div class="form-group">
                        <label for="groupnames" class="form-label">*Group Name</label>
                        <select class="form-select" id="groupnames" [formControl]='userMember.controls["groupid"]'>    
                            <option *ngFor="let group of services.groupDataSource.filteredData" [value]="group.id">
                                {{group.groupName}}
                            </option>                       
                        </select>
                    </div>   
                </div>                
                </div>
            </form>
            </div>
            <div class="card-footer text-muted">
                <button [disabled]="!userMember.valid" class="btn btn-success btn-block" form="editForm" (click)="createMember()" ><i class="fas fa-user"></i> CREATE USER IN GROUP</button>
            </div>
        </div>
    </div>
</div>