import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorialdeleteinvoice',
  templateUrl: './tutorialdeleteinvoice.component.html',
  styleUrls: ['./tutorialdeleteinvoice.component.css']
})
export class TutorialdeleteinvoiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
