<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to delete an account payment</h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/Delete Account Client Payments/dacp01.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Go to the clients “Account Payments” page  </h2>
    <h2>•	Scroll down to the “Payment List” section  </h2>
    <h2>•	Click the three (3) dots beside the Payment that you wish to delete  </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/Delete Account Client Payments/dacp02.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Under the menu, click “Delete Payment Account”</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/Delete Account Client Payments/dacp03.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Click “Delete” to confirm the “Payment Account” deletion</h2>
    <h2>•	If you wish not to delete the “Payment Account” click “Cancel”</h2>
</div>