import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Client } from 'src/app/_models/client';
import { ClientService } from 'src/app/_services/client/client.service';

@Component({
  selector: 'app-clientinformation',
  templateUrl: './clientinformation.component.html',
  styleUrls: ['./clientinformation.component.css']
})
export class ClientinformationComponent implements OnInit {
  @Input() isitinvoice: string;
  clientName: string;
  clientID: any;

  constructor(private clientService: ClientService, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.getParrams();
    this.onLoad();    
  }

  onLoad(){
    this.clientService.getClient(this.clientID).subscribe(response => {
      this.clientName = response.name;
    })
  }

   getParrams(){
    this.route.paramMap.subscribe(params => {
      this.clientID = params.get("id");    
    });
  }

}
