import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmimportsinvoices',
  templateUrl: './confirmimportsinvoices.component.html',
  styleUrls: ['./confirmimportsinvoices.component.css']
})
export class ConfirmimportsinvoicesComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  confirm(){
    this.data.confirm = true;
  }

}
