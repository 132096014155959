import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Client } from 'src/app/_models/client';
import { PaginatedResult } from 'src/app/_models/pagination';
import { User } from 'src/app/_models/user';
import { environment } from 'src/environments/environment';
import { HostpresenceService } from '../hubsignalr/hostpresence.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  baseUrl =  environment.apiUrl;
  clients: Client[] = [];
  client: Client;
  editClient: Client;
  private hubConnection?: HubConnection;
  huburl = environment.hubUrl;
  public clientsDataSource: MatTableDataSource<Client> = new MatTableDataSource<Client>();
  private clientSource = new BehaviorSubject<Client[]>([]);
  clients$ = this.clientSource.asObservable();
  showNonArchive$: boolean = false;

  constructor(private http: HttpClient, private presenceHub: HostpresenceService, private toastr: ToastrService ) { }

  createClient(model: Client){
    return this.http.post(this.baseUrl + "clients/createclient", model).pipe(
      map((response: Client) => {
        return response;             
      })); 
  }  

  getClients(page?: number, itemPerPage?: number){
    return this.http.get<Client[]>(this.baseUrl + 'clients/getclients').pipe(
      map(response => {
        this.clientSource.next(response);
        this.SortDataSourceTable(response);
        return response;
      })
    )
  }

  getClient(accountID: number){
    return this.http.get<Client>(this.baseUrl + 'clientinformation/getinformationclient?accountID=' + accountID).pipe(
      map(client => {
        this.client = client;
        return client;
      })
    );
  }

  getEditClient(accountID: number){
    return this.http.get<Client>(this.baseUrl + 'clients/geteditclient?accountID=' + accountID).pipe(
      map(client => {
        this.editClient = client;
        return client;
      })
    )
  }

  updateClient(client: Client){
    return this.http.put(this.baseUrl + 'clients/updateclient', client).pipe(
      map((update: boolean) => {
        return update;
      })
    );
  }

  deleteClient(accountID: number){
    return this.http.delete(this.baseUrl + 'clients/deleteclient?accountID=' + accountID).pipe(
      map((result: boolean) => {
        return result;
      })
    );
  }

  archiveClient(account: any){
    return this.http.put(this.baseUrl + 'clients/updatearchiveclient', account).pipe(
      map((result: boolean) => {
        return result;
      })
    );
  }

  getClientsAdmin(){
    return this.http.get<Client>(this.baseUrl + 'adminclients/getadminclients').pipe( 
      map((user: Client) => {
        return user;
      })
    );
  }

  getCheckCanEditClient(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'clients/Iseditactive?accountID=' + accountID).pipe(
      map((client => {
        return client;
      }))
    )
  }

  getCheckCanDeleteClient(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'clients/Iseditactive?accountID=' + accountID + '&isItDeleteFunction=true').pipe(
      map((client => {
        return client;
      }))
    )
  }

  getCheckCanArchiveClient(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'clients/Iseditactive?accountID=' + accountID + '&isItArchiveFunction=true').pipe(
      map((client => {
        return client;
      }))
    )
  }

  getCheckOpenInvoiceAccount(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'clients/canyouopeninvoiceaccounts?accountID=' + accountID).pipe(
      map((canOpen: boolean) => {
        return canOpen;
      })
    )
  }

  getCheckOpenStoreAccount(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'clients/canyouopenstoreaccounts?accountID=' + accountID).pipe(
      map((canOpen: boolean) => {
        return canOpen;
      })
    )
  }

  getCheckAccountPaymentsForSingleClientAccount(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'clients/canyouopenpaymentaccountsclientaccount?accountID=' + accountID).pipe(
      map((canOpen: boolean) => {
        return canOpen;
      })
    )
  }

  cancelCanEditClient(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'clients/closeeditactive?accountID=' + accountID).pipe(
      map((client => {
        return client;
      }))
    )
  }

  async createHubConnection(user: User) 
  {  
    if (this.presenceHub.pageActive[0] === false)
      return;

     this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.huburl + 'client', {
        accessTokenFactory: () => user.token
    })
    .withAutomaticReconnect()
    .build();

    this.hubConnection.start().catch(err => {
      console.log(err);
      this.toastr.error("Server is Unreachable!");
    });

    this.hubConnection.on('ReceiveClients', (clients: Client[]) => {
      console.log(clients);
      if (clients !== null)
      {  
        if (this.showNonArchive$ === false)
        {
          return;
        }

        this.CheckValidationOnConnectionClients(clients);
      }     
    })    
  }

  async createArchiveHubConnection(user: User) 
  {  
    if (this.presenceHub.pageActive[0] === false)
      return;

     this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.huburl + 'clientarchive', {
        accessTokenFactory: () => user.token
    })
    .withAutomaticReconnect()
    .build();

    this.hubConnection.start().catch(err => {
      console.log(err);
      this.toastr.error("Server is Unreachable!");
    });

    this.hubConnection.on('ReceiveArchiveClients', (clients: Client[]) => {
      if (clients !== null)
      {  
        if (this.showNonArchive$ === true)
        {
          return;
        }

        this.CheckValidationOnConnectionClients(clients);
      }     
    })    
  }


  stopHubConnection(){
    if (this.hubConnection) {
      this.hubConnection?.stop();
    }
  }

  stopArchiveHubConnection(){
    if (this.hubConnection) {
      this.hubConnection?.stop();
    }
  }

  private async CheckValidationOnConnectionClients(clients: Client[])
  {  
    console.log(clients);
    var clientLength: number = 0;
    const dataSourceLength: number = this.clientsDataSource.data.length;
    clients.forEach(client => {
      clientLength = clientLength + 1;
    });

    if (dataSourceLength === 0)
    {
      this.clientDataSourcenext(clients);
    }
    else if (clientLength === 0)
    {
      this.clientDataSourcenext(clients); 
    }
    else if (clientLength > dataSourceLength)
    {  
      this.clientDataSourcenext(clients); 
      //var client = clients[clients.length - 1];      
      //this.clientsDataSource.data.push(client);
    }
    else if (clientLength < dataSourceLength)
    {
      var i: number = 0;
      var isItlastNumber: boolean = true;
      clients.forEach(client => {              
        if (client.name != this.clientsDataSource.data[i].name)
        {
          this.clientsDataSource.data.splice(i, 1);
          isItlastNumber = false;          
        }
        i = i + 1;
      });
      
      if (isItlastNumber)
      {
        this.clientsDataSource.data.splice(this.clientsDataSource.data.length - 1, 1);
      }
    }
    else if (dataSourceLength === clientLength)
    {
      var i: number = 0;
      clients.forEach(client => {
        if (client.SendInitialMessage !== this.clientsDataSource.data[i].SendInitialMessage)
        {
          this.clientsDataSource.data[i].SendInitialMessage = client.SendInitialMessage;
        }
        else if (client.name !== this.clientsDataSource.data[i].name)
        {
          this.clientsDataSource.data[i].name = client.name;
        }
        else if (client.abn !== this.clientsDataSource.data[i].abn)
        {
          this.clientsDataSource.data[i].abn = client.abn;
        }
        else if (client.address !== this.clientsDataSource.data[i].address)
        {
          this.clientsDataSource.data[i].address = client.address;
        }
        else if (client.addressUnit !== this.clientsDataSource.data[i].addressUnit)
        {
          this.clientsDataSource.data[i].addressUnit = client.addressUnit;
        }
        else if (client.bussinessName !== this.clientsDataSource.data[i].bussinessName)
        {
          this.clientsDataSource.data[i].bussinessName = client.bussinessName;
        }
        else if (client.contactBusiness !== this.clientsDataSource.data[i].contactBusiness)
        {
          this.clientsDataSource.data[i].contactBusiness = client.contactBusiness;
        }
        else if (client.contactHomePhone !== this.clientsDataSource.data[i].contactHomePhone)
        {
          this.clientsDataSource.data[i].contactHomePhone = client.contactHomePhone;
        }
        else if (client.contactMobile !== this.clientsDataSource.data[i].contactMobile)
        {
          this.clientsDataSource.data[i].contactMobile = client.contactMobile;
        }
        else if (client.creditRatingApproved !== this.clientsDataSource.data[i].creditRatingApproved)
        {
          this.clientsDataSource.data[i].creditRatingApproved = client.creditRatingApproved;
        }
        else if (client.email !== this.clientsDataSource.data[i].email)
        {
          this.clientsDataSource.data[i].email = client.email;
        }
        else if (client.firstName !== this.clientsDataSource.data[i].firstName)
        {
          this.clientsDataSource.data[i].firstName = client.firstName;
        }
        else if (client.lastName !== this.clientsDataSource.data[i].lastName)
        {
          this.clientsDataSource.data[i].lastName = client.lastName;
        }
        else if (client.postCode !== this.clientsDataSource.data[i].postCode)
        {
          this.clientsDataSource.data[i].postCode = client.postCode;
        }
        else if (client.province !== this.clientsDataSource.data[i].province)
        {
          this.clientsDataSource.data[i].province = client.province;
        }
        else if (client.state !== this.clientsDataSource.data[i].state)
        {
          this.clientsDataSource.data[i].state = client.state;
        }
        else if (client.country !== this.clientsDataSource.data[i].country)
        {
          this.clientsDataSource.data[i].country = client.country;
        }
        else if (client.archive !== this.clientsDataSource.data[i].archive)
        {
          this.clientsDataSource.data[i].archive = client.archive;
        }
        i = i + 1;
      });
    }
  }

  private SortDataSourceTable(response: any){
    this.clients = response;
    this.clientsDataSource = new MatTableDataSource<Client>(
       this.clients
    );
  } 

  private async checkDataSourceClient(clients: Client[])
  {
    var index = 0;
    clients.forEach(client => {
      if (client.firstName !== this.clientsDataSource.data[index].firstName)
      {
        return true;
      }       
      index = index + 1;
    });
    return false;
  }

  private clientDataSourcenext(clients: Client[])
  {
    this.clientSource.next(clients);
    this.clientsDataSource.data = clients;
  }
   
}
