import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgControlStatusGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { EmailRemoveGMT } from 'src/app/_helper/emailremovegmt';
import { accountpayment } from 'src/app/_models/accountpayment';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { ClientaccountpaymentsService } from 'src/app/_services/clientaccountpayments/clientaccountpayments.service';
import { InvoiceService } from 'src/app/_services/invoice/invoice.service';
import { PaymentService } from 'src/app/_services/payment/payment.service';
import { ReceivableaccountspaymentsService } from 'src/app/_services/receivablesaccountspayments/receivableaccountspayments.service';
import { TogglewindowService } from 'src/app/_services/togglewindow.service';
import { InvoiceValidator } from 'src/app/_validators/invoicevalidator';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator';


@Component({
  selector: 'app-accountclientreceivable',
  templateUrl: './accountclientreceivable.component.html',
  styleUrls: ['./accountclientreceivable.component.css']
})
export class AccountclientreceivableComponent implements OnInit, OnDestroy {
  @ViewChild('editForm') editForm: NgForm;  
  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any){
    if (this.editForm.dirty){
      $event.returnValue = true;
    }
  }  

  public invoiceID: string;
  accountAccount: accountpayment;  
  public hidePublicType: boolean = false;
  formAccounts: UntypedFormGroup;
  showErrorMessage: boolean; // Show error when trying to when to hack into the system.
  clientInfo: any;
  clientID: number;
  user?: User;

  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private paymentService: PaymentService,
    private toastr: ToastrService, public toggle: TogglewindowService, public accountService: AccountService,
     private receivableaccountspaymentsService: ReceivableaccountspaymentsService,
     private clientAccountPaymentService: ClientaccountpaymentsService, private invoiceService: InvoiceService) { }

  ngOnDestroy(): void {
    this.setDefaultValuesInvoice();
    this.ClosedEditInvoice();
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.hidePublicType = false;
    this.setDefaultValuesInvoice();
    this.getParrams();   
    this.assignUser();
    this.initializeForm();  
    this.getClientInfo();    
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  setDefaultValuesInvoice(){
    this.paymentService.defaultInvoicePayment$ = 0;
    this.paymentService.defaultInvoiceCost = 0;
  }

  initializeForm(){
    this.formAccounts = this.fb.group({
      accountmoney:['', [Validators.required, Validators.min(0.01), Validators.max(999999999999999), InvoiceValidator.matchInvoiceFromInvoiceToAmountPaid(this.paymentService.defaultInvoiceCost)]],
      date: ['', Validators.required],
      option: ['', Validators.required],
      description:['', [WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter, Validators.maxLength(1000)]],
      paymenttype:['Cash', Validators.required],
    })
  }

  createAccount(){    
    this.createAccountModel();
    this.paymentService.createPayment(this.accountAccount).subscribe(response => {
      this.toastr.success("Created Payment Account");
      this.resetform();

      // get payment services //
      this.paymentService.stopHubConnection();
      this.paymentService.createHubConnection(this.user, +this.invoiceID);

      // get all payments //
      this.receivableaccountspaymentsService.stopHubConnection();
      this.receivableaccountspaymentsService.createHubConnection(this.user, true);

      // get single all payments //
      this.clientAccountPaymentService.stopHubConnection();
      this.clientAccountPaymentService.createHubConnection(this.user, this.clientID, true);
    }, error => {
      console.log(error);
    });
  }

  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.invoiceID = params.get("id");
      this.paymentService.defaultInvoiceCost = +params.get("invoiceamount");
    });
  }

  createAccountModel(){
    const date = new Date(this.formAccounts.value.date);
    this.accountAccount = {
      amount: this.formAccounts.value.accountmoney,
      date: date,
      note: this.formAccounts.value.description,
      paymentOption: this.formAccounts.value.option,
      invoiceID: Number(this.invoiceID),
      paymentType: this.formAccounts.value.paymenttype,
      dateS: date.toUTCString()
    }
  }

  getClientInfo(){
    const accountID = parseInt(this.invoiceID);
    this.paymentService.getClientInformation(accountID).subscribe(response => {
      if (response)
      {
        this.clientInfo = response;      
        this.clientID = this.clientInfo.clientID;
        this.paymentService.defaultInvoicePayment$ = this.clientInfo.amountPaid;
        return;
      } 
      this.clientID = 0;
      this.paymentService.defaultInvoicePayment$ = 0;
    }, error => {
      console.log(error);
    })
  }

  
  resetform(){
    this.initializeForm();
    this.paymentService.getPayments(this.invoiceID).subscribe(
      response => {
        this.paymentService.accounts = response;
        console.log(response);
      }, error => {
        console.log(error);
      }
    )
  }

  ClosedEditInvoice(): void {
    this.invoiceService.cancelCanEditInvoice(+this.invoiceID).subscribe(response => {

    }, err => {
      console.log(err);
    });    
  }

  ShowPublicType(value: boolean){
    this.hidePublicType = value;
  }
}
