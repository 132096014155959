import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { InvoiceService } from 'src/app/_services/invoice/invoice.service';
import { PayablesreceivablesService } from 'src/app/_services/payablesreceivablesaccounts/payablesreceivables.service';
import { InvoicelistComponent } from '../invoicelist/invoicelist.component';

@Component({
  selector: 'app-deleteinvoice',
  templateUrl: './deleteinvoice.component.html',
  styleUrls: ['./deleteinvoice.component.css']
})
export class DeleteinvoiceComponent implements OnInit {

  accountID: number;
  clientID: number;
  user?: User;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<InvoicelistComponent>,
    private invoiceService: InvoiceService, private accountService: AccountService, private toastr: ToastrService,
    private payablesreceivables: PayablesreceivablesService) { }

  ngOnInit(): void {    
    this.accountID = this.data.id;    
    this.clientID = this.data.clientID;
    this.assignUser();
  }

  deleteAccount(){
    this.invoiceService.deleteInvoice(this.accountID).subscribe( response => {
      if (response)
      {
        this.data.deleteClient = true;
        this.invoiceService.stopHubConnection();
        this.invoiceService.createHubConnection(this.user, this.clientID);
        this.toastr.success("Successfully updated account!");
        this.payablesreceivables.stopHubConnection();
        this.payablesreceivables.createHubConnection(this.user, true);
        location.reload();
      }
      else
      {
        this.toastr.error("You can't delete this account. You can however archive this account by going into 'edit' options");
      }
  
    }, error => {
      this.invoiceService.stopHubConnection();
      console.log(error);
    })
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

}
