<app-nav></app-nav>
<section class="pt-4 mt-4">
    <div class="container px-lg-5">
        <!-- Page Features-->
        <div class="row gx-lg-5">
            <div class="col-lg-2 col-md-2 col-sm-12 col-xxl-12 mb-5">
            
            </div>  
            <div class="col-lg-8 col-md-8 col-sm-12 col-xxl-12 mb-5">
                <div class="card bg-white h-100 shadow-lg">
                    <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                        <img src="/assets/images/7176685.jpg" alt="SMS" class="img-thumbnail">
                        <h2 class="fs-4 fw-bold">What is Forward Pay?</h2>
                        <p class="mb-0">
                            Introducing Forward Pay, a feature that allows you to easily pay off your bills now without worrying about paying them later. With Forward Pay, you can enjoy the convenience of managing your finances and paying off your bills or invoices immediately. Our flexible payment options allow you to take control of your finances and pay off your bills or invoices on your terms. Try Forward Pay today and experience the freedom of paying without worry
                        </p>
                    </div>
                </div>
            </div>           
            <div class="col-lg-2 col-md-2 col-sm-12 col-xxl-12 mb-5">
               
            </div>   
        </div>
    </div>
</section>