import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { StoreAccountModel } from 'src/app/_models/storeaccountmodel';
import { User } from 'src/app/_models/user';
import { UserStoreInfoAccount } from 'src/app/_models/userstoreinfoacccount';
import { AccountService } from 'src/app/_services/account/account.service';
import { HostpresenceService } from 'src/app/_services/hubsignalr/hostpresence.service';
import { StoreaccountService } from 'src/app/_services/storeaccount/storeaccount.service';
import { DeletestorecreditComponent } from '../deletestorecredit/deletestorecredit.component';
import { EditstorecreditComponent } from '../editstorecredit/editstorecredit.component';
import { ClientService } from 'src/app/_services/client/client.service';
import { stat } from 'fs';
import { ArchivestorecreditComponent } from '../archivestorecredit/archivestorecredit.component';

@Component({
  selector: 'app-liststorecredit',
  templateUrl: './liststorecredit.component.html',
  styleUrls: ['./liststorecredit.component.css']
})
export class ListstorecreditComponent implements OnInit, OnDestroy {

  clientID: string;
  user?: User;
  timeLeft: number = 1;
  interval;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns = ['Index', 'TypePayment', 'PaymentAmount','InvoiceNumber', 'DatePaid', 'Note', 'Actions'];
  constructor(private route: ActivatedRoute, public services: StoreaccountService, private dialog: MatDialog, private toastr: ToastrService
    , private accountService: AccountService, private presenceHub: HostpresenceService, private clientService: ClientService, private router: Router) { }

  ngOnInit(): void {
    this.presenceHub.pageActive[1] = true;
    this.toastr.info("Please wait! We are getting your records now!");
    this.getParrams();
    this.assignUser();
    this.loadMembers();
    this.startTimer();
  }

  ngOnDestroy(): void {
    this.presenceHub.pageActive[1] = false;
    this.RemovedIDUser();
    clearInterval(this.interval);
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  editAccount(row: number){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "75vw";
    dialogConfig.height = "auto";
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      id: this.services.dataSource.filteredData[row].id,
      typePayment: this.services.dataSource.filteredData[row].typePayment,
      paymentAmount: this.services.dataSource.filteredData[row].paymentAmount,
      datePaid: this.services.dataSource.filteredData[row].datePaid,
      note: this.services.dataSource.filteredData[row].note,
      AccountRefID: this.services.dataSource.filteredData[row].AccountRefID,
      row: row
    };
    const id = this.services.dataSource.filteredData[row].id;
    this.services.getCheckCanEditAccount(id).subscribe(response => {
      if (!response)
      {
        const dialogRef = this.dialog.open(EditstorecreditComponent, dialogConfig);
        this.toastr.info("Active Account: Opened Store Account");
        dialogRef.afterClosed().subscribe(result => {
          this.services.createHubConnection(this.user, +this.clientID, false);  
          this.services.cancelCanEditClient(id).subscribe(response => {
            if (response)
            {
               this.toastr.info("Active Account Closed");
            } 
           }, err => {
             console.log(err);
           })      
        });
      }
      else
      {
        this.toastr.error("Failed! Another account is in use of editing this account.");
      }
    }, err => {
      console.log(err);
    })    
  }

  deleteAccount(id: number){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "75vw";
    dialogConfig.data = {
      id: id,
      deleteClient: false
    };

    this.services.getCheckCanDeleteAccount(id).subscribe(response => {
      if (!response)
      {
        const dialogRef = this.dialog.open(DeletestorecreditComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          if (result.deleteClient == true){
            this.services.stopHubConnection();
            this.services.createHubConnection(this.user, +this.clientID, false);
          }
          else
          {
            this.services.cancelCanEditClient(id).subscribe(response => {
              if (response)
              {
                this.toastr.info("Active Account Closed");
              }
            }, err => { console.log(err)});
          }
        });
      }
      else
      {
        this.toastr.error("Failed! Another account is in use of editing this account.");
      }
    }, err => {console.log(err)});
  }

  archiveAccount(id: number, archive: boolean){
    console.log(this.clientID);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "75vw";
    dialogConfig.data = {
      id: id,
      archive: archive,
      clientID: this.clientID
    };


    this.services.getCheckCanArchiveAccount(id).subscribe(response => {
      if (!response)
      {
        const dialogRef = this.dialog.open(ArchivestorecreditComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          this.services.cancelCanEditClient(id).subscribe(response => {
            if (response)
            {
              this.toastr.info("Active Account Closed");
            }
          }, err => { console.log(err)});
        });
      }
      else
      {
        this.toastr.error("Failed! Another account is in use of editing this account.");
      }
    }, err => {console.log(err)});
  }

  loadMembers(){
    this.services.createHubConnection(this.user, +this.clientID, false);
  }

  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.clientID = params.get("id");
    });
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.services.dataSource.paginator = this.paginator;
        this.services.dataSource._updateChangeSubscription();
        this.timeLeft = 10;
        this.presenceHub.pageActive[1] = true;
      }
    },1000)
  }

  openAccountPayment(accountID: number){
    this.services.openPaymentAccount(accountID).subscribe(response => {
      if (response === 0)
      {
        this.toastr.error("No Accounts found!");
        return;
      }
      
      this.router.navigate(['/members/accountclientreceivable', accountID, response]);
    })
  }

  private RemovedIDUser(): void{
    this.clientService.cancelCanEditClient(+this.clientID).subscribe(response => {
      if (response)
      {
         this.toastr.info("Active Account Closed!");
      } 
     }, err => {
       console.log(err);
     })
  }

  onChangeArchive(status){
    this.services.stopHubConnection();
    this.services.stopArchiveHubConnection();
    if (status.target.value === "1")
    {
      this.services.showArchive = false;
      this.services.createHubConnection(this.user, +this.clientID, true);      
    }
    else
    {
      this.services.showArchive = true;
      this.services.createArchiveHubConnection(this.user, +this.clientID, true);    
    }
  }
}
