import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { accountpayment } from 'src/app/_models/accountpayment';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { HostpresenceService } from 'src/app/_services/hubsignalr/hostpresence.service';
import { PaymentService } from 'src/app/_services/payment/payment.service';
import { threadId } from 'worker_threads';
import { ArchiveaccountclientreceivableComponent } from '../archiveaccountclientreceivable/archiveaccountclientreceivable.component';
import { DeleteaccountreceivableComponent } from '../deleteaccountreceivable/deleteaccountreceivable.component';
import { EditpaymentaccountreceivableComponent } from '../editpaymentaccountreceivable/editpaymentaccountreceivable.component';

@Component({
  selector: 'app-clientlistaccountreceivable',
  templateUrl: './clientlistaccountreceivable.component.html',
  styleUrls: ['./clientlistaccountreceivable.component.css']
})
export class ClientlistaccountreceivableComponent implements OnInit, OnDestroy {
  public invoiceID: string;
  user?: User;
  timeLeft: number = 1;
  interval;
  totalCost: number = 0;

  displayedColumns = ['Index', 'PaymentMethod', 'Amount', 'Date', 'PaymentType', 'Note', 'Actions'];

  constructor(private route: ActivatedRoute, public paymentService: PaymentService, private dialog: MatDialog,
    private toastr: ToastrService, private accountService: AccountService, private presenceHub: HostpresenceService) { }

  ngOnDestroy(): void {
    this.presenceHub.pageActive[3] = false;
    this.paymentService.stopHubConnection();
    this.paymentService.stopHubArchiveConnection();
    this.paymentService.showarchive = false;
  }

  ngOnInit(): void {
    this.paymentService.totalPayments$ = 0;
    this.presenceHub.pageActive[3] = true;
    this.toastr.info("Please wait! We are getting your records now!");
    this.getParrams();
    this.assignUser();
    this.loadMembers();
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.invoiceID = params.get("id");
    });
  }

  loadMembers(){
    this.paymentService.showarchive = false;
    this.paymentService.stopHubConnection();
    this.paymentService.stopHubArchiveConnection();
    this.paymentService.createHubConnection(this.user, +this.invoiceID);
    this.startTimer();
  }

  editAccount(id: any, clientID: number){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "auto";
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = "75vw";
    dialogConfig.data = {
      id: id,
      invoiceID: this.invoiceID,
      clientID: clientID
    };
    
    this.paymentService.getCheckCanEditClient(id).subscribe(response => {
      if (response)
      {
        const dialogRef = this.dialog.open(EditpaymentaccountreceivableComponent, dialogConfig);
        this.toastr.info("Active Account: Opened Payment Account");
        dialogRef.afterClosed().subscribe(result => {            
          this.paymentService.cancelCanEditClient(id).subscribe(response => {
            if (response)
            {
              this.toastr.info("Active Account Closed");
            }
          }, err => {
            console.log(err);
          })
        }, err => {
          console.log(err);
        }); 
      }
      else
      {
        this.toastr.error("Failed! Another account is in use of editing this account.");
      }
    }, err => {
      console.log(err);
    })  
  }

  deleteAccount(id: any, clientID: number){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "75vw";
    dialogConfig.height = "auto";
    dialogConfig.data = {
      id: id,
      invoiceID: this.invoiceID,
      paymentDelete: false,
      clientID: clientID
    };

    this.paymentService.getCheckCanDeleteClient(id).subscribe(response => {
      if (response)
      {
        const dialogRef = this.dialog.open(DeleteaccountreceivableComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (!result.paymentDelete)
          {
            this.paymentService.cancelCanEditClient(id).subscribe(response => {
              if (response)
              {
                this.toastr.info("Active Account Closed");
              }
            }, err => {
              console.log(err);
            })
          }
        }, err => {
          console.log(err);
        });
    }
    else
    {
      this.toastr.error("Failed! Another account is in use of editing this account.");
    }
    }, err => {console.log(err)});
  }

  archiveAccount(id: any, clientID: number, status: boolean){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "75vw";
    dialogConfig.height = "auto";
    dialogConfig.data = {
      id: id,
      invoiceID: this.invoiceID,
      paymentDelete: false,
      clientID: clientID,
      archive: status
    };

    this.paymentService.getCheckCanArchiveClient(id).subscribe(response => {
      if (response)
      {
        const dialogRef = this.dialog.open(ArchiveaccountclientreceivableComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          this.paymentService.cancelCanEditClient(id).subscribe(response => {
            if (response)
            {
              this.toastr.info("Active Account Closed");
            }
          }, err => {
            console.log(err);
          });
    
        }, err => {
          console.log(err);
        });
      }
    });

  }

  onChangeArchive(status){
    if (status.target.value === "1")
    {
      this.OnChangeConnection(false);
      this.paymentService.createHubConnection(this.user, +this.invoiceID);
    }
    else
    {
      this.OnChangeConnection(true);
      this.paymentService.createHubArchiveConnection(this.user, +this.invoiceID);
    }
  }

  private OnChangeConnection(showArchive: boolean)
  {
    this.paymentService.showarchive = showArchive;
    this.paymentService.paymentsDataSource = new MatTableDataSource<accountpayment>();
    this.paymentService.stopHubConnection();
    this.paymentService.stopHubArchiveConnection();
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 10;
        this.presenceHub.pageActive[3] = true;
        this.totalCost = this.paymentService.paymentsDataSource.data.map(t => t.amount).reduce((acc, value) => acc + value, 0);
        this.paymentService.totalPayments$ = this.totalCost;
      }
    },1000)
  }
}
