
<div class="col-12 mt-4 d-none d-sm-block" >     
  <nav class="navbar navbar-light bg-primary shadow-lg">
    <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-chevron-circle-right fa-1x" aria-hidden="true"></i> Definitions</a>
    <button type="button" class="btn btn-outline-light btn-lg" (click)="legends.showLegends(0)"><i class="fa fa-window-restore"></i> {{legends.$showLegendsTxt[0]}}</button>
  </nav>
  <ng-container *ngIf="legends.$showLegends[0]" >
    <h2 class="mt-4">Icons Legends</h2>
    <div class="col-12">
      <div class="bs-component">
        <span class="badge badge-pill badge-success fs-6 mt-4 text-center"><i class="fa fa-info"></i> <b> S</b>: Is it connected to stripe account? </span>
        <span class="badge badge-pill badge-warning fs-6 ms-2 text-center"><i class="fa fa-info"></i> <b> R</b>: Can you trust your customer to make repayments?</span>     
        <span class="badge badge-pill badge-info fs-6 ms-2 text-center"><i class="fa fa-info"></i> <b> ABN</b>: Australia Business Number</span> 
        <span class="badge badge-pill badge-primary fs-6 text-center mt-4"><i class="fa fa-info"></i> <b> Email</b>: If you get a message saying "Email is set to no reply!". It means that your customers won't be able to receive emails.</span> 
      </div>
    </div>
  </ng-container>
</div> 
<div class="col-12">     
    <h1 class="text-center text-primary mt-4 font-weight-bold"><i class="fas fa-users fa-lg"></i> Clients List</h1>
</div> 
<div class="bs-component mt-4">
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary rounded ">
    <div class="container-fluid">
      <a class="navbar-brand"><i class="fas fa-search fa-lg"></i></a>
      <a class="navbar-brand text-white font-weight-bold" > Search Features:</a>    
      <div class="form-group mt-3 ms-4">
        <select class="form-select" (change)="onChangeArchive($event)">
          <option value="1">Search all clients non-archives</option>
          <option value="2">Search all clients with only archives</option>
        </select>
      </div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarColor01">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
          
          </li>
        </ul>
        <a class="navbar-brand text-white font-weight-bold" > Search:</a> 
        <form class="d-flex">
          <input class="form-control me-sm-2" type="search" placeholder="Search" (keyup)="applyFilter($event)">
        </form>
      </div>
    </div>
  </nav>
</div>

<ng-container *ngIf="(clientService.clients$ | async) !== null">
    <div class="mat-elevation-z8 mt-2">
        <table mat-table [dataSource]="clientService.clientsDataSource" multiTemplateDataRows matSort>      
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let client" > 
                  <div class="shadow-sm p-3 mb-5 bg-white rounded">      
                    <p class="font-weight-bold">{{client.name}}</p>   
                  </div>
              </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
            <td mat-cell *matCellDef="let client" > 
                <div class="shadow-sm p-3 mb-5 bg-white rounded">                           
                  <p class="mt-2"><small>{{client.addressUnit}} {{client.address}}, {{client.suburb}}</small></p>
                </div>
            </td>
          </ng-container>

            <!-- Email Column -->
             <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
                <td mat-cell *matCellDef="let client">
                    <div class="shadow-sm p-3 mb-5 bg-white rounded ms-4">    
                      <ng-container *ngIf="client.email === 'no-reply@invoicepager.com'; else defaultemail">
                        <p><b>Email:</b></p><p><b class="text-warning"><small> Is set to no email!</small></b></p>
                      </ng-container>                
                      <ng-template #defaultemail>
                        <p><b>Email:</b></p><p><small> {{client.email}}</small></p>
                      </ng-template>                  
                     </div>
                </td>
              </ng-container>
            <!-- Address Column -->
            <ng-container matColumnDef="state">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> State</th>
              <td mat-cell *matCellDef="let client">
                  <div class="shadow-sm p-3 mb-5 bg-white rounded ms-4">    
                    <p><b>State:</b></p><p><small> {{client.state}}</small></p>               
                  </div>
              </td>
            </ng-container>
             <!-- Contact Mobile Column -->
             <ng-container matColumnDef="contactMobile">
                <th mat-header-cell *matHeaderCellDef> Contact Details </th>
                <td mat-cell *matCellDef="let client">
                    <div class="shadow-sm p-3 mb-5 bg-white rounded ms-4">   
                     <p><b>Mobile:</b><small> {{client.contactMobile}}</small></p>
                     <p *ngIf="client.contactHomePhone !== ''"><b>Contact Other:</b><small> {{client.contactHomePhone}}</small></p>
                     <p *ngIf="client.contactBusiness !== ''"><b>BC:</b><small> {{client.contactBusiness}}</small></p>
                    </div>
                </td>
              </ng-container>

             <!-- Stripe Connected -->
             <ng-container matColumnDef="stripeConnected">
                <th mat-header-cell *matHeaderCellDef class="text-right"> Stripe / Rating </th>
                <td mat-cell *matCellDef="let client">
                    <div class="shadow-none  p-3 mb-5 bg-white rounded">
                      <div *ngIf="client.linkedStripe===true"><p><b>S:</b> <i class="fa fa-check" style="font-size:24px;color:green"></i></p></div>
                      <div *ngIf="client.linkedStripe===false"><p><b>S:</b> <i class="fa fa-remove" style="font-size:24px;color:red"></i></p></div>  
                      <div *ngIf="client.creditRatingApproved===true"><p><b>R:</b> <i class="fa fa-check" style="font-size:24px;color:green"></i></p></div>
                      <div *ngIf="client.creditRatingApproved===false"><p><b>R:</b> <i class="fa fa-remove" style="font-size:24px;color:red"></i></p></div>  
                    </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                <td mat-cell *matCellDef="let element">
                  <div class="p-3 mb-5 bg-white border-none ms-4">    
                    <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">           
                      <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                      <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                    </button>        
                  </div>
                </td>
              </ng-container>
                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let client" [attr.colspan]="columnsToDisplayWithExpand.length">
      <div class="example-element-detail"
           [@detailExpand]="client == expandedElement ? 'expanded' : 'collapsed'">
        <div class="example-element-description">
            <div class="col-lg-12">
              <div class="page-header">
                <h1 class="weight-bold text-primary text-uppercase fs-3">Actions for Client Account</h1>
              </div>
              <div class="row">             
                  <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xm-12">
                    <button type="button" class="btn btn-outline-primary" (click)="openInvoices(client.id)">
                      <i class='fas fa-file'></i> 
                      <ng-container *ngIf="accountService.currentUser$ | async as user">
                        <ng-container *ngIf="user.typeOfService; else showInvoice ">
                          Open Billing accounts
                        </ng-container>
                        <ng-template #showInvoice>
                          Open invoice accounts
                        </ng-template>
                      </ng-container>
                    </button>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xm-12 mt-4 mt-md-0 ">
                    <button type="button" class="btn btn-outline-primary" (click)="openStoreCredit(client.id)"><i class='fas fa-file'></i> Open Store Debit/Credit Accounts</button>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xm-12 mt-4 mt-md-0 ">
                    <button type="button" class="btn btn-outline-primary" (click)="openPayaccountAccountsForOneClient(client.id)"><i class='fas fa-file'></i> Open Client Account Payments</button>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xm-12 mt-4 mt-md-0 ">
                    <button type="button" class="btn btn-outline-warning" (click)="editClient(client.id)"><i class='fas fa-edit'></i> Edit Client Account</button>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xm-12 mt-4 mt-md-0 ">
                    <button type="button" class="btn btn-outline-danger" (click)="archiveClient(client.id, client.archive)"><i class='fas fa-trash'></i> Archive Client Account</button>
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xm-12 mt-4 mt-md-0 ">
                    <button type="button" class="btn btn-outline-danger" (click)="deleteClient(client.id)"><i class='fas fa-trash'></i> Delete Client Account</button>
                  </div>
                  <div class="page-header">
                    <h1 class="weight-bold text-primary text-uppercase fs-4 mt-4">Client Personal Details</h1>
                  </div>
                  <div class="bs-component">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">First Name</th>
                          <th scope="col">Last Name</th>
                          <th scope="col">Address Unit</th>
                          <th scope="col">Address</th>
                          <th scope="col">Surburb / City</th>
                          <th scope="col">Postcode</th>
                          <th scope="col">Contact Other</th>    
                          <th scope="col">State</th>
                          <th scope="col">Country</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td scope="row">{{client.firstName}}</td>
                          <td scope="row">{{client.lastName}}</td>
                          <td>{{client.addressUnit}}</td>
                          <td>{{client.address}}</td>                  
                          <td>{{client.province}}</td>
                          <td>{{client.postCode}}</td>
                          <td>{{client.contactHomePhone}}</td>                    
                          <td>{{client.state}}</td>
                          <td>{{client.country}}</td>
                        </tr>                    
                      </tbody>
                    </table>
                </div>
                <div class="page-header">
                  <h1 class="weight-bold text-primary text-uppercase fs-4 mt-4">Business Details</h1>
                </div>
                <div class="bs-component">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Business Name</th>
                        <th scope="col">ABN</th>
                        <th scope="col">Business Contact</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">{{client.bussinessName}}</th>
                        <td>{{client.abn}}</td>
                        <td>{{client.contactBusiness}}</td>
                      </tr>                    
                    </tbody>
                  </table>
              </div>
              </div>
            </div>
        </div>
      </div>
    </td>
  </ng-container>
              <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
              <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
                  class="example-element-row"
                  [class.example-expanded-row]="expandedElement === element"
                  (click)="expandedElement = expandedElement === element ? null : element">
               </tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>         
  <mat-paginator [pageSizeOptions]="[10000]" showFirstLastButtons></mat-paginator>
  </div>
</ng-container>
<div class="mt-3"></div>