<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to search for an invoice account</h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/Search for Invoice Account/siac06.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Follow instructions on “How to go to the Invoice Accounts page” </h2>
    <h2>•	Scroll down the page to find “List of Invoices” section </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/Search for Invoice Account/siac07.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	You can search for the client’s account invoice using the” Search” bar</h2>
    <h2>•	Please note that this search bar will only search for “Invoice Accounts” for the client you selected earlier</h2>
    <h2>•	Ensure that when you search that the spelling is correct</h2>
</div>