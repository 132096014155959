<app-nav></app-nav>
<div class="container mt-4">
    <h2 class="text-center text-primary mt-2">Frequenctly Asked Questions</h2>
    <div class="accordion accordion-flush border" id="accordionFlushExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingrefundClient">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapserefundClient" aria-expanded="false" aria-controls="flush-collapserefundClient">
                How do I do a refund to my client?
            </button>
          </h2>
          <div id="flush-collapserefundClient" class="accordion-collapse collapse" aria-labelledby="flush-headingrefundClient" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
                At the top of the navigation menu bar, click on 'Stripe account'. Next, locate the Stripe payment table, and click on the 'three dots' which should be located on the far-right-hand side of the screen. Finally, click on 'Refund'
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingCancelSubscription">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCancelSubscription" aria-expanded="false" aria-controls="flush-collapseCancelSubscription">
               How do I cancel my subscription?
            </button>
          </h2>
          <div id="flush-collapseCancelSubscription" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
                How do I cancel my subscription? At the top of the menu on the far right-hand side of the screen, click on 'Billing accounts'. Below the title 'subscription account details', you should see a button called 'Cancel membership'. Click on the button.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingEditAccount">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEditAccount" aria-expanded="false" aria-controls="flush-collapseEditAccount">
                How do I edit my account?
            </button>
          </h2>
          <div id="flush-collapseEditAccount" class="accordion-collapse collapse" aria-labelledby="flush-headingEditAccount" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
                At the top of the menu bar, where it says 'Welcome [your name],' click on the down arrow next to it, and then click on 'Edit Account'.
            </div>
          </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingGroupPermissions">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseGroupPermissions" aria-expanded="false" aria-controls="flush-collapseGroupPermissions">
                How do I create a group permission?
              </button>
            </h2>
            <div id="flush-collapseGroupPermissions" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                <p>Please follow these steps to navigate the menu bar:</p>
                <ol>               
                    <li>Locate the menu bar at the top of the screen, where you will find the phrase "Welcome [your name]".</li>
                    <li>Click on the downward arrow situated next to the "Welcome [your name]" text.</li>
                    <li>After clicking the arrow, a dropdown menu will appear. Proceed to select the option labeled "User groups".</li>
                    <li>This action will open a new page for you.</li>
                    <li>On the navigation bar of the new page, locate the section labeled "User groups".</li>
                    <li>Click on the button labeled "Maximize Window".</li>
                    <li>Next, you need to specify the requirements or options for the permissions you desire for that particular user group.</li>
                    <li>Once you have filled in the necessary information, click on the "Create user group" button.</li>                       
                </ol>
              </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingCreateClient">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCreateClient" aria-expanded="false" aria-controls="flush-collapseCreateClient">
                How do I create client account?
              </button>
            </h2>
            <div id="flush-collapseCreateClient" class="accordion-collapse collapse" aria-labelledby="flush-headingCreateClient" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <ol>
                        <li>Click on <a routerLink="/members/turtorialhome">here</a> to refer to the tutorials.</li>
                        <li>Find the left-hand side and then click on 'Clients'</li>
                        <li>A list will then appear.</li>
                        <li>Finally, click on 'How to create client account?'</li>
                      </ol>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingEditClient">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEditClient" aria-expanded="false" aria-controls="flush-collapseCreateClient">
                How do I edit client account?
              </button>
            </h2>
            <div id="flush-collapseEditClient" class="accordion-collapse collapse" aria-labelledby="flush-headingEditClient" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <ol>
                        <li>Click on <a routerLink="/members/turtorialhome">here</a> to refer to the tutorials.</li>
                        <li>Find the left-hand side and then click on 'Clients'</li>
                        <li>A list will then appear.</li>
                        <li>Finally, click on 'How to create client account?'</li>
                      </ol>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingDeleteClient">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseDeleteClient" aria-expanded="false" aria-controls="flush-collapseFive">
                How do I delete or archive client account?
              </button>
            </h2>
            <div id="flush-collapseDeleteClient" class="accordion-collapse collapse" aria-labelledby="flush-headingDeleteClient" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <ol>
                        <li>Click <a routerLink="/members/turtorialhome">here</a> to refer to the tutorials.</li>
                        <li>Locate on the left-hand side. Click the section called 'Clients'.</li>
                        <li>Then, find the section to archive or delete the client account.</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingCantDeleteClient">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCantDeleteClient" aria-expanded="false" aria-controls="flush-collapseSix">
                I can’t delete my client account! How do I delete it?
              </button>
            </h2>
            <div id="flush-collapseCantDeleteClient" class="accordion-collapse collapse" aria-labelledby="flush-headingCantDeleteClient" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    I can’t delete my client account! How do I delete it? If you receive a message indicating that you cannot delete your client account, it may be because there is data stored within your account or your client has made payments using your account. You can attempt to remove the data within your account to proceed with the deletion, but it is strongly recommended to archive the account instead.
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingCreateInvoice">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCreateInvoice" aria-expanded="false" aria-controls="flush-collapseCreateInvoice">
                How do I create an invoice?
              </button>
            </h2>
            <div id="flush-collapseCreateInvoice" class="accordion-collapse collapse" aria-labelledby="flush-headingCreateInvoice" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <ol>
                        <li>Click on <a routerLink="/members/turtorialhome">here</a> to refer to the tutorials.</li>
                        <li>Find the left-hand side and then click on 'Invoice page.'</li>
                        <li>A list will then appear.</li>
                        <li>Finally, click on 'Create an invoice'</li>
                    </ol>
                </div>
            </div>        
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingEditInvoice">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEditInvoice" aria-expanded="false" aria-controls="flush-collapseEight">
                How do I edit an invoice?
              </button>
            </h2>
            <div id="flush-collapseEditInvoice" class="accordion-collapse collapse" aria-labelledby="flush-headingEditInvoice" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <ol>
                        <li>Click on <a routerLink="/members/turtorialhome">here</a> to refer to the tutorials.</li>
                        <li>Find the left-hand side and then click on 'Invoice page.'</li>
                        <li>A list will then appear.</li>
                        <li>Finally, click on 'How to edit an invoice?'</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingDeleteInvoice">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseDeleteInvoice" aria-expanded="false" aria-controls="flush-collapseNine">
                How do I delete or archive invoice account?
              </button>
            </h2>
            <div id="flush-collapseDeleteInvoice" class="accordion-collapse collapse" aria-labelledby="flush-headingDeleteInvoice" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                <ol>
                    <li>Click on <a routerLink="/members/turtorialhome">here</a> to refer to the tutorials.</li>
                    <li>Find the left-hand side and then click on 'Invoice page.'</li>
                    <li>A list will then appear.</li>
                    <li>Finally, click on 'How to delete an invoice?'</li>
                </ol>
              </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingCantDeleteInvoice">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCantDeleteInvoice" aria-expanded="false" aria-controls="flush-collapseEleven">
                I can’t delete my invoice account? How do I delete it?
              </button>
            </h2>
            <div id="flush-collapseCantDeleteInvoice" class="accordion-collapse collapse" aria-labelledby="flush-headingCantDeleteInvoice" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    If you receive a message indicating that you cannot delete your invoice account, it may be because there is data stored within your account or your invoice account has made payments using your account. You can attempt to remove the data within your account to proceed with the deletion, but it is strongly recommended to archive the account instead.
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingCreateInstalment">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCreateInstalment" aria-expanded="false" aria-controls="flush-collapseTwelve">
                How do I create an instalment?
              </button>
            </h2>
            <div id="flush-collapseCreateInstalment" class="accordion-collapse collapse" aria-labelledby="flush-headinCreateInstalment" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <ol>
                        <li>Click on <a routerLink="/members/turtorialhome">here</a> to refer to the tutorials.</li>
                        <li>Find the left-hand side and then click on 'Instalment'</li>
                        <li>A list will then appear.</li>
                        <li>Finally, click on 'How to create instalment account?'</li>
                      </ol>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingEditInstalment">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEditInstalment" aria-expanded="false" aria-controls="flush-collapseThirteen">
                How do I edit an instalment?
              </button>
            </h2>
            <div id="flush-collapseEditInstalment" class="accordion-collapse collapse" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <ol>
                        <li>Click on <a routerLink="/members/turtorialhome">here</a> to refer to the tutorials.</li>
                        <li>Find the left-hand side and then click on 'Instalments'</li>
                        <li>A list will then appear.</li>
                        <li>Finally, click on 'How to edit instalment account'</li>
                      </ol>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingDeleteInstalment">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseDeleteInstalment" aria-expanded="false" aria-controls="flush-collapseFourteen">
                How do I delete or archive instalment account?
              </button>
            </h2>
            <div id="flush-collapseDeleteInstalment" class="accordion-collapse collapse" aria-labelledby="flush-headingDeleteInstalment" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <ol>
                        <li>Click on <a routerLink="/members/turtorialhome">here</a> to refer to the tutorials.</li>
                        <li>Find the left-hand side and then click on 'Instalments'</li>
                        <li>A list will then appear.</li>
                        <li>Finally, click on 'Delete instalment account?'</li>
                      </ol>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingCantDeleteInstalment">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCantDeleteInstalment" aria-expanded="false" aria-controls="flush-collapseSixteen">
                I can’t delete my instalment account! How do I delete it?
              </button>
            </h2>
            <div id="flush-collapseCantDeleteInstalment" class="accordion-collapse collapse" aria-labelledby="flush-headingCantDeleteInstalment" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                I can’t delete my instalment account! How do I delete it?	If you receive a message indicating that you cannot delete your instalment account, it may be because there is data stored within your account or your client has made payments using your account. You can attempt to remove the data within your account to proceed with the deletion, but it is strongly recommended to archive the account instead.
              </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingCreatePaymentAccount">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCreatePaymentAccount" aria-expanded="false" aria-controls="flush-collapseSeventeen">
                How do I create an payment account?
              </button>
            </h2>
            <div id="flush-collapseCreatePaymentAccount" class="accordion-collapse collapse" aria-labelledby="flush-headingCreatePaymentAccount" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <ol>
                        <li>Click on <a routerLink="/members/turtorialhome">here</a> to refer to the tutorials.</li>
                        <li>Find the left-hand side and then click on 'Account payments'</li>
                        <li>A list will then appear.</li>
                        <li>Finally, click on 'Create payment account'</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingEditPaymentAccount">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEditPaymentAccount" aria-expanded="false" aria-controls="flush-collapseEighteen">
                How do I edit an payment account?
              </button>
            </h2>
            <div id="flush-collapseEditPaymentAccount" class="accordion-collapse collapse" aria-labelledby="flush-headingEditPaymentAccount" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <ol>
                        <li>Click on <a routerLink="/members/turtorialhome">here</a> to refer to the tutorials.</li>
                        <li>Find the left-hand side and then click on 'Account payments'</li>
                        <li>A list will then appear.</li>
                        <li>Finally, click on 'Create edit payment account'</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingDeletePaymentAccount">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseDeletePaymentAccount" aria-expanded="false" aria-controls="flush-collapseEighteen">
                How do I delete payment account?
              </button>
            </h2>
            <div id="flush-collapseDeletePaymentAccount" class="accordion-collapse collapse" aria-labelledby="flush-headingDeletePaymentAccount" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <ol>
                        <li>Click on <a routerLink="/members/turtorialhome">here</a> to refer to the tutorials.</li>
                        <li>Find the left-hand side and then click on 'Account payments'</li>
                        <li>A list will then appear.</li>
                        <li>Finally, click on 'Delete payment account'</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingCantDeletePaymentAccount">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCantDeletePaymentAccount" aria-expanded="false" aria-controls="flush-collapseSixteen">
                I can’t delete my payment account?
              </button>
            </h2>
            <div id="flush-collapseCantDeletePaymentAccount" class="accordion-collapse collapse" aria-labelledby="flush-headingCantDeletePaymentAccount" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                I can’t delete my payment account! How do I delete it?	If you receive a message indicating that you cannot delete your client account, it may be because there is data stored within your account or your client has made payments using your account. You can attempt to remove the data within your account to proceed with the deletion, but it is strongly recommended to archive the account instead.
              </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingCreateStoreAccount">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCreateStoreAccount" aria-expanded="false" aria-controls="flush-collapseSeventeen">
                How do I create an Storeaccount?
              </button>
            </h2>
            <div id="flush-collapseCreateStoreAccount" class="accordion-collapse collapse" aria-labelledby="flush-headingCreateStoreAccount" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <ol>
                        <li>Click on <a routerLink="/members/turtorialhome">here</a> to refer to the tutorials.</li>
                        <li>Find the left-hand side and then click on 'Store Account'</li>
                        <li>A list will then appear.</li>
                        <li>Finally, click on 'Create Store account'</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingEditStoreAccount">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEditStoreAccount" aria-expanded="false" aria-controls="flush-collapseEighteen">
                How do I edit an Store account?
              </button>
            </h2>
            <div id="flush-collapseEditStoreAccount" class="accordion-collapse collapse" aria-labelledby="flush-headingEditStoreAccount" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <ol>
                        <li>Click on <a routerLink="/members/turtorialhome">here</a> to refer to the tutorials.</li>
                        <li>Find the left-hand side and then click on 'Store Account'</li>
                        <li>A list will then appear.</li>
                        <li>Finally, click on 'Edit Store Account'</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingDeleteStoreAccount">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseStorePaymentAccount" aria-expanded="false" aria-controls="flush-collapseEighteen">
                How do I delete Store account?
              </button>
            </h2>
            <div id="flush-collapseStorePaymentAccount" class="accordion-collapse collapse" aria-labelledby="flush-headingDeletePaymentAccount" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <ol>
                        <li>Click on <a routerLink="/members/turtorialhome">here</a> to refer to the tutorials.</li>
                        <li>Find the left-hand side and then click on 'Store Account'</li>
                        <li>A list will then appear.</li>
                        <li>Finally, click on 'How to delete store account'</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingCantDeleteStoreAccount">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCantDeleteStoreAccount" aria-expanded="false" aria-controls="flush-collapseSixteen">
                I can’t delete my store account! How do I delete it?
              </button>
            </h2>
            <div id="flush-collapseCantDeleteStoreAccount" class="accordion-collapse collapse" aria-labelledby="flush-headingCantDeleteStoreAccount" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                I can’t delete my store account! How do I delete it?	If you receive a message indicating that you cannot delete your store account, it may be because there is data stored within your account or your client has made payments using your account. You can attempt to remove the data within your account to proceed with the deletion, but it is strongly recommended to archive the account instead.
              </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingEditGroupPermissions">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEditGroupPermissions" aria-expanded="false" aria-controls="flush-collapseEighteen">
                How do I edit group permissions?
              </button>
            </h2>
            <div id="flush-collapseEditGroupPermissions" class="accordion-collapse collapse" aria-labelledby="flush-headingEditGroupPermissions" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <p>To navigate the menu bar, please follow these steps:</p>
                    <ol>
                        <li>Locate the menu bar at the top of the screen, where you will find the phrase "Welcome [your name]."</li>
                        <li>Click on the downward arrow next to the "Welcome [your name]" text.</li>
                        <li>This will display a dropdown menu. Select the option labeled "User groups."</li>
                        <li>Upon selecting "User groups," a new page will open.</li>
                        <li>Scroll down to the section titled "Permissions groups." You will find a table in this section.</li>
                        <li>Locate the specific row that you wish to edit and click on the downward arrow.</li>
                        <li>From the options that appear, choose "Edit group permissions."</li>
                        <li>A new window will appear, allowing you to edit the permissions.</li>
                        <li>After making the necessary changes to the permissions, click on "Update user group" to finalize the modifications.</li>
                      </ol>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingLinkedProfileAccounts">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseLinkedProfileAccounts" aria-expanded="false" aria-controls="flush-collapseEighteen">
                How do I link accounts to my account?
              </button>
            </h2>
            <div id="flush-collapseLinkedProfileAccounts" class="accordion-collapse collapse" aria-labelledby="flush-headingLinkedProfileAccounts" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <p>To navigate the menu bar, please follow these steps:</p>
                    <ol>
                        <li>Locate the menu bar at the top of the screen, where you will find the phrase "Welcome [your name]."</li>
                        <li>Click on the downward arrow next to the "Welcome [your name]" text.</li>
                        <li>This will display a dropdown menu. Select the option labeled "User groups."</li>
                        <li>Upon selecting "User groups," a new page will open.</li>
                        <li>Find the section labeled "Create member group" and click on the "Maximize window" button</li>
                        <li>Once the window is maximized, proceed to fill in the necessary details for the user you want to create for the permission group.</li>
                        <li>After entering the required information, click on the "Create user in group" button.</li>
                        <li>A user must confirm the invitation via email to finalize the transfer.</li>
                      </ol>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingCustomerNotifications">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCustomerNotifications" aria-expanded="false" aria-controls="flush-collapseEighteen">
                How do I custom my messages via email and SMS?
              </button>
            </h2>
            <div id="flush-collapseCustomerNotifications" class="accordion-collapse collapse" aria-labelledby="flush-headingCustomerNotifications" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <ol>
                        <li>Click on <a routerLink="/members/turtorialhome">here</a> to refer to the tutorials.</li>
                        <li>Find the left-hand side and then click on 'Messages'</li>
                        <li>A list will then appear.</li>
                        <li>Finally, click on 'How to customise your notifcations to your customers'</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingCustomerTimeStamp">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseCustomerTimeStamp" aria-expanded="false" aria-controls="flush-collapseEighteen">
                How do I change due time sent for messages?
              </button>
            </h2>
            <div id="flush-collapseCustomerTimeStamp" class="accordion-collapse collapse" aria-labelledby="flush-headingCustomerTimeStamp" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <ol>
                        <li>Click on <a routerLink="/members/turtorialhome">here</a> to refer to the tutorials.</li>
                        <li>Find the left-hand side and then click on 'Messages'</li>
                        <li>A list will then appear.</li>
                        <li>Finally, click on 'How to change messages timestamp'</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>