import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorialhowtogetpayments',
  templateUrl: './tutorialhowtogetpayments.component.html',
  styleUrls: ['./tutorialhowtogetpayments.component.css']
})
export class TutorialhowtogetpaymentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
