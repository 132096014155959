import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { StoreAccountModel } from 'src/app/_models/storeaccountmodel';
import { StoreaccountService } from 'src/app/_services/storeaccount/storeaccount.service';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator';
import { ListstorecreditComponent } from '../liststorecredit/liststorecredit.component';

@Component({
  selector: 'app-editstorecredit',
  templateUrl: './editstorecredit.component.html',
  styleUrls: ['./editstorecredit.component.css']
})
export class EditstorecreditComponent implements OnInit {

  formAccount: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: StoreAccountModel, public fb: UntypedFormBuilder, private services: StoreaccountService, private toastr: ToastrService
  ,public dialogRef: MatDialogRef<ListstorecreditComponent>) { }

  ngOnInit(): void {
    this.initializeForm();
    this.sortDateInitialize();
  }

  onSubmit(){
    const data = this.getUpdateControls();
    this.updateData();
    console.log(this.data.datePaid);
    this.services.editStoreAccount(data).subscribe(response => {
      if (response == "s")
      {
        this.toastr.success("Updated Account Succesfully,");
        this.dialogRef.close();
      }
    }, err => {
      console.log(err);
    });
  }

  initializeForm(){
    this.formAccount = this.fb.group({
      paymentAmount:['', [Validators.required,  RxwebValidators.numeric({allowDecimal:true,isFormat:true}), Validators.min(0.01), Validators.max(999999999999999)]],
      datePaid: ['', Validators.required],
      typePayment: ['', Validators.required],
      note: ['', [WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter, Validators.maxLength(1000)]],     
    })
  }

  sortDateInitialize(){
    const paymentType = this.getTypePayment(this.data.typePayment);
    this.formAccount.controls['paymentAmount'].setValue(this.data.paymentAmount);
    this.formAccount.controls['datePaid'].setValue(new Date(this.data.datePaid));
    this.formAccount.controls['typePayment'].setValue(paymentType);
    this.formAccount.controls['note'].setValue(this.data.note);
  }

  getTypePayment(type: number): string{
    if (type == 1)
    {
      return "StoreDebit";
    }
    return "StoreCredit";
  }

  getUpdateControls(): StoreAccountModel{
    const date = new Date(this.formAccount.controls['datePaid'].value);
    const account: StoreAccountModel = {
      id: this.data.id,
      datePaid: date,
      note: this.formAccount.value.note,
      paymentAmount: this.formAccount.value.paymentAmount,
      typePayment: this.formAccount.value.typePayment,
      datePaidS: date.toUTCString()
    };
    return account;    
  }

  getTypePaymentName(type: string): number{
    if (type == "StoreDebit")
    {
      return 1;

    }
    return 0;
  }

  updateData(){
    const date = new Date(this.formAccount.value.datePaid);
    this.data.datePaid = date;
    this.data.note = this.formAccount.value.note;
    this.data.paymentAmount = this.formAccount.value.paymentAmount;
    this.data.typePayment = this.getTypePaymentName(this.formAccount.value.typePayment);    
  }

}
