import { AbstractControl, ValidationErrors } from '@angular/forms';  
    
export class WhiteSpacesValidator {  
    static cannotContainSpace(control: AbstractControl) : ValidationErrors | null {  
        if((control.value as string).indexOf(' ') >= 0){  
            return {cannotContainSpace: true}  
        }  

        
        if((control.value as string).indexOf(' ') == 0){  
            return {cannotContainSpaceAtFirstCharacter: true}  
        }  
    
        return null;  
    } 
    
    static cannotContainSpaceAtFirstCharacter(control: AbstractControl) : ValidationErrors | null {  
        if((control.value as string).charAt(0) == " "){  
            return {cannotContainSpaceAtFirstCharacter: true}  
        }

        if((control.value as string).charAt(0) == " "){  
            return {cannotContainSpaceAtFirstCharacter: true}  
        }  

        return null;  
    }  


}  