<mat-dialog-content>
<div class="container">
    <div class="row">
        <div class="col-12">            
            <form #editForm="ngForm" id="editForm" [formGroup]="formEdit" (ngSubmit)="formEdit.valid" autocomplete="off">
                <h4 class="text-center"><b>Time Selected from before:</b> {{time}}</h4> 
                <div class="form-group">
                    <label for="exampleFormControlSelect1">SELECT DUE TIME:</label>
                    <select class="form-control" formControlName="changeText">
                      <option value="" selected disabled hidden>Choose here</option>
                      <option value="0000">12:00 AM</option>   
                      <option value="0100">1:00 AM</option>
                      <option value="0200">2:00 AM</option>
                      <option value="0300">3:00 AM</option>
                      <option value="0400">4:00 AM</option>
                      <option value="0500">5:00 AM</option>
                      <option value="0600">6:00 AM</option>
                      <option value="0700">7:00 AM</option>
                      <option value="0800">8:00 AM</option>
                      <option value="0900">9:00 AM</option>
                      <option value="1000">10:00 AM</option>
                      <option value="1100">11:00 AM</option>      
                      <option value="1200">12:00 PM</option>             
                      <option value="1300">1:00 PM</option>
                      <option value="1400">2:00 PM</option>
                      <option value="1500">3:00 PM</option>
                      <option value="1600">4:00 PM</option>
                      <option value="1700">5:00 PM</option>
                      <option value="1800">6:00 PM</option>
                      <option value="1900">7:00 PM</option>
                      <option value="2000">8:00 PM</option>
                      <option value="2100">9:00 PM</option>
                      <option value="2200">10:00 PM</option>
                      <option value="2300">11:00 PM</option>                                     
                    </select>
                  </div>  
            </form>   
        </div>              
        <div class="col-6">
            <button [disabled]='!formEdit.valid || !formEdit.dirty' class="btn btn-success btn-block" mat-button [mat-dialog-close]="data" (click)="onSubmit()">Confirm Changes</button>
        </div>
        <div class="col-6">
            <button class="btn btn-danger btn-block" mat-button mat-dialog-close>Cancel</button>
        </div>
    </div>
</div>
</mat-dialog-content>