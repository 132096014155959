<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to import invoices from Excel</h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/importing/invoices/ii01.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	On the navigation bar, click the “Imports” item</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/importing/invoices/ii02.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Click “Import Invoices via Excel”</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/importing/invoices/ii03.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	You are now on the “Import File” page </h2>
    <h2>•	Click “Choose file”</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/importing/invoices/ii04.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Choose the file you wish to import from</h2>
    <h2>•	Click “Open”</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/importing/invoices/ii05.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	By default, the “Data Types” Invoice Number, Invoice Amount, Payment Amount, Initial Date, Due Date, First Name, Last Name, and Mobile Contact will be imported</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/importing/invoices/ii06.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	To import more Data Types, select the Data Types from the list</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/importing/invoices/ii07.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Selected Data Types will appear on the right</h2>
    <h2>•	The order shown here signifies how the columns must be ordered within the file you are importing</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/importing/invoices/ii08.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Click “Upload” to upload the invoice data</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/importing/invoices/ii09.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	You will now be able to see a list of invoices to be imported </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/importing/invoices/ii10.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Choose whether you want the listed clients to cover the surcharge of 1.75% + $0.30c</h2>
    <h2>•	Selecting “No” will result in your business covering the surcharges</h2>
    <h2>•	This is a mandatory field. “Yes” is selected by default</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/importing/invoices/ii11.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	You can edit or delete invoices prior to Submitting the Import List</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/importing/invoices/ii12.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Click “Submit Import List” to import the invoices</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/importing/invoices/ii13.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Confirm the import by clicking “Import invoices into our cloud services”</h2>
</div>