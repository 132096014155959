<div class="">
    <div class="card-body">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xm-12">
          <img src="/assets/images/profile-account.jpg" alt="profile account" class="img-thumbnail rounded center">
          <h2 class="card-title text-center bg-white p-2 font-weight-bold text-primary fs-3 text-uppercase shadow-sm"><i class="fas fa-house-user fa-2x text-primary"></i> Client Information </h2>
          <p class="card-text text-center p-2 fs-4"><b>Client Name: </b>{{clientName}} </p>         
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xm-12">
          <div class="card border shadow-lg">
            <div class="card-body bg-secondary">
              <fieldset class="form-group">
                <legend class="text-center text-primary fs-2 text-uppercase font-weight-bold">How to use This?</legend>
                <div class="form-group mt-4">
                  <ng-container *ngIf="isitinvoice === 'yes';else shownotinvoice">
                    <div class="embed-responsive embed-responsive-16by9">
                      <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/efaHfpwbwiA?si=8CnQBIkB73jYJHfX" allowfullscreen></iframe>
                    </div>
                  </ng-container>
                  <ng-template #shownotinvoice>
                    <div class="embed-responsive embed-responsive-16by9">
                      <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/HR20xz5z2fU?si=pAVS4t5c06Qu4qE9" allowfullscreen></iframe>
                    </div>
                  </ng-template>              
                  <button class="btn btn-info ms-2 btn-block mt-4" routerLink="/members/turtorialhome"><i class="fas fa-question-circle fa-lg"></i> Click Here to learn more!</button>
                </div>             
              </fieldset>
            </div>
          </div>          
        </div>
      </div>
    </div>
</div> 