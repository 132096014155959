<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to view a clients invoice account</h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/View Invoices/viac01.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Go to the Client’s “Invoice Accounts” page and then scroll to the “Create Invoice Account” section to view existing “Invoice Accounts” </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/View Invoices/viac02.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Click in line with an “Invoice Account” (Not the 3 dots) to reveal more information </h2>
    <h2>•	Click on an “Invoice Account” again to hide the additional information </h2>
</div>
