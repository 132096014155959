import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { HostpresenceService } from 'src/app/_services/hubsignalr/hostpresence.service';
import { InstalmentService } from 'src/app/_services/instalment/instalment.service';
import { DeleteinstalmentsComponent } from '../deleteinstalments/deleteinstalments.component';
import { EditinstalmentsComponent } from '../editinstalments/editinstalments.component';
import { MatTableDataSource } from '@angular/material/table';
import { Instalment } from 'src/app/_models/instalment';
import { ArchiveinstalmentComponent } from '../archiveinstalment/archiveinstalment.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DialogrestinstalmentinitalmessageComponent } from '../dialogrestinstalmentinitalmessage/dialogrestinstalmentinitalmessage.component';

@Component({
  selector: 'app-listinstalmentaccounts',
  templateUrl: './listinstalmentaccounts.component.html',
  styleUrls: ['./listinstalmentaccounts.component.css']
})
export class ListinstalmentaccountsComponent implements OnInit, OnDestroy {
  user?: User;
  invoiceAmount: number = 0;
  columnsToDisplay = ['no', 'duedate', 'initialdate', 'costamount', 'addfee', 'optioninitialsent','addinitalpayment', 'duedatesent', 'receivepayment', 'note', 'actions'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  interval;
  timeLeft: number = 1;
  showErrorInitialMessage: boolean = false;

  constructor(public instalmentService: InstalmentService, private route: ActivatedRoute, private dialog: MatDialog,
    private toastr: ToastrService, private accountService: AccountService, private presenceHub: HostpresenceService) { }

  ngOnInit(): void {
    this.presenceHub.pageActive[3] = true;
    this.getParrams();
    this.assignUser();
    this.loadMembers();
  }

  ngOnDestroy(): void {
    this.presenceHub.pageActive[3] = false;
    this.toastr.info("Please wait! We are getting your records now!");
    this.instalmentService.stopHubConnection();
    this.instalmentService.stopHubArchiveConnection();
    this.instalmentService.showarchive = false;
    clearInterval(this.interval);
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  loadMembers(){
    this.instalmentService.stopHubConnection();
    this.instalmentService.createHubConnection(this.user, +this.instalmentService.$invoiceID);
    this.startTimer();
  }

  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.instalmentService.$invoiceID = params.get("id");
      this.invoiceAmount = +params.get("invoiceamount");
    });
  }

  editinstalment(id: number, costamount: number){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "75%";
    dialogConfig.height = "auto";
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      id: id,
      invoiceID: this.instalmentService.$invoiceID,
      costAmount: costamount,
      invoiceAmount: +this.invoiceAmount
    };
    
    this.instalmentService.getCheckCanEditClient(id).subscribe(response => {
      if (!response)
      {
        this.toastr.info("Active Account: Opened Invoice Account");   
        const dialogRef = this.dialog.open(EditinstalmentsComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          this.instalmentService.cancelCanEditClient(id).subscribe(response => {
            if (response)
            {
              this.toastr.info("Active Account Closed");
            }
          }, err => {console.log(err)})
        }, err => {console.log(err)});       
      }
      else
      {
        this.toastr.error("Failed! Another account is in use of editing this account.");
      }
    }, err => {
      console.log(err);
    }) 
  }

  resetInitialMessage(id: number){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "auto"
    dialogConfig.width = "75vw";
    dialogConfig.data = {
      id: id,
    };
    
    const dialogRef = this.dialog.open(DialogrestinstalmentinitalmessageComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

    }); 
  }  

  deleteInstalment(id: any){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "auto"
    dialogConfig.width = "75vw";
    dialogConfig.data = {
      id: id,
      invoiceAmount: this.invoiceAmount,
      deleteInstalment: false,
      invoiceID: this.instalmentService.$invoiceID 
    };
    
    this.instalmentService.getCheckCanDeleteClient(id).subscribe(response => {
      if (!response)
      {
        const dialogRef = this.dialog.open(DeleteinstalmentsComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          if (result.deleteInstalment)
          {
            location.reload();
          }
          else
          {
            this.instalmentService.cancelCanEditClient(id).subscribe(response => {
              if (response)
              {
                this.toastr.info("Active Account Closed");
              }
            }, err => {console.log(err)})
          }
        });   
     }
     else
     {
      this.toastr.error("Failed! Another account is in use of editing this account.");
     }
    }, err => console.log(err));
  }  

  archiveInstalment(id: any, archive: boolean){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "75vw";
    dialogConfig.data = {
      id: id,
      deleteInstalment: false,
      invoiceID: this.instalmentService.$invoiceID,
      invoiceAmount: this.invoiceAmount,
      archive: archive, 
    };
    
    this.instalmentService.getCheckCanArchiveClient(id).subscribe(response => {
      if (!response)
      {
        const dialogRef = this.dialog.open(ArchiveinstalmentComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          this.instalmentService.cancelCanEditClient(id).subscribe(response => {
            if (response)
            {
              this.toastr.info("Active Account Closed");
            }
          }, err => {console.log(err)})
        });
      }
      else
      {
        this.toastr.error("Failed! Another account is in use of editing this account.");
      }
    }, err => 
    {
      console.log(err) 
    }
    );
  }  

  onChangeArchive(status){
    if (status.target.value === "1")
    {
      this.OnChangeConnection(false);
      this.instalmentService.createHubConnection(this.user, +this.instalmentService.$invoiceID);
    }

    else
    {   
      this.OnChangeConnection(true);
      this.instalmentService.createHubArchiveConnection(this.user, +this.instalmentService.$invoiceID);
    }
  }

  sendInitalizationForInitialMessages(): void {
    const invoiceID: string = this.instalmentService.$invoiceID;
    this.instalmentService.sendInitialInstalmentMessages(invoiceID).subscribe(response => {
      if (response === 0)
      {
        this.toastr.success("You have sent initial instalment messages!");
        location.reload();
        return;
      }

      if (response === 1)
      {
        this.toastr.error("No Accounts were found, or it could be the account is archive, or this account has already been paid!");
        location.reload();
        return;
      }

      if (response === 2)
      {
        this.toastr.error("You cannot send instalments notifications until you have match the total instalment cost with invoice amount!");
        this.showErrorInitialMessage = true;
        return;
      }

      if (response === 3)
      {
        this.toastr.error("Failed to send instalment initial message! One of your instalment accounts for this invoice account has the option disabled for sending out initial message.");
        this.showErrorInitialMessage = true;
        return;
      }

      if (response === 4)
      {
        this.toastr.error("You have already sent out original message for the first sequence to your instalment accounts. You may want to try to reset initial message to this account or to any above.");
        this.showErrorInitialMessage = true;
        return;
      }

      this.toastr.error("Failed to sent out initial messages");      
    }, err => {
      console.log(err);
    })
  }

  private OnChangeConnection(showArchive: boolean)
  {
    this.instalmentService.showarchive = showArchive;
    this.instalmentService.instalmentDataSource = new MatTableDataSource<Instalment>();
    this.instalmentService.stopHubConnection();
    this.instalmentService.stopHubArchiveConnection();
  }

  private startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.instalmentService.instalmentDataSource.paginator = this.paginator;
        this.instalmentService.instalmentDataSource.sort = this.sort;
        this.timeLeft = 10;
        this.presenceHub.pageActive[3] = true;
      }
    },1000)
  }
}
