<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to create a new client account</h2>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets\images\Client\Images\New Client\nc01.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Enter the client’s information</h2>
        <h2>•	* Denotes a mandatory field</h2>
        <h2>•	A client’s “Contact Mobile” is required for the SMS notifications to be sent</h2>
        <h2>•	Ensure that the “Email” field is filled for the billing emails to be sent</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets\images\Client\Images\New Client\nc02.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Click “Create Client Account” to save the account</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets\images\Client\Images\New Client\nc03.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	You have now successfully created a new client</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets\images\Client\Images\New Client\nc04.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	•	The newly created client will appear in the list below the creation form</h2>
    </div>
    <hr>