import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UserGroupsPermissions } from 'src/app/_models/usergrouppermissions';
import { UsergroupspermissionsService } from 'src/app/_services/usergroups/usergroupspermissions.service';

@Component({
  selector: 'app-deleteusergroups',
  templateUrl: './deleteusergroups.component.html',
  styleUrls: ['./deleteusergroups.component.css']
})
export class DeleteusergroupsComponent implements OnInit {
  accountID: number;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public services: UsergroupspermissionsService) { }

  ngOnInit(): void {
    this.accountID = this.data.id;
  }

  deleteAccount(){
    this.services.deleteGroup(this.accountID).subscribe( response => {      
      if (response === true)
      {
        this.data.deleteAccount = true;
        this.services.getUserGroups().subscribe(response => {
          this.services.groupDataSource = new MatTableDataSource<UserGroupsPermissions>(response);
          this.services.groupDataSource.paginator = this.services.groupPaginator;
          this.services.groupDataSource._updateChangeSubscription();
          console.log(this.services.groupDataSource.filteredData);
          
          this.services.groupDataSource.filteredData.forEach(element => {
            this.services.groupNames.push(element.groupName);
          });
    
          if (response.length > 0)
          {
            this.services.showMember = true;
          }
          else
          {
            this.services.showMember = false;
          }
    
        }, err => {
          console.log(err);
        })
      }
    }, error => {
      console.log(error);
    })
  }

}
