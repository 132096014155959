<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to create a store account</h2>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Store Credit/Create Store Account/csa01.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Go to “Store Account” page and scroll down to the “Create Store Account” form – This is where you can create a Store Account</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Store Credit/Create Store Account/csa02.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Enter the payment information as needed </h2>
        <h2>•	“Payment Amount” and “Date Sent Payment“ are mandatory to fill </h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Store Credit/Create Store Account/csa03.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	If the client has credited into the account, select “Store Credit”</h2>
        <h2>•	To take out of the Credited Account, select “Store Debit”</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Store Credit/Create Store Account/csa04.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Click “Create Store Account” to add the Debit/Credit to the Client’s Store Account</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Store Credit/Create Store Account/csa05.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	The “Client Information” will reflect these changes</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Store Credit/Create Store Account/csa06.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	These changes will also appear below in the “List of In-store payments” section near the bottom of the page</h2>
    </div>