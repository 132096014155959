<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to create an invoice account</h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/New Invoice/ni01.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Go to the client’s “Invoice Accounts” page, and scroll down to the “Create Invoice Account” section </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/New Invoice/ni02.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Fill out the Invoice details as needed</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/New Invoice/ni03.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="fw-bold">Under “Send Initial Messages for Payment”: </h2>
    <h2>
        •	Selecting “Yes” will send an SMS and Email to the client with the initial invoice notification
    </h2>
    <h2>
        •	Selecting “No” will not send out the initial invoice notification
    </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/New Invoice/ni04.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="fw-bold">Under “Send Out Due Date Message:” </h2>
    <h2>
        •	Selecting "Yes" will send an SMS and Email message regarding the due date
    </h2>
    <h2>
        •	Selecting "No" will not send out a due date message
    </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/New Invoice/ni05.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="fw-bold">Under “Add transaction fees:” </h2>
    <h2>
        •	Selecting “Yes “ will add 1.75% of the “Invoice Amount”, plus an additional $0.30c to the client’s billing account
    </h2>
    <h2>
        •	Selecting “No” will mean your business will need to cover the 1.75% + $0.30c surcharge
    </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/New Invoice/ni06.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Take care when entering the “Invoice Number” as this cannot be edited later </h2>
  
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/New Invoice/ni07.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Click “Create Invoice Account” to save the invoice and automatically send the initial invoice notifications (if selected)</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/New Invoice/ni08.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	The newly created “Invoice Account” will now appear under the “List of Invoices” section near the bottom of the “Invoices” page</h2>
</div>
