<nav class="navbar navbar-light bg-primary shadow-lg">
    <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Edit Invoice Import Account!</a>  
</nav>

<hr>
<form #editForm="ngForm" id="editForm" [formGroup]="formInvoice" (ngSubmit)="formInvoice.valid" autocomplete="off">
    <div class="bs-component">
        <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
        <button type="button" class="close" data-dismiss="alert">x</button>
        <h4 class="alert-heading">Information!</h4>
        <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice fa-lg"></i> Invoice Details:</h4>
            <app-text-input [formControl]='formInvoice.controls["invoiceNumber"]' [label]='"*Invoice Number"' [smalltext]='""' [type]='"text"' [placeholder]='"Invoice Number"'>  </app-text-input>
            <app-text-input [formControl]='formInvoice.controls["invoiceAmount"]' [label]='"*Invoice Amount"' [smalltext]='"Invoice Amount means the amount invoiced to a Buyer for the Lot with GST included."' [type]='"number"' [placeholder]='"Invoice Amount"'> </app-text-input>
            <app-text-input [formControl]='formInvoice.controls["paidAmount"]' [label]='"*Payment Amount"' [smalltext]='"A sum of money paid"' [type]='"number"' [placeholder]='"Payment Amount"'> </app-text-input>          
            <app-area-text [formControl]='formInvoice.controls["note"]' [label]='"Note"' [smalltext]='""' [placeholder]='"Note"'></app-area-text>
        </div>
        <div class="col-6">
            <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice fa-lg"></i> Invoice Dates:</h4>
            <app-date-input [formControl]='formInvoice.controls["initialDate"]' [label]='"*Initial Date"' [smalltext]='"Initial date sent to the buyer."' [placeholder]='"Initial Date"'> </app-date-input>
            <app-date-input [formControl]='formInvoice.controls["dueDate"]' [label]='"*Due Date"' [smalltext]='"Final date for the payment"' [placeholder]='"Due Date"'> </app-date-input>
            <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice fa-lg"></i> Contact Details:</h4>
            <app-text-input [formControl]='formInvoice.controls["firstName"]' [label]='"*First Name"' [smalltext]='""' [placeholder]='"First Name"'> </app-text-input>
            <app-text-input [formControl]='formInvoice.controls["lastName"]' [label]='"*last Name"' [smalltext]='""' [placeholder]='"Last Name"'> </app-text-input>
            <app-text-input [formControl]='formInvoice.controls["contactMobile"]' [label]='"*Mobile Contact"' [smalltext]='""' [placeholder]='"Contact Mobile Number"'> </app-text-input>
        </div>     
    </div>
</form>
<div class="row">
<div class="col-6">
    <button [disabled]="!formInvoice.valid" mat-button [mat-dialog-close]="data" class="btn btn-success btn-block" form="editForm" (click)="onSubmit()">Confirm Edit Account</button>
</div>
<div class="col-6">
    <button class="btn btn-danger btn-block" mat-button mat-dialog-close>Close</button>
</div>
</div>             