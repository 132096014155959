import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { TwoFactorAuthenticatorModel } from 'src/app/_models/twofactorauthenticatormodel';
import { User } from 'src/app/_models/user';
import { MembersService } from 'src/app/_services/account/members.service';

@Component({
  selector: 'app-hometwofactorauthentication',
  templateUrl: './hometwofactorauthentication.component.html',
  styleUrls: ['./hometwofactorauthentication.component.css']
})
export class HometwofactorauthenticationComponent implements OnInit {

  private uri = new ReplaySubject<TwoFactorAuthenticatorModel>(1);
  user: User;
  uriStatus$ = this.uri.asObservable();
  private enable = new ReplaySubject<boolean>(1);
  enableStatus$ = this.enable.asObservable();
  

  formCode: UntypedFormGroup;
  constructor(private service: MembersService, public fb: UntypedFormBuilder, private toastr: ToastrService) { 

  }

  ngOnInit(): void {
    this.initializeForm();
    this.onLoad();
    this.onLoadEnabled();
  }

  onLoad(){
    this.service.get2fa().subscribe(
      res => {
        this.uri.next(res);
      }
    ,err => {
      console.log(err);
    })
  }

  initializeForm(){
    this.formCode = this.fb.group({
      code:['', [Validators.maxLength(255), Validators.required]],
      password:['',[Validators.required]],
    })
  }


  submitForm(){
    const code = {
      code: this.formCode.value.code,
      password: this.formCode.value.password
    };

    this.service.submitCodeTwoFactorAutentication(code).subscribe(response => {
      if (response)
      {
        this.toastr.success("Two Factor Authenication has been updated!");
        this.enable.next(true);
      }
      else
      {
        this.toastr.error("Failed to register code on the App!");
      }      
    }, err => {
      console.log(err);
    })
  }

  onLoadEnabled(){
    this.service.check2faenabled().subscribe(response => {
      if (response)
      {
        this.enable.next(true);
        console.log(true);
      }
      else
      {
        this.enable.next(false);
        console.log(false);
      }
    }, err => {
      console.log(err);
    })
  }

  disabletwofactorauthencator(){
    this.service.delete2fasetup().subscribe(response => {
      if (response)
      {
        this.toastr.success("Tfa has been removed");
        this.enable.next(false);
      }
      else
      {
        this.toastr.error("Failed to remove 2fa");
      }
    }, err => {
      console.log(err);
    })
  }



}
