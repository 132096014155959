<ngx-spinner>
  <h3 class="text-white">Loading...</h3>
</ngx-spinner>

<router-outlet></router-outlet>
<div class="container py-3" *ngIf="(showFooter.showFooter$ | async) === true">
  <footer class="pt-4 my-md-5 pt-md-5 border-top">
    <div class="row">
      <div class="col-12 col-md">
        <img src="assets/images/Invoice pager_logo.jpg" class="rounded float-left img-thumbnail" alt="Business Logo">
        <mat-card-title class="RalewayMainLogo text-black" routerLink="#">Invoice Pager</mat-card-title>  
        <small class="d-block mb-3 text-muted">© 2022–2023</small>
      </div>
      <div class="col-6 col-md">
        <h5 class="font-weight-bold">Our Product</h5>
        <ul class="list-unstyled text-small">
          <li class="mb-1"><a class="link-secondary text-decoration-none" routerLink="/features">See Our features of Invoice Pager</a></li>
          <li class="mb-1"><a class="link-secondary text-decoration-none" routerLink="/forwardpay">What is Forward Pay?</a></li>
          <li class="mb-1"><a class="link-secondary text-decoration-none" routerLink="/paylater">What is Pay Later</a></li>
          <li class="mb-1"><a class="link-secondary text-decoration-none" routerLink="/Instalments">Learn about our Instalments works</a></li>
        </ul>
      </div>
      <div class="col-6 col-md">
        <h5 class="font-weight-bold">Resources</h5>
        <ul class="list-unstyled text-small">
          <li class="mb-1"><a class="link-secondary text-decoration-none" routerLink="/termandconditions">Terms & Conditions</a></li>
          <li class="mb-1"><a class="link-secondary text-decoration-none" routerLink="/privacypolicy">Privacy Policy</a></li>
          <li class="mb-1"><a class="link-secondary text-decoration-none" routerLink="/creditartwork">Credit Art Work</a></li>
        </ul>
      </div>
    </div>
  </footer>
</div>

