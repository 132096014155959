import { Component, Input, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.css']
})
export class RadioButtonComponent implements ControlValueAccessor {
  @Input() name: string = '';
  @Input() value: string = '';
  @Input() displayname: string = '';
  @Input() checkedvalue: any;
  @Input() doyouhavetick: string = '';

  constructor(@Self() public ngControl: NgControl ) { 
    this.ngControl.valueAccessor = this;
  }

  writeValue(obj: any): void {

  }
  registerOnChange(fn: any): void {

  }
  registerOnTouched(fn: any): void {

  }

}
