import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { User } from './_models/user';
import { AccountService } from './_services/account/account.service';
import { ActivatedRoute } from '@angular/router';
import { HtmlfooterService } from './_services/htmlfooter.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'client';
  users: any;
  constructor(private http: HttpClient, private accountService: AccountService, public showFooter: HtmlfooterService){
    
  }

  setCurrentUser(){
    const user: User = JSON.parse(localStorage.getItem('user'));
    this.accountService.setCurrentUser(user);
  }

  ngOnInit(): void {
    //this.getUser();
    this.setCurrentUser();
  }

  getUser(){
    this.http.get('https://localhost:44325/api/Users').subscribe(
      response => {
        this.users = response;
      },
      error => {
        console.log(error);
      }
    );
  }
}
