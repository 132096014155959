<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to go to the store debit/credit page</h2>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Store Credit/Open Store Account/ipsfa01.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Go to the “Clients” page</h2>
        <h2>•	Then scroll down to the bottom of the page to the “Clients List” section</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Store Credit/Open Store Account/ipsfa02.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Click the drop down arrow beside the “Client” </h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Store Credit/Open Store Account/ipsfa03.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	In the menu, click “Open Store Debit/Credit Accounts"</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Store Credit/Open Store Account/ipsfa04.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Now you can see an overview of the “Client Store Account Information”</h2>
        <h2>•	If you scroll down, you will see a list of all “In-store Payments”</h2>
    </div>