import { Component, OnInit } from '@angular/core';
import { MessageSent } from 'src/app/_models/messagesent';
import { Pagination } from 'src/app/_models/pagination';
import { MessagesService } from 'src/app/_services/message/messages.service';
import { MessagesentService } from 'src/app/_services/messageSent/messagesent.service';

@Component({
  selector: 'app-messageslist',
  templateUrl: './messageslist.component.html',
  styleUrls: ['./messageslist.component.css']
})
export class MessageslistComponent implements OnInit {

  constructor(private service: MessagesentService, private messageService: MessagesService) { }
  pagination: Pagination;
  pageNumber = 1;
  pageSize = 5;
  messageSent: MessageSent[];

  ngOnInit(): void {
    this.GetMessageSent();

  }

  private GetMessageSent(){
    this.service.getReceiveEmailMessage(this.pageNumber, this.pageSize).subscribe(response => {
      this.messageSent = response.result;
      this.pagination = response.pagination;
      this.removeMessage();
    }, error => {
      console.log(error);
    });
  }

  pageChanged(event: any){
    this.pageNumber = event.page;
    this.GetMessageSent();
  }

  removeMessage(){
    var index: number = 0;
    this.messageSent.forEach(message => {
      this.messageSent[index].message = this.messageService.removeHTMLTags(message.message);
      index = index + 1;
    });
  }

}
