import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppUserPermission } from 'src/app/_models/appuserpermission';
import { ClientProductSubscription } from 'src/app/_models/clientproductsubscription';
import { ClientSubscripton } from 'src/app/_models/clientsubscription';
import { Currentaccountsubscriptioninfo } from 'src/app/_models/currentaccountsubscriptioninfo';
import { environment } from 'src/environments/environment';

declare const Stripe;

@Injectable({
  providedIn: 'root'
})
export class ClientSubscriptionService {
  baseUrl =  environment.apiUrl;
  constructor(private http: HttpClient) { }

  getProducts(){
    return this.http.get<ClientProductSubscription[]>(this.baseUrl + 'clientsubscription/getproducts').pipe(
      map(response => {
        return response;
      })
    )
  }

  purchaseProduct(priceID: string, publicToken: string){
    const token = {
      priceID: priceID,
    }
    return this.http.post<any>(this.baseUrl + 'clientsubscription/getproduct', token).pipe(
      map(response => {
          this.redirectToCheckout(response.sessionId, publicToken);
      })
    )
  }

  redirectToCheckout(sessionId: string, publicToken: string){
    const stripe = Stripe(publicToken);
    stripe.redirectToCheckout({
      sessionId: sessionId,
    })
  } 

  createSubscription(id: string){
    const token = {
      token: id,
    }
    return this.http.post<any>(this.baseUrl + 'clientsubscription/createsubscription', token).pipe(
      map(response => {
        return response;
      })
    )
  }

  getPurchaseCheckProduct(id: string){
    const token ={
      sessionID: id,
    }
    return this.http.post<any>(this.baseUrl + 'clientsubscription/createsubscription', token).pipe(
      map(response => {
        return response;
      })
    )
  }

  getUserBillSubscriptions(){
    return this.http.get<ClientSubscripton[]>(this.baseUrl + 'clientsubscription/GetUserBillSubscriptions').pipe(
      map(response => {
        return response;
      })
    )
  }

  getCurrentPermissions(){
    return this.http.get<AppUserPermission>(this.baseUrl + 'clientsubscription/getPermissionscurrentsubscriptionbill').pipe(
      map(response => {
        return response;
      })
    )
  }

  cancelCurrentMembership(){
    return this.http.get<AppUserPermission>(this.baseUrl + 'clientsubscription/cancelmembership').pipe(
      map(response => {
        return response;
      })
    )
  }

  onLoadUserSubscription(){
    return this.http.get<Currentaccountsubscriptioninfo>(this.baseUrl + 'clientsubscription/getcurrentsubscription').pipe(
      map(response => {
        return response;
      })
    )
  }

  getPrintSubscription(id: number){
    return this.http.get<ClientSubscripton>(this.baseUrl + 'clientsubscription/getprintsubscription?accountid=' + id ).pipe(
      map(response => {
        return response;
      })
    )
  }


}
