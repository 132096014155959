<app-nav></app-nav>
<div class="container">
    <div class="row">
        <div class="col-12 mt-4">
          <nav class="navbar navbar-light bg-primary shadow-lg">
            <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Import Xero API</a>
          </nav>
        </div>
    </div>
    <div class="card border-info mb-3" *ngIf="!isitreadyAPI">  
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xxl-12 mb-5">
              <div class="card bg-white h-100 shadow-lg">
                  <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                      <img src="/assets/images/sorry.jpg" alt="SMS" class="img-thumbnail">                
                  </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xxl-12 mb-5">
              <h1 class="card-title text-primary"><b>Sorry!</b> The API application isn't ready as of yet.</h1>
              <p class="card-text">Please stay tuned for future updates.</p>
            </div>
          </div>
        </div>
    </div>
    <div class="card border-info mb-3 mt-4" *ngIf="userCanUse && isitreadyAPI">
        <div class="card-body">
          <h1 class="card-title">Import Xero API Clients or Invoice</h1>
          <p class="card-text">Get the quickest way to import your data into our cloud services. This will go to another APP that will provide the services you need to import your data.</p><button class="btn btn-success" (click)="importXeroAPI()">Import Xero API</button>
        </div>
      </div>
      <div class="card border-info mb-3" *ngIf="!userCanUse && isitreadyAPI">
        <div class="card-body">
          <h1 class="card-title">Import Xero API Clients or using Invoice API requires permissions first</h1>
          <p class="card-text">You will need to upgrade your plans before you can use this feature. Sorry about this!</p><button class="btn btn-success" routerLink="/members/subscriptions">Upgrade My Plan Now</button>
        </div>
      </div>
</div>
