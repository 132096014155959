import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { StripeAllTransactionOneClient } from 'src/app/_models/stripealltransactionsoneclient';
import { AccountService } from 'src/app/_services/account/account.service';
import { StripeaccountService } from 'src/app/_services/stripeaccount/stripeaccount.service';

@Component({
  selector: 'app-stripeaccountsalltransactions',
  templateUrl: './stripeaccountsalltransactions.component.html',
  styleUrls: ['./stripeaccountsalltransactions.component.css']
})
export class StripeaccountsalltransactionsComponent implements OnInit {

  stripeDataSource: MatTableDataSource<StripeAllTransactionOneClient>
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['invoiceNumber', 'name', 'firstName', 'lastName', 'fees','amount', 'isRefund', 'datePaid'];
  constructor(public services: StripeaccountService, public accountService: AccountService) { 
    this.onLoad();
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  onLoad(){
    this.services.getStripeAllTransaction().subscribe(response => {
      console.log(response);
      this.stripeDataSource = new MatTableDataSource<StripeAllTransactionOneClient>(response);
      this.stripeDataSource.paginator = this.paginator;
      this.stripeDataSource.sort = this.sort;
    }, err => {
      console.log(err);
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.stripeDataSource.filter = filterValue.trim().toLowerCase();

    if (this.stripeDataSource.paginator) {
      this.stripeDataSource.paginator.firstPage();
    }
  }

}
