import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';  
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { Invoice } from '../_models/invoice';
import { InvoiceService } from '../_services/invoice/invoice.service';
    
/* Doesn't work - Still in play testing */
export class FullStopValidator {  
    static cannotContainFullStop(control: AbstractControl) : ValidationErrors | null {  
        if((control.value as string).indexOf('.') >= 0){  
            return {cannotContainFullStop: true}  
        }  
    
        return null;  
    }    
}  