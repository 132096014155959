<ng-container *ngIf="accountService.currentUser$ | async as user">
  <nav class="navbar navbar-light bg-primary shadow-lg mt-4">
    <a class="navbar-brand text-white font-weight-bold mt-4" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Accounts - Cash Flow Payments</a>  
    <button type="button" class="btn btn-outline-light btn-lg mt-2 mt-md-0" (click)="toggle.toggleWindow(37)"><i class="{{toggle.$Icon[37]}}" aria-hidden="true\"></i> {{toggle.$ToggleText[37]}}</button>
  </nav>
  <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded" [hidden]="!toggle.$Toggle[37]">
    <div class="card-body">
      <fieldset class="form-group">           
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
            <img src="/assets/images/profile-account.jpg" alt="profile account" class="img-thumbnail rounded center">
              <legend class="text-center fs-3">How to create a <b>Payment Account</b></legend>
            <div class="form-group">                      
              <small id="fileHelp" class="form-text text-muted text-center fs-6">
                In order to create a payment account, you must have an existing invoice account under the same client. Click the button below to learn more!
              </small>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
            <legend class="text-center fs-3 text-uppercase text-primary"><b>HOW TO USE THIS!</b></legend>
            <div class="embed-responsive embed-responsive-1by1 sizer shadow-lg border mt-4">
              <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/bblMPldXOow?si=GEFKnn8SFYQxVYvo" allowfullscreen></iframe>
            </div>
          </div>
        </div>              
        <button type="submit" class="btn btn-outline-primary btn-lg btn-block mt-4" routerLink="/members/turtorialhome"><i class="fas fa-info"></i> Click Here To Find Out More!</button>
      </fieldset>
    </div>
  </div>
  <div class="col-12 mt-4">
    <h1 class="text-center text-primary mt-2 font-weight-bold"><i class="fas fa-coins fa-lg"></i> {{titleText}}</h1>
  </div>  
  <div class="bs-component mt-4">
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary rounded ">
      <div class="container-fluid">
        <a class="navbar-brand"><i class="fas fa-search fa-lg"></i></a>
        <a class="navbar-brand text-white font-weight-bold" > Search Features</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarColor01">
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <ul class="navbar-nav me-auto">
                <li class="nav-item">
                  <button [class]="buttonClass" (click)="toggleButtonPayments()">{{showText}}</button>
                </li>    
              </ul>
            </li>
          </ul>
          <form class="d-flex" *ngIf="!toggleCashflowBtn">
            <input class="form-control me-sm-2" type="search" placeholder="Search" (keyup)="applyFilter($event)">
          </form>
        </div>
      </div>
    </nav>
  </div>
      <div class="mat-elevation-z8 mt-2" *ngIf="(receivablesaccountspaymentService.allPayments$ | async) && !toggleCashflowBtn">
          <table mat-table [dataSource]="receivablesaccountspaymentService.accountsDataSource$" multiTemplateDataRows matSort>                 
            <!-- Invoice Column -->
            <ng-container matColumnDef="invoice">
              <th mat-header-cell *matHeaderCellDef class="text-right" mat-sort-header>
                <ng-container *ngIf="user.typeOfService; else showInvoiceNumber ">
                  Billing No.
                </ng-container>
                <ng-template #showInvoiceNumber>
                  Invoice No.
                </ng-template>    
              </th>
              <td mat-cell *matCellDef="let account"> 
                  <p class="text-primary"><b>{{account.invoiceNumber}}</b></p> 
              </td>
            </ng-container>
        
            <!-- first name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef class="text-center" mat-sort-header>Name </th>
              <td mat-cell *matCellDef="let account"> 
                  <p class="font-weight-bold">{{account.name | titlecase }}</p>
              
              </td>
            </ng-container>

              <!-- Contact Mobile Column -->
              <ng-container matColumnDef="paymentMethod">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Method</th>
                  <td mat-cell *matCellDef="let account">
                      <p>
                      <ng-container *ngIf="account.paymentOption === 0"><span class="badge bg-success">Account Paid</span></ng-container> 
                      <ng-container *ngIf="account.paymentOption === 1"><span class="badge bg-warning">Account Refunded </span></ng-container> 
                      <ng-container *ngIf="account.paymentOption === 2"><span class="badge bg-primary">Store Credit</span></ng-container>
                      <ng-container *ngIf="account.paymentOption === 3"><span class="badge bg-danger">Unknown</span></ng-container>  
                      </p>
                  </td>
                </ng-container>
                

              <!-- Stripe Connected -->
              <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef class="text-left" mat-sort-header>Amount Paid</th>
                  <td mat-cell *matCellDef="let account">
                      <p class="font-weight-bold text-success">{{account.accountPayment | currency }}</p>
                  </td>
                </ng-container>

                  <!-- Address Column -->
              <ng-container matColumnDef="paymentType">
                  <th mat-header-cell *matHeaderCellDef class="text-center" mat-sort-header>Payment Type</th>
                  <td mat-cell *matCellDef="let account">
                    <ng-container *ngIf="account.paymentOption === 2; else showdefaultpayment">
                      <span class="badge bg-primary">Special</span>                    
                    </ng-container>
                    <ng-template #showdefaultpayment><span class="badge bg-success">{{account.paymentType}}</span></ng-template>             
                  </td>
                </ng-container>

               <!-- Address Column -->
              <ng-container matColumnDef="datePaid">
                <th mat-header-cell *matHeaderCellDef class="text-center" mat-sort-header>Date Paid</th>
                <td mat-cell *matCellDef="let account">
                    <p class="">{{account.date | date: 'mediumDate'  }}</p>
                </td>
              </ng-container>

              <!-- Stripe Connected -->
              <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="text-left"> Actions </th>
                  <td mat-cell *matCellDef="let account">
                    <button mat-mini-fab color="primary" [routerLink]="['/members/invoices/', account.clientID]">
                      <mat-icon>account_balance</mat-icon>
                    </button>  
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            
              <mat-paginator [pageSizeOptions]="[10000]" showFirstLastButtons></mat-paginator>
        </div>
        <div class="mat-elevation-z8 mt-2" *ngIf="toggleCashflowBtn">
          <table mat-table [dataSource]="accountsDataSource" multiTemplateDataRows matSort>                 
            <!-- Invoice Column -->
            <ng-container matColumnDef="invoice">
              <th mat-header-cell *matHeaderCellDef class="text-right" mat-sort-header>
                <ng-container *ngIf="user.typeOfService; else showInvoiceNumber ">
                  Billing No.
                </ng-container>
                <ng-template #showInvoiceNumber>
                  Invoice No.
                </ng-template>    
              </th>
              <td mat-cell *matCellDef="let account"> 
                  <p class="text-primary"><b>{{account.invoiceNumber}}</b></p> 
              </td>
            </ng-container>
        
            <!-- first name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef class="text-center" mat-sort-header>Name </th>
              <td mat-cell *matCellDef="let account"> 
                  <p class="font-weight-bold">{{account.name | titlecase }}</p>
              
              </td>
            </ng-container>

              <!-- Contact Mobile Column -->
              <ng-container matColumnDef="paymentMethod">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Method</th>
                  <td mat-cell *matCellDef="let account">
                      <p>
                      <ng-container *ngIf="account.paymentOption === 0"><span class="badge bg-success">Account Paid</span></ng-container> 
                      <ng-container *ngIf="account.paymentOption === 1"><span class="badge bg-warning">Account Refunded </span></ng-container> 
                      <ng-container *ngIf="account.paymentOption === 2"><span class="badge bg-primary">Store Credit</span></ng-container>
                      <ng-container *ngIf="account.paymentOption === 3"><span class="badge bg-danger">Unknown</span></ng-container>  
                      </p>
                  </td>
                </ng-container>
                

              <!-- Stripe Connected -->
              <ng-container matColumnDef="amount">
                  <th mat-header-cell *matHeaderCellDef class="text-left" mat-sort-header>Amount Paid</th>
                  <td mat-cell *matCellDef="let account">
                      <p class="font-weight-bold text-success">{{account.accountPayment | currency }}</p>
                  </td>
                </ng-container>

                  <!-- Address Column -->
              <ng-container matColumnDef="paymentType">
                  <th mat-header-cell *matHeaderCellDef class="text-center" mat-sort-header>Payment Type</th>
                  <td mat-cell *matCellDef="let account">
                    <ng-container *ngIf="account.paymentOption === 2; else showdefaultpayment">
                      <span class="badge bg-primary">Special</span>                    
                    </ng-container>
                    <ng-template #showdefaultpayment><span class="badge bg-success">{{account.paymentType}}</span></ng-template>             
                  </td>
                </ng-container>

               <!-- Address Column -->
              <ng-container matColumnDef="datePaid">
                <th mat-header-cell *matHeaderCellDef class="text-center" mat-sort-header>Date Paid</th>
                <td mat-cell *matCellDef="let account">
                    <p class="">{{account.date | date: 'mediumDate'  }}</p>
                </td>
              </ng-container>

              <!-- Stripe Connected -->
              <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="text-left"> Actions </th>
                  <td mat-cell *matCellDef="let account">
                    <button mat-mini-fab color="primary" [routerLink]="['/members/invoices/', account.clientID]">
                      <mat-icon>account_balance</mat-icon>
                    </button>  
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            
              <mat-paginator [pageSizeOptions]="[10000]" showFirstLastButtons></mat-paginator>
        </div>
  <div class="mt-3"></div>
</ng-container>
