<mat-dialog-content>
<form #editForm="ngForm" id="formEdit" [formGroup]="formEmail" (ngSubmit)="formEmail.valid" autocomplete="off">
    <div class="bs-component">
        <div class="alert alert-dismissible alert-primary shadow-lg rounded-3 text-center">
            <strong class="text-white fs-6">If you want to test out receive email looks like. Just enter some fields here and just click on submit when you are done!</strong> 
        </div>        
    </div>
    <div class="bs-component">
    <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
    <button type="button" class="close" data-dismiss="alert">x</button>
    <h4 class="alert-heading">Information!</h4>
    <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
    </div>
</div>
<div class="row">
    <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> Test Fields Required For Due Date!</h4>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">      
        <app-text-input [formControl]='formEmail.controls["Name"]' [label]='"*Full Name"' [smalltext]='"Contains Full Name"' [placeholder]='"Full Name"'> </app-text-input>
        <app-text-input [formControl]='formEmail.controls["EmailTo"]' [label]='"*Your Email"' [smalltext]='"Use Your Email To Test It"' [placeholder]='"Your Email"'> </app-text-input>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
        <app-date-input [formControl]='formEmail.controls["dueDate"]' [label]='"*Due Date"' [smalltext]='"Final date for the payment"' [placeholder]='"Due Date"'> </app-date-input>
        <app-text-input [formControl]='formEmail.controls["InvoiceAmount"]' [label]='"*Invoice Amount"' [smalltext]='""' [placeholder]='"Invoice Amount"' [type]='"number"'> </app-text-input>    
    </div>
</div>
</form>
<div class="row">
<div class="col-6">
    <button [disabled]="!formEmail.valid" mat-button [mat-dialog-close]="data" class="btn btn-success btn-block" form="editForm" (click)="onSubmit()">Send Email Message</button>
</div>
<div class="col-6">
    <button class="btn btn-danger btn-block" mat-button mat-dialog-close>Close</button>
</div>
</div>             
</mat-dialog-content>