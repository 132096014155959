<app-nav></app-nav>
<div class="container mt-4">
    <div class="row justify-content-md-center">
        <div class="col-sm-12 col-md-4  py-5 bg-white text-black text-center border shadow-lg rounded">
            <div class=" ">
                <div class="card-body">
                    <img src="/assets/images/edit.jpg" class="img-thumbnail">
                    <h2 class="py-3">Contact Us</h2>
                    <p>
                       Contact us here!
                    </p>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-8 mt-2 ">
            <div class="card mt-4 border-info">
                <div class="card-body">
                  <form #editForm="ngForm" [formGroup]="contactForm" (ngSubmit)="contactForm.valid" autocomplete="off" id="editForm">
                    <h2 class="text-center text-primary mt-2"> Contact Us</h2>
                    <div class="form-group">
                        <label class="form-label mt-4">Select Criteria</label>
                        <select multiple="" class="form-select" formControlName="typemessage">
                          <option value="General Enquiries">General Enquiries</option>
                          <option value="Bug Reports">Bug Reports</option>
                          <option value="I Need Help (On Something)">I Need Help (On Something)</option>
                          <option value="My clients is having issues with the payments system">My clients is having issues with the payments system</option>
                          <option value="Help me register Stripe Account">Help me register Stripe Account</option>
                          <option value="I can't create a client account! Help me.">I can't create a client account! Help me.</option>
                          <option value="My sms sending isn't working properly">My SMS's isn't working properly</option>
                        </select>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-sm-12" *ngIf="showExtraEmail">
                            <app-text-input [formControl]='contactForm.controls["email"]' [label]='"*Email"' [smalltext]='""' [type]='"email"'> </app-text-input>    
                            <app-text-input [formControl]='contactForm.controls["firstname"]' [label]='"*First name"' [smalltext]='""' [type]='"text"'> </app-text-input>
                        </div>
                        <div class="col-md-6 col-sm-12" *ngIf="showExtraEmail">
                            <app-text-input [formControl]='contactForm.controls["lastname"]' [label]='"*Last name"' [smalltext]='""' [type]='"text"'> </app-text-input>  
                            <app-text-input [formControl]='contactForm.controls["phone"]' [label]='"*Mobile Contact"' [smalltext]='""' [type]='"phone"'> </app-text-input>
                        </div>
                    </div>              
                    <app-area-text [formControl]='contactForm.controls["message"]' [label]='"*Message"' [smalltext]='""'> </app-area-text>
                </form>
                </div>
                <div class="card-footer text-muted">
                    <button [disabled]="!contactForm.valid || !contactForm.dirty" class="btn btn-success btn-block" form="editForm" (click)="sendEmail()" >Send Message</button>
                </div>
              </div>
        </div>
    </div>
  </div>
  