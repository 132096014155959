import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';  
    
/* Doesn't work - Still in play testing */
export class ABNValidation {  
    static checkABNValidation(control: AbstractControl) : ValidationErrors | null {  
        if (control.value === "")
        {
            return null;
        }

        const values: number[] = [19, 17, 15, 13, 11, 9, 7, 5, 3, 1, 10];       
        var total: number = 0; 
        var temp: number = +control.value;
        var remainder: number = 0;
        console.log('control:' + +control.value + 'total: ' + total  + ' temp: ' + temp + ' remainder: ' + remainder);
        values.forEach(value => {
            if (value === 10)
            {
                temp = temp - 1;
            }
            remainder = temp % 10;
            temp = Math.floor(temp/10);
            total += remainder * value;
            console.log('total: ' + total  + ' temp: ' + temp + ' remainder: ' + remainder);
        });

        console.log('result ABN = ' + total % 89);

        if (total % 89 === 0)
        {
            return null;
        }
    
        return {checkABNValidation: true};
    }    
}  