<div class="form-check">
    <input class="form-check-input" type="radio" name="{{name}}" value="{{value}}" [formControl]="ngControl.control" [checked]="true">
    <label class="form-check-label fw-bold">
    {{checkedvalue}}
    <ng-container *ngIf="doyouhavetick==='1'">
        <i class="fas fa-check text-success"></i>
    </ng-container> 
    <ng-container *ngIf="doyouhavetick==='2'">
        <i class="fas fa-close text-danger"></i>
    </ng-container> 

    {{displayname}}
    </label>
    <div *ngIf="ngControl.control.errors?.required" class="invalid-feedback"> Please enter a {{displayname}}</div>
</div>