<app-nav></app-nav>
<div class="container mt-4">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <!-- Just an narbar title -->
          <nav class="navbar navbar-light bg-primary shadow-lg">
            <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> User Groups</a>
          </nav>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]= "['/members/usergroups']">usergroups</a></li>
            <li class="breadcrumb-item active">
              Edit User Groups 
            </li>
          </ol>
        </div>
        <ng-container *ngIf="membergroup">
            <div class="col-12">
                <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded">
                    <div class="card-body"> 
                    <h2 class="text-center text-primary mt-2">Edit USER GROUP Permissions</h2>
                    <hr>
                    <form #editForm="ngForm" id="editForm" [formGroup]="userMemberGroup" (ngSubmit)="userMemberGroup.valid" autocomplete="off">     
                        <div class="row">                     
                        <div class="bs-component">
                            <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
                                <button type="button" class="close" data-dismiss="alert">×</button>
                                <h4 class="alert-heading">Information!</h4>
                                <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
                            </div>             
                        </div>
                        <div class="col-12">
                            <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i>  Edit group name:</h4>
                            <app-text-input [formControl]='userMemberGroup.controls["groupname"]' [label]='"*Group Name"' [smalltext]='""' [placeholder]='"Group Name"'> </app-text-input>  
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                            <h4 class ="card-title bg-dark text-white text-center"><i class="fas fa-star"></i> Read Permissions:</h4>
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to read Clients accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["readclients"]' [value]='"true"' [displayname]="'Yes'" [name]="'readclients'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["readclients"]' [value]='"false"' [displayname]="'No'" [name]="'readclients'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset> 
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to read Invoices accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["readinvoices"]' [value]='"true"' [displayname]="'Yes'" [name]="'readinvoices'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["readinvoices"]' [value]='"false"' [displayname]="'No'" [name]="'readinvoices'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>                
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to read Instalment accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["readinstalments"]' [value]='"true"' [displayname]="'Yes'" [name]="'readinstalments'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["readinstalments"]' [value]='"false"' [displayname]="'No'" [name]="'readinstalments'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>                
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to read Payments Accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["readaccountpayment"]' [value]='"true"' [displayname]="'Yes'" [name]="'readaccountpayment'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["readaccountpayment"]' [value]='"false"' [displayname]="'No'" [name]="'readaccountpayment'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>                                 
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to read Store Credit Accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["readstorecredit"]' [value]='"true"' [displayname]="'Yes'" [name]="'readstorecredit'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["readstorecredit"]' [value]='"false"' [displayname]="'No'" [name]="'readstorecredit'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>                
                        </div>     
                        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                            <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i>  Write Permissions:</h4>
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to write Clients accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["writeclients"]' [value]='"true"' [displayname]="'Yes'" [name]="'writeclients'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["writeclients"]' [value]='"false"' [displayname]="'No'" [name]="'writeclients'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>   
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to write Invoices accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["writeinvoices"]' [value]='"true"' [displayname]="'Yes'" [name]="'writeinvoices'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["writeinvoices"]' [value]='"false"' [displayname]="'No'" [name]="'writeinvoices'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>   
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to write Instalments accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["writeinstalments"]' [value]='"true"' [displayname]="'Yes'" [name]="'writeinstalments'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["writeinstalments"]' [value]='"false"' [displayname]="'No'" [name]="'writeinstalments'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>   
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to write Payment accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["writeaccountpayment"]' [value]='"true"' [displayname]="'Yes'" [name]="'writeaccountpayment'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["writeaccountpayment"]' [value]='"false"' [displayname]="'No'" [name]="'writeaccountpayment'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>   
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to write Store Credit accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["writestoreaccount"]' [value]='"true"' [displayname]="'Yes'" [name]="'writestoreaccount'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["writestoreaccount"]' [value]='"false"' [displayname]="'No'" [name]="'writestoreaccount'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>   
                        </div>  
                        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                            <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i>  Delete Permissions:</h4>
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to Delete Clients accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["candeleteclient"]' [value]='"true"' [displayname]="'Yes'" [name]="'candeleteclient'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["candeleteclient"]' [value]='"false"' [displayname]="'No'" [name]="'candeleteclient'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>   
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to Delete Invoices accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["candeleteinvoice"]' [value]='"true"' [displayname]="'Yes'" [name]="'candeleteinvoice'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["candeleteinvoice"]' [value]='"false"' [displayname]="'No'" [name]="'candeleteinvoice'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>   
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to Delete Instalments accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["candeleteinstalment"]' [value]='"true"' [displayname]="'Yes'" [name]="'candeleteinstalment'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["candeleteinstalment"]' [value]='"false"' [displayname]="'No'" [name]="'candeleteinstalment'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>   
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to Delete Payment accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["candeletepayment"]' [value]='"true"' [displayname]="'Yes'" [name]="'candeletepayment'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["candeletepayment"]' [value]='"false"' [displayname]="'No'" [name]="'candeletepayment'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>   
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to Delete Store Credit Accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["candeletestore"]' [value]='"true"' [displayname]="'Yes'" [name]="'candeletestore'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["candeletestore"]' [value]='"false"' [displayname]="'No'" [name]="'candeletestore'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>   
                        </div> 
                        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                            <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i>  Archive Permissions:</h4>
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to Archive Clients accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["canarchiveclient"]' [value]='"true"' [displayname]="'Yes'" [name]="'canarchiveclient'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["canarchiveclient"]' [value]='"false"' [displayname]="'No'" [name]="'canarchiveclient'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>   
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to Archive Invoices accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["canarchiveinvoice"]' [value]='"true"' [displayname]="'Yes'" [name]="'canarchiveinvoice'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["canarchiveinvoice"]' [value]='"false"' [displayname]="'No'" [name]="'canarchiveinvoice'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>   
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to Archive Instalments accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["canarchiveinstalment"]' [value]='"true"' [displayname]="'Yes'" [name]="'canarchiveinstalment'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["canarchiveinstalment"]' [value]='"false"' [displayname]="'No'" [name]="'canarchiveinstalment'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>   
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to Archive Payment accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["canarchivepayment"]' [value]='"true"' [displayname]="'Yes'" [name]="'canarchivepayment'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["canarchivepayment"]' [value]='"false"' [displayname]="'No'" [name]="'canarchivepayment'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>     
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to Archive Store accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["canarchivestore"]' [value]='"true"' [displayname]="'Yes'" [name]="'canarchivestore'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["canarchivestore"]' [value]='"false"' [displayname]="'No'" [name]="'canarchivestore'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>   
                        </div> 
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i>  General Controls:</h4>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                            <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i>  Stripe Permissions:</h4>
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                    <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to read Stripe Payments accounts:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["readstripeaccount"]' [value]='"true"' [displayname]="'Yes'" [name]="'readstripeaccount'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["readstripeaccount"]' [value]='"false"' [displayname]="'No'" [name]="'readstripeaccount'" [doyouhavetick]="'2'"></app-radio-button>
                                    </div>
                                </div>                         
                            </fieldset>  
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to Refund Stripe Payments:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["refundstripepayment"]' [value]='"true"' [displayname]="'Yes'" [name]="'refundstripepayment'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["refundstripepayment"]' [value]='"false"' [displayname]="'No'" [name]="'refundstripepayment'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset> 
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to Update Status on Stripe:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["updatestatusstripe"]' [value]='"true"' [displayname]="'Yes'" [name]="'updatestatusstripe'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["updatestatusstripe"]' [value]='"false"' [displayname]="'No'" [name]="'updatestatusstripe'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset> 
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                            <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i>  Message Controls:</h4>
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to use Messages:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["canusemessages"]' [value]='"true"' [displayname]="'Yes'" [name]="'canusemessages'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["canusemessages"]' [value]='"false"' [displayname]="'No'" [name]="'canusemessages'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                            <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i>  Import and API Controls:</h4>
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to use Imports:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["canuserimport"]' [value]='"true"' [displayname]="'Yes'" [name]="'canuserimport'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["canuserimport"]' [value]='"false"' [displayname]="'No'" [name]="'canuserimport'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset> 
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-light text-black text-center"><i class="fas fa-star"></i> Permission to use API:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["canuserapi"]' [value]='"true"' [displayname]="'Yes'" [name]="'canuserapi'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["canuserapi"]' [value]='"false"' [displayname]="'No'" [name]="'canuserapi'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset>  
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                            <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i>  Admin Controls:</h4>
                            <fieldset class="form-group">
                                <div class="card border-secondary mb-3">
                                <div class="card-body">
                                    <h4 class ="card-title bg-danger text-white text-center"><i class="fas fa-star"></i> Ability to add & remove members:</h4>
                                    <app-radio-button [formControl]='userMemberGroup.controls["isadmin"]' [value]='"true"' [displayname]="'Yes'" [name]="'isadmin'" [doyouhavetick]="'1'"></app-radio-button>
                                    <app-radio-button [formControl]='userMemberGroup.controls["isadmin"]' [value]='"false"' [displayname]="'No'" [name]="'isadmin'" [doyouhavetick]="'2'"></app-radio-button>
                                </div>
                                </div>                         
                            </fieldset> 
                        </div>
                     </div>
                    </form>
                    </div>
                    <div class="card-footer text-muted">
                        <div class="col-12">
                            <button [disabled]="!userMemberGroup.valid" class="btn btn-success btn-block" form="editForm" (click)="updateUserMember()" ><i class="fas fa-user"></i>UPDATE USER GROUP</button>
                            <button class="bnt btn-danger btn-block" [routerLink]= "['/members/usergroups']"><i class="fas fa-circle-xmark"></i> Cancel</button>
                        </div>
                        <div class="col-12">

                        </div>              
                    </div>
                </div>
            </div>
        </ng-container>
      </div>
    </div>
</div>
        