<mat-dialog-content>
    <h4 class="bg-danger text-white text-center">Before moving forward, consider resetting the initial message associated with this account. Resetting is possible only if your client hasn't deposited funds into this account or has attempted payment via the Stripe account page. If the account is locked due to these actions, resetting the initial message won't be feasible. Are you sure you want to proceed?</h4>
    <h4 class="text-center">Are you sure you want to <b>RESET INITIAL MESSAGE</b> to this invoice account?</h4>    
<div class="row mt-4">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
        <button type="button" class="btn btn-danger btn-block" mat-button [mat-dialog-close]="data" (click)="resetInitialMessage()"><i class='fas fa-refresh'></i> RESET INITIAL MESSAGE TO INVOICE ACCOUNT</button>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
        <button type="button" class="btn btn-primary btn-block" mat-button mat-dialog-close><i class='fas fa-close'></i> Cancel</button>
    </div>
</div>
</mat-dialog-content>