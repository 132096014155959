import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorialhowtoviewstoreaccounts',
  templateUrl: './tutorialhowtoviewstoreaccounts.component.html',
  styleUrls: ['./tutorialhowtoviewstoreaccounts.component.css']
})
export class TutorialhowtoviewstoreaccountsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
