<div class="form-group">
    <label [class]="classInput">{{label}}</label>
    <input
    [class.is-invalid]='ngControl.touched && ngControl.invalid'
    [class.is-valid]='ngControl.valid'
    type={{type}}
    class="form-control rounded-3"
    [formControl]="ngControl.control"    
    placeholder={{placeholder}}>

    <div *ngIf="ngControl.control.errors?.required" class="invalid-feedback">Requires you to enter {{label}}!</div>

    <div *ngIf="ngControl.control.errors?.min" class="invalid-feedback">
        {{label}} must be at least {{ngControl.control.errors.min['min'] | currency }}
    </div>
    <div *ngIf="ngControl.control.errors?.cannotContainSpace" class="invalid-feedback">
        {{label}} cannot contain white spaces. 
    </div>

    <div *ngIf="ngControl.control.errors?.passwordValidated" class="invalid-feedback">
        {{label}} must contain upper cases, lower cases, must contain a number, and must contain 1 special character. 
    </div>

    <div *ngIf="ngControl.control.errors?.cannotContainFullStop" class="invalid-feedback">
        {{label}} cannot contain decimal places.
    </div>

    <div *ngIf="ngControl.control.errors?.cannotContainSpaceAtFirstCharacter" class="invalid-feedback">
        {{label}} cannot contain at first character to have white spaces. 
    </div>
    <div *ngIf="ngControl.control.errors?.max" class="invalid-feedback">
        {{label}} must be at most {{ 999999999999999 | currency }}
    </div>

    <div *ngIf="ngControl.control.errors?.minlength" class="invalid-feedback">
        {{label}} must be at least {{ngControl.control.errors.minlength['requiredLength']}} characters long.
    </div>

    <div *ngIf="ngControl.control.errors?.maxlength" class="invalid-feedback">
        {{label}} must be at most {{ngControl.control.errors.maxlength['requiredLength']}} characters long.
    </div>
    <div *ngIf="ngControl.control.errors?.isMatchingInvoice" class="invalid-feedback">
        Sorry, you already have that invoice number. Please choose another invoice number.
    </div>
    <div *ngIf="ngControl.control.errors?.isMatching" class="invalid-feedback">
        Passwords do not match
    </div>
    <div *ngIf="ngControl.control.errors?.email" class="invalid-feedback">
        Email is required
    </div>
    <div *ngIf="ngControl.control.errors?.checkABNValidation" class="invalid-feedback">
        ABN is invalid.
    </div>
    <div *ngIf="ngControl.control.errors?.cannotContainLetters" class="invalid-feedback">
        It must contain numeric as an input!
    </div>
    <div *ngIf="ngControl.control.errors?.isAmountPaidGreaterThanInvoice" class="invalid-feedback">
        Invoice amount must not be greater than amount paid!
    </div>
    <small id="emailHelp" class="form-text text-muted">{{smalltext}}</small>
</div>