import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CurrentSubscriptionHome } from 'src/app/_models/currentsubscriptionhome';
import { HomeTask } from 'src/app/_models/hometask';
import { NotePatch } from 'src/app/_models/notepatch';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeserviceService {
  baseUrl =  environment.apiUrl;
  constructor(private http: HttpClient) { }

  getHomeTasks(){
    return this.http.get<HomeTask>(this.baseUrl + 'home/gethometasks').pipe(
      map((response: HomeTask)=> {
        return response;
      })
    )
  }

  getPatches(){
    return this.http.get<NotePatch>(this.baseUrl + 'home/getpatches').pipe(
      map((response: NotePatch) => {
        return response;
      })
    ) 
  }

  getCurrentSubscripton(){
    return this.http.get<CurrentSubscriptionHome>(this.baseUrl + 'home/getcurrentsubscription').pipe(
      map((response: CurrentSubscriptionHome) => {
        return response;
      })
    )
  }

  getTestHome(){
    return this.http.get(this.baseUrl + 'home/testhome').pipe(
      map((response: any) => {
        return response;
      })
    )
  }
}
