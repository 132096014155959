<app-nav></app-nav>
<header id="header-background" class="py-1 border-bottom mb-4">
    <div class="container">
        <div class="text-center my-5">
            <h1 class="fw-bolder text-white text-center">Thank You For Joining </h1>
        </div>
    </div>
  </header>
<div class="container">
    <div>
        <div class="row justify-content-center">
            <div class="col-sm-12 col-md-6 col-lg-6 py-5 bg-white text-black text-center border shadow-lg rounded">
                <div class=" ">
                    <div class="card-body">
                        <div class="alert alert-dismissible alert-{{statusAlert}}">
                            <button type="button" class="close" data-dismiss="alert">×</button>
                            <strong>{{status}}</strong> {{productDescription}}
                        </div>
                        <img src="/assets/images/successpurchase.jpg" class="img-fluid">
                        <div class="mt-4">
                            <section>
                                <p>
                                We appreciate your business! If you have any questions, please email
                                <a href="mailto:orders@example.com">billingaccounts@invoicepager.com</a>.
                                </p>
                            </section>
                        </div>
                    </div>                         
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
   
</div>