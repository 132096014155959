import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EmailconfigService } from 'src/app/_services/email/emailconfig.service';

@Component({
  selector: 'app-emailresender',
  templateUrl: './emailresender.component.html',
  styleUrls: ['./emailresender.component.css']
})
export class EmailresenderComponent implements OnInit {

  sendEmailForm: UntypedFormGroup;
  emailAddress: string; 
  constructor( private fb: UntypedFormBuilder, private services: EmailconfigService, private toastr: ToastrService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getParrams();
    this.initializeForm();
  }

  
  initializeForm(){
    this.sendEmailForm = this.fb.group({
      email:[this.emailAddress, [Validators.email]],
    })
  }

  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.emailAddress = params.get("id");
    });
  }

  resendEmail(){
    this.services.resendEmailConfirm(this.sendEmailForm.value).subscribe(response => {
      if (response === true)
      {
        this.toastr.success("Email Sent!");
      }
      else
      {
        this.toastr.error("Email failed to send!");
      }
    }, err => {
      console.log(err);
    })
  }

}
