import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogaskuserareyousurewanttocreateusergroup',
  templateUrl: './dialogaskuserareyousurewanttocreateusergroup.component.html',
  styleUrls: ['./dialogaskuserareyousurewanttocreateusergroup.component.css']
})
export class DialogaskuserareyousurewanttocreateusergroupComponent implements OnInit {

  message: string = "";
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.message = this.data.message;
  }

  createGroup(){
    this.data.createGroup = true;
  }

}
