import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { User } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class PresenceService {
  huburl = environment.hubUrl;
  private hubConnection?: HubConnection;
  constructor(private toastr: ToastrService) { }

  createHubConnection(user: User)
  {
     this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.huburl + 'presence', {
        accessTokenFactory: () => user.token
    })
    .withAutomaticReconnect()
    .build();

    this.hubConnection.start().catch(err => {
      console.log(err);
    });

    this.hubConnection.on('UserIsonline', username => {
      console.log(username);
      this.toastr.info(username + ' has connected');
    })

    this.hubConnection.on('UserIsOffline', username => {
      console.log(username);
      this.toastr.warning(username + ' has disconnnected');
    })       
  }

  stopHubConnection(){
    this.hubConnection?.stop().catch(error => console.log(error));
  }
  
}
