import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HtmlfooterService {

  showFooter = new ReplaySubject<boolean>(1);
  showFooter$ = this.showFooter.asObservable();
  constructor() { 

  }
}
