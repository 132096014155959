<app-nav></app-nav>
<div class="container">
    <nav class="navbar navbar-light bg-primary shadow-lg mt-4">
      <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-info-circle fa-2x" aria-hidden="true"></i> Tutorials</a>
    </nav>
    <div class="row mt-4">
      <div class="col-lg-3 col-md-3 col-sm-12">
        <div class="flex-shrink-0 p-3 bg-white border border-light ">
            <a href="/" class="d-flex align-items-center pb-3 mb-3 link-dark text-decoration-none border-bottom">
              <svg class="bi me-2" width="30" height="24"><use xlink:href="#bootstrap"></use></svg>
              <span class="fs-5 fw-semibold">How to</span>
            </a>
            <ul class="list-unstyled ps-0">
              <li class="mb-1">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#client-collapse" aria-expanded="false">
                  Client
                </button>
                <div class="collapse" id="client-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li><a [routerLink]="[]" (click)="btntutorial(0)" class="link-dark rounded">How to go to the clients page</a></li>
                    <li><a [routerLink]="[]"  (click)="btntutorial(1)" class="link-dark rounded">How to create a new client account</a></li>
                    <li><a [routerLink]="[]"  (click)="btntutorial(2)" class="link-dark rounded">How to search for a client</a></li>
                    <li><a [routerLink]="[]"  (click)="btntutorial(3)" class="link-dark rounded">How to edit an existing client record</a></li>
                    <li><a [routerLink]="[]"  (click)="btntutorial(4)" class="link-dark rounded">How to delete a client account</a></li>
                    <li><a [routerLink]="[]"  (click)="btntutorial(5)" class="link-dark rounded">How to view a clients account</a></li>
                  </ul>
                </div>
              </li>
              <li class="mb-1">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#invoice-collapse" aria-expanded="false">
                  Invoices page
                </button>
                <div class="collapse" id="invoice-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li><a [routerLink]="[]" (click)="btntutorial(6)"  class="link-dark rounded">How to go to the invoices page</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(7)" class="link-dark rounded">How to create an invoice account</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(8)" class="link-dark rounded">How to edit an existing invoice account</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(9)" class="link-dark rounded" class="link-dark rounded">How to delete an invoice account</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(10)"  class="link-dark rounded">How to view a clients invoice account</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(32)"  class="link-dark rounded">How to search for an invoice account</a></li>

                  </ul>
                </div>
              </li>
              <li class="mb-1">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#storeaccount-collapse" aria-expanded="false">
                  Store Account
                </button>
                <div class="collapse" id="storeaccount-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li><a [routerLink]="[]" (click)="btntutorial(11)" class="link-dark rounded">How to go to the store debit/credit page</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(12)" class="link-dark rounded">How to create a store account</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(13)" class="link-dark rounded">How to delete a store account</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(14)" class="link-dark rounded">How to edit a store account</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(31)" class="link-dark rounded">How to view a store account</a></li>
                  </ul>
                </div>
              </li>
              <li class="mb-1">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#searchallinvoices-collapse" aria-expanded="false">
                  Invoices Search All page
                </button>
                <div class="collapse" id="searchallinvoices-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li><a [routerLink]="[]" (click)="btntutorial(15)" class="link-dark rounded">How to search for all invoices</a></li>
                  </ul>
                </div>
              </li>
              <li class="mb-1">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#instalments-collapse" aria-expanded="false">
                  Instalments
                </button>
                <div class="collapse" id="instalments-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li><a [routerLink]="[]" (click)="btntutorial(16)" class="link-dark rounded">How to go to the instalments page</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(17)" class="link-dark rounded">How to create a new instalment account</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(18)" class="link-dark rounded">How to edit an instalment account</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(19)" class="link-dark rounded">How to delete an instalment account</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(20)" class="link-dark rounded">How to view an instalment account</a></li>
                  </ul>
                </div>
              </li>
              <li class="mb-1">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#payments-collapse" aria-expanded="false">
                  Account payments
                </button>
                <div class="collapse" id="payments-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li><a [routerLink]="[]" (click)="btntutorial(21)" class="link-dark rounded">How to go to the account payments page</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(22)" class="link-dark rounded">How to create a new account payment</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(23)" class="link-dark rounded">How to edit an existing account payment</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(24)" class="link-dark rounded">How to delete an account payment</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(25)" class="link-dark rounded">How to view an account payment</a></li>
                  </ul>
                </div>
              </li>
              <li class="mb-1">
                <button class="btn btn-toggle align-items-center rounded collapsed text-left" data-bs-toggle="collapse" data-bs-target="#payments-collapse" aria-expanded="false">
                    How to search payments for one client
                </button>
                <div class="collapse" id="payments-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li><a [routerLink]="[]" (click)="btntutorial(30)" class="link-dark rounded">How to search all payments for one client</a></li>
                  </ul>
                </div>
              </li>
              <li class="mb-1">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#import-collapse" aria-expanded="false">
                  Import 
                </button>
                <div class="collapse" id="import-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li><a [routerLink]="[]" (click)="btntutorial(26)" class="link-dark rounded">How to import clients via excel</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(27)" class="link-dark rounded">How to import invoices via excel</a></li>
                  </ul>
                </div>
              </li>
              <li class="mb-1">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#messages-collapse" aria-expanded="false">
                  Messages 
                </button>
                <div class="collapse" id="messages-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li><a [routerLink]="[]" (click)="btntutorial(28)" class="link-dark rounded">How to customise notifications to your customers</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(29)" class="link-dark rounded">How to change the due time to send messages</a></li>
                    <li><a [routerLink]="[]" (click)="btntutorial(33)" class="link-dark rounded">How to check sent messages</a></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
      </div>
      <div class="col-lg-9 col-md-9 col-sm-12 border-top border-right border-bottom">
        <div class="mt-4"></div>
        <app-tutorialgettoclient *ngIf="showtutorial[0]"></app-tutorialgettoclient>
        <app-tutorialhowtocreateclient *ngIf="showtutorial[1]"></app-tutorialhowtocreateclient>
        <app-tutorialsearchclient *ngIf="showtutorial[2]"></app-tutorialsearchclient>
        <app-tutorialeditclient *ngIf="showtutorial[3]"></app-tutorialeditclient>
        <app-tutorialdeleteclient *ngIf="showtutorial[4]"></app-tutorialdeleteclient>
        <app-tutorialhowtoviewclientaccounts *ngIf="showtutorial[5]"></app-tutorialhowtoviewclientaccounts>
        <app-tutorialgettoinvoice *ngIf="showtutorial[6]"></app-tutorialgettoinvoice>
        <app-tutorialcreateinvoice *ngIf="showtutorial[7]"></app-tutorialcreateinvoice>
        <app-tutorialeditinvoice *ngIf="showtutorial[8]"></app-tutorialeditinvoice>
        <app-tutorialdeleteinvoice *ngIf="showtutorial[9]"></app-tutorialdeleteinvoice>
        <app-tutoiralviewinvoice *ngIf="showtutorial[10]"></app-tutoiralviewinvoice>
        <app-tutorialhowtoopenstoreaccount *ngIf="showtutorial[11]"></app-tutorialhowtoopenstoreaccount>
        <app-tutorialcreatestoreaccount *ngIf="showtutorial[12]"></app-tutorialcreatestoreaccount>
        <app-tutorialhowtodeletstoreaccount *ngIf="showtutorial[13]"></app-tutorialhowtodeletstoreaccount>
        <app-tutorialhowtoeditstoreaccount *ngIf="showtutorial[14]"></app-tutorialhowtoeditstoreaccount>
        <app-tutorialhowtosearchforinvoices *ngIf="showtutorial[15]"></app-tutorialhowtosearchforinvoices>
        <app-howtogetintoinstalment *ngIf="showtutorial[16]"></app-howtogetintoinstalment>
        <app-tutorialhowtocreatenewaccountpayment *ngIf="showtutorial[17]"></app-tutorialhowtocreatenewaccountpayment>
        <app-tutorialhowtoeditinstalment *ngIf="showtutorial[18]"></app-tutorialhowtoeditinstalment>
        <app-tutorialhowtodeleteinstalment *ngIf="showtutorial[19]"></app-tutorialhowtodeleteinstalment>
        <app-tutorialhowtoviewinstalmentaccounts *ngIf="showtutorial[20]"></app-tutorialhowtoviewinstalmentaccounts>
        <app-tutorialhowtogetpayments *ngIf="showtutorial[21]"></app-tutorialhowtogetpayments>
        <app-tutorialhowtocreatenewaccountpayment *ngIf="showtutorial[22]"></app-tutorialhowtocreatenewaccountpayment>
        <app-tutorialhowtoeditpayment *ngIf="showtutorial[23]"></app-tutorialhowtoeditpayment>
        <app-tutorialhowtodeletepayment *ngIf="showtutorial[24]"></app-tutorialhowtodeletepayment>
        <app-tutorialhowtoviewpayment *ngIf="showtutorial[25]"></app-tutorialhowtoviewpayment>
        <app-tutorialhowtoimportclients *ngIf="showtutorial[26]"></app-tutorialhowtoimportclients>
        <app-tutorialhowtoimportinvoices *ngIf="showtutorial[27]"></app-tutorialhowtoimportinvoices>
        <app-tutorialhowtousemesages *ngIf="showtutorial[28]"></app-tutorialhowtousemesages>
        <app-tutorialchangemessagetimes *ngIf="showtutorial[29]"></app-tutorialchangemessagetimes>
        <app-tutorialhowtogettosearchallpaymentssingleclient *ngIf="showtutorial[30]"></app-tutorialhowtogettosearchallpaymentssingleclient>
        <app-tutorialhowtoviewstoreaccounts *ngIf="showtutorial[31]"></app-tutorialhowtoviewstoreaccounts>
        <app-tutorialsearchinvoice *ngIf="showtutorial[32]"></app-tutorialsearchinvoice>
        <app-tutorialhowtochecksentmessages *ngIf="showtutorial[33]"></app-tutorialhowtochecksentmessages>
      </div>      
    </div>
</div>