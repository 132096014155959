import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-creditartworkfeatures',
  templateUrl: './creditartworkfeatures.component.html',
  styleUrls: ['./creditartworkfeatures.component.css']
})
export class CreditartworkfeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
