import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService }  from '../_services/account/account.service';
import { HtmlfooterService } from '../_services/htmlfooter.service';
import { StripeaccountService } from '../_services/stripeaccount/stripeaccount.service';
import { take } from 'rxjs/operators';
import { EmailconfigService } from '../_services/email/emailconfig.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  model: any = {};
  user: any = {};

  constructor(public accountService: AccountService, private router: Router, private toastr: ToastrService, private htmlFooter: HtmlfooterService,
    public stripeService: StripeaccountService, private servicesEmail: EmailconfigService ){
    this.htmlFooter.showFooter.next(true);
  }

  ngOnInit(): void {
    this.assignUser();
    this.loadStripeAccounts();
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  login(){
    const username = this.model.username;
    this.accountService.login(this.model).subscribe(response => {
      if (this.accountService.checkTwoFactor)
      {
        this.router.navigateByUrl('/login');    
      }
      else
      {
        this.router.navigateByUrl('/members');
      }
    }, error => {
      if (error.error === "Emailed not confirmed!")
      {
        this.router.navigateByUrl(`/emailresender/${username}`);
      }
      console.log(error);
    });
  }

  logout(){
    this.accountService.logout();
    this.router.navigate(['/','']);
  }

  playSound(){
    let audioPlay = new Audio();
    audioPlay.src = "assets/sound/dingsound.wav";
    audioPlay.load();
    audioPlay.play();
    console.log('playing sound');
  }

  private loadStripeAccounts(){
    if (this.accountService.hasUserLogIn === false)
    {
      this.accountService.setUserLogIn();
      this.stripeService.stopUpdateStripeAccountsHubConnection();
      this.stripeService.createHubUpdateStripeAccountConnection(this.user);  
    }
  }
}
