<app-nav></app-nav>
<div class="container mt-4">
  <nav class="navbar navbar-light bg-primary shadow-lg mt-4">
    <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-user fa-2x" aria-hidden="true"></i> Account Details</a>
  </nav>
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/members/editdetailsacount">Edit Account Details</a></li>
    <li class="breadcrumb-item active">Edit Account</li>
  </ol>
  <div class="row justify-content-md-center mt-4" *ngIf="member">
      <div class="col-sm-12 col-md-4  py-5 bg-white border text-black text-center shadow-lg">
          <div class=" ">
              <div class="card-body">
                <img src="/assets/images/edit.jpg" class="img-thumbnail">
                  <h2 class="py-3">Edit Account</h2>
                  <p>
                     Edit your account and details!
                  </p>
              </div>
          </div>
      </div>
      <div class="col-sm-12 col-md-8 mt-2 ">
          <div class="card mt-4 border-white shadow-lg">
              <div class="card-body">
                <form #editForm="ngForm" [formGroup]="memberForm" (ngSubmit)="memberForm.valid" autocomplete="off" id="editForm">
                  <h2 class="text-center text-primary mt-2"> Edit Account</h2>
                  <div class="bs-component">
                    <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
                      <button type="button" class="close" data-dismiss="alert">×</button>
                      <h4 class="alert-heading">Information!</h4>
                      <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
                    </div>
                  </div>                 
                  <app-text-input [formControl]='memberForm.controls["firstname"]' [label]='"*First name"' [smalltext]='""'> </app-text-input>
                  <app-text-input [formControl]='memberForm.controls["lastname"]' [label]='"*Last name"' [smalltext]='""'> </app-text-input>
                  <app-text-input [formControl]='memberForm.controls["email"]' [label]='"Email"' [smalltext]='"We will never share your email with anyone else."' [type]='"email"'> </app-text-input>      
                  <app-text-input [formControl]='memberForm.controls["phone"]' [label]='"Phone"' [smalltext]='""'> </app-text-input>
                  <app-text-input [formControl]='memberForm.controls["businessname"]' [label]='"Business Name"' [smalltext]='""'> </app-text-input>
                  <app-text-input [formControl]='memberForm.controls["address"]' [label]='"Business Address"' [smalltext]='""'> </app-text-input>
                  <app-text-input [formControl]='memberForm.controls["state"]' [label]='"State"' [smalltext]='""'> </app-text-input>
                  <app-text-input [formControl]='memberForm.controls["city"]' [label]='"City"' [smalltext]='""'> </app-text-input>
                  <app-text-input [formControl]='memberForm.controls["postcode"]' [label]='"Postcode"' [smalltext]='""' [type]='"number"'> </app-text-input>
                  <app-text-input [formControl]='memberForm.controls["contactussupportphone"]' [label]='"Your Business Help Support For Phone Contact"' [smalltext]='""'> </app-text-input>
                  <app-text-input [formControl]='memberForm.controls["contactussupportemail"]' [label]='"Your Business Help Support For Email Contact"' [smalltext]='""'> </app-text-input>
                  <app-text-input [formControl]='memberForm.controls["abn"]' [label]='"ABN"' [smalltext]='""'> </app-text-input>
                  <app-text-input [formControl]='memberForm.controls["accountname"]' [label]='"Account Name"' [smalltext]='""'> </app-text-input>
                  <app-text-input [formControl]='memberForm.controls["bsb"]' [label]='"BSB for Business transaction Account"' [smalltext]='""' [type]='"number"'> </app-text-input>
                  <app-text-input [formControl]='memberForm.controls["accountnumber"]' [label]='"Account Number for Business Transaction Account"' [smalltext]='""' [type]='"number"'> </app-text-input>
                  <app-text-input [formControl]='memberForm.controls["contactwebsite"]' [label]='"Your Company / Business Website Address"' [smalltext]='""'> </app-text-input>
                  <app-text-input [formControl]='memberForm.controls["payid"]' [label]='"Pay ID"' [smalltext]='""'> </app-text-input>                 
              </form>
              </div>
              <div class="card-footer text-muted">
                  <button [disabled]="!memberForm.valid || !memberForm.dirty" class="btn btn-success btn-block" form="editForm" (click)="updatemember()" >Update Account</button>
              </div>
            </div>
      </div>
  </div>
</div>
