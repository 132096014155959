<app-nav></app-nav>
<header id="header-background" class="py-1 border-bottom mb-4">
  <div class="container">
      <div class="text-center my-5">
          <h1 class="fw-bolder text-white text-center">Our Membership Plans</h1>
      </div>
  </div>
</header>
 <ng-container *ngIf="accountInfo">    
  <ng-container class="container-fluid" *ngIf="accountInfo.accountStripeReady; else showdefaultpayments">   
    <div class="col-lg-12" *ngIf="checkErrorProduct">
      <div class="bs-component">
        <div class="alert alert-dismissible alert-danger mt-4">
          <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
          <strong>Error! </strong> You will need to cancel your current subscription before entering into another subscription. <a routerLink="/members/listuserbills"><button class="btn btn-success" > Cancel Subscription </button></a> 
          Please click on the link, & then click on cancel subscription button.
        </div>
      </div>
    </div>
    <h1 class="fw-bolder text-primary text-center">Sole Traders & Small Business Plans</h1>
    <div id="generic_price_table">  
    <section>
      <div class="container">
          <div class="row">
              <div class="col-md-12">
                  <!--PRICE HEADING START-->
                  <div class="price-heading clearfix"> 
                  </div>
                  <!--//PRICE HEADING END-->
              </div>
          </div>
      </div>
      <div class="container">
          
          <!--BLOCK ROW START-->
          <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-12">
              
                <!--PRICE CONTENT START-->
                  <div class="generic_content clearfix">
                      
                      <!--HEAD PRICE DETAIL START-->
                      <div class="generic_head_price clearfix">
                      
                          <!--HEAD CONTENT START-->
                          <div class="generic_head_content clearfix">
                          
                            <!--HEAD START-->
                              <div class="head_bg"></div>
                              <div class="head">
                                  <span>Basic</span>
                              </div>
                              <!--//HEAD END-->
                              
                          </div>
                          <!--//HEAD CONTENT END-->
                          
                          <!--PRICE START-->
                          <div class="generic_price_tag clearfix">  
                              <span class="price">
                                  <span class="sign">$</span>
                                  <span class="currency">30</span>
                                  <span class="cent">.00</span>
                                  <span class="month">/MON</span>
                              </span>
                          </div>
                          <!--//PRICE END-->
                          
                      </div>                            
                      <!--//HEAD PRICE DETAIL END-->
                      
                      <!--FEATURE LIST START-->
                      <div class="generic_feature_list">
                        <ul>
                          <li class="text-secondary"><span class="text-success font-weight-bold">First-time Buyer</span> <p class="font-italic"><small>Get First Month For Free (T&C's apply).</small></p></li>
                            <li><span class="text-danger">No</span> Linked Accounts</li>                        
                            <li><span class="text-success"><i class="fa fa-check"></i></span> Send Bills / Invoices (Email only)</li>
                            <li><span class="text-success"><i class="fa fa-check"></i></span> Send via Instalments Bills / Invoices (Email only)</li>
                            <li><span class="text-success"><i class="fa fa-check"></i></span> Create Instaments</li>                          
                        </ul>
                      </div>
                      <!--//FEATURE LIST END-->
                      
                      <!--BUTTON START-->
                      <div class="generic_price_btn clearfix">
                        <a class="user-select-auto" (click)="pay(0)">Buy Now!</a>
                      </div>
                      <!--//BUTTON END-->
                      
                  </div>
                  <!--//PRICE CONTENT END-->
                      
              </div>
              
             
              <div class="col-lg-3 col-md-3 col-sm-12">
              
                <!--PRICE CONTENT START-->
                  <div class="generic_content clearfix">
                      
                      <!--HEAD PRICE DETAIL START-->
                      <div class="generic_head_price clearfix">
                      
                          <!--HEAD CONTENT START-->
                          <div class="generic_head_content clearfix">
                          
                            <!--HEAD START-->
                              <div class="head_bg"></div>
                              <div class="head">
                                  <span>Lite</span>
                              </div>
                              <!--//HEAD END-->
                              
                          </div>
                          <!--//HEAD CONTENT END-->
                          
                          <!--PRICE START-->
                          <div class="generic_price_tag clearfix">  
                              <span class="price">
                                  <span class="sign">$</span>
                                  <span class="currency">55</span>
                                  <span class="cent">.00</span>
                                  <span class="month">/MON</span>
                              </span>
                          </div>
                          <!--//PRICE END-->
                          
                      </div>                            
                      <!--//HEAD PRICE DETAIL END-->
                      
                      <!--FEATURE LIST START-->
                      <div class="generic_feature_list">
                        <ul>
                          <li class="text-secondary"><span class="text-success font-weight-bold">First-time Buyer</span> <p class="font-italic"><small>Get First Month For Free (T&C's apply).</small></p></li>
                          <li><span class="text-danger">No</span> Linked Accounts</li>                          
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Send Bills / Invoices (Email only)</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Send via Instalments Bills / Invoices (Email only)</li>       
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Stripe Payment Links (Email Only)</li>
   
                        </ul>
                      </div>
                      <!--//FEATURE LIST END-->
                      
                      <!--BUTTON START-->
                      <div class="generic_price_btn clearfix">
                        <a class="user-select-auto" (click)="pay(1)">Buy Now!</a>
                      </div>
                      <!--//BUTTON END-->
                      
                  </div>
                  <!--//PRICE CONTENT END-->
                      
              </div>
              <div class="col-lg-3 col-md-3 col-sm-12">
              
                <!--PRICE CONTENT START-->
                  <div class="generic_content clearfix">
                      
                      <!--HEAD PRICE DETAIL START-->
                      <div class="generic_head_price clearfix">
                      
                          <!--HEAD CONTENT START-->
                          <div class="generic_head_content clearfix">
                          
                            <!--HEAD START-->
                              <div class="head_bg"></div>
                              <div class="head">
                                  <span>Standard</span>
                              </div>
                              <!--//HEAD END-->
                              
                          </div>
                          <!--//HEAD CONTENT END-->
                          
                          <!--PRICE START-->
                          <div class="generic_price_tag clearfix">  
                              <span class="price">
                                  <span class="sign">$</span>
                                  <span class="currency">88</span>
                                  <span class="cent">.00</span>
                                  <span class="month">/MON</span>
                              </span>
                          </div>
                          <!--//PRICE END-->
                          
                      </div>                            
                      <!--//HEAD PRICE DETAIL END-->
                      
                      <!--FEATURE LIST START-->
                      <div class="generic_feature_list">
                        <ul>
                          <li class="text-secondary"><span class="text-success font-weight-bold">First-time Buyer</span> <p class="font-italic"><small>Get First Month For Free (T&C's apply).</small></p></li>
                          <li><span class="text-sucess">1</span> Linked Accounts</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Send Bills / Invoices</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Send via Instalments Bills / Invoices</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Create Instaments</li>    
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Stripe Payment Links</li>
                          <li><span>450 text</span> Messages (SMS's)</li>
     
                        </ul>
                      </div>
                      <!--//FEATURE LIST END-->
                      
                      <!--BUTTON START-->
                      <div class="generic_price_btn clearfix">
                        <a class="user-select-auto" (click)="pay(2)">Buy Now!</a>
                      </div>
                      <!--//BUTTON END-->
                      
                  </div>
                  <!--//PRICE CONTENT END-->
                      
              </div>
              <div class="col-lg-3 col-md-3 col-sm-12">
              
                <!--PRICE CONTENT START-->
                  <div class="generic_content active clearfix">
                      
                      <!--HEAD PRICE DETAIL START-->
                      <div class="generic_head_price clearfix">
                      
                          <!--HEAD CONTENT START-->
                          <div class="generic_head_content clearfix">
                          
                            <!--HEAD START-->
                              <div class="head_bg"></div>
                              <div class="head">
                                  <span>Super Plan</span>
                              </div>
                              <!--//HEAD END-->
                              
                          </div>
                          <!--//HEAD CONTENT END-->
                          
                          <!--PRICE START-->
                          <div class="generic_price_tag clearfix">  
                              <span class="price">
                                  <span class="sign">$</span>
                                  <span class="currency">125</span>
                                  <span class="cent">.00</span>
                                  <span class="month">/MON</span>
                              </span>
                          </div>
                          <!--//PRICE END-->
                          
                      </div>                            
                      <!--//HEAD PRICE DETAIL END-->
                      
                      <!--FEATURE LIST START-->
                      <div class="generic_feature_list">
                        <ul>
                          <li class="text-secondary"><span class="text-success font-weight-bold">First-time Buyer</span> <p class="font-italic"><small>Get First Month For Free (T&C's apply).</small></p></li>
                          <li><span class="text-success">2</span> Linked Accounts</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Send Bills / Invoices</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Send via Instalments Bills / Invoices</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Create Instaments</li>    
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Stripe Payment Links</li>
                          <li><span>1,000 text</span> Messages (SMS's)</li>
                          </ul>
                      </div>
                      <!--//FEATURE LIST END-->
                      
                      <!--BUTTON START-->
                      <div class="generic_price_btn clearfix">
                        <a class="user-select-auto" (click)="pay(3)">Buy Now!</a>
                      </div>
                      <!--//BUTTON END-->
                      
                  </div>
                  <!--//PRICE CONTENT END-->
                      
              </div>
              <div class="mt-2"></div>
              <hr>
          </div>  
          <!--//BLOCK ROW END-->
      <div class="mt-2"></div>
          <hr>
      </div>
     </section>     
    </div>
    <h1 class="fw-bolder text-primary text-center">Medium to Large Enterprise Plans</h1>
    <div id="generic_price_table">  

    <section>
      <div class="container">
          <div class="row">
              <div class="col-md-12">
                  <!--PRICE HEADING START-->
                  <div class="price-heading clearfix"> 
                  </div>
                  <!--//PRICE HEADING END-->
              </div>
          </div>
      </div>
      <div class="container">
          
          <!--BLOCK ROW START-->
          <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12">
              
                <!--PRICE CONTENT START-->
                  <div class="generic_content clearfix">
                      
                      <!--HEAD PRICE DETAIL START-->
                      <div class="generic_head_price clearfix">
                      
                          <!--HEAD CONTENT START-->
                          <div class="generic_head_content clearfix">
                          
                            <!--HEAD START-->
                              <div class="head_bg"></div>
                              <div class="head">
                                  <span>Master Plan</span>
                              </div>
                              <!--//HEAD END-->
                              
                          </div>
                          <!--//HEAD CONTENT END-->
                          
                          <!--PRICE START-->
                          <div class="generic_price_tag clearfix">  
                              <span class="price">
                                  <span class="sign">$</span>
                                  <span class="currency">220</span>
                                  <span class="cent">.00</span>
                                  <span class="month">/MON</span>
                              </span>
                          </div>
                          <!--//PRICE END-->
                          
                      </div>                            
                      <!--//HEAD PRICE DETAIL END-->
                      
                      <!--FEATURE LIST START-->
                      <div class="generic_feature_list">
                        <ul>
                          <li><span class="text-success">3</span> Linked Accounts</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span>Future Updates Includes <b>Imported Features (files)</b></li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span>Future Updates Includes <b>API Features</b></li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Send Bills / Invoices</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Send via Instalments Bills / Invoices</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Create Instaments</li>    
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Stripe Payment Links</li>
                          <li><span>2,000 text</span> Messages (SMS's)</li>                    
                        </ul>
                      </div>
                      <!--//FEATURE LIST END-->
                      
                      <!--BUTTON START-->
                      <div class="generic_price_btn clearfix">
                        <a class="user-select-auto" (click)="pay(4)">Buy Now!</a>
                      </div>
                      <!--//BUTTON END-->
                      
                  </div>
                  <!--//PRICE CONTENT END-->
                      
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
              
                <!--PRICE CONTENT START-->
                  <div class="generic_content active clearfix">
                      
                      <!--HEAD PRICE DETAIL START-->
                      <div class="generic_head_price clearfix">
                      
                          <!--HEAD CONTENT START-->
                          <div class="generic_head_content clearfix">
                          
                            <!--HEAD START-->
                              <div class="head_bg"></div>
                              <div class="head">
                                  <span>Ultimate Plan</span>
                              </div>
                              <!--//HEAD END-->
                              
                          </div>
                          <!--//HEAD CONTENT END-->
                          
                          <!--PRICE START-->
                          <div class="generic_price_tag clearfix">  
                              <span class="price">
                                  <span class="sign">$</span>
                                  <span class="currency">440</span>
                                  <span class="cent">.00</span>
                                  <span class="month">/MON</span>
                              </span>
                          </div>
                          <!--//PRICE END-->
                          
                      </div>                            
                      <!--//HEAD PRICE DETAIL END-->
                      
                      <!--FEATURE LIST START-->
                      <div class="generic_feature_list">
                        <ul>
                          <li><span class="text-success">5</span> Linked Accounts</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span>Future Updates Includes <b>Imported Features (files)</b> </li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span>Future Updates Includes <b>API Features</b></li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Send Bills / Invoices</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Send via Instalments Bills / Invoices</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Create Instaments</li>    
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Stripe Payment Links</li>
                          <li><span>5,000 text</span> Messages (SMS's)</li>                    
                        </ul>
                      </div>
                      <!--//FEATURE LIST END-->
                      
                      <!--BUTTON START-->
                      <div class="generic_price_btn clearfix">
                        <a class="user-select-auto" (click)="pay(5)">Buy Now!</a>
                      </div>
                      <!--//BUTTON END-->
                      
                  </div>
                  <!--//PRICE CONTENT END-->
                      
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
              
                <!--PRICE CONTENT START-->
                  <div class="generic_content clearfix">
                      
                      <!--HEAD PRICE DETAIL START-->
                      <div class="generic_head_price clearfix">
                      
                          <!--HEAD CONTENT START-->
                          <div class="generic_head_content clearfix">
                          
                            <!--HEAD START-->
                              <div class="head_bg"></div>
                              <div class="head">
                                  <span>Premium Plus Plan</span>
                              </div>
                              <!--//HEAD END-->
                              
                          </div>
                          <!--//HEAD CONTENT END-->
                          
                          <!--PRICE START-->
                          <div class="generic_price_tag clearfix">  
                              <span class="price">
                                  <span class="sign">$</span>
                                  <span class="currency">880</span>
                                  <span class="cent">.00</span>
                                  <span class="month">/MON</span>
                              </span>
                          </div>
                          <!--//PRICE END-->
                          
                      </div>                            
                      <!--//HEAD PRICE DETAIL END-->
                      
                      <!--FEATURE LIST START-->
                      <div class="generic_feature_list">
                        <ul>
                          <li><span class="text-success">10</span> Linked Accounts</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span>Future Updates Includes <b>Imported Features (files)</b> </li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span>Future Updates Includes <b>API Features</b></li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Send Bills / Invoices</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Send via Instalments Bills / Invoices</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Create Instaments</li>    
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Stripe Payment Links</li>
                          <li><span>10,000 text</span> Messages (SMS's)</li>
     
                        </ul>
                      </div>
                      <!--//FEATURE LIST END-->
                      
                      <!--BUTTON START-->
                      <div class="generic_price_btn clearfix">
                        <a class="user-select-auto" (click)="pay(6)">Buy Now!</a>
                      </div>
                      <!--//BUTTON END-->
                      
                  </div>
                  <!--//PRICE CONTENT END-->
                      
              </div>
            </div>
          </div>
        </section>
      </div>
      <h1 class="fw-bolder text-primary text-center">Corporate Plans</h1>
      <div id="generic_price_table">  
  
      <section>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <!--PRICE HEADING START-->
                    <div class="price-heading clearfix"> 
                    </div>
                    <!--//PRICE HEADING END-->
                </div>
            </div>
        </div>
        <div class="container">
          <!--BLOCK ROW START-->
          <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
              
                <!--PRICE CONTENT START-->
                  <div class="generic_content active clearfix">
                      
                      <!--HEAD PRICE DETAIL START-->
                      <div class="generic_head_price clearfix">
                      
                          <!--HEAD CONTENT START-->
                          <div class="generic_head_content clearfix">
                          
                            <!--HEAD START-->
                              <div class="head_bg"></div>
                              <div class="head">
                                  <span>Corporate Tier 1</span>
                              </div>
                              <!--//HEAD END-->
                              
                          </div>
                          <!--//HEAD CONTENT END-->
                          
                          <!--PRICE START-->
                          <div class="generic_price_tag clearfix">  
                              <span class="price">
                                  <span class="sign">$</span>
                                  <span class="currency">2000</span>
                                  <span class="cent">.00</span>
                                  <span class="month">/MON</span>
                              </span>
                          </div>
                          <!--//PRICE END-->
                          
                      </div>                            
                      <!--//HEAD PRICE DETAIL END-->
                      
                      <!--FEATURE LIST START-->
                      <div class="generic_feature_list">
                        <ul>
                          <li><span class="text-success">20</span> Linked Accounts</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span>Future Updates Includes <b>Imported Features (files)</b> </li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span>Future Updates Includes <b>API Features</b></li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Send Bills / Invoices</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Send via Instalments Bills / Invoices</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Create Instaments</li>    
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Stripe Payment Links</li>
                          <li><span>25,000 text</span> Messages (SMS's)</li>
   
                        </ul>
                      </div>
                      <!--//FEATURE LIST END-->
                      
                      <!--BUTTON START-->
                      <div class="generic_price_btn clearfix">
                        <a class="user-select-auto" (click)="pay(7)">Buy Now!</a>
                      </div>
                      <!--//BUTTON END-->
                      
                  </div>
                  <!--//PRICE CONTENT END-->
                      
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
              
                <!--PRICE CONTENT START-->
                  <div class="generic_content active clearfix">
                      
                      <!--HEAD PRICE DETAIL START-->
                      <div class="generic_head_price clearfix">
                      
                          <!--HEAD CONTENT START-->
                          <div class="generic_head_content clearfix">
                          
                            <!--HEAD START-->
                              <div class="head_bg"></div>
                              <div class="head">
                                  <span>Corporate Tier 2</span>
                              </div>
                              <!--//HEAD END-->
                              
                          </div>
                          <!--//HEAD CONTENT END-->
                          
                          <!--PRICE START-->
                          <div class="generic_price_tag clearfix">  
                              <span class="price">
                                  <span class="sign">$</span>
                                  <span class="currency">4400</span>
                                  <span class="cent">.00</span>
                                  <span class="month">/MON</span>
                              </span>
                          </div>
                          <!--//PRICE END-->
                          
                      </div>                            
                      <!--//HEAD PRICE DETAIL END-->
                      
                      <!--FEATURE LIST START-->
                      <div class="generic_feature_list">
                        <ul>
                          <li><span class="text-success">50</span> Linked Accounts</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span>Future Updates Includes <b>Imported features (files)</b></li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span>Future Updates Includes <b>API Features</b></li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Send Bills / Invoices</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Send via Instalments Bills / Invoices</li>
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Create Instaments</li>    
                          <li><span class="text-success"><i class="fa fa-check"></i></span> Stripe Payment Links</li>
                          <li><span>50,000 text</span> Messages (SMS's)</li>
                          </ul>
                      </div>
                      <!--//FEATURE LIST END-->
                      
                      <!--BUTTON START-->
                      <div class="generic_price_btn clearfix">
                        <a class="user-select-auto" (click)="pay(8)">Buy Now!</a>
                      </div>
                      <!--//BUTTON END-->
                      
                  </div>
                  <!--//PRICE CONTENT END-->
                      
              </div>
          </div>  
          <!--//BLOCK ROW END-->
      <div class="mt-2"></div>
          <hr>
      </div>
    </section>  
  </div>     
  </ng-container>
  <ng-template #showdefaultpayments>
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12">

        </div>
        <div class="col-sm-6 col-md-6 col-sm-12 py-5 bg-white text-black text-center border shadow-lg rounded">
          <div class=" ">
              <div class="card-body">           
                  <h1 class="py-3 font-weight-bold">Contact Us</h1>
                  <hr>
                  <div class="bs-component">
                    <div class="alert alert-dismissible alert-light">
                      <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                      <h4><i class="fas fa-user-plus"></i> Call Us Now On <a href="tel:0459044781">(0430 386 505)</a> To Setup Your Account! </h4>
                    </div>
                  </div>
                  <img src="/assets/images/subscriptioncontact.jpg" class="img-thumbnail">
                  <h2 class="py-3">Please Contact Us To Setup Your Account </h2>
                  <button class="btn btn-outline-success btn-lg" routerLink="/home" >Go Back To Home Portal</button>    
              </div>
          </div>  
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12">

        </div>
      </div>
  </div>
  </ng-template>
</ng-container>