import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { ClientaccountpaymentsService } from 'src/app/_services/clientaccountpayments/clientaccountpayments.service';
import { PaymentService } from 'src/app/_services/payment/payment.service';
import { ReceivableaccountspaymentsService } from 'src/app/_services/receivablesaccountspayments/receivableaccountspayments.service';
import { ClientlistaccountreceivableComponent } from '../clientlistaccountreceivable/clientlistaccountreceivable.component';

@Component({
  selector: 'app-deleteaccountreceivable',
  templateUrl: './deleteaccountreceivable.component.html',
  styleUrls: ['./deleteaccountreceivable.component.css']
})
export class DeleteaccountreceivableComponent implements OnInit {

  accountID: number;
  invoiceID: number;
  clientID: number;
  user?: User;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ClientlistaccountreceivableComponent>,
    private paymentService: PaymentService, private accountService: AccountService,
    private receivableaccountspaymentsService: ReceivableaccountspaymentsService,
    private clientAccountPaymentService: ClientaccountpaymentsService
    ) { }

  ngOnInit(): void {
    this.invoiceID = this.data.invoiceID;
    this.accountID = this.data.id;
    this.clientID = this.data.clientID;
    this.assignUser();
  }

  deleteAccount(){
    this.data.paymentDelete = false;
    this.paymentService.deleteAccountPayment(this.accountID).subscribe(response => {
      if (response)
      {
        // Get payments //
        this.paymentService.stopHubConnection();
        this.paymentService.stopHubArchiveConnection();
        this.paymentService.createHubConnection(this.user, this.invoiceID);
        this.paymentService.createHubArchiveConnection(this.user, this.invoiceID);

        // Get All Payments //
        this.receivableaccountspaymentsService.stopHubConnection();
        this.receivableaccountspaymentsService.createHubConnection(this.user, true);

        // Get Single Payments //
        this.clientAccountPaymentService.stopHubConnection();
        this.clientAccountPaymentService.createHubConnection(this.user, this.clientID, true );
        this.data.paymentDelete = true;      
      }
    }, error => {
      console.log(error);
    });
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

}
