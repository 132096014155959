import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ConfirmEmail } from 'src/app/_models/confirmemail';
import { EmailInfo } from 'src/app/_models/emailinfo';
import { LostAccount } from 'src/app/_models/lostaccount';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailconfigService {
  baseUrl =  environment.apiUrl;
  constructor(private http: HttpClient) { }

  resendEmailConfirm(email: any){
    return  this.http.post(this.baseUrl + "email/postemailresend", email).pipe(
      map((response: any) => {
        return response;      
      })
    );
  } 
  
  loadConfirmEmail(email: ConfirmEmail){
    return  this.http.get(this.baseUrl + "email/getconfirmemail?token=" + email.token + "&id=" + email.id).pipe(
      map((response: any) => {
        return response;      
      })
    );
  }

  
  loadConfirmLostAccount(email: LostAccount){
    return  this.http.post(this.baseUrl + "email/getconfirmlostaccount", email).pipe(
      map((response: any) => {
        return response;      
      })
    );
  }


  sendLostEmailAccount(email: any){
    return  this.http.post(this.baseUrl + "email/getlostemailaccount", email).pipe(
      map((response: any) => {
        return response;      
      })
    );
  }

  contactUs(email: any){
    return  this.http.post(this.baseUrl + "email/contactus", email).pipe(
      map((response: any) => {
        return response;      
      })
    );
  }

  getEmailInfo(){
    return  this.http.get(this.baseUrl + "email/getemailinfo").pipe(
      map((response: EmailInfo) => {
        return response;      
      })
    );
  }

}

 