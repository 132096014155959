<div class="col-lg-12 col-md-12 col-sm-12">
<h2 class="text-center">How to view a clients account</h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Client/Images/View Client/vca01.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•   Go to the “Client’s” page</h2>
  
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Client/Images/View Client/vca02.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•   Scroll down the page to find the “Clients List”</h2>
    
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Client/Images/View Client/vca03.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•   Click in line with the “Client Account” (or the drop down arrow) to reveal more information</h2>
    <h2>•   Doing this will allow you to view more personal and business details</h2>
    <h2>•   Click on the “Client Account” again to hide the additional information</h2>
</div>
