import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TouchSequence } from 'selenium-webdriver';
import { EmailconfigService } from 'src/app/_services/email/emailconfig.service';

@Component({
  selector: 'app-receivelostemail',
  templateUrl: './receivelostemail.component.html',
  styleUrls: ['./receivelostemail.component.css']
})
export class ReceivelostemailComponent implements OnInit {

  sendEmailForm: UntypedFormGroup;
  showSendEmail: boolean;
  constructor(private fb: UntypedFormBuilder, private services: EmailconfigService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.showSendEmail = false;
    this.initializeForm();
  }

  initializeForm(){
    this.sendEmailForm = this.fb.group({
      email:['', [Validators.email, Validators.required]],
    })
  }

  sendResetEmail(){
    this.services.sendLostEmailAccount(this.sendEmailForm.value).subscribe(response => {
      this.showSendEmail = true;
      this.toastr.success("We have sent you an email to reset your account. Please go and check your spam folder.")
    }, err => {
      console.log(err);
    })
  }

}
