import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { MessagesService } from 'src/app/_services/message/messages.service';

@Component({
  selector: 'app-changeduedatetime',
  templateUrl: './changeduedatetime.component.html',
  styleUrls: ['./changeduedatetime.component.css']
})
export class ChangeduedatetimeComponent implements OnInit {
  public data: any;
  @ViewChild('editForm') editForm: NgForm; 
  formEdit: UntypedFormGroup;
  time: string;;
  constructor(public fb: UntypedFormBuilder, private service: MessagesService) { }

  ngOnInit(): void {
    this.initializeForm();
    this.onLoad();
  }

  onSubmit(){
    this.convertTime();

    const time = {
      DueTime: this.time
    }

    this.data.message = time.DueTime;
    this.service.updateDueTime(time).subscribe(response => {
      this.onLoadSettingMesaging();
      console.log(response);
    }, error => {
      console.log(error);
    });
  }

  initializeForm(){
    this.formEdit = this.fb.group({
      changeText:['', Validators.required],
    }); 
    
    this.formEdit.controls['changeText'].setValue('');
  }

  convertTime(){
    const time = this.formEdit.controls['changeText'].value; 
    //this.time = new Date(2018, 0O5, 0O5, time, 0, 0, 0);  
    this.time = this.formEdit.controls['changeText'].value;
  }

  onLoad(){
    this.service.getDueTime().subscribe(response => {
      this.data = response;
      this.time = this.data.dueDateTime;
      this.time = this.getTimeValue();
      console.log(response);
    }, error => {
      console.log(error);
    })
  }

  onLoadSettingMesaging(){
    this.service.getTextSettingMessaging().subscribe(response => {
      this.service.message = response;
      if (this.service.message == null){
        this.service.setMessageValues();
      }

      console.log(response);
    }, error => {
      console.log(error);
    })
  }

  getTimeValue(): string{
    var time: number = +this.time;
    if (time === 0)
      return "12:00 AM"
    time = time / 100;
    if (time > 0 && time < 12)
      return time + ":00 AM";
    if (time === 12)
      return "12:00 PM"
    time = time - 12;
    return  time + ":00 PM"
  }

}
