import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { InvoiceService } from 'src/app/_services/invoice/invoice.service';
import { PayablesreceivablesService } from 'src/app/_services/payablesreceivablesaccounts/payablesreceivables.service';
import { InvoicelistComponent } from '../invoicelist/invoicelist.component';

@Component({
  selector: 'app-invoicearchive',
  templateUrl: './invoicearchive.component.html',
  styleUrls: ['./invoicearchive.component.css']
})
export class InvoicearchiveComponent implements OnInit {
  accountID: number;
  clientID: number;
  user?: User;
  archive: boolean = false;
  

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<InvoicelistComponent>,
  private invoiceService: InvoiceService, private accountService: AccountService, private toastr: ToastrService,
  private payablesreceivables: PayablesreceivablesService) { }

  ngOnInit(): void {
    this.accountID = this.data.id;    
    this.clientID = this.data.clientID;
    this.archive = this.data.archive;
    this.assignUser();
  }

  archiveAccount(archive: boolean){
    const account = {
      id: this.accountID,
      archive: archive
    };

    if (archive === this.invoiceService.showarchive)
    {
      this.toastr.error("Your account already has the same type!");
      return;
    }

    this.invoiceService.archiveInvoice(account).subscribe( response => {
      if (response)
      {
        this.data.archive = true;

        // Invoices
        this.invoiceService.stopHubConnection();
        this.invoiceService.createHubConnection(this.user, this.clientID);
        this.invoiceService.stopArchiveHubConnection();
        this.invoiceService.createArchiveHubConnection(this.user, this.clientID);
        this.toastr.success("Successfully updated account!");
        
        // Payments
        this.payablesreceivables.stopHubConnection();
        this.payablesreceivables.createHubConnection(this.user, true);
      }  
    }, error => {
      this.invoiceService.stopHubConnection();
      console.log(error);
    })
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

}
