import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Client } from 'src/app/_models/client';
import { ClientService } from 'src/app/_services/client/client.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ClientlistComponent } from '../clientlist/clientlist.component';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator'; 
import { FullStopValidator } from 'src/app/_validators/fullstopvalidator';
import { AccountService } from 'src/app/_services/account/account.service';
import { User } from 'src/app/_models/user';
import { take } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-editclient',
  templateUrl: './editclient.component.html',
  styleUrls: ['./editclient.component.css']
})
export class EditclientComponent implements OnInit {
  formClient: UntypedFormGroup;
  client: Client;
  accountID: any;
  acceptApproval: string = '';
  rejectApproval: string = '';
  user?: User;

  constructor(public fb: UntypedFormBuilder, public dialog: MatDialog, private clientService: ClientService,
     @Inject(MAT_DIALOG_DATA) public data: Client, public dialogRef: MatDialogRef<ClientlistComponent>, public accountService: AccountService, 
     private toastr: ToastrService) { }

  ngOnInit(): void {
    console.log(this.data);
    this.accountID = this.data.id;
    this.initializeForm();
    this.onLoad();
    this.assignUser();
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }
  
  initializeForm(){
    this.formClient = this.fb.group({
      email:['', [Validators.email]],
      name: ['', [Validators.maxLength(255), Validators.minLength(2), Validators.required, WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      firstname: ['', [Validators.maxLength(255), Validators.minLength(2), WhiteSpacesValidator.cannotContainSpace]],
      middlename: ['',[Validators.maxLength(255), Validators.minLength(2), WhiteSpacesValidator.cannotContainSpace]],
      lastname: ['', [Validators.maxLength(255), Validators.minLength(2), WhiteSpacesValidator.cannotContainSpace]],
      contacthomephone: ['', [Validators.minLength(8), Validators.maxLength(10), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter, FullStopValidator.cannotContainFullStop]],
      contactmobile: ['', [Validators.maxLength(10), Validators.minLength(10), WhiteSpacesValidator.cannotContainSpace, FullStopValidator.cannotContainFullStop]],
      contactbussinessnumber: ['', [Validators.minLength(8), Validators.maxLength(10), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter, FullStopValidator.cannotContainFullStop]],
      bussinessname: ['', [Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      abn: ['', [Validators.maxLength(11), Validators.maxLength(11), WhiteSpacesValidator.cannotContainSpace, FullStopValidator.cannotContainFullStop]],
      address: ['', [Validators.maxLength(1000), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      addressunit: ['', [Validators.maxLength(1000), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      postcode: ['', [Validators.maxLength(1000), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      province: ['', [Validators.maxLength(1000), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      state: ['', [Validators.maxLength(1000), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      country: ['', [Validators.maxLength(1000), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      creditRatingApproved: ['', Validators.required],    
    })
  }

  onSubmit(){
    const oldClient = this.client;
    this.updateClient(); 
    this.clientService.updateClient(this.client).subscribe(response => {
      if (response)
      {
            this.toastr.success("You have successfully updated your account!");
            //this.updateData();
            this.clientService.stopHubConnection();         
            this.clientService.stopArchiveHubConnection();
            //this.clientService.clientsDataSource = new MatTableDataSource<Client>();
            this.clientService.createArchiveHubConnection(this.user);
            this.clientService.createHubConnection(this.user); 
            this.dialogRef.close();
      }

    }, error => {
      //this.KeepOldUpdateData(oldClient);
      console.log(error);
    });
  }

  onLoad(){
    this.clientService.getEditClient(this.accountID).subscribe(response => {
      this.client = response;
      this.updateInfoForm();
    }, error => {      
      console.log(error);
    });
  }

  updateInfoForm(){
    var acceptCreditRating = "false";
    var archiveContact = "false";

    /* Check update rating */
    if (this.client.creditRatingApproved === true)
    {
      acceptCreditRating = "true";
    }

    /* check for archive */
    if (this.client.archive === true)
    {
      archiveContact = "true";
    }


    /* Initiate form client values */
    this.formClient.controls['name'].setValue(this.client.name);
    this.formClient.controls['email'].setValue(this.client.email);
    this.formClient.controls['firstname'].setValue(this.client.firstName);
    this.formClient.controls['middlename'].setValue(this.client.middleName);
    this.formClient.controls['lastname'].setValue(this.client.lastName);
    this.formClient.controls['contacthomephone'].setValue(this.client.contactHomePhone);
    this.formClient.controls['contactmobile'].setValue(this.client.contactMobile);
    this.formClient.controls['contactbussinessnumber'].setValue(this.client.contactBusiness);
    this.formClient.controls['bussinessname'].setValue(this.client.bussinessName);
    this.formClient.controls['addressunit'].setValue(this.client.addressUnit);
    this.formClient.controls['postcode'].setValue(this.client.postCode);
    this.formClient.controls['province'].setValue(this.client.province);
    this.formClient.controls['state'].setValue(this.client.state);
    this.formClient.controls['country'].setValue(this.client.country);
    this.formClient.controls['address'].setValue(this.client.address);
    this.formClient.controls['creditRatingApproved'].setValue(acceptCreditRating); 
    this.formClient.controls['abn'].setValue(this.client.abn.toString());
  }

  updateClient(){
    const linkedStripe = this.client.linkedStripe; 
    var archive: boolean = false;
    var creditRating: boolean = false;
    if (this.formClient.value.archive === "true")
    {
      archive = true;
    }

    if (this.formClient.value.creditRatingApproved === "true")
    {
      creditRating = true;
    }

    this.client = {
       id: this.client.id,
       name: this.formClient.value.name,
       email: this.formClient.value.email,
       abn: this.formClient.value.abn.toString(),
       address: this.formClient.value.address,
       addressUnit: this.formClient.value.addressunit,
       postCode: this.formClient.value.postcode,
       province: this.formClient.value.province,
       state: this.formClient.value.state,
       country: this.formClient.value.country,
       bussinessName: this.formClient.value.bussinessname,
       contactHomePhone: this.formClient.value.contacthomephone,
       contactMobile: this.formClient.value.contactmobile,
       contactBusiness: this.formClient.value.contactbussinessnumber,
       firstName: this.formClient.value.firstname,
       middleName: this.formClient.value.middlename,
       lastName: this.formClient.value.lastname,
       linkedStripe: linkedStripe,
       creditRatingApproved: creditRating,
    };

    if (this.formClient.value.abn == "")
    {
      this.client.abn = 0;
    }
  }

  updateData(){
    this.data.id = this.client.id;
    this.data.name = this.client.name;
    this.data.abn = this.client.abn;
    this.data.address = this.client.address;
    this.data.bussinessName = this.client.bussinessName;
    this.data.contactHomePhone = this.client.contactHomePhone;
    this.data.contactMobile = this.client.contactMobile;
    this.data.contactBusiness = this.client.contactBusiness;
    this.data.addressUnit = this.client.addressUnit;
    this.data.postCode = this.client.postCode;
    this.data.province = this.client.province;
    this.data.state = this.client.state;
    this.data.country = this.client.country;
    this.data.email = this.client.email;
    this.data.firstName = this.client.firstName;
    this.data.middleName = this.client.middleName;
    this.data.lastName = this.client.lastName;
    this.data.linkedStripe = this.client.linkedStripe;
    this.data.archive = this.client.archive;
    var option = false;
    /* Change boolean value for credit rating approved */
    if (this.formClient.get('creditRatingApproved').value === "true")
    {
      option = true;
    }

    /* Update credit rating value */
    this.data.creditRatingApproved = option;
  }

  KeepOldUpdateData(client: Client){
    this.data.id = client.id;
    this.data.name = client.name;
    this.data.abn = client.abn;
    this.data.address = client.address;
    this.data.bussinessName = client.bussinessName;
    this.data.contactHomePhone = client.contactHomePhone;
    this.data.contactMobile = client.contactMobile;
    this.data.contactBusiness = client.contactBusiness;
    this.data.email = client.email;
    this.data.firstName = client.firstName;
    this.data.middleName = client.middleName;
    this.data.lastName = client.lastName;
    this.data.linkedStripe = client.linkedStripe;
    var option = false;
    /* Change boolean value for credit rating approved */
    if (this.formClient.get('creditRatingApproved').value === "true")
    {
      option = true;
    }

    /* Update credit rating value */
    this.data.creditRatingApproved = option;
  }
}
