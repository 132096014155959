import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImportfileInvoice } from 'src/app/_models/importfileinvoice';
import { Invoice } from 'src/app/_models/invoice';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator';

@Component({
  selector: 'app-editimportinvoice',
  templateUrl: './editimportinvoice.component.html',
  styleUrls: ['./editimportinvoice.component.css']
})
export class EditimportinvoiceComponent implements OnInit {
  formInvoice: UntypedFormGroup;
  invoice: Invoice;
  accountID: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.accountID = this.data.firstName;
    this.initializeForm();
  }

  onSubmit(){
    console.log(this.data);
    this.getDataFromSubmit();
  }

  initializeForm(){
    const initialDate = new Date(this.data.initalDate);
    const dueDate = new Date(this.data.dueDate);
    console.log(this.data);

    var note = this.data.note;
    if (this.data.note == null)
      note = "";

    this.formInvoice = this.fb.group({
      invoiceNumber:[this.data.invoiceNumber, [Validators.required]],
      invoiceAmount: [this.data.invoiceAmount, [Validators.required, Validators.max(999999999999999)]],
      paidAmount: [this.data.paidAmount, [Validators.required, Validators.max(999999999999999)]],
      initialDate: [initialDate, [Validators.required]],
      dueDate: [dueDate, [Validators.required]],
      firstName: [this.data.firstName, [Validators.required, WhiteSpacesValidator.cannotContainSpace]],
      lastName: [this.data.lastName, [Validators.required, WhiteSpacesValidator.cannotContainSpace]],
      contactMobile: [this.data.contactMobile, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      note: [note, [WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter, Validators.maxLength(1000)]],
    })
  }

  getDataFromSubmit(){
    this.data.invoiceAmount = +this.formInvoice.value.invoiceAmount;
    this.data.paidAmount = +this.formInvoice.value.paidAmount;
    this.data.initalDate = this.formInvoice.value.initialDate;
    this.data.dueDate = this.formInvoice.value.dueDate;
    this.data.firstName = this.formInvoice.value.firstName;
    this.data.lastName = this.formInvoice.value.lastName;
    this.data.note = this.formInvoice.value.note;
    this.data.invoiceNumber = this.formInvoice.value.invoiceNumber;
    this.data.contactMobile = this.formInvoice.value.contactMobile;
  }
}
