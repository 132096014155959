<ng-container *ngIf="services.membersdataSource">
    <h1 class="text-center text-primary mt-4 font-weight-bold"><i class="fas fa-users fa-lg"></i> Members group</h1>
    <div class="bs-component mt-4">
        <nav class="navbar navbar-expand-lg navbar-dark bg-primary rounded ">
            <div class="container-fluid">
                <a class="navbar-brand"><i class="fas fa-search fa-lg"></i></a>
                <a class="navbar-brand text-white font-weight-bold" > Search Features</a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarColor01">
                    <ul class="navbar-nav me-auto">
                    <li class="nav-item">
                    
                    </li>
                    </ul>
                    <form class="d-flex">
                    <input class="form-control me-sm-2" type="search" placeholder="Search" (keyup)="applyFilter($event)">
                    </form>
                </div>
            </div>
        </nav>
    </div>
    
    <div class="mat-elevation-z8 mt-4">
    <table mat-table [dataSource]="services.membersdataSource" matSort>
        <!-- Group Name Column -->
        <ng-container matColumnDef="groupname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Group Name </th>
            <td mat-cell *matCellDef="let row"> {{row.groupName}} </td>
        </ng-container>

        <!-- First Name Column -->
        <ng-container matColumnDef="firstname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> First name </th>
        <td mat-cell *matCellDef="let row"> {{row.firstName}} </td>
        </ng-container>

        <!-- Last Name Column -->
        <ng-container matColumnDef="lastname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
        <td mat-cell *matCellDef="let row"> {{row.lastName}} </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let row"> {{row.email}} </td>
        </ng-container>

        <!-- Activation Column -->
        <ng-container matColumnDef="activate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> User Activate Account? </th>
            <td mat-cell *matCellDef="let row"> 
                <ng-container *ngIf="row.didEmailActiviate; else shownotactiviate" >
                    <span class="badge bg-success">Yes</span>
                </ng-container>
                <ng-template #shownotactiviate>
                    <span class="badge bg-danger">No</span>
                </ng-template>
            </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>  </th>
            <td mat-cell *matCellDef="let row">  
                <button mat-icon-button color="primary" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item [routerLink]="['/members/editmemberinagroup', row.id]">
                    <mat-icon color="primary">account_box</mat-icon>
                    <span>Edit Member Account</span>
                    </button>
                    <button mat-menu-item  (click)="deleteAccount(row.id)">
                    <mat-icon color="warn">delete</mat-icon>
                    <span>Delete Member Account</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    </div>
</ng-container>