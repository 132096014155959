<app-nav></app-nav>
<div class="container mt-4">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <!-- Just an narbar title -->
          <nav class="navbar navbar-light bg-primary shadow-lg">
            <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Edit member in a group</a>
          </nav>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]= "['/members/usergroups']">usergroups</a></li>
            <li class="breadcrumb-item active">
              Edit Member
            </li>
          </ol>
        </div>
        <div class="col-12">
            <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded">
                <div class="card-body"> 
                  <h2 class="text-center text-primary mt-2">EDIT MEMBER</h2>
                  <hr>
                  <form #editForm="ngForm" id="editForm" [formGroup]="userMember" (ngSubmit)="userMember.valid" autocomplete="off">     
                    <div class="row">                     
                      <div class="bs-component">
                        <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
                            <button type="button" class="close" data-dismiss="alert">×</button>
                            <h4 class="alert-heading">Information!</h4>
                            <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
                        </div>             
                      </div>
                      <div class="col-12">
                        <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i>  Personal Details:</h4>
                        <app-text-input [formControl]='userMember.controls["firstname"]' [label]='"*First Name"' [smalltext]='""' [placeholder]='"First Name"'> </app-text-input>  
                        <app-text-input [formControl]='userMember.controls["lastname"]' [label]='"*Last Name"' [smalltext]='""' [placeholder]='"Last Name"'> </app-text-input>                      
                        <div class="form-group">
                            <fieldset disabled="">
                              <label class="form-label" >Email</label>
                              <input class="form-control" [formControl]='userMember.controls["email"]' type="email" placeholder="Email" disabled="">
                              <div class="valid-feedback">Notification to this Email has already sent!</div>
                            </fieldset>
                          </div>    
                        <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i>  Choose Group:</h4>  
                        <div class="form-group">
                            <label for="groupnames" class="form-label">*Group Name</label>
                            <select class="form-select" id="groupnames" [formControl]='userMember.controls["groupid"]'>    
                                <option *ngFor="let group of services.groupDataSource.filteredData" [value]="group.id">
                                    {{group.groupName}}
                                </option>                       
                            </select>
                          </div>   
                      </div>                
                    </div>
                  </form>
                </div>
                <div class="card-footer text-muted">
                  <div class="row">
                    <div class="col-xl-6 col lg-6 col col-md-6 col-sm-12 col-xm-12">
                      <button [disabled]="!userMember.valid" class="btn btn-success btn-block" form="editForm" (click)="updateUsermember()" ><i class="fas fa-user"></i> UPDATE USER IN GROUP</button>
                    </div>
                    <div class="col-xl-6 col lg-6 col col-md-6 col-sm-12 col-xm-12">
                      <button class="btn btn-primary btn-block" [routerLink]= "['/members/usergroups']"><i class="fas fa-circle-xmark"></i> Cancel</button>
                    </div>
                  </div>               
                </div>
            </div>
        </div>
    </div>
</div>
        