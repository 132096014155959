<h2 class="text-center text-primary"> PRIVACY POLICY</h2>
<ul class="list-unstyled">
    <li class="font-weight-bold">1.   INTRODUCTION</li>
    <li class="mt-2"><p>This document sets out the privacy policy of INVOICE PAGER PTY LTD ABN 32 670 833 842  trading under the registered business name “Invoice Pager” (referred to in this privacy policy as ‘we’, ‘us’, or ‘our’).
        We take our privacy obligations seriously and we’ve created this privacy policy to explain how we store, maintain, use and disclose personal information.
        By providing personal information to us, you consent to our storage, maintenance, use and disclosing of personal information in accordance with this privacy policy.
        We may change this privacy policy from time to time by posting an updated copy on our website and we encourage you to check our website regularly to ensure that you are aware of our most current privacy policy.
        </p> 
    </li>
    <li class="font-weight-bold">2.   TYPES OF PERSONAL INFORMATION WE COLLECT 
    </li>
    <li>
        <p class="mt-2">The personal information we collect may include the following:
    </li>
    <li>(a)	name;</li>
    <li>(b)	mailing or street address;</li>
    <li>(c)	email address;</li>
    <li>(d)	social media information;</li>
    <li>(e)	telephone number and other contact details;</li>
    <li>(f)	age;</li>
    <li>(g)	date of birth;</li>
    <li>(h)	credit card or other payment information;</li>
    <li>(i)	information about your business or personal circumstances;</li>
    <li>(j)	information in connection with client surveys, questionnaires and promotions;</li>
    <li>(k)	your device identity and type, I.P. address, geo-location information, page view statistics, advertising data and standard web log information;</li>
    <li>(l)	information about third parties; and</li>
    <li>(m)	any other information provided by you to us via our website or our online presence, or otherwise required by us or provided by you.</li>
    <li class="mt-2 font-weight-bold">3.   HOW WE COLLECT PERSONAL INFORMATION</li>
    <li class="mt-2"><p>We may collect personal information either directly from you, or from third parties, including where you: </p></li>
    <li>(a)	contact us through our website;</li>
    <li>(b)	receive goods or services from us;</li>
    <li>(c)	submit any of our online sign up forms;</li>
    <li>(d)	communicate with us via email, telephone, SMS, social applications (such as LinkedIn, Facebook or Twitter) or otherwise;</li>
    <li>(e)	interact with our website, social applications, services, content and advertising; and</li>
    <li>(f)	invest in our business or enquire as to a potential purchase in our business.</li>
    <li><p>We may also collect personal information from you when you use or access our website or our social media pages. 
        This may be done through use of web analytics tools, ‘cookies’ or 
        other similar tracking technologies that allow us to track and analyse your website usage. Cookies
         are small files that store information on your computer, mobile phone or other device and 
         enable and allow the creator of the cookie to identify when you visit different websites.
          If you do not wish information to be stored as a cookie, you can disable cookies in your web browser.</p>
    </li>
    <li class="font-weight-bold">4.	USE OF YOUR PERSONAL INFORMATION</li>
    <li class="mt-2"><p>We collect and use personal information for the following purposes:</p>
    </li>
    <li>(a)	to provide goods, services or information to you;</li>
    <li>(b)	for record keeping and administrative purposes;</li>
    <li>(c)	to provide information about you to our contractors, employees, consultants, agents or other third parties for the purpose of providing goods or services to you;</li>
    <li>(d)	to improve and optimise our service offering and customer experience;</li>
    <li>(e)	to comply with our legal obligations, resolve disputes or enforce our agreements with third parties;</li>
    <li>(f)	to send you marketing and promotional messages and other information that may be of interest to you and for the purpose of direct marketing (in accordance with the Spam Act). In this regard, we may use email, SMS, social media or mail to send you direct marketing communications.
         You can opt out of receiving marketing materials from us by using the opt-out facility provided (e.g. an unsubscribe link);
    </li>
    <li>(g)	to send you administrative messages, reminders, notices, updates, security alerts, and other information requested by you; and</li>
    <li>(h)	to consider an application of employment from you.</li>
    <li><p>	We may disclose your personal information to cloud-providers, contractors and other third parties located inside or outside of Australia. If we do so, we will take reasonable steps to ensure that any overseas recipient deals with such personal information in a manner consistent with how we deal with it.</p>
    </li>
    <li class="font-weight-bold">5.    SECURITY</li>
    <li class="mt-2"><p>	We take reasonable steps to ensure your personal information is secure and protected from misuse or unauthorised access. Our information technology systems are password protected, and we use a range of administrative and technical measures to protect these systems. However, we cannot guarantee the security of your personal information.</p>
    </li>
    <li class="font-weight-bold">6.	LINKS</li>
    <li class="mt-2"><p>	Our website may contain links to other websites. Those links are provided for convenience and may not remain current or be maintained. We are not responsible for the privacy practices of those linked websites and we suggest you review the privacy policies of those websites before using them.</p>
    </li>
    <li class="font-weight-bold">7.	REQUESTING ACCESS OR CORRECTING YOUR PERSONAL INFORMATION</li>
    <li class="mt-2"><p>	If you wish to request access to the personal information we hold about you, please contact us using the contact details set out below including your name and contact details. We may need to verify your identity before providing you with your personal information. In some cases, we may be unable to provide you with access to all your personal information and where this occurs, we will explain why. We will deal with all requests for access to personal information within a reasonable timeframe.</p>
    </li>
    <li><p>	If you think that any personal information we hold about you is inaccurate, please contact us using the contact details set out below and we will take reasonable steps to ensure that it is corrected.</p>
    </li>
    <li class="font-weight-bold">8.	COMPLAINTS</li>
    <li class="mt-2"><p>	If you wish to complain about how we handle your personal information held by us, please contact us using the details set out below including your name and contact details. We will investigate your complaint promptly and respond to you within a reasonable timeframe.</p>
    </li>
    <li class="font-weight-bold">9.  CONTACT US</li>
    <li class="mt-2"><p>	For further information about our privacy policy or practices, or to access or correct your personal information, or make a complaint, please contact us using the details set out below:</p>
        <p>Email: support&#64;invoicepager.com</p>
        <p>Our privacy policy was last updated on 2 October 2023.</p>  
    </li>
</ul>
