<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to edit a store account</h2>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Store Credit/Edit Store Payment/esa01.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Click the three (3) dots beside the “Store Account” you wish to edit</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Store Credit/Edit Store Payment/esa02.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Click on “Edit Payment Account Profile” </h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Store Credit/Edit Store Payment/esa03.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Correct any necessary information as needed</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Store Credit/Edit Store Payment/esa04.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Click “Confirm Edit Account” to save the changes</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Store Credit/Edit Store Payment/esa05.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	These changes will now be reflected in the “Client Information” section near the top of the page”</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Store Credit/Edit Store Payment/esa06.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	These changes will also be reflected in the “List of In-store payments” near the bottom of the page</h2>
    </div>