import { HttpClient } from '@angular/common/http';
import { Injectable, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { map } from 'rxjs/operators';
import { GetInfoUserGroupPermission } from 'src/app/_models/getinfousergrouppermissions';
import { MemberingroupPermissions } from 'src/app/_models/memberingrouppermissions';
import { PaginatedResult } from 'src/app/_models/pagination';
import { UserGroupsPermissions } from 'src/app/_models/usergrouppermissions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsergroupspermissionsService {
  @ViewChild(MatPaginator) groupPaginator: MatPaginator;
  @ViewChild(MatPaginator) memberPaginator: MatPaginator;
  @ViewChild(MatSort) membersort: MatSort;
  baseUrl =  environment.apiUrl;
  paginatedResult: PaginatedResult<UserGroupsPermissions[]> = new PaginatedResult<UserGroupsPermissions[]>();
  public Toggle: boolean = false;
  public ToggleText: string = "Minimize Window";
  public Icon: string = "fa fa-window-minimize";
  public ToggleCreate: boolean = true;
  public ToggleTextCreate: string = "Maximize Window";
  public IconCreate: string = "fa fa-window-maximize";
  public showMember: boolean = false;
  public groupDataSource = new MatTableDataSource<UserGroupsPermissions>();
  public membersdataSource: MatTableDataSource<MemberingroupPermissions>;
  public groupNames: string[] = [];
  public getFirstOptionSelectedForGroups: number = 0;
  public getInfoGroupPermissions: GetInfoUserGroupPermission;
  constructor(private http: HttpClient) { }

  createUserGroup(model: UserGroupsPermissions){
    return this.http.post(this.baseUrl + "usergroup/createusergroup", model).pipe(
      map((response: boolean) => {
        return response;            
      })); 
  } 

  createMemberinGroup(model: MemberingroupPermissions){
    return this.http.post(this.baseUrl + "usergroup/createuseringroup", model).pipe(
      map((response: boolean) => {
        return response;            
      })); 
  }

  updateUserMember(model: MemberingroupPermissions){
    return this.http.put(this.baseUrl + "usergroup/updateusermember", model).pipe(
      map((response: boolean) => {
        return response;            
      })); 
  } 

  updateUserGroup(model: UserGroupsPermissions){
    return this.http.put(this.baseUrl + "usergroup/updateusergroup", model).pipe(
      map((response: boolean) => {
        return response;            
      })); 
  } 
  
  getUserGroups(){
    return this.http.get(this.baseUrl + "usergroup/getusergroups").pipe(
      map((response: UserGroupsPermissions[]) => {                
        return response;
      })
    )
  }

  getMembersFromGroup(){
    return this.http.get(this.baseUrl + "usergroup/getmembersfromgroup").pipe(
      map((response: any) => {     
       
        return response;
      })
    )
  }

  getUserGroup(id: string){
    return this.http.get(this.baseUrl + "usergroup/getusergroup?id=" + id).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getUserMember(id: string){
    return this.http.get(this.baseUrl + "usergroup/getusermember?id=" + id).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  loadConfirmEmail(token: string, id: string){
    return  this.http.get(this.baseUrl + "usergroup/getconfirmemail?token=" + token + '&id=' + id).pipe(
      map((response: boolean) => {
        return response;      
      })
    );
  }

  deleteGroup(id: number){
    return this.http.delete(this.baseUrl + "usergroup/deleteusergroup?accountid=" + id).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  deleteMember(id: number){
    return this.http.delete(this.baseUrl + "usergroup/deletemember?accountid=" + id).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  getInfoUserGroupPermissions(){
    return this.http.get(this.baseUrl + "usergroup/getinfousergrouppermissions").pipe(
      map((response: GetInfoUserGroupPermission) => {
        return response;
      })
    )
  }

  leaveGroup(){
    return this.http.get(this.baseUrl + "usergroup/leavegroup").pipe(
      map((reponse: boolean) => {
        return reponse;
      })
    )
  }

  toggleWindow(): void{  
    this.Toggle = !this.Toggle;
    if (this.Toggle)
    {
      this.ToggleText = "Maximize Window";
      this.Icon = "fa fa-window-maximize";
    }
    else
    {
      this.ToggleText = "Minimize Window";
      this.Icon = "fa fa-window-minimize";
    }
  }

  toggleWindowCreate(): void{  
    this.ToggleCreate = !this.ToggleCreate;
    if (this.ToggleCreate)
    {
      this.ToggleTextCreate = "Maximize Window";
      this.IconCreate = "fa fa-window-maximize";
    }
    else
    {
      this.ToggleTextCreate = "Minimize Window";
      this.IconCreate = "fa fa-window-minimize";
    }
  }


}
