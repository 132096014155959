
<div class="container-fluid mt-4">
    <html>

    <head>

    </head>

    <body lang=EN-AU link="#FA2B5C" vlink="#BC658E" style='word-wrap:break-word' *ngIf="clientSubscription$ | async as subscription">

    <div class=WordSection1>

    <table class=MsoTable15Plain4 border=0 cellspacing=0 cellpadding=0
    summary="First table contains information like the Company Name, address, invoice number, date, etc. and the second table is the main invoice table with the description and amount"
    width="98%" style='width:98.34%;border-collapse:collapse'>
    <tr style='height:57.55pt'>
    <td width=408 valign=top style='width:305.95pt;padding:0cm 0cm 0cm 0cm;
    height:57.55pt'>
    <h1 align=left style='margin-bottom:0cm;text-align:left'><span lang=EN-US
    style='font-size:18.0pt;color:#B71E42;font-weight:normal'><img width=183
    height=183 id="Picture 1"
    src="https://live.staticflickr.com/65535/53207285410_ab3276867a_c.jpg"
    alt="A logo of a tablet and envelope&#10;&#10;Description automatically generated"></span></h1>
    </td>
    <td width=300 valign=top style='width:225.05pt;padding:0cm 0cm 0cm 0cm;
    height:57.55pt'>
    <h1 style='margin-bottom:0cm'><span lang=EN-US style='color:#00B0F0'>RECEIPT</span></h1>
    </td>
    </tr>
    <tr style='height:67.45pt'>
    <td width=408 valign=top style='width:305.95pt;padding:0cm 0cm 0cm 0cm;
    height:67.45pt'>
    <p class=ContactInfoCxSpFirst><span lang=EN-US>&nbsp;</span></p>
    <p class=ContactInfoCxSpMiddle><span lang=EN-US>Ph: 0430 386 505</span></p>
    <p class=ContactInfoCxSpMiddle><span lang=EN-US><a
    href="mailto:billingaccounts@invoicepager.com">billingaccounts@invoicepager.com</a></span></p>
    <p class=ContactInfoCxSpMiddle><span lang=EN-US>ABN: 32 670 833 842</span></p>
    <p class=ContactInfoCxSpLast><span lang=EN-US>&nbsp;</span></p>
    </td>
    <td width=300 valign=top style='width:225.05pt;padding:0cm 0cm 0cm 0cm;
    height:67.45pt'>
    <p class=Rightalign style='margin-bottom:0cm'><strong><span lang=EN-US>&nbsp;</span></strong></p>
    <p class=Rightalign style='margin-bottom:0cm'><strong><span lang=EN-US
    style='color:#00B0F0'>RECEIPT</span></strong><span class=Heading3Char><span
    lang=EN-US style='color:#00B0F0'> </span></span><span class=Heading3Char><span
    lang=EN-US style='color:#0070C0'># {{subscription.id}}</span></span></p>
    <p class=Rightalign style='margin-bottom:0cm'><span class=Heading3Char><span
    lang=EN-US style='color:#C00000'>        </span></span><strong><span
    lang=EN-US style='color:#C00000'><span style='color:#0070C0'>DATE</span></span></strong><span
    class=Heading3Char><span lang=EN-US>  </span></span><span class=Heading3Char><span
    lang=EN-US style='color:black'>{{subscription.startDate | date: 'dd/MM/yyyy'}}</span></span></p>
    </td>
    </tr>
    <tr style='height:93.55pt'>
    <td width=408 valign=top style='width:305.95pt;padding:0cm 0cm 0cm 0cm;
    height:93.55pt'>
    <p class=ContactInfoCxSpFirst><strong><span lang=EN-US><span
    style='color:#0070C0'>TO</span></span></strong></p>
    <p class=ContactInfoCxSpMiddle><span lang=EN-US>{{subscription.fullName}}</span></p>
    <p class=ContactInfoCxSpLast><span lang=EN-US>&nbsp;</span></p>
    </td>
    <td width=300 valign=top style='width:225.05pt;padding:0cm 0cm 0cm 0cm;
    height:93.55pt'>
    <p class=Rightalign style='margin-bottom:0cm'><strong><span lang=EN-US><span
    style='color:#0070C0'>FOR</span></span></strong><span lang=EN-US> Subscription
    Bill</span></p>
    <p class=Rightalign style='margin-bottom:0cm'><span lang=EN-US> </span></p>
    </td>
    </tr>
    </table>

    <table class=MsoTable15Plain4 border=0 cellspacing=0 cellpadding=0
    summary="First table contains information like the Company Name, address, invoice number, date, etc. and the second table is the main invoice table with the description and amount"
    align=left width="93%" style='width:93.4%;border-collapse:collapse;margin-left:
    6.75pt;margin-right:6.75pt'>
    <tr style='height:42.25pt'>
    <td width=228 valign=top style='width:171.05pt;padding:0cm 0cm 0cm 0cm;
    height:42.25pt'>
    <h5 style='margin-bottom:0cm;line-height:normal'><em><span lang=EN-US
    style='color:#0070C0'>DESCRIPTION</span></em></h5>
    </td>
    <td width=118 valign=top style='width:88.8pt;padding:0cm 0cm 0cm 0cm;
    height:42.25pt'>
    <h5 style='margin-bottom:0cm;line-height:normal'><em><span lang=EN-US
    style='color:#0070C0'>UNIT PRICE</span></em></h5>
    </td>
    <td width=83 valign=top style='width:62.1pt;padding:0cm 0cm 0cm 0cm;
    height:42.25pt'>
    <h5 style='margin-bottom:0cm;line-height:normal'><em><span lang=EN-US
    style='color:#0070C0'>&nbsp;</span></em></h5>
    </td>
    <td width=220 colspan=3 valign=top style='width:165.35pt;padding:0cm 0cm 0cm 0cm;
    height:42.25pt'>
    <h5 style='margin-bottom:0cm;line-height:normal'><em><span lang=EN-US
    style='color:#0070C0'>          Total</span></em></h5>
    <h5 align=right style='margin-bottom:0cm;text-align:right;line-height:normal'><em><span
    lang=EN-US style='color:#0070C0'>&nbsp;</span></em></h5>
    </td>
    <td width=15 valign=top style='width:11.1pt;padding:0cm 0cm 0cm 0cm;
    height:42.25pt'>
    <h5 align=right style='margin-bottom:0cm;text-align:right;line-height:normal'><em><span
    lang=EN-US style='color:#C00000'>&nbsp;</span></em></h5>
    </td>
    <td style='border:none;padding:0cm 0cm 0cm 0cm' width=8><p class='MsoNormal'>&nbsp;</td>
    </tr>
    <tr style='height:19.2pt'>
    <td width=228 valign=top style='width:171.05pt;background:#F2F2F2;padding:
    0cm 0cm 0cm 0cm;height:19.2pt'>
    <p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><b><span
    lang=EN-US>
    {{getProductType(subscription.productType)}} {{addStringStartDate()}}  {{subscription.startDate | date: 'dd/MM/yyyy'}} {{addStringEndDate()}} {{subscription.endDate | date: 'dd/MM/yyyy'}}

    </span></b></p>
    </td>
    <td width=118 valign=top style='width:88.8pt;background:#F2F2F2;padding:0cm 0cm 0cm 0cm;
    height:19.2pt'>
    <p class=Amount align=left style='margin-bottom:0cm;text-align:left'><span
    lang=EN-US>{{subscription.clientpaidTotalcostWithGST | currency }}</span></p>
    </td>
    <td width=83 valign=top style='width:62.1pt;background:#F2F2F2;padding:0cm 0cm 0cm 0cm;
    height:19.2pt'>
    <p class=Amount align=center style='margin-bottom:0cm;text-align:center'><span
    lang=EN-US>&nbsp;</span></p>
    </td>
    <td width=220 colspan=3 valign=top style='width:165.35pt;background:#F2F2F2;
    padding:0cm 0cm 0cm 0cm;height:19.2pt'>
    <p class=Amount align=center style='margin-bottom:0cm;text-align:left'><span
    lang=EN-US>{{subscription.clientpaidTotalcostWithGST | currency }}</span></p>
    <p class=Amount style='margin-bottom:0cm'><span lang=EN-US>&nbsp;</span></p>
    <p class=Amount style='margin-bottom:0cm'><span lang=EN-US>&nbsp;</span></p>
    </td>
    <td width=15 valign=top style='width:11.1pt;background:#F2F2F2;padding:0cm 0cm 0cm 0cm;
    height:19.2pt'>
    <p class=Amount style='margin-bottom:0cm'><span lang=EN-US>&nbsp;</span></p>
    </td>
    <td style='border:none;padding:0cm 0cm 0cm 0cm' width=8><p class='MsoNormal'>&nbsp;</td>
    </tr>
    <tr style='height:18.25pt'>
    <td width=228 valign=top style='width:171.05pt;padding:0cm 0cm 0cm 0cm;
    height:18.25pt'>
    <p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><em><span
    lang=EN-US style='font-weight:normal'><b><span style='color:#0070C0'>Total</span></b></span></em></p>
    </td>
    <td width=118 valign=top style='width:88.8pt;padding:0cm 0cm 0cm 0cm;
    height:18.25pt'>
    <p class=Amount align=left style='margin-bottom:0cm;text-align:left'><b><span
    lang=EN-US>&nbsp;</span></b></p>
    </td>
    <td width=83 valign=top style='width:62.1pt;padding:0cm 0cm 0cm 0cm;
    height:18.25pt'>
    <p class=Amount align=left style='margin-bottom:0cm;text-align:left'><b><span
    lang=EN-US>&nbsp;</span></b></p>
    </td>
    <td width=15 valign=top style='width:11.1pt;padding:0cm 0cm 0cm 0cm;
    height:18.25pt'>
    <p class=Amount align=left style='margin-bottom:0cm;text-align:left'><b><span
    lang=EN-US>&nbsp;</span></b></p>
    </td>
    <td width=171 valign=top style='width:128.55pt;padding:0cm 0cm 0cm 0cm;
    height:18.25pt'>
    <p class=Amount align=left style='margin-bottom:0cm;text-align:left'><b><span
    lang=EN-US>{{subscription.clientpaidTotalcostWithGST | currency }}</span></b></p>
    </td>
    <td width=57 colspan=3 valign=top style='width:42.75pt;padding:0cm 0cm 0cm 0cm;
    height:18.25pt'>
    <p class=Amount align=center style='margin-bottom:0cm;text-align:center'><b><span
    lang=EN-US>   </span></b></p>
    </td>
    </tr>
    <tr height=0>
    <td width=225 style='border:none'></td>
    <td width=117 style='border:none'></td>
    <td width=81 style='border:none'></td>
    <td width=14 style='border:none'></td>
    <td width=169 style='border:none'></td>
    <td width=33 style='border:none'></td>
    <td width=14 style='border:none'></td>
    <td width=8 style='border:none'></td>
    </tr>
    </table>

    <p class=MsoClosingCxSpFirst><b><span lang=EN-US style='font-size:9.0pt;
    line-height:115%;font-family:"Arial",sans-serif'><br clear=all>
    </span></b></p>

    <p class=MsoClosingCxSpMiddle><span lang=EN-US style='font-size:7.0pt;
    line-height:115%'>&nbsp;</span></p>

    <p class=MsoClosingCxSpMiddle><span class=Heading5Char><span lang=EN-US
    style='font-size:10.0pt;line-height:115%;color:#0070C0'>&nbsp;</span></span></p>


    </div>

    </body>

    </html>

</div>
