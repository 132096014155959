import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { AccountsReceivablespayments } from 'src/app/_models/accountsreceivablespayments';
import { Pagination } from 'src/app/_models/pagination';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { HostpresenceService } from 'src/app/_services/hubsignalr/hostpresence.service';
import { ReceivableaccountspaymentsService } from 'src/app/_services/receivablesaccountspayments/receivableaccountspayments.service';
import { TogglewindowService } from 'src/app/_services/togglewindow.service';

@Component({
  selector: 'app-accountsreceivablepayments',
  templateUrl: './accountsreceivablepayments.component.html',
  styleUrls: ['./accountsreceivablepayments.component.css']
})
export class AccountsreceivablepaymentsComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['invoice', 'name', 'paymentMethod', 'amount', 'paymentType', 'datePaid', 'actions'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  user?: User;
  timeLeft: number = 1;
  interval;
  showText: string = "Show Only Archive Accounts";
  titleText: string = "Cash Flow Income (Non-Archive)";
  toggleCashflowBtn: boolean = false;
  buttonClass = "btn btn-danger my-2 my-sm-0 font-weight-bold";

  accountsDataSource: MatTableDataSource<AccountsReceivablespayments> = new MatTableDataSource<AccountsReceivablespayments>();

  constructor(private receivablesaccountspaymentService: ReceivableaccountspaymentsService, public accountService: AccountService
    ,private presenceHub: HostpresenceService, private toastr: ToastrService, public toggle: TogglewindowService) 
  {

  }

  ngOnDestroy(): void {
    this.presenceHub.pageActive[6] = false;
    this.receivablesaccountspaymentService.stopHubConnection();

  }

  ngOnInit(): void {
   window.scrollTo(0, 0);
   this.assignUser();
   this.presenceHub.pageActive[6] = true;
   this.loadMembers();
   this.toastr.info("Please wait! We are getting your records now!");
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  loadMembers(){    
    this.receivablesaccountspaymentService.stopHubConnection();
    this.receivablesaccountspaymentService.createHubConnection(this.user, false);
    this.startTimer();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.receivablesaccountspaymentService.accountsDataSource$.filter = filterValue.trim().toLowerCase();

    if (this.receivablesaccountspaymentService.accountsDataSource$.filter) {
      this.receivablesaccountspaymentService.accountsDataSource$.paginator.firstPage();
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.receivablesaccountspaymentService.accountsDataSource$.paginator = this.paginator;
        this.receivablesaccountspaymentService.accountsDataSource$.sort = this.sort;
        this.timeLeft = 10;
        this.presenceHub.pageActive[6] = true;
      }
    },1000)
  }

  toggleButtonPayments(){
    this.toggleCashflowBtn = !this.toggleCashflowBtn;
    this.toastr.info("Please wait! We are getting your records now!");

    if (this.toggleCashflowBtn)
    {
      this.titleText = "Only Showing Cash Flow Income (Archive Accounts Only)";
      this.showText = "Show All Accounts except for Archive Accounts";
      this.buttonClass = "btn btn-success my-2 my-sm-0 font-weight-bold";
      this.showOnlyArchives();
    }
    else
    {
      this.titleText = "Cash Flow Income (Non-Archive)";
      this.showText = "Show Archive Accounts Only";
      this.buttonClass = "btn btn-danger my-2 my-sm-0 font-weight-bold";
      this.loadMembers();
    }
  }

  private showOnlyArchives(){
    this.receivablesaccountspaymentService.getArchiveAccounts().subscribe(response => {
      console.log(response);
      if (response != null)
      {
        this.accountsDataSource.data = response;
      }

    }, err => {
      console.log(err);
    })
  }

}
