import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { PayablesreceivablesService } from 'src/app/_services/payablesreceivablesaccounts/payablesreceivables.service';

@Component({
  selector: 'app-dialogsendingallsms',
  templateUrl: './dialogsendingallsms.component.html',
  styleUrls: ['./dialogsendingallsms.component.css']
})
export class DialogsendingallsmsComponent implements OnInit {

  user?: User;
  constructor(@Inject(MAT_DIALOG_DATA) public data, private payablesreceivables: PayablesreceivablesService,
  private toastr: ToastrService, public accountService: AccountService) { }

  ngOnInit(): void {
    this.assignUser();
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  sendALLSMS(){
    this.payablesreceivables.sendALLSMS().toPromise().then(res => {
      this.data.loadpage = 1;
      this.toastr.success("Sent All Messages!");
      this.loadMembers();
    }, err => {
      console.log(err);
    })
  }

  loadMembers(){
    this.payablesreceivables.stopHubConnection();
    this.payablesreceivables.stopHubConnection();
    this.payablesreceivables.stopHubConnection();
    this.payablesreceivables.stopHubConnection();
    if (this.user !== null)
    {
      if (this.user.token !== null)
      {
        this.payablesreceivables.createHubConnection(this.user, true);
      }
    }
  }

}
