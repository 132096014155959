<app-nav></app-nav>
<div class="container mt-4">
    <div class="row">
    
        <!-- Just an narbar title -->
        <nav class="navbar navbar-light bg-primary shadow-lg">
          <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-money fa-2x" aria-hidden="true"></i> Info payments For Store Account</a>
          <button type="button" class="btn btn-outline-light btn-lg mt-2 mt-md-0" (click)="toggle.toggleWindow(31)"><i class="{{toggle.$Icon[31]}}" aria-hidden="true\"></i> {{toggle.$ToggleText[31]}}</button>
        </nav>
        <ol class="breadcrumb bg-light">
          <li class="breadcrumb-item"><a [routerLink]= "['/members/clients']">Clients</a></li>
          <li class="breadcrumb-item active">Store Account</li>
        </ol>
        <div class="col-12 mt-4" [hidden]="!toggle.$Toggle[31]">
          <div class="ngcontainer">           
            <div class="card-body" *ngIf="clientInfo">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xm-12">
                    <img src="/assets/images/profile-account.jpg" alt="profile account" class="img-thumbnail rounded center">
                    <h2 class="card-title text-center bg-white p-2 font-weight-bold text-primary fs-3 text-uppercase shadow-sm"><i class="fas fa-house-user fa-2x text-primary"></i> Client Information </h2>
                    <p class="card-text text-center p-2 fs-4"><b>Client Name: </b> {{ clientInfo.name }}</p>
                    <p class="card-text text-center p-2 fs-4"><b>Total Credits: </b> <b class="text-success">{{ clientInfo.totalCredits | currency }}</b></p>
                    <p class="card-text text-center p-2 fs-4"><b>Total Debits: </b> <b class="text-primary">{{ clientInfo.totalDebits | currency }}</b></p>
                    <p class="card-text text-center p-2 fs-5 border"><b>Total Balance (Credits - Debits): </b> 
                      <ng-container *ngIf="(clientInfo.totalCredits - clientInfo.totalDebits) > 0; else showRedCredit">
                        <b class="text-success">{{ clientInfo.totalCredits - clientInfo.totalDebits | currency  }}</b>
                      </ng-container>
                      <ng-template #showRedCredit>
                        <b class="text-danger">{{ clientInfo.totalCredits - clientInfo.totalDebits | currency  }}</b>
                      </ng-template>
                    </p>   
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xm-12">
                    <div class="card border shadow-lg">
                      <div class="card-body bg-secondary">
                        <fieldset class="form-group">
                          <legend class="text-center text-primary fs-2 text-uppercase font-weight-bold">How to use This?</legend>
                          <div class="form-group mt-4">
                            <div class="embed-responsive embed-responsive-16by9">
                              <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/nSpmyck2y2Y?si=_lTZucO69HvQvRFh" allowfullscreen></iframe>
                            </div>
                            <button class="btn btn-info ms-2 btn-block mt-4" routerLink="/members/turtorialhome"><i class="fas fa-upload fa-lg"></i> Click Here to learn more!</button>
                          </div>             
                        </fieldset>
                      </div>
                    </div>          
                  </div>
                </div>
            </div>
          </div>
        </div>
        <nav class="navbar navbar-light bg-primary shadow-lg">
          <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-money fa-2x" aria-hidden="true"></i> Create Store Account</a>
          <button type="button" class="btn btn-outline-light btn-lg mt-2 mt-md-0" (click)="toggle.toggleWindow(1)"><i class="{{toggle.$Icon[1]}}" aria-hidden="true\"></i> {{toggle.$ToggleText[1]}}</button>
        </nav>
          <div class="col-12" [hidden]="!toggle.$Toggle[1]">
         
              <div class="card mt-4 border-info">
            
                  <div class="card-body">
                    <h2 class="text-center text-primary mt-2"><i class="fas fa-credit-card fa-lg"></i> Create Store Account</h2>
                    <hr>
                    <form #editForm="ngForm" id="editForm" [formGroup]="formAccount" (ngSubmit)="formAccount.valid" autocomplete="off">
                      <div class="bs-component">
                        <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
                          <button type="button" class="close" data-dismiss="alert">×</button>
                          <h4 class="alert-heading">Information!</h4>
                          <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                          <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-calendar"></i> Account Payment:</h4>
                          <app-text-input [formControl]='formAccount.controls["paymentamount"]' [label]='"*Payment Amount"' [smalltext]='""' [type]='"number"' [placeholder]='"Cost Amount"'>  </app-text-input>
                          <app-date-input [formControl]='formAccount.controls["datepaid"]' [label]='"*Date Sent Payment "' [smalltext]='"Date Sent Payment to the buyer."' [placeholder]='"Date Paid"'> </app-date-input>
                      
                        </div>    
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">                                
                          <fieldset class="form-group">
                            <div class="card border-secondary mb-3">
                              <div class="card-body">
                                <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-money "></i> Type of Payment:</h4>
                                <app-radio-button [formControl]='formAccount.controls["paymenttype"]' [name]='"storeaccount "' [value]='"StoreDebit"' [displayname]="'Store Debit'"> </app-radio-button>
                                <app-radio-button [formControl]='formAccount.controls["paymenttype"]' [name]='"storeaccount "' [value]='"StoreCredit"' [displayname]="'Store Credit'"> </app-radio-button>
                              </div>
                            </div>                           
                          </fieldset>
                        </div>  
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">                                
                          <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-comment "></i> Futher Information About This Store Account:</h4>
                          <app-area-text [formControl]='formAccount.controls["note"]' [label]='"Note"' [smalltext]='""' [placeholder]='"Note"'></app-area-text>
                        </div> 
                      </div>
                    </form> 
                  </div>
                  <div class="card-footer text-muted">
                      <button [disabled]="!formAccount.valid" class="btn btn-success btn-block" form="editForm" (click)="createStoreAccount()" ><i class="fas fa-money "></i>  Create Store Account</button>
                  </div> 
                </div>
          </div>
          <div class="col-4 mt-4">
            
          </div>
          <div class="col-12 mt-4">
            <h1 class="text-center text-primary mt-2 font-weight-bold"><i class="fas fa-coins fa-lg"></i> List of In-store payments</h1>
          </div>
          <div class="col-12">
            <app-liststorecredit></app-liststorecredit>
          </div>
      </div>
  </div>