<div class="bs-component mt-4">
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary rounded ">
    <div class="container-fluid">
      <a class="navbar-brand"><i class="fas fa-search fa-lg"></i></a>
      <a class="navbar-brand text-white font-weight-bold" > Search Features:</a>    
      <div class="form-group mt-3 ms-4">
        <select class="form-select" (change)="onChangeArchive($event)">
          <option value="1">Search all Store Accounts non-archives</option>
          <option value="2">Search all Store Accounts with only archives</option>
        </select>
      </div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarColor01">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
          
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
<div class="example-container mat-elevation-z8" *ngIf="(services.storeAccounts$ | async) !== null">
    <table mat-table [dataSource]="services.dataSource">
        
      <!-- Iteration Column -->
      <ng-container matColumnDef="Index">
        <th mat-header-cell *matHeaderCellDef scope="col"> List No. </th>
        <td mat-cell *matCellDef="let account; let i = index ;" class="w-10"> {{i + 1}}. </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>  
      <!-- Date Column -->
     <ng-container matColumnDef="TypePayment">
      <th mat-header-cell *matHeaderCellDef scope="col"> Type Payment </th>
      <td mat-cell *matCellDef="let account" class="w-10">
         <ng-container *ngIf="account.typePayment===1;else showDebit">
           <p><span class="badge bg-primary fs-6 text-light">Debit</span></p>
        </ng-container>  
         <ng-template #showDebit>
          <p><span class="badge bg-success fs-6 text-light">Credit</span></p>
         </ng-template>
      </td>
      <td mat-footer-cell *matFooterCellDef>  </td>
    </ng-container>
      <!-- Payment Method Column -->
      <ng-container matColumnDef="PaymentAmount">
        <th mat-header-cell *matHeaderCellDef scope="col"> Payment Amount </th>
        <td mat-cell *matCellDef="let account" class="w-10"> 
            <p>{{account.paymentAmount | currency }}</p>
        </td>
        <td mat-footer-cell *matFooterCellDef>  </td>
      </ng-container>
  
      <!-- Date Column -->
      <ng-container matColumnDef="InvoiceNumber">
        <th mat-header-cell *matHeaderCellDef scope="col">Come from Account No? </th>
        <td mat-cell *matCellDef="let account" class="w-10">
          <ng-container *ngIf="account.accountRefInvoiceID!==0;else showNoInvoice">
            <p><span class="badge bg-success fs-6 text-light">Invoice No: {{account.accountRefInvoiceID}}</span></p>
          </ng-container>  
          <ng-template #showNoInvoice>
           <p><span class="badge bg-danger fs-6 text-light">No</span></p>
          </ng-template>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="DatePaid" scope="col">
        <th mat-header-cell *matHeaderCellDef> Date Paid </th>
        <td mat-cell *matCellDef="let account" class="w-10">
             <p>{{account.datePaid | date: 'mediumDate'  }}</p> 
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

    <!-- Item Column -->
    <ng-container matColumnDef="Note">
        <th mat-header-cell *matHeaderCellDef scope="col" class="text-center"> Note </th>
        <td mat-cell *matCellDef="let account" class="w-50"> <p>{{account.note}}</p></td>
        <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    
    <!-- Actions Column -->
    <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef scope="col"> </th>
        <td mat-cell *matCellDef="let account; let i = index;" class="w-10">
          <button mat-icon-button color="primary" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
          
            <ng-container *ngIf="account.accountRefID === 0 ">
              <button mat-menu-item  (click)="editAccount(i)">
                <mat-icon color="primary">account_box</mat-icon>
                <span>Edit Store Account Profile</span>
              </button>
              <button mat-menu-item  (click)="archiveAccount(account.id, account.archive)">
                <mat-icon color="warn">delete</mat-icon>
                <span>Archive Store Account</span>
              </button>
              <button mat-menu-item  (click)="deleteAccount(account.id)">
                <mat-icon color="warn">delete</mat-icon>
                <span>Delete Store Account</span>
              </button>
            </ng-container>
            <ng-container *ngIf="account.accountRefID !== 0" >
              <button mat-menu-item  (click)="openAccountPayment(account.accountRefInvoiceID)">
                <mat-icon color="primary">account_box</mat-icon>
                <span>Go to Payment Details</span>
              </button>
            </ng-container>
     
          </mat-menu>   
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10000]" showFirstLastButtons></mat-paginator>
</div>
  <div class="mt-4"></div>