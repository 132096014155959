import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { Client } from 'src/app/_models/client';
import { DeleteClient } from 'src/app/_models/deleteclient';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { ClientService } from 'src/app/_services/client/client.service';
import { ClientlistaccountreceivableComponent } from '../create-client/createinvoice/clientlistaccountreceivable/clientlistaccountreceivable.component';

@Component({
  selector: 'app-deleteclient',
  templateUrl: './deleteclient.component.html',
  styleUrls: ['./deleteclient.component.css']
})
export class DeleteclientComponent implements OnInit {

  accountID: number;
  user?: User;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DeleteClient, public dialogRef: MatDialogRef<ClientlistaccountreceivableComponent>,
    private clientService: ClientService, private accountService: AccountService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.accountID = this.data.id;
    this.assignUser();

  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  deleteAccount(){
    this.clientService.deleteClient(this.accountID).subscribe( response => {
      if (response)
      {
        this.data.deleteClient = true;
        this.clientService.stopHubConnection();   
        this.clientService.stopArchiveHubConnection();
        this.clientService.createArchiveHubConnection(this.user);
        this.clientService.createHubConnection(this.user); 
      }
      else
      {
        this.toastr.error("You can't delete this account. You can, however archive this account by going into drop down menu, and then choosing the 'Archive Client Account'.");
      }
  
    }, error => {
      console.log(error);
    })
  }

}
