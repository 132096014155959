import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { yearsPerPage } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { email } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { UserGroupsPermissions } from 'src/app/_models/usergrouppermissions';
import { UsergroupspermissionsService } from 'src/app/_services/usergroups/usergroupspermissions.service';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator';
import { LeavegroupdialogComponent } from '../leavegroupdialog/leavegroupdialog.component';
import { DialogaskuserareyousurewanttocreateusergroupComponent } from '../dialogaskuserareyousurewanttocreateusergroup/dialogaskuserareyousurewanttocreateusergroup.component';

@Component({
  selector: 'app-createusergroups',
  templateUrl: './createusergroups.component.html',
  styleUrls: ['./createusergroups.component.css']
})
export class CreateusergroupsComponent implements OnInit {
  @ViewChild('editForm') editForm: NgForm;  
  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any){
    if (this.editForm.dirty){
      $event.returnValue = true;
    }
  }
  @Output() resetForm = new EventEmitter();
  userMemberGroup: UntypedFormGroup;
  membergroup: UserGroupsPermissions;
  

  constructor(public services: UsergroupspermissionsService,
    public fb: UntypedFormBuilder, private toastr: ToastrService, private dialog: MatDialog ) { }

  initializeForm(){
    this.userMemberGroup = this.fb.group({
      groupname:['', [Validators.required, WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      readclients:['', [Validators.required]],
      readinvoices:['', [Validators.required]],
      readinstalments:['', [Validators.required]],
      readaccountpayment:['', [Validators.required]],
      readstripeaccount:['', [Validators.required]],
      refundstripepayment:['', [Validators.required]],
      readstorecredit:['', [Validators.required]],
      updatestatusstripe:['', [Validators.required]],
      isadmin:['', [Validators.required]],
      writeclients:['', [Validators.required]],
      writeinvoices:['', [Validators.required]],
      writeinstalments:['', [Validators.required]],
      writeaccountpayment:['', [Validators.required]],
      writestoreaccount:['', [Validators.required]],
      canusemessages:['', [Validators.required]],
      canuserimport:['', [Validators.required]],
      canuserapi:['', [Validators.required]],
      candeleteclient:['', [Validators.required]],
      candeletestore:['', [Validators.required]],
      candeleteinvoice:['', [Validators.required]],
      candeleteinstalment:['', [Validators.required]],
      candeletepayment:['', [Validators.required]],
      canarchiveclient:['', [Validators.required]],
      canarchiveinvoice:['', [Validators.required]],
      canarchiveinstalment:['', [Validators.required]],
      canarchivepayment:['', [Validators.required]],
      canarchivestore:['', [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.initializeForm();
    this.loadInfoGroupPermissions();
    this.onload();
  }

  createUserMember(){
    this.setModel();
    const message = this.checkWriteAndRead();
    if (message === "")
    {
      this.CreateuserGroup();
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "50%";
    dialogConfig.data = {
      createGroup: false,
      message: message
    };

    const dialogRef = this.dialog.open(DialogaskuserareyousurewanttocreateusergroupComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.createGroup)
      {
        this.CreateuserGroup();
      }
    });
 
  }

  private CreateuserGroup()
  {
    this.services.createUserGroup(this.membergroup).subscribe(response => {
      if (response)
      {
        this.toastr.success('Created User Group');
        this.resetform();
        this.onload();
        return;
      }
      this.toastr.error('You cannot create group until you have a valid Stripe account!');
      
    }, err => {
      console.log(err);
    })
  }

  setModel(){
    this.membergroup = {
      groupName: this.userMemberGroup.value.groupname,
      canReadAccountPayments: this.userMemberGroup.value.readaccountpayment,
      canReadClient: this.userMemberGroup.value.readclients,
      canReadInstalments: this.userMemberGroup.value.readinstalments,
      canReadInvoice: this.userMemberGroup.value.readinvoices,
      canReadStripeAccount: this.userMemberGroup.value.readstripeaccount,
      canReadStoreAccount: this.userMemberGroup.value.readstorecredit,
      canRefundStripeAccount: this.userMemberGroup.value.refundstripepayment,
      canUpdateStatusStripeAccount: this.userMemberGroup.value.updatestatusstripe,
      canUseAPI: this.userMemberGroup.value.canuserapi,
      canUseImport: this.userMemberGroup.value.canuserimport,
      canWriteAccountPayments: this.userMemberGroup.value.writeaccountpayment,
      canWriteClient: this.userMemberGroup.value.writeclients,
      canWriteInstalments: this.userMemberGroup.value.writeinstalments,
      canWriteInvoice: this.userMemberGroup.value.writeinvoices,
      canUseMessages: this.userMemberGroup.value.canusemessages,
      canWriteStoreAccount: this.userMemberGroup.value.writestoreaccount,
      isAdmin: this.userMemberGroup.value.isadmin,
      canDeleteClientAccount: this.userMemberGroup.value.candeleteclient,
      canDeleteStoreAccount: this.userMemberGroup.value.candeletestore,
      canDeleteInvoiceAccount: this.userMemberGroup.value.candeleteinvoice,
      canDeleteInstalmentAccount: this.userMemberGroup.value.candeleteinstalment,
      canDeletePaymentAccount: this.userMemberGroup.value.candeletepayment,
      canArchiveClientAccount: this.userMemberGroup.value.canarchiveclient,
      canArchiveInvoiceAccount: this.userMemberGroup.value.canarchiveinvoice,
      canArchiveInstalmentAccount: this.userMemberGroup.value.canarchiveinstalment,
      canArchivePaymentAccount: this.userMemberGroup.value.canarchivepayment,
      canArchiveStoreAccount: this.userMemberGroup.value.canarchivestore,
    } 
  }

  resetform(){
    this.initializeForm();
  }

  onload(){
    this.services.getUserGroups().subscribe(response => {
      this.services.groupDataSource = new MatTableDataSource<UserGroupsPermissions>(response);
      this.services.groupDataSource.paginator = this.services.groupPaginator;
      this.services.groupDataSource._updateChangeSubscription();
      console.log(this.services.groupDataSource.filteredData);          
      this.services.groupDataSource.filteredData.forEach(element => {
        this.services.groupNames.push(element.groupName);
      });

      if (response.length > 0)
      {
        this.services.getFirstOptionSelectedForGroups = response[0].id;
        this.services.showMember = true;
      }
      else
      {
        this.services.showMember = false;
      }

    }, err => {
      console.log(err);
    })
  }

  loadInfoGroupPermissions(){
    this.services.getInfoGroupPermissions = {
      isAdmin: true,
      isPartofthegroup: false,
      currentMembers: 0,
      emailJoinedGroup: "",
      maximumOfUsers: 0
    };
    this.services.getInfoUserGroupPermissions().subscribe(response => {
      this.services.getInfoGroupPermissions = response;
      console.log(response);
    }, err => {
      console.log(err);
    })
  }

  leaveGroup(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "25%";
    dialogConfig.data = {
      
    };

    const dialogRef = this.dialog.open(LeavegroupdialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  checkWriteAndRead(): string{
    /* Read Clients */
    const readClient = this.userMemberGroup.value.readclients;
    const readInstalment = this.userMemberGroup.value.readinstalments;
    const readAccountPayments = this.userMemberGroup.value.readaccountpayment;
    const readStoreAccounts = this.userMemberGroup.value.readstorecredit;
    const readInvoiceAccounts = this.userMemberGroup.value.readinvoices;

    /* Write Clients */
    const writeClient = this.userMemberGroup.value.writeclients;
    const writeInstalment = this.userMemberGroup.value.writeinstalments;
    const writeAccountPayments = this.userMemberGroup.value.writeaccountpayment;
    const writeStoreAccounts = this.userMemberGroup.value.writestoreaccount;
    const writeInvoiceAccounts = this.userMemberGroup.value.writeinvoices;

    /* Delete Accounts */
    const deleteClient = this.userMemberGroup.value.candeleteclient;
    const deleteInstalment = this.userMemberGroup.value.candeleteinstalment;
    const deleteAccountPayments = this.userMemberGroup.value.candeletepayment;
    const deleteStoreAccounts = this.userMemberGroup.value.candeletestore;
    const deleteInvoiceAccounts = this.userMemberGroup.value.candeleteinvoice;

    /* Archive Accounts */
    const archiveClient = this.userMemberGroup.value.canarchiveclient;
    const archiveInstalment = this.userMemberGroup.value.canarchiveinstalment;
    const archiveAccountPayments = this.userMemberGroup.value.canarchivepayment;
    const archiveStoreAccounts = this.userMemberGroup.value.canarchivestore;
    const archiveInvoiceAccounts = this.userMemberGroup.value.canarchiveinvoice;

    /* Stripe Account */
    const readStripeAccount = this.userMemberGroup.value.readstripeaccount;
    const refundStripeAcocunt = this.userMemberGroup.value.refundstripepayment;
    const updatestatusstripe =  this.userMemberGroup.value.updatestatusstripe;

    /* Read and write */
    if (readClient === "false" && writeClient === "true")
      return "There’s a problem with both client read and client write. This means that it will be impossible for you to write to your clients because they are not visible to you! To rectify this situation, it’s necessary for both fields to be marked as “Yes” or “No”. Are you certain you want to proceed with this process?";
    if (readInstalment === "false" && writeInstalment === "true")
      return "There’s a problem with both instalment read and instalment write. This means that it will be impossible for you to write to your clients because they are not visible to you! To rectify this situation, it’s necessary for both fields to be marked as “Yes” or “No”. Are you certain you want to proceed with this process?";
    if (readAccountPayments === "false" && writeAccountPayments === "true")
      return "There’s a problem with both account payments read and account payments write. This means that it will be impossible for you to write to your clients because they are not visible to you! To rectify this situation, it’s necessary for both fields to be marked as “Yes” or “No”. Are you certain you want to proceed with this process?";
    if (readStoreAccounts === "false" && writeStoreAccounts === "true")
      return "There’s a problem with both store acocunt read and store acocunt write. This means that it will be impossible for you to write to your clients because they are not visible to you! To rectify this situation, it’s necessary for both fields to be marked as “Yes” or “No”. Are you certain you want to proceed with this process?";
    if (readInvoiceAccounts === "false" && writeInvoiceAccounts === "true")
      return "There’s a problem with both invoice read and invoice write. This means that it will be impossible for you to write to your clients because they are not visible to you! To rectify this situation, it’s necessary for both fields to be marked as “Yes” or “No”. Are you certain you want to proceed with this process?";      
    
    /* Read and delete */
    if (readClient === "false" && deleteClient === "true")
      return "There’s a problem with both client read and client delete. This means that it will be impossible for you to delete to your clients because they are not visible to you! To rectify this situation, it’s necessary for both fields to be marked as “Yes” or “No”. Are you certain you want to proceed with this process?";
    if (readInstalment === "false" && deleteInstalment === "true")
      return "There’s a problem with both instalment read and instalment delete. This means that it will be impossible for you to delete to your clients because they are not visible to you! To rectify this situation, it’s necessary for both fields to be marked as “Yes” or “No”. Are you certain you want to proceed with this process?";
    if (readAccountPayments === "false" && deleteAccountPayments === "true")
      return "There’s a problem with both account payments read and account payments delete. This means that it will be impossible for you to delete to your clients because they are not visible to you! To rectify this situation, it’s necessary for both fields to be marked as “Yes” or “No”. Are you certain you want to proceed with this process?";
    if (readStoreAccounts === "false" && deleteStoreAccounts === "true")
      return "There’s a problem with both store acocunt read and store acocunt delete. This means that it will be impossible for you to delete to your clients because they are not visible to you! To rectify this situation, it’s necessary for both fields to be marked as “Yes” or “No”. Are you certain you want to proceed with this process?";
    if (readInvoiceAccounts === "false" && deleteInvoiceAccounts === "true")
      return "There’s a problem with both invoice read and invoice delete. This means that it will be impossible for you to delete to your clients because they are not visible to you! To rectify this situation, it’s necessary for both fields to be marked as “Yes” or “No”. Are you certain you want to proceed with this process?";      
    
    /* Read and archive */
    if (readClient === "false" && archiveClient === "true")
      return "There’s a problem with both client read and client archive. This means that it will be impossible for you to archive to your clients because they are not visible to you! To rectify this situation, it’s necessary for both fields to be marked as “Yes” or “No”. Are you certain you want to proceed with this process?";
    if (readInstalment === "false" && archiveInstalment === "true")
      return "There’s a problem with both instalment read and instalment archive. This means that it will be impossible for you to archive to your clients because they are not visible to you! To rectify this situation, it’s necessary for both fields to be marked as “Yes” or “No”. Are you certain you want to proceed with this process?";
    if (readAccountPayments === "false" && archiveAccountPayments === "true")
      return "There’s a problem with both account payments read and account payments archive. This means that it will be impossible for you to archive to your clients because they are not visible to you! To rectify this situation, it’s necessary for both fields to be marked as “Yes” or “No”. Are you certain you want to proceed with this process?";
    if (readStoreAccounts === "false" && archiveStoreAccounts === "true")
      return "There’s a problem with both store acocunt read and store acocunt archive. This means that it will be impossible for you to archive to your clients because they are not visible to you! To rectify this situation, it’s necessary for both fields to be marked as “Yes” or “No”. Are you certain you want to proceed with this process?";
    if (readInvoiceAccounts === "false" && archiveInvoiceAccounts === "true")
      return "There’s a problem with both invoice read and invoice archive. This means that it will be impossible for you to archive to your clients because they are not visible to you! To rectify this situation, it’s necessary for both fields to be marked as “Yes” or “No”. Are you certain you want to proceed with this process?";          
    
    /* Stripe Account */
   if (readStripeAccount === "false" && refundStripeAcocunt === "true")
     return "There’s a problem with both read stripe account read and refund stripe account. This means that it will be impossible for you to refund to your stripe accounts because they are not visible to you! To rectify this situation, it’s necessary for both fields to be marked as “Yes” or “No”. Are you certain you want to proceed with this process?";
   if (readStripeAccount === "false" && updatestatusstripe === "true")
     return "There’s a problem with both read stripe accounts read and update stripe account status. This means that it will be impossible for you to update to your stripe account because they are not visible to you! To rectify this situation, it’s necessary for both fields to be marked as “Yes” or “No”. Are you certain you want to proceed with this process?";
    return "";
  }
}
