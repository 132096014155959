import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export interface InstalmentElement {
  duedate: Date;
  position: number;
  amountdefault: number;
  amountwithfees: number;
}



@Component({
  selector: 'app-howmanyinstalments',
  templateUrl: './howmanyinstalments.component.html',
  styleUrls: ['./howmanyinstalments.component.css']
})
export class HowmanyinstalmentsComponent implements AfterViewInit, OnInit {
  displayedColumns: string[] = ['position', 'duedate', 'amountdefault', 'amountwithfees'];
  dataSource = new MatTableDataSource<InstalmentElement>();
  formInstalment: UntypedFormGroup;
  invoiceID: number;
  instalmentAmount: number;
  optionAutomation: number;
  duedate: Date;
  invoiceAmount: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(@Inject(MAT_DIALOG_DATA) public data, public fb: UntypedFormBuilder) { }
  ngOnInit(): void {
    this.initializeForm();
  }

  ngAfterViewInit(): void {  
    this.getDataFromInstalment();
  }

  onSubmit(){

  }

  OnChangeSelectOption(){
    const value = this.formInstalment.get('typeofinstalment').value;
    switch(value)
    {
      case "0":
        this.SetDefaultValues();
        this.data.instalmentposition = 0;
        break;
      case "1":
        this.SetInstalmentData(0);
        this.data.instalmentposition = 1;
        break;
      case "2":
        this.SetInstalmentData(1);
        this.data.instalmentposition = 2;
        break;
      case "3":
        this.SetInstalmentData(2);
        this.data.instalmentposition = 3;
        break;
      case "4":
        this.SetInstalmentData(3);
        this.data.instalmentposition = 4;
        break;
      case "5":
        //this.FixedMonthlyDate();
        //this.data.instalmentposition = 5;
    }
  }

  private initializeForm(): void{
    this.formInstalment = this.fb.group({
      typeofinstalment: ['0', Validators.required],
    })
  }

  private getDataFromInstalment(): void {
    this.invoiceID = this.data.id;
    this.instalmentAmount = +this.data.costamount;
    this.duedate = this.data.duedate;
    this.invoiceAmount = +this.data.invoiceamount;
    this.optionAutomation = +this.data.instalmentposition;    
    this.formInstalment.controls['typeofinstalment'].setValue(this.data.instalmentposition);
  
    switch(this.optionAutomation)
    {
      case 0:
        this.SetDefaultValues();
        break;
      case 1:
        this.SetInstalmentData(0);
        break;
      case 2:
        this.SetInstalmentData(1);
        break;
      case 3:
        this.SetInstalmentData(2);
        break;
      case 4:
        this.SetInstalmentData(3);
        break;
      case 5:
        this.FixedMonthlyDate();
        break;
    }
  }

  private SetDefaultValues(): void{
    const INSTALMENT_DATA: InstalmentElement[] = [
      {position: 1, duedate: this.duedate, amountdefault: this.instalmentAmount, amountwithfees: this.instalmentAmount},
    ];
    this.dataSource = new MatTableDataSource<InstalmentElement>(INSTALMENT_DATA);
    this.dataSource.paginator = this.paginator;
  }

  private SetInstalmentData(type: number): void{
    const numberofinstalments = this.GetNumberIteration();
    let instalmentAmount = this.instalmentAmount;
    const invoiceAmount = this.invoiceAmount;
    let countInstalmentAmount = 0;
    let invoiceLeft: number = invoiceAmount;
    var isThisFirstTime = true;
    console.log('invoice amount ' + invoiceAmount);
    var duedate = new Date(this.duedate);
    
    var INSTALMENTS_DATA: InstalmentElement[] = [];
    for (var i: number = 0; i < numberofinstalments; i++)
    {    
      if (!isThisFirstTime)
        duedate = new Date(this.GetDateInstalment(type, duedate));

      isThisFirstTime = false;     
      if (invoiceLeft >= instalmentAmount)
      {
        invoiceLeft -= instalmentAmount;
      }
    
      countInstalmentAmount += instalmentAmount;

      if (invoiceAmount < countInstalmentAmount)
      {
        instalmentAmount = invoiceLeft;
      }

     
      const newValue: InstalmentElement = {
        duedate: new Date(duedate),
        position: i + 1,
        amountdefault: instalmentAmount,
        amountwithfees: instalmentAmount
      };

      INSTALMENTS_DATA.push(
        newValue
      );
    }

    this.dataSource = new MatTableDataSource<InstalmentElement>(INSTALMENTS_DATA);
    this.dataSource.paginator = this.paginator;
  }

  private GetDateInstalment(type: number, date: Date): Date{
    switch (type)
    {
      case 0:
        date.setDate(date.getDate() + 1);
        break;
      case 1:
        date.setDate(date.getDate() + 7);
        break;
      case 2:
        date.setDate(date.getDate() + 14);
        break;
      case 3:
        date.setDate(date.getDate() + 30);
        break;      
    }
    return date;
  }

  private GetNumberIteration(): number {
    var invoiceAmount = this.invoiceAmount;
    var instalmentAmount = this.instalmentAmount;
    var index: number = 1;
    for (index; invoiceAmount > instalmentAmount; index++)
    {
      instalmentAmount = instalmentAmount + this.instalmentAmount;
      console.log('instalment amount: ' + instalmentAmount);
    }

    if (instalmentAmount > invoiceAmount || instalmentAmount === invoiceAmount)
    {
      //index += 1;
    }
    return index;
  }

  private FixedMonthlyDate(): void {
    const numberofinstalments: number = this.GetNumberIteration();
    const instalmentAmount: number = this.instalmentAmount;
    var isThisFirstTime: boolean = true;
    var dueDate = new Date (this.duedate);

    var INSTALMENT_DATA: InstalmentElement[] = [];
    for (var i: number = 0; i < numberofinstalments; i++)
    {
      var currentDate = new Date(dueDate);
      if (!isThisFirstTime)
      {  
        dueDate = new Date(dueDate.getDate() + 30);
        const day = dueDate.getDate(); 
        const year = dueDate.getFullYear();
        const month = dueDate.getMonth();
        if (day <= 28)
        {
          currentDate = new Date(year + '-' + month + '-' + day);
        }       
      }
      isThisFirstTime = false; 
      const newValue: InstalmentElement = {
        duedate: new Date(currentDate),
        amountdefault: instalmentAmount,
        amountwithfees: instalmentAmount,
        position: i + 1
      };

      INSTALMENT_DATA.push(newValue);
      this.dataSource = new MatTableDataSource<InstalmentElement>(INSTALMENT_DATA);
      this.dataSource.paginator = this.paginator;
    }
  }

}
