import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppUserPermission } from 'src/app/_models/appuserpermission';
import { ClientSubscripton } from 'src/app/_models/clientsubscription';
import { ClientSubscriptionService } from 'src/app/_services/clientsubscription/client-subscription.service';
import { AreyousurewanttoremovesubscriptionComponent } from '../areyousurewanttoremovesubscription/areyousurewanttoremovesubscription.component';

@Component({
  selector: 'app-listuserbills',
  templateUrl: './listuserbills.component.html',
  styleUrls: ['./listuserbills.component.css']
})
export class ListuserbillsComponent implements AfterViewInit  {
  userbills: any;
  accountID: string;
  subscriptionBillAccount: AppUserPermission;
  subscriptionBills: ClientSubscripton[];
  dataSource: MatTableDataSource<ClientSubscripton>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['SubscriptionID','StartDate','EndDate','StatusMembership','clientpaidTotalcostWithGST','messageEmailSent','messageSMSSent', 'MembershipType', 'actions'];


  constructor(private services: ClientSubscriptionService, private route: ActivatedRoute,  private toastr: ToastrService, private dialog: MatDialog) { }
  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
    this.onLoad();
    this.getCurrentSubscriptionBill();
  }

  
    onLoad(){
      this.services.getUserBillSubscriptions().subscribe(response => {    
       this.subscriptionBills = response;    
       this.dataSource = new MatTableDataSource<ClientSubscripton>(this.subscriptionBills);
       this.dataSource.paginator = this.paginator;
       this.dataSource.sort = this.sort;     
       console.log(this.dataSource);
  
      }, error => {
        console.log(error);
      });
   }

   getCurrentSubscriptionBill(){
    this.services.getCurrentPermissions().subscribe(res => {
      this.subscriptionBillAccount = res;
      console.log(this.subscriptionBillAccount);
    
    }, error => {
      console.log(error);
    })
  }

  cancelMembership(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "90%";
    dialogConfig.height = "auto";
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
 
    };
    const dialogRef = this.dialog.open(AreyousurewanttoremovesubscriptionComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.cancelMember != null){
        if (result.cancelMember === true)
        {
          this.subscriptionBillAccount.isActiveMembership = false;          
        }
      }
    })
  }
}
