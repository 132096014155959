import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MessageSetting } from 'src/app/_models/messagesetting';
import { SettingsTimeManagement } from 'src/app/_models/settingstimemanagement';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  baseUrl =  environment.apiUrl;
  public message: MessageSetting;
  
  constructor(private http: HttpClient) { }

  getTextSettingMessaging(): Observable<MessageSetting>{
    return this.http.get<MessageSetting>(this.baseUrl + 'messages/getsettingstextmessaging').pipe(
      map(message => {
        return message;
      }));  
  }

  getTimeMagement(): Observable<SettingsTimeManagement>{
    return this.http.get<SettingsTimeManagement>(this.baseUrl + 'messages/getsettingstimemanagement').pipe(
      map(message => {
        return message;      
      }));    
  }

  getDueTimeEmailMessage(){
    return this.http.get(this.baseUrl + 'messages/getduedateemailmessage').pipe(
      map(message => {
        return message;
      }));
  }

  updateDueDateEmailMessage(message: any){
    return this.http.put(this.baseUrl + 'messages/updateduedateemailmessage', message);
  }

  getDueDateSMSMessage(){
    return this.http.get(this.baseUrl + 'messages/getduedatesmsmessage').pipe(
      map(duedate => {
        return duedate;
      })
    );
  }

  updateduedatesmsmessage(message: any){
    return this.http.put(this.baseUrl + 'messages/updateduedatesmsmessage', message);
  }

  getReceiveEmailMessage(){
    return this.http.get(this.baseUrl + 'messages/getreceiveemailmessage').pipe(
      map(message => {
        return message;
      })
    );
  }

  getInitialEmaillMessage(){
    return this.http.get(this.baseUrl + 'messages/getinitialemailmessage').pipe(
      map(message => {
        return message;
      })
    );
  }

  updateReceiveEmaiLMessage(message: any){
    return this.http.put(this.baseUrl + 'messages/updatereceiveemailmessage', message);
  }

  updateInitialEmailMessage(message: any){
    return this.http.put(this.baseUrl + 'messages/updateinitialemailmessage', message);
  }

  updateInitialInstalmentEmailMessage(message: any){
    return this.http.put(this.baseUrl + 'messages/updateinstalmentinitialemail', message);
  }

  updateInitialInstalmentSMSMessage(message: any){
    return this.http.put(this.baseUrl + 'messages/updateinstalmentinitialsms', message);
  }

  updateDueDateInstalmentEmailMessage(message: any){
    return this.http.put(this.baseUrl + 'messages/updateduedateinstalmentemail', message);
  }
  updateDueDateInstalmentSMSMessage(message: any){
    return this.http.put(this.baseUrl + 'messages/updateduedateinstalmentsms', message);
  }
  
  updateInitialSMSMessage(message: any){
    console.log(message);
    return this.http.put(this.baseUrl + 'messages/updateinitialsmsmessage', message);
  }

  getReceiveSMS(){
    return this.http.get(this.baseUrl + 'messages/getpaymentreceiveSMS').pipe(
      map(message => {
        return message;
      })
    );
  }

  getInitialSMS(){
    return this.http.get(this.baseUrl + 'messages/getinitialsmsmessage').pipe(
      map(message => {
        return message;
      })
    );
  }

  getInitialInstalmentEmail(){
    return this.http.get(this.baseUrl + 'messages/getinstalmentinitialemailmessage').pipe(
      map(message => {
        return message;
      })
    );
  }

  getInitialInstalmentSMS(){
    return this.http.get(this.baseUrl + 'messages/getinstalmentinitialsmsmessage').pipe(
      map(message => {
        return message;
      })
    );
  }

  getDueDateInstalmentEmail(){
    return this.http.get(this.baseUrl + 'messages/GetInstalmentduedateemailmessage').pipe(
      map(message => {
        return message;
      })
    );
  }

  getDueDateInstalmentSMS(){
    return this.http.get(this.baseUrl + 'messages/GetInstalmentduedatesmsmessage').pipe(
      map(message => {
        return message;
      })
    );
  }

  updateReceiveSMS(message: any){
    return this.http.put(this.baseUrl + 'messages/updatepaymentreceiveSMS', message);
  }

  getDueTime(){
    return this.http.get(this.baseUrl + 'messages/getduetime').pipe(
      map(time => {
        return time;
      })
    );
  }

  updateDueTime(message: any){
    return this.http.put(this.baseUrl + 'messages/updateduetime', message);
  }

  getTimeZone(){
    return this.http.get(this.baseUrl + 'messages/gettimezone').pipe(
      map(zone => {
        return zone;
      })
    )
  }

  updateTimeZone(message: any){
    return this.http.post(this.baseUrl + 'messages/updatetimezone', message);
  }

  TestUpdateDueDateEmail(email: any){
    return this.http.post(this.baseUrl + 'messages/showduedatetest', email);
  }

  TestUpdateInitialEmail(email: any){
    return this.http.post(this.baseUrl + 'messages/showinitialtest', email);
  }
  
  TestUpdateReceiveEmail(email: any){
    return this.http.post(this.baseUrl + 'messages/showreceivetest', email);
  }

  TestInstalmentDueDateEmail(email: any){
    return this.http.post(this.baseUrl + 'messages/showinstalmentduedateemailtest', email);
  }

  TestInstalmentInitialEmail(email: any){
    return this.http.post(this.baseUrl + 'messages/showinstalmentinitialemailtest', email);
  }

  setMessageValues(){
    this.message = {
      dueDateEmailMessage: 'You havent set your message yet!',
      dueDateSMSMessage: 'You havent set your message yet!',
      paymentReceiveEmailMessage: 'You havent set your message yet!',
      receiveSMSMessage: 'You havent set your message yet!',
      initialSMSMessage: 'You havent set your message yet!',
      initialEmailMessage: 'You havent set your message yet!',
      initialInstalmentEmailMessage: 'You havent set your message yet!',  
      dueDateInstalmentEmailMessage: 'You havent set your message yet!',
      dueDateInstalmentSMSMessage: 'You havent set your message yet!',
      initialInstalmentSMSmessage: 'You havent set your message yet!'
    };
  }

  removeHTMLTags(value: string): string{
    var start: boolean = false;
    var startIndexValue: number = 0;
    for (var i = 0; i < value.length; i++)
    {
      if (value.charAt(i) === "<")
      {
        start = true;
        startIndexValue = i;
      }
      else if (value.charAt(i) === ">" && start === true)
      {
        const removedValue = value.substring(startIndexValue, i + 1);
        value = value.replace(removedValue, '');
        start = false;
      }
    }
    return value;
  }
}
