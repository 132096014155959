import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, NgControlStatusGroup, NumberValueAccessor } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { ImportfileClient } from 'src/app/_models/importfileclient';
import { ImportfilesService } from 'src/app/_services/import/importfiles.service';

export interface ClientOrderList {
  type: string;
  show: Number;
}

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportComponent implements OnInit {
  selectedFile: File = null;
  orderList: ClientOrderList[];
  importFiles: ImportfileClient[];
  listMode: boolean = false;
  userCanUse: boolean = false;
  showErrorMessage: boolean = false;

  checkoutImport = this.formBuilder.group({
    file: '',
    name: '',
    firstName: '',
    lastName: '',
    contactMobile: '',
    email: '',
    contactHomePhone: '',
    contactBusinessNumber: '',
    bussinessName: '',
    ABN: '',
    address: '',
    unit: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
  });

  
  constructor(private service: ImportfilesService, private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.createOrderList();
    this.getPermissionForUser();
  }

  onFileSelected(event){
    this.selectedFile = <File>event.target.files[0];
  }

  onSubmit(){
    var valuesList = this.orderList.map(function(object){
      return object.show;
    })
    const fd = new FormData();
    fd.append('sheet', this.selectedFile, this.selectedFile.name);
    console.log(this.selectedFile);
    this.service.importFileClient(fd, valuesList.toString()).subscribe(response => {
      this.importFiles = response;
      console.log( 'import files');
      console.log( this.importFiles );
      if (this.importFiles.length > 0)
      {
        this.listMode = true;
      }
    }, error => {
      console.log(error);
      this.showErrorMessage = true;
    });
  }

  onChangeCheckBox(event: any, name: string, index: number){
    const value = <ClientOrderList> {
      type: name,
      show: this.getCheckboxValue(event.target.checked),
    }
    this.orderList[index] = value;
  }

  getCheckboxValue(value: boolean): number{
    if (value){
      return 1;
    } 
    return 0;
  }

  createOrderList(){
    this.orderList = [
      {type: 'name', show: 1 },
      {type: 'First name', show: 0 },
      {type: 'Last Name', show: 0 },
      {type: 'Contact Mobile', show: 0 },
      {type: 'Email', show: 0 },
      {type: 'Contact Home Phone', show: 0 },
      {type: 'Contact Bussiness Number', show: 0 },
      {type: 'Bussiness Name', show: 0 },
      {type: 'ABN', show: 0 },
      {type: 'Address', show: 0 },
      {type: 'Unit', show: 0 },
      {type: 'State', show: 0 },
      {type: 'Postcode', show: 0 },
      {type: 'Country', show: 0 },
    ];
  }

  getPermissionForUser(){
    this.service.getPemissionForUser().subscribe(response => {
        this.userCanUse = true;
        console.log(this.userCanUse);
    }, err => {
      console.log(err);
      this.userCanUse = false;
    })
  }

  

}
