<app-nav></app-nav>
<div class="container">
    <div class="row">
        <ol class="list mt-4">
            <p>Your login is protected with an authenticator app. Enter your authenticator code below.</p>
            <li>
                <app-text-input [formControl]='formCode.controls["code"]' [label]='"*Code"' [smalltext]='""' [placeholder]='"Code"'> </app-text-input>
            </li>
            <li>
                <app-text-input [formControl]='formCode.controls["email"]' [label]='"*Email"' [smalltext]='""' [placeholder]='"Email"'> </app-text-input>
            </li>
            <li>    
                <app-text-input [formControl]='formCode.controls["password"]' [type]='"password"'  [label]='"*Password"' [smalltext]='""' [placeholder]='"Password"'> </app-text-input>
                <button [disabled]="!formCode.valid" class="btn btn-success btn-block" (click)="submitForm()" ><i class="fas fa-user"></i> Validate Code</button>
            </li>
        </ol>        
    </div>
</div>