<mat-dialog-content>
<div class="container" *ngIf="data">
    <div class="row">
        <div class="col-12">   
            <h2 class="text-center">Due Date Email Message:</h2>
            <button class="btn btn-success btn-block" mat-button (click)="generateText()">Generate Text</button>
        </div>
        <div class="col-12">            
            <form #editForm="ngForm" id="editForm" [formGroup]="formEdit" (ngSubmit)="formEdit.valid" autocomplete="off">
                <div class="form-group">                
                    <ckeditor  [config]="{ toolbar: [ 'heading', 'paragraph', '|', 'bold', 'italic', 'undo', 'redo', '-', 'numberedList', 'bulletedList' ] }" tagName="textarea" [editor]="Editor" formControlName="changeText" (ready)="onReady($event)">
                    </ckeditor>              
                </div>   
            </form>
            <h4 class="text-center">Helper:</h4>
            <button class="btn btn-light btn-block">Show Example </button>
            <div class="alert alert-dark" role="alert">        
                Type "<strong style="color: green;"> [invoicenumber] </strong>" in the above message to show invoice number!.  
                Type "<strong style="color: green;"> [amountpaid] </strong>" in the above message shows you the amount paid!.  
                Type "<strong style="color: green;"> [duedate] </strong>" in the above message to show due date for the invoice!.     
                Type "<strong style="color: green;"> [dueamount] </strong>" in the above message to shows amount due!.
                Type "<strong style="color: green;"> [invoiceamount] </strong>" in the above message to show total invoice amount!.
                Type "<strong style="color: green;"> [name] </strong>" in the above message to show full name to your customer!
                Type "<strong style="color: green;"> [mybusiness] </strong>" in the above message to show your business name!
                Type "<strong style="color: green;"> [mybusinessphone] </strong>" in the above message shows your business phone contact support number!
                Type "<strong style="color: green;"> [mybusinessemail] </strong>" in the above message shows your business email support!
                Type "<strong style="color: green;"> [code] </strong>" in the above message to enter the code to grant access to paying the bill!
                Type "<strong style="color: green;"> [link] </strong>" in the above message is a button or a link that allows your customers to pay their bill.
            </div>               
        </div>              
        <div class="col-6">
            <button [disabled]='!formEdit.valid || !formEdit.dirty' class="btn btn-success btn-block" mat-button [mat-dialog-close]="data" (click)="onSubmit()">Confirm Changes</button>
        </div>
        <div class="col-6">
            <button class="btn btn-danger btn-block" mat-button mat-dialog-close>Cancel</button>
        </div>
    </div>
</div>
</mat-dialog-content>