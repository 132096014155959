import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorialhowtocreatenewaccountpayment',
  templateUrl: './tutorialhowtocreatenewaccountpayment.component.html',
  styleUrls: ['./tutorialhowtocreatenewaccountpayment.component.css']
})
export class TutorialhowtocreatenewaccountpaymentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
