<app-nav></app-nav>
<ng-container *ngIf="accountService.currentUser$ | async as user">
  <div class="container">
    <div class="row">
        <div class="col-12 mt-4">
          <div class="ngcontainer" *ngIf="clientInfo">
            <nav class="navbar navbar-light bg-primary shadow-lg">
              <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Instalment Accounts</a> 
              <button type="button" class="btn btn-outline-light btn-lg mt-2 mt-md-0" (click)="toggle.toggleWindow(33)"><i class="{{toggle.$Icon[33]}}" aria-hidden="true\"></i> {{toggle.$ToggleText[33]}}</button>             
            </nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a [routerLink]= "['/members/clients']">Clients</a></li>
              <li class="breadcrumb-item">
                <a [routerLink]= "['/members/invoices', clientInfo.clientID]">
                  <ng-container *ngIf="user.typeOfService; else showInvoiceTitle ">
                    Billing Accounts
                  </ng-container>
                  <ng-template #showInvoiceTitle>
                    Invoices 
                  </ng-template> 
                </a>               
              </li>
              <li class="breadcrumb-item active">Instalments</li>
            </ol>
            <div class="card-body" [hidden]="!toggle.$Toggle[33]">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xm-12">
                  <img src="/assets/images/profile-account.jpg" alt="profile account" class="img-thumbnail rounded center">
                  <h2 class="card-title text-center bg-white p-2 font-weight-bold text-primary fs-3 text-uppercase shadow-sm"><i class="fas fa-house-user fa-2x text-primary"></i> Invoice Information </h2>
                  <p class="card-text text-center p-2 fs-4"><b>Invoice Number: </b><b class="text-primary"> {{ clientInfo.invoiceNumber }}</b></p>
                  <p class="card-text text-center p-2 fs-4"><b>Client Name: </b>{{ clientInfo.name }} </p>            
                  <p class="card-text text-center p-2 fs-4"><b>Invoice Amount: </b><b class="text-success">{{ defaultInvoiceAmount | currency }}</b></p>           
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xm-12">
                  <div class="card border shadow-lg">
                    <div class="card-body bg-secondary">
                      <fieldset class="form-group">
                        <legend class="text-center text-primary fs-2 text-uppercase font-weight-bold">How to use This?</legend>
                        <div class="form-group mt-4">
                          <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/F3Lhazl_Gjw?si=TSskLzzWUnLJu_bt" allowfullscreen></iframe>
                          </div>
                          <button class="btn btn-info ms-2 btn-block mt-4" routerLink="/members/turtorialhome"><i class="fas fa-question-circle fa-lg"></i> Click Here to learn more!</button>
                        </div>             
                      </fieldset>
                    </div>
                  </div>          
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav class="navbar navbar-light bg-primary shadow-lg">
          <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Create Instalment Accounts</a>
          <button type="button" class="btn btn-outline-light btn-lg mt-2 mt-md-0" (click)="toggle.toggleWindow(4)"><i class="{{toggle.$Icon[4]}}" aria-hidden="true\"></i> {{toggle.$ToggleText[4]}}</button>
        </nav>
          <div class="col-12" [hidden]="!toggle.$Toggle[4]">
              <div class="card mt-4 border-info" [hidden]="(instalmentService.showCreateForm$ | async) === true">
                  <div class="card-body">
                    <h2 class="text-center text-primary mt-2">Create Instalment Account</h2>
                    <form #editForm="ngForm" id="editForm" [formGroup]="formInstalment" (ngSubmit)="formInstalment.valid" autocomplete="off">
                      <div class="bs-component">
                        <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
                          <button type="button" class="close" data-dismiss="alert">×</button>
                          <h4 class="alert-heading">Information!</h4>
                          <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> Payment Details:</h4>
                          <app-date-input [formControl]='formInstalment.controls["duedate"]' [label]='"*Due Date"' [smalltext]='"Due Date of Payment"' [placeholder]='"Due Date"' [minDate]='minDate'> </app-date-input>              
                          <app-text-input [formControl]='formInstalment.controls["costamount"]' [label]='"*Instalment Amount"' [smalltext]='""' [type]='"text"' [placeholder]='"Cost Amount"'>  </app-text-input>                           
                          <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> A Description Note:</h4>
                          <app-area-text [formControl]='formInstalment.controls["note"]' [label]='"Note"' [smalltext]='""' [placeholder]='"Note"'></app-area-text>            
                        </div>    
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">   
                          <fieldset class="form-group">
                            <div class="card border-secondary mb-3">
                              <div class="card-body">
                                <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice "></i> Add transaction fees:</h4>
                                <label class="form-check-label"></label>
                                <div class="form-check">
                                  <label class="form-check-label ">
                                    <input type="radio" class="form-check-input" name="addfees" value="Yes" checked=""  [formControl]='formInstalment.controls["addfees"]'>
                                    <b class="text-success">Yes</b>: in 1.75% + A$0.30 to your customer instalment billing account. 
                                  </label>
                                </div>
                                <div class="form-check">
                                  <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="addfees"  value="No" [formControl]='formInstalment.controls["addfees"]'>
                                    <b class="text-danger">No</b>: My business will pay out the transaction fee. 
                                  </label>
                                </div>
                              </div>
                            </div>                        
                          </fieldset>   
                          <fieldset class="form-group">
                            <div class="card border-secondary mb-3">
                              <div class="card-body">
                                <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice "></i> Add Initial payment after the first instalment:</h4>
                                <label class="form-check-label"></label>
                                <div class="form-check">
                                  <label class="form-check-label ">
                                    <input type="radio" class="form-check-input" name="sendinitialafterfirstpayment" value="Yes" checked=""  [formControl]='formInstalment.controls["sendinitialafterfirstpayment"]'>
                                    <b class="text-success">Yes</b>: Send initial message after your customer pays the first bill!
                                  </label>
                                </div>
                                <div class="form-check">
                                  <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="sendinitialafterfirstpayment"  value="No" [formControl]='formInstalment.controls["sendinitialafterfirstpayment"]'>
                                    <b class="text-danger">No</b>: No, I would prefer to only send reminders on due dates!
                                  </label>
                                </div>
                              </div>
                            </div>                        
                          </fieldset> 
                          <fieldset class="form-group">
                            <div class="card border-secondary mb-3">
                              <div class="card-body">
                                <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice "></i> Automate Instalments:</h4>
                                <label class="form-check-label">Current Set to: <b class="text-success">{{displayInstalmentAutomation}}</b></label>
                                <p class="mt-4"><button type="button" class="btn btn-primary" (click)="openInstalmentsTable()">Open Up Instalments Table</button></p>
                              </div>
                            </div>                        
                          </fieldset> 
                        </div>  
                      </div>
                    </form> 
                  </div>
                  <div class="card-footer text-muted">
                      <button [disabled]="!formInstalment.valid" class="btn btn-success btn-block" form="editForm" (click)="createInstalment()" >Create Instalment Account</button>
                  </div> 
            </div>
            <ng-container *ngIf="(instalmentService.showCreateForm$ | async) !== false">
              <div class="bs-component">
                <div class="alert alert-dismissible alert-info mt-2">
                  <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                  <h1>Reached Capacity on Instalments</h1>
                  <strong>Please be advised that the maximum allowable number of installment accounts has been reached. The total cost amount currently matches the invoiced amount for these installment accounts. Consequently, the functionality to initiate new installment accounts, along with the automated due date notifications, will continue seamlessly for existing members. Should you have any further inquiries or require assistance, please do not hesitate to reach out.</strong> 
                </div>
            </div>
            </ng-container>
          </div>
          <div class="col-12">
            <app-listinstalmentaccounts></app-listinstalmentaccounts>
          </div>
          <div class="col-12 mt-4">
            <h1 class="text-center text-primary mt-2 font-weight-bold">Accounts Payments</h1>
            <div class="bs-component">
              <div class="alert alert-dismissible alert-info">
                <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                <h1 class="alert-heading">General Information!</h1>
                <p class="mb-0 fs-6"><strong>Heads Up!</strong> If you wish to add/edit or delete account payments. Just press back (Located at the top of this screen where it says invoices on the menu), and then scroll down to the bottom, and then click on three dots on the invoice account, and then select the option for <strong>Account payments</strong>!</p>
              </div>
            </div>
          </div>  
          <div class="col-12">
            <nav class="navbar navbar-light bg-primary shadow-lg mt-4">
              <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Payment Accounts</a>
              <button type="button" class="btn btn-outline-light btn-lg" (click)="toggle.toggleWindow(7)"><i class="{{toggle.$Icon[7]}}" aria-hidden="true\"></i> {{toggle.$ToggleText[7]}}</button>
            </nav>
            <div [hidden]="toggle.$Toggle[7]">
              <app-clientlistaccountreceivable></app-clientlistaccountreceivable>
            </div>
          </div>
      </div>
  </div>
</ng-container>