import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termsandconditionsfeatures',
  templateUrl: './termsandconditionsfeatures.component.html',
  styleUrls: ['./termsandconditionsfeatures.component.css']
})
export class TermsandconditionsfeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
