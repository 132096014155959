<app-nav></app-nav>
<div class="container mt-4" *ngIf="uriStatus$ | async as user">
    <div>
        <nav class="navbar navbar-light bg-primary shadow-lg mt-4">
            <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-user fa-2x" aria-hidden="true"></i> Two Factor Authenticator</a>
        </nav>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/members/editdetailsacount">Edit Account Details</a></li>
            <li class="breadcrumb-item active">Two Factor Authenticator</li>
        </ol>
        <ol class="list" *ngIf="(enableStatus$ | async) === false">
            <li>
                <p>Download a two-factor authenticator app like Microsoft authenticator for</p>
            </li>
            <li>
                <p>Scan the QR Code or enter this key <kbd>{{user.authenticatorUri }}</kbd> into your factor authenticator app.</p>
                <div id="qrCode"></div>
                <div id="qrCodeData" data-url=""> </div>                
            </li>
            <li>
                <p>
                    Once you have scanned the QR code or input the key above, your two factor authentication app will provide you with a unique code. Enter the 
                    code in the confirmation box below.
                </p>
                <qrcode qrdata="{{user.authenticatorUri}}"></qrcode>
                <app-text-input [formControl]='formCode.controls["code"]' [label]='"*Code"' [smalltext]='"Place the code here"' [placeholder]='"Code"'> </app-text-input>
                <app-text-input [formControl]='formCode.controls["password"]' [type]='"password"' [label]='"*Password"'  [smalltext]='"Enter Your Password to Verify Account"' [placeholder]='"Password"'> </app-text-input>
                <button [disabled]="!formCode.valid" class="btn btn-success btn-block" (click)="submitForm()" ><i class="fas fa-user"></i> Validate Code</button>
            </li>
        </ol>     
        <ol class="list" *ngIf="(enableStatus$ | async) === true">
            <li>
                <p>Two factor authenticator is <kbd>Enabled</kbd></p>
            </li>
            <li>
                <p>To disable 2fa. Click on the button below!</p>
                <button type="button" class="btn btn-outline-primary" (click)="disabletwofactorauthencator()">Disable Two Factor authenticator</button>
            </li>
        </ol>           
    </div>
</div>