import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Client } from 'src/app/_models/client';
import { ImportfileClient } from 'src/app/_models/importfileclient';
import { ImportfileInvoice } from 'src/app/_models/importfileinvoice';
import { Invoice } from 'src/app/_models/invoice';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImportfilesService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  importFileClient(model: any, values: string){
    return this.http.post(this.baseUrl + 'ImportFiles/CheckImportFileClient?model='+ values, model).pipe(
      map((response: ImportfileClient[]) => {
        return response;
      })
    );    
  }

  importFileInvoice(model: any, values: string){
    return this.http.post(this.baseUrl + 'ImportFiles/CheckFileImportInvoice?model='+ values, model).pipe(
      map((response: ImportfileInvoice[]) => {
        return response;
      })
    );    
  }

  getListClients(){
    return this.http.get(this.baseUrl + 'ImportFiles/getclientsconflicts').pipe(
      map((response: Client[]) => {
        return response;
      })
    ); 
  }

  createImportsClients(imports: any){
    return this.http.post(this.baseUrl + 'ImportFiles/postimportsclients', imports);
  }

  getListInvoice(imports: any){
    return this.http.post(this.baseUrl + 'ImportFiles/postgetinvoicesconflicts', imports).pipe(
      map((response: ImportfileInvoice[]) => {
        return response;
      })
    ); 
  }

  createImportsInvoices(imports: any){
    return this.http.post(this.baseUrl + 'ImportFiles/postimportsinvoices', imports);
  }

  getPemissionForUser(){
    return this.http.get(this.baseUrl + 'ImportFiles/getpermissionforimport').pipe(
      map((response: any) => {
        return response;
      })
    ); 
  }




}
