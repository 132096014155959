import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { MessagesService } from 'src/app/_services/message/messages.service';

@Component({
  selector: 'app-changeinitialsmsmessage',
  templateUrl: './changeinitialsmsmessage.component.html',
  styleUrls: ['./changeinitialsmsmessage.component.css']
})
export class ChangeinitialsmsmessageComponent implements OnInit {

  public data: any;
  @ViewChild('editForm') editForm: NgForm; 
  formEdit: UntypedFormGroup;

  
  constructor(public fb: UntypedFormBuilder, private service: MessagesService) { }

  ngOnInit(): void {
    this.initializeForm();
    this.onLoad();
  }

  initializeForm(){
    this.formEdit = this.fb.group({
      changeText:['', [ Validators.required, Validators.maxLength(2000) ]],
    });  
  }

  onSubmit(){
    const message = {
      InitialSMSMessage: this.formEdit.controls['changeText'].value
    }
    this.data.message = message.InitialSMSMessage;
    this.service.updateInitialSMSMessage(message).subscribe(response => {
      this.onLoadSettingMesaging();
    }, error => {
      console.log(error);
    })
  }

  onLoad(){
    this.service.getInitialSMS().subscribe(response => {
      this.data = response;
      this.formEdit.controls['changeText'].setValue(this.data.initialSMSMessage);
    }, error => {
      console.log(error);
    })
  }

  generateText(){
    this.formEdit.controls['changeText'].setValue("Dear [name], \n" +
    "I am writing to inform you that we have sent your bill from [mybusiness]. The invoice number is #[invoicenumber] and the amount due is [dueamount], which is payable by [duedate]. \n" +
    "To make a payment, please visit the Invoice Pager website and enter the following code: \n[code]\nYou can access the website by following this link: \n " +
    "[link] \n" +
    "If you have any questions or concerns, please do not hesitate to contact us at [mybusinessemail] or [mybusinessphone].\n" +
    "Thank you for your prompt attention to this matter. \n" +
    "Yours sincerely,\n" +
    "[mybusiness] \n" +
    "Email: [mybusinessemail] \n" +
    "Phone: [mybusinessphone]");
    this.formEdit.markAsDirty();
  }

  onLoadSettingMesaging(){
    this.service.getTextSettingMessaging().subscribe(response => {
      this.service.message = response;
      if (this.service.message == null){
        this.service.setMessageValues();
      }
      console.log(response);
    }, error => {
      console.log(error);
    })
  }


}
