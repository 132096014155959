import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MessagesService } from 'src/app/_services/message/messages.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-initialemailinstalmentmessage',
  templateUrl: './initialemailinstalmentmessage.component.html',
  styleUrls: ['./initialemailinstalmentmessage.component.css']
})
export class InitialemailinstalmentmessageComponent implements OnInit {
  public data: any;
  @ViewChild('editForm') editForm: NgForm; 
  formEdit: UntypedFormGroup;
  public Editor = ClassicEditor;
  
  constructor(public fb: UntypedFormBuilder, private service: MessagesService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.initializeForm();
    this.onLoad();
  }

  onLoad(){
    this.service.getInitialInstalmentEmail().subscribe(response => {
      this.data = response;
      this.formEdit.controls['changeText'].setValue(this.data.initialInstalmentEmailMessage);
    }, error => {
      console.log(error);
    })
  }

  generateText(){
    this.formEdit.controls['changeText'].setValue(
      "<body>\r\n\r\n" +
      " <p>Dear [name],</p>\r\n\r\n  <p>I trust this message finds you in good health. " +
      "Your ongoing partnership is greatly valued, " +
      "and we'd like to gently remind you that the next installment payment for #[invoicenumber] is scheduled for [duedate].</p>\r\n\r\n" +
      "  <strong>Payment Details:</strong>\r\n  <ul>\r\n    <li>Invoice Number: [invoicenumber]</li>\r\n    <li>Current Due Amount: [dueamount]</li>\r\n  " +
      "  <li>Due Date: [duedate]</li>\r\n    <li>Amount Paid to Date: [amountpaid]</li>\r\n  </ul>\r\n\r\n " +
      " <p>To ensure a seamless transaction and to avoid any inconvenience, we kindly request that you complete the payment by the specified due date." +
      " To make the payment, please visit the Invoice Pager website using the button below and enter the provided code: [code]. </p>\r\n\r\n" +
      "<p>[link]</p>\r\n\r\n " +
      " <p>Should you have already settled the payment, we extend our sincere appreciation for your promptness. " +
      "In case of any challenges or queries concerning the payment procedure, please feel free to reach out to us via email at [mybusinessemail].</p>\r\n\r\n " +
      " <p>Your business is of utmost importance to us, and your timely payments enable us to consistently deliver the highest level of service. " +
      "We look forward to sustaining this positive collaboration.</p>\r\n\r\n  <p>Best regards,<br>\r\n\r\n " +
      " [mybusiness]<br>\r\n  [mybusinessphone]</p>\r\n\r\n</body>"
    );
    this.formEdit.markAsDirty();
  }

  initializeForm(){
    this.formEdit = this.fb.group({
      changeText:['', [ Validators.required, Validators.maxLength(2000) ]],
    });  
  }

  onSubmit(){
    const message = {
      InitialInstalmentEmailMessage: this.formEdit.controls['changeText'].value
    }
    this.data.message = message.InitialInstalmentEmailMessage;
    this.service.updateInitialInstalmentEmailMessage(message).subscribe(response => {
      this.onLoadSettingMesaging();
      this.toastr.success("Updated Message!");
    }, error => {
      console.log(error);
    })   
  }

  onLoadSettingMesaging(){
    this.service.getTextSettingMessaging().subscribe(response => {
      this.service.message = response;
      if (this.service.message == null){
        this.service.setMessageValues();
      }
      console.log(response);
    }, error => {
      console.log(error);
    })
  }

  

}
