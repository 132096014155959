<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to edit an existing invoice account</h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/Edit Invoice/ei01.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Click the three (3) dots beside the “Invoice Account” you wish to edit </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/Edit Invoice/ei02.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	In the menu Click “Edit Invoice Account”</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/Edit Invoice/ei03.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>
        •	Correct the information as needed
    </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/Edit Invoice/ei04.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>
        •	Click “Confirm Editing Invoice Account” to save the changes
    </h2>
</div>
