import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client } from 'src/app/_models/client';
import { ImportfileClient } from 'src/app/_models/importfileclient';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator';

@Component({
  selector: 'app-importeditclient',
  templateUrl: './importeditclient.component.html',
  styleUrls: ['./importeditclient.component.css']
})
export class ImporteditclientComponent implements OnInit {
  formClient: UntypedFormGroup;
  client: Client;
  accountID: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ImportfileClient, public fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.accountID = this.data.id;
    this.initializeForm();
  }

  onSubmit(){
    this.getDataFromSubmit();
  }

  initializeForm(){
    this.formClient = this.fb.group({
      email:[this.data.email, [Validators.email, Validators.maxLength(255)]],
      firstname: [this.data.firstName, [Validators.required, Validators.maxLength(255)]],
      lastname: [this.data.lastName, [Validators.required, Validators.maxLength(255)]],
      contacthomephone: [this.data.contactHomePhone, [Validators.minLength(8), Validators.maxLength(10)]],
      contactmobile: [this.data.contactMobile, [Validators.required, Validators.maxLength(10), Validators.minLength(10)]],
      contactbussinessnumber: [this.data.contactBusinessNumber, [Validators.minLength(8), Validators.maxLength(10)]],
      bussinessname: [this.data.bussinessName, [Validators.maxLength(255),  WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      abn: [this.data.abn, [Validators.maxLength(11), Validators.minLength(11)]],
      address: [this.data.address, [Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      unit: [this.data.unit, [Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      suburb: [this.data.suburb, [Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      state: [this.data.state, [Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      postcode: [this.data.postCode, [Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      country: [this.data.country, [Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
    })
  }

  getDataFromSubmit(){
    const abn: string = this.formClient.value.abn; 
    this.data.abn = abn;
    this.data.address = this.formClient.value.address;
    this.data.bussinessName = this.formClient.value.bussinessname;
    this.data.contactHomePhone = this.formClient.value.contacthomephone;
    this.data.contactMobile = this.formClient.value.contactmobile;
    this.data.contactBusinessNumber = this.formClient.value.contactbussinessnumber;
    this.data.email = this.formClient.value.email;
    this.data.firstName = this.formClient.value.firstname;
    this.data.lastName = this.formClient.value.lastname;
    this.data.unit = this.formClient.value.unit;
    this.data.suburb = this.formClient.value.suburb;
    this.data.state = this.formClient.value.state;
    this.data.country = this.formClient.value.country;
    this.data.postCode = this.formClient.value.postcode;
    console.log('sub');
    console.log(this.data);
  }
}