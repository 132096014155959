import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-importconfirmationdialog',
  templateUrl: './importconfirmationdialog.component.html',
  styleUrls: ['./importconfirmationdialog.component.css']
})
export class ImportconfirmationdialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  confirmSubmitClients(){
    this.data.confirm = true;
  }

}
