import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LostAccount } from 'src/app/_models/lostaccount';
import { EmailconfigService } from 'src/app/_services/email/emailconfig.service';
import { PasswordValidator } from 'src/app/_validators/passwordvalidator';

@Component({
  selector: 'app-resetlostemail',
  templateUrl: './resetlostemail.component.html',
  styleUrls: ['./resetlostemail.component.css']
})
export class ResetlostemailComponent implements OnInit {
  user: LostAccount;
  message: string = "Reset your password";
  leftMessage: string = "To Reset Account. Type in your new password";
  defaultConfirm: boolean = true;
  sendEmailForm: UntypedFormGroup;
  showSendEmail: boolean;
  showConfirm: boolean = false;
  
  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private services: EmailconfigService, private toastr: ToastrService ) { }

  ngOnInit(): void {
    this.initializatIonUser();
    this.getParrams();
    this.initializeForm();
  }

  initializeForm(){
    this.sendEmailForm = this.fb.group({
      password:['', [Validators.required, PasswordValidator.PasswordValidated]],
      retypepassword:['', [Validators.required, PasswordValidator.matchValues('password')]]
    })
  }

  getParrams(){
    this.route.queryParams.subscribe(parrams => {
      this.user.email = parrams.email;
      this.user.token = parrams.token;
    });
  }

  
  initializatIonUser(){
    this.user = {
      email: "",
      token: "",
      password: ""
    };
  }

  sendAccountNewPassword(){
    this.user.password = this.sendEmailForm.controls['password'].value;
    this.services.loadConfirmLostAccount(this.user).subscribe(response => {
      if (response == true)
      {
        this.toastr.success("Updated Password to your account!");
        this.showConfirm = true;
        this.defaultConfirm = false;
        this.message = "Your Acount has been Updated!";
      }
      else
      {
        this.message = "Your account has failed to update";
        this.toastr.error("Token Expired!");
        this.leftMessage = "Failed To Confirm Email Account!";
        this.defaultConfirm = false;
      }     
    }, err => {
      console.log(err);
      this.defaultConfirm = false;
    })
  }



}
