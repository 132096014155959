import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Invoice } from 'src/app/_models/invoice';
import { InvoiceService } from 'src/app/_services/invoice/invoice.service';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator';
import { AccountService } from 'src/app/_services/account/account.service';
import { take } from 'rxjs/operators';
import { User } from 'src/app/_models/user';
import { ToastrService } from 'ngx-toastr';
import { PayablesreceivablesService } from 'src/app/_services/payablesreceivablesaccounts/payablesreceivables.service';
import { InvoiceValidator } from 'src/app/_validators/invoicevalidator';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceItems } from 'src/app/_models/createinvoiceitems';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogeeditareyousuretoupdateComponent } from '../dialogeeditareyousuretoupdate/dialogeeditareyousuretoupdate.component';

export interface TogglingOptionsTypes {
  value: string,
  description: string,
  selected: boolean
}


@Component({
  selector: 'app-editinvoice',
  templateUrl: './editinvoice.component.html',
  styleUrls: ['./editinvoice.component.css']
})

export class EditinvoiceComponent implements OnInit, OnDestroy {

  constructor(public fb: UntypedFormBuilder, private invoiceService: InvoiceService,
    private accountService: AccountService, private toastr: ToastrService,
    private payablesreceivables: PayablesreceivablesService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog) { }
    
  ngOnDestroy(): void {
    this.invoiceService.cancelCanEditInvoice(this.accountID).subscribe(response => {
      if (response)
      {
        this.toastr.info("Active Account Closed");
      }
    }, err => {
      console.log(err);
    })
  }

  formInvoice: UntypedFormGroup;
  accountID: any;
  clientID: any;
  invoice: Invoice;
  user?: User;
  archive: boolean = false;
  showInvalidDate: boolean;
  showCreateForm: boolean = false;
  formItems: UntypedFormGroup[] = [];
  invoiceItems: InvoiceItems[] = [];
  customCreateInvoieSubTotal: number = 0;
  customCreateInvoiceGST: number = 0;
  customCreateInvoiceTotalAmount: number = 0;
  selectTaxOptionsDefault: string = "true";
  customCreateInvoiceTaxInclude: boolean = true;
  customCreateInvoiceIsValid: boolean = true;
  hasItems: boolean = false;

  optionToggleInvoiceTypes: TogglingOptionsTypes[] = [
    {value: "false", description: 'I like to add in items to my account.', selected: false}, 
    {value: "true", description: 'I\'ve already set up the account within the accounting software, saved the file, or stored it elsewhere.', selected: false}
  ];

  optionToggleTaxTypes: TogglingOptionsTypes[] = [
    {value: "true", description: 'Inclusive Tax', selected: false},
    {value: "false", description: 'No Tax', selected: false}
  ];

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.assignUser();
    this.initializeForm();    
    this.getParrams();
    this.initializeItemsForm('','','','');
    this.onLoad();
    this.onLoadItems();
  }

  initializeForm(){
    this.formInvoice = this.fb.group({
      invoicenumber:['', [Validators.required, Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      invoiceamount: ['', [Validators.max(999999999999), Validators.min(0.01), Validators.required]],
      paymentamount: ['', [Validators.max(999999999999), Validators.min(0.0), Validators.required, InvoiceValidator.matchInvoiceToAmountPaid('invoiceamount')]],
      initaldate: ['', Validators.required],
      duedate: ['', Validators.required],
      comment: ['', [Validators.maxLength(1000), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      addFees: ['', Validators.required],
      addduedate: ['', Validators.required],
    })
  }

  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.accountID = params.get("id");
      this.clientID = params.get("clientID");
    });
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  onSubmit(){
    this.updateInvoice();
    if (this.hasItems)
    {
      this.UpdateWarningDialog()
      return;
    }

    this.invoiceService.updateInvoice(this.invoice).subscribe(response => {
      if (response)
      {
        this.invoiceService.stopHubConnection();  
        this.invoiceService.createHubConnection(this.user, this.invoice.clientID);
        this.invoiceService.stopArchiveHubConnection();  
        this.invoiceService.createArchiveHubConnection(this.user, this.invoice.clientID);
        this.toastr.success("Successfully updated account!");
        this.payablesreceivables.stopHubConnection();
        this.payablesreceivables.createHubConnection(this.user, true);
        this.loadInvoiceNumbers();
        this.cancelButton();
      }
      else
      {
        this.toastr.error("Failed! Your Invoice Account has something inside it. You can choose to delete the instalment payments" +
          ", or account payments from within this account, or choose to archive this account");  
      }
    }, error => {
      //this.invoiceService.stopHubConnection();
      console.log(error);
    });
  }

  CustomonSubmit(){
    this.updateInvoice();
    const updateInvoice = this.CustomUpdateInvoice();
    this.invoiceService.updateCustomInvoice(updateInvoice).subscribe(response => {
      if (response)
      {
        this.invoiceService.stopHubConnection();  
        this.invoiceService.createHubConnection(this.user, this.invoice.clientID);
        this.invoiceService.stopArchiveHubConnection();  
        this.invoiceService.createArchiveHubConnection(this.user, this.invoice.clientID);
        this.toastr.success("Successfully updated account!");
        this.payablesreceivables.stopHubConnection();
        this.payablesreceivables.createHubConnection(this.user, true);
        this.loadInvoiceNumbers();
        this.cancelButton();
      }
      else
      {
        this.toastr.error("Failed! Your Invoice Account has something inside it. You can choose to delete the instalment payments" +
          ", or account payments from within this account, or choose to archive this account");  
      }
    }, error => {
      //this.invoiceService.stopHubConnection();
      console.log(error);
    });
  }

  CustomUpdateInvoice(): any{
    const hasDueDateMessageSent = this.invoice.hasDueDateMessageSent;
    const hasReceiveMessageSent = this.invoice.hasReceiveMessageSent;
    const sendInitialMessage = this.invoice.sendInitialMessage;
    const valueFees = this.getOptionValue(this.formInvoice.value.addFees);
    const valueDueDateMessage = this.getOptionValue(this.formInvoice.value.addduedate);
    const initialDate = new Date(this.formInvoice.value.initaldate);
    const dueDate = new Date(this.formInvoice.value.duedate);
    const clientID = this.invoice.clientID;
    const isitGst = this.customCreateInvoiceTaxInclude;

    let items = [];
    this.formItems.forEach(item => {
      const invoiceItem= {
        description: item.controls['description'].value,
        qty: +item.controls['qty'].value,
        unitPrice: +item.controls['unitprice'].value
      };
      items.push(invoiceItem);
    });

    const updateInvoice = {
      id: this.invoice.id,
      invoiceNumber: this.formInvoice.value.invoicenumber,
      invoiceAmount: this.formInvoice.value.invoiceamount,
      initialDate: initialDate,
      dueDate: dueDate,
      comment: this.formInvoice.value.comment,
      paidAmount: this.formInvoice.value.paymentamount,
      hasDueDateMessageSent: hasDueDateMessageSent,
      hasReceiveMessageSent: hasReceiveMessageSent,
      sendInitialMessage: sendInitialMessage,
      addFeesDomestic: valueFees,
      clientID: clientID,
      sendOutDueDateMessage: valueDueDateMessage,
      initialDateS: initialDate.toUTCString(),
      dueDateS: dueDate.toUTCString(),
      isItGST: isitGst,
      invoiceItemDtos: items
    };

    return updateInvoice;
  }

  onLoad(){
    this.invoiceService.getEditInvoice(this.accountID).subscribe(response => {      
      this.invoice = response;
      console.log(this.invoice);
      this.updateInfoForm();
      if (this.invoice !== null)
      {
        if (this.invoice.tax > 0)
        {
          this.optionToggleTaxTypes[0].selected = true;
          this.customCreateInvoiceTaxInclude = true; 
        }
        else
        {
          this.optionToggleTaxTypes[1].selected = true;
          this.customCreateInvoiceTaxInclude = false; 
        }
      }
      
    }, error => {
      console.log(error);
    });
  }

  onLoadItems(){
    this.invoiceService.getEditItemsInvoice(this.accountID).subscribe(response => {
      this.formInvoice.markAsUntouched;
      this.formInvoice.markAsPristine;
      this.formInvoice.markAllAsTouched;
      if (response.length === 0)
      {
        this.showCreateForm = true;
        this.optionToggleInvoiceTypes[1].selected = true;
        return;
      }
      this.hasItems = true;
      this.optionToggleInvoiceTypes[0].selected = true;
      const invoiceItems: InvoiceItems[] = response;     
      this.showCreateForm = false;
      let counter = 0;
      const maxLength = invoiceItems.length;      
      invoiceItems.forEach(item => {
        const qty: number = item.qty;
        const unitprice: number = item.unitPrice;
        const total: number = qty * unitprice;
        const amountAud: string = total.toString();
        if (counter === 0)
        {
          this.formItems[counter].controls['description'].setValue(item.description);
          this.formItems[counter].controls['qty'].setValue(qty);
          this.formItems[counter].controls['unitprice'].setValue(unitprice);
          this.formItems[counter].controls['amountaud'].setValue(amountAud);
        }
        else
        {
          this.initializeItemsForm(item.description, 
           qty.toString(), unitprice.toString(), '');
          this.invoiceItems.push(item);
        }
        this.addAmountAUD(counter);       
        counter = counter + 1;
      }); 
    }, err => {
      console.log(err);
    })
  }

  updateInvoice(){
    const hasDueDateMessageSent = this.invoice.hasDueDateMessageSent;
    const hasReceiveMessageSent = this.invoice.hasReceiveMessageSent;
    const sendInitialMessage = this.invoice.sendInitialMessage;
    const valueFees = this.getOptionValue(this.formInvoice.value.addFees);
    const valueDueDateMessage = this.getOptionValue(this.formInvoice.value.addduedate);
    const initialDate = new Date(this.formInvoice.value.initaldate);
    const dueDate = new Date(this.formInvoice.value.duedate);
    const clientID = this.invoice.clientID;

    this.invoice = {
       id: this.invoice.id,
       invoiceNumber: this.formInvoice.value.invoicenumber,
       invoiceAmount: this.formInvoice.value.invoiceamount,
       initialDate: initialDate,
       dueDate: dueDate,
       comment: this.formInvoice.value.comment,
       paidAmount: this.formInvoice.value.paymentamount,
       hasDueDateMessageSent: hasDueDateMessageSent,
       hasReceiveMessageSent: hasReceiveMessageSent,
       sendInitialMessage: sendInitialMessage,
       addFeesDomestic: valueFees,
       clientID: clientID,
       sendOutDueDateMessage: valueDueDateMessage,
       initialDateS: initialDate.toUTCString(),
       dueDateS: dueDate.toUTCString()
    };
  }

  updateInfoForm(){
    this.formInvoice.controls['invoicenumber'].setValue(this.invoice.invoiceNumber);
    console.log(this.invoice)
    this.formInvoice.controls['invoiceamount'].setValue(this.invoice.invoiceAmount);
    this.formInvoice.controls['paymentamount'].setValue(this.invoice.paidAmount);
    var initialDate = new Date(this.invoice.initialDate);
    this.formInvoice.controls['initaldate'].setValue(initialDate);
    var dueDate = new Date(this.invoice.dueDate);
    this.formInvoice.controls['duedate'].setValue(dueDate);
    this.formInvoice.controls['comment'].setValue(this.invoice.comment);
    var addFees: string = "false";
    var dueDateMes: string = "false";  
    if (this.invoice.addFeesDomestic === 1)
    {
      addFees = "true";
    }

    if (this.invoice.sendOutDueDateMessage)
    {
      dueDateMes = "true";
    }

    this.formInvoice.controls['addFees'].setValue(addFees);
    this.formInvoice.controls['addduedate'].setValue(dueDateMes);
  }

  getOptionValue(value: string){
    var result: number = 0;
    if (value == "true")
    {
      result = 1;
    }
    return result;
  }

  onChangeDueDate(){
    if (this.formInvoice.value.duedate === "" || this.formInvoice.value.dueDate === "Invalid date")
    {
      return;
    }
    const dueDate: Date = this.formInvoice.value.duedate;
    const initalDate: Date = this.formInvoice.value.initaldate;

    if (initalDate > dueDate)
    {
      this.showInvalidDate = true;
      return;
    }    
    this.showInvalidDate = false;
  }

  private loadInvoiceNumbers(){
    this.invoiceService.stopInvoiceNumberConnection();
    this.invoiceService.createInvoiceNumberHubConnection(this.user);
  }

  toggleTypeOfTax(value: any): void {
    console.log(value);
    if (value === "true")    
      this.customCreateInvoiceTaxInclude = true;    
    else  
      this.customCreateInvoiceTaxInclude = false;    
    this.addCustomMadeInvoiceTotal();
    this.checkItemFormsForInvoiceHasIsInValid();
  }

  private addCustomMadeInvoiceTotal(): void{
    let total: number = 0;
    this.formItems.forEach(item => {
      const qty = item.controls['qty'].value;
      const unitprice = item.controls['unitprice'].value;
      total += qty * unitprice;
    });    
    
    this.customCreateInvoieSubTotal = total;
    this.customCreateInvoiceGST = this.getTotalGST();
    this.customCreateInvoiceTotalAmount = this.customCreateInvoieSubTotal + this.customCreateInvoiceGST;
    this.formInvoice.controls['invoiceamount'].setValue(this.customCreateInvoiceTotalAmount.toFixed(2).toString());
  }

  checkItemFormsForInvoiceHasIsInValid(): void {
    const isInvalid = this.formItems.find(f => f.status === "INVALID");
    console.log(isInvalid);
    if (isInvalid === undefined)
     this.customCreateInvoiceIsValid = false;
    else
    this.customCreateInvoiceIsValid = true;
  }

  revertLastActionForCreateNewItem(): void {
    const lastFormItem: UntypedFormGroup = this.formItems[this.formItems.length - 1];
    this.invoiceItems.splice(-1);
    this.formItems.splice(-1);
    this.formItems[0] = lastFormItem;
    this.checkItemFormsForInvoiceHasIsInValid();
  }

  deleteItem(formIndex: number, itemsIndex: number): void {
    this.formItems.splice(formIndex, 1);
    this.invoiceItems.splice(itemsIndex, 1);
    this.addCustomMadeInvoiceTotal(); 
  }

  private getTotalGST(): number {
    return this.customCreateInvoiceTaxInclude ? (this.customCreateInvoieSubTotal * 1.1) - this.customCreateInvoieSubTotal : 0;
  }

  addAmountAUD(index: number): void {
    const qty: string = this.formItems[index].value.qty;
    const unitprice: string = this.formItems[index].value.unitprice;
    this.checkItemFormsForInvoiceHasIsInValid();

    if (isNaN(+qty))
      return;

    if (isNaN(+unitprice))
      return;

    if (+qty < 1 || +unitprice < 0.01)
      return;

    const total: string = (+qty * +unitprice).toFixed(2).toString();
    this.formItems[index].controls['amountaud'].setValue(total);
    this.addCustomMadeInvoiceTotal();
  }  

  addItemForm(): void {
    const description: string = this.formItems[0].value.description;
    const qty: number = +this.formItems[0].value.qty;
    const unitprice: number = +this.formItems[0].value.unitprice;
    const amount: string =this.formItems[0].value.amountaud;

    const invoiceItems: InvoiceItems = {
      description: description,
      qty: qty,
      unitPrice: unitprice,
      amountaud: +amount
    };

    const resetForm = this.fb.group
    ({
      description:['', Validators.required],
      qty:['', [Validators.required, Validators.min(1)]],
      unitprice:['', [Validators.required, Validators.min(1)]],
      amountaud:['']
    });

    this.formItems[0] = resetForm;     
    this.invoiceItems.push(invoiceItems); 
    this.initializeItemsForm(description, qty.toString(), unitprice.toString(), amount); 
    this.checkItemFormsForInvoiceHasIsInValid();
    this.formItems[0].markAllAsTouched;
  }

  initializeItemsForm(description: string, qty: string, unitprice: string, amount: string){
    let formItems: UntypedFormGroup;
    formItems = this.fb.group
    ({
      description:[description, [Validators.required, WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      qty:[qty, [Validators.required, Validators.min(1), Validators.max(99999)]],
      unitprice:[unitprice, [Validators.required, Validators.min(0.01), Validators.max(99999999999)]],
      amountaud:[amount]
    });

    this.formItems.push(formItems);
  }

  onChangeFormToggle(value: any) { 
    if (value.target.value === "true")
    {
      this.showCreateForm = true;
      return;
    }
    this.showCreateForm = false;
  } 

  cancelButton(): void {
    this.initializeForm();
    this.formInvoice.markAsPristine();
    this.formInvoice.markAsUntouched();
    this.invoiceItems = [];
    this.formItems = [];
    this.initializeItemsForm('','','','');
    this.router.navigate(['/members/invoices' , this.clientID]);
  }

  private UpdateWarningDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "auto";
    dialogConfig.width = "75vw";
    
    dialogConfig.data = {
      closed: false
    };

    const dialogRef = this.dialog.open(DialogeeditareyousuretoupdateComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.closed === true)
      {
        this.invoiceService.updateInvoice(this.invoice).subscribe(response => {
          if (response)
          {
            this.invoiceService.stopHubConnection();  
            this.invoiceService.createHubConnection(this.user, this.invoice.clientID);
            this.invoiceService.stopArchiveHubConnection();  
            this.invoiceService.createArchiveHubConnection(this.user, this.invoice.clientID);
            this.toastr.success("Successfully updated account!");
            this.payablesreceivables.stopHubConnection();
            this.payablesreceivables.createHubConnection(this.user, true);
            this.loadInvoiceNumbers();
            this.cancelButton();
          }
          else
          {
            this.toastr.error("Failed! Your Invoice Account has something inside it. You can choose to delete the instalment payments" +
              ", or account payments from within this account, or choose to archive this account");  
          }
        }, error => {
          //this.invoiceService.stopHubConnection();
          console.log(error);
        });
      }
    });
  }

}
