import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Instalment } from 'src/app/_models/instalment';
import { InstalmentService } from 'src/app/_services/instalment/instalment.service';
import { ListinstalmentaccountsComponent } from '../listinstalmentaccounts/listinstalmentaccounts.component';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator';
import { EmailRemoveGMT } from 'src/app/_helper/emailremovegmt';
import { PaymentService } from 'src/app/_services/payment/payment.service';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { take } from 'rxjs/operators';
import { InvoiceValidator } from 'src/app/_validators/invoicevalidator';
import { DateValidation } from 'src/app/_validators/datevalidation';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-editinstalments',
  templateUrl: './editinstalments.component.html',
  styleUrls: ['./editinstalments.component.css']
})
export class EditinstalmentsComponent implements OnInit {
  
  formInstalment: UntypedFormGroup;
  instalment: Instalment;
  accountID: any;
  invoiceID: number = 0;
  invoiceAmount: number = 0;
  costAmount: number = 0;
  defaultInvoiceAmount: number = 0;
  user?: User;
  constructor(public fb: UntypedFormBuilder,
     @Inject(MAT_DIALOG_DATA) public data: Instalment, public dialogRef: MatDialogRef<ListinstalmentaccountsComponent>, 
     private instalmentService: InstalmentService, public paymentService: PaymentService, private accountService: AccountService, private toastr: ToastrService) { }

  private setDataSet(): void{
    this.invoiceID = this.data.invoiceID;
    this.costAmount = this.data.costAmount;
    this.invoiceAmount = +this.data.invoiceAmount;
  }

  ngOnInit(): void {
    this.setDataSet();
    this.getTotalInstalmentCost(true); 
    this.initializeForm();
    this.assignUser();
    this.onLoad();
    this.onLoadLastestDate();
    //this.onLoadLastestDate();   
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  onSubmit(){
    this.updateInstalment();
    this.instalmentService.updateInstalment(this.instalment).subscribe(response => {
      this.toastr.success('You have sucessfully updated instalment account!');
      const costamount: number = +this.formInstalment.value.costamount;
      this.invoiceAmount = this.invoiceAmount + costamount;
      this.updateData();
      //this.loadMembersListInstalments();    
      this.getTotalInstalmentCost(false); 
      this.instalmentService.stopHubConnection();
      this.instalmentService.stopHubArchiveConnection();
      this.instalmentService.createArchiveHubConnection(this.user, this.invoiceID);
      this.instalmentService.createHubConnection(this.user, this.invoiceID);    
      this.dialogRef.close();
    }, error => {
      console.log(error);
    });
  }

  initializeForm(){
    this.formInstalment = this.fb.group({
      costamount:['', [Validators.required, InvoiceValidator.matchInvoiceFromInvoiceToAmountPaid(this.invoiceAmount), Validators.min(0.01), Validators.max(999999999999999)]],
      note: ['', [WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter, Validators.maxLength(1000)]],
      duedate: ['', Validators.required],
      addfees: ['', Validators.required],
      sendinitialdateafter: ['', Validators.required],
    })
  }

  onLoad(){
    this.accountID = this.data.id;
    this.instalmentService.getEditInstalment(this.accountID).subscribe(response => {
      this.instalment = response;   
      console.log(response); 
      this.updateInfoForm();
    }, error => {
      console.log(error);
    });
  }

  updateInfoForm(){
    var addFees: string = "No";
    var sendInitialMessage: string = "No";  
    addFees = this.CheckStatus(this.instalment.addFees);
    sendInitialMessage = this.CheckStatus(this.instalment.sendInitialDateAfterFirstPayment);

    this.formInstalment.controls['costamount'].setValue(this.instalment.costAmount);
    var dueDue = new Date(this.instalment.dueDate);
    this.formInstalment.controls['note'].setValue(this.instalment.note);
    this.formInstalment.controls['duedate'].setValue(dueDue);
    this.formInstalment.controls['addfees'].setValue(addFees);
    this.formInstalment.controls['sendinitialdateafter'].setValue(sendInitialMessage);
  }

  updateInstalment(){
    const initialDate = EmailRemoveGMT.RemovedEmailGMT(this.formInstalment.value.initaldate);
    const dueDate = new Date(this.formInstalment.value.duedate);
    const addfees = this.RemoveStringForStatus(this.formInstalment.value.addfees);
    const sendfirstpayment = this.RemoveStringForStatus(this.formInstalment.value.sendinitialdateafter);

    const invoiceID = this.instalment.invoiceID;

    this.instalment = {
       id: this.instalment.id,
       costAmount: this.formInstalment.value.costamount,
       invoiceID: invoiceID,
       note: this.formInstalment.value.note,
       addFees: addfees,
       sendInitialDateAfterFirstPayment: sendfirstpayment,
       dueDate: dueDate,    
       dueDateS: dueDate.toUTCString()
    };
  }

  updateData(){
    this.data.id = this.instalment.id;
    this.data.invoiceID = this.instalment.invoiceID;
    this.data.costAmount = this.instalment.costAmount;
    this.data.dueDate = this.instalment.dueDate;
    this.data.note = this.instalment.note;
  }

  loadMembersListInstalments(){
    const invoiceID: string = this.data.invoiceID.toString();
    this.paymentService.getPayments(invoiceID).subscribe(
      response => {
        this.paymentService.accounts = response;
        console.log(response);
      }, error => {
        console.log(error);
      }
    )
  }

  private getTotalInstalmentCost(whentoStart: boolean){
    this.instalmentService.gettotalcostforInstalments(this.invoiceID).subscribe(response => {
      const addCost = +response;
      if (whentoStart)
      {
        this.defaultInvoiceAmount = this.invoiceAmount;
        this.invoiceAmount = this.invoiceAmount - addCost + this.costAmount;        
        this.formInstalment.controls['costamount'].setValidators([Validators.required, InvoiceValidator.matchInvoiceFromInvoiceToAmountPaid(this.invoiceAmount), 
          Validators.min(0.01), Validators.max(999999999999999)]);
      }

      if (addCost >= this.defaultInvoiceAmount)
      {
        this.instalmentService.showCreateFormSource.next(true);
        return;
      }

      this.instalmentService.showCreateFormSource.next(false);    
      this.instalmentService.defaultValueCostAmountSource.next(addCost);
    }, err => {
      console.log(err);
    })
  }

  private CheckStatus(status: boolean): string
  {
    return status === true ? "Yes" : "No";
  }
  private RemoveStringForStatus(status: string): boolean
  {
    return status === "No" ? false : true;
  }

  private onLoadLastestDate(){
    this.instalmentService.getupdatedlastestdateinstalments(this.invoiceID, this.accountID).subscribe(response => {  
      if (response != null)
      {
        this.instalmentService.lastestInstalmentDate = response;   
        this.formInstalment.controls['duedate'].setValidators([Validators.required, DateValidation.CompareDate(response)]);
      }    
    }, error => {
      console.log(error);
    });    
  }
}
