import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectionSignalRHub } from 'src/app/_helper/connectionsignalrhub';
import { StoreAccountModel } from 'src/app/_models/storeaccountmodel';
import { User } from 'src/app/_models/user';
import { UserStoreInfoAccount } from 'src/app/_models/userstoreinfoacccount';
import { environment } from 'src/environments/environment';
import { HostpresenceService } from '../hubsignalr/hostpresence.service';
import { DelStoreAccount } from 'src/app/_models/delstoreaccount';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class StoreaccountService {
  showArchive: boolean = false;
  huburl = environment.hubUrl;
  baseUrl =  environment.apiUrl;
  dataSource = new MatTableDataSource<StoreAccountModel>();
  private hubConnection?: HubConnection;
  private hubArchiveConnection?: HubConnection;
  private storeAccountsSource = new BehaviorSubject<StoreAccountModel[]>([]);
  storeAccounts$ = this.storeAccountsSource.asObservable();

  constructor(private http: HttpClient, private presenceHub: HostpresenceService, private toastr: ToastrService) { }

  createStoreAccount(storemodel: StoreAccountModel){
    return  this.http.post(this.baseUrl + "storeaccount/createstorepayment", storemodel).pipe(
      map((response: any) => {
        return response;      
      })
    );
  }  


  getStoreAccounts(clientID: string){
    return  this.http.get(this.baseUrl + "storeaccount/getliststoreaccounts?clientID=" + clientID).pipe(
      map((response: StoreAccountModel[]) => {
        this.storeAccountsSource.next(response);
        this.SortDataSourceTable(response);
        return response;      
      })
    );
  }

  editStoreAccount(storemodel: StoreAccountModel){
    return  this.http.put(this.baseUrl + "storeaccount/updatestoreaccount", storemodel).pipe(
      map((response: any) => {
        return response;      
      })
    );
  }  

  deleteStoreAccount(id: number){
    const account = {

    };
    
    return this.http.delete(this.baseUrl + "storeaccount/deletestoreaccount?accountID=" + id, account).pipe(
      map((response: any) => {
        return response;      
      })
    );
  }

  archiveStoreAccount(id: number, archivetype: boolean){
    const account = {

    };
    
    return this.http.put(this.baseUrl + "storeaccount/archivestoreaccount?accountID=" + id + "&accounttype=" + archivetype, account).pipe(
      map((response: any) => {
        return response;      
      })
    );
  }

  getStoreUserInfo(clientID: string){
    return  this.http.get(this.baseUrl + "storeaccount/getuserstoreinfo?clientID=" + clientID).pipe(
      map((response: UserStoreInfoAccount) => {
        return response;      
      })
    );
  }

  getCheckCanEditAccount(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'storeaccount/Iseditactive?accountID=' + accountID).pipe(
      map((client => {
        return client;
      }))
    )
  }

  getCheckCanDeleteAccount(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'storeaccount/Iseditactive?accountID=' + accountID + '&isItDeleteFunction=true').pipe(
      map((client => {
        return client;
      }))
    )
  }

  getCheckCanArchiveAccount(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'storeaccount/Iseditactive?accountID=' + accountID + '&isItArchiveFunction=true').pipe(
      map((client => {
        return client;
      }))
    )
  }

  cancelCanEditClient(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'storeaccount/closeeditactive?accountID=' + accountID).pipe(
      map((client => {
        return client;
      }))
    )
  }
  openPaymentAccount(accountID: number){
    return this.http.get<number>(this.baseUrl + 'storeaccount/openpaymentaccount?accountID=' + accountID).pipe(
      map((amount : number) => {
        return amount;
      })
    )
  }

  async createHubConnection(user: User, accountID: number, resetTable: boolean) 
  {  
    if (resetTable)
      this.resetDataSource();

    if (ConnectionSignalRHub.CheckAccessHub(this.presenceHub.pageActive[1], false) === false)
      return;

    this.hubConnection = ConnectionSignalRHub.GetConnectionHub(this.hubConnection, this.huburl, accountID, user, "storeaccount");

    this.hubConnection.on('ReceiveStoreAccounts', (storeAccounts: StoreAccountModel[]) => {
      if (storeAccounts !== null)
      {
        if (this.showArchive === true)
          return;          
        
        console.log(storeAccounts);
        this.CheckDataSource(storeAccounts);
      }     
    })    
  }

  async createArchiveHubConnection(user: User, accountID: number, resetTable: boolean) 
  {  
    if (resetTable)
      this.resetDataSource();

    if (ConnectionSignalRHub.CheckAccessHub(this.presenceHub.pageActive[1], false) === false)
      return;

    this.hubConnection = ConnectionSignalRHub.GetConnectionHub(this.hubArchiveConnection, this.huburl, accountID, user, "storeaccountsarchive");

    this.hubConnection.on('ReceiveArchiveStoreAccounts', (storeAccounts: StoreAccountModel[]) => {
      if (storeAccounts !== null)
      {
        if (this.showArchive === false)
          return;  
        this.CheckDataSource(storeAccounts);
      }     
    })      
  }

  private CheckDataSource(storeAccounts: StoreAccountModel[])
  {
    var storeAcccountLength: number = 0;
    const dataSourceLength = this.dataSource.data.length;
    storeAccounts.forEach(account => {
      storeAcccountLength = storeAcccountLength + 1;
    });
    console.log(dataSourceLength);
    if (dataSourceLength === 0)
    {
      this.SetDataSource(storeAccounts);
    }    
    else if (storeAcccountLength === 0)
    {
      this.SetDataSource(storeAccounts);
    }
    else if (storeAcccountLength > dataSourceLength)
    {
      var storeAccount = storeAccounts[storeAcccountLength - 1];     
      console.log(storeAccount);
      this.dataSource.data.push(storeAccount);
    }
    else if (storeAcccountLength < dataSourceLength)
    {
      var i: number = 0;
      var isItlastNumber: boolean = true;
      storeAccounts.forEach(storeAccount => {              
        if (storeAccount.id != this.dataSource.data[i].id)
        {
          this.dataSource.data.splice(i, 1);
          isItlastNumber = false;          
        }
        i = i + 1;
      });
      
      if (isItlastNumber)
      {
        this.dataSource.data.splice(dataSourceLength - 1, 1);
      }
    }
    else if (storeAcccountLength === dataSourceLength)
    {
      var index: number = 0;
      storeAccounts.forEach(account => {
        if (this.dataSource.data[0].datePaid != account.datePaid)
        {
          this.SetDataSource(storeAccounts);
        }
        else if (this.dataSource.data[0].note != account.note)
        {
          this.SetDataSource(storeAccounts);
        }
        else if (this.dataSource.data[0].paymentAmount != account.paymentAmount)
        {
          this.SetDataSource(storeAccounts);
        }
        else if (this.dataSource.data[0].typePayment != account.typePayment)
        {
          this.SetDataSource(storeAccounts);
        }
        index = index + 1;
      });        
    }
  }

  SetDataSource(storeAccounts: StoreAccountModel[]){
    this.storeAccountsSource.next(storeAccounts);
    this.dataSource.data = storeAccounts;
  }

  stopHubConnection(){
    if (this.hubConnection) {
      this.hubConnection?.stop();
    }
  }

  stopArchiveHubConnection(){
    if (this.hubArchiveConnection) {
      this.hubArchiveConnection?.stop();
    }
  }

  private SortDataSourceTable(response: any){
    this.dataSource = new MatTableDataSource<StoreAccountModel>(
      response
    );
  }

  private resetDataSource() {
    this.storeAccountsSource = new BehaviorSubject<StoreAccountModel[]>([]);
    this.dataSource = new MatTableDataSource<StoreAccountModel>();
  }

}
