import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ChargePayment } from '../_models/chargepayment';
import { CreateCheckoutDto } from '../_models/checkout';
import { ValidatePaymentCharge } from '../_models/validatepaymentcharge';
import { StripepaymentService } from '../_services/payment/stripepayment.service';
import { WhiteSpacesValidator } from '../_validators/whitespaces.validator';
import { ToastrService } from 'ngx-toastr';

declare const Stripe;

@Component({
  selector: 'app-customerpaymentpage',
  templateUrl: './customerpaymentpage.component.html',
  styleUrls: ['./customerpaymentpage.component.css']
})
export class CustomerpaymentpageComponent implements OnInit {

  @ViewChild('editForm') editForm: NgForm;  
  token: any;
  idCharge: string;
  chargePayment: ChargePayment;
  takePaymentResult: string;
  handler:any = null;
  baseUrl =  environment.apiUrl;
  checkout: CreateCheckoutDto;
  hasValidated: boolean = false;
  btnCheckingFields: boolean = false;
  formValidator: UntypedFormGroup;
  hasSubmit: boolean = false;
  firstName: string = "";
  lastName: string = "";
  mobileNumber: string = "";
  code: string = "";



  constructor(private route: ActivatedRoute, public service: StripepaymentService, private http: HttpClient,   private _renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document, public fb: UntypedFormBuilder
  ,private toastr: ToastrService) { }

  ngOnInit(): void {
    //this.invokeStripe();
    //this.testStripe();
    this.getParrams();
    this.initializeForm();
  }

  onSubmit(){
    const chargevalidation: ValidatePaymentCharge = {
      id: this.idCharge,
      code: this.formValidator.get('code').value
    };

    this.service.checkInvoiceValidation(chargevalidation).subscribe(response => {
      if (response)
      {
        this.hasValidated = true;
        this.code = this.formValidator.get('code').value;
        this.getCharge();
      }
      else
      {
        this.hasValidated = false;
        this.toastr.error("Failed attempt! You have entered the code wrong, or have entered the code too many times.");
      }
      this.hasSubmit = true;
    }, err => {
      console.log(err);
    })
  }

  invokeStripe(){
    if(!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      s.onload = () => {
        this.handler = (<any>window).StripeCheckout.configure({
          key: 'pk_test_RDRA5LRW6W7JRim13DICVsHH0023gWT4tq',
          locale: 'auto',
          token: function (token: any) {
            // You can access the token ID with `token.id`.
            // Get the token ID to your server-side code for use.
            console.log(token)
            alert('Payment Success!!');
          }
        });
      }
        
      window.document.body.appendChild(s);
    }
  }

  getCharge(){
    this.service.getChargePayment(this.idCharge, this.code).subscribe(response => {
      this.chargePayment = response;
    }, error => {
      console.log(error);      
    })
  }

  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.idCharge = params.get("id");
    });
  }

  clickEventCharge(){
    this.sortCheckOut();
    this.service.createCheckout(this.checkout); 
  }

  testStripe(){
    this.service.requestMemberSession('price_1Idsy3Gw03Axe2vnK0zFyB9J');
  }

  sortCheckOut(){
    this.checkout = {
      id: this.chargePayment.id,      
      token: this.chargePayment.token,
    }
  }

  initializeForm(){
    this.formValidator = this.fb.group({
      code:['', [Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpace, Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
    })
  }

  onTouchField(){
    const firstName = this.formValidator.get('firstname').value;
    const lastName = this.formValidator.get('lastname').value;
    const mobileNumber = this.formValidator.get('mobilenumber').value;

    if (this.isEmptyOrSpaces(mobileNumber))
    {
      if (this.isEmptyOrSpaces(firstName) )
      {
        this.btnCheckingFields = false;
        return;
      }
      else
      {
        this.btnCheckingFields = true;
      }
      if (this.isEmptyOrSpaces(lastName))
      {
        this.btnCheckingFields = false;
      }
      else
      {
        this.btnCheckingFields = true;
      }
    }
    else
    {
      this.btnCheckingFields = true;
    }
  }

  isEmptyOrSpaces(str: string){
    return str === null || str.match(/^ *$/) !== null;
  }
}
