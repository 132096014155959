import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StripepaymentService } from 'src/app/_services/payment/stripepayment.service';

@Component({
  selector: 'app-sucesspayment',
  templateUrl: './sucesspayment.component.html',
  styleUrls: ['./sucesspayment.component.css']
})
export class SucesspaymentComponent implements OnInit {

  idCharge: string;
  isitFinish

  constructor(private route: ActivatedRoute, public service: StripepaymentService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getParrams();
    this.chargePayment();
  }

  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.idCharge = params.get("id");
    });
  }

  chargePayment(){
    this.service.chargepayment(this.idCharge);
  }

}
