
    <h4 class="text-center"><span class="badge bg-warning">Warning! This could take about serveral minutes to complete.</span></h4>
    <h4 class="text-center"> Are you sure you want import invoices into our cloud services?</h4>

<div class="row">
    <div class="col-6">
        <button type="button" class="btn btn-danger btn-block" mat-button [mat-dialog-close]="data" (click)="confirmInvoicesSubmit()">Import invoices into our cloud services</button>
    </div>
    <div class="col-6">
        <button type="button" class="btn btn-primary btn-block" mat-button mat-dialog-close>Cancel</button>
    </div>
</div>