import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { accountpayment } from 'src/app/_models/accountpayment';
import { PaymentService } from 'src/app/_services/payment/payment.service';
import { ClientlistaccountreceivableComponent } from '../clientlistaccountreceivable/clientlistaccountreceivable.component';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator';
import { EmailRemoveGMT } from 'src/app/_helper/emailremovegmt';
import { AccountService } from 'src/app/_services/account/account.service';
import { User } from 'src/app/_models/user';
import { take } from 'rxjs/operators';
import { ReceivableaccountspaymentsService } from 'src/app/_services/receivablesaccountspayments/receivableaccountspayments.service';
import { ClientaccountpaymentsService } from 'src/app/_services/clientaccountpayments/clientaccountpayments.service';
import { InvoiceValidator } from 'src/app/_validators/invoicevalidator';

@Component({
  selector: 'app-editpaymentaccountreceivable',
  templateUrl: './editpaymentaccountreceivable.component.html',
  styleUrls: ['./editpaymentaccountreceivable.component.css']
})
export class EditpaymentaccountreceivableComponent implements OnInit {

  formAccount: UntypedFormGroup;
  paymentAccount: accountpayment;
  public hidePaymentType: boolean;
  accountID: number;
  invoiceID: number;
  clientID: number;
  user?: User;

  constructor(public fb: UntypedFormBuilder, private paymentservice: PaymentService, 
    @Inject(MAT_DIALOG_DATA) public data: accountpayment,
     public dialogRef: MatDialogRef<ClientlistaccountreceivableComponent>, private accountService: AccountService,
     private receivableaccountspaymentsService: ReceivableaccountspaymentsService,
     private clientAccountPaymentService: ClientaccountpaymentsService) { }

  ngOnInit(): void {
    this.hidePaymentType = false;
    this.accountID = this.data.id;
    this.invoiceID = this.data.invoiceID;
    this.clientID = this.data.clientID;
    console.log('invoice id ' + this.invoiceID);
    this.initializeForm();
    this.assignUser();
    this.onLoad();
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }
  
  initializeForm(){
    console.log('INVOICE');
    console.log('INVOICE' + this.paymentservice.defaultInvoicePayment$);
    this.formAccount = this.fb.group({
      accountmoney:['', [Validators.required, Validators.min(0.01), Validators.max(999999999999999), 
        InvoiceValidator.matchInvoiceFromInvoiceToAmountPaid(this.paymentservice.defaultInvoiceCost)]],
      date: ['', Validators.required],
      option: ['accountreceivable', Validators.required],
      description:['', [WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter, Validators.maxLength(1000)]],
      paymenttype:['Cash', Validators.required],
    })
  }

  onSubmit(){
    this.updatePayment();
    this.paymentservice.updatePayment(this.paymentAccount).subscribe(response => {
      // Get payments //
      this.paymentservice.stopHubConnection();   
      this.paymentservice.stopHubArchiveConnection();
      this.paymentservice.createHubConnection(this.user, this.invoiceID);
      this.paymentservice.createHubArchiveConnection(this.user, this.invoiceID);

      // Get all payments //
      this.receivableaccountspaymentsService.stopHubConnection();
      this.receivableaccountspaymentsService.createHubConnection(this.user, true);

      // Get single payments //
      this.clientAccountPaymentService.stopHubConnection();
      this.clientAccountPaymentService.createHubConnection(this.user, this.clientID, true);
    }, error => {
      console.log(error);
    });
  }

  updatePayment(){
    const date = new Date(this.formAccount.value.date);

    this.paymentAccount = {
       id: this.paymentAccount.id,
       paymentOption: this.formAccount.value.option,
       amount: this.formAccount.value.accountmoney,
       date: date,
       note: this.formAccount.value.description,
       paymentType: this.formAccount.value.paymenttype,
       invoiceID: this.paymentAccount.id,
       dateS: date.toUTCString()
    };
  }
  
  onLoad(){
    this.paymentservice.getEditPayment(this.accountID).subscribe(response => {
      this.paymentAccount = response;
      this.updateInfoForm();
    }, error => {
      console.log(error);
    });
  }

  updateInfoForm(){
   
    var option = "";
    if (this.paymentAccount.paymentOption === 0)
      option = "accountreceivable";
    else if (this.paymentAccount.paymentOption === 1)   
      option = "accountreturn";
    else
    {
      option = "credit"; 
      this.hidePaymentType = true;
    }
      
    console.log('option setting ' + option);
    this.formAccount.controls['option'].setValue(option);
    this.formAccount.controls['accountmoney'].setValue(this.paymentAccount.amount);
    var date = new Date(this.paymentAccount.date);
    this.formAccount.controls['date'].setValue(date);
    this.formAccount.controls['description'].setValue(this.paymentAccount.note);
    this.formAccount.controls['paymenttype'].setValue(this.paymentAccount.paymentType);
  }

  showPaymentForm(value: boolean){
    this.hidePaymentType = value;
  }
}
