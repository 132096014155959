import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { User } from '../_models/user';
import { AccountService } from '../_services/account/account.service';
import { PasswordValidator } from 'src/app/_validators/passwordvalidator';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator';
import { ABNValidation } from '../_validators/abnvalidaton';



@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})


export class RegisterComponent implements OnInit {
  @Output() cancelRegister = new EventEmitter();
  baseUrl = environment.apiUrl;
  validationErrors: string[] = [];
  registerForm: UntypedFormGroup;
  toggleTerms: boolean = false;
  togglePolicy: boolean = false;
  userNameError: boolean = false;

  constructor(private accountService: AccountService, private toastr: ToastrService,
    public fb: UntypedFormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.registerForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8), PasswordValidator.PasswordValidated]],
      confirmpassword: ['', [Validators.required, PasswordValidator.matchValues('password')]],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), WhiteSpacesValidator.cannotContainSpace]],
      firstname: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpace]],
      lastname: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpace]],
      bussinessname: ['', [Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      contactussupportphone: ['', [Validators.maxLength(10), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      contactussupportemail: ['', [Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter, Validators.email]],
      abn: ['', [Validators.maxLength(11), Validators.minLength(11), WhiteSpacesValidator.cannotContainSpace, ABNValidation.checkABNValidation]],
      referal: ['', [Validators.maxLength(255), Validators.minLength(0), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
    });
    this.registerForm.controls.password.valueChanges.subscribe(() => {
      this.registerForm.controls.confirmpassword.updateValueAndValidity();
    })
  }

  matchValues(matchTo: string): ValidatorFn {
    return (control: AbstractControl) => {
      return control?.value === control?.parent?.controls[matchTo].value
       ? null : {isMatching: true}
    }
  }

  register(){
    const email = this.registerForm.controls['username'].value; 
    this.accountService.register(this.registerForm.value).subscribe(response => {
      this.router.navigateByUrl(`/emailresender/${email}`);
    }, error => {
      this.validationErrors = error;
      console.log(error);
      if (error !== null)
      {
        if (error.error === "Username is taken")
        {
          this.userNameError = true;
        }
      }
    });
  }

  cancel(){
    this.cancelRegister.emit(false);
  }

  confirmTermsandConditions(){
    this.toggleTerms = true;
  }

  confirmPolicies(){
    this.togglePolicy = true;
    window.scrollTo(0, 0);   
  }

  getValueService(): string{
     return this.registerForm.controls['typeofservice'].value;    
  }

}
