import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Client } from 'src/app/_models/client';
import { ImportfileClient } from 'src/app/_models/importfileclient';
import { ImportfileInvoice } from 'src/app/_models/importfileinvoice';
import { XeroCustomerKeys } from 'src/app/_models/xerocustomerkeys';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class XeroapiService {
  baseUrl = environment.apiUrl;
  
  constructor(private http: HttpClient) { }

  public getListClients(){
    return this.http.get(this.baseUrl + 'Xero/GetClientsEF').pipe(
      map((response: Client[]) => {
        return response;
      })
    ); 
  }

  public getListAPIContactFile(){
    return this.http.get(this.baseUrl + 'Xero/GetContactsAPI').pipe(
      map((response: ImportfileClient[]) => {
        return response;
      })
    ); 
  }

  public getListInvoice(){
    return this.http.get(this.baseUrl + 'Xero/GetInvoicesEF').pipe(
      map((response: ImportfileInvoice[]) => {
        return response;
      })
    ); 
  }

  public getListAPIInvoiceFile(){
    return this.http.get(this.baseUrl + 'Xero/GetInvoicesAPI').pipe(
      map((response: ImportfileInvoice[]) => {
        return response;
      })
    ); 
  }

  public createImportsInvoices(imports: any){
    return this.http.post(this.baseUrl + 'Xero/postimportsinvoices', imports);
  }

  public createImportsClients(imports: any){
    return this.http.post(this.baseUrl + 'Xero/postimportsclients', imports);
  }


  
}
