import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ImportfilesService } from 'src/app/_services/import/importfiles.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-importpage',
  templateUrl: './importpage.component.html',
  styleUrls: ['./importpage.component.css']
})
export class ImportpageComponent implements OnInit {
  xeroBaseURL: string =  environment.xeroAPI;
  userCanUse: boolean = false;
  isitreadyAPI: boolean = false;
  constructor(@Inject(DOCUMENT) private document: Document, private service: ImportfilesService) { }

  ngOnInit(): void {
    //this.getPermissionForUser();
  }

  importXeroAPI(){
    this.document.location.href = this.xeroBaseURL;
  }

  getPermissionForUser(){
    this.service.getPemissionForUser().subscribe(response => {
        this.userCanUse = true;
    }, err => {
      console.log(err);
      this.userCanUse = false;
    })
  }

}
