<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to view a store account</h2>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Store Credit/View Store Account/vsta06.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        
        <h2>•	Follow instructions to go to “How to open Store Debit/Credit Accounts"</h2>
        <h2>•	The “List of In-Store payments” where you can view the payments relevant to the “Client” you selected earlier.</h2>
        <h2>•	As you can see, there are no “Store Accounts” as none have been created, you can do this by filling the form above to “Create Store Account”</h2>
    </div>
    