<app-nav></app-nav>
<div class="container">
    <div class="row">
        <div class="col-12 mt-4">
            <nav class="navbar navbar-light bg-primary shadow-lg">
                <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-commenting fa-3x" aria-hidden="true"></i> Settings for Text Messaging</a>  
                <button type="button" class="btn btn-outline-light btn-lg mt-2 mt-md-0" (click)="toggle.toggleWindow(38)"><i class="{{toggle.$Icon[38]}}" aria-hidden="true\"></i> {{toggle.$ToggleText[38]}}</button>
            </nav>
            <hr>
        </div>
        <div class="col-12" [hidden]="!toggle.$Toggle[38]">
            <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded">
                <div class="card-body">
                  <fieldset class="form-group">           
                    <div class="row">
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
                        <img src="/assets/images/profile-account.jpg" alt="profile account" class="img-thumbnail rounded center">
                        <legend class="text-center center fs-3">How to use Messages
                            
                        </legend>
                        <div class="form-group">                      
                          <small id="fileHelp" class="form-text text-muted text-center fs-6">
                            To find out more, just click on the link below!
                          </small>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
                        <legend class="text-center fs-3 text-uppercase text-primary"><b>HOW TO USE THIS!</b></legend>
                        <div class="embed-responsive embed-responsive-1by1 sizer shadow-lg border">
                          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/alJ85BPBZwc?si=9iyVkUUVJ4O7E6Wm" allowfullscreen></iframe>
                        </div>
                      </div>
                    </div>              
                    <button type="submit" class="btn btn-outline-primary btn-lg btn-block mt-4" routerLink="/members/turtorialhome"><i class="fas fa-info"></i> Click Here To Find Out More!</button>
                  </fieldset>
                </div>
            </div>
        </div>
        <div class="col-12 mt-4">
            <div class="card text-white bg-primary mb-3">
                <div class="card-body">
                    <div class="row">
                        <ng-container *ngIf="messageService.message ">             
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <h4 class="card-title text-uppercase">Due Date Email Message:</h4>
                                <div class="alert alert-dismissible alert-light" >
                                    <div innerHTML *ngIf="messageService.message.dueDateEmailMessage">                               
                                        {{dueDateEmailMessage}}
                                    </div>                          
                                </div>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <button type="button" class="btn btn-success btn-block" (click)="changeDueDateEmailText()"><strong>Change Due Date Email Text</strong></button>
                                       
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <button type="button" class="btn btn-light btn-block" (click)="openExampleDueDateEmail()"><strong>Show An Example For Email</strong></button>
                                    </div>
                                </div>       
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <h4 class="card-title text-uppercase">Due Date SMS:</h4>
                                <div class="alert alert-dismissible alert-light">
                                <strong><i>{{messageService.message.dueDateSMSMessage}}</i></strong>
                                </div> 
                                <button type="button" class="btn btn-success btn-block" (click)="changeDueDateSMS()"><strong>Change Due Date SMS Message</strong></button>                          
                            </div>  
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <h4 class="card-title text-uppercase mt-4">Due Date Instalment Email Message:</h4>
                                <div class="alert alert-dismissible alert-light" >
                                    <div innerHTML *ngIf="messageService.message.dueDateEmailMessage">                               
                                        {{instalmentDueDateEmailMessage}}
                                    </div>                          
                                </div>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <button type="button" class="btn btn-success btn-block" (click)="changeInstalmentDueDateEmail()"><strong>Change Due Instalment Due Date</strong></button>
                                       
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <button type="button" class="btn btn-light btn-block" (click)="openExampleInstalmentDueDateEmail()"><strong>Show An Example For Email</strong></button>
                                    </div>
                                </div>       
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <h4 class="card-title text-uppercase mt-4">DUE DATE INSTALMENT SMS MESSAGE:</h4>
                                <div class="alert alert-dismissible alert-light">
                                <strong><i>{{messageService.message.dueDateInstalmentSMSMessage}}</i></strong>
                                </div>
                                <button type="button" class="btn btn-success btn-block" (click)="changeInstalmentDueDateSMS()"><strong>Change Instalment Due Date SMS payment Message</strong></button>                 
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <h4 class="card-title text-uppercase mt-4">Payment Receive Email Message:</h4>
                                <div class="alert alert-dismissible alert-light">
                                <strong><i>{{paymentReceiveEmailMessage}}</i></strong>
                                </div>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <button type="button" class="btn btn-success btn-block" (click)="changeReceiveDateEmailText()"><strong>Change Receive Payment Text</strong></button>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <button type="button" class="btn btn-light btn-block" (click)="openExampleReceiveEmail()"><strong>Show An  Example For Email</strong></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <h4 class="card-title text-uppercase mt-4">Payment Receive  SMS:</h4>
                                <div class="alert alert-dismissible alert-light">
                                <strong><i>{{messageService.message.receiveSMSMessage}}</i></strong>
                                </div>
                                <button type="button" class="btn btn-success btn-block" (click)="changeReceiveSMS()"><strong>Change Receive SMS payment Message</strong></button>                 
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <h4 class="card-title text-uppercase mt-4">Initial Email Message:</h4>
                                <div class="alert alert-dismissible alert-light">
                                <strong><i>{{initialEmailMessage}}</i></strong>
                                </div>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <button type="button" class="btn btn-success btn-block" (click)="changeInitialEmailText()"><strong>Change Initial Email Message</strong></button>                 
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <button type="button" class="btn btn-light btn-block" (click)="openExampleInitialEmail()"><strong>Show An  Example For Email</strong></button>
                                    </div>
                                </div>
                              
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <h4 class="card-title text-uppercase mt-4">Initial SMS Message:</h4>
                                <div class="alert alert-dismissible alert-light">
                                <strong><i>{{messageService.message.initialSMSMessage}}</i></strong>
                                </div>
                                <button type="button" class="btn btn-success btn-block" (click)="changeInitialSMS()"><strong>Change Initial SMS Message</strong></button>                 
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <h4 class="card-title text-uppercase mt-4">Initial Instalment Email Message:</h4>
                                <div class="alert alert-dismissible alert-light">
                                <strong><i>{{initalInstalmentEmailMessage}}</i></strong>
                                </div>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <button type="button" class="btn btn-success btn-block" (click)="changeInitialInstalmentEmailText()"><strong>Change Instalment Initial Email Message</strong></button>                 
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <button type="button" class="btn btn-light btn-block" (click)="openExampleInstalmentInitialEmail()"><strong>Show An  Example For Email</strong></button>
                                    </div>
                                </div>
                              
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <h4 class="card-title text-uppercase mt-4">Initial Instalment SMS Message:</h4>
                                <div class="alert alert-dismissible alert-light">
                                <strong><i>{{messageService.message.initialInstalmentSMSMessage}}</i></strong>
                                </div>
                                <button type="button" class="btn btn-success btn-block" (click)="changeInitialInstalmentSMS()"><strong>Change Initial Instalment SMS Message</strong></button>                 
                            </div>
                        </ng-container>                
                      
                    </div>  
                </div>
            </div>
                                           
        </div>
        <div class="col-12">
            <div class="col-12 mt-4">
                <h1 class="text-center text-primary mt-2 font-weight-bold">Settings for Time Management</h1>
                <hr/>
            </div>
            <div class="card text-white bg-primary mb-3">
                <div class="card-body">
                    <div class="row">
                        <ng-container *ngIf="timemanagement">                        
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <h4 class="card-title text-uppercase">Due Time To Send Messages To Customers:</h4>
                                <div class="alert alert-dismissible alert-light">
                                <strong><i>{{getTimeVlaue(timemanagement.dueTime)}}</i></strong>
                                </div>
                                <button type="button" class="btn btn-success btn-block" (click)="changeDueTime()"><strong>Change Due Date Time</strong></button>                              
                            </div>
                            <!--
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <h4 class="card-title text-uppercase">Time Zone:</h4>
                                <div class="alert alert-dismissible alert-light">
                                <strong><i>{{timemanagement.timeFormat }}</i></strong>
                                </div>
                                <button type="button" class="btn btn-success btn-block" (click)="changeTimeZone()"><strong>Change Time Zone</strong></button>
                            </div>-->
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 mt-4">
        <h1 class="text-center text-primary mt-2 font-weight-bold">Messages Sent</h1>
        <hr/>
    </div>
    <div class="col-12">
        <app-messageslist></app-messageslist>
    </div>
</div>