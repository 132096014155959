import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MessagesService } from 'src/app/_services/message/messages.service';

@Component({
  selector: 'app-initialsmsinstalmentmessage',
  templateUrl: './initialsmsinstalmentmessage.component.html',
  styleUrls: ['./initialsmsinstalmentmessage.component.css']
})
export class InitialsmsinstalmentmessageComponent implements OnInit {
  public data: any;
  @ViewChild('editForm') editForm: NgForm; 
  formEdit: UntypedFormGroup;
  
  constructor(public fb: UntypedFormBuilder, private service: MessagesService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.initializeForm();
    this.onLoad();
  }

  onLoad(){
    this.service.getInitialInstalmentSMS().subscribe(response => {
      console.log("SMS");
      this.data = response;
      this.formEdit.controls['changeText'].setValue(this.data.initialInstalmentSMSMessage);
      console.log(response);
    }, error => { 
      console.log(error);
    })
  }

  generateText(){
    this.formEdit.controls['changeText'].setValue("Dear [name],\r\n" +
    "I hope this email finds you well." +
    " We appreciate your continued partnership and would like to remind you that the next installment payment of #[invoicenumber] is due on [duedate].\r\nInvoice Details:\r\n" +
    "Invoice Number: [invoicecnumber]\r\nDue Amount Now: [dueamount]\r\nDue Date: [duedate]\r\nAmount Paid: [amountpaid]\r\nTo ensure a smooth transaction and avoid any inconvenience," +
    " we kindly request that you process the payment by the specified due date. " +
    " Please visit the Invoice Pager website below, and enter the following code: [code]\r\n" +
    "[link] \r\n" +
    "If you have already processed the payment, " +
    "we sincerely thank you for your promptness. However, " +
    "if you encounter any difficulties or have any questions regarding the payment process, " +
    "please do not hesitate to contact our email at " +
    "[mybusinessemail]\r\n" +
    "Your business is of utmost importance to us, and your timely payments enable us to " +
    "consistently deliver the highest level of service. We look forward to sustaining this positive collaboration.\r\n" +
    "Best regards,\r\n[mybusiness]\r\n[mybusinessphone]\r\n");
    this.formEdit.markAsDirty();
  }

  initializeForm(){
    this.formEdit = this.fb.group({
      changeText:['', [ Validators.required, Validators.maxLength(2000) ]],
    });  
  }

  onSubmit(){
    const message = {
      InitialInstalmentSMSMessage: this.formEdit.controls['changeText'].value
    }
    this.data.message = message.InitialInstalmentSMSMessage;
    this.service.updateInitialInstalmentSMSMessage(message).subscribe(response => {
      this.onLoadSettingMesaging();
      this.toastr.success("Updated Message!");
    }, error => {
      console.log(error);
    })  
  }

  onLoadSettingMesaging(){
    this.service.getTextSettingMessaging().subscribe(response => {
      this.service.message = response;
      if (this.service.message == null){
        this.service.setMessageValues();
      }
      console.log(response);
    }, error => {
      console.log(error);
    })
  }

}
