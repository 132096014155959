import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { InstalmentService } from 'src/app/_services/instalment/instalment.service';

@Component({
  selector: 'app-dialogrestinstalmentinitalmessage',
  templateUrl: './dialogrestinstalmentinitalmessage.component.html',
  styleUrls: ['./dialogrestinstalmentinitalmessage.component.css']
})
export class DialogrestinstalmentinitalmessageComponent implements OnInit {
  user?: User;
  accountID: number;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private service: InstalmentService, private toastr: ToastrService, private accountService: AccountService ) { }

  ngOnInit(): void {
    this.assignUser();
    this.accountID = this.data.id;
  }

  resetInitialMessage(): void{
    this.service.resetInstalmentMessage(this.accountID).subscribe(response => {
      if (response)
      {
        this.toastr.success("You have successfully reseted instalment account initial message!");
        this.service.stopHubConnection();
        this.service.createHubConnection(this.user, +this.service.$invoiceID);
      }
    }, err => {
      console.log(err);
    })
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

}
