import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { EmailRemoveGMT } from 'src/app/_helper/emailremovegmt';
import { Instalment } from 'src/app/_models/instalment';
import { StoreAccountModel } from 'src/app/_models/storeaccountmodel';
import { User } from 'src/app/_models/user';
import { UserStoreInfoAccount } from 'src/app/_models/userstoreinfoacccount';
import { AccountService } from 'src/app/_services/account/account.service';
import { InstalmentService } from 'src/app/_services/instalment/instalment.service';
import { StoreaccountService } from 'src/app/_services/storeaccount/storeaccount.service';
import { TogglewindowService } from 'src/app/_services/togglewindow.service';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator';

@Component({
  selector: 'app-createstorecredit',
  templateUrl: './createstorecredit.component.html',
  styleUrls: ['./createstorecredit.component.css']
})
export class CreatestorecreditComponent implements OnInit {

  @ViewChild('editForm') editForm: NgForm;  
  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any){
    if (this.editForm.dirty){
      $event.returnValue = true;
    }
  }

  clientID: string;
  formAccount: UntypedFormGroup;
  storeAccount: StoreAccountModel;
  clientInfo: UserStoreInfoAccount;
  user?: User;
  constructor(public fb: UntypedFormBuilder, public services: StoreaccountService, private route: ActivatedRoute, 
    private toastr: ToastrService, public toggle: TogglewindowService, private accountService: AccountService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getParrams();
    this.initializeForm();
    this.loadUserInfo();
    this.assignUser();
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  createStoreAccount(){
    const clientID: number = +this.clientID;
    //const date = EmailRemoveGMT.RemovedEmailGMT(this.formAccount.controls['datepaid'].value);
    const date = new Date(this.formAccount.controls['datepaid'].value);
    this.storeAccount = {
      typePayment: this.formAccount.controls['paymenttype'].value,
      datePaid: date,
      note: this.formAccount.controls['note'].value,
      paymentAmount: this.formAccount.controls['paymentamount'].value,
      datePaidS: date.toUTCString(),
      clientID: clientID
    };



    this.services.createStoreAccount(this.storeAccount).subscribe(response => {
      if (response == "s")
      {
        this.services.dataSource = new MatTableDataSource<StoreAccountModel>();
        this.toastr.success("Successfully created store account", "Created Store Account");
        this.services.stopHubConnection();
        this.services.createHubConnection(this.user, +clientID, false);       
        this.initializeForm();
      }
    }, err => {
      console.log(err);
    });
  }

  initializeForm(){
    this.formAccount = this.fb.group({
      paymentamount: ['', [Validators.required, Validators.min(0.01), Validators.max(999999999999999)]],
      note: ['', [WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter, Validators.maxLength(1000)]],
      datepaid: ['', Validators.required],
      paymenttype: ['', Validators.required]
    })
  }

  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.clientID = params.get("id");
    });
  }

  loadUserInfo(){
    this.services.getStoreUserInfo(this.clientID).subscribe(
      response => {
        this.clientInfo = response;
        console.log(this.clientInfo);
      }, err => {
        console.log(err);
      }
    );
  }
}
