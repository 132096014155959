import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EmailInfo } from 'src/app/_models/emailinfo';
import { User } from 'src/app/_models/user';
import { EmailconfigService } from 'src/app/_services/email/emailconfig.service';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator';

@Component({
  selector: 'app-contactemail',
  templateUrl: './contactemail.component.html',
  styleUrls: ['./contactemail.component.css']
})
export class ContactemailComponent implements OnInit {
  @ViewChild('editForm') editForm: NgForm;
  showExtraEmail: boolean;
  user: User;
  emailInfo: EmailInfo;
  contactForm: UntypedFormGroup;
  constructor(private toastr: ToastrService, private fb: UntypedFormBuilder, private services: EmailconfigService) {

  }

  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any){
    if (this.editForm.dirty){
      $event.returnValue = true;
    }
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.showExtraEmail = true;
    this.initializeForm();
    this.getEmailInfo();
  }

  sendEmail(): void {
    const email = {
      typemessage: this.contactForm.controls['typemessage'].value.toString(),
      email: this.contactForm.controls['email'].value,
      firstname: this.contactForm.controls['firstname'].value,
      lastname: this.contactForm.controls['lastname'].value,
      phone: this.contactForm.controls['phone'].value,
      message:this.contactForm.controls['message'].value
    };

    console.log(email);


    this.services.contactUs(email).subscribe(response => {
      if (response)
      {
        this.toastr.success("Message Sent!");
        this.initializeForm();
      }
      else
      {
        this.toastr.error("Message fail to send!");
      }
    }, err => {
      console.log(err);
    })

  }

  initializeForm(){
    this.contactForm = this.fb.group({
      typemessage: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      firstname: ['', [Validators.required, Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpace]],
      lastname: ['', [Validators.required, Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpace]],
      phone: ['',[Validators.required, Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      message: ['', [Validators.required, Validators.maxLength(1000), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
    });
  }

  getEmailInfo(){
    this.services.getEmailInfo().subscribe(response =>
    {
      this.emailInfo = response;
      if (this.emailInfo != null)
      {
        this.showExtraEmail = true;
        this.assignValuesForms();        
        this.showExtraEmail = false;
      }

    }, err => {
      console.log(err);
    });
  }

  assignValuesForms(){
    this.contactForm.controls['email'].setValue(this.emailInfo.email);
    this.contactForm.controls['firstname'].setValue(this.emailInfo.firstName);
    this.contactForm.controls['lastname'].setValue(this.emailInfo.lastName);
    this.contactForm.controls['phone'].setValue(this.emailInfo.phone);  
  }
}
