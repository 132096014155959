<app-nav></app-nav>
<ng-container *ngIf="accountService.currentUser$ | async as user">
  <div class="container">
      <div class="row">
          <div class="col-12 mt-4">
            <nav class="navbar navbar-light bg-primary shadow-lg">
              <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> {{titleText}}</a>
              <button type="button" class="btn btn-outline-light btn-lg mt-2 mt-md-0" (click)="toggle.toggleWindow(35)"><i class="{{toggle.$Icon[35]}}" aria-hidden="true\"></i> {{toggle.$ToggleText[35]}}</button>
            </nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]= "['/members/clients']">Clients</a></li>
                <li class="breadcrumb-item active">Account Payments</li>     
              </ol>
              <div [hidden]="!toggle.$Toggle[35]">
                <app-clientinformation [isitinvoice]="'no'"></app-clientinformation>
              </div>
          </div>        
          <div class="col-12 mt-4">     

    <div class="col-12 mt-4">
      <h1 class="text-center text-primary mt-2 font-weight-bold"><i class="fas fa-coins fa-lg"></i> {{titleText}}</h1>
    </div>
    <div class="bs-component mt-4">
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary rounded ">
      <div class="container-fluid">
        <a class="navbar-brand"><i class="fas fa-search fa-lg"></i></a>
        <a class="navbar-brand text-white font-weight-bold" > Search Features</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarColor01">
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <button [class]="buttonClass" (click)="toggleButtonPayments()">{{showText}}</button>
            </li>
          </ul>
          <form class="d-flex" *ngIf="!toggleCashflowBtn">
            <input class="form-control me-sm-2" type="search" placeholder="Search" (keyup)="applyFilter($event)">
          </form>
        </div>
      </div>
    </nav>
  </div>
      <div class="mat-elevation-z8 mt-2" *ngIf="(clientAccountPaymentService.allPayments$ | async) && !toggleCashflowBtn">
          <table mat-table [dataSource]="clientAccountPaymentService.accountsDataSource$" multiTemplateDataRows matSort>           
            <!-- Invoice Number Column -->
            <ng-container matColumnDef="InvoiceNumber">
              <th mat-header-cell *matHeaderCellDef class="text-center">
                <ng-container *ngIf="user.typeOfService; else showInvoiceNo ">
                  Billing No.
                </ng-container>
                <ng-template #showInvoiceNo>
                  Invoice No.
                </ng-template>   
              </th>
              <td mat-cell *matCellDef="let account" > 
                <p class="text-center text-primary"><b>{{account.invoiceNumber}}</b></p>
              </td>
            </ng-container>
        
            <!-- Invoice Amount Column -->
            <ng-container matColumnDef="InvoiceAmount">
              <th mat-header-cell *matHeaderCellDef class="text-left">Invoice Amount </th>
              <td mat-cell *matCellDef="let account"> 
                  <p class="ms-3 text-success font-weight-bold"> {{account.invoiceAmount | currency }} </p> 
              </td>
            </ng-container>
        
            <!-- Paid Amount Column -->
            <ng-container matColumnDef="AmountPaid">
              <th mat-header-cell *matHeaderCellDef class="text-left">Paid Amount</th>
              <td mat-cell *matCellDef="let account"> 
                <p class="ms-3 text-success font-weight-bold">{{account.amount | currency }} </p>              
              </td>
            </ng-container>

              <!-- Date Paid Column -->
              <ng-container matColumnDef="DatePaid">
                  <th mat-header-cell *matHeaderCellDef class="text-left">Payment Date Paid </th>
                  <td mat-cell *matCellDef="let account">
                      <p>{{account.date | date }}</p>
                  </td>
                </ng-container>

              <!-- Payment Option Column -->
              <ng-container matColumnDef="PaymentOption">
                  <th mat-header-cell *matHeaderCellDef>Payment Option</th>
                  <td mat-cell *matCellDef="let account">             
                      <ng-container *ngIf="account.paymentOption===0">
                        <span class="badge bg-success">
                          <mat-icon>account_balance</mat-icon> Account Paid
                        </span>   
                      </ng-container>                                              
                      <ng-container *ngIf="account.paymentOption===2">
                        <span class="badge bg-primary">
                          <mat-icon>credit_card</mat-icon> Store Credits
                        </span>
                      </ng-container>
                      <ng-container *ngIf="account.paymentOption===3">
                        <span class="badge bg-primary">
                          <mat-icon>credit_card</mat-icon> Store Debits
                        </span>
                      </ng-container>                         
                      <ng-container *ngIf="account.paymentOption===1">
                        <span class="badge bg-warning">
                          <mat-icon>keyboard_return</mat-icon> Accounts Returns
                        </span>
                      </ng-container>              
                  </td>
                </ng-container>
              <!-- Payment Type Column -->
              <ng-container matColumnDef="PaymentType">
                  <th mat-header-cell *matHeaderCellDef class="text-left">Payment Type</th>
                  <td mat-cell *matCellDef="let account">
                    <ng-container *ngIf="account.paymentOption===2;else showdefaultpayment">
                      <span class="badge bg-primary">Special</span> 
                    </ng-container>
                    <ng-template #showdefaultpayment><span class="badge bg-success">{{account.paymentType}}</span></ng-template>          
                  </td>
                </ng-container>
              <!-- Actions Column -->
              <ng-container matColumnDef="Actions">
                  <th mat-header-cell *matHeaderCellDef class="text-center"> </th>
                  <td mat-cell *matCellDef="let account">
                    <div class="wrap">
                      <button mat-mini-fab color="primary" [routerLink]="['/members/accountclientreceivable/', account.invoiceID, account.invoiceAmount]">
                        <mat-icon>account_balance</mat-icon>
                      </button>  
                    </div>                 
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            
              <mat-paginator [pageSizeOptions]="[25]" showFirstLastButtons></mat-paginator>
        </div>

        <div class="mat-elevation-z8 mt-2" *ngIf="toggleCashflowBtn">
          <table mat-table [dataSource]="accountsDataSource" multiTemplateDataRows matSort>           
            <!-- Invoice Number Column -->
            <ng-container matColumnDef="InvoiceNumber">
              <th mat-header-cell *matHeaderCellDef class="text-center">
                <ng-container *ngIf="user.typeOfService; else showInvoiceNo ">
                  Billing No.
                </ng-container>
                <ng-template #showInvoiceNo>
                  Invoice No.
                </ng-template>   
              </th>
              <td mat-cell *matCellDef="let account" > 
                <p class="text-center text-primary"><b>{{account.invoiceNumber}}</b></p>
              </td>
            </ng-container>
        
            <!-- Invoice Amount Column -->
            <ng-container matColumnDef="InvoiceAmount">
              <th mat-header-cell *matHeaderCellDef class="text-left">Invoice Amount </th>
              <td mat-cell *matCellDef="let account"> 
                  <p class="ms-3 text-success font-weight-bold"> {{account.invoiceAmount | currency }} </p> 
              </td>
            </ng-container>
        
            <!-- Paid Amount Column -->
            <ng-container matColumnDef="AmountPaid">
              <th mat-header-cell *matHeaderCellDef class="text-left">Paid Amount</th>
              <td mat-cell *matCellDef="let account"> 
                <p class="ms-3 text-success font-weight-bold">{{account.amount | currency }} </p>              
              </td>
            </ng-container>

              <!-- Date Paid Column -->
              <ng-container matColumnDef="DatePaid">
                  <th mat-header-cell *matHeaderCellDef class="text-left">Payment Date Paid </th>
                  <td mat-cell *matCellDef="let account">
                      <p>{{account.date | date }}</p>
                  </td>
                </ng-container>

              <!-- Payment Option Column -->
              <ng-container matColumnDef="PaymentOption">
                  <th mat-header-cell *matHeaderCellDef>Payment Option</th>
                  <td mat-cell *matCellDef="let account">             
                      <ng-container *ngIf="account.paymentOption===0">
                        <span class="badge bg-success">
                          <mat-icon>account_balance</mat-icon> Account Paid
                        </span>   
                      </ng-container>                                              
                      <ng-container *ngIf="account.paymentOption===2">
                        <span class="badge bg-primary">
                          <mat-icon>credit_card</mat-icon> Store Credits
                        </span>
                      </ng-container>
                      <ng-container *ngIf="account.paymentOption===3">
                        <span class="badge bg-primary">
                          <mat-icon>credit_card</mat-icon> Store Debits
                        </span>
                      </ng-container>                         
                      <ng-container *ngIf="account.paymentOption===1">
                        <span class="badge bg-warning">
                          <mat-icon>keyboard_return</mat-icon> Accounts Returns
                        </span>
                      </ng-container>              
                  </td>
                </ng-container>
              <!-- Payment Type Column -->
              <ng-container matColumnDef="PaymentType">
                  <th mat-header-cell *matHeaderCellDef class="text-left">Payment Type</th>
                  <td mat-cell *matCellDef="let account">
                    <ng-container *ngIf="account.paymentOption===2;else showdefaultpayment">
                      <span class="badge bg-primary">Special</span> 
                    </ng-container>
                    <ng-template #showdefaultpayment><span class="badge bg-success">{{account.paymentType}}</span></ng-template>          
                  </td>
                </ng-container>
              <!-- Actions Column -->
              <ng-container matColumnDef="Actions">
                  <th mat-header-cell *matHeaderCellDef class="text-center"> </th>
                  <td mat-cell *matCellDef="let account">
                    <div class="wrap">
                      <button mat-mini-fab color="primary" [routerLink]="['/members/accountclientreceivable/', account.invoiceID, account.invoiceAmount]">
                        <mat-icon>account_balance</mat-icon>
                      </button>  
                    </div>                 
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            
              <mat-paginator [pageSizeOptions]="[10000]" showFirstLastButtons></mat-paginator>
        </div>
    <div class="mt-3"></div>
          </div>
      </div>
  </div>
</ng-container>
