<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to search for a client</h2>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Client/Images/Search Client/sc01.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Search for the client you want to find by typing their first or last name into the search box</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Client/Images/Search Client/sc02.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	The search results will appear below</h2>
    </div>