import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ImportfileInvoice } from 'src/app/_models/importfileinvoice';
import { ImportfilesService } from 'src/app/_services/import/importfiles.service';
import { ClientOrderList } from '../../importedclients/importfile/import.component';

@Component({
  selector: 'app-importfileinvoice',
  templateUrl: './importfileinvoice.component.html',
  styleUrls: ['./importfileinvoice.component.css']
})
export class ImportfileinvoiceComponent implements OnInit {

  listMode: boolean = false;
  orderList: ClientOrderList[];
  importFiles: ImportfileInvoice[];
  selectedFile: File = null;
  userCanUse: boolean = false;
  shoeErrorMsg: boolean = false;
  constructor(private service: ImportfilesService, private formBuilder: UntypedFormBuilder) { }

  checkoutImport = this.formBuilder.group({
    invoicenumber: '',
    invoiceamount: '',
    paymentamount: '',
    contactMobile: '',
    initialdate: '',
    duedate: '',
    mobilecontact: '',
    note: '',
  });

  ngOnInit(): void {
    this.createOrderList();
    this.getPermissionForUser();
  }

  onSubmit(){
    var valuesList = this.orderList.map(function(object){
      return object.show;
    })
    const fd = new FormData();
    fd.append('sheet', this.selectedFile, this.selectedFile.name);
    console.log(this.selectedFile);
    
    this.service.importFileInvoice(fd, valuesList.toString()).toPromise().then(response => {
      this.importFiles = response;
      console.log( 'import files');
      console.log( this.importFiles );
      if (this.importFiles.length > 0)
      {
        this.listMode = true;
      }
    }, error => {
      console.log(error);
      this.shoeErrorMsg = true;
    });
  }

  onChangeCheckBox(event: any, name: string, index: number){
    const value = <ClientOrderList> {
      type: name,
      show: this.getCheckboxValue(event.target.checked),
    }
    this.orderList[index] = value;   
  }



  getCheckboxValue(value: boolean): number{
    if (value){
      return 1;
    } 
    return 0;
  }

  createOrderList(){
    this.orderList = [
      {type: 'Invoice Number', show: 1 },
      {type: 'Invoice Amount', show: 1 },
      {type: 'Payment Amount', show: 1 },
      {type: 'Initial Date', show: 1 },
      {type: 'Due Date', show: 1 },
      {type: 'First Name', show: 1 },
      {type: 'Last Name', show: 1 },
      {type: 'Mobile Contact', show: 1},
      {type: 'Note', show: 0 },
    ];
  }

  onFileSelected(event){
    this.selectedFile = <File>event.target.files[0];
  }

  getPermissionForUser(){
    this.service.getPemissionForUser().subscribe(response => {
        this.userCanUse = true;

    }, err => {
      console.log(err);
      this.userCanUse = false;
    })
  }

}
