<div class="bs-docs-section container-fluid">
    <div class="row">
      <div class="col-lg-12"> 
        <div class="inlineimage"> 
            <img class="img-responsive images .d-none .d-sm-block .d-sm-none .d-md-block" 
            src="https://cdn0.iconfinder.com/data/icons/credit-card-debit-card-payment-PNG/128/Mastercard-Curved.png"> 
            <img class="img-responsive images .d-none .d-sm-block .d-sm-none .d-md-block ms-1" 
            src="/assets/images/googlepay.png"> 
            <img class="img-responsive images .d-none .d-sm-block .d-sm-none .d-md-block ms-1" 
            src="/assets/images/visa.png">
            <img class="img-responsive images .d-none .d-sm-block .d-sm-none .d-md-block ms-1" 
            src="https://cdn0.iconfinder.com/data/icons/credit-card-debit-card-payment-PNG/128/American-Express-Curved.png"> 
        </div>
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xm-3 ">
            <div class="container py-3">
              <footer class="">
                <div class="row">
                  <div class="col-12 col-md center-block">
                    <img src="assets/images/Invoice pager_logo.jpg" class="rounded center" alt="Business Logo">
                    <small class="d-block mb-3 text-muted text-center mt-4">© 2022–2023</small>
                  </div>
                </div>
              </footer>
            </div>
            <hr>
          </div>
          <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xm-9">
            <div class="page-header">  
              <h1 class="text-primary font-weight-bold">Payment Status</h1>
            </div>
            <div class="card border-primary mb-3">
              <div class="card-body">  
                  <div class="alert alert-dismissible alert-info mt-4" *ngIf="(service.paymentCustomerClientHasPay$ | async) === null">
                      <button type="button" class="close" data-dismiss="alert">×</button>
                      <strong>Your payment is being process</strong>. Please hold on!
                  </div>
                  <div class="alert alert-dismissible alert-success mt-4" *ngIf="(service.paymentCustomerClientHasPay$ | async) === 1">
                      <button type="button" class="close" data-dismiss="alert">×</button>
                      <strong>Success</strong> Your payment has been successful!
                  </div>
                  <div class="alert alert-dismissible alert-success mt-4" *ngIf="(service.paymentCustomerClientHasPay$ | async) === 2">
                      <button type="button" class="close" data-dismiss="alert">×</button>
                      <strong>Your payment has been successfully processed! </strong> Our records indicate that the transaction has been completed and the funds have been received.
                    
                  </div>
                  <div class="alert alert-dismissible alert-danger mt-4" *ngIf="(service.paymentCustomerClientHasPay$ | async) === 3">
                      <button type="button" class="close" data-dismiss="alert">×</button>
                      <strong>Failed</strong> Your payment details is invalid!
                  </div>
                  <div class="mt-4">
                      <section>
                          <p>                       
                            Thank you for choosing our services! If you have any inquiries, please reach out to us via email at
                          <a href="mailto:billingaccounts@invoicepager.com"> Click Here to Email Us!</a>.
                          </p>
                      </section>
              </div>
            </div>  
          </div> 
          </div>
        </div>
      </div>
    </div>
</div>