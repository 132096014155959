import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MemberingroupPermissions } from 'src/app/_models/memberingrouppermissions';
import { UsergroupspermissionsService } from 'src/app/_services/usergroups/usergroupspermissions.service';
import { DeletememberinagroupComponent } from '../deletememberinagroup/deletememberinagroup.component';

@Component({
  selector: 'app-listmembersinagroup',
  templateUrl: './listmembersinagroup.component.html',
  styleUrls: ['./listmembersinagroup.component.css']
})
export class ListmembersinagroupComponent implements AfterViewInit  {
  displayedColumns: string[] = ['groupname', 'firstname', 'lastname', 'email', 'activate', 'action'];

  
  constructor(public services: UsergroupspermissionsService, private dialog: MatDialog) { 
    this.onLoad();
  }

  ngAfterViewInit() {

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.services.membersdataSource.filter = filterValue.trim().toLowerCase();

    if (this.services.membersdataSource.paginator) {
      this.services.membersdataSource.paginator.firstPage();
    }
  }

  onLoad(){
    this.services.getMembersFromGroup().subscribe(response => {
      console.log(response);
      this.services.membersdataSource = new MatTableDataSource(response);
      this.services.membersdataSource.paginator = this.services.memberPaginator;
      this.services.membersdataSource.sort = this.services.membersort;
    }, err => {
      console.log(err);
    })
  }

  editAcount(id: number){

  }

  deleteAccount(id: number){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "75vw";
    dialogConfig.data = {
      id: id,
      deleteClient: false
    };

    const dialogRef = this.dialog.open(DeletememberinagroupComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.deleteClient != null){
        const index = this.services.membersdataSource.data.findIndex(x => x.id === result.id); 
        this.services.membersdataSource.filteredData.splice(index, 1);
        this.services.membersdataSource._updateChangeSubscription();
      }
    });
  }   
}