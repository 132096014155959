import { Injectable } from '@angular/core';
import { PaginatedResult, Pagination } from 'src/app/_models/pagination';
import { environment } from 'src/environments/environment';
import { Instalment } from 'src/app/_models/instalment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { InstalmentClientInfo } from 'src/app/_models/instalmentClientInfo';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { User } from 'src/app/_models/user';
import { HostpresenceService } from '../hubsignalr/hostpresence.service';
import { ConnectionSignalRHub } from 'src/app/_helper/connectionsignalrhub';
import { MatTableDataSource } from '@angular/material/table';

@Injectable({
  providedIn: 'root'
})
export class InstalmentService {
  baseUrl =  environment.apiUrl;
  paginatedResult: PaginatedResult<Instalment[]> = new PaginatedResult<Instalment[]>();
  public $invoiceID: string;
  public showarchive: boolean;
  private instalmentSource = new BehaviorSubject<Instalment[]>([]);
  public showCreateFormSource = new ReplaySubject<Boolean>(1);
  public showCreateForm$ = this.showCreateFormSource.asObservable();
  public defaultValueCostAmountSource = new ReplaySubject<number>(1);
  public defaultValueCost$ = this.defaultValueCostAmountSource.asObservable();
  public lastestInstalmentDate = new Date;
  instalment$ = this.instalmentSource.asObservable();
  public instalmentDataSource = new MatTableDataSource<Instalment>();
  private hubConnection?: HubConnection;
  private hubArchiveConnection?: HubConnection; 
  huburl = environment.hubUrl;

  constructor(private http: HttpClient, private presenceHub: HostpresenceService) { }

  async createHubConnection(user: User, accountID: number) 
  {  
    if (ConnectionSignalRHub.CheckAccessHub(this.presenceHub.pageActive[3], false) === false)
      return;

    this.hubArchiveConnection = ConnectionSignalRHub.GetConnectionHub(this.hubConnection, this.huburl, accountID, user, "instalment");

    this.hubArchiveConnection.on('ReceiveInstalmentAccounts', (instalments: Instalment[]) => {
      if (instalments !== null)
      { 
        if (this.showarchive === true)
        {
          return;
        }

        this.CheckInstalmentSource(instalments);
      }         
    })  
  }

  async createHubArchiveConnection(user: User, accountID: number) 
  {  
    if (ConnectionSignalRHub.CheckAccessHub(this.presenceHub.pageActive[3], false) === false)
      return;

    this.hubArchiveConnection = ConnectionSignalRHub.GetConnectionHub(this.hubConnection, this.huburl, accountID, user, "instalmentarchive");

    this.hubArchiveConnection.on('ReceiveInstalmentArchiveAccounts', (instalments: Instalment[]) => {
      if (instalments !== null)
      { 
        if (this.showarchive === false)
        {
          return;
        }
        this.CheckInstalmentSource(instalments);
      }         
    })  
  }

  stopHubConnection(){
    if (this.hubConnection) {
      this.hubConnection?.stop();
    }
  }

  stopHubArchiveConnection(){
    if (this.hubConnection) {
      this.hubConnection?.stop();
    }
  }

  createInstalment(model: Instalment){
    return this.http.post(this.baseUrl + "instalments/createinstalment", model).pipe(
      map((response: any) => {
        const instalment = response;
        console.log(response);
        if (instalment) {
          localStorage.setItem('Instalment', JSON.stringify(instalment));
        }                  
      }));
  } 

  
  sendInitialInstalmentMessages(invoiceID: string){
    return this.http.get(this.baseUrl + "instalments/sendinitialinstalmentmessages?invoiceID=" + invoiceID).pipe(
      map((response: number) => {
        return response;                
      }));
  } 
  
  getinstallments(page?: number, itemPerPage?: number, invoiceID?: string){
    let params = new HttpParams();
    console.log('Invoice ID: ' + invoiceID);

    if (page !== null && itemPerPage !== null) {
      console.log(page);   
      params = params.append('pageNumber', page.toString());
      params = params.append('pageSize', itemPerPage.toString());
    }

    return this.http.get<Instalment[]>(this.baseUrl + 'instalments/Getlistinstalments?invoiceID=' + invoiceID, {observe: 'response', params}).pipe(
      map(response => {
        this.paginatedResult.result = response.body;
        if (response.headers.get('Pagination') !== null) {
          this.paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));           
        }
        return this.paginatedResult;
      })
    )
  }

  async createArchiveHubConnection(user: User, accountID: number) 
  {      
    if (ConnectionSignalRHub.CheckAccessHub(this.presenceHub.pageActive[2], false) === false)
      return;

    this.hubArchiveConnection = ConnectionSignalRHub.GetConnectionHub(this.hubConnection, this.huburl, accountID, user, "instalment");

    this.hubArchiveConnection.on('ReceiveArchiveInvoiceAccounts', (instalments: Instalment[]) => {
      if (instalments !== null)
      { 
        this.CheckInstalmentSource(instalments);
      }         
    })    
  }

  private CheckInstalmentSource(instalments: Instalment[])
  {
    this.setInstalmentSource(instalments);
  }

  private setInstalmentSource(instalments: Instalment[]){
    this.instalmentSource.next(instalments);
    this.instalmentDataSource.data = instalments;
    console.log(instalments);
  }  

  getClientinformation(accountID: number){
    return this.http.get(this.baseUrl + "instalments/getclientinformation?accountID=" + accountID);
  }

  getEditInstalment(accountID: number){
    return this.http.get<Instalment>(this.baseUrl + 'instalments/geteditinstalment?accountID=' + accountID).pipe(
      map((instalment : Instalment) => {
        return instalment;
      })
    )
  }

  sendInitializationOnInitalMesssages(accountID: number){
    return this.http.get<Instalment>(this.baseUrl + 'instalments/geteditinstalment?accountID=' + accountID).pipe(
      map((instalment : Instalment) => {
        return instalment;
      })
    )
  }

  updateInstalment(instalment: Instalment){
    return this.http.put(this.baseUrl + 'instalments/updateeditinstalment', instalment).pipe(
      map(() => {
        
      })
    );
  }

  deleteInstalment(accountID: number){
    return this.http.delete(this.baseUrl + 'instalments/deleteinstalment?accountID=' + accountID).pipe(
      map((response: boolean) => {
        return response;
      })
    );
  }

  resetInstalmentMessage(accountID: number){
    const account = {
      id: accountID
    };

    return this.http.post(this.baseUrl + 'instalments/resetinitialinstalmentmessage', account).pipe(
      map((response: boolean) => {
        return response;
      })
    );
  }

  archiveInstalment(account: any){
    return this.http.put(this.baseUrl + 'instalments/archiveinstalment', account).pipe(
      map((response: boolean) => {
        return response;
      })
    );
  }
  gettotalcostforInstalments(account: number){
    return this.http.get(this.baseUrl + 'instalments/checktotalamountinstalmentsCost?accountID=' + account).pipe(
      map((response: number) => {
        return response;
      })
    );
  }

  getlastestdateinstalments(account: number){
    return this.http.get(this.baseUrl + 'instalments/getlastestdateinstalment?accountID=' + account).pipe(
      map((response: Date) => {
        return response;
      })
    );
  }

  getupdatedlastestdateinstalments(invoiceID: number, accountID: number){
    return this.http.get(this.baseUrl + 'instalments/getupdatelastestdateinstalment?invoiceID=' + invoiceID + '&' + 'accountID=' + accountID).pipe(
      map((response: Date) => {
        return response;
      })
    );
  }

  getCheckCanEditClient(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'instalments/Iseditactive?accountID=' + accountID).pipe(
      map((instalment => {
        return instalment;
      }))
    )
  }

  getCheckCanDeleteClient(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'instalments/Iseditactive?accountID=' + accountID + '&isItDeleteFunction=true').pipe(
      map((account: boolean) => {
        return account;
      })
    )
  }

  getCheckCanArchiveClient(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'instalments/Iseditactive?accountID=' + accountID + '&isItArchiveFunction=true').pipe(
      map((account: boolean) => {
        return account;
      })
    )
  }

  cancelCanEditClient(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'instalments/closeeditactive?accountID=' + accountID).pipe(
      map((canEdit: boolean) => {
        return canEdit;
      })
    )
  }



}
