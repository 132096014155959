<app-nav></app-nav>
<div class="container mt-4">
  <nav class="navbar navbar-light bg-primary shadow-lg mt-4">
    <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-user fa-2x" aria-hidden="true"></i> Account Details</a>
  </nav>
  <ol class="breadcrumb">
    <li class="breadcrumb-item active">Edit Account Details</li>
  </ol>
    <div class="bs-docs-section">
  
      <div class="row" *ngIf="member$ | async as member">
        <div class="col-12 shadow-lg border">
          <h2 id="nav-breadcrumbs" class="text-center text-primary">Personal Status Details</h2>
          <table class="table table-hover  table-bordered border-primary">
            <thead>
              <tr class="">
                <th scope="col">Secret Code Verification</th>
                <th scope="col">Only needed when you call us!</th>
                <th scope="col">Two Factor Authenication</th>
                <th scope="col">Setup Authentication</th>
                <th scope="col">Current Service</th>
                <th scope="col">Actions for Type of Service</th>            
              </tr>
            </thead>
            <tbody>
              <tr class="">
                <th scope="row">{{member.clientSecretCode}}</th>
                <td>
                  <button class="" mat-raised-button color="primary" (click)="getSecretCode()">Send me Verification Code</button>
                </td>
                <td>
                  <ng-container *ngIf="user.twoFactorAuthentication;else shownofactor"><b class="text-success">YES</b></ng-container>
                  <ng-template #shownofactor>
                    <b class="text-danger">NO</b>
                  </ng-template>
                </td>
                <td>
                  <button class="" mat-raised-button color="primary" routerLink="/members/twofactorauthentication">Go To Two Factor Authenticator</button>
                </td>
                <td>
                  <h4>
                    <b class="text-black">
                      <ng-container *ngIf="member.typeOfService;else showinvoice">
                      Billing Accounts
                      </ng-container>
                      <ng-template #showinvoice>
                      Invoices Services
                      </ng-template>
                    </b>                  
                  </h4>
                </td>
                <td><button class="" mat-raised-button color="warn" (click)="changeServices()">Change Type of Services</button></td>
              </tr>              
            </tbody>
          </table>
   
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4 shadow-lg border">
          <div class="bs-component">
            <table class="table border-primary mt-4 table-sm">
              <thead class="table-light">
                <td class="text-primary h4 font-weight-bold border shadow-sm">Options For Updating Your Account</td>
              </thead>
              <tbody>
                <tr> 
                  <button mat-raised-button color="primary" routerLink="/members/editaccount" class="mt-2">Edit Account</button>
                  <button class="ms-4 mt-2" mat-raised-button color="primary" routerLink="/members/editaccountchangepassword">Change Account Password</button>
                </tr>
              </tbody>
            </table>
            <h2  class="nav-breadcrumbs text-primary mt-2"></h2>
            <hr>
           
          
        
            <h2 id="nav-breadcrumbs" class="text-primary">Personal Details</h2>
            <hr>
              <mat-list>
                  <div mat-subheader>First Name</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="accent">account_box</mat-icon>
                      <div mat-line> {{ member.firstName }} </div>
                    </mat-list-item>
                  <mat-divider></mat-divider>
                  <div mat-subheader>Last Name</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="accent">account_box</mat-icon>
                      <div mat-line> {{ member.lastName }} </div>
                    </mat-list-item>
                  <mat-divider></mat-divider>
                  <div mat-subheader>Mobile Contact Number</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="accent">account_box</mat-icon>
                      <div mat-line> {{ member.phone }} </div>
                    </mat-list-item>
                  <mat-divider></mat-divider>
                  <div mat-subheader>Business Name</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="accent">account_box</mat-icon>
                      <div mat-line> {{ member.bussinessName }} </div>
                    </mat-list-item>
                  <mat-divider></mat-divider>
                  <div mat-subheader>Business Address Name</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="accent">account_box</mat-icon>
                      <div mat-line> {{ member.address }} </div>
                    </mat-list-item>
                  <mat-divider></mat-divider>
                  <mat-divider></mat-divider>
                  <div mat-subheader>City</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="accent">account_box</mat-icon>
                      <div mat-line> {{ member.city }} </div>
                    </mat-list-item>
                  <mat-divider></mat-divider>               
                  <div mat-subheader>State</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="accent">account_box</mat-icon>
                      <div mat-line> {{ member.state }} </div>
                    </mat-list-item>
                  <mat-divider></mat-divider>
                  <div mat-subheader>Business Postcode</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="accent">account_box</mat-icon>
                      <div mat-line> {{ member.postcode }} </div>
                    </mat-list-item>
                  <div mat-subheader>Contact Help For Phone Support</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="accent">account_box</mat-icon>
                      <div mat-line> {{ member.contactUsSupportPhone }} </div>
                    </mat-list-item>
                  <mat-divider></mat-divider>
                  <div mat-subheader>Contact Help For Email Support</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="accent">account_box</mat-icon>
                      <div mat-line> {{ member.contactUsSupportEmail }} </div>
                    </mat-list-item>
                  <mat-divider></mat-divider>
                  <div mat-subheader>ABN (Australia Business Number)</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="accent">account_box</mat-icon>
                      <div mat-line> {{ member.abn }} </div>
                    </mat-list-item>
                  <mat-divider></mat-divider>
                  <div mat-subheader>Account Name for your Business Transaction Account</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="accent">account_box</mat-icon>
                      <div mat-line> {{ member.accountName }} </div>
                    </mat-list-item>
                  <mat-divider></mat-divider>
                  <div mat-subheader>Account Number for your Business Transaction Account</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="accent">account_box</mat-icon>
                      <div mat-line> {{ member.accountNumber }} </div>
                    </mat-list-item>
                  <mat-divider></mat-divider>
                  <div mat-subheader>BSB for your Business Transaction Account</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="accent">account_box</mat-icon>
                      <div mat-line> {{ member.bsb }} </div>
                    </mat-list-item>
                  <mat-divider></mat-divider>
                  <div mat-subheader>PayID for your Business Transaction Account</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="accent">account_box</mat-icon>
                      <div mat-line> {{ member.payID }} </div>
                    </mat-list-item>
                  <mat-divider></mat-divider>
                  <div mat-subheader>Website Address for your company / business</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="accent">account_box</mat-icon>
                      <div mat-line> {{ member.website }} </div>
                    </mat-list-item>
                  <mat-divider></mat-divider>
                  <div mat-subheader>Stripe Account Linked</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="accent">insert_link</mat-icon>
                      <div mat-line>
                        <ng-container *ngIf="member.stripeAccountLinked; else showdown">
                          <i class="fas fa-check"></i> 
                        </ng-container>
                        <ng-template #showdown>
                          <i class="fas fa-times"></i> 
                        </ng-template>
                      </div>
                    </mat-list-item>
                  <mat-divider></mat-divider>
              </mat-list>
          </div>
      </div>
      <div class="col-sm-12 col-md-6 py-5 bg-white text-black text-center mt-4 shadow-lg border">
        <div class=" ">
            <div class="card-body">
                <img src="/assets/images/edit.jpg" class="img-thumbnail">
                <h2 class="py-3">Your Personal Account</h2>
            </div>
        </div>
     </div>
    </div>
    </div>
  </div>