import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { XeroapiService } from 'src/app/_services/Xero/xeroapi.service';
import { XeroCustomerKeys } from 'src/app/_models/xerocustomerkeys';

import { Router } from '@angular/router';
import { Client } from 'src/app/_models/client';
import { ImportfileClient } from 'src/app/_models/importfileclient';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmimportsinvoicesComponent } from '../../importinvoices/confirmimportsinvoices/confirmimportsinvoices.component';
import { ImporteditclientComponent } from '../importeditclient/importeditclient.component';
import { ImporteddeleteclientComponent } from '../importeddeleteclient/importeddeleteclient.component';



@Component({
  selector: 'app-importapiclient',
  templateUrl: './importapiclient.component.html',
  styleUrls: ['./importapiclient.component.css'],

})
export class ImportapiclientComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  httpRequest: XeroCustomerKeys;
  clients: Client[];
  validationErrors: boolean = false;
  enableSubmit: boolean = false;
  errorID: boolean = false;
  errorMessage: string = "";
  conflictClientsEnable: boolean = false;
  @Input() usersFromImportFile: ImportfileClient[];
  
  constructor(private service: XeroapiService, 
    private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getclients();
    this.getAPIFile();
  }

  getclients() {
    this.service.getListClients().subscribe(res => {
      this.clients = res;
      this.enableSubmit = true;
      console.log(this.clients);
    }, error => {
      console.log(error);
    })
  }

  getAPIFile() {
    this.service.getListAPIContactFile().subscribe(res => {
      this.usersFromImportFile = res;
      this.checkConflictClient();
    }, error => {
      console.log(error);
    });
  }

  checkConflictClient(){
    var indexClient: number = 0;
    var indexImport: number = 0;

    if (this.clients == null)
      return;

    this.clients.forEach(client => {      
      if (this.usersFromImportFile != null)
      {
        this.usersFromImportFile.forEach(file => {
       
          if (client.firstName.toLowerCase() == file.firstName.toLowerCase() && client.lastName.toLowerCase() == file.lastName.toLowerCase()
           && client.middleName.toLowerCase() == file.middleName.toLowerCase())
          {
            this.usersFromImportFile[indexImport].conflictImport = true;
            this.clients[indexClient].conflictImport = true;
          }
          indexImport++;
        });
      }
      indexImport = 0;
      indexClient++;
    });
  }

  editImport(id: any){
    const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = "50%";
      dialogConfig.height = "100%";
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.data = {
        id: id,
        firstName: this.usersFromImportFile[id].firstName,
        lastName: this.usersFromImportFile[id].lastName,
        address: this.usersFromImportFile[id].address,
        email: this.usersFromImportFile[id].email,
        conflictImport: this.usersFromImportFile[id].conflictImport,
        bussinessName : this.usersFromImportFile[id].bussinessName,
        contactBusinessNumber : this.usersFromImportFile[id].contactBusinessNumber,
        contactHomePhone : this.usersFromImportFile[id].contactHomePhone,
        contactMobile : this.usersFromImportFile[id].contactMobile,
        abn : this.usersFromImportFile[id].abn,
      };
      
       const dialogRef = this.dialog.open(ImporteditclientComponent, dialogConfig);
  
       dialogRef.afterClosed().subscribe(result => {        
        this.usersFromImportFile[result.id] = result;
        this.checkClientsAbove();
        this.sortDefaultGreen();
        this.checkConflictClient();
        //this.checkConflictImports();
      }); 
  }

  // Was the check conflicts with clients. Now we import all clients into the system. This is reductant. 
  checkConflictImports(){
    let index: number = 0;
    let counter: number = 0;
    for (let i = 0; i < this.usersFromImportFile.length; i++)
    {
      console.log(this.usersFromImportFile[i].firstName);
      this.usersFromImportFile.forEach(element => {
        if (this.usersFromImportFile[i].firstName.toLowerCase() == element.firstName.toLowerCase() && this.usersFromImportFile[i].lastName.toLowerCase()
         == element.lastName.toLowerCase() && this.usersFromImportFile[i].middleName.toLowerCase() == element.middleName.toLowerCase())
        {
          counter++;    
        }

        if (counter == 2)
        {
          this.usersFromImportFile[i].conflictImport = true;
        }
        index++;
      });
      counter = 0;
    }
  }

  deleteImport(id){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "25%";
    dialogConfig.data = {
      id: id,
      deleteInstalment: false
    };
    
    const dialogRef = this.dialog.open(ImporteddeleteclientComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
     if (result.deleteImportAccount != null)
     {      
       this.usersFromImportFile.splice(result.id, 1);
       this.checkClientsAbove();
       this.sortDefaultGreen();
       this.checkConflictClient();
       // this.checkConflictImports();       
      }
     });   
  }

  sortDefaultGreen(){
    for (let i = 0; i < this.usersFromImportFile.length; i++ )
    {
      this.usersFromImportFile[i].conflictImport = false;
    }

    for (let i = 0; i < this.clients.length; i++ )
    {
      this.clients[i].conflictImport = false;
    }
  }

  checkClientsAbove(){
    if (this.usersFromImportFile.length > 0)
    {
      this.enableSubmit = true;
    }
    else
    {
      this.enableSubmit = false;
    }
  }


  onSubmit(){
    this.service.createImportsClients(this.usersFromImportFile).subscribe(response => {
      this.messageConfirm();
    }, error => {
      const meg: string = error;
      console.log(meg);
      if (meg.includes("Required Mobile Contact Number!"))
      {
        this.setError(true,meg);
      }
      else if(meg.includes("Required First Name!"))
      {
        this.setError(true,meg);
      }
      else if(meg.includes("Required Last Name!"))
      {
        this.setError(true,meg);
      }
      else if(meg.includes("Multiple records exist in import!"))
      {
        this.setError(true,meg);
      }
      else
      {
          this.validationErrors = true;     
          this.errorID = false; 
      }
    })
  }

  messageConfirm(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "25%";
    dialogConfig.data = {
    };
    
    const dialogRef = this.dialog.open(ConfirmimportsinvoicesComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm != null)
      {      
       this.router.navigate(['/members/clients']);
      }
    }); 
  }

  setError(error: boolean, msg: string){
    this.errorID = true;
    this.errorMessage = msg;
  }
}
