<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <div class="container-fluid">
        <div class="row">
            <mat-card-title class="RalewayMainLogo text-white" routerLink="#">Invoice Pager</mat-card-title>            
        </div>

        <a class="navbar-brand" routerLink="/"><img src="" alt=""></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/home" routerLinkActive="active">
                        <ng-container *ngIf="(accountService.currentUser$ | async) === null else showhome">Learn about our Features & Services</ng-container>
                        <ng-template #showhome>Portal</ng-template>
                    </a>
                </li>
                <ng-container *ngIf="(accountService.currentUser$ | async) === null"> 
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" routerLinkActive="active" href="#" role="button" aria-haspopup="true" aria-expanded="false">Why Choose Us?</a>
                        <div class="dropdown-menu mt-2">
                            <a class="dropdown-item" routerLink="/forwardpay" routerLinkActive="active">
                                What is Forward Pay?
                            </a>
                            <a class="dropdown-item" routerLink="/paylater" routerLinkActive="active">
                                What is Pay Later?
                            </a>
                            <a class="dropdown-item" routerLink="/Instalments" routerLinkActive="active">
                                Learn about our Instalment Plans
                            </a>
                            <a class="dropdown-item" routerLink="/features" routerLinkActive="active">
                                Learn more about our product and features
                            </a>
                        </div>
                    </li>                                       
                </ng-container>
                <ng-container *ngIf="accountService.currentUser$ | async"> 
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/members/clients" routerLinkActive="active">Clients</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" routerLinkActive="active" href="#" role="button" aria-haspopup="true" aria-expanded="false">Accounts</a>
                        <div class="dropdown-menu mt-2">
                            <ng-container *ngIf="accountService.currentUser$ | async as user">
                                <ng-container *ngIf="user.typeOfService; else showInvoice ">
                                    <a class="dropdown-item"  routerLink="/members/accountsreceivable" >Billing Accounts</a>
                                </ng-container>
                                <ng-template #showInvoice>
                                    <a class="dropdown-item"  routerLink="/members/accountsreceivable" >Account Invoices</a>
                                </ng-template>
                              </ng-container>
                          <a class="dropdown-item" routerLink="/members/listaccountsinvoices">Accounts Cash Flow</a>
                        </div>
                    </li>
                    <li class="nav-item">
                        <a class="btn btn-outline-light position-relative " routerLink="/members/stripeaccounts" routerLinkActive="active">Stripe Accounts 
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" *ngIf="stripeService.notificationStripeNumbers$ | async">
                                {{stripeService.notificationStripeNumbers$ | async}}
                            <span class="visually-hidden">unread messages</span>
                            </span>
                        </a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" routerLinkActive="active" href="#" role="button" aria-haspopup="true" aria-expanded="false">Imports</a>
                        <div class="dropdown-menu mt-2">
                          <a class="dropdown-item" routerLink="/members/importfileclient">Import Clients via Excel</a>
                          <a class="dropdown-item" routerLink="/members/importfileinvoice">Import Invoices via Excel </a>
                          <a class="dropdown-item" routerLink="/members/importpage">Import via Xero API</a>
                          <!--<a class="dropdown-item" routerLink="/members/importapiclients">Import Clients via Xero API</a>-->
                          <!--<a class="dropdown-item" routerLink="/members/importapiinvoice">Import Invoices via Xero API </a>-->
                        </div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/members/message" routerLinkActive="active">Messages</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" routerLinkActive="active" href="#" role="button" aria-haspopup="true" aria-expanded="false">Help</a>
                        <div class="dropdown-menu mt-2">                    
                          <a class="dropdown-item" routerLink="/members/turtorialhome">Tutorials</a>
                          <a class="dropdown-item" routerLink="/members/faqs">FAQ </a>
                        </div>
                    </li>                 
                </ng-container>       
                <li class="nav-item">
                    <a class="nav-link" routerLink="/contactus" routerLinkActive="active">Contact Us</a>
                </li>
                <ng-container *ngIf="(accountService.currentUser$ | async)"> 
                    <li class="nav-item">
                        <a class="btn btn-success position-relative " routerLink="/members/subscriptions" routerLinkActive="active">
                            <i class="fa-regular fas fa-user"></i> Upgrade Membership
                        </a>
                    </li>  
                </ng-container>
            </ul>
            <div class="dropdown" *ngIf="accountService.currentUser$ | async as user" dropdown=""> 
                <a class="dropdown-toggle text-light" dropdownToggle>Welcome <b>{{user.firstName | titlecase }} {{user.lastName | titlecase }}</b></a>
                <div class="dropdown-menu mt-3" *dropdownMenu>
                    <a class="dropdown-item" routerLink="/members/editdetailsacount">Edit Account</a>
                    <a class="dropdown-item" routerLink="/members/listuserbills">Billing Accounts</a>
                    <a class="dropdown-item" routerLink="/members/usergroups">User groups</a>
                    <a class="dropdown-divider"></a>
                    <a class="dropdown-item" (click)="logout()">logout</a>
                </div>             
            </div> 
            <ng-container *ngIf="(accountService.currentUser$ | async) === null"> 
                <form autocomplete="off" *ngIf="(accountService.currentUser$ | async) === null ">
                    <button class="btn btn-success mt-2 mt-mb-0" [routerLink]="['/home', 1]"><i class="fa-regular fas fa-user"></i> Sign Up Today</button>
                </form>               
            </ng-container>
            <form #loginForm="ngForm" class="d-flex ms-2 mt-2 mt-mb-0" (ngSubmit)="login()" autocomplete="off" *ngIf="(accountService.currentUser$ | async) === null ">
                <input class="form-control me-2" type="text" name="username" [(ngModel)]="model.username" placeholder="Username">
                <input class="form-control me-2" type="password" name="password" [(ngModel)]="model.password" placeholder="Password">
                <button class="btn btn-success" type="submit"> <small>Login</small></button>
            </form>
            <button class="btn btn-warning ms-1 mt-2 mt-mb-0" *ngIf="(accountService.currentUser$ | async) === null " routerLink="/receivelostemail"><small> <i class="fa-regular fas fa-user"></i> Lost Account</small></button>
        </div>
    </div>
</nav>