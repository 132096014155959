import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { EmailRemoveGMT } from 'src/app/_helper/emailremovegmt';
import { Instalment } from 'src/app/_models/instalment';
import { InstalmentClientInfo } from 'src/app/_models/instalmentClientInfo';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { InstalmentService } from 'src/app/_services/instalment/instalment.service';
import { InvoiceService } from 'src/app/_services/invoice/invoice.service';
import { PaymentService } from 'src/app/_services/payment/payment.service';
import { TogglewindowService } from 'src/app/_services/togglewindow.service';
import { DateValidation } from 'src/app/_validators/datevalidation';
import { InvoiceValidator } from 'src/app/_validators/invoicevalidator';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator';
import { HowmanyinstalmentsComponent } from '../howmanyinstalments/howmanyinstalments.component';
import { DateValidatorTime } from 'src/app/_validators/datevalidatortime';

@Component({
  selector: 'app-createinstalments',
  templateUrl: './createinstalments.component.html',
  styleUrls: ['./createinstalments.component.css']
})
export class CreateinstalmentsComponent implements OnInit, OnDestroy {
  @ViewChild('editForm') editForm: NgForm;  
  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any){
    if (this.editForm.dirty){
      $event.returnValue = true;
    }
  }
  user?: User;
  formInstalment: UntypedFormGroup;
  instalment: Instalment;
  optionAutomation: number ;
  lastestDateInstalment: Date;
  invoiceID: any;
  clientInfo: any;
  invoiceAmount: number = 0;
  paidAmount: number = 0;
  defaultInvoiceAmount: number = 0;
  displayInstalmentAutomation: string = "";
  private interval;
  private timeLeft: number = 1;
  minDate: Date;
 

  constructor(public fb: UntypedFormBuilder, public instalmentService: InstalmentService, private route: ActivatedRoute,
    private toastr: ToastrService, public toggle: TogglewindowService, public accountService: AccountService, public paymentService: PaymentService,
    private invoiceService: InvoiceService, private dialog: MatDialog
    ) { }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.ClosedinstalmentEditInvoice();
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.showDefault();
    this.assignUser();
    this.minDate = new Date(); 
    this.getParrams();
    this.initializeForm(this.invoiceAmount);
    this.getClientInfo();
    this.getTotalInstalmentCost();
    //this.onLoadLastestDate();
    this.startTimer();
  }

  showDefault(){
    this.instalmentService.showCreateFormSource.next(true);
    this.invoiceAmount = 0;
    this.defaultInvoiceAmount = 0;
    this.displayInstalmentAutomation = "Singular instalment";
    this.optionAutomation = 0;
    this.paidAmount = 0;
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  getTotalInstalmentCost(){
    this.instalmentService.gettotalcostforInstalments(this.invoiceID).subscribe(response => {
      const addCost = +response;
      const totalPaidLeft = this.defaultInvoiceAmount - this.paidAmount;
      if (addCost >= totalPaidLeft)
      {
        this.instalmentService.showCreateFormSource.next(true);
        return;
      }
      this.invoiceAmount = this.defaultInvoiceAmount - addCost - this.paidAmount;
      this.instalmentService.showCreateFormSource.next(false);
      this.formInstalment.controls['costamount'].setValidators([Validators.required,  RxwebValidators.numeric({allowDecimal:true,isFormat:true}), 
        Validators.min(0.01), Validators.max(999999999999999), InvoiceValidator.matchInvoiceFromInvoiceToAmountPaid(this.invoiceAmount)]);
    }, err => {
      console.log(err);
    })
  }

  initializeForm(amount: number){
    this.formInstalment = this.fb.group({
      costamount:['', [Validators.required,  
        Validators.min(0.01), Validators.max(999999999999999), InvoiceValidator.matchInvoiceFromInvoiceToAmountPaid(amount)]],
      note: ['', [Validators.maxLength(1000) , WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      addfees: ['', [Validators.required]],
      duedate: ['', [Validators.required, DateValidatorTime.DateInvalidTime]],
      sendinitialafterfirstpayment: ['', Validators.required],
    })
  }

  createInstalment(){
    this.initialModelInstalment();
    this.instalmentService.createInstalment(this.instalment).subscribe(response => {
      this.toastr.success('Succesfully created Instalment account');
      this.resetForm();
      this.instalmentService.stopHubConnection();
      this.instalmentService.createHubConnection(this.user, +this.instalmentService.$invoiceID);
      this.getTotalInstalmentCost();
      //this.onLoadLastestDate();
    }, error => {
      console.log(error);
    });
  }

  initialModelInstalment(){
    const id: number = this.invoiceID;
    const dueDate = new Date(this.formInstalment.value.duedate);
    const initialDate = EmailRemoveGMT.RemovedEmailGMT(this.formInstalment.value.initaldate);
    const addfees = this.formInstalment.value.addfees;
    const payinitial = this.formInstalment.value.sendinitialafterfirstpayment;;
    const addfees1 = this.getOptionValue(addfees);
    const payinitial1 = this.getOptionValue(payinitial);

    this.instalment = {
      costAmount: this.formInstalment.value.costamount,
      note: this.formInstalment.value.note,
      dueDate: dueDate,
      sendInitialDateAfterFirstPayment: payinitial1,
      addFees: addfees1,
      invoiceID: id,
      typeOfInstalmentsPayments: this.optionAutomation,
      dueDateS: dueDate.toUTCString()
    }
  }

  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.invoiceID = params.get("id");
      this.invoiceAmount = +params.get("invoiceamount");
      this.defaultInvoiceAmount = this.invoiceAmount;
      this.paidAmount = +params.get("amountpaid");
    });
  }

  resetForm(){
    this.initializeForm(this.invoiceAmount);
  }

  getClientInfo(){
    this.instalmentService.getClientinformation(this.invoiceID).subscribe(response => {
        console.log(response);
        this.clientInfo = response;
        this.paymentService.defaultInvoicePayment$ = this.clientInfo.paidAmount;
      }, error => {
        console.log(error);
      });
  }

  loadAccountPayments(){
    this.paymentService.getPayments(this.invoiceID).subscribe(
      response => {
        this.paymentService.accounts = response;
        console.log(response);
      }, error => {
        console.log(error);
      }
    )
  }

  openInstalmentsTable(){
    const isValidatedDate = this.formInstalment.get('duedate').valid;
    if (!isValidatedDate)
    {    
      this.toastr.error("You must enter correct the date before you can proceed here!");    
      return;
    }

    const isValidateCostAmount = this.formInstalment.get('costamount').valid;
    if (!isValidateCostAmount)
    {
      this.toastr.error("You must enter the right cost amount before you can proceed here!");    
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100%";
    dialogConfig.height = "auto";
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      id: this.invoiceID,
      costamount: this.formInstalment.get('costamount').value,
      duedate: this.formInstalment.get('duedate').value,
      invoiceamount: this.invoiceAmount,
      instalmentposition: this.optionAutomation
    };
    
    const dialogRef = this.dialog.open(HowmanyinstalmentsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {          
      if (result !== null)
      {
        if (result.instalmentposition !== null)
        {
          const position = result.instalmentposition;
          switch (position)
          {
            case 0:
              this.SetTypeOfInstalmentAutomation("Singular instalment", 0);
              break;
            case 1:
              this.SetTypeOfInstalmentAutomation("Daily", 1);
              break;
            case 2:
              this.SetTypeOfInstalmentAutomation("Weekly", 2);
              break;
            case 3:
              this.SetTypeOfInstalmentAutomation("Fortnightly", 3);
              break;
            case 4:
              this.SetTypeOfInstalmentAutomation("Monthly", 4);
              break;
            case 5:
              this.SetTypeOfInstalmentAutomation("Monthly (Fixed Date)", 5);
              break;
          }
        }
      }
    });   
  }

  private SetTypeOfInstalmentAutomation(display: string, typeInstalment: number): void{
    this.displayInstalmentAutomation = display;
    this.optionAutomation = typeInstalment;
  }

  private getOptionValue(value: string)
  {
    var result: boolean = false;
    if (value == "Yes"){
      result = true;
    } 
    return result;
  }

  private onLoadLastestDate(){
    this.instalmentService.getlastestdateinstalments(this.invoiceID).subscribe(response => {  
      if (response != null)
      {
        this.instalmentService.lastestInstalmentDate = response;   
        this.formInstalment.controls['duedate'].setValidators([Validators.required, DateValidation.CompareDate(this.instalmentService.lastestInstalmentDate)]);
      }
    }, error => {
      console.log(error);
    });    
  }

  private ClosedinstalmentEditInvoice(): void{
    this.invoiceService.cancelCanEditInvoice(this.invoiceID).subscribe(response => {

    }, err => {
      console.log(err);
    })
  }

  private startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 3;
        this.instalmentService.defaultValueCost$.subscribe(response => {
          const costmoney: number = response;
          if (costmoney != this.invoiceAmount)
          {
            console.log('cost money: ' + costmoney);
            this.invoiceAmount = costmoney;       
            this.formInstalment.controls['costamount'].setValidators([Validators.required,  RxwebValidators.numeric({allowDecimal:true,isFormat:true}), 
              Validators.min(0.01), Validators.max(999999999999999), InvoiceValidator.matchInvoiceFromInvoiceToAmountPaid(this.invoiceAmount)]);       
          }
        });
      }
    },1000)
  }
}
