import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UsergroupspermissionsService } from 'src/app/_services/usergroups/usergroupspermissions.service';

@Component({
  selector: 'app-getemailconfirmusergroups',
  templateUrl: './getemailconfirmusergroups.component.html',
  styleUrls: ['./getemailconfirmusergroups.component.css']
})
export class GetemailconfirmusergroupsComponent implements OnInit {
  message: string = "Confirmation, You made it user group!";
  leftMessage: string = "User group";
  showConfirm: boolean = true;
  showEmailErrAlreadyjoinedGroup: boolean;
  email: string;
  id: string;

  constructor(private service: UsergroupspermissionsService, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.showEmailErrAlreadyjoinedGroup = false;
    this.getParrams();
    this.service.loadConfirmEmail(this.email, this.id).subscribe(response => {
      if (response === true)
      {
        this.toastr.success("Congratuations, you have successfully been added to the group!");
      }
      else
      {
        this.message = "Failed to join a user group!";
        this.toastr.error("Invalid token or maybe email token has expired!");
        this.showConfirm = false;
      }
    }, err => {
      console.log(err);
      this.showConfirm = false;
      this.message = "You have already joined a group";
      if (err.error === "Your email account has already been activated! You need to disband current group before you can join other groups.")
      {
        this.showConfirm = true;
        this.showEmailErrAlreadyjoinedGroup = true;
      }
    });
  }

  getParrams(){
    this.route.queryParams.subscribe(parrams => {
      console.log(parrams);
      this.email = parrams.token;
      this.id = parrams.id;
    });

    console.log(this.email);
  }


}
