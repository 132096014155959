import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { MessagesService } from 'src/app/_services/message/messages.service';

@Component({
  selector: 'app-paymentreceivesms',
  templateUrl: './paymentreceivesms.component.html',
  styleUrls: ['./paymentreceivesms.component.css']
})
export class PaymentreceivesmsComponent implements OnInit {

  public data: any;
  @ViewChild('editForm') editForm: NgForm; 
  formEdit: UntypedFormGroup;

  constructor(public fb: UntypedFormBuilder, private service: MessagesService) { }

  ngOnInit(): void {
    this.initializeForm();
    this.onLoad();
  }

  initializeForm(){
    this.formEdit = this.fb.group({
      changeText:['', [ Validators.required, Validators.maxLength(2000) ]],
    });  
  }

  onLoad(){
    this.service.getReceiveSMS().subscribe(response => {
      this.data = response;
      this.formEdit.controls['changeText'].setValue(this.data.paymentReceiveSMS);
    }, error => {
      console.log(error);
    })
  }

  onSubmit(){
    const message = {
      PaymentReceiveSMS: this.formEdit.controls['changeText'].value
    }

    console.log(message)
    this.data.message = message.PaymentReceiveSMS;
    this.service.updateReceiveSMS(message).subscribe(response => {
      this.onLoadSettingMesaging();
    }, error => {
      console.log(error);
    })
  }

  generateText(){
    this.formEdit.controls['changeText'].setValue
    (
      "Dear [name],\n" +
      "I am writing to express my sincere appreciation for the payment of [amountpaid] that you have made. Your timely and generous support is greatly valued." +
      " If you require any further information or assistance, please do not hesitate to contact us at [mybusinessemail] or [mybusinessphone]. We are always happy to help and provide any additional information that you may need. " +
      "Thank you once again for your support.\n" +
      "Yours sincerely,\n" +
      "[mybusiness]\n" +
      "[mybusinessemail]\n" +
      "[mybusinessphone]\n"
    );
    this.formEdit.markAsDirty();
 }

  onLoadSettingMesaging(){
    this.service.getTextSettingMessaging().subscribe(response => {
      this.service.message = response;
      if (this.service.message == null){
        this.service.setMessageValues();
      }

      console.log(response);
    }, error => {
      console.log(error);
    })
  }

}
