import { Component, OnInit } from '@angular/core';
import { NgControlStatusGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Client } from 'src/app/_models/client';
import { Member } from 'src/app/_models/member';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { MembersService } from '../../../_services/account/members.service';
import { DialogtypeservicesComponent } from '../../typeservices/dialogtypeservices/dialogtypeservices.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-editdetailsacount',
  templateUrl: './editdetailsacount.component.html',
  styleUrls: ['./editdetailsacount.component.css']
})
export class EditdetailsacountComponent implements OnInit {
  user: User;
  private member: Member; 
  private memberSource = new ReplaySubject<Member>(1);
  member$ = this.memberSource.asObservable();


  constructor(private service: MembersService, private accountService: AccountService, private dialog: MatDialog, private toastr: ToastrService
    , private router: Router) {
    this.accountService.currentUser$.pipe(take(1)).subscribe(user => this.user = user);  
   }

  ngOnInit(): void {
    this.loadAccount();
  }

  loadAccount(){
    this.service.getMember(this.user.username).subscribe(member => {
      this.memberSource.next(member);     
      this.member = member;
      console.log(member);
    })
  }

  changeServices(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "37%";
    dialogConfig.data = {
      service: this.memberSource.subscribe(response => {
        response.typeOfService;
      })      
    };

    const dialogRef = this.dialog.open(DialogtypeservicesComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== null)
      {
        if (result.change === true)
        {
          this.logout();
        }
      }
    });
  } 

  getSecretCode(){
    this.service.getSecretCode().subscribe(response => {
      if (response !== null)
      {
        if (response === 0)
        {
          this.toastr.error("No Account Found!");
          return;
        }
        this.member.clientSecretCode = response;
        this.toastr.success("Updated secret Code. You now have the new secret code.");
      }
    }, err => {
      console.log(err);
    })
  }

  private logout(){
    this.accountService.logout();
    this.router.navigate(['/','']);
  }

}
