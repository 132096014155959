<app-nav></app-nav>
<ng-container *ngIf="accountService.currentUser$ | async as user">
  <div class="container mt-4">
    <div class="row">
      <nav class="navbar navbar-light bg-primary shadow-lg">
        <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i>        
            <ng-container *ngIf="user.typeOfService; else showInvoiceTitle ">
              Billing Accounts
            </ng-container>
            <ng-template #showInvoiceTitle>
              Invoices 
            </ng-template>       
        </a>    
        <button type="button" class="btn btn-outline-light btn-lg mt-2 mt-md-0" (click)="toggle.toggleWindow(32)"><i class="{{toggle.$Icon[32]}}" aria-hidden="true\"></i> {{toggle.$ToggleText[32]}}</button>  
      </nav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]= "['/members/clients']">Clients</a></li>
        <li class="breadcrumb-item active">
          <ng-container *ngIf="user.typeOfService; else showInvoiceCrumbs ">
            Billing Accounts
          </ng-container>
          <ng-template #showInvoiceCrumbs>
            Invoices 
          </ng-template>   
        </li>
      </ol>
        <div class="col-12 mt-4" [hidden]="!toggle.$Toggle[32]">       
          <app-clientinformation [isitinvoice]="'yes'"></app-clientinformation>
        </div>
      <nav class="navbar navbar-light bg-primary shadow-lg mt-2">
        <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i>      
          Create  
            <ng-container *ngIf="user.typeOfService; else showInvoiceTitle ">
              Billing Accounts
            </ng-container>
            <ng-template #showInvoiceTitle>
              Invoices 
            </ng-template>       
        </a>
        <button type="button" class="btn btn-outline-light btn-lg mt-2 mt-md-0" (click)="toggle.toggleWindow(2)"><i class="{{toggle.$Icon[2]}}" aria-hidden="true\"></i> {{toggle.$ToggleText[2]}}</button>
      </nav>  
      <div [hidden]="!toggle.$Toggle[2]">   
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" >
          <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded">
            <div class="card-body"> 
                <h2 class="text-center text-primary mt-2">Type Of Account</h2>
                <hr>
                <div class="form-group">
                  <label class="form-label">Would you like to establish a new account, or do you prefer to utilize this app for sending notifications?</label>
                  <select class="form-select" (change)="onChangeFormToggle($event)">             
                    <option value="false" selected>I need to create a brand New Account.</option>      
                    <option value="true" >I've already set up the account within the accounting software, saved the file, or stored it elsewhere.</option>                 
                  </select>
                </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" [hidden]="!showCreateForm">
              <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded">
                  <div class="card-body">
                    <h2 class="text-center text-primary mt-2">
                      <ng-container *ngIf="user.typeOfService; else showInvoiceCreateAccount ">
                        Create Billing Account (Exclusively for Notification Sending)
                      </ng-container>
                      <ng-template #showInvoiceCreateAccount>
                        Create Invoice Account (Exclusively for Notification Sending)
                      </ng-template> 
                    </h2>
                    <hr class="new1">
                    <form #editForm="ngForm" id="editForm" [formGroup]="formInvoice" (ngSubmit)="formInvoice.valid" autocomplete="off">
                      <div class="bs-component">
                        <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
                          <button type="button" class="close" data-dismiss="alert">×</button>
                          <h4 class="alert-heading">Information!</h4>
                          <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
                        </div>
                      </div>
                      <div class="bs-component">
                        <div class="alert alert-dismissible alert-danger" *ngIf="errorsServer">
                          <h4 class="alert-heading">Error!</h4>
                          <p class="mb-0">Your already have existing record of that invoice number! Please use another invoice number.</p>
                        </div>             
                      </div>
                      <div class="row">                  
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                          <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice fa-lg"></i>
                            <ng-container *ngIf="user.typeOfService; else showInvoiceIdentity ">
                              Billing Accounts Identity:
                            </ng-container>
                            <ng-template #showInvoiceIdentity>
                              Invoicing Identity:
                            </ng-template>                        
                          </h4>
                          <app-text-input [formControl]='formInvoice.controls["invoicenumber"]' [label]='"*Invoice Number"' [smalltext]='"An invoice number is simply a unique record number assigned to each invoice you issue."' [placeholder]='"Invoice Number"' (input)="onChangeEventInvoiceNumber()">  </app-text-input>
                          <span class="badge bg-info h4" *ngIf="service.lastInvoiceNumber !== ''">
                            <p class="h6 text-center mt-2 font-italic"><i class="fa-solid fa-circle-info fa-lg"></i> 
                              <small>
                                <ng-container *ngIf="user.typeOfService; else showInvoiceNumberTitle ">
                                  Last Billing Account Number known is: <b>{{service.lastInvoiceNumber}}</b>
                                </ng-container>
                                <ng-template #showInvoiceNumberTitle>
                                  Last Invoice Account Number known is: <b>{{service.lastInvoiceNumber}}</b>
                                </ng-template>  
                            </small>
                            </p>                          
                          </span>
                          <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-comment fa-lg"></i> Information About This Invoice :</h4>
                          <app-area-text [formControl]='formInvoice.controls["comment"]' [label]='"Note"' [smalltext]='""' [placeholder]='"Note"'></app-area-text>                                       
                        </div>    
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">       
                          <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-calendar"></i> Dates:</h4>   
                          <app-date-input [formControl]='formInvoice.controls["initaldate"]' [label]='"*Initial  Date"' [smalltext]='"Initial date sent to the buyer."' [placeholder]='"Initial Date"'> </app-date-input>
                          <app-date-input [formControl]='formInvoice.controls["duedate"]' [label]='"*Due Date"' [change]="onChangeDueDate()" [smalltext]='"Final date for the payment"' [placeholder]='"Due Date"'> </app-date-input>
                          <p class="text-warning" *ngIf="showInvalidDate">Warning! Due Date is greater than initial date.</p>
                          <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-coins fa-lg"></i> Payments:</h4>
                          <app-text-input [formControl]='formInvoice.controls["invoiceamount"]' [label]='"*Invoice Amount"' [type]='"number"' [smalltext]='"Invoice Amount means the amount invoiced to a Buyer for the Lot with GST included."' [type]='"number"' [placeholder]='"Invoice Amount"'> </app-text-input>
                          <app-text-input [formControl]='formInvoice.controls["paymentamount"]' [label]='"*Amount Paid"' [type]='"number"' [smalltext]='"A sum of money paid"' [type]='"number"' [placeholder]='"Payment Amount"'> </app-text-input>                          
                        </div>  
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">      
                          <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice fa-lg"></i>
                              Date Sending                      
                          </h4> 
                          <fieldset class="form-group">
                            <div class="card border-secondary mb-3">
                              <div class="card-body">
                                <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice fa-lg"></i> Send Initial Messages for Payment:</h4>
                                <div class="form-check">
                                  <label class="form-check-label ">
                                    <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios1" value="Yes" checked=""  [formControl]='formInvoice.controls["optionSendInitialInvoice"]'>
                                    <b class="text-success">Yes</b>: Send initial payment to your client.
                                  </label>
                                </div>
                                <div class="form-check">
                                  <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="No" [formControl]='formInvoice.controls["optionSendInitialInvoice"]'>
                                    <b class="text-danger">No</b>: I'm not ready yet, I'll manually send it later.
                                  </label>
                                </div>
                              </div>
                            </div>                                                  
                          </fieldset>
                          <fieldset class="form-group">
                            <div class="card border-secondary mb-3">
                              <div class="card-body">
                                <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice "></i> Send Out Due Date Message:</h4>
                                <label class="form-check-label"></label>
                                <div class="form-check">
                                  <label class="form-check-label ">
                                    <input type="radio" class="form-check-input" name="sendOutDueDateMessage" value="Yes" checked=""  [formControl]='formInvoice.controls["sendOutDueDateMessage"]'>
                                    <b class="text-success">Yes</b>: The message will be sent out.
                                  </label>
                                </div>
                                <div class="form-check">
                                  <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="sendOutDueDateMessage"  value="No" [formControl]='formInvoice.controls["sendOutDueDateMessage"]'>
                                    <b class="text-danger">No</b>: The due date message won't be sent out.
                                  </label>
                                </div>
                              </div>
                            </div>                        
                          </fieldset>   
                          <fieldset class="form-group">
                            <div class="card border-secondary mb-3">
                              <div class="card-body">
                                <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice "></i> Add transaction fees:</h4>
                                <label class="form-check-label"></label>
                                <div class="form-check">
                                  <label class="form-check-label ">
                                    <input type="radio" class="form-check-input" name="addfees" id="optionsRadios3" value="Yes" checked=""  [formControl]='formInvoice.controls["addfees"]'>
                                    <b class="text-success">Yes</b>: Add in 1.75% + A$0.30 to your customer billing account. 
                                  </label>
                                </div>
                                <div class="form-check">
                                  <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="addfees" id="optionsRadios4" value="No" [formControl]='formInvoice.controls["addfees"]'>
                                    <b class="text-danger">No</b>: My business will pay out the transaction fee. 
                                  </label>
                                </div>
                              </div>
                            </div>                        
                          </fieldset>   
                        </div> 
                      </div>
                    </form>
                  </div>
                  <div class="card-footer text-muted">
                      <button [disabled]="!formInvoice.valid" class="btn btn-success btn-block" form="editForm" (click)="createInvoiceAccount()" ><i class="fas fa-file-invoice fa-lg"></i> 
                        <ng-container *ngIf="user.typeOfService; else showInvoiceButtonForCreate ">
                          Create Billing Account
                        </ng-container>
                        <ng-template #showInvoiceButtonForCreate>
                          Create Invoice Account 
                        </ng-template>                      
                      </button>
                  </div>
                </div>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" [hidden]="showCreateForm">
            <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded">
                <div class="card-body">
                  <h2 class="text-center text-primary mt-2">
                    <ng-container *ngIf="user.typeOfService; else showCreateInvoiceCreateAccount ">
                      Create Items For Billing Account
                    </ng-container>
                    <ng-template #showCreateInvoiceCreateAccount>
                      Create Items For Invoice Account 
                    </ng-template> 
                  </h2>             
                </div>
                <div class="form-group">
                  <label>Choose type of Tax</label>
                  <select class="form-control" (change)="toggleTypeOfTax($event)">
                    <option selected value="1">Inclusive Tax</option>
                    <option value="0">No Tax</option>
                  </select>
                </div>
                <div class="d-none d-md-none d-lg-block">
                  <div class="row ">          
                    <div class="col-4 bg-primary text-white fs-5 p-3 ">
                      Description
                    </div>
                    <div class="col-2 bg-primary text-white fs-5 p-3">
                      Qty
                    </div>
                    <div class="col-2 bg-primary text-white fs-5 p-3">
                      Unit Price
                    </div>
                    <div class="col-2 bg-primary text-white fs-5 p-3">
                      Amount AUD
                    </div>
                    <div class="col-2 bg-primary text-white fs-5 p-3">
                      Actions
                    </div>
                  </div>
                </div>           
              
                <ng-container *ngFor="let item of invoiceItems; let i = index">
                  <form class="" [formGroup]="formItems[i + 1]" (ngSubmit)="formItems.valid" autocomplete="off">
                      <div class="row mt-2">             
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 p-3">
                          <label class="form-label fw-bold d-block d-md-block d-lg-none">Description</label>
                          <textarea class="form-control" rows="8" placeholder="Description" name="description"  [class.is-invalid]='formItems[i + 1].controls.description.touched && formItems[i + 1].controls.description.invalid' [formControl]='formItems[i + 1].controls["description"]'></textarea>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-3">
                          <label class="form-label fw-bold d-block d-md-block d-lg-none">Quantity</label>
                          <input class="form-control" type="number" placeholder="Qty" name="qty" [class.is-invalid]='formItems[i + 1].controls.qty.touched && formItems[i + 1].controls.qty.invalid' [formControl]='formItems[i + 1].controls["qty"]' [value]='item.qty' (change)="addAmountAUD(i + 1)">
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-3">
                          <label class="form-label fw-bold d-block d-md-block d-lg-none">Unit Price</label>
                          <input class="form-control" type="number" placeholder="Unit Price" [class.is-invalid]='formItems[i + 1].controls.unitprice.touched && formItems[i + 1].controls.unitprice.invalid' name="unitprice" [formControl]='formItems[i + 1].controls["unitprice"]' (change)="addAmountAUD(i + 1)">
                        </div>                     
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-3">
                          <label class="form-label fw-bold d-block d-md-block d-lg-none">Amount Paid</label>
                          <input class="form-control" type="number" name="amountaud" [formControl]='formItems[i + 1].controls["amountaud"]' disabled>
                        </div>                   
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-3">
                          <div class="row">
                            <div class="col-12">
                              <button class="form-control btn-outline-danger" (click)="deleteItem(i + 1, i)"><i class="fas fa-trash"></i> Delete Item</button>
                            </div>
                          </div>
                        </div>                                              
                      </div>
                  </form> 
                </ng-container>      
                <form class="" [formGroup]="formItems[0]" (ngSubmit)="formItems.valid" autocomplete="off">
                  <div class="row mt-2">             
                      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 p-3">
                        <label class="form-label fw-bold d-block d-md-block d-lg-none">Description</label>
                        <textarea class="form-control" rows="8" placeholder="Description" name="description" [class.is-invalid]='formItems[0].controls.description.touched && formItems[0].controls.description.invalid'  [formControl]='formItems[0].controls["description"]'></textarea>
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-3">
                        <label class="form-label fw-bold d-block d-md-block d-lg-none">Quantity</label>
                        <input class="form-control" type="number" placeholder="Qty" name="qty"  [class.is-invalid]='formItems[0].controls.qty.touched && formItems[0].controls.qty.invalid'  [formControl]='formItems[0].controls["qty"]' (change)="addAmountAUD(0)">
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-3">
                        <label class="form-label fw-bold d-block d-md-block d-lg-none">Unit Price</label>
                        <input class="form-control" type="number" placeholder="Unit Price" name="unitprice"  [class.is-invalid]='formItems[0].controls.unitprice.touched && formItems[0].controls.unitprice.invalid'  [formControl]='formItems[0].controls["unitprice"]' (change)="addAmountAUD(0)">
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-3">
                        <label class="form-label fw-bold d-block d-md-block d-lg-none">Amount Paid</label>
                        <input class="form-control" type="number" name="amountaud" [formControl]='formItems[0].controls["amountaud"]' disabled>
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-3">
                        <div class="row">
                          <div class="col-12">
                            <button class="form-control btn-outline-success notallowed" [disabled]="!formItems[0].valid"  [hidden]="formItems[0].valid"><i class="fas fa-save"></i> Create Item</button><button class="form-control btn-outline-success" [disabled]="!formItems[0].valid" [hidden]="!formItems[0].valid" (click)="addItemForm()" ><i class="fas fa-save"></i> Create Item</button>
                          </div>
                          <div class="col-12 mt-2" *ngIf="formItems.length > 1">
                            <button class="form-control btn-outline-danger" (click)="revertLastActionForCreateNewItem()"><i class="fas fa-trash"></i> Remove</button>
                          </div>
                        </div>                       
                      </div>                                              
                  </div>
                </form> 
              <div class="row">
                <div class="col">
                 
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col">
              
                </div>
                <div class="col">
                  <table class="table caption-top"> 
                    <tbody>
                      <tr>
                        <td class="font-weight-bold">Sub Total:</td>
                        <td>{{ customCreateInvoieSubTotal | currency }} </td>
                      </tr>
                      <tr [hidden]="!customCreateInvoiceTaxInclude">
                        <td class="font-weight-bold">GST:</td>
                        <td>{{ customCreateInvoiceGST | currency }} </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr class="bordertable">
                        <td class="font-weight-bold fs-5">Total Amount: </td>
                        <td class="fs-5">{{ customCreateInvoiceTotalAmount | currency }}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
       <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" [hidden]="showCreateForm || customCreateInvoiceTotalAmount <= 0">
        <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded">
            <div class="card-body">
              <h2 class="text-center text-primary mt-2">
                <ng-container *ngIf="user.typeOfService; else showInvoiceCreateCustomAccount ">
                  Create Billing Account
                </ng-container>
                <ng-template #showInvoiceCreateCustomAccount>
                  Create Invoice Account
                </ng-template> 
              </h2>
              <hr class="new1">
              <form #editForm="ngForm" id="editForm" [formGroup]="formInvoice" (ngSubmit)="formInvoice.valid" autocomplete="off">
                <div class="alert alert-dismissible alert-danger" *ngIf="customCreateInvoiceIsValid">
                  <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                  <h4 class="alert-heading">You can't create an account until you fix the issues from above!</h4>
                  <p class="mb-0">        
                      A problem has been identified with the "Create Items for an Account" function. It is crucial to rectify these issues prior to initiating the creation of an invoice account. Please ensure that you have provided the necessary information in the "Description," "Qty," and "Unit Price" fields for all entries, 
                      including the row designated as "Add New Item".
                  </p>
                </div>
                <div class="bs-component">
                  <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
                    <button type="button" class="close" data-dismiss="alert">×</button>
                    <h4 class="alert-heading">Information!</h4>
                    <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
                  </div>
                </div>
                <div class="bs-component">
                  <div class="alert alert-dismissible alert-danger" *ngIf="errorsServer">
                    <h4 class="alert-heading">Error!</h4>
                    <p class="mb-0">Your already have existing record of that invoice number! Please use another invoice number.</p>
                  </div>             
                </div>
                <div class="row">                  
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice fa-lg"></i>
                      <ng-container *ngIf="user.typeOfService; else showInvoiceIdentity ">
                        Billing Accounts Identity:
                      </ng-container>
                      <ng-template #showInvoiceIdentity>
                        Invoicing Identity:
                      </ng-template>                        
                    </h4>
                    <app-text-input [formControl]='formInvoice.controls["invoicenumber"]' [label]='"*Invoice Number"' [smalltext]='"An invoice number is simply a unique record number assigned to each invoice you issue."' [placeholder]='"Invoice Number"' (input)="onChangeEventInvoiceNumber()">  </app-text-input>
                    <span class="badge bg-info h4" *ngIf="service.lastInvoiceNumber !== ''">
                      <p class="h6 text-center mt-2 font-italic"><i class="fa-solid fa-circle-info fa-lg"></i> 
                        <small>
                          <ng-container *ngIf="user.typeOfService; else showInvoiceCustomNumberTitle ">
                            Last Billing Account Number known is: <b>{{service.lastInvoiceNumber}}</b>
                          </ng-container>
                          <ng-template #showInvoiceCustomNumberTitle>
                            Last Invoice Account Number known is: <b>{{service.lastInvoiceNumber}}</b>
                          </ng-template>  
                      </small>
                      </p>                          
                    </span>
                    <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-comment fa-lg"></i> Information About This Invoice :</h4>
                    <app-area-text [formControl]='formInvoice.controls["comment"]' [label]='"Note"' [smalltext]='""' [placeholder]='"Note"'></app-area-text>                                       
                  </div>    
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">       
                    <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-calendar"></i> Dates:</h4>   
                    <app-date-input [formControl]='formInvoice.controls["initaldate"]' [label]='"*Initial  Date"' [smalltext]='"Initial date sent to the buyer."' [placeholder]='"Initial Date"'> </app-date-input>
                    <app-date-input [formControl]='formInvoice.controls["duedate"]' [label]='"*Due Date"' [change]="onChangeDueDate()" [smalltext]='"Final date for the payment"' [placeholder]='"Due Date"'> </app-date-input>
                    <p class="text-warning" *ngIf="showInvalidDate">Warning! Due Date is greater than initial date.</p>
                    <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-coins fa-lg"></i> Payments:</h4>
                    <div class="form-group">
                      <label class="col-form-label" >Invoice Amount</label>
                      <input type="text" class="form-control" placeholder="Invoice Amount" [formControl]='formInvoice.controls["invoiceamount"]' disabled>
                    </div>
                    <app-text-input [formControl]='formInvoice.controls["paymentamount"]' [label]='"*Amount Paid"' [type]='"number"' [smalltext]='"A sum of money paid"' [type]='"number"' [placeholder]='"Payment Amount"'> </app-text-input>                          
                  </div>  
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">      
                    <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice fa-lg"></i>
                        Date Sending                      
                    </h4> 
                    <fieldset class="form-group">
                      <div class="card border-secondary mb-3">
                        <div class="card-body">
                          <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice fa-lg"></i> Send Initial Messages for Payment:</h4>
                          <div class="form-check">
                            <label class="form-check-label ">
                              <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios1" value="Yes" checked=""  [formControl]='formInvoice.controls["optionSendInitialInvoice"]'>
                              <b class="text-success">Yes</b>: Send initial payment to your client.
                            </label>
                          </div>
                          <div class="form-check">
                            <label class="form-check-label">
                              <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="No" [formControl]='formInvoice.controls["optionSendInitialInvoice"]'>
                              <b class="text-danger">No</b>: I'm not ready yet, I'll manually send it later.
                            </label>
                          </div>
                        </div>
                      </div>                                                  
                    </fieldset>
                    <fieldset class="form-group">
                      <div class="card border-secondary mb-3">
                        <div class="card-body">
                          <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice "></i> Send Out Due Date Message:</h4>
                          <label class="form-check-label"></label>
                          <div class="form-check">
                            <label class="form-check-label ">
                              <input type="radio" class="form-check-input" name="sendOutDueDateMessage" value="Yes" checked=""  [formControl]='formInvoice.controls["sendOutDueDateMessage"]'>
                              <b class="text-success">Yes</b>: The message will be sent out.
                            </label>
                          </div>
                          <div class="form-check">
                            <label class="form-check-label">
                              <input type="radio" class="form-check-input" name="sendOutDueDateMessage"  value="No" [formControl]='formInvoice.controls["sendOutDueDateMessage"]'>
                              <b class="text-danger">No</b>: The due date message won't be sent out.
                            </label>
                          </div>
                        </div>
                      </div>                        
                    </fieldset>   
                    <fieldset class="form-group">
                      <div class="card border-secondary mb-3">
                        <div class="card-body">
                          <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice "></i> Add transaction fees:</h4>
                          <label class="form-check-label"></label>
                          <div class="form-check">
                            <label class="form-check-label ">
                              <input type="radio" class="form-check-input" name="addfees" id="optionsRadios3" value="Yes" checked=""  [formControl]='formInvoice.controls["addfees"]'>
                              <b class="text-success">Yes</b>: Add in 1.75% + A$0.30 to your customer billing account. 
                            </label>
                          </div>
                          <div class="form-check">
                            <label class="form-check-label">
                              <input type="radio" class="form-check-input" name="addfees" id="optionsRadios4" value="No" [formControl]='formInvoice.controls["addfees"]'>
                              <b class="text-danger">No</b>: My business will pay out the transaction fee. 
                            </label>
                          </div>
                        </div>
                      </div>                        
                    </fieldset>   
                  </div> 
                </div>
              </form>
            </div>
            <div class="card-footer text-muted">
                <button [disabled]="!formInvoice.valid || customCreateInvoiceIsValid" class="btn btn-success btn-block" form="editForm" (click)="createCustomInvouceAccount()" ><i class="fas fa-file-invoice fa-lg"></i> 
                  <ng-container *ngIf="user.typeOfService; else showInvoiceButtonForCreate ">
                    Create Billing Account
                  </ng-container>
                  <ng-template #showInvoiceButtonForCreate>
                    Create Invoice Account 
                  </ng-template>                      
                </button>
            </div>
          </div>
    </div> 
  </div>
          <div class="col-12">
            <app-invoicelist></app-invoicelist>
          </div>
      </div>
  </div>
</ng-container>