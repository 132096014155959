<mat-dialog-content>
<h3 class="bg-danger text-white text-center">Any accounts associated with this data will also get deleted too!</h3>
<h4 class="text-center">Are you sure you want to <b>DELETE</b> this client account?</h4>
<h4></h4>
<div class="row">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
        <button type="button" class="btn btn-danger btn-block" mat-button [mat-dialog-close]="data" (click)="deleteAccount()"><i class='fas fa-trash'></i> Permanently Delete Client Account</button>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12 mt-4 mt-md-0">
        <button type="button" class="btn btn-primary btn-block" mat-button mat-dialog-close><i class='fas fa-close'></i> Cancel</button>
    </div>
</div>
</mat-dialog-content>