import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectionSignalRHub } from 'src/app/_helper/connectionsignalrhub';
import { PaginatedResult } from 'src/app/_models/pagination';
import { PayablesReceivables } from 'src/app/_models/payablesreceivables';
import { User } from 'src/app/_models/user';
import { environment } from 'src/environments/environment';
import { HostpresenceService } from '../hubsignalr/hostpresence.service';
import { ToggleinvoicebuttonService } from '../invoice/toggleinvoicebutton.service';

@Injectable({
  providedIn: 'root'
})
export class PayablesreceivablesService {
  baseUrl =  environment.apiUrl;
  paginatedResult: PaginatedResult<PayablesReceivables[]> = new PaginatedResult<PayablesReceivables[]>();
  private invoicesSource = new BehaviorSubject<PayablesReceivables[]>([]);
  allInvoices$ = this.invoicesSource.asObservable();
  private hubConnection?: HubConnection;
  huburl = environment.hubUrl;
  public accountsDataSource$: MatTableDataSource<PayablesReceivables> = new MatTableDataSource<PayablesReceivables>();
  
  constructor(private http: HttpClient, private presenceHub: HostpresenceService, private toggleInvoice: ToggleinvoicebuttonService) { }

  async createHubConnection(user: User, status: boolean) 
  {  
    const accountID: number = 0;

    if (ConnectionSignalRHub.CheckAccessHub(this.presenceHub.pageActive[5], status) === false)
      return;   

    if (user === null)
      return;

    if (this.toggleInvoice.$toggleInvoicesBtn)
    {
      this.hubConnection = ConnectionSignalRHub.GetConnectionHub(this.hubConnection, this.huburl, accountID, user, "allinvoices");
      this.hubConnection.on('ReceiveAllinvoices', payments => {
      if (payments !== null)
      {        
        console.log(payments)
        if (this.accountsDataSource$.data.length != payments.length)
        {
          this.AssignDataSource(payments);
        }
        else
        {
          var index: number = 0;
          payments.forEach(payment => {
            if (this.accountsDataSource$.data[index].accountReceivables != payment.accountReceivables)
            {
              this.AssignDataSource(payments);
            }
            else if (this.accountsDataSource$.data[index].accountReturns != payment.accountReturns)
            {
              this.AssignDataSource(payments);
            }
            else if (this.accountsDataSource$.data[index].contactMobile != payment.contactMobile)
            {
              this.AssignDataSource(payments);
            }
            else if (this.accountsDataSource$.data[index].dueDate != payment.dueDate)
            {
              this.AssignDataSource(payments);
            }
            else if (this.accountsDataSource$.data[index].firstName != payment.firstName)
            {
              this.AssignDataSource(payments);
            }
            else if (this.accountsDataSource$.data[index].lastName != payment.lastName)
            {
              this.AssignDataSource(payments);
            }
            else if (this.accountsDataSource$.data[index].invoiceAmount != payment.invoiceAmount)
            {
              this.AssignDataSource(payments);
            }
            else if (this.accountsDataSource$.data[index].invoiceNumber != payment.invoiceNumber)
            {
              this.AssignDataSource(payments);
            }
            else if (this.accountsDataSource$.data[index].paidAmount != payment.paidAmount)
            {
              this.AssignDataSource(payments);
            }
            else if (this.accountsDataSource$.data[index].sendInitialMessage != payment.sendInitialMessage)
            {
              this.AssignDataSource(payments);
            }
            index = index + 1;
          });        
        } 
      }});
    }   
    else
    {
      this.hubConnection = ConnectionSignalRHub.GetConnectionHub(this.hubConnection, this.huburl, accountID, user, "allunpaidinvoices");     
      this.hubConnection.on('ReceiveAllunpaidinvoices', payments => {
      if (payments !== null)
      {  
        if (this.accountsDataSource$.data.length != payments.length)
        {      
          this.AssignDataSource(payments);      
        }
        else 
        {
          var index: number = 0;
          payments.forEach(payment => {
            if (this.accountsDataSource$.data[index].accountReceivables != payment.accountReceivables)
            {
              this.AssignDataSource(payments);
            }
            else if (this.accountsDataSource$.data[index].accountReturns != payment.accountReturns)
            {
              this.AssignDataSource(payments);
            }
            else if (this.accountsDataSource$.data[index].contactMobile != payment.contactMobile)
            {
              this.AssignDataSource(payments);
            }
            else if (this.accountsDataSource$.data[index].dueDate != payment.dueDate)
            {
              this.AssignDataSource(payments);
            }
            else if (this.accountsDataSource$.data[index].firstName != payment.firstName)
            {
              this.AssignDataSource(payments);
            }
            else if (this.accountsDataSource$.data[index].lastName != payment.lastName)
            {
              this.AssignDataSource(payments);
            }
            else if (this.accountsDataSource$.data[index].invoiceAmount != payment.invoiceAmount)
            {
              this.AssignDataSource(payments);
            }
            else if (this.accountsDataSource$.data[index].invoiceNumber != payment.invoiceNumber)
            {
              this.AssignDataSource(payments);
            }
            else if (this.accountsDataSource$.data[index].paidAmount != payment.paidAmount)
            {
              this.AssignDataSource(payments);
            }
            else if (this.accountsDataSource$.data[index].sendInitialMessage != payment.sendInitialMessage)
            {
              this.AssignDataSource(payments);
            }
            index = index + 1;
          });   
        }
    
      }});
    } 
  }

  stopHubConnection(){
    if (this.hubConnection) {
      this.hubConnection?.stop();
    }
  }

  getReceivablesPayables(){
    return this.http.get<any[]>(this.baseUrl + 'accountspayablesandrecivables/getpayablesreceivablesaccounts').pipe(
      map(response => {
        return response;
      })
    )
  }

  showOnlyArchiveAccounts(){
    return this.http.get<any[]>(this.baseUrl + 'accountspayablesandrecivables/readonlyarchiveaccounts').pipe(
      map(response => {
        return response;
      })
    )
  }

  private AssignDataSource(payments: any)
  {
    this.invoicesSource.next(payments);
    this.accountsDataSource$.data = payments;
    console.log(payments);
  }

  sendALLSMS(){
    return this.http.get(this.baseUrl + 'accountspayablesandrecivables/SendAllInitialSMS').pipe(
      map(response => {
        return response;
      })
    )
  }

  getReceivablesPayablesUnpaid(){
    return this.http.get<any[]>(this.baseUrl + 'accountspayablesandrecivables/getpayablesreceivablesaccountsunpaid').pipe(
      map(response => {
        return response;
      })
    )
  }

  
}
