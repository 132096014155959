import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ClientSubscriptionService } from 'src/app/_services/clientsubscription/client-subscription.service';

@Component({
  selector: 'app-areyousurewanttoremovesubscription',
  templateUrl: './areyousurewanttoremovesubscription.component.html',
  styleUrls: ['./areyousurewanttoremovesubscription.component.css']
})
export class AreyousurewanttoremovesubscriptionComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private services: ClientSubscriptionService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.data.cancelMember = false;
  }

  removeSubscription(){
    this.services.cancelCurrentMembership().subscribe(res => {
      this.toastr.success("You have successfully cancel your membership!");
      this.data.cancelMember = true;
      location.reload();
    }, error => {
      console.log(error);
    })
  }

}
