import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { stringify } from 'querystring';
import { ImportfileInvoice } from 'src/app/_models/importfileinvoice';
import { XeroapiService } from 'src/app/_services/Xero/xeroapi.service';
import { ConfirmimportsinvoicesComponent } from '../confirmimportsinvoices/confirmimportsinvoices.component';
import { DeleteimportinvoiceComponent } from '../deleteimportinvoice/deleteimportinvoice.component';
import { EditimportinvoiceComponent } from '../editimportinvoice/editimportinvoice.component';

@Component({
  selector: 'app-importapiinvoice',
  templateUrl: './importapiinvoice.component.html',
  styleUrls: ['./importapiinvoice.component.css']
})
export class ImportapiinvoiceComponent implements AfterViewInit {
  usersFromImportFile: ImportfileInvoice[];
  invoices: ImportfileInvoice[] = [];
  validationErrors: boolean = false;
  canSubmit: boolean = false;
  errorText: string = "";
  showConflictEnable: boolean = false;
  onClickPayExtra: boolean = true;
  constructor(private service: XeroapiService, private dialog: MatDialog, private router: Router, private toastr: ToastrService ) { }
  
  ngAfterViewInit(): void {
    this.getAPIFile();
    //this.getInvoiceList();
  }


  getAPIFile() {
    this.service.getListAPIInvoiceFile().subscribe(res => {
      console.log(res);
      this.usersFromImportFile = res;
      this.checkCanSubmit();
    }, error => {
      console.log(error);
    });
  }


  // Reductant - Removed due to ability just to update */
  getInvoiceList(){
    this.service.getListInvoice().subscribe(response => {
      this.invoices = response;   
      this.checkConflictClient();
      //this.checkConflictImports(); 
    }, error => {
      console.log(error);
    })
  }

  // Reductant - Removed due to ability just to update */
  checkConflictClient(){
    var indexClient: number = 0;
    var indexImport: number = 0;
    this.invoices.forEach(invoice => {      
      if (this.usersFromImportFile != null)
      {
        this.usersFromImportFile.forEach(file => {
       
          if (invoice.invoiceNumber == file.invoiceNumber)
          {
            this.usersFromImportFile[indexImport].conflictImport = true;
            this.invoices[indexClient].conflictImport = true;
            this.canSubmit = false;
          }
          indexImport++;
        });
      }
      indexImport = 0;
      indexClient++;
    });
  
  }

  // Reductant - Removed due to ability to just update the record to server 
  checkConflictImports(){
    let index: number = 0;
    let counter: number = 0;

    if (this.usersFromImportFile == null)
      return;
      
    for (let i = 0; i < this.usersFromImportFile.length; i++)
    {
      this.usersFromImportFile.forEach(element => {
        if (this.usersFromImportFile[i].invoiceNumber == element.invoiceNumber)
        {
          this.usersFromImportFile[i].conflictImport = true;  
        }

        if (counter == 2)
        {
          this.usersFromImportFile[i].conflictImport = true;
        }
        index++;
      });
      counter = 0;
    }
  }

  editImport(id: any){
    const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = "50%";
      dialogConfig.height = "100%";
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.data = {
        id: id,
        invoiceNumber: this.usersFromImportFile[id].invoiceNumber,
        firstName: this.usersFromImportFile[id].firstName,
        lastName: this.usersFromImportFile[id].lastName,
        invoiceAmount: this.usersFromImportFile[id].invoiceAmount,
        paymentAmount: this.usersFromImportFile[id].paidAmount,
        initalDate: this.usersFromImportFile[id].initalDate,
        dueDate : this.usersFromImportFile[id].dueDate,
        note : this.usersFromImportFile[id].note,        
        contactMobile: this.usersFromImportFile[id].contactMobile,
        paidAmount: this.usersFromImportFile[id].paidAmount
      };
      
      const dialogRef = this.dialog.open(EditimportinvoiceComponent, dialogConfig);
  
      dialogRef.afterClosed().subscribe(result => {        
        this.usersFromImportFile[result.id] = result;
        this.checkDefaultValidation();
        this.checkErrorMessage();
      }); 
  }

  sortDefaultGreen(){
    for (let i = 0; i < this.usersFromImportFile.length; i++ )
    {
      this.usersFromImportFile[i].conflictImport = false;
    }

    for (let i = 0; i < this.invoices.length; i++ )
    {
      this.invoices[i].conflictImport = false;
    }
  }

  deleteImport(id){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "25%";
    dialogConfig.data = {
      id: id,
      deleteInstalment: false
    };
    
     const dialogRef = this.dialog.open(DeleteimportinvoiceComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.deleteImportAccount != null)
      {      
        this.usersFromImportFile.splice(result.id, 1);
        this.checkDefaultValidation();
        this.checkErrorMessage();
      }
    });   
  }

  onSubmit(){
    this.usersFromImportFile[0].payExtra = this.onClickPayExtra;
    this.service.createImportsInvoices(this.usersFromImportFile).subscribe(response => {
      this.messageConfirm();
    }, error => {
      if (error.error != null)
      {
        const meg: string = error.error;
        this.errorText = meg;
      }
      else
      {
        const meg: string = error;
        this.errorText = meg;
      }

      this.validationErrors = true;      
    });
  }

  checkDefaultValidation(){
    this.checkCanSubmit();
    //this.sortDefaultGreen();
    //this.checkConflictClient();
    //this.checkConflictImports();
  }

  messageConfirm(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "25%";
    dialogConfig.data = {
    };
    
     const dialogRef = this.dialog.open(ConfirmimportsinvoicesComponent, dialogConfig);

     dialogRef.afterClosed().subscribe(result => {
      if (result.confirm != null)
      {      
        this.router.navigate(['/members/clients']);
      }
    }); 
  }

  onChangeFees(){
    this.onClickPayExtra = !this.onClickPayExtra;
  }

  checkCanSubmit(){
    if (this.usersFromImportFile == null)
      return;

    if (this.usersFromImportFile.length > 0) 
      this.canSubmit = true;
    else 
      this.canSubmit = false;    
      this.errorText = " You have duplicate invoice numbers. Please look at the red record below the invoice list (You can remove record," 
        + " or change the invoice number so it is uniquely different.)"     
  }

  checkErrorMessage(){
   
    if (this.usersFromImportFile == null) {
      this.errorText = "You can't submit while you have no invoices to input!"   
      return;
    }

    if (this.usersFromImportFile.length < 1) 
      this.errorText = "You can't submit while you have no invoices to input!"     
  }


}
