import { Component, OnInit } from '@angular/core';
import { AccountService } from '../_services/account/account.service';
import { ActivatedRoute } from '@angular/router';
import { HomeserviceService } from '../_services/home/homeservice.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  registerMode = false;
  testHome: string = "";

  constructor(public accountService: AccountService, private route: ActivatedRoute, private homeservice: HomeserviceService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getParrams();
  }

  getParrams(){
    this.route.paramMap.subscribe(params => {
      const result = params.get("register");
      if (result !== null)
        this.registerMode = !this.registerMode;
    });
  }

  registerTogger(){
    this.registerMode = !this.registerMode;
  }

  cancelRegisterModel(event: boolean){
    this.registerMode = event;
  } 

  getHomeTest(){
    this.homeservice.getTestHome().subscribe(response => {
      console.log(response);
      this.testHome = response;
    }, err => {
      console.log(err);
    });
  }

}
