import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessagesService } from 'src/app/_services/message/messages.service';
import { MessageComponent } from '../message.component';

@Component({
  selector: 'app-showexamplereceiveemail',
  templateUrl: './showexamplereceiveemail.component.html',
  styleUrls: ['./showexamplereceiveemail.component.css']
})
export class ShowexamplereceiveemailComponent implements OnInit {
  @ViewChild('formEdit') editForm: NgForm; 
  formEmail: UntypedFormGroup;
  time: Date;
  constructor(public fb: UntypedFormBuilder, private service: MessagesService,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<MessageComponent>) { }

  ngOnInit(): void {
    this.data = 1;
    this.initializeForm();
  }

  initializeForm(){
    this.formEmail = this.fb.group({
      dueDate:['', Validators.required],
      InvoiceAmount:['', [Validators.required, Validators.min(0.5), Validators.max(999999999999999)]],
      Name:['', Validators.required],
      EmailTo:['', [Validators.required, Validators.email]], 
    });  
  }

  onSubmit(){
    const email = this.getFormFields();
    this.service.TestUpdateReceiveEmail(email).subscribe(response => {
    }, error => {
      console.log(error);
    });
  }

  getFormFields(){
    const email = {
      DueDate: this.formEmail.controls['dueDate'].value,
      FullName: this.formEmail.controls['Name'].value,
      InvoiceAmount: this.formEmail.controls['InvoiceAmount'].value,    
      Email: this.formEmail.controls['EmailTo'].value
    }
    return email;
  }

}
