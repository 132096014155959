import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectionSignalRHub } from 'src/app/_helper/connectionsignalrhub';
import { accountpayment } from 'src/app/_models/accountpayment';
import { Instalment } from 'src/app/_models/instalment';
import { PaginatedResult } from 'src/app/_models/pagination';
import { PayablesReceivables } from 'src/app/_models/payablesreceivables';
import { User } from 'src/app/_models/user';
import { environment } from 'src/environments/environment';
import { HostpresenceService } from '../hubsignalr/hostpresence.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  baseUrl =  environment.apiUrl;
  //paginatedResult: PaginatedResult<accountpayment[]> = new PaginatedResult<accountpayment[]>();
  public accounts: accountpayment[];
  
  constructor(private http: HttpClient, private presenceHub: HostpresenceService, private toastr: ToastrService) { }

  public paymentsDataSource = new MatTableDataSource<accountpayment>();
  private paymentsSource = new BehaviorSubject<accountpayment[]>([]);
  totalPayments$: number = 0;
  defaultInvoicePayment$: number = 0;
  defaultInvoiceCost: number =0;
  payments$ = this.paymentsSource.asObservable();
  showarchive: boolean = false;
  private hubConnection?: HubConnection;
  private hubArchiveConnection?: HubConnection;
  huburl = environment.hubUrl;

  createPayment(paymentaccount: accountpayment){
    return this.http.post(this.baseUrl + "accountpayments/createpayment", paymentaccount).pipe(
      map((response: any) => {
                        
      }));
  }
  
  getPayments(accountID?: string){
    return this.http.get<accountpayment[]>(this.baseUrl + 'accountpayments/getaccountpayments?accountID=' + accountID).pipe(
      map(response => {
        return response;
      })
    )
  }

  getClientInformation(accountID: number){
    return this.http.get(this.baseUrl + 'accountpayments/getinformationfromclient?accountid=' + accountID);
  }

  getEditPayment(accountID: number){
    return this.http.get<accountpayment>(this.baseUrl + 'accountpayments/geteditaccountpayment?accountID=' + accountID).pipe(
      map(account => {
        return account;
      })
    )
  }

  updatePayment(accountpayment: accountpayment){
    return this.http.put(this.baseUrl + 'accountpayments/updateaccountpayment', accountpayment).pipe(
      map((status: boolean) => {
        return status;
      })
    );
  }

  deleteAccountPayment(accountID: number){
    return this.http.delete(this.baseUrl + 'accountpayments/deleteaccountpayment?accountID=' + accountID).pipe(
      map((status: boolean) => {
        return status;
      })
    );
  }

  ArchiveAccountPayment(account: any){
    return this.http.put(this.baseUrl + 'accountpayments/updatearchiveaccount', account).pipe(
      map((status: boolean) => {
        return status;
      })
    );
  }

  getCheckCanEditClient(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'accountpayments/Iseditactive?accountID=' + accountID).pipe(
      map((client => {
        return client;
      }))
    )
  }

  getCheckCanDeleteClient(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'accountpayments/Iseditactive?accountID=' + accountID + '&isItDeleteFunction=true').pipe(
      map((client => {
        return client;
      }))
    )
  }

  getCheckCanArchiveClient(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'accountpayments/Iseditactive?accountID=' + accountID + '&isItArchiveFunction=true').pipe(
      map((client => {
        return client;
      }))
    )
  }

  cancelCanEditClient(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'accountpayments/closeeditactive?accountID=' + accountID).pipe(
      map((client => {
        return client;
      }))
    )
  }

  async createHubConnection(user: User, accountID: number) 
  {  
    if (ConnectionSignalRHub.CheckAccessHub(this.presenceHub.pageActive[3], false) === false)
      return;

    this.hubConnection = ConnectionSignalRHub.GetConnectionHub(this.hubConnection, this.huburl, accountID, user, "payments");

    this.hubConnection.on('ReceivePaymentsAccounts', (payments: accountpayment[]) => {
      if (payments !== null)
      {    
        this.CheckPaymentSource(payments, true);
      }     
    })    
  }

  async createHubArchiveConnection(user: User, accountID: number) 
  {  
    if (ConnectionSignalRHub.CheckAccessHub(this.presenceHub.pageActive[3], false) === false)
      return;

    this.hubConnection = ConnectionSignalRHub.GetConnectionHub(this.hubConnection, this.huburl, accountID, user, "paymentsarchive");

    this.hubConnection.on('ReceiveArchivePaymentsAccounts', (payments: accountpayment[]) => {
      if (payments !== null)
      {       
        this.CheckPaymentSource(payments, false);
      }     
    })    
  }

  private CheckPaymentSource(payments: accountpayment[], showarchive: boolean)
  {
    var accountPaymentsLength: number = 0;
    const dataSourcePaymentLength: number = this.paymentsDataSource.data.length;
    payments.forEach(payment => {
      accountPaymentsLength = accountPaymentsLength + 1;
    });
 
    if (this.showarchive === showarchive)
    {
      return;  
    }
     else if (dataSourcePaymentLength === 0)
    {
      this.setPaymentSournce(payments);
    }
    else if (accountPaymentsLength === 0)
    {
      this.setPaymentSournce(payments);
    }
    else if (accountPaymentsLength !== dataSourcePaymentLength)
    {
      this.setPaymentSournce(payments)
    }
    else if (accountPaymentsLength === dataSourcePaymentLength)
    {         
      var i: number = 0;
      payments.forEach(payment => {
        if (payment.amount != this.paymentsDataSource.data[i].amount)
        {
          this.setPaymentSournce(payments);
        }
        else if (payment.date != this.paymentsDataSource.data[i].date)
        {
          this.setPaymentSournce(payments);
        }
        else if (payment.note != this.paymentsDataSource.data[i].note)
        {
          this.setPaymentSournce(payments);
        }
        else if (payment.paymentOption != this.paymentsDataSource.data[i].paymentOption)
        {
          this.setPaymentSournce(payments);
        }
        else if (payment.paymentType != this.paymentsDataSource.data[i].paymentType)
        {
          this.setPaymentSournce(payments);
        }
        else if (payment.archive != this.paymentsDataSource.data[i].archive)
        {
          this.setPaymentSournce(payments);
        }
      }); 
    } 
  }

  setPaymentSournce(payments: accountpayment[]){
    this.paymentsSource.next(payments);
    this.paymentsDataSource.data = payments;
  }

  stopHubArchiveConnection(){
    if (this.hubConnection) {
      this.hubConnection?.stop();
    }
  }

  stopHubConnection(){
    if (this.hubArchiveConnection) {
      this.hubArchiveConnection?.stop();
    }
  }
}

