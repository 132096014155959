import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { MessageSent } from 'src/app/_models/messagesent';
import { PaginatedResult } from 'src/app/_models/pagination';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessagesentService {
  baseUrl =  environment.apiUrl;
  paginatedResult: PaginatedResult<MessageSent[]> = new PaginatedResult<MessageSent[]>();
  

  constructor(private http: HttpClient) { }

  getReceiveEmailMessage(page?: number, itemPerPage?: number){
    let params = new HttpParams();

    if (page !== null && itemPerPage !== null) {
      console.log(page);   
      params = params.append('pageNumber', page.toString());
      params = params.append('pageSize', itemPerPage.toString());
    }

    return this.http.get<any[]>(this.baseUrl + 'MessagesSent/getmessageslist', {observe: 'response', params}).pipe(
      map(response => {
        this.paginatedResult.result = response.body;
        if (response.headers.get('Pagination') !== null) {
          this.paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));           
        }
        return this.paginatedResult;
      })
    )
  }
}
