import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';  
    
export class PasswordValidator {  
    static  matchValues(matchTo: string): ValidatorFn {
        return (control: AbstractControl) => {
          return control?.value === control?.parent?.controls[matchTo].value
           ? null : {isMatching: true}
        }
      }

    static PasswordValidated(control: AbstractControl) : ValidationErrors | null { 
      var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})"); 
      
      var result = strongRegex.test(control.value as string);
      console.log(result);
      if (result == false){
        return {passwordValidated: true} 
      }
  
      return null;  
    } 
}  