import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { MembersService } from 'src/app/_services/account/members.service';

@Component({
  selector: 'app-dialogtypeservices',
  templateUrl: './dialogtypeservices.component.html',
  styleUrls: ['./dialogtypeservices.component.css']
})
export class DialogtypeservicesComponent implements OnInit {
  showTypeService = new ReplaySubject<Boolean>(1);
  showTypeService$ = this.showTypeService.asObservable();
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private service: MembersService) { }

  ngOnInit(): void {
    this.showTypeService.next(false);
    const oldData = this.data;
    this.data = {
      service: oldData.service,
      change: false
    }
    this.onLoad();
  }

  onLoad(){
    this.service.getTypeService().subscribe(response => {
      if (response)
      {
        this.showTypeService.next(true);
        return;
      }
      this.showTypeService.next(false);      
    }, err => {
      console.log(err);
    })
  }

  changeServicesAccount(){
    this.data.change = true;
    this.service.updateCurrentService().subscribe(response => {
      
    }, err => {
      console.log(err);
    })
    if (this.data.service)
    {
      this.data.service = false;
    }
    else
    {
      this.data.service = true;
    }
  }

}
