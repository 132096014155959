<div class="col-lg-12 col-md-12 col-sm-12">
<h2 class="text-center">How to go to the clients page</h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Client/Images/Go to Client/gtc01.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	On the navigation bar, click the “Clients” item</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Client/Images/Go to Client/gtc02.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	You’re now on the “Clients” page</h2>
</div>