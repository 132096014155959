<div class="container">
  <div class="alert alert-dismissible alert-danger mt-4">
    <button type="button" class="close" data-dismiss="alert">×</button>
    <strong>Oh snap!</strong> Failed To Make Payment!
  </div>
  <section>
      <p>
        We appreciate your business! If you have any questions, please email
        <a href="mailto:orders@example.com">support@dcryptitsolutions.com</a>.
      </p>
  </section>
</div>
