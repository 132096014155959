<div class="card-body">
    <nav class="navbar navbar-light bg-primary shadow-lg">
      <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Automation of Number of Instalments </a>  
    </nav>
    <form class="mt-4" [formGroup]="formInstalment" (ngSubmit)="formInstalment.valid" autocomplete="off">
        <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example" [formControl]='formInstalment.controls["typeofinstalment"]'  (change)="OnChangeSelectOption()">
            <option selected value="0">Default Singular Instalment</option>
            <option value="1">Daily</option>
            <option value="2">Weekly (Every 7 Days)</option>
            <option value="3">Fortnightly (Every 14 Days)</option>
            <option value="4">Monthly (Every 30 days)</option>
        </select>
    </form>
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource">
      
          <!-- Position Column -->
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
          </ng-container>
      
          <!-- Name Column -->
          <ng-container matColumnDef="duedate">
            <th mat-header-cell *matHeaderCellDef> Due Date </th>
            <td mat-cell *matCellDef="let element"> {{element.duedate | date }} </td>
          </ng-container>
      
          <!-- Weight Column -->
          <ng-container matColumnDef="amountdefault">
            <th mat-header-cell *matHeaderCellDef> Amount Default </th>
            <td mat-cell *matCellDef="let element"> {{element.amountdefault | currency }} </td>
          </ng-container>
      
          <!-- Symbol Column -->
          <ng-container matColumnDef="amountwithfees">
            <th mat-header-cell *matHeaderCellDef> Amount with Fees </th>
            <td mat-cell *matCellDef="let element"> {{element.amountwithfees | currency }} </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      
        <mat-paginator [pageSizeOptions]="[5, 10]"
                       showFirstLastButtons
                       aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    <div class="row mt-4">
      <div class="col-6">
        <button mat-button [disabled]="!formInstalment.valid" [mat-dialog-close]="data" class="btn btn-success btn-block btn-lg" (click)="onSubmit()" >Confirm Number Of Instalments</button>
      </div>
      <div class="col-6">
        <button class="btn btn-danger btn-block btn-lg" mat-button mat-dialog-close>Cancel</button>
      </div>
    </div>
  </div>
  
  
  