import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { StoreaccountService } from 'src/app/_services/storeaccount/storeaccount.service';

@Component({
  selector: 'app-archivestorecredit',
  templateUrl: './archivestorecredit.component.html',
  styleUrls: ['./archivestorecredit.component.css']
})
export class ArchivestorecreditComponent implements OnInit {
  archive: boolean;
  accountID: number;
  clientID: number;
  user?: User;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private service: StoreaccountService, private accountService: AccountService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.archive = !this.data.archive;
    this.accountID = +this.data.id;
    this.clientID = +this.data.clientID;
    this.assignUser();
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  archiveAccount(archiveType: boolean){
    this.service.archiveStoreAccount(this.accountID, archiveType).subscribe(response => {
      if (response)
      {
        this.toastr.success("You have successfully archive this account!");

        /* Just send both */
        this.service.createHubConnection(this.user, +this.clientID, false);
        this.service.stopHubConnection();
        this.service.createHubConnection(this.user, +this.clientID, false);
        this.service.stopArchiveHubConnection(); 
        this.service.createArchiveHubConnection(this.user, +this.clientID, false);    
      }
    }, err => {
      console.log(err);
    });
  }

}
