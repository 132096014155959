import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './errors/notfound/not-found/not-found.component';
import { ServerErrorComponent } from './errors/serverrror/server-error/server-error.component';
import { TestErrosComponent } from './errors/test-errors/test-erros/test-erros.component';
import { HomeComponent } from './home/home.component';
import { EditAccountComponent } from './members/account/edit-account/edit-account.component';
import { AccountclientreceivableComponent } from './members/client/create-client/createinvoice/accountclientreceivable/accountclientreceivable.component';
import { CreateClientComponent } from './members/client/create-client/create-client.component';
import { CreateinvoiceComponent } from './members/client/create-client/createinvoice/createinvoice.component';
import { AuthGuard } from './_guards/auth.guard';
import { PreventUnsavedChangesGuard } from './_guards/prevent-unsaved-changes.guard';
import { AccountsreceivableComponent } from './members/accountsreceivable/accountsreceivable.component';
import { CreateinstalmentsComponent } from './members/client/create-client/createinstalments/createinstalments.component';
import { ClientaccountpaymentsComponent } from './members/client/clientaccountpayments/clientaccountpayments.component';
import { MessageComponent } from './members/message/message.component';
import { CustomerpaymentpageComponent } from './customerpaymentpage/customerpaymentpage.component';
import { SucesspaymentComponent } from './customerpaymentresults/sucesspayment/sucesspayment.component';
import { FailedpaymentComponent } from './customerpaymentresults/failedpayment/failedpayment.component';
import { TestresultmessageComponent } from './members/message/testresultmessage/testresultmessage.component';
import { ImportComponent } from './members/import/importedclients/importfile/import.component';
import { ImportfileinvoiceComponent } from './members/import/importinvoices/importfileinvoice/importfileinvoice.component';
import { StripeaccountslistComponent } from './members/stripeaccounts/stripeaccountslist/stripeaccountslist.component';
import { ListaccountsinvoicesComponent } from './members/listaccountsinvoices/listaccountsinvoices.component';
import { TesttableComponent } from './testtable/testtable.component';
import { ListclientsubscriptionsComponent } from './members/subscription/listclientsubscriptions/listclientsubscriptions.component';
import { ClientsubscriptionsuccessComponent } from './members/subscription/clientsubscriptionsuccess/clientsubscriptionsuccess.component';
import { ImportapiclientComponent } from './members/import/importedclients/importapiclient/importapiclient.component';
import { ImportapiinvoiceComponent } from './members/import/importinvoices/importapiinvoice/importapiinvoice.component';
import { EditdetailsacountComponent } from './members/account/editdetailsacount/editdetailsacount.component';
import { ListuserbillsComponent } from './members/subscription/listuserbills/listuserbills.component';
import { CreatestorecreditComponent } from './members/credit/createstorecredit/createstorecredit.component';
import { EditchangepasswordComponent } from './members/account/edit-account/editchangepassword/editchangepassword.component';
import { EmailresenderComponent } from './email/confirmmail/emailresender/emailresender.component';
import { ConfirmemailpageComponent } from './email/confirmmail/confirmemailpage/confirmemailpage.component';
import { ReceivelostemailComponent } from './email/lostemail/receivelostemail/receivelostemail.component';
import { ResetlostemailComponent } from './email/lostemail/resetlostemail/resetlostemail.component';
import { ContactemailComponent } from './email/contactemail/contactemail.component';
import { ImportpageComponent } from './members/import/importpage/importpage.component';
import { TutorialhomeComponent } from './members/tutorials/tutorialhome/tutorialhome.component';
import { CreateusergroupsComponent } from './members/usergroups/createusergroups/createusergroups.component';
import { EditusergroupsComponent } from './members/usergroups/editusergroups/editusergroups.component';
import { EditmemberinagroupComponent } from './members/usergroups/editmemberinagroup/editmemberinagroup.component';
import { GetemailconfirmusergroupsComponent } from './email/getemailconfirmusergroups/getemailconfirmusergroups.component';
import { HometwofactorauthenticationComponent } from './members/account/hometwofactorauthentication/hometwofactorauthentication.component';
import { LoginComponent } from './login/login.component';
import { PrintbillComponent } from './members/subscription/listuserbills/printbill/printbill.component';
import { FaqsComponent } from './members/faqs/faqs/faqs.component';
import { CreditartworkfeaturesComponent } from './footer/creditartworkfeatures/creditartworkfeatures.component';
import { ForwardpayfeaturesComponent } from './footer/forwardpayfeatures/forwardpayfeatures.component';
import { InstalmentfeaturesComponent } from './footer/instalmentfeatures/instalmentfeatures.component';
import { PaylaterfeaturesComponent } from './footer/paylaterfeatures/paylaterfeatures.component';
import { PrivacypolicyfeaturesComponent } from './footer/privacypolicyfeatures/privacypolicyfeatures.component';
import { TermsandconditionsfeaturesComponent } from './footer/termsandconditionsfeatures/termsandconditionsfeatures.component';
import { HomefeaturesComponent } from './footer/homefeatures/homefeatures.component';
import { PrintinvoiceComponent } from './members/client/create-client/printinvoice/printinvoice.component';
import { EditinvoiceComponent } from './members/client/create-client/editinvoice/editinvoice.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      {path: 'members/clients', component: CreateClientComponent, canDeactivate: [PreventUnsavedChangesGuard]},
      {path: 'members/editaccount', component: EditAccountComponent, canDeactivate: [PreventUnsavedChangesGuard]},
      {path: 'members/editaccountchangepassword', component: EditchangepasswordComponent, canDeactivate: [PreventUnsavedChangesGuard]},
      {path: 'members/editinvoice/:id/:clientID', component: EditinvoiceComponent},
      {path: 'members/invoices/:id', component: CreateinvoiceComponent, canDeactivate: [PreventUnsavedChangesGuard]},
      {path: 'members/accountclientreceivable/:id/:invoiceamount', component: AccountclientreceivableComponent, canDeactivate: [PreventUnsavedChangesGuard]}, 
      {path: 'members/accountsreceivable', component: AccountsreceivableComponent},
      {path: 'members/createinstalments/:id/:invoiceamount/:amountpaid', component: CreateinstalmentsComponent, canDeactivate: [PreventUnsavedChangesGuard]},
      {path: 'members/clientaccountpayments/:id', component: ClientaccountpaymentsComponent},
      {path: 'members/editusergroup/:id', component: EditusergroupsComponent, canDeactivate: [PreventUnsavedChangesGuard]},
      {path: 'members/usergroups', component: CreateusergroupsComponent, canDeactivate: [PreventUnsavedChangesGuard]},
      {path: 'members/editmemberinagroup/:id', component: EditmemberinagroupComponent, canDeactivate: [PreventUnsavedChangesGuard]},
      {path: 'members/twofactorauthentication', component: HometwofactorauthenticationComponent},
      {path: 'members/message', component: MessageComponent},
      {path: 'members/importfileclient', component: ImportComponent},
      {path: 'members/stripeaccounts', component: StripeaccountslistComponent},
      {path: 'members/importapiclients', component: ImportapiclientComponent},
      {path: 'members/listaccountsinvoices', component: ListaccountsinvoicesComponent},
      {path: 'members/subscriptions', component: ListclientsubscriptionsComponent},
      {path: 'members/subscriptionsuccess/:id', component: ClientsubscriptionsuccessComponent},
      {path: 'members/importfileinvoice', component: ImportfileinvoiceComponent},
      {path: 'members/importapiinvoice', component: ImportapiinvoiceComponent},
      {path: 'members/editdetailsacount', component: EditdetailsacountComponent},
      {path: 'members/listuserbills', component: ListuserbillsComponent},
      {path: 'members/printinvoice/:id', component: PrintinvoiceComponent},
      {path: 'members/printbill/:id', component: PrintbillComponent},
      {path: 'members/importpage', component: ImportpageComponent},
      {path: 'members/createstorecredit/:id', component: CreatestorecreditComponent, canDeactivate: [PreventUnsavedChangesGuard]},
      {path: 'members/turtorialhome', component: TutorialhomeComponent},
      {path: 'members/faqs', component: FaqsComponent},
    ]
  },
  {path: 'testresultmessage', component: TestresultmessageComponent},
  {path: 'tabletable', component: TesttableComponent},
  {path: 'confirmusergrouppermissions', component: GetemailconfirmusergroupsComponent},
  {path: 'login', component: LoginComponent},
  {path: 'receivelostemail', component: ReceivelostemailComponent},
  {path: 'resetlostemail', component: ResetlostemailComponent},
  {path: 'contactus', component: ContactemailComponent},
  {path: 'emailresender/:id', component: EmailresenderComponent},
  {path: 'confirmemail', component: ConfirmemailpageComponent, pathMatch: 'full'},
  {path: 'customerpayment/:id', component: CustomerpaymentpageComponent},
  {path: 'sucesspayment/:id', component: SucesspaymentComponent},
  {path: 'failpayment', component: FailedpaymentComponent},
  {path: 'creditartwork', component: CreditartworkfeaturesComponent},
  {path: 'forwardpay', component: ForwardpayfeaturesComponent},
  {path: 'Instalments', component: InstalmentfeaturesComponent},
  {path: 'paylater', component: PaylaterfeaturesComponent},
  {path: 'privacypolicy', component: PrivacypolicyfeaturesComponent},
  {path: 'termandconditions', component: TermsandconditionsfeaturesComponent},
  {path: 'errors', component: TestErrosComponent},
  {path: 'not-found', component: NotFoundComponent},  
  {path: 'features', component: HomefeaturesComponent},  
  {path: 'server-error', component: ServerErrorComponent},  
  {path: 'home', component: HomeComponent},  
  {path: 'home/:register', component: HomeComponent},  
  {path: '**', component: HomeComponent, pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
