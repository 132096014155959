import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToggledefinitionService {
  public $showLegends: boolean[] = new Array(100).fill(true);
  public $showLegendsTxt: string[] = new Array(100).fill("Show Legends");
  constructor() {
    this.$showLegends[0] = false;
   }

  showLegends(value: number){
    this.$showLegends[value] = !this.$showLegends[value];
    if (this.$showLegends[value])
    {
      this.$showLegendsTxt[value] = "Hide Legends";
    }
    else
    {
      this.$showLegendsTxt[value] = "Show Legends";
    }
  }
}
