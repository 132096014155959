import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';  
    
/* Doesn't work - Still in play testing */
export class DateValidation {  
    static CompareDate(dateofLast: Date) : ValidatorFn {
        return (control: AbstractControl) => {     
            const currentDate: Date = new Date(dateofLast);
            const controlDate: Date = new Date(control?.value);
            return controlDate >= currentDate ? null : {compareDateInvalid: true};
        }
    }   
}  