import { Component, OnInit } from '@angular/core';
import { ClientProductSubscription } from 'src/app/_models/clientproductsubscription';
import { Currentaccountsubscriptioninfo } from 'src/app/_models/currentaccountsubscriptioninfo';
import { ClientSubscriptionService } from 'src/app/_services/clientsubscription/client-subscription.service';

@Component({
  selector: 'app-listclientsubscriptions',
  templateUrl: './listclientsubscriptions.component.html',
  styleUrls: ['./listclientsubscriptions.component.css']
})
export class ListclientsubscriptionsComponent implements OnInit {

  constructor(private services: ClientSubscriptionService) { }
  products: ClientProductSubscription[];
  publicToken: string;
  accountInfo: Currentaccountsubscriptioninfo;
  checkErrorProduct: boolean = false;

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.checkErrorProduct = false;
    this.onLoad();
    this.getOnLoadUserSubscrripton();
  }

  onLoad(){
    this.services.getProducts().subscribe(response => {
       this.products = response;
       this.publicToken = this.products[0].publicTokenID;
       console.log(response);
    }, error => {
      console.log(error);
    })
  }

  pay(id: number){
     id = id;
     this.services.purchaseProduct(this.products[id].priceID, this.publicToken).subscribe(response => {
       
     }, error => {
       console.log(error);
       if (error.error === "Your account is already assigned to a subscription base model. Please cancel current subscription!")
       {
         this.checkErrorProduct = true;
       }
     });
  }

  getOnLoadUserSubscrripton(){
    this.services.onLoadUserSubscription().subscribe(reponse => {
      this.accountInfo = reponse;
    }, err => {
      console.log(err);    
    })
  }

  goBackHome(){
    
  }
}
