<div>
  <ng-container *ngIf="accountService.currentUser$ | async as user">
  <div class="d-none d-md-none d-lg-block">
    <nav class="navbar navbar-light bg-primary shadow-lg mt-4">
      <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-chevron-circle-right fa-1x" aria-hidden="true"></i> Definitions</a>
      <button type="button" class="btn btn-outline-light btn-lg" (click)="legends.showLegends(1)"><i class="fa fa-window-restore"></i> {{legends.$showLegendsTxt[1]}}</button>
    </nav>
    <h2 class="mt-4" *ngIf="legends.$showLegends[1]">Icons Legends</h2>
      <div class="bs-component mt-4" *ngIf="legends.$showLegends[1]">
        <span class="badge badge-pill badge-primary fs-6"><i class="fa fa-exchange" aria-hidden="true"></i><b> (Store Credit) </b> - A store credit is a document offered by a store to a customer who returns an item not eligble for a refund.</span>
        <span class="badge badge-pill badge-success fs-6 mt-4"><i class="fa fa-bank"></i> <b> (Account Payment)</b> - A sum of money paid into your invoice account by using account payment settings.</span>
        <span class="badge badge-pill badge-danger fs-6 mt-4"><i class="fa fa-close"></i><b> (Account Refunded)</b> - Account refunded is a repayment of funds from the original payee to the original payer.</span>
      </div>
  </div>
  
        <div class="col-12 mt-4">
          <h1 class="text-center text-primary mt-2 font-weight-bold">
              <i class="fas fa-file-invoice "></i>
              <ng-container *ngIf="user.typeOfService; else showInvoiceTitle ">
                Billing Accounts
              </ng-container>
              <ng-template #showInvoiceTitle>
                List of Invoices
              </ng-template>
          </h1>
        </div>
      <div class="bs-component mt-4">
        <nav class="navbar navbar-expand-lg navbar-dark bg-primary rounded ">
          <div class="container-fluid">
            <a class="navbar-brand"><i class="fas fa-search fa-lg"></i></a>
            <a class="navbar-brand text-white font-weight-bold" > Search Features: </a>
            <div class="form-group mt-3 ms-4">
              <select class="form-select" (change)="onChangeArchive($event)">
                <option value="1">Search all invoices non-archive</option>
                <option value="2">Search all invoices with archive</option>
              </select>
            </div>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarColor01">
              <ul class="navbar-nav me-auto">
                <li class="nav-item">
                
                </li>
              </ul>
              <a class="navbar-brand text-white font-weight-bold" > Search: </a>
              <form class="d-flex">
                <input class="form-control me-sm-2" type="search" placeholder="Search" (keyup)="applyFilter($event)">
              </form>
            </div>
          </div>
        </nav>
      </div>


  <div class="mat-elevation-z8 mt-4" *ngIf="(invoiceService.invoices$ | async) !== null">
    <table mat-table
          [dataSource]="invoiceService.invoiceDataSource " multiTemplateDataRows
          class="mat-elevation-z8">

      <!-- Invoice Number Column -->
      <ng-container matColumnDef="InvoiceNumber">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="user.typeOfService; else showInvoiceNo ">
            Billing No.
          </ng-container>
          <ng-template #showInvoiceNo>
            Invoice No.
          </ng-template>
        </th>
        <td mat-cell *matCellDef="let account"> 
          <div class="shadow-none p-3 mb-5 bg-white rounded"><b>{{account.invoiceNumber}}</b></div>
        </td>
      </ng-container>

      <!-- Invoice Amount Column -->
      <ng-container matColumnDef="InvoiceAmount">
        <th mat-header-cell *matHeaderCellDef>Invoice Amount </th>
        <td mat-cell *matCellDef="let account"> 
          <div class="shadow-none p-3 mb-5 bg-white rounded">{{account.invoiceAmount | currency }}</div>  
        </td>
      </ng-container>

      <!-- Payment Column -->
      <ng-container matColumnDef="Instalment">
        <th mat-header-cell *matHeaderCellDef class="text-center"> Instalments </th>
        <td mat-cell *matCellDef="let account"> 
            <div class="shadow-none p-3 mb-5 bg-white rounded">
              <ng-container *ngIf="account.payInstalment else showNoInstalments">
                <span class="badge bg-warning fs-6">Yes</span>
              </ng-container>
              <ng-template #showNoInstalments>
                <span class="badge bg-success fs-6">No</span>
              </ng-template>
            </div>     
        </td>
      </ng-container>
      <!-- Payment Column -->
      <ng-container matColumnDef="AddFees">
        <th mat-header-cell *matHeaderCellDef class="text-center"> Add Fees </th>
        <td mat-cell *matCellDef="let account"> 
            <div class="shadow-none p-3 mb-5 bg-white rounded">
              <ng-container *ngIf="account.addFeesDomestic else showNoFees">
                <span class="badge bg-success fs-6">Yes</span>
              </ng-container>
              <ng-template #showNoFees>
                <span class="badge bg-warning fs-6">No</span>
              </ng-template>
            </div>     
        </td>
      </ng-container>

      <!-- Payment Column -->
      <ng-container matColumnDef="Payments">
      <th mat-header-cell *matHeaderCellDef class="text-center"> Total Payments </th>
      <td mat-cell *matCellDef="let account"> 
        <div class="shadow-none p-3 mb-5 bg-white rounded">
          <span *ngIf="sumTotalPayments(account) == 'Account Paid'" class="badge bg-success fs-6">Paid</span>
          <span *ngIf="sumTotalPayments(account) == 'Special'" class="badge bg-warning fs-6">Special</span>
          <span *ngIf="sumTotalPayments(account) == 'Not Paid'" class="badge bg-danger fs-6">Not Paid</span>
        </div>     
      </td>
    </ng-container>

      <!-- Inital Date Column -->
      <ng-container matColumnDef="InitialDate">
        <th mat-header-cell *matHeaderCellDef class="text-center"> Initial Date</th>
        <td mat-cell *matCellDef="let account"> 
          <div class="shadow-none p-3 mb-5 bg-white rounded text-center">{{account.initialDate | date: 'mediumDate' }}
          </div>
        </td>
      </ng-container>

      <!-- Due Date Column -->
      <ng-container matColumnDef="DueDate">
        <th mat-header-cell *matHeaderCellDef class="text-center"> Due Date</th>
        
        <td mat-cell *matCellDef="let account">
          <div class="shadow-none p-3 mb-5 bg-white rounded text-center">{{account.dueDate | date: 'mediumDate' }}</div> 
        </td>
      </ng-container>

      <!-- Message Initial Sent Column -->
      <ng-container matColumnDef="MessageInitialSent">
        <th mat-header-cell *matHeaderCellDef class="text-left">(Message Initial)</th>
        <td mat-cell *matCellDef="let account">
          <div class="shadow-none p-3 mb-5 bg-white rounded">

            <button *ngIf="account.sendInitialMessage === 0" class="btn btn-outline-primary" (click)="sendManualMessage(account.id)"><i class="fa fa-share"></i> Send Message</button>   
            <p *ngIf="account.sendInitialMessage === 1"><i class="fa fa-check" style="font-size:24px;color:green"></i></p>
            <p class="font-weight-bold text-danger" *ngIf="account.sendInitialMessage === 2"><i class="fa fa-remove" style="font-size:24px;color:red"></i> Unavailable</p>
            <p *ngIf="account.sendInitialMessage === 3" class="text-danger font-weight-bold">Message Not Setup</p>
            <p *ngIf="account.sendInitialMessage === 4" class="text-warning font-weight-bold">No Email to Send!</p>
            <p *ngIf="account.hasDueDateMessageSent === 6" class="text-danger font-weight-bold">Can't be sent when you have instalments</p>
            <p class="font-weight-bold text-success" *ngIf="account.sendInitialMessage === 5"><i class="fa fa-money" style="font-size:24px;color:green"></i> PAID - <small>(No Message sent!)</small></p>
        </div>  
        </td>
      </ng-container>

      <!-- Message Due Date Sent Column -->
      <ng-container matColumnDef="MessageDueDateSent">
        <th mat-header-cell *matHeaderCellDef>(Message Due Date) </th>
        <td mat-cell *matCellDef="let account"> 
          <div class="shadow-none p-3 mb-5 bg-white rounded">
            <p *ngIf="account.hasDueDateMessageSent === 0"><i class="fa fa-remove" style="font-size:24px;color:red"></i></p>
            <p *ngIf="account.hasDueDateMessageSent === 1"><i class="fa fa-check" style="font-size:24px;color:green"></i></p>
            <p class="font-weight-bold text-danger" *ngIf="account.hasDueDateMessageSent === 2" class="text-danger font-weight-bold">Unavailable</p>
            <p *ngIf="account.hasDueDateMessageSent === 3" class="text-danger font-weight-bold">Message Not Setup</p>
            <p *ngIf="account.hasDueDateMessageSent === 4" class="text-warning font-weight-bold">No Email to Send!</p>
            <p *ngIf="account.hasDueDateMessageSent === 6" class="text-danger font-weight-bold">Can't be sent when you have instalments</p>
            <p class="font-weight-bold text-success" *ngIf="account.hasDueDateMessageSent === 5"><i class="fa fa-money" style="font-size:24px;color:green"></i> PAID - <small>(No Message sent!)</small></p>
        </div>
        </td>
      </ng-container>

      <!-- Message Receive Sent Column -->
      <ng-container matColumnDef="MessageReceiveSent">
        <th mat-header-cell *matHeaderCellDef class="text-center">(Message Receive) </th>
        <td mat-cell *matCellDef="let account">
          <div class="shadow-none p-3 mb-5 bg-white rounded">
            <p *ngIf="account.hasReceiveMessageSent === 0"><i class="fa fa-remove" style="font-size:24px;color:red"></i></p>
            <p *ngIf="account.hasReceiveMessageSent === 1"><i class="fa fa-check" style="font-size:24px;color:green"></i></p>
            <p class="font-weight-bold text-danger" *ngIf="account.hasReceiveMessageSent === 2" class="text-danger font-weight-bold">Unavailable</p>
            <p *ngIf="account.hasReceiveMessageSent === 3" class="text-danger font-weight-bold">Message Not Setup</p>
            <p *ngIf="account.hasReceiveMessageSent === 4" class="text-warning font-weight-bold">No Email to Send!</p>
            <p *ngIf="account.hasDueDateMessageSent === 6" class="text-danger font-weight-bold">Can't be sent when you have instalments</p>
            <p class="font-weight-bold text-success" *ngIf="account.hasReceiveMessageSent === 5"><i class="fa fa-money" style="font-size:24px;color:green"></i> PAID</p>
        </div>
        </td>
      </ng-container>

      
      <!-- Actions Column -->
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef class="text-center" style="size: 20px;">  </th>
        <td mat-cell *matCellDef="let account">
          <div class="shadow-none p-3 mb-5 bg-white rounded">
            <button mat-icon-button color="primary" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" 
            (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="['/members/printinvoice', account.id]">
                <mat-icon color="primary">print</mat-icon>
                <span>Print Account</span>
              </button>  
              <button mat-menu-item (click)="openInstalmentAccount(account.id, account.invoiceAmount, account.paidAmount)">
                <mat-icon color="primary">payment</mat-icon>
                <span>Open Instalment Accounts</span>
              </button>  
              <button mat-menu-item (click)="openPaymentAccount(account.id, account.invoiceAmount)">
                <mat-icon color="primary">folder_open </mat-icon>
                <span>Open Payment Accounts</span>
              </button>
              <button mat-menu-item  (click)="editInvoice(account.id)">
                <mat-icon color="primary">account_box</mat-icon>
                <span>Edit Invoice Account</span>
              </button>
              <button mat-menu-item  (click)="resetInitialMessage(account.id)">               
                <mat-icon color="warn">local_post_office</mat-icon>
                <span>Reset Initial Message</span>
              </button>
              <button mat-menu-item  (click)="archiveInvoice(account.id, account.clientID, account.archive)">               
                <mat-icon color="warn">delete</mat-icon>
                <span>Archive Invoice Account</span>
              </button>
              <button mat-menu-item  (click)="deleteInvoice(account.id, account.clientID)">
                <mat-icon color="warn">delete</mat-icon>
                <span>Delete Invoice Account</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>


      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let account" [attr.colspan]="columnsToDisplay.length">
          <div class="example-element-detail"
              [@detailExpand]="account == expandedElement ? 'expanded' : 'collapsed'">
              <div class="row">
                <div class="page-header">
                  <div class="p-3 mb-3 bg-dark rounded text-white font-weight-bold h5">Payments Details</div>
                </div>
                <div class="col-md-8 col-md-8 col-sm-12">
                  <div class="example-element-description ms-4">
                    <div class="p-3 mb-5 bg-white rounded">
                      <!-- Show Receivables-->
                        <div class="bd-example">
                          <p class="font-weight-bold fs-5 text-primary">Details</p>
                          <table class="table table-bordered" >
                            <thead>
                              <tr>
                                <th scope="col">Type</th>
                                <th scope="col">Amount AUD</th>
                              </tr>
                            </thead>
                            <tbody>                          
                                <tr class="table-primary">
                                  <th scope="row"><i class="fa fa-exchange" aria-hidden="true"></i> Store Credit</th>
                                  <td><p class="fs-6">{{account.totalStoreCredit | currency }}</p></td>
                                </tr>
                                <tr class="table-success">
                                  <th scope="row"><i class="fa fa-bank"></i> Account Payments - Paid</th>
                                  <td><p class="fs-6">{{account.totalPaidAccounts + account.paidAmount | currency }}</p></td>                         
                                </tr>
                                <tr class="table-danger">
                                  <th scope="row"><i class="fa fa-close"></i> Account Refunded</th>
                                  <td><p class="fs-6">{{account.totalAccountRefunded | currency }} </p></td>                             
                                </tr>                              
                            </tbody>
                          </table>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <p class="font-weight-bold fs-5 text-primary mt-4">Comment:</p>
                  <div class="p-3 mb-5 bg-white rounded border">                 
                    <p>{{account.comment }}</p>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card text-black bg-white mb-3">
                    <div class="card-header text-center">Ability to send out Due Date Message?</div>
                    <div class="card-body">
                      <ng-container *ngIf="account.sendOutDueDateMessage; else showmanualnottosendingoutduedatemessage"> 
                        <h4 class="card-title text-center"><i class="fas fa-check text-success font-weight-bold"></i> (Yes, it has the ability to send out due date message)</h4>
                      </ng-container>
                      <ng-template #showmanualnottosendingoutduedatemessage>
                        <h4 class="card-title text-center"><i class="fas fa-ban text-danger font-weight-bold"></i> (No, it will not send out due date message)</h4>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card text-black bg-white mb-3">
                    <div class="card-header text-center">Did you add in transaction fees to this account?</div>
                    <div class="card-body">
                      <ng-container *ngIf="account.addFeesDomestic === 1;  else showmanualcannotaddfees">
                        <h4 class="card-title text-center"><i class="fas fa-check text-success font-weight-bold"></i> (Yes, the customer will be paying an extra fee on top)</h4>
                      </ng-container>
                      <ng-template #showmanualcannotaddfees>
                        <h4 class="card-title text-center"><i class="fas fa-ban text-danger font-weight-bold"></i> (No, you will be paying for the transaction fees)</h4>
                      </ng-template>       
                    </div>
                  </div>       
                </div>
                <hr style="height:5px;border:none;color:#000000;background-color:#000000;" class="shadow-lg">
              </div>
          </div>
        </td>
      </ng-container>

      

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
          class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

    </table>
  </div>
</ng-container>
<div class="mt-4"></div>
