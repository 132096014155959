
<div *ngIf="togglePolicy === false && toggleTerms === false">
    <app-termsandconditions></app-termsandconditions>
    <button class="btn btn-success mr-2 mt-2 btn-block btn-lg" (click)="confirmTermsandConditions()"><i class="fa fa-check"></i> I agree to the Terms and Conditions in place!</button>
    <button class="btn btn-danger mr-2 mt-2 mt-mb-0 btn-block btn-lg" (click)="cancel()"><i class="fa fa-times" aria-hidden="true"></i> I disagree!</button>
</div>
<div *ngIf="togglePolicy === false && toggleTerms === true">
    <app-policy></app-policy>
    <button class="btn btn-success mr-2 mt-2 btn-block btn-lg" (click)="confirmPolicies()"><i class="fa fa-check"></i> I agree with the Privacy Policy of Conditions</button>
    <button class="btn btn-danger mr-2 mt-2 mt-mb-0 btn-block btn-lg" (click)="cancel()"><i class="fa fa-times" aria-hidden="true"></i> I disagree!</button>
</div>
<form *ngIf="toggleTerms && togglePolicy" [formGroup]="registerForm" (ngSubmit)="registerForm.valid && register()" autocomplete="off">
    <h2 class="text-center text-primary"> Register Account</h2>
    <div *ngIf="userNameError" class="alert alert-danger" role="alert" >
        Email is already taken! Please choose another.
    </div>
    <ng-container *ngIf="validationErrors.length > 0">
        <div *ngFor="let error of validationErrors" class="alert alert-danger" role="alert" >
            {{error}}
        </div>
    </ng-container>
    <app-text-input [formControl]='registerForm.controls["username"]' [label]='"*Email"' [smalltext]='"We will never share your email with anyone else."' [type]='"email"'> </app-text-input>
    <app-text-input [formControl]='registerForm.controls["password"]' [label]='"*Password"' [smalltext]='""'  [type]='"password"'> </app-text-input>
    <app-text-input [formControl]='registerForm.controls["confirmpassword"]' [label]='"*Confirm Password"' [smalltext]='""'  [type]='"password"'> </app-text-input>
    <app-text-input [formControl]='registerForm.controls["firstname"]' [label]='"*First name"' [smalltext]='""'> </app-text-input>
    <app-text-input [formControl]='registerForm.controls["lastname"]' [label]='"*Last name"' [smalltext]='""'> </app-text-input>
    <app-text-input [formControl]='registerForm.controls["phone"]' [label]='"*Phone Contact"' [smalltext]='"For mobile contact just put down the whole 10 digits. For phone number, you need to put down area code if it is a telephone number. For example NSW area code is (02). Another example is: 02970444708"' [type]='"number"'> </app-text-input>
    <app-text-input [formControl]='registerForm.controls["bussinessname"]' [label]='"Business Name"' [smalltext]='""'> </app-text-input>
    <app-text-input [formControl]='registerForm.controls["contactussupportphone"]' [label]='"Your Business Help Support Contact Number"' [smalltext]='"To obtain support for your business, please utilize the designated Business Support Phone Number. In the event that you dont possess one, kindly employ your regular contact number."'> </app-text-input>
    <app-text-input [formControl]='registerForm.controls["contactussupportemail"]' [label]='"Your Business Help Support For Emailing"' [smalltext]='"To obtain support for your business, please utilize the designated Business Support Email. In the event that you dont possess one, kindly employ your regular email account."' [type]='"email"'> </app-text-input>
    <app-text-input [formControl]='registerForm.controls["abn"]' [label]='"ABN"' [smalltext]='"Australia Business Number"' [type]='"number"'> </app-text-input>
    <app-text-input [formControl]='registerForm.controls["referal"]' [label]='"Referal"' [smalltext]='"A referal means an act of referring to a business/company that has pointed you where you are now."' [type]='""'> </app-text-input>
    <div class="form-group text-center">
        <button [disabled]="!registerForm.valid" class="btn btn-success mr-2" type="submit"><i class='fas fa-registered'></i> Register</button>
        <button class="btn btn-default mr-2" (click)="cancel()">Cancel</button>
    </div>
</form>

