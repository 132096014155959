import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { HtmlfooterService } from 'src/app/_services/htmlfooter.service';
import { InvoiceService } from 'src/app/_services/invoice/invoice.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-printinvoice',
  templateUrl: './printinvoice.component.html',
  styleUrls: ['./printinvoice.component.css']
})
export class PrintinvoiceComponent implements OnInit, OnDestroy {

  constructor(private htmlFooter: HtmlfooterService, public invoiceService: InvoiceService, private route: ActivatedRoute, private location: Location) { }
  accountID: string;
  title: string = "Tax Invoice";
  error: boolean = false;
  widthBody: string = "700px";  
  private invoicePrint = new ReplaySubject<any>(1);
  invoicePrint$ = this.invoicePrint.asObservable();
  hideButton: boolean = false;
  ngOnDestroy(): void {
    this.htmlFooter.showFooter.next(true);
  }

  ngOnInit(): void {
    window.scrollTo(0, document.body.scrollHeight);
    this.invoicePrint.next(null);
    this.showDefault();
    this.getParrams();
    this.onLoad();
    this.hideButton = true;
  }

  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.accountID = params.get("id");
    });
  }

  showDefault(){
    this.htmlFooter.showFooter.next(false);
  }

  onLoad(){
    this.invoiceService.getPrintInvoice(this.accountID).subscribe(response => {
      console.log(response);
      if (response === null)
      {
        this.invoicePrint.next(null);
        this.error = true;
        return;
      }

      if (response.tax <= 0)
      {
        this.title = "Invoice";
      }
      this.invoicePrint.next(response);
  
    }, err => {
      console.log(err);
    });
  }

  backClicked() {
    this.location.back();
  }

  printInvoice(){
    this.hideButton = true;
    window.print();
  }

}
