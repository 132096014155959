import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacypolicyfeatures',
  templateUrl: './privacypolicyfeatures.component.html',
  styleUrls: ['./privacypolicyfeatures.component.css']
})
export class PrivacypolicyfeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
