<nav class="navbar navbar-light bg-primary shadow-lg">
  <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Edit Instalment Account</a> 
</nav>
<mat-dialog-content>
<form #editForm="ngForm" id="editForm" [formGroup]="formInstalment" (ngSubmit)="formInstalment.valid" autocomplete="off">
  <div class="bs-component">
    <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
      <button type="button" class="close" data-dismiss="alert">×</button>
      <h4 class="alert-heading">Information!</h4>
      <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
      <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> Payment Details:</h4>
      <app-text-input [formControl]='formInstalment.controls["costamount"]' [label]='"*Cost Amount"' [smalltext]='""' [type]='"number"' [placeholder]='"Cost Amount"'>  </app-text-input>
      <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> Description Details:</h4>
      <app-area-text [formControl]='formInstalment.controls["note"]' [label]='"Note"' [smalltext]='""' [placeholder]='"Note"'></app-area-text>
    </div>    
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
      <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> Dates:</h4>          
      <app-date-input [formControl]='formInstalment.controls["duedate"]' [label]='"*Due Date"' [smalltext]='"Due Date of Payment"' [placeholder]='"Due Date"'> </app-date-input>  
      <fieldset class="form-group">
        <div class="card border-secondary mb-3">
          <div class="card-body">
            <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice "></i> Add transaction fees:</h4>
            <label class="form-check-label"></label>
            <div class="form-check">
              <label class="form-check-label ">
                <input type="radio" class="form-check-input" name="addfees" value="Yes" checked=""  [formControl]='formInstalment.controls["addfees"]'>
                <b class="text-success">Yes</b>: Add in 1.75% + A$0.30 to your customer instalment billing account. 
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input type="radio" class="form-check-input" name="addfees"  value="No" [formControl]='formInstalment.controls["addfees"]'>
                <b class="text-danger">No</b>: My business will pay out the transaction fee. 
              </label>
            </div>
          </div>
        </div>                        
      </fieldset>
      <fieldset class="form-group">
        <div class="card border-secondary mb-3">
          <div class="card-body">
            <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice "></i> Can the Initial Payment be sent After First Payment:</h4>
            <label class="form-check-label"></label>
            <div class="form-check">
              <label class="form-check-label ">
                <input type="radio" class="form-check-input" name="sendinitialdateafter" value="Yes" checked=""  [formControl]='formInstalment.controls["sendinitialdateafter"]'>
                <b class="text-success">Yes</b>: The next payment made by your client to pay this instalment. Will receive a notification when the next instalment payment is due as well as getting due date notification.
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input type="radio" class="form-check-input" name="sendinitialdateafter"  value="No" [formControl]='formInstalment.controls["sendinitialdateafter"]'>
                <b class="text-danger">No</b>: The next payment made by your client to pay this instalment. Will only receive due date notification when the next instalment is due.
              </label>
            </div>
          </div>
        </div>                        
      </fieldset>
    </div>  
  </div>
</form> 
<div class="row">
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
      <button [disabled]="!formInstalment.valid" mat-button [mat-dialog-close]="data" class="btn btn-success btn-block btn-lg" form="editForm" (click)="onSubmit()" ><i class='fas fa-check'></i>  Confirm Edit Account</button>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
      <button class="btn btn-danger btn-block btn-lg mt-4 mt-md-0" mat-button mat-dialog-close><i class='fas fa-close'></i>  Cancel</button>
  </div>
</div>
</mat-dialog-content>