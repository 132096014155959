import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgControlStatusGroup, NgForm, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import { EmailRemoveGMT } from 'src/app/_helper/emailremovegmt';
import { InvoiceItems } from 'src/app/_models/createinvoiceitems';
import { Invoice } from 'src/app/_models/invoice';
import { Pagination } from 'src/app/_models/pagination';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { ClientService } from 'src/app/_services/client/client.service';
import { InvoiceService } from 'src/app/_services/invoice/invoice.service';
import { PayablesreceivablesService } from 'src/app/_services/payablesreceivablesaccounts/payablesreceivables.service';
import { TogglewindowService } from 'src/app/_services/togglewindow.service';
import { FullStopValidator } from 'src/app/_validators/fullstopvalidator';
import { InvoiceValidator } from 'src/app/_validators/invoicevalidator';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator';

@Component({
  selector: 'app-createinvoice',
  templateUrl: './createinvoice.component.html',
  styleUrls: ['./createinvoice.component.css']
})
export class CreateinvoiceComponent implements OnInit, OnDestroy {
  @ViewChild('editForm') editForm: NgForm;  
  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any){
    if (this.editForm.dirty){
      $event.returnValue = true;
    }
  }

  accountInvoice: any[];
  invoiceModel: Invoice;
  pagination: Pagination;
  pageNumber = 1;
  pageSize = 5;
  formInvoice: UntypedFormGroup;
  formItems: UntypedFormGroup[] = [];
  invoiceItems: InvoiceItems[] = [];
  clientID: any;
  showInvalidDate: boolean;
  showCreateForm: boolean = false;
  customCreateInvoieSubTotal: number = 0;
  customCreateInvoiceGST: number = 0;
  customCreateInvoiceTotalAmount: number = 0;
  customCreateInvoiceTaxInclude: boolean = true;
  customCreateInvoiceIsValid: boolean = true;
  
  
  errorsServer: Boolean = false;
  user?: User;

  constructor(public fb: UntypedFormBuilder, public service: InvoiceService, private route: ActivatedRoute,
    private toastr: ToastrService, private router:
     Router, public toggle: TogglewindowService, public accountService: AccountService
     , private payablesreceivables: PayablesreceivablesService, private clientService: ClientService ) { 
   
    }
    
  ngOnDestroy(): void {
    this.cancelCanEditClient(this.clientID);
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getParrams();
    this.assignUser();
    this.initializeForm();
    this.loadInvoiceNumbers();
    this.OnStartLoadInvoiceNumber();
    this.initializeItemsForm('','','','');
    this.showInvalidDate = false;
    this.showCreateForm = false;
    this.customCreateInvoiceTaxInclude = true;
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  initializeItemsForm(description: string, qty: string, unitprice: string, amount: string){
    let formItems: UntypedFormGroup;
    formItems = this.fb.group
    ({
      description:[description, [Validators.required, WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      qty:[qty, [Validators.required, Validators.min(1), Validators.max(99999)]],
      unitprice:[unitprice, [Validators.required, Validators.min(0.01), Validators.max(99999999999)]],
      amountaud:[amount]
    });

    this.formItems.push(formItems);
  }

  initializeForm(){
    this.formInvoice = this.fb.group({
      invoicenumber:[this.service.invoiceNumberPlaceHolder, [Validators.required, Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      invoiceamount: ['', [Validators.required, Validators.min(0.01), Validators.max(999999999999999)]],
      paymentamount: ['0', [Validators.required, Validators.min(0), Validators.max(999999999999999), InvoiceValidator.matchInvoiceToAmountPaid('invoiceamount')]],
      initaldate: ['', [Validators.required]],
      duedate: ['', [Validators.required]],
      comment: ['', [Validators.maxLength(1000), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      optionSendInitialInvoice: ['', Validators.required],
      addfees: ['', Validators.required],
      sendOutDueDateMessage: ['', Validators.required],
    })
  }

  createInvoiceAccount()
  {
    this.initialModelInvoice();
    this.service.createinvoice(this.invoiceModel).subscribe(response => {     
      this.toastr.success("Successfully created account!");
      this.resetform();
      this.service.stopHubConnection();
      this.service.createHubConnection(this.user, +this.invoiceModel.clientID);
      this.payablesreceivables.stopHubConnection();
      this.payablesreceivables.createHubConnection(this.user, true); 
      const newInvoiceNumber: string = this.service.RemoveCharactersInInvoiceNumber(response.invoiceNumber);
      this.service.invoiceNumberPlaceHolder = newInvoiceNumber;
      this.loadInvoiceNumbers();
    },error => {
     console.log(error);
      this.errorsServer = true;
      this.service.stopHubConnection();
    })
  }

  createCustomInvouceAccount(){
    const newvoice = this.customIntialModelInvoice();     
    this.service.createCustominvoice(newvoice).subscribe(response => {
      this.toastr.success("Successfully created account!");
      this.resetform();
      this.service.stopHubConnection();
      this.service.createHubConnection(this.user, +this.invoiceModel.clientID);
      this.payablesreceivables.stopHubConnection();
      this.payablesreceivables.createHubConnection(this.user, true);   
      const newInvoiceNumber: string = this.service.RemoveCharactersInInvoiceNumber(response.invoiceNumber);
      this.service.invoiceNumberPlaceHolder = newInvoiceNumber;
      this.loadInvoiceNumbers();
    },error => {
     console.log(error);
      this.errorsServer = true;
      this.service.stopHubConnection();
    })    
  }
    

  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.clientID = params.get("id");
    });
  }

  initialModelInvoice(){
    const option = this.formInvoice.value.optionSendInitialInvoice;
    const payfees = this.formInvoice.value.addfees;
    const addDueDateMessage = this.formInvoice.value.sendOutDueDateMessage;

    const option1 = this.getOptionValue(option);
    const payfees2 = this.getOptionValue(payfees);
    const addDueDateMessge2 = this.getOptionValue(addDueDateMessage);

    const initialDate = new Date(this.formInvoice.value.initaldate);
    const dueDate = new Date(this.formInvoice.value.duedate);
    

    this.invoiceModel = {
      invoiceNumber: this.formInvoice.value.invoicenumber,
      invoiceAmount: this.formInvoice.value.invoiceamount,
      paidAmount: this.formInvoice.value.paymentamount,
      initialDate: initialDate,
      dueDate: dueDate,
      clientID: this.clientID,
      comment: this.formInvoice.value.comment,    
      sendInitialMessage: option1,
      hasDueDateMessageSent: 0,
      hasReceiveMessageSent: 0, 
      sendOutDueDateMessage: addDueDateMessge2, 
      addFeesDomestic: payfees2,
      dueDateS: dueDate.toUTCString(),
      initialDateS: initialDate.toUTCString()
    }    
  }


  private customIntialModelInvoice() {
    const option = this.formInvoice.value.optionSendInitialInvoice;
    const payfees = this.formInvoice.value.addfees;
    const addDueDateMessage = this.formInvoice.value.sendOutDueDateMessage;

    const option1 = this.getOptionValue(option);
    const payfees2 = this.getOptionValue(payfees);
    const addDueDateMessge2 = this.getOptionValue(addDueDateMessage);

    const initialDate = new Date(this.formInvoice.value.initaldate);
    const dueDate = new Date(this.formInvoice.value.duedate);
    

    this.invoiceModel = {
      invoiceNumber: this.formInvoice.value.invoicenumber,
      invoiceAmount: this.formInvoice.value.invoiceamount,
      paidAmount: this.formInvoice.value.paymentamount,
      initialDate: initialDate,
      dueDate: dueDate,
      clientID: this.clientID,
      comment: this.formInvoice.value.comment,    
      sendInitialMessage: option1,
      hasDueDateMessageSent: 0,
      hasReceiveMessageSent: 0, 
      sendOutDueDateMessage: addDueDateMessge2, 
      addFeesDomestic: payfees2,
      dueDateS: dueDate.toUTCString(),
      initialDateS: initialDate.toUTCString()
    }  

    let invoiceItems: InvoiceItems[] = [];
    this.formItems.forEach(item => {
      const newItem: InvoiceItems = {
        description: item.controls['description'].value,
        qty: +item.controls['qty'].value,
        unitPrice: +item.controls['unitprice'].value,         
      };

      invoiceItems.push(newItem);
    });

    const customInvoice = {
      invoiceNumber: this.formInvoice.value.invoicenumber,
      invoiceAmount: this.formInvoice.value.invoiceamount,
      paidAmount: this.formInvoice.value.paymentamount,
      initialDate: initialDate,
      dueDate: dueDate,
      clientID: this.clientID,
      comment: this.formInvoice.value.comment,    
      sendInitialMessage: option1,
      hasDueDateMessageSent: 0,
      hasReceiveMessageSent: 0, 
      sendOutDueDateMessage: addDueDateMessge2, 
      addFeesDomestic: payfees2,
      dueDateS: dueDate.toUTCString(),
      initialDateS: initialDate.toUTCString(),
      isItGST: this.customCreateInvoiceTaxInclude,
      invoiceItemDtos: invoiceItems
    }

    return customInvoice;
  }

  resetform(){
    this.service.getinvoices(this.clientID).subscribe(
      response => {      
        this.service.invoiceDataSource = new MatTableDataSource<Invoice>(response);
        this.service.invoiceDataSource.paginator = this.service.paginator;
        this.service.invoiceDataSource._updateChangeSubscription();
        this.initializeForm();
        this.formItems = [];
        this.invoiceItems = [];
        this.initializeItemsForm('','','','');
      }, error => {
        console.log(error);
      }
    )
    this.initializeForm();
  }


  uploadFile(){
    this.router.navigateByUrl('/members/importfileinvoice');
  }

  uploadAPI(){
    this.router.navigateByUrl('/members/importpage');
  }

  onChangeEventInvoiceNumber(){
    var status = this.formInvoice.controls['invoicenumber'].value;
    console.log(status);
    this.service.invoicesNumber$.subscribe(response => {
      var isSame = response.find(i => i.invoiceNumber === status);
      if (isSame !== undefined)
      {
        this.formInvoice.controls['invoicenumber'].setErrors({isMatchingInvoice: true});
      }
    }, err => {
      console.log(err);
    });
  }

  getOptionValue(value: string)
  {
    var result: number = 0;
    if (value == "Yes"){
      result = 1;
    } 
    return result;
  }

  onChangeDueDate(){
    if (this.formInvoice.value.duedate === "" || this.formInvoice.value.dueDate === "Invalid date")
    {
      return;
    }
    const dueDate: Date = this.formInvoice.value.duedate;
    const initalDate: Date = this.formInvoice.value.initaldate;

    if (initalDate > dueDate)
    {
      this.showInvalidDate = true;
      return;
    }    
    this.showInvalidDate = false;
  }

  getTypeService(typeService: boolean): string{
    return (typeService) ? "Invoice" : "Billing";
  }

  private loadInvoiceNumbers(){
    this.service.stopInvoiceNumberConnection();
    this.service.createInvoiceNumberHubConnection(this.user);
  }

  private OnStartLoadInvoiceNumber(){
    this.service.onLoadStartUpGetLastInvoiceNumber().subscribe(response => {
      if (response === null)
        return;

      if (response.invoiceNumber === null)
        return;

      if (response.invoiceNumber === '')
        return;

      const lastInvoice: string = this.service.RemoveCharactersInInvoiceNumber(response.invoiceNumber);
      this.formInvoice.controls['invoicenumber'].setValue(lastInvoice);
    }, err => {
      console.log(err);
    })
  }

  private cancelCanEditClient(id: number){
    this.clientService.cancelCanEditClient(id).subscribe(response => {
     }, err => {
       console.log(err);
     })
  }

  onChangeFormToggle(value: any) { 
    this.formInvoice.controls['invoiceamount'].setValue('0');
    if (value.target.value === "true")
    {
      this.showCreateForm = true;
      return;
    }
    
    this.formInvoice.controls['invoiceamount'].setValue(this.customCreateInvoiceTotalAmount.toFixed(2).toString());
    this.showCreateForm = false;
  } 

  addItemForm(): void {
    const description: string = this.formItems[0].value.description;
    const qty: number = +this.formItems[0].value.qty;
    const unitprice: number = +this.formItems[0].value.unitprice;
    const amount: string =this.formItems[0].value.amountaud;

    const invoiceItems: InvoiceItems = {
      description: description,
      qty: qty,
      unitPrice: unitprice,
      amountaud: +amount
    };

    const resetForm = this.fb.group
    ({
      description:['', Validators.required],
      qty:['', [Validators.required, Validators.min(1)]],
      unitprice:['', [Validators.required, Validators.min(1)]],
      amountaud:['']
    });

    this.formItems[0] = resetForm;     
    this.invoiceItems.push(invoiceItems); 
    this.initializeItemsForm(description, qty.toString(), unitprice.toString(), amount); 
    this.checkItemFormsForInvoiceHasIsInValid();
    this.formItems[0].markAllAsTouched;
  }


  deleteItem(formIndex: number, itemsIndex: number): void {
    this.formItems.splice(formIndex, 1);
    this.invoiceItems.splice(itemsIndex, 1);
    this.addCustomMadeInvoiceTotal(); 
  }

  addAmountAUD(index: number): void {
    const qty: string = this.formItems[index].value.qty;
    const unitprice: string = this.formItems[index].value.unitprice;
    this.checkItemFormsForInvoiceHasIsInValid();

    if (isNaN(+qty))
      return;

    if (isNaN(+unitprice))
      return;

    if (+qty < 1 || +unitprice < 0.01)
      return;

    const total: string = (+qty * +unitprice).toFixed(2).toString();
    this.formItems[index].controls['amountaud'].setValue(total);
    this.addCustomMadeInvoiceTotal();
  }  

  private addCustomMadeInvoiceTotal(): void{
    let total: number = 0;
    this.formItems.forEach(item => {
      const qty = item.controls['qty'].value;
      const unitprice = item.controls['unitprice'].value;
      total += qty * unitprice;
    });    
    
    this.customCreateInvoieSubTotal = total;
    this.customCreateInvoiceGST = this.getTotalGST();
    this.customCreateInvoiceTotalAmount = this.customCreateInvoieSubTotal + this.customCreateInvoiceGST;
    this.formInvoice.controls['invoiceamount'].setValue(this.customCreateInvoiceTotalAmount.toFixed(2).toString());
  }

  toggleTypeOfTax(value: any): void {
    if (value.target.value === "1")    
      this.customCreateInvoiceTaxInclude = true;    
    else  
      this.customCreateInvoiceTaxInclude = false;    
    this.addCustomMadeInvoiceTotal();
    this.checkItemFormsForInvoiceHasIsInValid();
  }

  private getTotalGST(): number {
    return this.customCreateInvoiceTaxInclude ? (this.customCreateInvoieSubTotal * 1.1) - this.customCreateInvoieSubTotal : 0;
  }

  checkItemFormsForInvoiceHasIsInValid(): void {
    const isInvalid = this.formItems.find(f => f.status === "INVALID");
    console.log(isInvalid);
    if (isInvalid === undefined)
     this.customCreateInvoiceIsValid = false;
    else
    this.customCreateInvoiceIsValid = true;
  }

  revertLastActionForCreateNewItem(): void {
    const lastFormItem: UntypedFormGroup = this.formItems[this.formItems.length - 1];
    this.invoiceItems.splice(-1);
    this.formItems.splice(-1);
    this.formItems[0] = lastFormItem;
    this.checkItemFormsForInvoiceHasIsInValid();
  }
}

