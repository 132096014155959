import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { Client } from 'src/app/_models/client';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { ClientService } from 'src/app/_services/client/client.service'; 
import { PayablesreceivablesService } from 'src/app/_services/payablesreceivablesaccounts/payablesreceivables.service';
import { TogglewindowService } from 'src/app/_services/togglewindow.service';
import { ABNValidation } from 'src/app/_validators/abnvalidaton';
import { FullStopValidator } from 'src/app/_validators/fullstopvalidator';
import { NumberericValidation } from 'src/app/_validators/numbericvalidation';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.css']
})
export class CreateClientComponent implements OnInit {
  @ViewChild('editForm') editForm: NgForm;  
  @Output() resetForm = new EventEmitter();
  errorsServer: Boolean = false;
  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any){
    if (this.editForm.dirty){
      $event.returnValue = true;
    }
  }

  formClient: UntypedFormGroup;
  client: Client;
  test: string;
  sortOrder: [] = [];
  clientExist: boolean = false;
  emailChecked: boolean = false;
  user?: User;
  showStatus: boolean;
  showErrorClientStripeAccount: boolean;

  constructor(private toastr: ToastrService,
    public fb: UntypedFormBuilder, public clientService: ClientService,
     private router: Router, public toggle: TogglewindowService, private accountService: AccountService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.showStatus = false;
    this.showErrorClientStripeAccount = false;
    this.assignUser();
    this.initializeForm();
    this.clientExist = false;   
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  initializeForm(){
    this.formClient = this.fb.group({
      email:['', [Validators.email, Validators.maxLength(255)]],
      name: ['', [Validators.required, Validators.minLength(2) ,Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      firstname: ['', [Validators.minLength(2) ,Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpace]],
      lastname: ['', [Validators.minLength(2),Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpace]],
      middlename: ['', [Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpace] ],
      contacthomephone: ['', [Validators.minLength(8), Validators.maxLength(10), WhiteSpacesValidator.cannotContainSpace, FullStopValidator.cannotContainFullStop]],
      contactmobile: ['', [Validators.minLength(10), Validators.maxLength(10), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter, FullStopValidator.cannotContainFullStop]],
      contactbussinessnumber: ['', [Validators.minLength(8), Validators.maxLength(10), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter, FullStopValidator.cannotContainFullStop]],
      bussinessname: ['', [Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      abn: ['', [Validators.minLength(11), Validators.maxLength(11), WhiteSpacesValidator.cannotContainSpace, ABNValidation.checkABNValidation, FullStopValidator.cannotContainFullStop]],
      address: ['', [Validators.maxLength(1000), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      addressunit: ['', [Validators.maxLength(1000), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      postcode: ['', [Validators.maxLength(1000), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      province: ['', [Validators.maxLength(1000), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      state: ['', [Validators.maxLength(1000), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      country: ['', [Validators.maxLength(1000), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      creditRatingApproved: ['true', Validators.required],

    })
  }

  createClientAccount()
  {
    this.setClientValues();
    console.log(this.client);
    this.clientService.createClient(this.client).subscribe(response => {
      this.toastr.success("Successfully created Client account!");
      this.resetform(response);
      this.clientService.stopHubConnection();   
      this.clientService.createHubConnection(this.user);   
    }, error => {
      console.log(error);
      if (error.error === "Client Account Already Exist!")
      {
        this.clientExist = true;
      }

      if (error.error === "You can't create client! You will need to contact Invoicepager to setup your Stripe Account.")
      {
        this.showErrorClientStripeAccount = true;
      }
    });   
  }

  setClientValues(){
    var abn = this.formClient.value.abn;
    if (this.formClient.value.abn == "")
    {
      abn = 0;
    }

    this.client = {
      name: this.formClient.value.name,
      firstName: this.formClient.value.firstname,
      middleName: this.formClient.value.middlename,
      lastName: this.formClient.value.lastname,
      bussinessName: this.formClient.value.bussinessname,
      contactBusiness: this.formClient.value.contactbussinessnumber,
      contactHomePhone: this.formClient.value.contacthomephone,
      contactMobile: this.formClient.value.contactmobile,
      email: this.formClient.value.email,
      abn: abn,
      address: this.formClient.value.address,
      addressUnit: this.formClient.value.addressunit,
      postCode: this.formClient.value.postcode,
      province: this.formClient.value.province,
      state: this.formClient.value.state,
      creditRatingApproved: this.formClient.value.creditRatingApproved,
      country: this.formClient.value.country,
    }
    if (this.formClient.value.abn == "")
    {
      this.client.abn = 0;
    }
  }

  resetform(client: Client){
    this.clientService.clientsDataSource.data.unshift(client);
    this.clientService.clientsDataSource._updateChangeSubscription();
    this.initializeForm();
  }

  uploadFile(){
    this.router.navigateByUrl('/members/importfileclient');
  }

  uploadAPI(){
    this.router.navigateByUrl('/members/importpage');
  }
}
