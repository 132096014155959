<app-nav></app-nav>
<div class="container">
    <div class="mt-4">
      <nav class="navbar navbar-light bg-primary shadow-lg mt-4">
        <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-cc-stripe fa-3x" aria-hidden="true"></i> Stripe Accounts Payments</a>
        <button type="button" class="btn btn-outline-light btn-lg mt-2 mt-md-0" (click)="toggle.toggleWindow(39)"><i class="{{toggle.$Icon[39]}}" aria-hidden="true\"></i> {{toggle.$ToggleText[39]}}</button>
      </nav>
    </div>
    <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded" [hidden]="!toggle.$Toggle[39]">
      <div class="card-body">
        <fieldset class="form-group">           
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
              <img src="/assets/images/profile-account.jpg" alt="profile account" class="img-thumbnail rounded center">
                <legend class="text-center fs-3">How to Use <b>Stripe Account</b></legend>
              <div class="form-group">                      
                <small id="fileHelp" class="form-text text-muted text-center fs-6">
                  Click the button below to learn more!
                </small>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
              <legend class="text-center fs-3 text-uppercase text-primary"><b>HOW TO USE THIS!</b></legend>
              <div class="embed-responsive embed-responsive-1by1 sizer shadow-lg border mt-4">
                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/lxFR8VecGtE?si=0HkYp4kWWYlQnuV_" allowfullscreen></iframe>
              </div>
            </div>
          </div>              
          <button type="submit" class="btn btn-outline-primary btn-lg btn-block mt-4" routerLink="/members/faqs"><i class="fas fa-info"></i> Click Here To Find Out More!</button>
        </fieldset>
      </div>
    </div>
      <div class="mt-2 d-none d-sm-block">
        <h2 class="fs-3">Icons Legends</h2>
        <hr>
        <div class="bs-component">
          <span class="badge badge-pill badge-primary fs-6"><i class="fa fa-exchange" aria-hidden="true"></i> Your payment is being processed via Stripe method and should arrive in your <b>Bussiess Transaction Account</b> in four bussiness days.</span>
          <span class="badge badge-pill badge-warning mt-2 fs-6"><i class="fa fa-check-square-o" aria-hidden="true"></i> Your payment account is ready to updated. All you need now is click on the "<b>Check & Update Transactions</b>" button to update the process.</span>
          <span class="badge badge-pill badge-success fs-6 mt-2"><i class="fa fa-bank"></i> Payment delivered is an account paid into your <b>Business Transaction Account</b></span>
          <span class="badge badge-pill badge-danger fs-6 ms-2"><i class="fa fa-close"></i> Payment Refunded to your client</span>
         </div>
       </div>
       <div class="alert alert-dismissible alert-info mt-2">
        <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
        <h2 class="alert-heading"> <i class="fa fa-cc-stripe fa-10x" aria-hidden="true"></i> General Information</h2>
        <p class="mb-0 fs-6">You might need to top up your stripe account credits to give your client refunded amount! 
            <a href="https://dashboard.stripe.com/login_success?redirect=%2F" class="alert-link">Please click here to login into your stripe account.</a></p>
       </div>
      <div class="mt-4">
        <ng-container *ngIf="services.showalltransactionsbtn">
          <h1 class="text-center text-primary mt-2 font-weight-bold">Show All Transactions Made By Stripe Accounts</h1>
          <div class="bs-component">
            <nav class="navbar navbar-expand-lg navbar-dark bg-primary rounded ">
              <div class="container-fluid">
                <a class="navbar-brand"><i class="fas fa-tools fa-lg"></i></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarColor01">
                  <ul class="navbar-nav me-auto">
                    <li class="nav-item">
                      <button class="btn btn-primary my-2 my-sm-0" (click)="showAlltransactions()">{{services.namebtnshowtransactions}}</button>
                    </li>
                  </ul>
                  <ng-container *ngIf="services.isitShowAllTransactionPage">
                    <form class="d-flex" >
                      <input class="form-control me-sm-2" type="search" placeholder="Search" >
                    </form>
                  </ng-container>             
                </div>
              </div>
            </nav>
          </div>
          <app-stripeaccountsalltransactions></app-stripeaccountsalltransactions>
        </ng-container>
      </div>
      <ng-container *ngIf="services.showalltransactionsbtn === false">
      <hr>
      <div class="mt-4">
        <h1 class="text-center text-primary mt-2 font-weight-bold">Stripe Payments</h1>
      </div>
      <div class="bs-component">
        <nav class="navbar navbar-expand-lg navbar-dark bg-primary rounded ">
          <div class="container-fluid">
            <a class="navbar-brand"><i class="fas fa-tools fa-lg"></i></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarColor01">
              <ul class="navbar-nav me-auto">
                <li class="nav-item">
                  <button class="btn btn-outline-light my-2 my-sm-0 font-weight-bold" (click)="showAlltransactions()">{{services.namebtnshowtransactions}}</button>
                </li>
              </ul>
              <ul class="navbar-nav me-auto">
                <li class="nav-item">
                  <button class="btn btn-success my-2 my-sm-0" (click)="updateTransactions()"><i class="fa fa-bank"></i> Check & Update Transactions</button>
                </li>
              </ul>
              <form class="d-flex">
                <input class="form-control me-sm-2" type="search" (keyup)="applyFilter($event)" placeholder="Search" >
              </form>
            </div>
          </div>
        </nav>
      </div>
    <div class="mat-elevation-z8 mt-4" *ngIf="services.stripeAccounts$ | async">
        <table mat-table [dataSource]="services.stripeDataSource$" matSort>
          <!-- Invoice Number Column -->
          <ng-container matColumnDef="invoiceNumber" *ngIf="accountService.currentUser$ | async as user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> 
              <ng-container *ngIf="user.typeOfService; else showInvoiceNo ">
                Billing No.
              </ng-container>
              <ng-template #showInvoiceNo>
                Invoice No.
              </ng-template>
            </th>
            <td mat-cell *matCellDef="let account"> <p class="font-weight-bold text-primary">{{account.invoiceNumber}}</p> </td>
          </ng-container>

          <!-- Name Column -->
         <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Full Name </th>
          <td mat-cell *matCellDef="let account"> <p>{{account.name | titlecase}}</p> </td>
         </ng-container>


          <!-- First Name Column -->
         <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
          <td mat-cell *matCellDef="let account"> <p>{{account.firstName | titlecase}}</p> </td>
         </ng-container>

           <!-- Last Name Column -->
           <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
            <td mat-cell *matCellDef="let account"> <p>{{account.lastName | titlecase}}</p> </td>
           </ng-container>

             <!-- Fees Number Column -->
             <ng-container matColumnDef="Fees">
              <th mat-header-cell *matHeaderCellDef> Charge Fees</th>
              <td mat-cell *matCellDef="let account" class="text-success font-weight-bold">  <p>{{account.chargeFeeAmount | currency }}</p>  </td>
           </ng-container>

              <!-- Amount Paid Column -->
              <ng-container matColumnDef="amountPaid">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount Paid </th>
                <td mat-cell *matCellDef="let account" class="text-success font-weight-bold">
                     <p>{{account.amountPaid | currency}}</p> 
                </td>
             </ng-container>

                <!-- Amount Paid Column -->
                <ng-container matColumnDef="amountRefunded">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount Refunded </th>
                    <ng-container *matCellDef="let account">
                        <td mat-cell *ngIf="account.amountRefund > 0; else elseBlockAccountRefund" class="text-danger font-weight-bold"> <p>{{account.amountRefund | currency}}</p> </td>
                        <ng-template #elseBlockAccountRefund><td mat-cell class="text-success font-weight-bold"> <p>{{account.amountRefund | currency}} </p> </td></ng-template>                  
                    </ng-container>                  
                 </ng-container>

                 <!-- Amount Paid Column -->
                <ng-container matColumnDef="datePaid">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Paid </th>
                    <td mat-cell *matCellDef="let account"> <p>{{account.datePaid | date:'medium'}}</p> </td>
                 </ng-container>

              <!-- ReadThisTransaction Number Column -->
             <ng-container matColumnDef="ReadThisTransaction">
              <th mat-header-cell *matHeaderCellDef> *Mark as Read</th>
              <td mat-cell *matCellDef="let account"> 
                <div>
                  <p class="h4">
                    <ng-container *ngIf="account.hasClientTickReadThisRecord; else ShowNotTick">
                      <span class="badge bg-success">Yes</span>
                    </ng-container> 
                    <ng-template #ShowNotTick>
                      <span class="badge bg-warning">No</span>
                    </ng-template> 
                  </p>     
                </div>                        
              </td>
             </ng-container>

                 
                 <!-- Amount Paid Column -->
                <ng-container matColumnDef="Status">
                    <th mat-header-cell *matHeaderCellDef class="text-center">Payment Status</th>
                    <ng-container *matCellDef="let account">
                             <ng-container *ngIf="account.dateStatus > currentDate && account.hasUpdatedStripeAccountAfterThreeDays === false && account.amountRefund === 0">                           
                                <td mat-cell class="text-primary font-weight-bold text-center"><p><i class="fa fa-exchange" aria-hidden="true"></i> Processing</p>  </td>         
                             </ng-container>
                             <ng-container *ngIf="currentDate > account.dateStatus && account.hasUpdatedStripeAccountAfterThreeDays === false && account.amountRefund === 0">                           
                              <td mat-cell class="text-warning font-weight-bold text-center"><p><i class="fa fa-check-square-o" aria-hidden="true"></i> Waiting for your status!</p></td>                              
                             </ng-container>
                             <ng-container *ngIf="account.hasUpdatedStripeAccountAfterThreeDays && account.amountRefund === 0">                           
                              <td mat-cell class="text-success font-weight-bold text-center"><p><i class="fa fa-exchange" aria-hidden="true"></i> Payment Delivered</p></td>                              
                             </ng-container>
                            <ng-container *ngIf="account.amountRefund !== 0">                           
                              <td mat-cell class="text-danger font-weight-bold text-center"><p><i class="fa fa-times" aria-hidden="true"></i> Account Refunded</p></td>                            
                            </ng-container>                                 
                            <ng-container *ngIf="account.hasAnyErrorsStripeProcess">                           
                              <td mat-cell class="text-danger font-weight-bold text-center"><p><i class="fa fa-times" aria-hidden="true"></i> Something has gone wrong! <b>Call Us.</b></p></td>                            
                            </ng-container>                                 
                    </ng-container>                   
                 </ng-container>

                   <!-- Amount Paid Column -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="text-center"></th>
                    <td mat-cell *matCellDef="let account"> 
                      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="ButtonUpdateStatusNotification(account.id)">
                          <mat-icon color="primary">autorenew</mat-icon>
                          <span>Change Status "Read This"</span>
                        </button>
                        <button mat-menu-item (click)="loadInvoicePage(account.clientID)">
                          <mat-icon color="accent">folder_open</mat-icon>
                          <span>Open Invoice Account</span>
                        </button>
                        <button mat-menu-item *ngIf="account.amountRefund < 1" (click)="refundAccount(account.id)" >
                          <mat-icon color="warn">delete_sweep</mat-icon>
                          <span class="text-danger">Refund Payment</span>
                        </button>
                      </mat-menu>                  
                    </td>
                 </ng-container>
          
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10000]" showFirstLastButtons></mat-paginator>
    </div>
    </ng-container>
    <div class="mt-3"></div>
