import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StripeaccountService } from 'src/app/_services/stripeaccount/stripeaccount.service';

@Component({
  selector: 'app-refundmessagestripeclient',
  templateUrl: './refundmessagestripeclient.component.html',
  styleUrls: ['./refundmessagestripeclient.component.css']
})
export class RefundmessagestripeclientComponent implements OnInit {

  accountID: number;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private services: StripeaccountService) { }

  ngOnInit(): void {
  }

  refundAccount(){
    const id: string = this.data.id;
    const result = this.services.accountRefund(id);
    if (result != "error")
    {
      this.data.refundAccount = true;
    }    
  }

}
