<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to view an account payment</h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/View Account Payments/vacp1.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	There are two ways to view “Account Payments”   </h2>
    <h2>•	This first way will show you how to view “Account Payments” from one specific "Invoice" that you choose  </h2>
    <h2>•	Go to “Account Payments” page via the clients “Invoices” page  </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/View Account Payments/vacp2.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Scroll down the page to find the “Client List” section </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/View Account Payments/vacp3.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Click the drop down arrow next to the client that you want to view “Account Payments” </h2>
    <h2>•	Under the menu, click “Open Invoice Accounts”</h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/View Account Payments/vacp4.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Scroll down the page to the “List of Invoices”   </h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/View Account Payments/vacp5.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Click the three (3) dots beside the client’s “Invoice Account” that you want to view “Account Payments” for </h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/View Account Payments/vacp6.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Click “Open Payment Accounts” </h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/View Account Payments/vacp7.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	You will now be on the “Account Payment” page for that specific invoice </h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/View Account Payments/vacp8.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Scroll down the page to find the “Payment List” section </h2>
    <h2>•	The “Account Payments” records will show in this list </h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/View Account Payments/vacp9.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Please keep in mind the list will be empty until you create an “Account Payment”, which can be done using the “Create Account Payment” form above </h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/View Account Payments/vacp10.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	This second way is by going to the “Clients List” like earlier. </h2>
    <h2>•	Click the drop-down arrow next to the chosen client. </h2>
    <h2>•	Click “Open Client Account Payments” </h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/View Account Payments/vacp11.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Now you will be on the “Search For All Payments From One Client” page </h2>
    <h2>•	Please note that this page allows you to view all account payments from the client that you chose earlier </h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/View Account Payments/vacp12.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Scroll down the page and navigate to the “List of All Payments From One Client”  </h2>
    <h2>•	This will simply be a list of records of all account payments for that client </h2>
</div>