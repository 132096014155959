<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to search for all invoices</h2>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Invoice/Search All Invoices/msiac01.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Go to the navigation bar at the top of the page, click the “Accounts” item</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Invoice/Search All Invoices/msiac02.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Under the menu, Click on “Account Invoices” </h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Invoice/Search All Invoices/msiac03.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	You are now on the “All Accounts - Invoices” page</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Invoice/Search All Invoices/msiac04.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Scroll down the page to find the “All Invoices” section</h2>
        <h2>•	This is where you can manually search for any invoice for any client</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Invoice/Search All Invoices/msiac05.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Here is where you will search for the specific “Invoice Account” you are looking for</h2>
        <h2>•	Please note that when you do your search, ensure that all spelling is correct</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Invoice/Search All Invoices/msiac06.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	To view only the invoices that are overdue, then click “Only Show Overdue Invoices”</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Invoice/Search All Invoices/msiac07.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2 class="mt-2">•	Only overdue invoices will now be displayed</h2>
        <h2 class="mt-2">•	In this example, there is one (1) overdue invoice</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Invoice/Search All Invoices/msiac08.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2 class="mt-2">•	To go back and view all total invoices again, click “Show All Invoices”</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Invoice/Search All Invoices/msiac09.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2 class="mt-2">•	As you can see, now all invoices will be displayed </h2>
    </div>