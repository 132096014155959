import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';  
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { Invoice } from '../_models/invoice';
import { InvoiceService } from '../_services/invoice/invoice.service';
    
/* Doesn't work - Still in play testing */
export class InvoiceValidator {  
    static matchInvoiceToAmountPaid(matchTo: string): ValidatorFn {
        return (control: AbstractControl) => {
            const amountPaid: number = +control?.parent?.controls[matchTo].value;
            const invoiceAmount: number = +control?.value;
            return amountPaid >= invoiceAmount ? null : {isAmountPaidGreaterThanInvoice: true};
        }
    }   
        
    static matchInvoiceFromInvoiceToAmountPaid(amountPaid: number): ValidatorFn {
        return (control: AbstractControl) => {
            const invoiceNumber: number = +control?.value;
            return amountPaid >= invoiceNumber ? null : {isAmountPaidGreaterThanInvoice: true};
        }
    }
}  