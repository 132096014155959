<div id="carouselDark" class="carousel-inner" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active" data-bs-interval="10000">
        <img src="/assets/images/pro1.jpg" class="d-block w-100 h-50" alt="...">
        <div class="carousel-caption d-md-block text-white ">
          <h1 class="text-uppercase">Portal</h1>
          <p>Sending SMS's & emails to pay off invoices and billing accounts.</p>
        </div>
      </div>
      <div class="carousel-item" data-bs-interval="10000">
        <img src="/assets/images/pro2.jpg" class="d-block w-100 h-50" alt="...">
        <div class="carousel-caption d-none d-md-block text-white">
          <h1 class="text-uppercase">Portal</h1>
          <p>Set a structure for your clients to pay later, or pay it forward</p>
        </div>
      </div>
      <div class="carousel-item">
        <img src="/assets/images/pro3.jpg" class="d-block w-100 h-50" alt="...">
        <div class="carousel-caption d-none d-md-block text-white">
          <h1 class="text-uppercase">Portal</h1>
          <p>Setup a payment plan for your client.</p>
        </div>
      </div>
    </div>
</div>
<section class="pt-4">
    <div class="container px-lg-5">
        <!-- Page Features-->
        <div class="row gx-lg-5">
            <div class="col-lg-6 col-xxl-4 mb-5">
                <div class="card bg-white border h-100 shadow-lg ">
                    <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                        <img src="/assets/images/edit.jpg" alt="Pay online" class="img-thumbnail">
                        <h2 class="fs-4 fw-bold">Current Status</h2>
                        <p class="mb-0">Your current status is "<b class="text-success">{{ subscription$ | async }}</b>".</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xxl-4 mb-5">
                <div class="card bg-white border h-100 shadow-lg">
                    <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                        <img src="/assets/images/subscriptions.jpg" alt="Subscriptions" class="img-thumbnail">
                        <h2 class="fs-4 fw-bold">Upgrade Membership</h2>
                        <p class="mb-0">We would like to kindly remind you that upgrading your current plan will provide you with access to enhanced benefits 
                          on our SaaS product. Please feel free to update your plan at your convenience.</p>
                        <button class="btn btn-outline-primary btn-block mt-2" [routerLink]="['/members/subscriptions']">Upgrade Plan Now!</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container" *ngIf="tasks">
    <div class="card border-primary mb-3">
        <div class="card-body">
          <h2 class="specialFont">To Do List!</h2>
          <hr>
          <div class="list-group">
                <a [routerLink]="['/members/message']" class="list-group-item list-group-item-action flex-column align-items-start" *ngIf="tasks.showSMSAndEmailTask">
                <div class="d-flex w-100 justify-content-between">
                    <h3 class="mb-1 text-primary">You need to Setup Messages SMS and Emails</h3>
                    <small class="text-danger font-weight-bold">Important Information</small>
                </div>
                <p class="mb-1"><small class="text-muted"><i class="fa fa-commenting-o fs-1 text-primary" aria-hidden="true"></i></small> We have noticed that you have not yet configured your email and SMS settings. To ensure that you are able to effectively communicate with your clients, we kindly request that you follow this <a routerLink="/members/message">Click Here To Update Messages</a> to set up your messaging preferences.</p>  
                </a>
                <a [routerLink]="['/members/subscriptions']" class="list-group-item list-group-item-action flex-column align-items-start" *ngIf="tasks.showStripeAccountTask">
                    <div class="d-flex w-100 justify-content-between">
                    <h3 class="mb-1 text-primary">You need to setup your Stripe Account</h3>
                    <small class="text-danger font-weight-bold">Important Information</small>
                    </div>
                    <p class="mb-1"><small class="text-muted"><i class="fab fa-cc-stripe fs-1 text-primary"></i></small> You have not yet set up your Stripe account for this profile. Please contact us here so that we can assist you in setting up your banking account.</p>  
                </a>
                <a class="list-group-item list-group-item-action flex-column align-items-start" *ngIf="tasks.showSMSAndEmailTask === false && tasks.showStripeAccountTask === false ">
                    <div class="d-flex w-100 justify-content-between">
                    <h3 class="mb-1 text-primary"><b>Congratulations!</b> Everything is done!</h3>
                    <small class="text-success font-weight-bold">Important Information</small>
                    </div>
                    <p class="mb-1"><small class="text-muted"><i class="fa fa-check text-success" aria-hidden="true"></i></small> You are currently up to date with all necessary tasks. There is no further action required on your part at this time.</p>  
                </a>
            </div>
        </div>
    </div>
</div>
<div class="container" *ngIf="patches">
    <div class="card border-primary mb-3">
        <div class="card-body">
          <h2 class="specialFont">Patches</h2>
          <hr>
          <div class="list-group">
            <table class="table table-borderless w-auto  ">
                <thead>
                  <tr class="table-primary">
                    <th scope="col">Patch Date</th>
                    <th scope="col">Patch Number</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                <tbody *ngFor="let patch of patches">                    
                  <tr class="border-bottom border-info" >
                    <th scope="row" class="w-25">{{patch.dateCreated | date:'longDate'}}</th>
                    <th scope="row" class="w-25">{{patch.patchVersion}}</th>
                    <td>{{patch.description}}</td>
                  </tr>
                </tbody>
                
            </table>
          </div>
        </div>
    </div>
</div>