<h3 class="bg-white text-danger text-center font-weight-bold">ARCHIVE / NON-ARCHIVE</h3>
<h4 class="text-center">What is your desired outcome? Do you want to archive, not archive, or cancel.</h4>
<div class="row">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <button type="button" class="btn btn-success btn-block" mat-button [mat-dialog-close]="data" (click)="deleteAccount(false)"><i class='fas fa-check'></i> Non-Acrhive
            <ng-container *ngIf="archive; else shownonarchive"> (Currently Active)</ng-container>
        </button>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <button type="button" class="btn btn-danger btn-block" mat-button [mat-dialog-close]="data" (click)="deleteAccount(true)"><i class='fas fa-trash'></i> Archive
        <ng-template #shownonarchive> (Currently Active)</ng-template>
        </button>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <button type="button" class="btn btn-primary btn-block" mat-button mat-dialog-close><i class='fas fa-close'></i> Cancel</button>
    </div>
</div>