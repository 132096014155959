import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { MessagesService } from 'src/app/_services/message/messages.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'app-duedateemailmessage',
  templateUrl: './duedateemailmessage.component.html',
  styleUrls: ['./duedateemailmessage.component.css']
})
export class DuedateemailmessageComponent implements OnInit {
  data: any;
  @ViewChild('editForm') editForm: NgForm; 
  formEdit: UntypedFormGroup;
  public Editor = ClassicEditor;

  constructor(private service: MessagesService,  public fb: UntypedFormBuilder, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.initializeForm();
    this.onLoad(); 
  }

  onSubmit(){
    const message = {
      dueDateEmailMessage: this.formEdit.controls['changeText'].value
    };     
    this.data.email = message.dueDateEmailMessage; 
    this.service.updateDueDateEmailMessage(message).subscribe(response => {
      this.onLoadSettingMesaging();
      this.toastr.success("Updated Message!");
    }, error => {
      console.log(error);
    });
  }

  onLoad(){
    this.service.getDueTimeEmailMessage().subscribe(response => {
      this.data = response;
      this.formEdit.controls['changeText'].setValue(this.data.dueDateEmailMessage);
      console.log(this.data);
    }, error => {
      console.log(error);
    })
  }

  initializeForm(){
    this.formEdit = this.fb.group({
      changeText:['', Validators.required],
    });  
  }

  public onReady( editor ) {
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );
  }

  generateText(){
    this.formEdit.controls['changeText'].setValue
    ("<body>\r\n  " +
    "  <div class=\"container\">\r\n    " +
    "    <p>Dear [name],</p>\r\n      " +
    "  <p>I trust this email finds you well.</p>\r\n\r\n   " +
    "     <p>I'm writing to remind you of the upcoming payment deadline for Invoice #[invoicenumber], totaling [dueamount], due today.</p>\r\n\r\n " +
    "       <p>To ensure a seamless transaction, kindly process the payment by the close of business today. Failure to do so may result in late fees.</p>\r\n\r\n     " +
    "   <h3>Invoice Details:</h3>\r\n   " +
    "     <ul>\r\n          " +
    "  <li>Invoice Number: [invoicenumber]</li>\r\n   " +
    "         <li>Amount Due: [dueamount]</li>\r\n     " +
    "       <li>Due Date: [duedate] </li>\r\n    " +
    "    </ul>\r\n\r\n    " +
    "    <h3>Payment Instructions:</h3>\r\n   " +
    "     <p>To make the payment, please visit the Invoice Pager website using the button below and enter the provided code: [code].</p>\r\n  [link]" +
    "        <p>If you've already settled this invoice, we extend our gratitude, and you can disregard this reminder.</p>\r\n\r\n        <p>Your prompt attention to this matter is highly appreciated. Feel free to respond to this email [mybusinessemail] for any questions or concerns regarding the invoice or payment process.</p>\r\n\r\n   " +
    "     <p>Thank you for your cooperation.</p>\r\n\r\n  " +
    "      <p>Best regards,<br>\r\n         " +
    "      [mybusiness]<br>\r\n       " +
    "     [mybusinessemail]<br>\r\n " +
    "     [mybusinessphone]</p>\r\n " +
    "   </div>\r\n</body>");
    this.formEdit.markAsDirty();
  }

  onLoadSettingMesaging(){
    this.service.getTextSettingMessaging().subscribe(response => {
      this.service.message = response;
      if (this.service.message == null){
        this.service.setMessageValues();
      }

      console.log(response);
    }, error => {
      console.log(error);
    })
  }

}
