<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to customise notifications to your customers </h2>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Messages/m01.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	On the navigation bar at the top section of the page, click the “Messages” item</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Messages/m02.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	You’re now on the “Messages” page</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Messages/m03.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•   For example to customise email messages, under “Due Date Email Message”, click “Change Due Date Email Text” to customise the automated “Due Date” email to send to your clients</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Messages/m04.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	You can now alter the message to suit your needs</h2>
        <h2>•	There are several dynamic placeholders that can be used to save you time</h2>
        <div class="row" class="border border-primary">
            <div class="col-5">
                <h1 class="mt-2">PlaceHolder</h1>
                <p>[Name]</p>
                <p>[MyBusiness]</p>
                <p>[InvoiceNumber]</p>
                <p>[DuePayment]</p>
                <p>[DueDate]</p>
                <p>[Link]</p>
            </div>
            <div class="col-5">
                <h1>Description</h1>
                <p>Insert the client's name</p>
                <p>Inserts your business or company's name</p>
                <p>The number for the invoice</p>
                <p>The payment amount due</p>
                <p>The date the payment is due</p>
                <p>URL link to the payment page</p>
            </div>
        </div>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Messages/m05.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Click “Confirm Changes” to save any changes you have made</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Messages/m06.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Click on “Show An Example For Email” to see how your email message will appear to your clients</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Messages/m07.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Fill out all details as needed</h2>
    </div>
    <hr>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <img src="assets/images/Messages/m08.png" class="img-fluid" alt="Responsive image">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>•	Click “Send Email Message”</h2>
        <h2>•	A test email will now be sent to the email address you entered</h2>
    </div>