<div class="form-group">
    <label class="form-label fw-bold">{{label}}</label>   
    <textarea
        [class.is-invalid]='ngControl.touched && ngControl.invalid'
        [class.is-valid]='ngControl.valid'
        class="form-control"
        [formControl]='ngControl.control'
        placeholder="{{placeholder}}"
        rows="{{rows}}">
    </textarea>
    <small id="emailHelp" class="form-text text-muted">{{smalltext}}</small>
    <div *ngIf="ngControl.control.errors?.cannotContainSpaceAtFirstCharacter" class="invalid-feedback">
        {{label}} cannot contain at first character to have white spaces. 
    </div>
    <div *ngIf="ngControl.control.errors?.required" class="invalid-feedback">{{label}} is required</div>
    <div *ngIf="ngControl.control.errors?.minlength" class="invalid-feedback">
        {{label}} must be at least {{ngControl.control.errors.minlength['requiredLength']}}
    </div>
    <div *ngIf="ngControl.control.errors?.maxlength" class="invalid-feedback">
        {{label}} must be at most {{ngControl.control.errors.maxlength['requiredLength']}}
    </div>
</div>