<div class="form-group">
    <label class="form-label fw-bold">{{label}}</label>
    
    <input type="text"
    [class.is-invalid]='ngControl.touched && ngControl.invalid'
    [class.is-valid]='ngControl.valid'
    class="form-control"
    [formControl]='ngControl.control'
    placeholder="{{placeholder}}"
    bsDatepicker
    [bsConfig]='bsConfig'
    [maxDate]='maxDate'
    [minDate]='minDate'
    >
    <small id="emailHelp" class="form-text text-muted">{{smalltext}}</small>
    <div *ngIf="ngControl.control.errors?.required" class="invalid-feedback">{{label}} is required</div>
    <div *ngIf="ngControl.control.errors?.compareDateInvalid" class="invalid-feedback">
        Date is invalid. You must have due date greater than the last input date!
    </div>
    <div *ngIf="ngControl.control.errors?.dateinvalidtime" class="invalid-feedback">
        Due Date must be equal or greater than todays date!
    </div>
</div>