import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HubConnection } from '@microsoft/signalr';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectionSignalRHub } from 'src/app/_helper/connectionsignalrhub';
import { iStripeAccount } from 'src/app/_models/istripeaccount';
import { StripeAllTransactionOneClient } from 'src/app/_models/stripealltransactionsoneclient';
import { User } from 'src/app/_models/user';
import { environment } from 'src/environments/environment';
import { HostpresenceService } from '../hubsignalr/hostpresence.service';

@Injectable({
  providedIn: 'root'
})
export class StripeaccountService {

  user: User;
  baseUrl =  environment.apiUrl;
  showalltransactionsbtn: boolean = false;
  resetTimerStripeTable: boolean = false;
  stripeDataSource$: MatTableDataSource<iStripeAccount> = new MatTableDataSource<iStripeAccount>();
  namebtnshowtransactions: string = "Show All Transactions";
  public isitShowAllTransactionPage: boolean = false;
  private stripeSource = new BehaviorSubject<iStripeAccount[]>([]);
  private sAccounts: iStripeAccount[] = []; 
  stripeAccounts$ = this.stripeSource.asObservable();
  private notificationStripeNumbers = new ReplaySubject<number>(1);
  notificationStripeNumbers$ = this.notificationStripeNumbers.asObservable();
  stripeNotificationNumbers
  private hubConnectionStripeAccounts?: HubConnection;
  private hubConnectionUpdateStripeAccounts?: HubConnection;
  private hubConnectionNotificationNumbers?: HubConnection;

  private huburl = environment.hubUrl;
  
  constructor(private http: HttpClient, private presenceHub: HostpresenceService, private toastr: ToastrService) { }

  getStripeAccounts(){
    return this.http.get<iStripeAccount[]>(this.baseUrl + 'stripeaccounts/getaccountsstripe').pipe(
      map(response => {
        return response;
      })
    )
  }

  accountRefund(accountID: string) : string {
    this.http.put( this.baseUrl + 'stripeaccounts/refundaccount?accountID=' + accountID, null).subscribe(
      response => {
        return "";
      }, error => {
        console.log(error);
        return "error";
      }
    )
    return "";
  }

  getStripeAllTransaction(){
    return this.http.get<StripeAllTransactionOneClient[]>(this.baseUrl + 'stripeaccounts/getalltransactions').pipe(
      map(response => {
        return response;
      }, error => {
        console.log(error);
      })
    )
  }

  updateStatusTransaction(account: any){
    return this.http.put<any>(this.baseUrl + 'stripeaccounts/updateStatusreadnotification', account).pipe(
      map(response => {
        return response;
      })
    )
  }

  getCheckCanUserUseRefund(){
    return this.http.get<boolean>(this.baseUrl + 'stripeaccounts/canuserusefund').pipe(
      map((refund: boolean) => {
        return refund;
      })
    )
  }

  getCheckCanUpdateStatusOnStripe(){
    return this.http.get<boolean>(this.baseUrl + 'stripeaccounts/canuserupdatestatusonstripe').pipe(
      map((updateStatus: boolean) => {
        return updateStatus;
      })
    )
  }
  getCheckCanGoToInvoiceOnStripe(){
    return this.http.get<boolean>(this.baseUrl + 'stripeaccounts/canusercanuseinvoiceonstripe').pipe(
      map((canaccessinvoice: boolean) => {
        return canaccessinvoice;
      })
    )
  }

  updateAllTransactions(){
    return this.http.put<boolean>(this.baseUrl + 'stripeaccounts/updatetransactions', null).pipe(
      map((updatetransactions: boolean) => {
        return updatetransactions;
      })
    )
  }

  async createHubUpdateStripeAccountConnection(user: User) 
  {  
    if (user === null)
      user = this.user;
    else
      this.user = user;

    const accountID = 0;

    user = this.CheckUserExist(user);
 
    this.hubConnectionUpdateStripeAccounts = ConnectionSignalRHub.GetConnectionHub(this.hubConnectionUpdateStripeAccounts, this.huburl, accountID, user, "updatestripeaccounts");
    this.hubConnectionUpdateStripeAccounts.on('ReceiveUpdateStripeAccounts', (reset: any) =>
    {
      this.stopHubNotificationNumberConnection();
      this.createHubNotificationNumberConnection(user);
      
      console.log('receive payment');
      this.stopHubConnection();
      this.createHubConnection(user, true);      
    });
  }

  async createHubNotificationNumberConnection(user: User) 
  {  
    const accountID = 0;

    user = this.CheckUserExist(user);
 
    console.log('numbers are here');
    this.hubConnectionNotificationNumbers = ConnectionSignalRHub.GetConnectionHub(this.hubConnectionNotificationNumbers, this.huburl, accountID, user, "getnotificationsnumbers");
    this.hubConnectionNotificationNumbers.on('ReceiveStripeNotificationNumbers', (numbers: number) =>
    {
      console.log('number is ' + numbers)
      if (numbers === 0)
      {
        this.notificationStripeNumbers.next(null);
        return;
      }       
             
      if (this.notificationStripeNumbers === null)
      {
        this.notificationStripeNumbers.next(numbers);
        this.playSound();
        return;
      }

      this.notificationStripeNumbers.subscribe(resposne => {
       // if (resposne === numbers)
       //   return;          
      });    
      
      this.playSound();
      this.notificationStripeNumbers.next(numbers);
    });
  }



  async createHubConnection(user: User, status: boolean) 
  {  
    const accountID = 0;
    if (ConnectionSignalRHub.CheckAccessHub(this.presenceHub.pageActive[8], status) === false)
      return;

    user = this.CheckUserExist(user);
 
    this.hubConnectionStripeAccounts = ConnectionSignalRHub.GetConnectionHub(this.hubConnectionStripeAccounts, this.huburl, accountID, user, "stripeaccount");
    this.hubConnectionStripeAccounts.on('ReceiveStripeAccounts', (stripeAccounts: iStripeAccount[]) => {
    if (this.isitShowAllTransactionPage)
      return;

    if (stripeAccounts !== null)
    { 
      console.log(stripeAccounts);
      if (this.sAccounts === null)
      {
        this.resetTimerStripeTable = true;
        this.stripeSource.next(stripeAccounts);
        this.stripeDataSource$.data = stripeAccounts;
        this.sAccounts = stripeAccounts;   
        this.toastr.info("Please wait! We are currently updating your records now!");
      }       
      else if (stripeAccounts.length !== this.sAccounts.length)
      {
        this.resetTimerStripeTable = true;
        this.stripeSource.next(stripeAccounts);
        this.stripeDataSource$.data = stripeAccounts;
        this.sAccounts = stripeAccounts;  
        this.toastr.info("Please wait! We are currently updating your records now!");
      }
      else
      {    
        var i = 0;
        stripeAccounts.forEach(stripeacccount => {
          if (stripeacccount.hasClientTickReadThisRecord !== this.sAccounts[i].hasClientTickReadThisRecord)
          {
            this.resetTimerStripeTable = true;
            this.stripeSource.next(stripeAccounts);
            this.stripeDataSource$.data = stripeAccounts;
            this.sAccounts = stripeAccounts;
            this.toastr.info("User changed status!");
          }
          if (stripeacccount.hasUpdatedStripeAccountAfterThreeDays !== this.sAccounts[i].hasUpdatedStripeAccountAfterThreeDays)
          {
            this.resetTimerStripeTable = true;
            this.stripeSource.next(stripeAccounts);
            this.stripeDataSource$.data = stripeAccounts;
            this.sAccounts = stripeAccounts;
            this.toastr.info("User changed status!");
          }

          i = i + 1;
        });
      }
    
    }});
  }

  stopHubConnection(){
    if (this.hubConnectionStripeAccounts) {
      this.hubConnectionStripeAccounts?.stop();
    }
  }
  stopHubNotificationNumberConnection(){
    if (this.hubConnectionNotificationNumbers) {
      this.hubConnectionNotificationNumbers?.stop();
    }
  }

  stopUpdateStripeAccountsHubConnection(){
    if (this.hubConnectionUpdateStripeAccounts) {
      this.hubConnectionUpdateStripeAccounts?.stop();
    }
  }

  private CheckUserExist(user: User)
  {
    if (user == null)
    {
      user = {
        token: "",
        username: "",
        firstName: "",
        lastName: "",
        typeOfService: false,
      };
    }
    return user;
  }

  private playSound(){
    let audioPlay = new Audio();
    audioPlay.src = "assets/sound/dingsound.wav";
    audioPlay.load();
    audioPlay.play();
    console.log('playing sound');
  }

}
