<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <div class="container-fluid">
      <div class="row">
          <mat-card-title class="RalewayMainLogo text-white" >Invoice Pager</mat-card-title>            
      </div>

      <a class="navbar-brand" routerLink="/"><img src="" alt=""></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">  
              <li class="nav-item">
                  <a class="nav-link" (click)="cancelButton()" routerLinkActive="active">Go Back To Invoice Account</a>
              </li>
          </ul>
      </div>
  </div>
</nav>

<div class="container mt-4" *ngIf="invoice">
  <nav class="navbar navbar-light bg-primary shadow-lg">
    <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Edit Account:  
      <div class="mt-2 ms-4" *ngIf="invoice"><b>Invoice Number:</b> {{invoice.invoiceNumber}}</div>
    </a>  
  </nav>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" >
    <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded">
      <div class="card-body"> 
          <h2 class="text-center text-primary mt-2">Type Of Account</h2>
          <hr>
          <div class="form-group">
            <label class="form-label">Would you like to add items to your invoice account, or do you prefer to utilize this app for sending notifications?</label>
            <select class="form-select" (change)="onChangeFormToggle($event)">      
              <option *ngFor="let option of optionToggleInvoiceTypes" [selected]="option.selected === true" [value]="option.value">{{option.description}}</option>             
            </select>
          </div>
      </div>
    </div>
  </div>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" [hidden]="!showCreateForm">
    <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded">
        <div class="card-body">
          <h2 class="text-center text-primary mt-2">
            <ng-container *ngIf="user.typeOfService; else showInvoiceCreateAccount ">
              Update Billing Account (Exclusively for Notification Sending)
            </ng-container>
            <ng-template #showInvoiceCreateAccount>
              Update Invoice Account (Exclusively for Notification Sending)
            </ng-template> 
          </h2>
          <hr class="new1">
          <form #editForm="ngForm" id="editForm" [formGroup]="formInvoice" (ngSubmit)="formInvoice.valid" autocomplete="off">
            <div class="bs-component">
              <div class="alert alert-dismissible alert-warning" *ngIf="hasItems">
                <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                <h4 class="alert-heading">Warning!</h4>
                <p class="mb-0">
                  You currently have existing items associated with this Invoice account. Updating this account will result in the removal of all items linked to it. If you wish to retain these items, please navigate to the 'Type of Account' section above and select 
                  'I would like to add items to my account'.
                </p>
              </div>
            </div>
            <div class="bs-component">
              <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
                <button type="button" class="close" data-dismiss="alert">×</button>
                <h4 class="alert-heading">Information!</h4>
                <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
              </div>
            </div>
            <div class="row">                  
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice fa-lg"></i>
                  <ng-container *ngIf="user.typeOfService; else showInvoiceIdentity ">
                    Billing Accounts Identity:
                  </ng-container>
                  <ng-template #showInvoiceIdentity>
                    Invoicing Identity:
                  </ng-template>                        
                </h4>
                <ng *ngIf="invoice"><b>Invoice Number:</b> {{invoice.invoiceNumber}}</ng>
                <h4 class="card-title bg-dark text-white text-center mt-3"><i class="fas fa-comment fa-lg"></i> Information About This Invoice:</h4>
                <app-area-text [formControl]='formInvoice.controls["comment"]' [label]='"Note"' [smalltext]='""' [placeholder]='"Note"'></app-area-text>                                       
              </div>    
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">       
                <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-calendar"></i> Dates:</h4>   
                <app-date-input [formControl]='formInvoice.controls["initaldate"]' [label]='"*Initial  Date"' [smalltext]='"Initial date sent to the buyer."' [placeholder]='"Initial Date"'> </app-date-input>
                <app-date-input [formControl]='formInvoice.controls["duedate"]' [label]='"*Due Date"' [change]="onChangeDueDate()" [smalltext]='"Final date for the payment"' [placeholder]='"Due Date"'> </app-date-input>
                <p class="text-warning" *ngIf="showInvalidDate">Warning! Due Date is greater than initial date.</p>
                <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-coins fa-lg"></i> Payments:</h4>
                <app-text-input [formControl]='formInvoice.controls["invoiceamount"]' [label]='"*Invoice Amount"' [type]='"number"' [smalltext]='"Invoice Amount means the amount invoiced to a Buyer for the Lot with GST included."' [type]='"number"' [placeholder]='"Invoice Amount"'> </app-text-input>
                <app-text-input [formControl]='formInvoice.controls["paymentamount"]' [label]='"*Amount Paid"' [type]='"number"' [smalltext]='"A sum of money paid"' [type]='"number"' [placeholder]='"Payment Amount"'> </app-text-input>                          
              </div>  
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">      
                <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice fa-lg"></i>
                    Date Sending                      
                </h4> 
                <fieldset class="form-group">
                  <div class="card border-secondary mb-3">
                    <div class="card-body">
                      <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice "></i> Send Out Due Date Message:</h4>
                      <label class="form-check-label"></label>
                      <div class="form-check">
                        <label class="form-check-label ">
                          <input type="radio" class="form-check-input" name="addduedate" [formControl]='formInvoice.controls["addduedate"]' value="true" >
                          <b class="text-success">Yes</b>: The message will be sent out.
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" name="addduedate"  [formControl]='formInvoice.controls["addduedate"]' value="false">
                          <b class="text-danger">No</b>: The due date message won't be sent out.
                        </label>
                      </div>
                    </div>
                  </div>                        
                </fieldset>   
                <fieldset class="form-group">
                  <div class="card border-secondary mb-3">
                    <div class="card-body">
                      <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice "></i> Add transaction fees:</h4>
                      <label class="form-check-label"></label>
                      <div class="form-check">
                        <label class="form-check-label ">
                          <input type="radio" class="form-check-input" name="RatingRadioOptions2" id="optionsRadios3" [formControl]='formInvoice.controls["addFees"]' value="true">
                          <b class="text-success">Yes</b>: Add in 1.75% + A$0.30 to your customer billing account. 
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" name="RatingRadioOptions2" id="optionsRadios4" [formControl]='formInvoice.controls["addFees"]' value="false">
                          <b class="text-danger">No</b>: My business will pay out the transaction fee. 
                        </label>
                      </div>
                    </div>
                  </div>                        
                </fieldset>   
              </div> 
            </div>
          </form>
        </div>
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
              <button [disabled]="!formInvoice.valid" class="btn btn-success btn-block" form="editForm" (click)="onSubmit()" ><i class="fas fa-file-invoice fa-lg"></i> 
                <ng-container *ngIf="user.typeOfService; else showInvoiceButtonForCreate ">
                  Update Billing Account
                </ng-container>
                <ng-template #showInvoiceButtonForCreate>
                  Update Invoice Account 
                </ng-template>                      
              </button>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12 mt-4 mt-md-0">
              <button class="btn btn-primary btn-block" (click)="cancelButton()"><i class="fas fa-times fa-lg"></i> Cancel</button>
            </div>
          </div>
      </div>
  </div>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" [hidden]="showCreateForm">
  <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded">
      <div class="card-body">
        <h2 class="text-center text-primary mt-2">
          <ng-container *ngIf="user.typeOfService; else showCreateInvoiceCreateAccount ">
            Update Items For Billing Account
          </ng-container>
          <ng-template #showCreateInvoiceCreateAccount>
            Update Items For Invoice Account 
          </ng-template> 
        </h2>             
      </div>
      <div class="form-group">
        <label>Choose type of Tax</label>
        <select class="form-control" (change)="toggleTypeOfTax($event.target.value)" >
          <option *ngFor="let option of optionToggleTaxTypes" [value]="option.value" [selected]="option.selected">{{option.description}}</option>
        </select>
      </div>
      <div class="d-none d-md-none d-lg-block">
        <div class="row">
          <div class="col-4 bg-primary text-white fs-5 p-3">
            Description
          </div>
          <div class="col-2 bg-primary text-white fs-5 p-3">
            Qty
          </div>
          <div class="col-2 bg-primary text-white fs-5 p-3">
            Unit Price
          </div>
          <div class="col-2 bg-primary text-white fs-5 p-3">
            Amount AUD
          </div>
          <div class="col-2 bg-primary text-white fs-5 p-3">
            Actions
          </div>
        </div>
      </div>

      <ng-container *ngif="formItems">
        
      </ng-container>
      <ng-container *ngFor="let item of invoiceItems; let i = index">
        <form class="" [formGroup]="formItems[i + 1]" (ngSubmit)="formItems[i + 1].valid" autocomplete="off">
          <div class="row mt-2">             
              <div class="col-xl-4 col-lg-4 col-md-3 col-sm-12 p-3">
                <label class="form-label fw-bold d-block d-md-block d-lg-none">Description</label>
                <textarea class="form-control" rows="8" placeholder="Description" name="description"  [class.is-invalid]='formItems[i + 1].controls.description.touched && formItems[i + 1].controls.description.invalid' [formControl]='formItems[i + 1].controls["description"]'></textarea>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-3">
                <label class="form-label fw-bold d-block d-md-block d-lg-none">Qty</label>
                <input class="form-control" type="number" placeholder="Qty" name="qty" [class.is-invalid]='formItems[i + 1].controls.qty.touched && formItems[i + 1].controls.qty.invalid' [formControl]='formItems[i + 1].controls["qty"]' [value]='item.qty' (change)="addAmountAUD(i + 1)">
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-3">
                <label class="form-label fw-bold d-block d-md-block d-lg-none">Unit Price</label>
                <input class="form-control" type="number" placeholder="Unit Price" [class.is-invalid]='formItems[i + 1].controls.unitprice.touched && formItems[i + 1].controls.unitprice.invalid' name="unitprice" [formControl]='formItems[i + 1].controls["unitprice"]' (change)="addAmountAUD(i + 1)">
              </div>                 
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-3">
                <label class="form-label fw-bold d-block d-md-block d-lg-none">Amount Paid</label>
                <input class="form-control" type="number" name="amountaud" [formControl]='formItems[i + 1].controls["amountaud"]' disabled>
              </div>                   
              <div class="col-xl-2 col-lg-2 col-md-3 col-sm-12 p-3">
                <div class="row">
                  <div class="col-12">
                    <button class="form-control btn-outline-danger" (click)="deleteItem(i + 1, i)"><i class="fas fa-trash"></i> Delete</button>
                  </div>
                </div>
              </div>                                              
          </div>
        </form> 
      </ng-container>
      <form class="" [formGroup]="formItems[0]" (ngSubmit)="formItems[0].valid" autocomplete="off">
        <div class="row mt-2">             
            <div class="col-xl-4 col-lg-4 col-md-3 col-sm-12  p-3">
              <label class="form-label fw-bold d-block d-md-block d-lg-none">Description</label>
              <textarea class="form-control" rows="8" placeholder="Description" name="description" [class.is-invalid]='formItems[0].controls.description.touched && formItems[0].controls.description.invalid'  [formControl]='formItems[0].controls["description"]'></textarea>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-3">
              <label class="form-label fw-bold d-block d-md-block d-lg-none">Qty</label>
              <input class="form-control" type="number" placeholder="Qty" name="qty"  [class.is-invalid]='formItems[0].controls.qty.touched && formItems[0].controls.qty.invalid'  [formControl]='formItems[0].controls["qty"]' (change)="addAmountAUD(0)">
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-3">
              <label class="form-label fw-bold d-block d-md-block d-lg-none">Unit Price</label>
              <input class="form-control" type="number" placeholder="Unit Price" name="unitprice"  [class.is-invalid]='formItems[0].controls.unitprice.touched && formItems[0].controls.unitprice.invalid'  [formControl]='formItems[0].controls["unitprice"]' (change)="addAmountAUD(0)">
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-3">
              <label class="form-label fw-bold d-block d-md-block d-lg-none">Amount Paid</label>
              <input class="form-control" type="number" name="amountaud" [formControl]='formItems[0].controls["amountaud"]' disabled>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-12 p-3">
              <div class="row">
                <div class="col-12">
                  <button class="form-control btn-outline-success notallowed" [disabled]="!formItems[0].valid"  [hidden]="formItems[0].valid"><i class="fas fa-save"></i> Create New</button><button class="form-control btn-outline-success" [disabled]="!formItems[0].valid" [hidden]="!formItems[0].valid" (click)="addItemForm()" ><i class="fas fa-save"></i> Create Item</button>
                </div>
                <div class="col-12 mt-2" *ngIf="formItems.length > 1">
                  <button class="form-control btn-outline-danger" (click)="revertLastActionForCreateNewItem()"><i class="fas fa-trash"></i> Remove</button>
                </div>
              </div>                       
            </div>                                              
        </div>
      </form>       
   
    <div class="row">
      <div class="col">
      
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col">
    
      </div>
      <div class="col">
        <table class="table caption-top"> 
          <tbody>
            <tr>
              <td class="font-weight-bold">Sub Total:</td>
              <td>{{ customCreateInvoieSubTotal | currency }} </td>
            </tr>
            <tr [hidden]="!customCreateInvoiceTaxInclude">
              <td class="font-weight-bold">GST:</td>
              <td>{{ customCreateInvoiceGST | currency }} </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="bordertable">
              <td class="font-weight-bold fs-5">Total Amount: </td>
              <td class="fs-5">{{ customCreateInvoiceTotalAmount | currency }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="col-12" [hidden]="customCreateInvoiceTotalAmount > 0">
      <button class="btn btn-primary btn-block" (click)="cancelButton()"><i class="fas fa-times fa-lg"></i> Cancel And Then Head Back To Invoice Account</button>
    </div>
  </div>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" *ngIf="invoice !== null" [hidden]="showCreateForm || customCreateInvoiceTotalAmount <= 0">
    <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded">
        <div class="card-body">
          <h2 class="text-center text-primary mt-2">
            <ng-container *ngIf="user.typeOfService; else showInvoiceCreateCustomAccount ">
              Edit Billing Account
            </ng-container>
            <ng-template #showInvoiceCreateCustomAccount>
              Edit Invoice Account
            </ng-template> 
          </h2>
          <hr class="new1">
          <form #editForm="ngForm" id="editForm" [formGroup]="formInvoice" (ngSubmit)="formInvoice.valid" autocomplete="off">
            <div class="alert alert-dismissible alert-danger" *ngIf="customCreateInvoiceIsValid">
              <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
              <h4 class="alert-heading">You can't update this account until you fix the issues from above!</h4>
              <p class="mb-0">        
                  A problem has been identified with the "Create Items for an Account" function. It is crucial to rectify these issues prior to update this invoice account. Please ensure that you have provided the necessary information in the "Description," "Qty," and "Unit Price" fields for all entries, 
                  including the row designated as "Add New Item".
              </p>
            </div>
            <div class="bs-component">
              <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
                <button type="button" class="close" data-dismiss="alert">×</button>
                <h4 class="alert-heading">Information!</h4>
                <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
              </div>
            </div>
            <div class="row">                  
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice fa-lg"></i>
                  <ng-container *ngIf="user.typeOfService; else showInvoiceIdentity ">
                    Billing Accounts Identity:
                  </ng-container>
                  <ng-template #showInvoiceIdentity>
                    Invoicing Identity:
                  </ng-template>                        
                </h4>
                <ng *ngIf="invoice"><b>Invoice Number:</b> {{invoice.invoiceNumber}}</ng>
                <h4 class="card-title bg-dark text-white text-center mt-3"><i class="fas fa-comment fa-lg"></i> Information About This Invoice :</h4>
                <app-area-text [formControl]='formInvoice.controls["comment"]' [label]='"Note"' [smalltext]='""' [placeholder]='"Note"'></app-area-text>                                       
              </div>    
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">       
                <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-calendar"></i> Dates:</h4>   
                <app-date-input [formControl]='formInvoice.controls["initaldate"]' [label]='"*Initial  Date"' [smalltext]='"Initial date sent to the buyer."' [placeholder]='"Initial Date"'> </app-date-input>
                <app-date-input [formControl]='formInvoice.controls["duedate"]' [label]='"*Due Date"' [change]="onChangeDueDate()" [smalltext]='"Final date for the payment"' [placeholder]='"Due Date"'> </app-date-input>
                <p class="text-warning" *ngIf="showInvalidDate">Warning! Due Date is greater than initial date.</p>
                <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-coins fa-lg"></i> Payments:</h4>
                <div class="form-group">
                  <label class="col-form-label" >Invoice Amount</label>
                  <input type="text" class="form-control" placeholder="Invoice Amount" [formControl]='formInvoice.controls["invoiceamount"]' disabled>
                </div>
                <app-text-input [formControl]='formInvoice.controls["paymentamount"]' [label]='"*Amount Paid"' [type]='"number"' [smalltext]='"A sum of money paid"' [type]='"number"' [placeholder]='"Payment Amount"'> </app-text-input>                          
              </div>  
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">      
                <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice fa-lg"></i>
                    Date Sending                      
                </h4> 
                <fieldset class="form-group">
                  <div class="card border-secondary mb-3">
                    <div class="card-body">
                      <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice "></i> Send Out Due Date Message:</h4>
                      <label class="form-check-label"></label>
                      <div class="form-check">
                        <label class="form-check-label ">
                          <input type="radio" class="form-check-input" name="addduedate" value="true" checked=""  [formControl]='formInvoice.controls["addduedate"]'>
                          <b class="text-success">Yes</b>: The message will be sent out.
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" name="addduedate"  value="false" [formControl]='formInvoice.controls["addduedate"]'>
                          <b class="text-danger">No</b>: The due date message won't be sent out.
                        </label>
                      </div>
                    </div>
                  </div>                        
                </fieldset>   
                <fieldset class="form-group">
                  <div class="card border-secondary mb-3">
                    <div class="card-body">
                      <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-file-invoice "></i> Add transaction fees:</h4>
                      <label class="form-check-label"></label>
                      <div class="form-check">
                        <label class="form-check-label ">
                          <input type="radio" class="form-check-input" name="addfees" value="true" checked=""  [formControl]='formInvoice.controls["addFees"]'>
                          <b class="text-success">Yes</b>: Add in 1.75% + A$0.30 to your customer billing account. 
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" name="addfees" value="false" [formControl]='formInvoice.controls["addFees"]'>
                          <b class="text-danger">No</b>: My business will pay out the transaction fee. 
                        </label>
                      </div>
                    </div>
                  </div>                        
                </fieldset>   
              </div> 
            </div>
          </form>
        </div>
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
            <button [disabled]="!formInvoice.valid || customCreateInvoiceIsValid" class="btn btn-success btn-block" form="editForm" (click)="CustomonSubmit()" ><i class="fas fa-file-invoice fa-lg"></i> 
              <ng-container *ngIf="user.typeOfService; else showInvoiceButtonForCreate ">
                Update Billing Account
              </ng-container>
              <ng-template #showInvoiceButtonForCreate>
                Update Invoice Account
              </ng-template>                      
            </button>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12 mt-4 mt-md-0">
            <button class="btn btn-primary btn-block" (click)="cancelButton()"><i class="fas fa-times fa-lg"></i> Cancel</button>
          </div>
        </div> 
        </div> 
      </div>
</div> 
