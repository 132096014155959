<app-nav></app-nav>
<ng-container *ngIf="accountService.currentUser$ | async as user">
  <div class="container">
    <div class="row">
      <div class="ngcontainer" *ngIf="clientInfo">
        <div class="col-12 mt-4">
          <nav class="navbar navbar-light bg-primary shadow-lg">
            <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Account Payment</a>  
            <button type="button" class="btn btn-outline-light btn-lg mt-2 mt-md-0" (click)="toggle.toggleWindow(34)"><i class="{{toggle.$Icon[34]}}" aria-hidden="true\"></i> {{toggle.$ToggleText[34]}}</button>
          </nav>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]= "['/members/clients']">Clients</a></li>
            <li class="breadcrumb-item">
              <a [routerLink]= "['/members/invoices', clientInfo.clientID ]">
                <ng-container *ngIf="user.typeOfService; else showInvoiceTitle ">
                  Billing Accounts
                </ng-container>
                <ng-template #showInvoiceTitle>
                  Invoices 
                </ng-template> 
              </a>            
            </li>
            <li class="breadcrumb-item active">Payments</li>
          </ol>
          <div class="card-body" [hidden]="!toggle.$Toggle[34]">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xm-12">
                <img src="/assets/images/profile-account.jpg" alt="profile account" class="img-thumbnail rounded center">
                <h2 class="card-title text-center bg-white p-2 font-weight-bold text-primary fs-3 text-uppercase shadow-sm"><i class="fas fa-house-user fa-2x text-primary"></i> Invoice Information </h2>
                <p class="card-text text-center p-2 fs-4"><b>Invoice Number:</b> <b class="text-primary"> {{clientInfo.invoiceNumber }}</b></p>
                <p class="card-text text-center p-2 fs-4"><b>Client Name:</b> {{clientInfo.name}}</p>
                <p class="card-text text-center p-2 fs-4"><b>Invoice Amount:</b> <b class="text-success"> {{clientInfo.invoiceAmount | currency }}</b></p>    
                <p class="card-text text-center p-2 border fs-5"><b>Default from Invoice Paid Amount:</b><b class="text-success"> {{clientInfo.amountPaid | currency }} </b></p>                                  
                <p class="card-text text-center p-2 fs-4"><b>Total Paid Amount:</b> <b class="text-success"> {{clientInfo.amountPaid + paymentService.totalPayments$ | currency }} </b></p>             
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xm-12">
                <div class="card border shadow-lg">
                  <div class="card-body bg-secondary">
                    <fieldset class="form-group">
                      <legend class="text-center text-primary fs-2 text-uppercase font-weight-bold">How to use This?</legend>
                      <div class="form-group mt-4">
                        <div class="embed-responsive embed-responsive-16by9">
                          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/DrmnCGrrp9M?si=jZgfwv3I-s69UScx" allowfullscreen></iframe>
                        </div>
                        <button class="btn btn-info ms-2 btn-block mt-4" routerLink="/members/turtorialhome"><i class="fas fa-question-circle fa-lg"></i> Click Here to learn more!</button>
                      </div>             
                    </fieldset>
                  </div>
                </div>          
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav class="navbar navbar-light bg-primary shadow-lg mt-2">
        <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Create Account Payment</a>
        <button type="button" class="btn btn-outline-light btn-lg mt-2 mt-md-0" (click)="toggle.toggleWindow(3)"><i class="{{toggle.$Icon[3]}}" aria-hidden="true\"></i> {{toggle.$ToggleText[3]}}</button>
      </nav>
          <div class="col-12" [hidden]="!toggle.$Toggle[3]">
              <div class="card mt-4 border-info">
                  <div class="card-body">
                    <h2 class="text-center text-primary mt-2">Create Account Payment</h2>
                    <hr>
                    <form #editForm="ngForm" id="editForm" [formGroup]="formAccounts" (ngSubmit)="formAccounts.valid" autocomplete="off">
                      <div class="bs-component">
                        <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
                          <button type="button" class="close" data-dismiss="alert">×</button>
                          <h4 class="alert-heading">Information!</h4>
                          <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">                 
                              <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-credit-card"></i> Payment Amount:</h4>
                              <app-text-input [formControl]='formAccounts.controls["accountmoney"]' [label]='"*Amount"' [smalltext]='""' [placeholder]='"Amount"' [type]='"number"'> </app-text-input>                     
                              <fieldset class="form-group">
                                <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-credit-card"></i> Account Type:</h4>
                                <div class="card-body">      
                                  <div class="form-check">
                                    <label class="form-check-label">
                                      <input type="radio" class="form-check-input" name="paymentoptionsRadios" [formControl]='formAccounts.controls["option"]' value="accountreceivable" (change)="ShowPublicType(false)" checked="">
                                      <b class="text-success">Account Paid:</b><small> Account that's paid is done exchange for money.</small>
                                    </label>
                                  </div>                        
                                  <div class="form-check">
                                    <label class="form-check-label">
                                      <input type="radio" class="form-check-input" name="paymentoptionsRadios" [formControl]='formAccounts.controls["option"]' value="accountreturn" (change)="ShowPublicType(false)">
                                      <b class="text-danger">Account Refunded:</b><small> A account pay back (money), typically to a customer who is not satisfied with goods or services bought.</small>
                                    </label>
                                  </div>
                                  <div class="form-check">
                                    <label class="form-check-label">
                                      <input type="radio" class="form-check-input" name="paymentoptionsRadios" [formControl]='formAccounts.controls["option"]' value="credit" (change)="ShowPublicType(true)">
                                      <b class="text-primary">Store Credits:</b> <small> A store credit is a document offered by a store to a store to a customer who returns an item not eligible for a refund.</small>
                                    </label> 
                                  </div>
                                </div>
                              </fieldset>   
                              <h4 class="card-title bg-dark text-white text-center" [hidden]="hidePublicType"><i class="fas fa-credit-card"></i> Payment Type:</h4>
                              <div class="form-group" [hidden]="hidePublicType">
                                <label>Payment Type</label>
                                <select class="form-control" [formControl]='formAccounts.controls["paymenttype"]'>
                                  <option value="Cash" selected>Cash</option>
                                  <option value="Cheque" >Cheque</option>
                                  <option value="CreditCard">Credit Card</option>
                                  <option value="Cryptocurrency">Cryptocurrency</option>
                                  <option value="DebitCard">Debit Card</option>
                                  <option value="EFTPOS">EFTPOS</option>
                                  <option value="ElectronicBankTranfer">Electronic Bank Tranfer</option>
                                  <option value="MobilePayment">Mobile Payment</option>
                                  <option value="OnlinePayment">Online Payment</option>
                                </select>
                              </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-credit-card"></i> Description for Payment:</h4>
                          <app-area-text [formControl]='formAccounts.controls["description"]' [smalltext]='""' [label]='"Note"' [placeholder]='"Note"'></app-area-text>
                          <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-credit-card"></i> Date:</h4>
                          <app-date-input [formControl]='formAccounts.controls["date"]' [label]='"*Date"' [smalltext]='""' [placeholder]='"Date"'> </app-date-input>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="card-footer text-muted">
                      <button [disabled]="!formAccounts.valid" class="btn btn-success btn-block" form="editForm" (click)="createAccount()" >Create Payment Account</button>
                  </div>
                </div>
          </div>
          <div class="col-12 mt-4">
            <h1 class="text-center text-primary mt-2 font-weight-bold">Payment List</h1>
          </div>
          <div class="col-12">
            <app-clientlistaccountreceivable></app-clientlistaccountreceivable>
          </div>
      </div>
    </div>
</ng-container>