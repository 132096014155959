<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to go to the invoices page</h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/Go to Invoices Page Client/gtiac01.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	To view a specific client’s invoices, navigate to the “Clients” page and scroll down to find the “Clients List”  </h2>
    <h2>•	Then click the drop down arrow beside the relevant “Client”  </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/Go to Invoices Page Client/gtiac02.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Under the menu, click on “Open Invoice Accounts”</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/Go to Invoices Page Client/gtiac03.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	You are now on the “Invoices” page for that client</h2>
</div>