import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserGroupsPermissions } from 'src/app/_models/usergrouppermissions';
import { UsergroupspermissionsService } from 'src/app/_services/usergroups/usergroupspermissions.service';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator';
import { DialogaskuserareyousurewanttocreateusergroupComponent } from '../dialogaskuserareyousurewanttocreateusergroup/dialogaskuserareyousurewanttocreateusergroup.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-editusergroups',
  templateUrl: './editusergroups.component.html',
  styleUrls: ['./editusergroups.component.css']
})
export class EditusergroupsComponent implements OnInit {
  @ViewChild('editForm') editForm: NgForm;  
  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any){
    if (this.editForm.dirty){
      $event.returnValue = true;
    }
  }
  @Output() resetForm = new EventEmitter();  
  userMemberGroup: UntypedFormGroup;
  membergroup: UserGroupsPermissions;
  groupID: string;
  
  constructor(public fb: UntypedFormBuilder, private toastr: ToastrService, public services: UsergroupspermissionsService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.getParrams();
    this.onload();
  }

  updateUserMember(){
    this.setModel();  
    const message = this.checkReadAndWrite();
    if (message === "")
    {
      this.editForm.reset();
      this.initializeForm();
      this.router.navigate(['/members/usergroups']);          
      this.UpdateUserGroup();
      return;
    }
   
    const id: number = this.membergroup.id;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "50%";
    dialogConfig.height = "auto";
    dialogConfig.data = {
      id: id,
      createAccount: false,
      message: message
    };

    const dialogRef = this.dialog.open(DialogaskuserareyousurewanttocreateusergroupComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.createAccount)
      {
        this.UpdateUserGroup();
      }
    });

   
  }

  private UpdateUserGroup(){
  
    this.services.updateUserGroup(this.membergroup).subscribe(response => {
      if (response === true)
      {
        this.toastr.success('Updated User Group');    
   
 
      }
      else
      {
        this.toastr.error('Failed to Update User Group');
      }
    }, err => {
      console.log(err);
    });
  }

  initializeForm(){
    this.userMemberGroup = this.fb.group({
      groupname:['', [Validators.required]],
      readclients:['', [Validators.required]],
      readinvoices:['', [Validators.required]],
      readinstalments:['', [Validators.required]],
      readaccountpayment:['', [Validators.required]],
      readstripeaccount:['', [Validators.required]],
      refundstripepayment:['', [Validators.required]],
      readstorecredit:['', [Validators.required]],
      updatestatusstripe:['', [Validators.required]],
      isadmin:['', [Validators.required]],
      writeclients:['', [Validators.required]],
      writeinvoices:['', [Validators.required]],
      writeinstalments:['', [Validators.required]],
      writeaccountpayment:['', [Validators.required]],
      writestoreaccount:['', [Validators.required]],
      canusemessages:['', [Validators.required]],
      canuserimport:['', [Validators.required]],
      canuserapi:['', [Validators.required]],
      candeleteclient:['', [Validators.required]],
      candeletestore:['', [Validators.required]],
      candeleteinvoice:['', [Validators.required]],
      candeleteinstalment:['', [Validators.required]],
      candeletepayment:['', [Validators.required]],
      canarchiveclient:['', [Validators.required]],
      canarchiveinvoice:['', [Validators.required]],
      canarchiveinstalment:['', [Validators.required]],
      canarchivepayment:['', [Validators.required]],
      canarchivestore:['', [Validators.required]],
    })
  }

  setModel(){
    const id: number = this.membergroup.id;
    this.membergroup = {
      id: id,
      groupName: this.userMemberGroup.value.groupname,
      canReadAccountPayments: this.userMemberGroup.value.readaccountpayment,
      canReadClient: this.userMemberGroup.value.readclients,
      canReadInstalments: this.userMemberGroup.value.readinstalments,
      canReadInvoice: this.userMemberGroup.value.readinvoices,
      canReadStripeAccount: this.userMemberGroup.value.readstripeaccount,
      canUseMessages: this.userMemberGroup.value.canusemessages,      
      canReadStoreAccount: this.userMemberGroup.value.readstorecredit,
      canRefundStripeAccount: this.userMemberGroup.value.refundstripepayment,
      canUpdateStatusStripeAccount: this.userMemberGroup.value.updatestatusstripe,
      canUseAPI: this.userMemberGroup.value.canuserapi,
      canUseImport: this.userMemberGroup.value.canuserimport,
      canWriteAccountPayments: this.userMemberGroup.value.writeaccountpayment,
      canWriteClient: this.userMemberGroup.value.writeclients,
      canWriteInstalments: this.userMemberGroup.value.writeinstalments,
      canWriteInvoice: this.userMemberGroup.value.writeinvoices,
      canWriteStoreAccount: this.userMemberGroup.value.writestoreaccount,
      isAdmin: this.userMemberGroup.value.isadmin,
      canDeleteClientAccount: this.userMemberGroup.value.candeleteclient,
      canDeleteStoreAccount: this.userMemberGroup.value.candeletestore,
      canDeleteInvoiceAccount: this.userMemberGroup.value.candeleteinvoice,
      canDeleteInstalmentAccount: this.userMemberGroup.value.candeleteinstalment,
      canDeletePaymentAccount: this.userMemberGroup.value.candeletepayment,
      canArchiveClientAccount: this.userMemberGroup.value.canarchiveclient,
      canArchiveInvoiceAccount: this.userMemberGroup.value.canarchiveinvoice,
      canArchiveInstalmentAccount: this.userMemberGroup.value.canarchiveinstalment,
      canArchivePaymentAccount: this.userMemberGroup.value.canarchivepayment,
      canArchiveStoreAccount: this.userMemberGroup.value.canarchivestore,
    } 
  }

  onload(){
    this.services.getUserGroup(this.groupID).subscribe(response => {
      this.membergroup = response;
      console.log(this.membergroup);
      this.userMemberGroup.controls['groupname'].setValue(this.membergroup.groupName);
      this.userMemberGroup.controls['readclients'].setValue(String(this.membergroup.canReadClient));
      this.userMemberGroup.controls['readinvoices'].setValue(String(this.membergroup.canReadInvoice));
      this.userMemberGroup.controls['readinstalments'].setValue(String(this.membergroup.canReadInstalments));
      this.userMemberGroup.controls['readaccountpayment'].setValue(String(this.membergroup.canReadAccountPayments));
      this.userMemberGroup.controls['readstripeaccount'].setValue(String(this.membergroup.canReadStripeAccount));``
      this.userMemberGroup.controls['refundstripepayment'].setValue(String(this.membergroup.canRefundStripeAccount));
      this.userMemberGroup.controls['readstorecredit'].setValue(String(this.membergroup.canReadStoreAccount));
      this.userMemberGroup.controls['updatestatusstripe'].setValue(String(this.membergroup.canUpdateStatusStripeAccount));
      this.userMemberGroup.controls['isadmin'].setValue(String(this.membergroup.isAdmin));
      this.userMemberGroup.controls['writeclients'].setValue(String(this.membergroup.canWriteClient));
      this.userMemberGroup.controls['writeinvoices'].setValue(String(this.membergroup.canWriteInvoice));
      this.userMemberGroup.controls['writeinstalments'].setValue(String(this.membergroup.canWriteInstalments));
      this.userMemberGroup.controls['writeaccountpayment'].setValue(String(this.membergroup.canWriteAccountPayments));
      this.userMemberGroup.controls['writestoreaccount'].setValue(String(this.membergroup.canWriteStoreAccount));
      this.userMemberGroup.controls['canuserimport'].setValue(String(this.membergroup.canUseImport));
      this.userMemberGroup.controls['canuserapi'].setValue(String(this.membergroup.canUseAPI));
      this.userMemberGroup.controls['candeleteclient'].setValue(String(this.membergroup.canDeleteClientAccount));
      this.userMemberGroup.controls['canusemessages'].setValue(String(this.membergroup.canUseMessages));
      this.userMemberGroup.controls['candeletestore'].setValue(String(this.membergroup.canDeleteStoreAccount));
      this.userMemberGroup.controls['candeleteinvoice'].setValue(String(this.membergroup.canDeleteInvoiceAccount));
      this.userMemberGroup.controls['candeleteinstalment'].setValue(String(this.membergroup.canDeleteInstalmentAccount));
      this.userMemberGroup.controls['candeletepayment'].setValue(String(this.membergroup.canDeletePaymentAccount));
      this.userMemberGroup.controls['canarchiveinvoice'].setValue(String(this.membergroup.canArchiveInvoiceAccount));
      this.userMemberGroup.controls['canarchiveinstalment'].setValue(String(this.membergroup.canArchiveInstalmentAccount));
      this.userMemberGroup.controls['canarchivepayment'].setValue(String(this.membergroup.canArchivePaymentAccount));
      this.userMemberGroup.controls['canarchiveclient'].setValue(String(this.membergroup.canArchiveClientAccount));
      this.userMemberGroup.controls['canarchivestore'].setValue(String(this.membergroup.canArchiveStoreAccount));
    }, err => {
      console.log(err);
    })
  }

  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.groupID = params.get("id");
    });
  }

  private checkReadAndWrite(): string {
    /* Read Accounts */
    const readAccountpayment = this.userMemberGroup.value.readaccountpayment;
    const readClient = this.userMemberGroup.value.readclients;
    const readInstalment = this.userMemberGroup.value.readinstalments;
    const readInvoices = this.userMemberGroup.value.readinvoices;   
    const readStoreAccounts = this.userMemberGroup.value.readstorecredit;

    /* Write Accounts */
    const writeAccountPayments = this.userMemberGroup.value.writeaccountpayment;
    const writeClients = this.userMemberGroup.value.writeclients;
    const writeinstalments = this.userMemberGroup.value.writeinstalments;
    const writeInvoices = this.userMemberGroup.value.writeinvoices;
    const writeStoreAccounts = this.userMemberGroup.value.writestoreaccount;

    /* Delete Accounts */
    const deleteAccountPayments = this.userMemberGroup.value.candeletepayment;
    const deleteClients = this.userMemberGroup.value.candeleteclient;
    const deleteinstalments = this.userMemberGroup.value.candeleteinstalment;
    const deleteInvoices = this.userMemberGroup.value.candeleteinvoice;
    const deleteStoreAccounts = this.userMemberGroup.value.candeletestore;

    /* Archive Accounts */
    const archiveAccountPayments = this.userMemberGroup.value.canarchivepayment;
    const archiveClients = this.userMemberGroup.value.canarchiveclient;
    const archiveinstalments = this.userMemberGroup.value.canarchiveinstalment;
    const archiveInvoices = this.userMemberGroup.value.canarchiveinvoice;
    const archiveStoreAccounts = this.userMemberGroup.value.canarchivestore;

    /* Stripe Accounts */
    const readstripeaccount = this.userMemberGroup.value.readstripeaccount;
    const striperefund = this.userMemberGroup.value.refundstripepayment;   
    const stripeupdatestatus = this.userMemberGroup.value.updatestatusstripe;

    /* Write Accounts */
    if (readAccountpayment === "false" && writeAccountPayments === "true")
      return "There’s a problem with both account payments read and account payments write, which implies that you won’t be able to communicate with your account payments accounts. If you wish to resolve this issue, both fields need to be set to “Yes” or “No”. Are you certain you want to continue with the update of a user group?";
    if (readClient === "false" && writeClients === "true")
      return "There’s a problem with both client read and client write, which implies that you won’t be able to communicate with your client accounts. If you wish to resolve this issue, both fields need to be set to “Yes” or “No”. Are you certain you want to continue with the update of a user group?";
    if (readInstalment === "false" && writeinstalments === "true")
      return "There’s a problem with both instalment read and instalment write, which implies that you won’t be able to communicate with your instalment accounts. If you wish to resolve this issue, both fields need to be set to “Yes” or “No”. Are you certain you want to continue with the update of a user group?";
    if (readInvoices === "false" && writeInvoices === "true")
      return "There’s a problem with both invoices read and invoices write, which implies that you won’t be able to communicate with your invoices accounts. If you wish to resolve this issue, both fields need to be set to “Yes” or “No”. Are you certain you want to continue with the update of a user group?";
    if (readStoreAccounts === "false" && writeStoreAccounts === "true")
      return "There’s a problem with both store accounts read and store accounts write, which implies that you won’t be able to communicate with your store accounts. If you wish to resolve this issue, both fields need to be set to “Yes” or “No”. Are you certain you want to continue with the update of a user group?";
   
    /* Delete Accounts */
    if (readAccountpayment === "false" && deleteAccountPayments === "true")
      return "There’s a problem with both account payments read and account payments delete, which implies that you won’t be able to communicate with your account payments accounts. If you wish to resolve this issue, both fields need to be set to “Yes” or “No”. Are you certain you want to continue with the update of a user group?";
    if (readClient === "false" && deleteClients === "true")
      return "There’s a problem with both client read and client delete, which implies that you won’t be able to communicate with your client accounts. If you wish to resolve this issue, both fields need to be set to “Yes” or “No”. Are you certain you want to continue with the update of a user group?";
    if (readInstalment === "false" && deleteinstalments === "true")
      return "There’s a problem with both instalment read and instalment delete, which implies that you won’t be able to communicate with your instalment accounts. If you wish to resolve this issue, both fields need to be set to “Yes” or “No”. Are you certain you want to continue with the update of a user group?";
    if (readInvoices === "false" && deleteInvoices === "true")
      return "There’s a problem with both invoices read and invoices delete, which implies that you won’t be able to communicate with your invoices accounts. If you wish to resolve this issue, both fields need to be set to “Yes” or “No”. Are you certain you want to continue with the update of a user group?";
    if (readStoreAccounts === "false" && deleteStoreAccounts === "true")
      return "There’s a problem with both store accounts read and store accounts delete, which implies that you won’t be able to communicate with your store accounts. If you wish to resolve this issue, both fields need to be set to “Yes” or “No”. Are you certain you want to continue with the update of a user group?";
   
   
    /* Archive Accounts */
    if (readAccountpayment === "false" && archiveAccountPayments === "true")
      return "There’s a problem with both account payments read and account payments archive, which implies that you won’t be able to communicate with your account payments accounts. If you wish to resolve this issue, both fields need to be set to “Yes” or “No”. Are you certain you want to continue with the update of a user group?";
    if (readClient === "false" && archiveClients === "true")
      return "There’s a problem with both client read and client archive, which implies that you won’t be able to communicate with your client accounts. If you wish to resolve this issue, both fields need to be set to “Yes” or “No”. Are you certain you want to continue with the update of a user group?";
    if (readInstalment === "false" && archiveinstalments === "true")
      return "There’s a problem with both instalment read and instalment archive, which implies that you won’t be able to communicate with your instalment accounts. If you wish to resolve this issue, both fields need to be set to “Yes” or “No”. Are you certain you want to continue with the update of a user group?";
    if (readInvoices === "false" && archiveInvoices === "true")
      return "There’s a problem with both invoices read and invoices archive, which implies that you won’t be able to communicate with your invoices accounts. If you wish to resolve this issue, both fields need to be set to “Yes” or “No”. Are you certain you want to continue with the update of a user group?";
    if (readStoreAccounts === "false" && archiveStoreAccounts === "true")
      return "There’s a problem with both store accounts read and store archive write, which implies that you won’t be able to communicate with your store accounts. If you wish to resolve this issue, both fields need to be set to “Yes” or “No”. Are you certain you want to continue with the update of a user group?";
    
    /* Stripe Accounts */
    if (readstripeaccount === "false" && striperefund === "true")
      return "There’s a problem with both read stripe account and refund stripe payments, which implies that you won’t be able to communicate with your account payments accounts. If you wish to resolve this issue, both fields need to be set to “Yes” or “No”. Are you certain you want to continue with the update of a user group?";
    if (readstripeaccount === "false" && stripeupdatestatus === "true")
      return "There’s a problem with both read stripe account and update stripe account, which implies that you won’t be able to communicate with your client accounts. If you wish to resolve this issue, both fields need to be set to “Yes” or “No”. Are you certain you want to continue with the update of a user group?";  
    return "";
  }

}
