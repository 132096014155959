<app-nav></app-nav>
<div class="container">
  <div class="container">
    <div class="row">
        <div class="col-12 mt-4">
          <nav class="navbar navbar-light bg-primary shadow-lg">
            <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Import Features</a>
          </nav>
        </div>
    </div>
    <div class="card border-info mb-3">  
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xxl-12 mb-5">
              <div class="card bg-white h-100 shadow-lg">
                  <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                      <img src="/assets/images/sorry.jpg" alt="SMS" class="img-thumbnail">                
                  </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xxl-12 mb-5">
              <h1 class="card-title text-primary"><b>Sorry!</b> This feature isn't ready as of yet.</h1>
              <p class="card-text">Please stay tuned for future updates</p>
            </div>
          </div>
        </div>
    </div>
</div>
<div class="container" [hidden]="true">
    <div class="row"> 
      <ng-container *ngIf="userCanUse">   
        <ng-container *ngIf="listMode === false">
        <div class="col-12 mt-4">
          <nav class="navbar navbar-light bg-primary shadow-lg">
            <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Import File</a>  
          </nav>
        </div>
          <div class="col-8 mt-4">
              <div class="card mt-4 border-info">
                  <div class="card-body">
                    <h2 class="text-center text-primary mt-2">Upload Invoice Accounts</h2>
                    <hr>
                    <form [formGroup]="checkoutImport" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                          <div class="bs-component" *ngIf="shoeErrorMsg">
                            <div class="alert alert-dismissible alert-danger">
                              <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                              <strong>Error!</strong> Please only upload spreedsheet extension file. XLSX or XLS file extension only. Also, make
                              sure you have the right data in the right order.
                            </div>
                           </div>
                          <legend>File</legend>
                          <label for="exampleInputEmail1">Upload File</label>
                          <input type="file" class="form-control" id="exampleInputEmail1" placeholder="Upload File" (change)="onFileSelected($event)">
                          <small id="emailHelp" class="form-text text-muted">Upload XLSX or XLS. Speedsheet documents!</small>
                        </div>
                        <fieldset class="form-group">
                          <legend>Data Types</legend>
                          <small id="emailHelp" class="form-text text-muted">Add in what types of data you wish to add in for your client accounts.</small>
                          <div class="form-check">
                            <label class="form-check-label">
                              <input class="form-check-input" type="checkbox" value="" (change)="onChangeCheckBox($event, 'Note', 8)" formControlName="note">
                              Note / Comments
                            </label>
                          </div>
                        </fieldset>
                        <button type="submit" class="btn btn-primary" [disabled]="selectedFile === null">Upload</button>
                      </form>
                  </div>
                </div>
          </div>
          <div class="col-4">
            <div class="card mt-4 border-info">
              <div class="card-body">
                <h2 class="text-center text-primary mt-2">Must Be Set As Below </h2>        
                <legend class="text-center">Order List</legend>
                <small id="emailHelp" class="form-text text-muted text-center">Please make sure you it's from left to right column!</small>
                <div class="bs-component" *ngIf="orderList">
                    <ul class="list-group" *ngFor="let item of orderList; let element = index;">
                      <ng-container *ngIf="item.show === 1">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          <span class="badge badge-primary badge-pill">{{element + 1}}</span>
                          {{item.type}}       
                        </li>
                      </ng-container>
                    </ul>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!userCanUse">
        <div class="row">
          <div class="col-12 mt-4">
            <h1 class="text-center text-primary mt-2 font-weight-bold">Import Invoices</h1>
          </div>
       </div>
        <div class="card border-info mb-3">
          <div class="card-body">
            <h1 class="card-title">Import Invoices Automations requires permissions first</h1>
            <p class="card-text">You will need to upgrade your plans before you can use this feature. Sorry about this!</p><button class="btn btn-success" routerLink="/members/subscriptions">Upgrade My Plan Now</button>
          </div>
        </div>
      </ng-container>
    </div>
    <app-importlistinvoices *ngIf="listMode === true" [usersFromImportFile]="importFiles"></app-importlistinvoices>
    