<ng-container *ngIf="(instalmentService.instalment$ | async) !== null" >
    <div class="col-12 mt-4">
        <div class="bs-component">
          <div class="alert alert-dismissible alert-primary">
            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
            <h1>How Messages Works!</h1>
            <strong>To send messages to clients, ensure that the difference between the total invoice and the cost is consistent with non-archived installment accounts. If it doesn't meet the criteria, messages won't be sent to your clients. If payment accounts are manually included, verify that the total payments in the account either match or exceed the original invoice amount minus the default paid sum. Fulfilling this criterion prevents messages from being dispatched to your clients. This outlines how the messaging system operates.
            </strong>
          </div>
        </div>
        <h1 class="text-center text-primary mt-2 font-weight-bold">List of Instalments</h1>
    </div>
    <div class="bs-component" *ngIf="showErrorInitialMessage">
      <div class="alert alert-dismissible alert-danger">
        <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
        <strong>Failed to send messages! </strong>
      </div>
    </div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary rounded ">
        <div class="container-fluid">
          <a class="navbar-brand"><i class="fas fa-tools fa-lg"></i></a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="form-group mt-3 ms-4">
            <select class="form-select" (change)="onChangeArchive($event)">
              <option value="1">Search all account instalments non-archive</option>
              <option value="2">Search all account instalments with archive</option>
            </select>
          </div>
          <div class="collapse navbar-collapse" id="navbarColor01">
            <ul class="navbar-nav me-auto">
              <li class="nav-item">
                <button class="btn btn-outline-light my-2 my-sm-0 font-weight-bold ms-4" (click)="sendInitalizationForInitialMessages()"><i class="fas fa-mobile-alt fa-xl"></i>  Send Now The First Instalment Message (Only the First One On Top Of the List)</button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="alert alert-dismissible alert-primary d-block d-sm-none mt-4">
        <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
        <h4 class="alert-heading">Advise!</h4>
        <p class="mb-0">If you are using your mobile phone. For the best user experience, we recommend switching your view on your mobile phone into landscape mode, instead of using portrait mode. Sorry for any inconvenience we have caused here. We are committed to fixing the issue here. </p>
      </div>
    <table mat-table [dataSource]="instalmentService.instalmentDataSource" class="mat-elevation-z8 ">   
        <!-- No. Column -->
        <ng-container matColumnDef="no">
          <th mat-header-cell *matHeaderCellDef scope="col"> No. </th>
          <td mat-cell *matCellDef="let account; let i = index; " class="w-10" class="w-5"><b>{{i + 1}}</b></td>
        </ng-container>
      
        <!-- Due Date Column -->
        <ng-container matColumnDef="duedate">
          <th mat-header-cell *matHeaderCellDef scope="col"> Due Date </th>
          <td mat-cell *matCellDef="let account" class="text-primary font-weight-bold w-15"> {{account.dueDate | date:'mediumDate' }} </td>
        </ng-container>

        <!-- Initial Date Column -->
        <ng-container matColumnDef="initialdate">
          <th mat-header-cell *matHeaderCellDef scope="col"> Initial Date Created </th>
          <td mat-cell *matCellDef="let account" class="text-secondary font-weight-bold w-15"> {{account.initialDate | date:'mediumDate' }} </td>
        </ng-container>

        <!-- Cost Amount Column -->
        <ng-container matColumnDef="costamount">
          <th mat-header-cell *matHeaderCellDef scope="col"> Cost </th>
          <td mat-cell *matCellDef="let account" class="font-weight-bold" class="w-15"> {{account.costAmount | currency }} </td>
        </ng-container>

        <!-- Add fee Column -->
        <ng-container matColumnDef="optioninitialsent">
          <th mat-header-cell *matHeaderCellDef scope="col"> Can Initial Message be Sent Out! </th>
          <td mat-cell *matCellDef="let account" class="w-5">
            <ng-container *ngIf="account.sendInitialDateAfterFirstPayment;else shownoinitialmessagecango">
              <span class="badge bg-primary">Yes</span>
            </ng-container>
            <ng-template #shownoinitialmessagecango>
              <span class="badge bg-danger">No</span>
            </ng-template>          
          </td>
        </ng-container>

        <!-- Add fee Column -->
        <ng-container matColumnDef="addinitalpayment">
        <th mat-header-cell *matHeaderCellDef scope="col"> Send Initial Message Sent! </th>
        <td mat-cell *matCellDef="let account" class="w-5">
          <ng-container *ngIf="account.initialDateSent;else shownoinitialsent">
            <span class="badge bg-success">Yes</span>
          </ng-container>
          <ng-template #shownoinitialsent>
            <span class="badge bg-warning">No</span>
          </ng-template>          
        </td>
        </ng-container>

        <!-- Add fee Column -->
        <ng-container matColumnDef="receivepayment">
            <th mat-header-cell *matHeaderCellDef scope="col"> Receive Payment Message </th>
            <td mat-cell *matCellDef="let account" class="w-5">
              <ng-container *ngIf="account.receivePaymentMessage;else shownoreceivepayment">
                <span class="badge bg-success">Yes</span>
              </ng-container>
              <ng-template #shownoreceivepayment>
                <span class="badge bg-warning">No</span>
              </ng-template>
            </td>
        </ng-container>

        <!-- Add fee Column -->
        <ng-container matColumnDef="duedatesent">
            <th mat-header-cell *matHeaderCellDef scope="col"> Due Date Message Sent! </th>
            <td mat-cell *matCellDef="let account" class="w-5">
              <ng-container *ngIf="account.dueDateSent;else shownoduedatesent">
                <span class="badge bg-success">Yes</span>
              </ng-container>
              <ng-template #shownoduedatesent>
                <span class="badge bg-warning">No</span>
              </ng-template>
            </td>
        </ng-container>

        
        <!-- Add fee Column -->
        <ng-container matColumnDef="addfee">
            <th mat-header-cell *matHeaderCellDef scope="col"> Add Fees</th>
            <td mat-cell *matCellDef="let account" class="w-5">
              <ng-container *ngIf="account.addFees;else shownofees">
                <span class="badge bg-primary">Yes</span>
              </ng-container>
              <ng-template #shownofees>
                <span class="badge bg-danger">No</span>
              </ng-template>
            </td>
        </ng-container>
      
        <!-- Note Column -->
        <ng-container matColumnDef="note">
          <th mat-header-cell *matHeaderCellDef scope="col"> Note </th>
          <td mat-cell *matCellDef="let account" class="w-15"> {{account.note}} </td>
        </ng-container>
      
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef scope="col"> </th>
            <td mat-cell *matCellDef="let account" class="w-10"> 
                <button mat-icon-button [matMenuTriggerFor]="menu" color="primary" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item color="primary" (click)="editinstalment(account.id, account.costAmount)">
                      <mat-icon color="primary">account_box</mat-icon>
                      <span>Edit Instalment Account</span>
                    </button>
                    <button mat-menu-item color="warn" (click)="resetInitialMessage(account.id)">
                      <mat-icon color="primary">local_post_office</mat-icon>
                      <span>Reset Initial Message</span>
                    </button>
                    <button mat-menu-item color="warn" (click)="archiveInstalment(account.id, account.archive)">
                        <mat-icon color="primary">delete</mat-icon>
                        <span>Archive Account</span>
                      </button>
                    <button mat-menu-item color="warn" (click)="deleteInstalment(account.id)">
                      <mat-icon color="primary">delete</mat-icon>
                      <span>Delete Account</span>
                    </button>
                </mat-menu> 
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10000]" aria-label="Select page of users"></mat-paginator>
</ng-container>