import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MessagesService } from 'src/app/_services/message/messages.service';

@Component({
  selector: 'app-changetimeformat',
  templateUrl: './changetimeformat.component.html',
  styleUrls: ['./changetimeformat.component.css']
})
export class ChangetimeformatComponent implements OnInit {
  public data: any;
  @ViewChild('editForm') editForm: NgForm; 
  formEdit: UntypedFormGroup;
  time: Date;
  timeZone: string;



  constructor(public fb: UntypedFormBuilder, private service: MessagesService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.data = 1;
    this.initializeForm();
    this.onLoad();
  }

  initializeForm(){
    this.formEdit = this.fb.group({
      changeText:['', Validators.required],
    }); 
    
    this.formEdit.controls['changeText'].setValue('');
  }
  
  onSubmit(){
    const timezone = {
      TimeFormat: this.formEdit.controls['changeText'].value
    }
    this.service.updateTimeZone(timezone).subscribe(response => {
      console.log(response); 
      this.toastr.success("Updated Message!");
     }, error => {
       console.log(error);
     })
    
  }


  onLoad(){
    this.service.getTimeZone().subscribe(response => {
      this.data = response;
      this.timeZone = this.data.timeFormat;
    }, error => {
      console.log(error);
    })
  }


}
