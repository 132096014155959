import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorialcreateinvoice',
  templateUrl: './tutorialcreateinvoice.component.html',
  styleUrls: ['./tutorialcreateinvoice.component.css']
})
export class TutorialcreateinvoiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
