import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { error } from 'selenium-webdriver';
import { ChargePayment } from 'src/app/_models/chargepayment';
import { CreateCheckoutDto } from 'src/app/_models/checkout';
import { ISession } from 'src/app/_models/isession';
import { User } from 'src/app/_models/user';
import { ValidatePaymentCharge } from 'src/app/_models/validatepaymentcharge';
import { environment } from 'src/environments/environment';
import { StripeaccountService } from '../stripeaccount/stripeaccount.service';
import { ReplaySubject } from 'rxjs';

declare const Stripe;

@Injectable({
  providedIn: 'root'
})
export class StripepaymentService {
  private baseUrl =  environment.apiUrl ;
  private user?: User;
  private paymentCustomerClientHasPay = new ReplaySubject<number>(1);
  paymentCustomerClientHasPay$ = this.paymentCustomerClientHasPay.asObservable();
  showNonArchive$: boolean = false;
  constructor(private http: HttpClient, private stripeServices: StripeaccountService, private toastr: ToastrService) { }

  customerMakePayment(charge: any){
    console.log('got');
    return this.http.post(this.baseUrl + 'payments/chargepayment', charge);
  }

  getChargePayment(customerReference: string, code: string){
    const reference = {
      code: code,
      ID: customerReference
    };

    return this.http.post<ChargePayment>(this.baseUrl + 'payments/getchargepayment', reference).pipe( 
      map(payment => {
        return payment;
      }));  
  }

  createCheckout(checkout: CreateCheckoutDto): void {     

    this.http.post<ISession>(this.baseUrl + 'payments/create-checkout-session', {
      id: checkout.id,
      token: checkout.token
    })
    .subscribe((session : any) => {
      if (session == null)
      {
        this.toastr.error('Session ID is null!');
        return;
      }

      this.redirectToCheckout(session.sessionId, session.publicKey);
    }, err => {
      console.log(err);
      if (err !== "Account has been paid!")
        this.toastr.error("Error! " + err);
    });
  } 

  requestMemberSession(priceId: string): void {
    this.http.post<ISession>(this.baseUrl + 'payments/create-checkout-session', {
      priceId: priceId
    })
    .subscribe((session : any) => {
      this.redirectToCheckout(session.sessionId, session.publicKey);
    });
  }

  redirectToCheckout(sessionId: string, publicKeyID: string){
    const stripe = Stripe(publicKeyID);
    stripe.redirectToCheckout({
      sessionId: sessionId,
    })
  } 

  chargepayment(id: string): void{
    this.paymentCustomerClientHasPay.next(null);
    this.http.post(this.baseUrl + 'payments/createcharge', {
      ID: id,
    })
      .subscribe((result: boolean) => {
        if (result)
        {
          this.toastr.success("Your payment has been successful!");
          this.stripeServices.stopUpdateStripeAccountsHubConnection();
          this.stripeServices.createHubUpdateStripeAccountConnection(null);
          this.paymentCustomerClientHasPay.next(1);
          return;
        }
        this.toastr.info("Good news! You have already made your payment. No need to do this again.");      
        this.paymentCustomerClientHasPay.next(2);
      }, error => {
        this.paymentCustomerClientHasPay.next(3);
        console.log(error);
      });
  }

  checkInvoiceValidation(validation: ValidatePaymentCharge)
  {
    return this.http.post(this.baseUrl + 'payments/getvalidationInvoice', validation);    
  }


}
