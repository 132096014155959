import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { ClientService } from 'src/app/_services/client/client.service';

@Component({
  selector: 'app-archiveclient',
  templateUrl: './archiveclient.component.html',
  styleUrls: ['./archiveclient.component.css']
})
export class ArchiveclientComponent implements OnInit {
  accountID: number;
  user?: User;
  archive: boolean = false;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data, private clientService: ClientService, private accountService: AccountService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.accountID = this.data.id;
    this.archive = this.data.archive;
    this.assignUser();
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  archiveAccount(archive: boolean){
    const account = {
      id: this.accountID,
      archive: archive
    };

    this.clientService.archiveClient(account).subscribe( response => {
      if (response)
      {
        this.data.deleteClient = true;
        this.clientService.stopHubConnection();   
        this.clientService.stopArchiveHubConnection();
        this.clientService.createArchiveHubConnection(this.user);
        this.clientService.createHubConnection(this.user); 
        this.toastr.success("You have archive successfully this account");
      }
      else
      {
        this.toastr.error("Account doesn't exist or you don't have permissions to access it. Sorry, you can't archive this account.");
      }
  
    }, error => {
      console.log(error);
    })
  } 
}
