import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private toastr: ToastrService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error) {
          switch (error.status) {
            case 400:
        
              if (error.error.errors)
              {
           
                const modalStateErrors = [];

                console.log(error.error.errors);

                /* 
                  General Things                   
                */
                if (error.error.errors.Date != null)
                {
                  this.toastr.error(error.error.errors.Date);
                }  

                if (error.error.errors.Comment != null)
                {
                  this.toastr.error(error.error.errors.Comment);

                }  

                if (error.error.errors.comment != null)
                {
                  this.toastr.error(error.error.errors.comment);
                }  

                if (error.error.errors.note != null)
                {
                  this.toastr.error(error.error.errors.note);
                }  

                if (error.error.errors.amount != null)
                {
                  this.toastr.error(error.error.errors.amount);
                }  

                /* 
                  End of General Things
                  For Client and username
                */
                if (error.error.errors.Password != null)
                {
                  this.toastr.error(error.error.errors.Password);
                }                  

                if (error.error.errors.Email != null)
                {
                  this.toastr.error(error.error.errors.Email);
                }     

                if (error.error.errors.Username != null)
                {
                  this.toastr.error(error.error.errors.Username);
                }    

                if (error.error.errors.ContactMobile != null)
                {
                  this.toastr.error(error.error.errors.ContactMobile);
                }     

                if (error.error.errors.ContactBusiness != null)
                {
                  this.toastr.error(error.error.errors.ContactBusiness);
                }    

                if (error.error.errors.ContactHomePhone != null)
                {
                  this.toastr.error(error.error.errors.ContactHomePhone);
                }                    
                
                if (error.error.errors.FirstName != null)
                {
                  this.toastr.error(error.error.errors.FirstName);
                }     

                if (error.error.errors.LastName != null)
                {
                  this.toastr.error(error.error.errors.LastName);
                }     

                if (error.error.errors.Code != null)
                {
                  this.toastr.error(error.error.errors.Code);
                }      
                
                if (error.error.errors.abn != null)
                {
                  this.toastr.error(error.error.errors.abn);
                }   
                
                if (error.error.errors.Phone != null)
                {
                  this.toastr.error(error.error.errors.Phone);
                }   

                if (error.error.errors.Referal != null)
                {
                  this.toastr.error(error.error.errors.Referal);
                }   

                if (error.error.errors.ClientSecretCode != null)
                {
                  this.toastr.error(error.error.errors.ClientSecretCode);
                }     
                
                if (error.error.errors.BussinessName != null)
                {
                  this.toastr.error(error.error.errors.BussinessName);
                }    

                /*
                  End of Client & User Name
                  Start on Invoice & account Payments & account instalments 
                */
                if (error.error.errors.invoiceID != null)
                {
                  this.toastr.error(error.error.errors.InvoiceID);
                }   

                if (error.error.errors.invoiceAmount != null)
                {
                  this.toastr.error(error.error.errors.invoiceAmount);
                }                
                

                if (error.error.errors.paidAmount != null)
                {
                  this.toastr.error(error.error.errors.paidAmount);
                }                             

                if (error.error.errors.paymentAmount != null)
                {
                  this.toastr.error(error.error.errors.paymentAmount);
                }  

                if (error.error.errors.costAmount != null)
                {
                  this.toastr.error(error.error.errors.costAmount);
                }
                            
                if (error.error.errors.initialDate != null)
                {
                  this.toastr.error(error.error.errors.initialDate);
                }

                if (error.error.errors.dueDate != null)
                {
                  this.toastr.error(error.error.errors.dueDate);
                }                            

                for (const key in error.error.errors){
                  if (error.error.errors[key])
                  {
                    modalStateErrors.push(error.error.errors[key])         
                  }
                  throw modalStateErrors.flat();
                }
              }
              else
              {               
                if (error.error == "You are currently enrolled in the same subscription plan. Please wait until the end of the current subscription model’s lifetime before choosing to renew it.")
                {
                  this.toastr.error(error.error);
                }
                if (error.error == "No User Exist!")
                {
                  this.toastr.error(error.error);
                }
                if (error.error == "Your account is already assigned to a subscription base model. Please cancel current subscription!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Date Format is invalid!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Your invoice account for making these changes is archive. If you wish to make some changes then you must go back and unarchive it! Also, you might want try to disable archive on your client account.")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "For security reasons, this account has been created more than 24 hours ago. You can't delete this account. You can only archive it.")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Your client account for making these changes is archive. If you wish to make some changes then you must go back and unarchive it!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Emailed not confirmed!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "You current instalment must be greater than your previous instalment due dates!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "You can't pick due date instalment futher ahead of the current due date!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Due Date cannot be less than previous instalment due date!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "You can't add instalment accounts while your default paid amount is equal to invoice amount!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "You can't create instalments when you have already made all your payments for this invoice!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Due Date instalments from the second row and any below that must be greater than current date!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "This is an archive account! You can't edit this.")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "You can't pick futher back than the current due date!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Username is taken")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "You can't edit instalment archive account!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Failed! This archive account is locked. You can't change it!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Your total payment amount paid is equal or greater than invoice amount!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Reach Maximum limit on users!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Email has already been signed into group!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Your client email invalid!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Your client mobile contact is invalid!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Failed! A customer on yours has attempted, or has paid something to this account. You cannot delete or alter this account!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Failed! A customer on yours has paid something to this account. You cannot delete or alter this account!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "You cant use your own email account!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Your Account has been lockout!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "No permission group found!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Two Factor Authentication is disabled!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Failed to read Stripe Account API!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Two Factor Authentication is already enabled!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Email must be Confirmed!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Failed to send email!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Group Permissions: Access Denied!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Already Paid For The Product")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "No Client ID Exist!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "No File Exist!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Your email account has already been activated! You need to disband current group before you can join other groups.")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Can't read data!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Duplicate records already exist for first name & last name & middle name")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Errors in valdiation")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Your Client Already Exist In Our Database!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "ABN Requires minumum length of 11 characters!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Password requires 8 characters long and also requires lower and upper characters and special characters!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Old Password is invalid")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Failed to update Stripe Account!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Date is invalid!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Your stripe account isn't ready yet!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "This payment account invalid!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Record already exist!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "No client found!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "The main account for this account has cancel subscription!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "You already have joined a group. You will need to disband your original group before joining this group.")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "No Accounts found!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "In order to access this, you need to enroll in a subscription plan.")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "No Account found!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Failed! You cannot edit this account while you have data inside it.")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "You can't create client! You will need to contact Invoicepager to setup your Stripe Account.")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Invalid File Extension!")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "No Permission Granted")
                {
                  this.toastr.error(error.error);
                }
                else if (error.error == "Account payments has been all filled!")
                {
                  this.toastr.error(error.error);
                }
                else
                {
                  this.toastr.error(error.statusText, error.status);
                }
              }
              break;
            case 401:            
              if (error.error = "Invalid Password")            
              {
                this.toastr.error(error.error);
              }
              else  
              {
                this.toastr.error(error.statusText, error.status);
              }
                       
              break;
            case 404:
              this.router.navigateByUrl('/not-found');
              break;
            case 500:
              const navigiationExtras: NavigationExtras = { state: {error: error.error}};
              this.router.navigateByUrl('/server-error', navigiationExtras);
              break;
            default:
             
              this.toastr.error('Unable to reach server!');
              console.log(error);
              break;          
          }
        }
        return throwError(error);        
      })
    );
  }
}
