import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorialhome',
  templateUrl: './tutorialhome.component.html',
  styleUrls: ['./tutorialhome.component.css']
})
export class TutorialhomeComponent implements OnInit {

  showtutorial: boolean[];
  constructor() { }

  ngOnInit(): void {
    this.setdefaulttutorial();
    this.showtutorial[0] = true;
  }

  setdefaulttutorial(): void {
    this.showtutorial = new Array(100).fill(false);
  }

  btntutorial(value: number): void{
    this.setdefaulttutorial();
    this.showtutorial[value] = true;
  }

}
