import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-imports',
  templateUrl: './confirm-imports.component.html',
  styleUrls: ['./confirm-imports.component.css']
})
export class ConfirmImportsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  confirm(){
    this.data.confirm = true;
  }

}
