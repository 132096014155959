<h1 class="text-center text-primary mt-4 font-weight-bold"><i class="fas fa-users fa-lg"></i> Permission Groups</h1>
<table mat-table
       [dataSource]="services.groupDataSource" multiTemplateDataRows
       class="mat-elevation-z8">
    <!-- Full Name Column -->
    <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Group Name. </th>
    <td mat-cell *matCellDef="let element"> <b>{{element.groupName}}</b> </td>
    </ng-container>

    <!-- Stripe Refund Column -->
    <ng-container matColumnDef="refundstripe">
        <th mat-header-cell *matHeaderCellDef> Refund Stripe Account. </th>
        <td mat-cell *matCellDef="let element"> 
            <ng-container *ngIf="element.canRefundStripeAccount;else showrefundno">
                <span class="badge bg-success">Active</span>
            </ng-container>
            <ng-template #showrefundno>
                <span class="badge bg-danger">Inactive</span>
            </ng-template> 
        </td>
    </ng-container>

     <!-- Update Status On Account Column -->
     <ng-container matColumnDef="updatestatusstrip">
        <th mat-header-cell *matHeaderCellDef> Update Status On Read Me Stripe. </th>
        <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.canUpdateStatusStripeAccount;else updatestatus">
                <span class="badge bg-success">Active</span>
            </ng-container>
            <ng-template #updatestatus>
                <span class="badge bg-danger">Inactive</span>
            </ng-template> 
        </td>
    </ng-container>

     <!-- Can Use Message On Account Column -->
     <ng-container matColumnDef="canusemessage">
        <th mat-header-cell *matHeaderCellDef> Can Use Messages. </th>
        <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.canUseMessages;else updatemessages">
                <span class="badge bg-success">Active</span>
            </ng-container>
            <ng-template #updatemessages>
                <span class="badge bg-danger">Inactive</span>
            </ng-template> 
        </td>
    </ng-container>

     <!-- Use Import Column -->
     <ng-container matColumnDef="useimport">
        <th mat-header-cell *matHeaderCellDef> Use Import. </th>
        <td mat-cell *matCellDef="let element"> 
            <ng-container *ngIf="element.canUseImport;else showimport">
                <span class="badge bg-success">Active</span>
            </ng-container>
            <ng-template #showimport>
                <span class="badge bg-danger">Inactive</span>
            </ng-template> 
        </td>
    </ng-container>

    <!-- Use API Column -->
    <ng-container matColumnDef="useapi">
        <th mat-header-cell *matHeaderCellDef> Use API. </th>
        <td mat-cell *matCellDef="let element"> 
            <ng-container *ngIf="element.canUseAPI;else showapi">
                <span class="badge bg-success">Active</span>
            </ng-container>
            <ng-template #showapi>
                <span class="badge bg-danger">Inactive</span>
            </ng-template> 
        </td>
    </ng-container>

    <!-- Use API Column -->
    <ng-container matColumnDef="isadmin">
        <th mat-header-cell *matHeaderCellDef> Is an Admin? </th>
        <td mat-cell *matCellDef="let element"> 
            <ng-container *ngIf="element.isAdmin;else showadmin">
                <span class="badge bg-success">Active</span>
            </ng-container>
            <ng-template #showadmin>
                <span class="badge bg-danger">Inactive</span>
            </ng-template> 
        </td>
    </ng-container>

  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
      </button>
    </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
      <div class="example-element-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="example-element-description">
            <div class="col-lg-12">
                <div class="page-header">
                  <h1 class="weight-bold text-uppercase">Permissions for writing & reading</h1>
                </div>
                <div class="row">
                    <div class="col-6">
                        <button type="button" class="btn btn-outline-primary" [routerLink]="['/members/editusergroup', element.id]">Edit Group Permissions</button>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-outline-danger" (click)="deleteAccount(element.id)">Remove Group</button>
                    </div>
                </div>
    
                <div class="bs-component">
                  <table class="table table-hover mt-4">
                    <thead>
                      <tr>
                        <th scope="col">Read Permissions</th>
                        <th scope="col"></th>
                        <th scope="col">Write Permissions</th>
                        <th scope="col"></th>
                        <th scope="col">Delete Permissions</th>
                        <th scope="col"></th>
                        <th scope="col">Archive Permissions</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Read Clients:</td>
                        <th scope="row">
                            <div class="text-success" *ngIf="element.canReadClient"><i class="fas fa-check text-success"></i> Active</div>
                            <div class="text-danger" *ngIf="!element.canReadClient"><i class="fas fa-close text-danger"></i> Inactive</div>
                        </th>
                        <td>Write to Clients:</td>
                        <th scope="row" class="text-danger">
                            <div class="text-success" *ngIf="element.canWriteClient"><i class="fas fa-check text-success"></i> Active</div>
                            <div class="text-danger" *ngIf="!element.canWriteClient"><i class="fas fa-close text-danger"></i> Inactive</div>
                        </th>
                        <td>Delete to Clients:</td>
                        <th scope="row" class="text-danger">
                            <div class="text-success" *ngIf="element.canDeleteClientAccount"><i class="fas fa-check text-success"></i> Active</div>
                            <div class="text-danger" *ngIf="!element.canDeleteClientAccount"><i class="fas fa-close text-danger"></i> Inactive</div>
                        </th>
                        <td>Archive to Clients:</td>
                        <th scope="row" class="text-danger">
                            <div class="text-success" *ngIf="element.canArchiveClientAccount"><i class="fas fa-check text-success"></i> Active</div>
                            <div class="text-danger" *ngIf="!element.canArchiveClientAccount"><i class="fas fa-close text-danger"></i> Inactive</div>
                        </th>
                      </tr>              
                    </tbody>
                    <tbody>
                        <tr>
                          <td>Read Invoices:</td>
                          <th scope="row" class="text-danger">
                            <div class="text-success" *ngIf="element.canReadInvoice"><i class="fas fa-check text-success"></i> Active</div>
                            <div class="text-danger" *ngIf="!element.canReadInvoice"><i class="fas fa-close text-danger"></i> Inactive</div>
                          </th>
                          <td>Write to Invoices:</td>
                          <th scope="row" class="text-danger">
                            <div class="text-success" *ngIf="element.canWriteInvoice"><i class="fas fa-check text-success"></i> Active</div>
                            <div class="text-danger" *ngIf="!element.canWriteInvoice"><i class="fas fa-close text-danger"></i> Inactive</div>
                          </th>
                          <td>Delete to Invoices:</td>
                          <th scope="row" class="text-danger">
                            <div class="text-success" *ngIf="element.canDeleteInvoiceAccount"><i class="fas fa-check text-success"></i> Active</div>
                            <div class="text-danger" *ngIf="!element.canDeleteInvoiceAccount"><i class="fas fa-close text-danger"></i> Inactive</div>
                          </th>
                          <td>Archive to Invoices:</td>
                          <th scope="row" class="text-danger">
                            <div class="text-success" *ngIf="element.canArchiveInvoiceAccount"><i class="fas fa-check text-success"></i> Active</div>
                            <div class="text-danger" *ngIf="!element.canArchiveInvoiceAccount"><i class="fas fa-close text-danger"></i> Inactive</div>
                          </th>
                        </tr>              
                    </tbody>
                    <tbody>
                        <tr>
                          <td>Read Instalments:</td>
                          <th scope="row" class="text-danger">
                            <div class="text-success" *ngIf="element.canReadInstalments"><i class="fas fa-check text-success"></i> Active</div>
                            <div class="text-danger" *ngIf="!element.canReadInstalments"><i class="fas fa-close text-danger"></i> Inactive</div>
                          </th>
                          <td>Write to Instalments:</td>
                          <th scope="row" class="text-danger">
                            <div class="text-success" *ngIf="element.canWriteInstalments"><i class="fas fa-check text-success"></i> Active</div>
                            <div class="text-danger" *ngIf="!element.canWriteInstalments"><i class="fas fa-close text-danger"></i> Inactive</div>
                          </th>
                          <td>Delete to Instalments:</td>
                          <th scope="row" class="text-danger">
                            <div class="text-success" *ngIf="element.canDeleteInstalmentAccount"><i class="fas fa-check text-success"></i> Active</div>
                            <div class="text-danger" *ngIf="!element.canDeleteInstalmentAccount"><i class="fas fa-close text-danger"></i> Inactive</div>
                          </th>
                          <td>Archive to Instalments:</td>
                          <th scope="row" class="text-danger">
                            <div class="text-success" *ngIf="element.canArchiveInstalmentAccount"><i class="fas fa-check text-success"></i> Active</div>
                            <div class="text-danger" *ngIf="!element.canArchiveInstalmentAccount"><i class="fas fa-close text-danger"></i> Inactive</div>
                          </th>
                        </tr>              
                    </tbody>
                    <tbody>
                        <tr>
                          <td>Read Payment Accounts:</td>
                          <th scope="row" class="text-danger">
                            <div class="text-success" *ngIf="element.canReadAccountPayments"><i class="fas fa-check text-success"></i> Active</div>
                            <div class="text-danger" *ngIf="!element.canReadAccountPayments"><i class="fas fa-close text-danger"></i> Inactive</div>
                          </th>
                          <td>Write to Payment Accounts:</td>
                          <th scope="row" class="text-danger">
                            <div class="text-success" *ngIf="element.canWriteAccountPayments"><i class="fas fa-check text-success"></i> Active</div>
                            <div class="text-danger" *ngIf="!element.canWriteAccountPayments"><i class="fas fa-close text-danger"></i> Inactive</div>
                          </th>
                          <td>Delete to Payment Accounts:</td>
                          <th scope="row" class="text-danger">
                            <div class="text-success" *ngIf="element.canDeletePaymentAccount"><i class="fas fa-check text-success"></i> Active</div>
                            <div class="text-danger" *ngIf="!element.canDeletePaymentAccount"><i class="fas fa-close text-danger"></i> Inactive</div>
                          </th>
                          <td>Archive to Payment Accounts:</td>
                          <th scope="row" class="text-danger">
                            <div class="text-success" *ngIf="element.canArchivePaymentAccount"><i class="fas fa-check text-success"></i> Active</div>
                            <div class="text-danger" *ngIf="!element.canArchivePaymentAccount"><i class="fas fa-close text-danger"></i> Inactive</div>
                          </th>
                        </tr>              
                    </tbody>
                    <tbody>
                      <tr>
                        <td>Read Store Accounts:</td>
                        <th scope="row" class="text-danger">
                          <div class="text-success" *ngIf="element.canReadStoreAccount"><i class="fas fa-check text-success"></i> Active</div>
                          <div class="text-danger" *ngIf="!element.canReadStoreAccount"><i class="fas fa-close text-danger"></i> Inactive</div>
                        </th>
                        <td>Write to Store Accounts:</td>
                        <th scope="row" class="text-danger">
                          <div class="text-success" *ngIf="element.canWriteStoreAccount"><i class="fas fa-check text-success"></i> Active</div>
                          <div class="text-danger" *ngIf="!element.canWriteStoreAccount"><i class="fas fa-close text-danger"></i> Inactive</div>
                        </th>
                        <td>Delete to Store Accounts:</td>
                        <th scope="row" class="text-danger">
                          <div class="text-success" *ngIf="element.canDeleteStoreAccount"><i class="fas fa-check text-success"></i> Active</div>
                          <div class="text-danger" *ngIf="!element.canDeleteStoreAccount"><i class="fas fa-close text-danger"></i> Inactive</div>
                        </th>
                        <td>Archive to Store Accounts:</td>
                        <th scope="row" class="text-danger">
                          <div class="text-success" *ngIf="element.canArchiveStoreAccount"><i class="fas fa-check text-success"></i> Active</div>
                          <div class="text-danger" *ngIf="!element.canArchiveStoreAccount"><i class="fas fa-close text-danger"></i> Inactive</div>
                        </th>
                      </tr>              
                  </tbody>
                    <tbody>
                        <tr>
                          <td>Read Stripe Accounts:</td>
                          <th scope="row" class="text-danger">
                            <div class="text-success" *ngIf="element.canReadStripeAccount"><i class="fas fa-check text-success"></i> Active</div>
                            <div class="text-danger" *ngIf="!element.canReadStripeAccount"><i class="fas fa-close text-danger"></i> Inactive</div>
                          </th>
                          <td></td>
                          <th scope="row" class="text-danger"></th>
                          <td></td>
                          <th scope="row" class="text-danger"></th>
                        </tr>              
                    </tbody>
                  </table>
                </div>
              </div>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>