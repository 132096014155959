
<div class="bs-docs-section container-fluid colortop">
  <div class="row">
    <div class="col-12">
      <div class="inlineimage"> 
        <img class="img-responsive images .d-none .d-sm-block .d-sm-none .d-md-block" 
        src="https://cdn0.iconfinder.com/data/icons/credit-card-debit-card-payment-PNG/128/Mastercard-Curved.png"> 
        <img class="img-responsive images .d-none .d-sm-block .d-sm-none .d-md-block ms-1" 
        src="/assets/images/googlepay.png"> 
        <img class="img-responsive images .d-none .d-sm-block .d-sm-none .d-md-block ms-1" 
        src="/assets/images/visa.png">
        <img class="img-responsive images .d-none .d-sm-block .d-sm-none .d-md-block ms-1" 
        src="https://cdn0.iconfinder.com/data/icons/credit-card-debit-card-payment-PNG/128/American-Express-Curved.png"> 
      </div>
      <hr>
      <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xm-3 ">
          <div class="container py-3">
            <footer class="">
              <div class="row">
                <div class="col-12 col-md center-block">
                  <img src="assets/images/Invoice pager_logo.jpg" class="rounded center" alt="Business Logo">
                  <small class="d-block mb-3 text-muted text-center mt-4">© 2022–2023</small>
                </div>
              </div>
            </footer>
          </div>
          <hr>
        </div>
        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xm-9">
          <div class="">  
            <h1 class="text-primary font-weight-bold">Accepts payments via Credit and Mastercard </h1>
          </div>
          <div id="chargePaymentSec">
            <ng-container *ngIf="hasValidated === false" >
                    <div class="">
                      <div class="card border-primary mb-3">
                        <div class="card-body">  
                          <h2 class="border-dark text-primary bg-white rounded">The <strong>Last Step</strong> you will need to do is to verify your account!</h2>
                            <div class="bs-component">
                              <div class="alert alert-dismissible alert-danger" *ngIf="hasSubmit">
                                <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                                <strong>No data found!</strong> We couldn't locate any data within our database. Please reach out to your business provider to confirm the transmission of your code.
                              </div>
                            </div>
                          <form #editForm="ngForm" id="editForm" [formGroup]="formValidator" (ngSubmit)="formValidator.valid" autocomplete="off">                      
                              <app-text-input [formControl]='formValidator.controls["code"]' [label]='"Please enter the Validation Code from your email/SMS message!"' [smalltext]='""' [type]="'number'" [placeholder]='"Validation Code"'> </app-text-input>
                              <button [disabled]="!formValidator.valid" class="btn btn-outline-primary btn-lg" form="editForm" (click)="onSubmit()">Check Validation Code For Billing Account</button>
                          </form>
                        </div>
                      </div>
                    </div>                    
              </ng-container>
              <ng-container *ngIf="chargePayment">
                    <ng-container *ngIf="chargePayment.hasPaid === 0">
                      <div class="lg-12" *ngIf="hasValidated === true">
                        <div class="card border-primary mb-3">
                          <div class="card-body">  
                            <h2 id="type-blockquotes border-primary">Payment Details</h2>
                            <hr class="my-4">      
                            <p class="card-text">The business name who invoice/bill you is <strong>{{chargePayment.businessName | uppercase}}</strong></p>
                            <p class="card-text">Invoice Number: <strong>{{chargePayment.invoiceNumber | uppercase}}</strong></p>
                            <p class="card-text">Your due payment now:</p>
                            <h3 class="text-primary">{{chargePayment.chargeAmount | currency }} AUD</h3>
                            <button type="button" class="btn btn-outline-primary btn-lg" (click)="clickEventCharge()">Pay Now</button>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="chargePayment.hasPaid === 1">
                      <div class="lg-12">
                        <div class="card border-primary mb-3">
                          <div class="card-body">  
                            <h2 id="type-blockquotes border-primary">Payment Details</h2>
                            <hr class="my-4">      
                            <p class="card-text">This invoice has already been paid!</p>
                          </div>
                        </div>
                      </div>
                    </ng-container>
              </ng-container>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>

