import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { MergeMapSubscriber } from 'rxjs/internal/operators/mergeMap';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Member } from 'src/app/_models/member';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { MembersService } from 'src/app/_services/account/members.service';
import { Router } from '@angular/router';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator';
import { maxLength, minLength } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.css']
})
export class EditAccountComponent implements OnInit {
  @ViewChild('editForm') editForm: NgForm;
  user: User;
  member: Member;
  memberForm: UntypedFormGroup;
  members: Observable<Member[]>  
  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any){
    if (this.editForm.dirty){
      $event.returnValue = true;
    }
  }

  constructor(private accountService: AccountService, private memberService: MembersService, private toastr: ToastrService,
    private fb: UntypedFormBuilder, private router: Router){
    this.accountService.currentUser$.pipe(take(1)).subscribe(user => this.user = user);  
  }

  ngOnInit(): void {    
    this.initializeForm(); 
    this.loadAccount();
  }

  initializeForm(){
    this.memberForm = this.fb.group({
      email: ['', Validators.email],
      firstname: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpace]],
      lastname: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpace]],
      phone: ['',[Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpace, Validators.minLength(10), Validators.maxLength(10)]],
      businessname: ['', [Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      address: ['', [Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      postcode: ['', [Validators.maxLength(255), Validators.minLength(4), Validators.maxLength(4)]],
      state: ['', [Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      city: ['', [Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      contactussupportphone: ['', [Validators.maxLength(10), Validators.minLength(10), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      contactussupportemail: ['', [Validators.maxLength(255), WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter, Validators.email]],
      abn: ['', [Validators.minLength(11), Validators.maxLength(11), WhiteSpacesValidator.cannotContainSpace]],
      contactwebsite: ['', [WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      bsb: ['', [Validators.minLength(6), Validators.maxLength(6)]],
      accountnumber: ['', [WhiteSpacesValidator.cannotContainSpace]],
      accountname: ['', [WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
      payid: ['', [WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
    });

    console.log(this.member);
  }

  loadAccount(){
    this.memberService.getMember(this.user.username).subscribe(member => {
      this.member = member;   
      console.log(this.member);  
      this.updateFmody();
    }, err => {
      console.log(err);
    })
  }

  updatemember(){
    this.updateForm();
    this.initializeForm();
    console.log(this.member);
    this.memberService.updateMember(this.member).subscribe(() => {
      this.toastr.success('Profile updated successfully');
      const updateUser: any = {
        firstName: this.member.firstName,
        lastName: this.member.lastName,
        typeOfService: this.member.typeOfService
      };

      this.accountService.updateObserableChangesEditAccount(updateUser);
      console.log('got here');
      this.router.navigate(['/members/editdetailsacount']);

    });
  }

  updateForm(){
    let postcode: number = 0;
    if (this.memberForm.value.postcode !== "")
      postcode = +this.memberForm.value.postcode;

    this.member = {
      abn: this.memberForm.value.abn,
      bussinessName: this.memberForm.value.businessname,
      email: this.memberForm.value.email,
      firstName: this.memberForm.value.firstname,
      lastName: this.memberForm.value.lastname,
      phone: this.memberForm.value.phone,
      address: this.memberForm.value.address,
      city: this.memberForm.value.city,
      postcode: postcode,
      state: this.memberForm.value.state,
      contactUsSupportPhone: this.memberForm.value.contactussupportphone,
      contactUsSupportEmail: this.memberForm.value.contactussupportemail,
      website: this.memberForm.value.contactwebsite,
      bsb: this.memberForm.value.bsb,
      accountNumber: this.memberForm.value.accountnumber,
      accountName: this.memberForm.value.accountname,
      payID: this.memberForm.value.payid      
    }
  }

  updateFmody(){
    this.memberForm.controls['email'].setValue(this.member.email);
    this.memberForm.controls['firstname'].setValue(this.member.firstName);
    this.memberForm.controls['lastname'].setValue(this.member.lastName);
    this.memberForm.controls['phone'].setValue(this.member.phone);
    this.memberForm.controls['businessname'].setValue(this.member.bussinessName);
    this.memberForm.controls['contactussupportphone'].setValue(this.member.contactUsSupportPhone);
    this.memberForm.controls['contactussupportemail'].setValue(this.member.contactUsSupportEmail);
    this.memberForm.controls['abn'].setValue(this.member.abn);
    this.memberForm.controls['contactwebsite'].setValue(this.member.website);
    this.memberForm.controls['bsb'].setValue(this.member.bsb);
    this.memberForm.controls['accountnumber'].setValue(this.member.accountNumber);
    this.memberForm.controls['accountname'].setValue(this.member.accountName);
    this.memberForm.controls['payid'].setValue(this.member.payID);
  }

}
