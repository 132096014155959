import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/_models/user';
import { environment } from 'src/environments/environment';
import { PresenceService } from '../presence.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  baseUrl =  environment.apiUrl;
  checkTwoFactor: boolean = false;
  private currentUserSource = new ReplaySubject<User>(1);
  currentUser$ = this.currentUserSource.asObservable();
  hasUserLogIn: boolean = false;

  constructor(private http: HttpClient, private presenceService: PresenceService) {
   }

   login(model: any){
    this.checkTwoFactor = false;
      return this.http.post(this.baseUrl + 'account/login', model).pipe(
        map((response: User) => {
          const user = response;
          if (user) {
            console.log(user);
            if (user.password === null)
            {
              this.checkTwoFactor = true;
            }
            else
            {
              localStorage.setItem('user', JSON.stringify(user));
              this.currentUserSource.next(user);
              this.checkTwoFactor = false;
            }
          }
        })
      );
   }

   register(model: any){
    return this.http.post(this.baseUrl + 'account/register', model).pipe(
      map((user: User) => {
        if (user) {
          localStorage.setItem('user', JSON.stringify(user));
          this.currentUserSource.next(user);
        }
      })
    )
   }

   login2fa(model: any){
    return this.http.post(this.baseUrl + 'account/logintwofactorauthentication', model).pipe(
      map((user: User) => {        
        if (user) {
          localStorage.setItem('user', JSON.stringify(user));
          this.currentUserSource.next(user);
        }
      })
    )
   }

   updatepassword(password: any){
     return this.http.post(this.baseUrl + 'account/updatechangepassword', password);
   }

   setCurrentUser(user: User){
     this.currentUserSource.next(user);
     //this.presenceService.createHubConnection(user);
     console.log('user log');
   }

   logout(){
     localStorage.removeItem('user');
     this.currentUserSource.next(null);
     this.hasUserLogIn = false;
     //this.presenceService.stopHubConnection();
   }

   updateObserableChangesEditAccount(user: any){
    const oldUser = this.currentUserSource;
    oldUser.subscribe(response => {
      response.firstName = user.firstName;
      response.lastName = user.lastName;
      response.typeOfService = user.typeOfService;
      response.token = response.token;
      response.username = response.username;
      this.currentUserSource = oldUser;
    })
   }

   updateObserableChangesServices(user: boolean){
    const oldUser = this.currentUserSource;
    oldUser.subscribe(response => {
      response.firstName = response.firstName;
      response.lastName = response.lastName;
      response.typeOfService = user;
      response.token = response.token;
      response.username = response.username;
      this.currentUserSource = oldUser;
    })
   }

   getCurrentUser(): any{
    return this.currentUser$;
   }

   setUserLogIn(){
    this.hasUserLogIn = true;
   }
}
