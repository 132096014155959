<app-nav></app-nav>
<div class="container mt-4">
  <nav class="navbar navbar-light bg-primary shadow-lg">
    <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-user fa-2x" aria-hidden="true"></i> Account Details</a>
  </nav>
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/members/editdetailsacount">Edit Password Account Details</a></li>
      <li class="breadcrumb-item active">Edit Password Account</li>
    </ol>
    <div class="row justify-content-md-center mt-4">
        <div class="col-sm-12 col-md-4  py-5 bg-white text-black text-center shadow-lg">
            <div class=" ">
                <div class="card-body">
                    <img src="/assets/edit.jpg" class="img-thumbnail">
                    <h2 class="py-3">Edit Account</h2>
                    <p>
                       Edit your account and details!
                    </p>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-8 mt-2 ">
            <div class="card mt-4 border-white shadow-lg">
                <div class="card-body">
                  <form #editForm="ngForm" [formGroup]="memberForm" (ngSubmit)="memberForm.valid" autocomplete="off" id="editForm">
                    <h2 class="text-center text-primary mt-2"> Password Account</h2>
                    <div class="bs-component">
                      <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
                        <button type="button" class="close" data-dismiss="alert">×</button>
                        <h4 class="alert-heading">Information!</h4>
                        <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
                      </div>
                    </div>
                    <app-text-input [formControl]='memberForm.controls["oldpassword"]' [label]='"*Current Password"' [type]='"password"' [smalltext]='"You must put in your old password to able to change it!"'> </app-text-input>
                    <app-text-input [formControl]='memberForm.controls["password"]' [label]='"*New Password"' [type]='"password"' [smalltext]='""'> </app-text-input>
                    <app-text-input [formControl]='memberForm.controls["confirmpassword"]' [label]='"*Confirm New Password"' [type]='"password"' [smalltext]='""'> </app-text-input>
                </form>
                </div>
                <div class="card-footer text-muted">
                    <button [disabled]="!memberForm.valid" class="btn btn-success btn-block" form="editForm" (click)="updatePassword()" >Change Password</button>
                </div>
              </div>
        </div>
    </div>
  </div>
  