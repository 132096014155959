import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { User } from "../_models/user";

export class ConnectionSignalRHub{
    public static GetConnectionHub(hubConnection: HubConnection, huburl: any, accountID: number, user: User, controller: string){
        hubConnection = new HubConnectionBuilder()
      .withUrl(huburl + controller + '?accountID=' + accountID, {
        accessTokenFactory: () => user.token
        })
        .withAutomaticReconnect()
        .build();

        hubConnection.start().catch(err => {
        console.log(err);
        });
        return hubConnection;
    }

  public static CheckAccessHub(index: boolean, status: boolean) : boolean
  {
    if (!status)
      if (!index)
        return false;
    return true;
  }
}