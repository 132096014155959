import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MessagesService } from 'src/app/_services/message/messages.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-changeinstalmentduedateemailmessage',
  templateUrl: './changeinstalmentduedateemailmessage.component.html',
  styleUrls: ['./changeinstalmentduedateemailmessage.component.css']
})
export class ChangeinstalmentduedateemailmessageComponent implements OnInit {

  public data: any;
  @ViewChild('editForm') editForm: NgForm; 
  formEdit: UntypedFormGroup;
  public Editor = ClassicEditor;
  
  constructor(public fb: UntypedFormBuilder, private service: MessagesService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.initializeForm();
    this.onLoad();
  }

  initializeForm(){
    this.formEdit = this.fb.group({
      changeText:['', [ Validators.required, Validators.maxLength(1000) ]],
    });  
  }

  onSubmit(){
    const message = {
      dueDateInstalmentEmailMessage: this.formEdit.controls['changeText'].value
    }
    this.data.message = message.dueDateInstalmentEmailMessage;
    this.service.updateDueDateInstalmentEmailMessage(message).subscribe(response => {
      this.onLoadSettingMesaging();
      this.toastr.success("Updated Message!");
    }, error => {
      console.log(error);
    }) 
  }

  onLoad(){
    this.service.getDueDateInstalmentEmail().subscribe(response => {
      this.data = response;
      this.formEdit.controls['changeText'].setValue(this.data.dueDateInstalmentEmailMessage);
      console.log(response);
    }, error => { 
      console.log(error);
    })
  }

  onLoadSettingMesaging(){
    this.service.getTextSettingMessaging().subscribe(response => {
      this.service.message = response;
      if (this.service.message == null){
        this.service.setMessageValues();
      }
      console.log(response);
    }, error => {
      console.log(error);
    })
  }

  generateText(){
    this.formEdit.controls['changeText'].setValue("<body>\r\n   " +
    " <p>Dear [Name],</p>\r\n\r\n  " +
    "  <p>I hope this email finds you in good health.</p>\r\n\r\n " +
    "   <p>I am writing to gently remind you of the approaching payment deadline for Invoice #[invoicenumber], " +
    "with a total amount due of [DueAmount]. We appreciate the payments you have made, totaling [amountpaid].</p>\r\n\r\n  " +
    "  <p> To ensure a seamless transaction, kindly process the remaining payment by the close of business today to avoid any potential late fees.</p>\r\n\r\n  " +
    "  <strong>Invoice Details:</strong>\r\n    <ul>\r\n        <li>Invoice Number: #[invoicenumber]</li>\r\n        <li>Amount Due Now: [dueamount]</li>\r\n    " +
    "    <li>Due Date: [duedate]</li>\r\n        <li>Amount Paid: [paidamount]</li>\r\n    </ul>\r\n\r\n    <strong>Payment Instructions:</strong>\r\n  " +
    "  <p>To complete the payment, please visit the Invoice Pager website and enter the provided code: [Code].</p>\r\n\r\n  [link] " +
    " <p>If you have already settled this invoice, please accept our gratitude for your prompt payment, and you may disregard this reminder.</p>\r\n\r\n   " +
    " <p>Your prompt attention to this matter is highly appreciated." +
    " Should you have any questions or concerns, please feel free to reach out to us at [mybusinessemail]</p>\r\n\r\n " +
    "   <p>Thank you for your cooperation.</p>\r\n\r\n    <p>Best regards,<br>\r\n        [mybusiness]<br>\r\n    " +
    "    [mybusinessemail]<br>\r\n        [mybusinessphone]\r\n    </p>\r\n</body>");
    this.formEdit.markAsDirty();
  }
}
