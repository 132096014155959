import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from 'src/app/_services/invoice/invoice.service';

@Component({
  selector: 'app-dialogresetinitalmessage',
  templateUrl: './dialogresetinitalmessage.component.html',
  styleUrls: ['./dialogresetinitalmessage.component.css']
})
export class DialogresetinitalmessageComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private invoiceService: InvoiceService, private toastr: ToastrService) { }
  accountID: number;
  ngOnInit(): void {
    this.accountID = this.data.id;
  }

  resetInitialMessage(): void{
    this.invoiceService.resetInitialMessage(this.accountID).subscribe(response => {
      if (response)
      {
        this.toastr.success("You have successfully reseted initial message for this account!");
      }
    }, err => {
      console.log(err);
    })
  }

}
