<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to create a new instalment account</h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/instalments/howtocreateinstalments/ci01.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Navigate to the relevant invoice’s “Instalment Accounts” page </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/instalments/howtocreateinstalments/ci02.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Fill out the installment details</h2>
    <h2>•	Please note that “Cost Amount”, “Payment Amount”, “Initial Date”, and “Due Date” are mandatory</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/instalments/howtocreateinstalments/ci03.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Click “Create Invoice Account” to save the “Instalment Account”</h2>
</div>