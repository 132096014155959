import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Client } from 'src/app/_models/client';
import { ImportfilesService } from 'src/app/_services/import/importfiles.service';
import { ImportfileClient } from 'src/app/_models/importfileclient';
import { ConfirmimportsinvoicesComponent } from '../../importinvoices/confirmimportsinvoices/confirmimportsinvoices.component';
import { ImporteditclientComponent } from '../importeditclient/importeditclient.component';
import { ImporteddeleteclientComponent } from '../importeddeleteclient/importeddeleteclient.component';
import { ImportconfirmationdialogComponent } from '../importconfirmationdialog/importconfirmationdialog.component';


@Component({
  selector: 'app-importlist',
  templateUrl: './importlist.component.html',
  styleUrls: ['./importlist.component.css']
})
export class ImportlistComponent implements OnInit {
  @Input() usersFromImportFile: ImportfileClient[];
  clients: Client[];
  validationErrors: boolean = false;
  showconflictlist: boolean = false;
  

  constructor(private services: ImportfilesService, private dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
    //this.getListClients();
  }

  getListClients(){
    this.services.getListClients().subscribe(response => {
      this.clients = response;
      this.checkConflictClient();
      this.checkConflictImports();
    }, error => {
      console.log(error);
    });
  }

  

  checkConflictClient(){
    var indexClient: number = 0;
    var indexImport: number = 0;
    this.clients.forEach(client => {      
      if (this.usersFromImportFile != null)
      {
        this.usersFromImportFile.forEach(file => {
       
          if (client.firstName.toLowerCase() == file.firstName.toLowerCase() && client.lastName.toLowerCase() == file.lastName.toLowerCase())
          {
            this.usersFromImportFile[indexImport].conflictImport = true;
            this.clients[indexClient].conflictImport = true;
          }
          indexImport++;
        });
      }
      indexImport = 0;
      indexClient++;
    });
  }

  checkConflictImports(){
    let index: number = 0;
    let counter: number = 0;
    for (let i = 0; i < this.usersFromImportFile.length; i++)
    {
      console.log(this.usersFromImportFile[i].firstName);
      this.usersFromImportFile.forEach(element => {
        if (this.usersFromImportFile[i].firstName.toLowerCase() == element.firstName.toLowerCase() && this.usersFromImportFile[i].lastName.toLowerCase()
         == element.lastName.toLowerCase())
        {
          counter++;    
        }

        if (counter == 2)
        {
          this.usersFromImportFile[i].conflictImport = true;
        }
        index++;
      });
      counter = 0;
    }
  }

  editImport(id: any){
    const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = "80%";
      dialogConfig.height = "100%";
      dialogConfig.data = {
        id: id,
        firstName: this.usersFromImportFile[id].firstName,
        lastName: this.usersFromImportFile[id].lastName,
        address: this.usersFromImportFile[id].address,
        email: this.usersFromImportFile[id].email,
        conflictImport: this.usersFromImportFile[id].conflictImport,
        bussinessName : this.usersFromImportFile[id].bussinessName,
        contactBusinessNumber : this.usersFromImportFile[id].contactBusinessNumber,
        contactHomePhone : this.usersFromImportFile[id].contactHomePhone,
        contactMobile : this.usersFromImportFile[id].contactMobile,
        abn : this.usersFromImportFile[id].abn,
        unit : this.usersFromImportFile[id].unit,
        suburb : this.usersFromImportFile[id].suburb,
        state : this.usersFromImportFile[id].state,
        postCode : this.usersFromImportFile[id].postCode,
        country : this.usersFromImportFile[id].country,
      };
      
       const dialogRef = this.dialog.open(ImporteditclientComponent, dialogConfig);
  
       dialogRef.afterClosed().subscribe(result => {        
        this.usersFromImportFile[result.id] = result;
        //this.sortDefaultGreen();
        //this.checkConflictClient();
        //this.checkConflictImports();
       }); 
  }

  deleteImport(id){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "75%";
    dialogConfig.data = {
      id: id,
      deleteInstalment: false
    };
    
     const dialogRef = this.dialog.open(ImporteddeleteclientComponent, dialogConfig);

     dialogRef.afterClosed().subscribe(result => {
      if (result.deleteImportAccount != null)
      {      
        this.usersFromImportFile.splice(result.id, 1);
   //     this.sortDefaultGreen();
   //     this.checkConflictClient();
   //     this.checkConflictImports();
      }
    });   
  }

  sortDefaultGreen(){
    for (let i = 0; i < this.usersFromImportFile.length; i++ )
    {
      this.usersFromImportFile[i].conflictImport = false;
    }

    for (let i = 0; i < this.clients.length; i++ )
    {
      this.clients[i].conflictImport = false;
    }
  }

  onSubmit(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "40%";
    dialogConfig.data = {
    };
    
    const dialogRef = this.dialog.open(ImportconfirmationdialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm)
      {      
        this.services.createImportsClients(this.usersFromImportFile).subscribe(response => {
          this.messageConfirm();
        }, error => {
          this.validationErrors = true;      
          console.log(error);
        })
      }
    }); 
  }

  messageConfirm(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "25%";
    dialogConfig.data = {
    };
    
    const dialogRef = this.dialog.open(ConfirmimportsinvoicesComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm != null)
      {      
        this.router.navigate(['/members/clients']);
      }
    }); 
  }
}

