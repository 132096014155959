import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Member } from 'src/app/_models/member';
import { TwoFactorAuthenticatorModel } from 'src/app/_models/twofactorauthenticatormodel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MembersService {
  baseUrl = environment.apiUrl;
  members: Member[] = [];

  constructor(private http: HttpClient) { }

  getMembers() {
    if (this.members.length > 0) return of (this.members);
    return this.http.get<Member[]>(this.baseUrl + 'users').pipe(
      map(members => {
        this.members = members;
        return members;
      })
    );
  }

  getMember(username: string){
    const member = this.members.find(x => x.userName === username);
    if (member !== undefined) return of(member);
    return this.http.get<Member>(this.baseUrl + 'users/' + username);
  }

  updateMember(member: Member){
    return this.http.put(this.baseUrl + 'users', member).pipe(
      map(() => {
        const index = this.members.indexOf(member);
        this.members[index] = member;
      })
    );
  }

  updateCurrentService(){
    return this.http.get<any>(this.baseUrl + 'users/UpdateServiceForUser');
  }

  get2fa(){
    return this.http.get(this.baseUrl + 'users/getstatustwoFactorauthenticator').pipe(
      map((response: TwoFactorAuthenticatorModel)  => {
        return response;
      })
    );
  }

  check2faenabled(){
    return this.http.get(this.baseUrl + 'users/gettfasetup').pipe(
      map((response: boolean) => {
        return response;
      })
    )
  }

  delete2fasetup(){
    return this.http.delete(this.baseUrl + 'users/deletetfasetup');    
  }

  submitCodeTwoFactorAutentication(code: any){
    return this.http.post(this.baseUrl + 'users/onposttwofactorauthencationcode', code);
  }

  getSecretCode(){
    return this.http.get(this.baseUrl + 'users/getsecretcode').pipe(
      map((response: number) => {
        return response;
      })
    );
  }

  getTypeService(){
    return this.http.get(this.baseUrl + 'users/gettypeofservice').pipe(
      map((response: boolean) => {
        return response
      })
    )
  }
}
