import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MemberingroupPermissions } from 'src/app/_models/memberingrouppermissions';
import { UsergroupspermissionsService } from 'src/app/_services/usergroups/usergroupspermissions.service';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator';

@Component({
  selector: 'app-editmemberinagroup',
  templateUrl: './editmemberinagroup.component.html',
  styleUrls: ['./editmemberinagroup.component.css']
})
export class EditmemberinagroupComponent implements OnInit {
  @ViewChild('editForm') editForm: NgForm;  
  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any){
    if (this.editForm.dirty){
      $event.returnValue = true;
    }
  }

  userMember: UntypedFormGroup;
  member: MemberingroupPermissions;
  memberID: string;
  constructor(public services: UsergroupspermissionsService,
    public fb: UntypedFormBuilder, private toastr: ToastrService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.initializeForm();
    this.getParrams();
    this.onLoad();
  }

  initializeForm(){
    this.userMember = this.fb.group({
      firstname:['', [Validators.required, WhiteSpacesValidator.cannotContainSpace]],
      lastname:['', [Validators.required, WhiteSpacesValidator.cannotContainSpace]],
      email:['', [Validators.required, Validators.email]],
      groupid:['', [Validators.required]],
    })
  }

  updateUsermember(){
    this.setModel();
    this.services.updateUserMember(this.member).subscribe(response => {
      if (response === true)
      {
        this.toastr.success('Updated User Member Account');
        this.router.navigate(['/members/usergroups']);
      }
      else
      {
        this.toastr.error('Failed to update User Member Account');
      }
    }, err => {
      console.log(err);
    })
  }

  onLoad(){
    this.services.getUserMember(this.memberID).subscribe(response => {    
      console.log(response);  
      this.userMember.controls['firstname'].setValue(response.firstName);
      this.userMember.controls['lastname'].setValue(response.lastName);
      this.userMember.controls['email'].setValue(response.email);
      this.userMember.controls['groupid'].setValue(response.groupID);
    }, err => {
      console.log(err);
    })
  }

  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.memberID = params.get("id");
    });
  }

  setModel(){
    this.member = { 
      id: +this.memberID,      
      firstName: this.userMember.value.firstname,
      email: this.userMember.value.email,
      groupID: this.userMember.value.groupid,
      lastName: this.userMember.value.lastname,
    };
  }

  

}
