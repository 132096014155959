import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { InstalmentClientInfo } from 'src/app/_models/instalmentClientInfo';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { InstalmentService } from 'src/app/_services/instalment/instalment.service';
import { PaymentService } from 'src/app/_services/payment/payment.service';

@Component({
  selector: 'app-deleteinstalments',
  templateUrl: './deleteinstalments.component.html',
  styleUrls: ['./deleteinstalments.component.css']
})
export class DeleteinstalmentsComponent implements OnInit {
  accountID: number;
  invoiceID: number;
  invoiceAmount: number;
  user?: User;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<InstalmentClientInfo>,
    private instalmentService: InstalmentService, public paymentService: PaymentService, private accountService: AccountService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.accountID = this.data.id; 
    this.invoiceID = this.data.invoiceID;
    this.invoiceAmount = this.data.invoiceAmount;
    this.assignUser();
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  deleteAccount(){
    this.instalmentService.deleteInstalment(this.accountID).subscribe(response => {
      if (response)
      {
        this.data.deleteInstalment = true;
        this.instalmentService.stopHubConnection();
        this.instalmentService.stopHubArchiveConnection();
        this.instalmentService.createHubConnection(this.user, this.invoiceID);
        this.instalmentService.createArchiveHubConnection(this.user, this.invoiceID); 
        this.ReloadCreateFormForTotalingCostInstalments();
        //this.loadMembersListInstalments();
        this.onLoadLastestDate();
        this.toastr.success('Succesfully Deleted Instalment Account!');
        return;
      }
      this.toastr.error('Your customer has attemped, or has paid some money onto this account! You cannot delete this account.')      

    }, error => {
      console.log(error);
    })
  }

  private loadMembersListInstalments(){
    const invoiceID: string = this.invoiceID.toString();
    this.paymentService.getPayments(invoiceID).subscribe(
      response => {
        this.paymentService.accounts = response;
        console.log(response);
      }, error => {
        console.log(error);
      }
    )
  }

  private ReloadCreateFormForTotalingCostInstalments(): void
  {
    this.instalmentService.gettotalcostforInstalments(this.invoiceID).subscribe(response => {
      const addCost = +response;
      if (addCost >= this.invoiceAmount)
      {
        this.instalmentService.showCreateFormSource.next(true);
        return;
      }
      this.instalmentService.showCreateFormSource.next(false);
    }, err => {
      console.log(err);
    })
  }

  private onLoadLastestDate(){
    this.instalmentService.getlastestdateinstalments(this.invoiceID).subscribe(response => {  
      this.instalmentService.lastestInstalmentDate = response;   
    }, error => {
      console.log(error);
    });    
  }

}
