
<mat-dialog-content>
<div class="row">
    <div class="col-12">  
        <h3 class="bg-white text-danger text-center font-weight-bold">ARCHIVE / NON ARCHIVE</h3>
        <h4 class="text-center">What is your desired outcome? Do you want to archive, not archive, or cancel.</h4>       
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xm-12">
        <button type="button" class="btn btn-success  btn-block" mat-button [mat-dialog-close]="data" (click)="archiveAccount(false)"><i class='fas fa-check'></i>  Non-Archive Account
            <ng-container *ngIf="archive === false">
                <b>(Currently Active)</b>
            </ng-container>
        </button>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xm-12">
        <button type="button" class="btn btn-danger btn-block" mat-button [mat-dialog-close]="data" (click)="archiveAccount(true)"><i class='fas fa-trash'></i>  Archive Account
            <ng-container *ngIf="archive === true"><b>(Currently Active)</b></ng-container>
        </button>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xm-12">
        <button type="button" class="btn btn-primary btn-block" mat-button mat-dialog-close><i class='fas fa-close'></i>  Cancel</button>
    </div>
</div>
</mat-dialog-content>