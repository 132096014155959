/* Remove the GMT */
export class EmailRemoveGMT {  
    public static RemovedEmailGMT(date: Date) : any{
        return date;
    }
}

function AddZero(number: string) : string {
    if (number.length < 2)
    {
        return "0" + number.toString();
    }

    return number.toString();
}
