import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { SharedModule } from './_modules/shared.module';
import { TestErrosComponent } from './errors/test-errors/test-erros/test-erros.component';
import { ErrorInterceptor } from './_interceptors/error.interceptor';
import { NotFoundComponent } from './errors/notfound/not-found/not-found.component';
import { ServerErrorComponent } from './errors/serverrror/server-error/server-error.component';
import { EditAccountComponent } from './members/account/edit-account/edit-account.component';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { CreateClientComponent } from './members/client/create-client/create-client.component';
import { LoadingInterceptor } from './_interceptors/loading.interceptor'; 
import { NgxSpinnerModule } from "ngx-spinner";
import { TextInputComponent } from './_forms/text-input/text-input.component';
import { DateInputComponent } from './_forms/date-input/date-input.component';
import { ClientlistComponent } from './members/client/clientlist/clientlist.component';
import { CreateinvoiceComponent } from './members/client/create-client/createinvoice/createinvoice.component';
import { InvoicelistComponent } from './members/client/create-client/invoicelist/invoicelist.component';
import { AccountsreceivableComponent } from './members/accountsreceivable/accountsreceivable.component';
import { AccountclientreceivableComponent } from './members/client/create-client/createinvoice/accountclientreceivable/accountclientreceivable.component';
import { ClientlistaccountreceivableComponent } from './members/client/create-client/createinvoice/clientlistaccountreceivable/clientlistaccountreceivable.component';
import { AreaTextComponent } from './_forms/area-text/area-text.component';
import { CreateinstalmentsComponent } from './members/client/create-client/createinstalments/createinstalments.component';
import { ListinstalmentaccountsComponent } from './members/client/create-client/listinstalmentaccounts/listinstalmentaccounts.component';
import { ClientaccountpaymentsComponent } from './members/client/clientaccountpayments/clientaccountpayments.component';
import { AccountsreceivablepaymentsComponent } from './members/accountsreceivablepayments/accountsreceivablepayments.component';
import { ClientinformationComponent } from './members/client/clientinformation/clientinformation.component';
import { EditclientComponent } from './members/client/editclient/editclient.component';
import { SharedmaterialModule } from './_modules/sharedmaterial/sharedmaterial.module';
import { EditinvoiceComponent } from './members/client/create-client/editinvoice/editinvoice.component';
import { EditpaymentaccountreceivableComponent } from './members/client/create-client/createinvoice/editpaymentaccountreceivable/editpaymentaccountreceivable.component';
import { EditinstalmentsComponent } from './members/client/create-client/editinstalments/editinstalments.component';
import { DeleteclientComponent } from './members/client/deleteclient/deleteclient.component';
import { DeleteinvoiceComponent } from './members/client/create-client/deleteinvoice/deleteinvoice.component';
import { DeleteaccountreceivableComponent } from './members/client/create-client/createinvoice/deleteaccountreceivable/deleteaccountreceivable.component';
import { DeleteinstalmentsComponent } from './members/client/create-client/deleteinstalments/deleteinstalments.component';
import { MessageComponent } from './members/message/message.component';
import { MessageslistComponent } from './members/message/messageslist/messageslist.component';
import { ChangetimeformatComponent } from './members/message/changetimeformat/changetimeformat.component';
import { PaymentreceiveemailmessageComponent } from './members/message/paymentreceiveemailmessage/paymentreceiveemailmessage.component';
import { PaymentreceivesmsComponent } from './members/message/paymentreceivesms/paymentreceivesms.component';
import { DuedateemailmessageComponent } from './members/message/changeemailtext/duedateemailmessage/duedateemailmessage.component';
import { ReceiveemailmessageComponent } from './members/message/changeemailtext/receiveemailmessage/receiveemailmessage.component';
import { DuedatesmsComponent } from './members/message/changesmsmessage/duedatesms/duedatesms.component';
import { ChangeduedatetimeComponent } from './members/message/changeduedatetime/changeduedatetime.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ConfirmImportsComponent } from './members/import/importedclients/confirm-imports/confirm-imports.component'; 
import { CustomerpaymentpageComponent } from './customerpaymentpage/customerpaymentpage.component';
import { SucesspaymentComponent } from './customerpaymentresults/sucesspayment/sucesspayment.component';
import { FailedpaymentComponent } from './customerpaymentresults/failedpayment/failedpayment.component';
import { ChangeinitialemailmessageComponent } from './members/message/changeemailtext/changeinitialemailmessage/changeinitialemailmessage.component';
import { ChangeinitialsmsmessageComponent } from './members/message/changeinitialsmsmessage/changeinitialsmsmessage.component';
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { ShowexmapleduedateemailComponent } from './members/message/showexmapleduedateemail/showexmapleduedateemail.component';
import { MessagetestokComponent } from './members/message/messagetestok/messagetestok.component';
import { ShowexamplereceiveemailComponent } from './members/message/showexamplereceiveemail/showexamplereceiveemail.component';
import { ShowexampleinitalemailComponent } from './members/message/showexampleinitalemail/showexampleinitalemail.component';
import { TestresultmessageComponent } from './members/message/testresultmessage/testresultmessage.component';
import { ImportComponent } from './members/import/importedclients/importfile/import.component';
import { ImportlistComponent } from './members/import/importedclients/importlist/importlist.component';
import { ImportfileinvoiceComponent } from './members/import/importinvoices/importfileinvoice/importfileinvoice.component';
import { ImportlistinvoicesComponent } from './members/import/importinvoices/importlistinvoices/importlistinvoices.component';
import { EditimportinvoiceComponent } from './members/import/importinvoices/editimportinvoice/editimportinvoice.component';
import { DeleteimportinvoiceComponent } from './members/import/importinvoices/deleteimportinvoice/deleteimportinvoice.component';
import { ConfirmimportsinvoicesComponent } from './members/import/importinvoices/confirmimportsinvoices/confirmimportsinvoices.component';
import { StripeaccountslistComponent } from './members/stripeaccounts/stripeaccountslist/stripeaccountslist.component';
import { ListaccountsinvoicesComponent } from './members/listaccountsinvoices/listaccountsinvoices.component';
import { MaterialModules } from './_modules/sharedmaterial/material-module';
import { TesttableComponent } from './testtable/testtable.component';
import { RefundmessagestripeclientComponent } from './members/stripeaccounts/refundmessagestripeclient/refundmessagestripeclient.component';
import { ListclientsubscriptionsComponent } from './members/subscription/listclientsubscriptions/listclientsubscriptions.component';
import { ClientsubscriptionsuccessComponent } from './members/subscription/clientsubscriptionsuccess/clientsubscriptionsuccess.component';
import { HomeuserComponent } from './members/homeuser/homeuser.component';
import { FeaturesComponent } from './features/features.component';
import { RadioButtonComponent } from './_forms/radio-button/radio-button.component';
import { ImportapiclientComponent } from './members/import/importedclients/importapiclient/importapiclient.component';
import { VerifyapixeroComponent } from './members/import/importedclients/verifyapixero/verifyapixero.component';
import { ImportclientinformationComponent } from './members/import/importedclients/importclientinformation/importclientinformation.component';
import { ImportapiinvoiceComponent } from './members/import/importinvoices/importapiinvoice/importapiinvoice.component';
import { ImporteditclientComponent } from './members/import/importedclients/importeditclient/importeditclient.component';
import { ImporteddeleteclientComponent } from './members/import/importedclients/importeddeleteclient/importeddeleteclient.component';
import { EditdetailsacountComponent } from './members/account/editdetailsacount/editdetailsacount.component';
import { ListuserbillsComponent } from './members/subscription/listuserbills/listuserbills.component';
import { CreatestorecreditComponent } from './members/credit/createstorecredit/createstorecredit.component';
import { ListstorecreditComponent } from './members/credit/liststorecredit/liststorecredit.component';
import { EditstorecreditComponent } from './members/credit/editstorecredit/editstorecredit.component';
import { DeletestorecreditComponent } from './members/credit/deletestorecredit/deletestorecredit.component';
import { EditchangepasswordComponent } from './members/account/edit-account/editchangepassword/editchangepassword.component';
import { EmailresenderComponent } from './email/confirmmail/emailresender/emailresender.component';
import { ConfirmemailpageComponent } from './email/confirmmail/confirmemailpage/confirmemailpage.component';
import { ReceivelostemailComponent } from './email/lostemail/receivelostemail/receivelostemail.component';
import { ResetlostemailComponent } from './email/lostemail/resetlostemail/resetlostemail.component';
import { ContactemailComponent } from './email/contactemail/contactemail.component';
import { ImportpageComponent } from './members/import/importpage/importpage.component';
import { StripeaccountsalltransactionsComponent } from './members/stripeaccounts/stripeaccountsalltransactions/stripeaccountsalltransactions.component';
import { DialogsendingallsmsComponent } from './members/accountsreceivable/dialogsendingallsms/dialogsendingallsms.component';
import { TermsandconditionsComponent } from './register/termsandconditions/termsandconditions.component';
import { PolicyComponent } from './register/policy/policy.component';
import { ConfirmimportinvoicesComponent } from './members/import/importinvoices/confirmimportinvoices/confirmimportinvoices.component';
import { TutorialhomeComponent } from './members/tutorials/tutorialhome/tutorialhome.component';
import { TutorialgettoclientComponent } from './members/tutorials/clients/tutorialgettoclient/tutorialgettoclient.component';
import { TutorialhowtocreateclientComponent } from './members/tutorials/clients/tutorialhowtocreateclient/tutorialhowtocreateclient.component';
import { TutorialsearchclientComponent } from './members/tutorials/clients/tutorialsearchclient/tutorialsearchclient.component';
import { TutorialeditclientComponent } from './members/tutorials/clients/tutorialeditclient/tutorialeditclient.component';
import { TutorialdeleteclientComponent } from './members/tutorials/clients/tutorialdeleteclient/tutorialdeleteclient.component';
import { TutorialgettoinvoiceComponent } from './members/tutorials/invoices/tutorialgettoinvoice/tutorialgettoinvoice.component';
import { TutorialcreateinvoiceComponent } from './members/tutorials/invoices/tutorialcreateinvoice/tutorialcreateinvoice.component';
import { TutorialeditinvoiceComponent } from './members/tutorials/invoices/tutorialeditinvoice/tutorialeditinvoice.component';
import { TutorialdeleteinvoiceComponent } from './members/tutorials/invoices/tutorialdeleteinvoice/tutorialdeleteinvoice.component';
import { TutoiralviewinvoiceComponent } from './members/tutorials/invoices/tutoiralviewinvoice/tutoiralviewinvoice.component';
import { TutorialcreatestoreaccountComponent } from './members/tutorials/storeaccounts/tutorialcreatestoreaccount/tutorialcreatestoreaccount.component';
import { TutorialhowtoopenstoreaccountComponent } from './members/tutorials/storeaccounts/tutorialhowtoopenstoreaccount/tutorialhowtoopenstoreaccount.component';
import { TutorialhowtodeletstoreaccountComponent } from './members/tutorials/storeaccounts/tutorialhowtodeletstoreaccount/tutorialhowtodeletstoreaccount.component';
import { TutorialhowtoeditstoreaccountComponent } from './members/tutorials/storeaccounts/tutorialhowtoeditstoreaccount/tutorialhowtoeditstoreaccount.component';
import { TutorialhowtosearchforinvoicesComponent } from './members/tutorials/searchallinvoices/tutorialhowtosearchforinvoices/tutorialhowtosearchforinvoices.component';
import { HowtogetintoinstalmentComponent } from './members/tutorials/instalments/howtogetintoinstalment/howtogetintoinstalment.component';
import { TutorialhowtocreateinstalmentComponent } from './members/tutorials/instalments/tutorialhowtocreateinstalment/tutorialhowtocreateinstalment.component';
import { TutorialhowtogetpaymentsComponent } from './members/tutorials/payments/tutorialhowtogetpayments/tutorialhowtogetpayments.component';
import { TutorialhowtocreatenewaccountpaymentComponent } from './members/tutorials/payments/tutorialhowtocreatenewaccountpayment/tutorialhowtocreatenewaccountpayment.component';
import { TutorialhowtoviewclientaccountsComponent } from './members/tutorials/clients/tutorialhowtoviewclientaccounts/tutorialhowtoviewclientaccounts.component';
import { TutorialhowtoeditinstalmentComponent } from './members/tutorials/instalments/tutorialhowtoeditinstalment/tutorialhowtoeditinstalment.component';
import { TutorialhowtodeleteinstalmentComponent } from './members/tutorials/instalments/tutorialhowtodeleteinstalment/tutorialhowtodeleteinstalment.component';
import { TutorialhowtoviewinstalmentaccountsComponent } from './members/tutorials/instalments/tutorialhowtoviewinstalmentaccounts/tutorialhowtoviewinstalmentaccounts.component';
import { TutorialhowtoeditpaymentComponent } from './members/tutorials/payments/tutorialhowtoeditpayment/tutorialhowtoeditpayment.component';
import { TutorialhowtodeletepaymentComponent } from './members/tutorials/payments/tutorialhowtodeletepayment/tutorialhowtodeletepayment.component';
import { TutorialhowtoviewpaymentComponent } from './members/tutorials/payments/tutorialhowtoviewpayment/tutorialhowtoviewpayment.component';
import { TutorialhowtoimportclientsComponent } from './members/tutorials/importing/tutorialhowtoimportclients/tutorialhowtoimportclients.component';
import { TutorialhowtoimportinvoicesComponent } from './members/tutorials/importing/tutorialhowtoimportinvoices/tutorialhowtoimportinvoices.component';
import { TutorialhowtousemesagesComponent } from './members/tutorials/messages/tutorialhowtousemesages/tutorialhowtousemesages.component';
import { TutorialchangemessagetimesComponent } from './members/tutorials/messages/tutorialchangemessagetimes/tutorialchangemessagetimes.component';
import { DialogtypeservicesComponent } from './members/typeservices/dialogtypeservices/dialogtypeservices.component';
import { ImportconfirmationdialogComponent } from './members/import/importedclients/importconfirmationdialog/importconfirmationdialog.component';
import { CreateusergroupsComponent } from './members/usergroups/createusergroups/createusergroups.component';
import { ListusergroupsComponent } from './members/usergroups/listusergroups/listusergroups.component';
import { EditusergroupsComponent } from './members/usergroups/editusergroups/editusergroups.component';
import { DeleteusergroupsComponent } from './members/usergroups/deleteusergroups/deleteusergroups.component';
import { CreatememberingroupComponent } from './members/usergroups/creatememberingroup/creatememberingroup.component';
import { ListmembersinagroupComponent } from './members/usergroups/listmembersinagroup/listmembersinagroup.component';
import { DeletememberinagroupComponent } from './members/usergroups/deletememberinagroup/deletememberinagroup.component';
import { EditmemberinagroupComponent } from './members/usergroups/editmemberinagroup/editmemberinagroup.component';
import { GetemailconfirmusergroupsComponent } from './email/getemailconfirmusergroups/getemailconfirmusergroups.component';
import { LeavegroupdialogComponent } from './members/usergroups/leavegroupdialog/leavegroupdialog.component';
import { HometwofactorauthenticationComponent } from './members/account/hometwofactorauthentication/hometwofactorauthentication.component';
import { QRCodeModule } from 'angularx-qrcode';
import { InvoicearchiveComponent } from './members/client/create-client/invoicearchive/invoicearchive.component';
import { ArchiveaccountclientreceivableComponent } from './members/client/create-client/createinvoice/archiveaccountclientreceivable/archiveaccountclientreceivable.component';
import { ChangeinstalmentduedateemailmessageComponent } from './members/message/changeinstalmentduedateemailmessage/changeinstalmentduedateemailmessage.component';
import { ChangeinstalmentduedatesmsmessageComponent } from './members/message/changeinstalmentduedatesmsmessage/changeinstalmentduedatesmsmessage.component';
import { ShowexampleinstalmentduedateemailComponent } from './members/message/showexampleinstalmentduedateemail/showexampleinstalmentduedateemail.component';
import { ArchiveinstalmentComponent } from './members/client/create-client/archiveinstalment/archiveinstalment.component';
import { PrintbillComponent } from './members/subscription/listuserbills/printbill/printbill.component';
import { InitialemailinstalmentmessageComponent } from './members/message/initialemailinstalmentmessage/initialemailinstalmentmessage.component';
import { InitialsmsinstalmentmessageComponent } from './members/message/initialsmsinstalmentmessage/initialsmsinstalmentmessage.component';
import { ShowexampleinstalmentinitalemailComponent } from './members/message/showexampleinstalmentinitalemail/showexampleinstalmentinitalemail.component';
import { FaqsComponent } from './members/faqs/faqs/faqs.component';
import { HowmanyinstalmentsComponent } from './members/client/create-client/howmanyinstalments/howmanyinstalments.component';
import { TutorialhowtogettosearchallpaymentssingleclientComponent } from './members/tutorials/tutorialhowtogettosearchallpaymentssingleclient/tutorialhowtogettosearchallpaymentssingleclient.component';
import { TutorialhowtoviewstoreaccountsComponent } from './members/tutorials/storeaccounts/tutorialhowtoviewstoreaccounts/tutorialhowtoviewstoreaccounts.component';
import { TutorialsearchinvoiceComponent } from './members/tutorials/invoices/tutorialsearchinvoice/tutorialsearchinvoice.component';
import { TutorialshowtochecksentmessagesComponent } from './members/tutorials/messages/tutorialshowtochecksentmessages/tutorialshowtochecksentmessages.component';
import { ForwardpayfeaturesComponent } from './footer/forwardpayfeatures/forwardpayfeatures.component';
import { PaylaterfeaturesComponent } from './footer/paylaterfeatures/paylaterfeatures.component';
import { InstalmentfeaturesComponent } from './footer/instalmentfeatures/instalmentfeatures.component';
import { TermsandconditionsfeaturesComponent } from './footer/termsandconditionsfeatures/termsandconditionsfeatures.component';
import { PrivacypolicyfeaturesComponent } from './footer/privacypolicyfeatures/privacypolicyfeatures.component';
import { CreditartworkfeaturesComponent } from './footer/creditartworkfeatures/creditartworkfeatures.component';
import { HomefeaturesComponent } from './footer/homefeatures/homefeatures.component';
import { AreyousurewanttoremovesubscriptionComponent } from './members/subscription/areyousurewanttoremovesubscription/areyousurewanttoremovesubscription.component';
import { ArchiveclientComponent } from './members/client/archiveclient/archiveclient.component';
import { DialogaskuserareyousurewanttocreateusergroupComponent } from './members/usergroups/dialogaskuserareyousurewanttocreateusergroup/dialogaskuserareyousurewanttocreateusergroup.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { PrintinvoiceComponent } from './members/client/create-client/printinvoice/printinvoice.component';
import { DialogeeditareyousuretoupdateComponent } from './members/client/create-client/dialogeeditareyousuretoupdate/dialogeeditareyousuretoupdate.component';
import { ArchivestorecreditComponent } from './members/credit/archivestorecredit/archivestorecredit.component';
import { DialogresetinitalmessageComponent } from './members/client/create-client/dialogresetinitalmessage/dialogresetinitalmessage.component';
import { DialogrestinstalmentinitalmessageComponent } from './members/client/create-client/dialogrestinstalmentinitalmessage/dialogrestinstalmentinitalmessage.component';

@NgModule({
    declarations: [
        AppComponent,
        NavComponent,
        LoginComponent,
        RegisterComponent,
        HomeComponent,
        TestErrosComponent,
        NotFoundComponent,
        ServerErrorComponent,
        CreateClientComponent,
        EditAccountComponent,
        TextInputComponent,
        DateInputComponent,
        ClientlistComponent,
        CreateinvoiceComponent,
        InvoicelistComponent,
        AccountsreceivableComponent,
        AccountclientreceivableComponent,
        ClientlistaccountreceivableComponent,
        AreaTextComponent,
        CreateinstalmentsComponent,
        ListinstalmentaccountsComponent,
        ClientaccountpaymentsComponent,
        AccountsreceivablepaymentsComponent,
        ClientinformationComponent,
        EditclientComponent,
        EditinvoiceComponent,
        EditpaymentaccountreceivableComponent,
        EditinstalmentsComponent,
        DeleteclientComponent,
        DeleteinvoiceComponent,
        DeleteaccountreceivableComponent,
        ConfirmImportsComponent,
        DeleteinstalmentsComponent,
        MessageComponent,
        MessageslistComponent,
        ChangetimeformatComponent,
        PaymentreceiveemailmessageComponent,
        PaymentreceivesmsComponent,
        DuedateemailmessageComponent,
        ReceiveemailmessageComponent,
        DuedatesmsComponent,
        ChangeduedatetimeComponent,
        CustomerpaymentpageComponent,
        SucesspaymentComponent,
        FailedpaymentComponent,
        ChangeinitialemailmessageComponent,
        ChangeinitialsmsmessageComponent,
        ShowexmapleduedateemailComponent,
        MessagetestokComponent,
        ShowexamplereceiveemailComponent,
        ShowexampleinitalemailComponent,
        TestresultmessageComponent,
        ImportComponent,
        ImportlistComponent,
        ImportfileinvoiceComponent,
        ImportlistinvoicesComponent,
        EditimportinvoiceComponent,
        DeleteimportinvoiceComponent,
        ConfirmimportsinvoicesComponent,
        StripeaccountslistComponent,
        ListaccountsinvoicesComponent,
        TesttableComponent,
        RefundmessagestripeclientComponent,
        ListclientsubscriptionsComponent,
        ClientsubscriptionsuccessComponent,
        HomeuserComponent,
        FeaturesComponent,
        RadioButtonComponent,
        ImportapiclientComponent,
        VerifyapixeroComponent,
        ImportclientinformationComponent,
        ImportapiinvoiceComponent,
        ImporteditclientComponent,
        ImporteddeleteclientComponent,
        EditdetailsacountComponent,
        ListuserbillsComponent,
        CreatestorecreditComponent,
        ListstorecreditComponent,
        EditstorecreditComponent,
        DeletestorecreditComponent,
        EditchangepasswordComponent,
        EmailresenderComponent,
        ConfirmemailpageComponent,
        ReceivelostemailComponent,
        ResetlostemailComponent,
        ContactemailComponent,
        ImportpageComponent,
        StripeaccountsalltransactionsComponent,
        DialogsendingallsmsComponent,
        TermsandconditionsComponent,
        PolicyComponent,
        ConfirmimportinvoicesComponent,
        TutorialhomeComponent,
        TutorialgettoclientComponent,
        TutorialhowtocreateclientComponent,
        TutorialsearchclientComponent,
        TutorialeditclientComponent,
        TutorialdeleteclientComponent,
        TutorialgettoinvoiceComponent,
        TutorialcreateinvoiceComponent,
        TutorialeditinvoiceComponent,
        TutorialdeleteinvoiceComponent,
        TutoiralviewinvoiceComponent,
        TutorialcreatestoreaccountComponent,
        TutorialhowtoopenstoreaccountComponent,
        TutorialhowtodeletstoreaccountComponent,
        TutorialhowtoeditstoreaccountComponent,
        TutorialhowtosearchforinvoicesComponent,
        HowtogetintoinstalmentComponent,
        TutorialhowtocreateinstalmentComponent,
        TutorialhowtogetpaymentsComponent,
        TutorialhowtocreatenewaccountpaymentComponent,
        TutorialhowtoviewclientaccountsComponent,
        TutorialhowtoeditinstalmentComponent,
        TutorialhowtodeleteinstalmentComponent,
        TutorialhowtoviewinstalmentaccountsComponent,
        TutorialhowtoeditpaymentComponent,
        TutorialhowtodeletepaymentComponent,
        TutorialhowtoviewpaymentComponent,
        TutorialhowtoimportclientsComponent,
        TutorialhowtoimportinvoicesComponent,
        TutorialhowtousemesagesComponent,
        TutorialchangemessagetimesComponent,
        DialogtypeservicesComponent,
        ImportconfirmationdialogComponent,
        CreateusergroupsComponent,
        ListusergroupsComponent,
        EditusergroupsComponent,
        DeleteusergroupsComponent,
        CreatememberingroupComponent,
        ListmembersinagroupComponent,
        DeletememberinagroupComponent,
        EditmemberinagroupComponent,
        GetemailconfirmusergroupsComponent,
        LeavegroupdialogComponent,
        HometwofactorauthenticationComponent,
        InvoicearchiveComponent,
        ArchiveaccountclientreceivableComponent,
        ChangeinstalmentduedateemailmessageComponent,
        ChangeinstalmentduedatesmsmessageComponent,
        ShowexampleinstalmentduedateemailComponent,
        ArchiveinstalmentComponent,
        PrintbillComponent,
        InitialemailinstalmentmessageComponent,
        InitialsmsinstalmentmessageComponent,
        ShowexampleinstalmentinitalemailComponent,
        FaqsComponent,
        HowmanyinstalmentsComponent,
        TutorialhowtogettosearchallpaymentssingleclientComponent,
        TutorialhowtoviewstoreaccountsComponent,
        TutorialsearchinvoiceComponent,
        TutorialshowtochecksentmessagesComponent,
        ForwardpayfeaturesComponent,
        PaylaterfeaturesComponent,
        InstalmentfeaturesComponent,
        TermsandconditionsfeaturesComponent,
        PrivacypolicyfeaturesComponent,
        CreditartworkfeaturesComponent,
        HomefeaturesComponent,
        AreyousurewanttoremovesubscriptionComponent,
        ArchiveclientComponent,
        DialogaskuserareyousurewanttocreateusergroupComponent,
        PrintinvoiceComponent,
        DialogeeditareyousuretoupdateComponent,
        ArchivestorecreditComponent,
        DialogresetinitalmessageComponent,
        DialogrestinstalmentinitalmessageComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgxSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        SharedmaterialModule,
        CKEditorModule,
        RxReactiveFormsModule,
        MaterialModules,
        QRCodeModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
