
<div class="container">
    <div class="row">
        <div class="col-12 mt-4">
            <nav class="navbar navbar-light bg-primary shadow-lg mt-4">
                <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-user fa-2x" aria-hidden="true"></i> Invoice List</a>
            </nav>
        </div>
        <div class="col-lg-12" *ngIf="showConflictInvoice">
            <div class="bs-component">
                <table class="table table-hover">
                    <thead >
                    <tr class="table-primary">
                        <th scope="col">Invoice Number</th>
                        <th scope="col">Invoice Amount</th>
                        <th scope="col">Initial Date</th>
                        <th scope="col">Due Date</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Note</th>
                    </thead>
                    <tbody>

                    <tr class="table-danger" *ngFor="let invoice of invoices; let element = index;">                        
                        <ng-container *ngIf="invoice.conflictImport;">
                            <td>{{invoice.invoiceNumber}}</td>
                            <td>{{invoice.invoiceAmount | currency }}</td>
                            <td>{{invoice.initalDate | date }}</td>
                            <td>{{invoice.dueDate | date }}</td>
                            <td>{{invoice.firstName}}</td>
                            <td>{{invoice.lastName}}</td>
                            <td>{{invoice.note}}</td>
                        </ng-container>
                    </tr>      
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="card mt-4 border-info">
                <div class="card-body">
                  <h2 class="text-center text-primary mt-2">Submit Import Data</h2>
                  <fieldset class="form-group">
                    <p class="font-weight-bold">Do you want customers to pay 1.75% + $0.30?</p>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios1" value="option1" checked="" (change)="onChangePay()">
                        <b class="text-success">Yes:</b> Add in 1.75% + A$0.30 to your customer billing account.
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2" (change)="onChangePay()">
                        <b class="text-danger">No:</b> My business will pay out the transaction fee.
                      </label>
                    </div>
                  </fieldset>          
                  <button class="btn btn-success btn-block" (click)="onSubmit()"> Submit Import List</button>
                  <small class="form-text">Submit your request. Please fill in the necessarily fields so you can import this into our cloud services!</small>
                </div>
            </div>
        </div>
        <div class="col-12 mt-4">         
            <ng-container *ngIf="validationErrors">
                <div class="alert alert-dismissible alert-danger">
                    <strong>Error!</strong> Please make sure you have at least invoice number, invoice amount, payment amount, inital date, and due date for each record! 
                    Check the areas mark with the colour orange in the column name. Also, please ensure that you have your client's name in our database before submiting here. 
                  </div>
            </ng-container>
        </div>
        <div class="col-12">
            <div class="bs-component">
                <table class="table table-hover">
                    <thead >
                    <tr class="table-primary">
                        <th scope="col">#</th>
                        <th scope="col" class="text-warning">Invoice Number</th>
                        <th scope="col" class="text-warning">Invoice Amount </th>
                        <th scope="col" class="text-warning">Payment Amount</th>
                        <th scope="col" class="text-warning">Inital Date</th>
                        <th scope="col" class="text-warning">Due Date</th>
                        <th scope="col" class="text-warning">First Name</th>
                        <th scope="col" class="text-warning">Last Name</th>
                        <th scope="col" class="text-warning">Mobile Contact</th>
                        <th scope="col">Note</th>
                        <th scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="" *ngFor="let import of usersFromImportFile; let element = index;">                   
                        <ng-container *ngIf="import.conflictImport; else defaultImportTable">
                            <th scope="row" class="text-success">{{element + 1}}           </th>
                            <th scope="row" class="text-danger">{{import.invoiceNumber}}</th>
                            <th scope="row" class="text-danger">{{import.invoiceAmount | currency }}</th>
                            <th scope="row" class="text-danger">{{import.paidAmount | currency}}</th>
                            <th scope="row" class="text-danger">{{import.initalDate | date }}</th>
                            <th scope="row" class="text-danger">{{import.dueDate | date }}</th>
                            <th scope="row" class="text-danger">{{import.firstName }}</th>
                            <th scope="row" class="text-danger">{{import.lastName  }}</th>
                            <th scope="row" class="text-danger">{{import.contactMobile  }}</th>
                            <th scope="row" class="text-danger" >{{import.note}}</th>
                            <td>
                                <button mat-icon-button color="primary" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                <button mat-menu-item  (click)="editImport(element)">
                                    <mat-icon color="primary">account_box</mat-icon>
                                    <span>Edit Invoice Account</span>
                                </button>
                                <button mat-menu-item  (click)="deleteImport(element)">
                                    <mat-icon color="warn">delete</mat-icon>
                                    <span>Delete Invoice Account</span>
                                </button>
                                </mat-menu>      
                            </td>
                        </ng-container>
                        <ng-template #defaultImportTable>
                            <th scope="row" class="text-success">{{element + 1}}</th>
                            <th scope="row">{{import.invoiceNumber}}</th>
                            <th scope="row">{{import.invoiceAmount | currency }}</th>
                            <th scope="row">{{import.paidAmount | currency }}</th>
                            <th scope="row">{{import.initalDate | date }}</th>
                            <th scope="row">{{import.dueDate | date }}</th>
                            <th scope="row">{{import.firstName}}</th>
                            <th scope="row">{{import.lastName}}</th>
                            <th scope="row">{{import.contactMobile}}</th>
                            <th scope="row">{{import.note}}</th>
                            <td>
                                <button mat-icon-button color="primary" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                <button mat-menu-item  (click)="editImport(element)">
                                    <mat-icon color="primary">account_box</mat-icon>
                                    <span>Edit Invoice Account</span>
                                </button>
                                <button mat-menu-item  (click)="deleteImport(element)">
                                    <mat-icon color="warn">delete</mat-icon>
                                    <span>Delete Invoice Account</span>
                                </button>
                                </mat-menu>    
                            </td>
                        </ng-template>
                    </tr>      
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>