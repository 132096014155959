import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorialhowtousemesages',
  templateUrl: './tutorialhowtousemesages.component.html',
  styleUrls: ['./tutorialhowtousemesages.component.css']
})
export class TutorialhowtousemesagesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
