<h2 class="text-center text-primary"> WEBSITE TERMS AND CONDITIONS</h2>
<ul class="list-unstyled">
    <li class="font-weight-bold">1.    INTRODUCTION</li>
    <li>(a)	These terms and conditions (Terms) apply when you use this website, www.invoicepager.com.au (Website).</li>
    <li>(b)	You agree to be bound by these Terms which form a binding contractual agreement between you and us,
     INVOICE PAGER PTY LTD ABN 32 670 833 842  trading under the registered business name “Invoice Pager” (Invoice Pager, our, we or us).
    </li>
    <li>(c)	If you don’t agree to these Terms, you must refrain from using the Website.</li>
    <li>(d)	We may change these Terms at any time by updating this page of the Website,
         and your continued use of the Website following such an update will represent an agreement by you to be bound by the Terms as amended.
    </li>
    <li class="mt-2 font-weight-bold">2.	ACCESS AND USE OF THE WEBSITE</li>
    <li><p>	You must only use the Website in accordance with these Terms and any applicable laws, and must ensure that your employees, sub-contractors and any other agents who use or access the Website comply with the Terms and any applicable laws.</p>
    </li>
    <li class="font-weight-bold">3.   YOUR OBLIGATIONS</li>
    <li><p>	You must not:</p></li>
    <li>(a)	copy, mirror, reproduce, translate, adapt, vary, modify, sell, decipher or decompile any part or aspect of the Website without the express consent of Invoice Pager;</li>
    <li>(b)	use the Website for any purpose other than the purposes of browsing, selecting or purchasing goods;</li>
    <li>(c)	use, or attempt to use, the Website in a manner that is illegal or fraudulent or facilitates illegal or fraudulent activity;</li>
    <li>(d)	use, or attempt to use, the Website in a manner that may interfere with, disrupt or create undue burden on the Website or the servers or networks that host the Website;</li>
    <li>(e)	use the Website with the assistance of any automated scripting tool or software;</li>
    <li>(f)	act in a way that may diminish or adversely impact the reputation of Invoice Pager, including by linking to the Website on any other website; and</li>
    <li>(g)	attempt to breach the security of the Website, or otherwise interfere with the normal functions of the Website, including by:</li>
    <li>(i)	gaining unauthorised access to Website accounts or data;</li>
    <li>(ii)	scanning, probing or testing the Website for security vulnerabilities;</li>
    <li>(iii)	overloading, flooding, mailbombing, crashing or submitting a virus to the Website; or</li>
    <li>(iv)	instigate or participate in a denial-of-service attack against the Website.</li>
    <li class="mt-2 font-weight-bold">4.    INFORMATION ON THE WEBSITE</li>
    <li><p>	While we make every effort to ensure that the information on the Website is as up-to-date and accurate as possible, you acknowledge and agree that we do not (to the maximum extent permitted by law) guarantee that:</p>
    </li>
    <li>(a)	the Website will be free from errors or defects;</li>
    <li>(b)	the Website will be accessible at all times;</li>
    <li>(c)	messages sent through the Website will be delivered promptly, or delivered at all;</li>
    <li>(d)	information you receive or supply through the Website will be secure or confidential; or</li>
    <li>(e)	any information provided through the Website is accurate or true.</li>
    <li><p>	We reserve the right to change any information or functionality on the Website by updating the Website at any time without notice, including product descriptions, prices and other Website Content.</p>
    </li>
    <li class="font-weight-bold">5.    INTELLECTUAL PROPERTY</li>
    <li>(a)	Invoice Pager retains ownership of the Website and all materials on the Website (including text, graphics, logos, design, icons, images, sound and video recordings, pricing, downloads and software) (Website Content) and reserves all rights in any intellectual property rights owned or licensed by it not expressly granted to you.</li>
    <li>(b)	You may make a temporary electronic copy of all or part of the Website for the sole purpose of viewing it. You must not otherwise reproduce, transmit, adapt, distribute, sell, modify or publish the Website or any Website Content without prior written consent from Invoice Pager or as permitted by law.</li>
    <li class="font-weight-bold">6.    LINKS TO OTHER WEBSITES</li>
    <li>(a)	The Website may contain links to other websites that are not our responsibility. We have no control over the content of the linked websites and we are not responsible for it.</li>
    <li>(b)	Inclusion of any linked website on the Website does not imply our approval or endorsement of the linked website.</li>
    <li class="mt-2 font-weight-bold">7.    SECURITY</li>
    <li><p>	Invoice Pager does not accept responsibility for loss or damage to computer systems, mobile phones or other electronic devices arising 
        in connection with use of the Website. You should take your own precautions to ensure that the process that you employ for accessing the Website does not expose you to risk of viruses, malicious computer code or other forms of interference.</p>
    </li>
    <li class="font-weight-bold">8.    REPORTING MISUSE</li>
    <li><p>	If you become aware of misuse of the Website by any person, any errors in the material on the Website or any difficulty in accessing or using the Website,
         please contact us immediately using the contact details or form provided on our Website.</p>
    </li>
    <li>9.  PRIVACY</li>
    <li><p>You agree to be bound by our Privacy Policy, which can be found <a routerLink="/privacypolicy">here</a>.</p></li>
    <li class="font-weight-bold">10.    LIABILITY</li>
    <li><p>	We make no warranties or representations about this Website or any of its content and will not be responsible to you or any third party for any direct or consequential loss suffered in
         connection with the use of this Website. To the maximum extent permitted by law, we exclude any liability that may arise due to your use of our Website and/or the information or materials contained on it. You agree to indemnify us for any loss or liability arising out of your use of this Website.</p>
    </li>
    <li class="font-weight-bold">11.    GENERAL</li>
    <li class="mt-2"> 11.1	GOVERNING LAW AND JURISDICTION</li>
    <li><p>	This agreement is governed by the law applying in New South Wales. Each party irrevocably submits to the exclusive jurisdiction of the courts of New South Wales and courts of appeal from them in respect of any proceedings arising out of or in connection with this agreement.
         Each party irrevocably waives any objection to the venue of any legal process on the basis that the process has been brought in an inconvenient forum.</p>
    </li>
    <li>11.2	WAIVER</li>
    <li><p>	No party to this agreement may rely on the words or conduct of 
        any other party as a waiver of any right unless the waiver is in writing and signed by the party granting the waiver.</p>
    </li>
    <li>11.3	SEVERANCE</li>
    <li>
        <p>	Any term of this agreement which is wholly or partially void or unenforceable is severed to the extent that it is void or unenforceable. 
            The validity and enforceability of the remainder of this agreement is not limited or otherwise affected.</p>
    </li>
    <li>11.4	JOINT AND SEVERAL LIABILITY</li>
    <li><p>	An obligation or a liability assumed by, or a right conferred on, two or more persons binds or benefits them jointly and severally.</p>
    </li>
    <li>11.5	ASSIGNMENT</li>
    <li><p>	A party cannot assign, novate or otherwise transfer any of its rights or obligations under this agreement without the prior written consent of the other party.</p>
    </li>
    <li>11.6	ENTIRE AGREEMENT</li>
    <li><p>	This agreement embodies the entire agreement between the parties and supersedes any prior negotiation,
         conduct, arrangement, understanding or agreement, express or implied, in relation to the subject matter of this agreement.</p>
    </li>
    <li>11.7	INTERPRETATION</li>
    <li class="mt-2">(a)	(singular and plural) words in the singular includes the plural (and vice versa);</li>
    <li>(b)	(gender) words indicating a gender includes the corresponding words of any other gender;</li>
    <li>(c)	(defined terms) if a word or phrase is given a defined meaning, any other part of speech or grammatical form of that word or phrase has a corresponding meaning;</li>
    <li>(d)	(person) a reference to “person” or “you” includes an individual, the estate of an individual, a corporation, an authority, an association, consortium or joint venture (whether incorporated or unincorporated), a partnership, a trust and any other entity;</li>
    <li>(e)	(party) a reference to a party includes that party’s executors, administrators, successors and permitted assigns, including persons taking by way of novation and, in the case of a trustee, includes any substituted or additional trustee;</li>
    <li>(f)	(this agreement) a reference to a party, clause, paragraph, schedule, exhibit, attachment or annexure is a reference to a party, clause, paragraph, schedule, exhibit, attachment or annexure to or of this agreement, and a reference to this agreement includes all schedules, exhibits, attachments and annexures to it;</li>
    <li>(g)	(document) a reference to a document (including this agreement) is to that document as varied, novated, ratified or replaced from time to time;</li>
    <li>(h)	(headings) headings and words in bold type are for convenience only and do not affect interpretation;</li>
    <li>(i)	(includes) the word “includes” and similar words in any form is not a word of limitation;</li>
    <li>(j)	(adverse interpretation) no provision of this agreement will be interpreted adversely to a party because that party was responsible for the preparation of this agreement or that provision; and</li>
    <li>(k)	(currency) a reference to $, or “dollar”, is to Australian currency, unless otherwise agreed in writing.</li>
</ul>