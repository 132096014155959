<app-nav></app-nav>
<section class="pt-4 mt-4">
    <div class="container px-lg-5">
        <!-- Header -->
        <div class="row gx-lg-5">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xxl-12 mb-5">
                <div class="sub-heading text-center">
                    <h1 class="text-center text-primary mt-2 font-weight-bold">Features</h1>
                    <h2>What features does Invoice Pager offer?</h2>
                </div>
            </div>
        </div>
        <!-- Page Features-->
        <div class="row gx-lg-5">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xxl-12 mb-5">
                <div class="card bg-white h-100 shadow-lg">
                    <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                        <img src="/assets/images/7176685.jpg" alt="SMS" class="img-thumbnail">
                        <h2 class="fs-4 fw-bold">Store Accounts</h2>
                        <p class="mb-0">
                            With Invoice Pager, you can create and store accounts where you don’t need to refund cash directly back to your customers when requested. Instead, you can store information related to debit and credit. This allows you to keep track of money owed to your customers (credit) and money you owe your customers (debit).
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xxl-12 mb-5">
                <div class="card bg-white h-100 shadow-lg">
                    <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0 ">
                        <img src="/assets/images/29303653_vector_47713.jpg" alt="Email" class="img-thumbnail">
                        <h2 class="fs-4 fw-bold">Accept Payments</h2>
                        <p class="mb-0">
                            With Invoice Pager, you can accept online payments by credit card, debit card, or direct debit straight from your invoice. This allows customers to pay the way they want, increases the likelihood of getting paid on time, and reduces the time spent chasing payments.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xxl-12 mb-5">
                <div class="card bg-white h-100 shadow-lg">
                    <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                        <img src="/assets/images/Wavy_Bus-14_Single-09.jpg" alt="Bills" class="img-thumbnail">
                        <h2 class="fs-4 fw-bold">Manage Invoice Pager Clients</h2>
                        <p class="mb-0">
                            Invoice Pager’s client contact feature allows you to view a customer’s or sales, invoices, and payments all in one place.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xxl-12 mb-5">
                <div class="card bg-white h-100 shadow-lg">
                    <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                        <img src="/assets/images/10780020_19197439.jpg" alt="Pay online" class="img-thumbnail">
                        <h2 class="fs-4 fw-bold">Import Files</h2>
                        <p class="mb-0">
                            With Invoice Pager’s “Import Files” feature, you can easily import clients and invoices into our cloud services.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xxl-12 mb-5">
                <div class="card bg-white h-100 shadow-lg">
                    <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                        <img src="/assets/images/email.jpg" alt="Pay online" class="img-thumbnail">
                        <h2 class="fs-4 fw-bold">Account Payments - Pay Bills</h2>
                        <p class="mb-0">
                            Invoice Pager helps you stay on top of your finances by making it easy to manage your bills and providing a clear overview of your accounts payable and cash flow.”
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-features></app-features>