import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { ClientSubscripton } from 'src/app/_models/clientsubscription';
import { ClientSubscriptionService } from 'src/app/_services/clientsubscription/client-subscription.service';
import { HtmlfooterService } from 'src/app/_services/htmlfooter.service';

@Component({
  selector: 'app-printbill',
  templateUrl: './printbill.component.html',
  styleUrls: ['./printbill.component.css']
})
export class PrintbillComponent implements OnInit, OnDestroy {

  subscriptionID: number = 0;
  private clientSubscriptionSource = new ReplaySubject<ClientSubscripton>(1); 
  clientSubscription$ = this.clientSubscriptionSource.asObservable();

  constructor(private service: ClientSubscriptionService, private route: ActivatedRoute, private htmlFooter: HtmlfooterService) { }
  ngOnDestroy(): void {
    this.htmlFooter.showFooter.next(true);
  }

  ngOnInit(): void {
    this.showDefault();
    this.getParrams();
    this.onLoad();
  }

  showDefault(){
    this.htmlFooter.showFooter.next(false);
  }

  onLoad(){
    this.service.getPrintSubscription(this.subscriptionID).subscribe(response => {
      console.log(response);
      this.clientSubscriptionSource.next(response);
    }, err => {
      console.log(err);
    })
  }

  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.subscriptionID = +params.get("id");
    });
  }

  getProductType(id: number): string{
    switch(id){
      case 1: {
        return 'Basic Subscription';        
      }
      case 2: {
        return 'Lite Subscription';        
      }
      case 3: {
        return 'Standard Subscription';        
      }
      case 4: {
        return 'Super Subscription';        
      }
      case 5: {
        return 'Master Subscription';        
      }
      case 6: {
        return 'Ultimate Subscription';        
      }
      case 7: {
        return 'Premium Plus Subscription';        
      }
      case 8: {
        return 'Tier 1 Corporate Subscription';        
      }
      case 9: {
        return 'Tier 2 Corporate Subscription';        
      }
      case 10: {
        return 'Custom Plan';        
      }
    }
    return "";
  }

  addStringStartDate(): string {
    return " - Start Date: ";
  }

  addStringEndDate(): string {
    return " End Date: ";
  }

}
