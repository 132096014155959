import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsergroupspermissionsService } from 'src/app/_services/usergroups/usergroupspermissions.service';

@Component({
  selector: 'app-deletememberinagroup',
  templateUrl: './deletememberinagroup.component.html',
  styleUrls: ['./deletememberinagroup.component.css']
})
export class DeletememberinagroupComponent implements OnInit {
  accountID: number;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public services: UsergroupspermissionsService) { }

  ngOnInit(): void {
    this.accountID = this.data.id; 
  }

  deleteAccount(){
    this.services.deleteMember(this.accountID).subscribe( response => {
      this.data.deleteClient = true;
    }, error => {
      console.log(error);
    })
  }

}
