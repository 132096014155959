
<div class="card border-success shadow-lg p-3 mb-5 bg-white rounded mt-4" [hidden]="true">

    <div class="card-body">
    
          <h2>Search </h2>
          <hr>
        <div class="col-12">
            <form-group>
                <label>Search Query: </label>
                <mat-form-field class="ms-4">
                    <mat-label>Filter</mat-label>
                    <input class="form-control" matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
                    <small class="form-text text-muted">Must have two characters to use seach feature!</small>
                </mat-form-field>
            </form-group>   
        </div>
    </div>
</div>
<div class="bs-component mt-4">
    <div class="alert alert-dismissible alert-warning">
      <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
      <p><strong>Warning!</strong> Please note that any updates to this account will not be visible to all linked users. To view the most recent information, it may be necessary to refresh this page.</p>
    </div>
  </div>
<div class="mat-elevation-z8 mt-4">
    
    <table mat-table [dataSource]="stripeDataSource" matSort>
      <!-- First Name Column -->
     <ng-container matColumnDef="invoiceNumber" *ngIf="accountService.currentUser$ | async as user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >
            <ng-container *ngIf="user.typeOfService; else showInvoiceNo ">
                Billing No.
            </ng-container>
            <ng-template #showInvoiceNo>
                Invoice No.
            </ng-template>
        </th>
        <td mat-cell *matCellDef="let account"> <p class="font-weight-bold text-primary">{{account.invoiceNumber | titlecase}}</p> </td>
     </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name </th>
        <td mat-cell *matCellDef="let account"> <p>{{account.name | titlecase}}</p> </td>
    </ng-container>

      <!-- First Name Column -->
     <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name </th>
      <td mat-cell *matCellDef="let account"> <p>{{account.firstName | titlecase}}</p> </td>
     </ng-container>

       <!-- Last Name Column -->
       <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name </th>
        <td mat-cell *matCellDef="let account"> <p>{{account.lastName | titlecase}}</p> </td>
       </ng-container>

        <!-- Fees Paid Column -->
        <ng-container matColumnDef="fees">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fees </th>
            <td mat-cell *matCellDef="let account" class="text-success font-weight-bold">
                    <p>{{account.fees | currency}}</p> 
            </td>
            </ng-container>

          <!-- Amount Paid Column -->
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount </th>
            <td mat-cell *matCellDef="let account" class="text-success font-weight-bold">
                 <p>{{account.amount | currency}}</p> 
            </td>
         </ng-container>

            <!-- Amount Paid Column -->
            <ng-container matColumnDef="isRefund">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status Payment</th>
                <ng-container *matCellDef="let account">
                    <td>
                        <p class="h4">
                            <span *ngIf="account.isRefund === true"  class="badge bg-danger">Money has been refunded back to your customer</span>
                            <span *ngIf="account.isRefund === false " class="badge bg-success">Paid</span>
                            <span *ngIf="account.hasAnyErrorsStripeProcess" class="badge bg-danger"> Something has gone wrong here! <b>Call us.</b></span>
                        </p>
                    </td>                   
                </ng-container>                  
             </ng-container>

             <!-- Amount Paid Column -->
            <ng-container matColumnDef="datePaid">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Paid </th>
                <td mat-cell *matCellDef="let account"> <p>{{account.datePaid | date:'medium'}}</p> </td>
             </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[25]" showFirstLastButtons></mat-paginator>
</div>