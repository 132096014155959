<section class="pt-4 mt-4">
    <div class="container px-lg-5">
        <!-- Header -->
        <div class="row gx-lg-5">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xxl-12 mb-5">
                <div class="sub-heading text-center">
                    <h1 class="text-center text-primary mt-2 font-weight-bold">SERVICES</h1>
                    <h2>We are Offering All kinds of I.T. payment services.</h2>
                </div>
            </div>
        </div>
        <!-- Page Features-->
        <div class="row gx-lg-5">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xxl-12 mb-5">
                <div class="card bg-white h-100 shadow-lg">
                    <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                        <img src="/assets/images/sms.jpg" alt="SMS" class="img-thumbnail">
                        <h2 class="fs-4 fw-bold">Pay-Online Via SMS</h2>
                        <p class="mb-0">Employing state-of-the-art technology, we have developed an advanced Software as a Service (SaaS) solution tailored to facilitate timely and professional communication with your clients. Our platform seamlessly delivers payment reminders via SMS, providing a versatile framework that accommodates various payment modalities, including upfront payments, post-service settlements, and flexible installment options. This comprehensive approach enhances the efficiency of managing accounts receivable, ensuring a sophisticated and adaptable solution for optimizing financial transactions within your business.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xxl-12 mb-5">
                <div class="card bg-white h-100 shadow-lg">
                    <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0 ">
                        <img src="/assets/images/email.jpg" alt="Email" class="img-thumbnail">
                        <h2 class="fs-4 fw-bold">Pay-Online Via Email</h2>
                        <p class="mb-0">
                            Our cutting-edge SaaS solution ensures timely and professional client communication. Offering versatile payment reminders via Email, it accommodates upfront payments, post-service settlements, and flexible installments. This sophisticated approach optimizes accounts receivable management for enhanced efficiency in financial transactions.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xxl-12 mb-5">
                <div class="card bg-white h-100 shadow-lg">
                    <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                        <img src="/assets/images/bills.jpg" alt="Bills" class="img-thumbnail">
                        <h2 class="fs-4 fw-bold">Invoices, Payments Logs, Clients, & Instalments integations</h2>
                        <p class="mb-0">Automated processing for invoices, payments, and client data. Effortlessly upload with a click for streamlined efficiency on our secure cloud services.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xxl-12 mb-5">
                <div class="card bg-white h-100 shadow-lg">
                    <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                        <img src="/assets/images/pay-online.jpg" alt="Pay online" class="img-thumbnail">
                        <h2 class="fs-4 fw-bold">Pay Via Stripe API</h2>
                        <p class="mb-0">
                            Invoice Pager seamlessly integrates with the Stripe API to facilitate online payments for your customers. Our platform includes a robust feature to track payments made through Stripe, providing you with comprehensive visibility. Additionally, you have the flexibility to initiate refunds effortlessly, enhancing your control over financial transactions </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>