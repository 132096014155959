<app-nav></app-nav>
<div class="container mt-4">
    <div class="container-fluid">
         <!-- Page Features-->
         <div class="row gx-lg-5">
            <div class="col-lg-2 col-md-2 col-sm-12 col-xxl-12 mb-5">
            
            </div>  
            <div class="col-lg-8 col-md-8 col-sm-12 col-xxl-12 mb-5">
                <div class="card bg-white h-100 shadow-lg">
                    <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                        <img src="/assets/images/free.jpg" alt="SMS" class="img-thumbnail">
                        <h2 class="fs-4 fw-bold">Our Art Work Goes To</h2>
                        <p class="mb-0">
                            All of our artwork is available on Freepik.com, a leading platform for high-quality graphic resources
                        </p>
                    </div>
                </div>
            </div>           
            <div class="col-lg-2 col-md-2 col-sm-12 col-xxl-12 mb-5">
               
            </div>   
        </div>
    </div>
</div>
