import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { DelStoreAccount } from 'src/app/_models/delstoreaccount';
import { StoreAccountModel } from 'src/app/_models/storeaccountmodel';
import { StoreaccountService } from 'src/app/_services/storeaccount/storeaccount.service';

@Component({
  selector: 'app-deletestorecredit',
  templateUrl: './deletestorecredit.component.html',
  styleUrls: ['./deletestorecredit.component.css']
})
export class DeletestorecreditComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private services: StoreaccountService, private toastr: ToastrService) { }

  ngOnInit(): void {
    
  }

  deleteAccount(){
    this.data.deleteClient = true;
    this.services.deleteStoreAccount(this.data.id).subscribe(response => {
      if (response == "s")
      {
        this.services.dataSource = new MatTableDataSource<StoreAccountModel>();
        this.toastr.success("Successfully updated account!");
      }
    }, err => {
      console.log(err);
    })
  }

}
