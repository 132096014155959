<app-nav></app-nav>
<div class="container">
  <div class="bs-docs-section">
    <div class="row">
      <nav class="navbar navbar-light bg-primary shadow-lg mt-4">
        <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Membership Account</a>
      </nav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item active">Membership Billing Details</li>
      </ol>
    </div>
    <div class="row" *ngIf="subscriptionBillAccount">
      <div class="col-sm-12 col-md-6 col-xl-6 col-lg-6 py-5 bg-white border text-black shadow-lg">
        <h2 id="nav-breadcrumbs" class="text-center text-primary">Subscription Account Details</h2>
        <ng-container *ngIf="subscriptionBillAccount.isActiveMembership === true" >
          <p>Do you wish to cancel your Membership?</p>
          <button mat-raised-button color="primary" (click)="cancelMembership()">Cancel Membership</button>
        </ng-container>
        <div class="bs-component">      
            <mat-list>
              <div mat-subheader>Current Membership ID</div>
              <mat-list-item>
                  <mat-icon mat-list-icon color="warn">account_box</mat-icon>
                  <div mat-line> 
                    <ng-container *ngIf="subscriptionBillAccount.currentSubscriptionBillID; else showNullIDSubscription;">
                      {{subscriptionBillAccount.currentSubscriptionBillID}}
                    </ng-container>
                    <ng-template #showNullIDSubscription>
                      <mat-chip-list>
                        <mat-chip color="warn" selected>None</mat-chip>
                      </mat-chip-list> 
                    </ng-template>
                  </div>
                </mat-list-item>
              <mat-divider></mat-divider>
              <div mat-subheader>Current Membership</div>
              <mat-list-item>
                  <mat-icon mat-list-icon color="warn">account_box</mat-icon>
                  <div mat-line> 
                    {{subscriptionBillAccount.currentMemberShip}}
                  </div>
                </mat-list-item>
              <mat-divider></mat-divider>
              <div mat-subheader>Are you still on a Monthly Subscription plan?</div>
              <mat-list-item>
                  <mat-icon mat-list-icon color="warn">account_box</mat-icon>
                  <div mat-line> 
                    <ng-container *ngIf="subscriptionBillAccount.isActiveMembership === true">
                      <mat-chip color="accent" selected>Yes</mat-chip>
                    </ng-container>
                    <ng-container *ngIf="subscriptionBillAccount.isActiveMembership === false">
                      <mat-chip color="warn" selected>No</mat-chip>
                    </ng-container>
                  </div>
                </mat-list-item>
              <mat-divider></mat-divider>
              <div mat-subheader>First Name</div>
              <mat-list-item>
                  <mat-icon mat-list-icon color="warn">account_box</mat-icon>
                  <div mat-line> 
                    {{subscriptionBillAccount.firstName | titlecase }}
                  </div>
                </mat-list-item>
              <mat-divider></mat-divider>
              <div mat-subheader>Last Name</div>
              <mat-list-item>
                  <mat-icon mat-list-icon color="warn">account_box</mat-icon>
                  <div mat-line> 
                    {{subscriptionBillAccount.lastName | titlecase}}
                  </div>
                </mat-list-item>
              <mat-divider></mat-divider>
                <div mat-subheader>Can Send Emails?</div>
                <mat-list-item>
                    <mat-icon mat-list-icon color="warn">account_box</mat-icon>
                    <div mat-line> 
                      <ng-container *ngIf="subscriptionBillAccount.canSendEmail; else showSendEmailNo;">
                        <mat-chip-list>
                          <mat-chip color="accent" selected>Yes</mat-chip>
                        </mat-chip-list> 
                      </ng-container>
                      <ng-template #showSendEmailNo>
                        <mat-chip-list>
                          <mat-chip color="warn" selected>No</mat-chip>
                        </mat-chip-list> 
                      </ng-template>
                    </div>
                  </mat-list-item>
                <mat-divider></mat-divider>
                <div mat-subheader>Can Send Payment Link to Emails?</div>
                <mat-list-item>
                    <mat-icon mat-list-icon color="warn">account_box</mat-icon>
                    <div mat-line> 
                      <ng-container *ngIf="subscriptionBillAccount.canSendEmailViaPaymentLink; else showSendViaPaymentNo;">
                        <mat-chip-list>
                          <mat-chip color="accent" selected>Yes</mat-chip>
                        </mat-chip-list> 
                      </ng-container>
                      <ng-template #showSendViaPaymentNo>
                        <mat-chip-list>
                          <mat-chip color="warn" selected>No</mat-chip>
                        </mat-chip-list> 
                      </ng-template>
                    </div>
                  </mat-list-item>
                <mat-divider></mat-divider>
                <div mat-subheader>Can Send SMS?</div>
                <mat-list-item>
                    <mat-icon mat-list-icon color="warn">account_box</mat-icon>
                    <div mat-line> 
                      <ng-container *ngIf="subscriptionBillAccount.canSendSMS; else showSMSNo;">
                        <mat-chip-list>
                          <mat-chip color="accent" selected>Yes</mat-chip>
                        </mat-chip-list> 
                      </ng-container>
                      <ng-template #showSMSNo>
                        <mat-chip-list>
                          <mat-chip color="warn" selected>No</mat-chip>
                        </mat-chip-list> 
                      </ng-template>
                    </div>
                  </mat-list-item>
                <mat-divider></mat-divider>
                <div mat-subheader>Max Limit SMS Capacity</div>
                <mat-list-item>
                    <mat-icon mat-list-icon color="warn">account_box</mat-icon>
                    <div mat-line> 
                      {{subscriptionBillAccount.maxLimitSMS}} max SMS 
                    </div>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                  <div mat-subheader>Current SMS's Used this Session</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="warn">account_box</mat-icon>
                      <div mat-line> 
                        {{subscriptionBillAccount.totalSMSSent}} SMS's
                      </div>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                  <div mat-subheader>Current Emails Used this Session</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="warn">account_box</mat-icon>
                      <div mat-line> 
                        {{subscriptionBillAccount.totalEmailSent}} Emails
                      </div>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                  <div mat-subheader>Current Users Assigned to this Account</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="warn">account_box</mat-icon>
                      <div mat-line> 
                        {{subscriptionBillAccount.currentUsers}} users
                      </div>
                  </mat-list-item>
                  <div mat-subheader>Max Users Capacity</div>
                  <mat-list-item>
                      <mat-icon mat-list-icon color="warn">account_box</mat-icon>
                      <div mat-line> 
                        {{subscriptionBillAccount.limitMaxUsers}} max Users
                      </div>
                  </mat-list-item>
                <mat-divider></mat-divider>
            </mat-list>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 py-5 bg-white border text-black text-center shadow-lg">
            <div class="card-body">
              <img src="/assets/images/bills.jpg" class="img-thumbnail">
            </div>
        </div>
      </div>
    </div>
  </div>
<hr>  
<div class="container">
  <div class="col-12">     
      <h1 class="text-center text-primary mt-2 font-weight-bold">Your Membership Bills</h1>
  </div> 


  
  <ng-container *ngIf="dataSource">
      <div class="mat-elevation-z8 mt-2">
          <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>   
            <ng-container matColumnDef="SubscriptionID">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Meb ID</th>
              <td mat-cell *matCellDef="let subscription" > 
                  <div mat-line> 
                      <ng-container *ngIf="subscription.id">
                        <mat-chip-list *ngIf="subscription.id === subscriptionBillAccount.currentSubscriptionBillID">
                          <mat-chip color="primary" selected>{{subscription.id}}</mat-chip>
                        </mat-chip-list> 
                        <mat-chip-list *ngIf="subscription.id !== subscriptionBillAccount.currentSubscriptionBillID">
                          <mat-chip color="accent" selected>{{subscription.id}}</mat-chip>
                        </mat-chip-list> 
                      </ng-container>
                    </div>
              </td>
              </ng-container>    
                                <!-- Bussiness Name Column -->
            <ng-container matColumnDef="StartDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
                <td mat-cell *matCellDef="let subscription" > 
                    {{subscription.startDate | date }}
                </td>
                </ng-container>

                   <!-- Bussiness Name Column -->
            <ng-container matColumnDef="EndDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
                <td mat-cell *matCellDef="let subscription" > 
                    {{subscription.endDate | date }}
                </td>
                </ng-container>    
              <!-- Bussiness Name Column -->
               <ng-container matColumnDef="StatusMembership">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status Subscription</th>
                <td mat-cell *matCellDef="let subscription" >
                    <div mat-line> 
                    <ng-container *ngIf="subscription.membershipActive; else showActivemembership">
                        <mat-chip-list>
                            <mat-chip color="primary" selected>Active</mat-chip>
                         </mat-chip-list> 
                        
                    </ng-container> 
                    <ng-template #showActivemembership>
                        <mat-chip-list>
                            <mat-chip color="warn" selected>Cancelled</mat-chip>
                          </mat-chip-list>                         
                    </ng-template>
                    </div>
                </td>
                </ng-container>
                <!-- Bussiness Name Column -->
            <ng-container matColumnDef="clientpaidTotalcostWithGST">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Cost</th>
            <td mat-cell *matCellDef="let subscription" > 
                {{subscription.clientpaidTotalcostWithGST | currency }}
            </td>
            </ng-container>    
  
              <!-- Address Column -->
               <ng-container matColumnDef="messageEmailSent">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Messages Email Sent</th>
                  <td mat-cell *matCellDef="let subscription">
                    {{subscription.totalEmailSent}}
                  </td>
                </ng-container>
              <!-- Address Column -->
               <ng-container matColumnDef="messageSMSSent">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Messages SMS Sent</th>
                  <td mat-cell *matCellDef="let subscription">
                    {{subscription.totalSMSSent}}
                  </td>
                </ng-container>
                            <!-- Bussiness Name Column -->
              <ng-container matColumnDef="membershipActive">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Membership Active</th>
                <td mat-cell *matCellDef="let subscription" >
                    <ng-container *ngIf="subscription.membershipActive; else shownegetive">
                        Yes
                    </ng-container> 
                    <ng-template #shownegetive>
                        No
                    </ng-template>
               </td>
              </ng-container>

            <!-- Bussiness Name Column -->
            <ng-container matColumnDef="MembershipType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Membership Type</th>
            <td mat-cell *matCellDef="let subscription" > 
                <div mat-line>           
                <ng-container *ngIf="subscription.productType === 0">
                    <mat-chip-list>
                        <mat-chip color="accent" selected>Free</mat-chip>
                      </mat-chip-list> 
                    
                </ng-container>
                <ng-container *ngIf="subscription.productType === 1">
                    <mat-chip-list>
                        <mat-chip color="primary" selected>Basic Subscription</mat-chip>
                      </mat-chip-list> 
                    
                </ng-container>
                <ng-container *ngIf="subscription.productType === 2">
                    <mat-chip-list>
                        <mat-chip color="primary" selected>Lite Subscription</mat-chip>
                      </mat-chip-list> 
                    
                </ng-container>
                <ng-container *ngIf="subscription.productType === 3">
                    <mat-chip-list>
                        <mat-chip color="primary" selected>Standard Subscription</mat-chip>
                      </mat-chip-list> 
                  
                </ng-container>
                <ng-container *ngIf="subscription.productType === 4">
                    <mat-chip-list>
                        <mat-chip color="primary" selected>Super Subscription</mat-chip>
                      </mat-chip-list>                   
                </ng-container>
                <ng-container *ngIf="subscription.productType === 5">
                    <mat-chip-list>
                        <mat-chip color="primary" selected>Master Subscription</mat-chip>
                    </mat-chip-list>                
                </ng-container>
                <ng-container *ngIf="subscription.productType === 6">
                  <mat-chip-list>
                      <mat-chip color="primary" selected>Ultramate Subscription</mat-chip>
                  </mat-chip-list>                
              </ng-container>
                <ng-container *ngIf="subscription.productType === 7">
                    <mat-chip-list>
                        <mat-chip color="primary" selected>Premium Plus Subscription</mat-chip>
                      </mat-chip-list> 
                    
                </ng-container>
                <ng-container *ngIf="subscription.productType === 8">
                    <mat-chip-list>
                        <mat-chip color="primary" selected>Corporate Tier 1 Subscription</mat-chip>
                      </mat-chip-list> 
                </ng-container>
                <ng-container *ngIf="subscription.productType === 9">
                  <mat-chip-list>
                      <mat-chip color="primary" selected>Corporate Tier 2 Subscription</mat-chip>
                    </mat-chip-list> 
                </ng-container>
                <ng-container *ngIf="subscription.productType === 10">
                  <mat-chip-list>
                      <mat-chip color="warn" selected>Custom</mat-chip>
                    </mat-chip-list> 
                </ng-container>
              </div>
            </td>
            </ng-container>   
 
               <!-- Stripe Connected -->
               <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="text-center"> </th>
                  <td mat-cell *matCellDef="let subscription">
                    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>        
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item [routerLink]="['/members/printbill/', subscription.id]" >
                            <mat-icon color="accent">cloud_download</mat-icon>
                            <span class="font-weight-bold">Print Receipt</span>
                          </button>
  
                      </mat-menu>                         
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
        
        </div>
  </ng-container>
</div>