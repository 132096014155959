import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { file } from '@rxweb/reactive-form-validators';
import { element } from 'protractor';
import { ImportfileInvoice } from 'src/app/_models/importfileinvoice';
import { Invoice } from 'src/app/_models/invoice';
import { ImportfilesService } from 'src/app/_services/import/importfiles.service';
import { ConfirmimportinvoicesComponent } from '../confirmimportinvoices/confirmimportinvoices.component';
import { ConfirmimportsinvoicesComponent } from '../confirmimportsinvoices/confirmimportsinvoices.component';
import { DeleteimportinvoiceComponent } from '../deleteimportinvoice/deleteimportinvoice.component';
import { EditimportinvoiceComponent } from '../editimportinvoice/editimportinvoice.component';

@Component({
  selector: 'app-importlistinvoices',
  templateUrl: './importlistinvoices.component.html',
  styleUrls: ['./importlistinvoices.component.css']
})
export class ImportlistinvoicesComponent implements OnInit {
  @Input() usersFromImportFile: ImportfileInvoice[];
  invoices: ImportfileInvoice[] = [];
  validationErrors: boolean = false;
  showConflictInvoice: boolean = false;
  onClickPayExtra: boolean = true;
  constructor(private service: ImportfilesService, private dialog: MatDialog, private router: Router ) { }

  ngOnInit(): void {
    console.log(this.usersFromImportFile);
    this.getInvoiceList();
  }

  getInvoiceList(){
    this.service.getListInvoice(this.usersFromImportFile).subscribe(response => {
      this.invoices = response;    
      //this.checkConflictClient();
      this.checkConflictImports(); 
    }, error => {
      console.log(error);
    })
  }

  checkConflictClient(){
    var counter: number = 0;
    this.invoices.forEach(invoice => {      
      if (this.usersFromImportFile != null)
      {
        const duplicates = this.usersFromImportFile.find(x => x.firstName === invoice.firstName.toLowerCase() && x.lastName === invoice.lastName.toLowerCase() && x.contactMobile === invoice.contactMobile.toLowerCase());
        if (duplicates)
        {
          this.invoices[counter].conflictImport = true;
        }
      }
      counter++;
    });
  }

  checkConflictImports(){
    let counter: number = 0;
    this.usersFromImportFile.forEach(fileImport => {

      const duplicateInvoiceNumber = this.invoices.find(i => i.invoiceNumber === fileImport.invoiceNumber)
      if (duplicateInvoiceNumber)
      {
        this.usersFromImportFile[counter].conflictImport = true;
      }

      //const duplicateFirstNameLastNameAndPhone = this.invoices.find(i => i.firstName.toLowerCase() === fileImport.firstName.toLowerCase()
      //   && i.lastName.toLowerCase() === fileImport.lastName.toLowerCase() && i.contactMobile.toLowerCase() === fileImport.contactMobile.toLowerCase());
      
      //if (duplicateFirstNameLastNameAndPhone)
      //{
      //  this.usersFromImportFile[counter].conflictImport = true;
      //}

      counter++;
    });
  }

  editImport(id: any){
    const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = "50%";
      dialogConfig.height = "100%";
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.data = {
        id: id,
        invoiceNumber: this.usersFromImportFile[id].invoiceNumber,
        firstName: this.usersFromImportFile[id].firstName,
        lastName: this.usersFromImportFile[id].lastName,
        invoiceAmount: this.usersFromImportFile[id].invoiceAmount,
        paidAmount: this.usersFromImportFile[id].paidAmount,
        initalDate: this.usersFromImportFile[id].initalDate,
        dueDate : this.usersFromImportFile[id].dueDate,
        note : this.usersFromImportFile[id].note,
        contactMobile: this.usersFromImportFile[id].contactMobile,
        row: id
      };
      
      const dialogRef = this.dialog.open(EditimportinvoiceComponent, dialogConfig);
  
      dialogRef.afterClosed().subscribe(result => {        
        console.log("finish editing");
        console.log(result);
        if (result !== null)
        {
          this.usersFromImportFile[result.row].invoiceNumber = result.invoiceNumber;
          this.usersFromImportFile[result.row].invoiceAmount = result.invoiceAmount;
          this.usersFromImportFile[result.row].paidAmount = result.paidAmount;
          this.usersFromImportFile[result.row].initalDate = result.initalDate;
          this.usersFromImportFile[result.row].dueDate = result.dueDate;
          this.usersFromImportFile[result.row].firstName = result.firstName;
          this.usersFromImportFile[result.row].lastName = result.lastName;
          this.usersFromImportFile[result.row].note = result.note;
          this.usersFromImportFile[result.row].contactMobile = result.contactMobile;
        }

        //this.checkConflictImports(); 
      }); 
  }

  sortDefaultGreen(){
    for (let i = 0; i < this.usersFromImportFile.length; i++ )
    {
      this.usersFromImportFile[i].conflictImport = false;
    }

    for (let i = 0; i < this.invoices.length; i++ )
    {
      this.invoices[i].conflictImport = false;
    }
  }

  deleteImport(id){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "25%";
    dialogConfig.data = {
      id: id,
      deleteInstalment: false
    };
    
    const dialogRef = this.dialog.open(DeleteimportinvoiceComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.deleteImportAccount != null)
      {      
        this.usersFromImportFile.splice(result.id, 1);
        this.checkConflictImports(); 
      }
    });   
  }

  onSubmit(){
    this.usersFromImportFile[0].payExtra = this.onClickPayExtra;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "40%";
    dialogConfig.data = {
    };
    
    const dialogRef = this.dialog.open(ConfirmimportinvoicesComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm)
      {      
        this.service.createImportsInvoices(this.usersFromImportFile).subscribe(response => {
          this.messageConfirm();
        }, error => {
          this.validationErrors = true;      
          console.log(error);
        })
      }
    }); 
  }

  messageConfirm(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "25%";
    dialogConfig.data = {
    };
    
    const dialogRef = this.dialog.open(ConfirmimportsinvoicesComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm != null)
      {      
        this.router.navigate(['/members/clients']);
      }
    }); 
  }

  onChangePay(){
    this.onClickPayExtra = !this.onClickPayExtra;
    console.log(this.onClickPayExtra);
  }

}
