
<app-nav></app-nav>
<div class="container">
    <div class="row">
        <div class="col-12 mt-4">
            <h1 class="text-center text-primary mt-2 font-weight-bold">Invoice List</h1>
        </div>
        <div class="col-lg-12" *ngIf="showConflictEnable">
            <div class="bs-component">
                <table class="table table-hover">
                    <thead >
                    <tr class="table-primary">
                        <th scope="col">Invoice Number</th>
                        <th scope="col">Invoice Amount</th>
                        <th scope="col">Initial Date</th>
                        <th scope="col">Due Date</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                    </thead>
                    <tbody>

                    <tr class="table-danger" *ngFor="let invoice of invoices; let element = index;">                        
                        <ng-container *ngIf="invoice.conflictImport;">
                            <td>{{invoice.invoiceNumber}}</td>
                            <td>{{invoice.invoiceAmount | currency }}</td>
                            <td>{{invoice.initialDate | date }}</td>
                            <td>{{invoice.dueDate | date }}</td>
                            <td>{{invoice.firstName}}</td>
                            <td>{{invoice.lastName}}</td>
                        </ng-container>
                    </tr>      
                    </tbody>
                </table>
            </div>
        </div>
        <ng-container *ngIf="canSubmit===false">
            <div class="bs-component">
                <div class="alert alert-dismissible alert-danger">
                <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                <p><strong>Oh snap!</strong> {{errorText}}</p>
                </div>
            </div>
        </ng-container>
        <div class="col-lg-12">
            <div class="card mt-4 border-info">
                <div class="card-body">
                    <fieldset class="form-group">
                        <legend>Do you want customers to pay 1.75% + $0.30?</legend>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios1" value="option1" checked="" (change)="onChangeFees()">
                            Yes
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="radio" class="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2" (change)="onChangeFees()">
                            No
                          </label>
                        </div>
                      </fieldset>
                  <h2 class="text-center text-primary mt-2">Submit Import Data</h2>                         
                  <button class="btn btn-success btn-block" (click)="onSubmit()" [disabled]="canSubmit===false" >Submit Import List</button>
                  <small class="form-text">Submit your request. Please fill in the emails and phones so you can count on forwarding your payment request!</small>
                </div>
            </div>
        </div>
        <div class="col-12 mt-4">
            <h1 class="text-center text-primary mt-2 font-weight-bold">Invoice List</h1>
            <ng-container *ngIf="validationErrors">
                <div class="alert alert-dismissible alert-danger">
                    <strong>Error!</strong> {{errorText}}
                  </div>
            </ng-container>
        </div>
        <div class="col-12">
            <div class="bs-component">
                <table class="table table-hover">
                    <thead >
                    <tr class="table-primary">
                        <th scope="col">#</th>
                        <th scope="col" class="text-warning">Invoice Number</th>
                        <th scope="col" class="text-warning">Invoice Amount </th>
                        <th scope="col" class="text-warning">Payment Amount</th>
                        <th scope="col" class="text-warning">Initial Date</th>
                        <th scope="col" class="text-warning">Due Date</th>
                        <th scope="col" class="text-warning">First Name</th>
                        <th scope="col" class="text-warning">Last Name</th>
                        <th scope="col" class="text-warning">Mobile Contact</th>
                        <th scope="col">Note</th>
                        <th scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="" *ngFor="let import of usersFromImportFile; let element = index;">     
                        <ng-container *ngIf="import.conflictImport; else defaultImportTable">
                            <th scope="row" class="text-success">{{element + 1}}</th>
                            <th scope="row" class="text-danger">{{import.invoiceNumber}}</th>
                            <th scope="row" class="text-danger">{{import.invoiceAmount | currency }}</th>
                            <th scope="row" class="text-danger">{{import.paidAmount | currency}}</th>
                            <th scope="row" class="text-danger">{{import.initialDate | date }}</th>
                            <th scope="row" class="text-danger">{{import.dueDate | date }}</th>
                            <th scope="row" class="text-danger">{{import.firstName }}</th>
                            <th scope="row" class="text-danger">{{import.lastName  }}</th>
                            <th scope="row" class="text-danger">{{import.contactMobile  }}</th>
                            <th scope="row" class="text-danger">{{import.note  }}</th>
                            <td>
                                <button class="btn" class="btn btn-success" (click)="editImport(element)"><i class="fa fa-pencil"></i></button>   
                                <button class="btn" class="btn btn-danger mt-1 ms-1" (click)="deleteImport(element)"><i class="fa fa-trash"></i></button>    
                            </td>
                        </ng-container>
                        <ng-template #defaultImportTable>
                            <th scope="row" class="text-success">{{element + 1}}</th>
                            <th scope="row">{{import.invoiceNumber}}</th>
                            <th scope="row">{{import.invoiceAmount | currency }}</th>
                            <th scope="row">{{import.paidAmount | currency }}</th>
                            <th scope="row">{{import.initialDate | date }}</th>
                            <th scope="row">{{import.dueDate | date }}</th>
                            <th scope="row">{{import.firstName}}</th>
                            <th scope="row">{{import.lastName}}</th>
                            <th scope="row">{{import.contactMobile}}</th>
                            <th scope="row">{{import.note}}</th>
                            <td>
                                <button class="btn" class="btn btn-success" (click)="editImport(element)"><i class="fa fa-pencil"></i></button>   
                                <button class="btn" class="btn btn-danger ms-1" (click)="deleteImport(element)"><i class="fa fa-trash"></i></button>     
                            </td>
                        </ng-template>
                    </tr>      
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>