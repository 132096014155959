import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MessagesService } from 'src/app/_services/message/messages.service';

@Component({
  selector: 'app-changeinstalmentduedatesmsmessage',
  templateUrl: './changeinstalmentduedatesmsmessage.component.html',
  styleUrls: ['./changeinstalmentduedatesmsmessage.component.css']
})
export class ChangeinstalmentduedatesmsmessageComponent implements OnInit {

  public data: any;
  @ViewChild('editForm') editForm: NgForm; 
  formEdit: UntypedFormGroup;
  
  constructor(public fb: UntypedFormBuilder, private service: MessagesService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.initializeForm();
    this.onLoad();
  }

  initializeForm(){
    this.formEdit = this.fb.group({
      changeText:['', [ Validators.required, Validators.maxLength(2000) ]],
    });  
  }

  onSubmit(){
    const message = {
      dueDateInstalmentSMSMessage: this.formEdit.controls['changeText'].value
    }
    this.data.message = message.dueDateInstalmentSMSMessage;
    this.service.updateDueDateInstalmentSMSMessage(message).subscribe(response => {
      this.onLoadSettingMesaging();
    }, error => {
      console.log(error);
    }) 
  }

  onLoad(){
    this.service.getDueDateInstalmentSMS().subscribe(response => {
      this.data = response;
      this.formEdit.controls['changeText'].setValue(this.data.dueDateInstalmentSMSMessage);
      this.toastr.success("Updated Message!");
      console.log(response);
    }, error => { 
      console.log(error);
    })
  }

  onLoadSettingMesaging(){
    this.service.getTextSettingMessaging().subscribe(response => {
      this.service.message = response;
      if (this.service.message == null){
        this.service.setMessageValues();
      }
      console.log(response);
    }, error => {
      console.log(error);
    })
  }

  generateText(){
    this.formEdit.controls['changeText'].setValue("Dear [name],\r\n" +
    "I hope this message finds you well." +
    " This is a friendly reminder about the payment due today for Invoice #[invoicenumber], amounting to [dueamount].\r\n" +
    "We appreciate the payments you have made, totaling [amountpaid]." +
    "To ensure a smooth transaction, please process the payment by close of business today to avoid late fees.\r\n" +
    "Invoice Details:\r\n" +
    "Invoice Number: [invoicenumber]\r\nAmount Due:" +
    " [dueamount]\r\n" +
    "Due Date: [duedate]\r\n" +
    "Payment Instructions: To make a payment, please visit the Invoice Pager website below, and enter the following code: [code] \r\n [link]" +
    "\r\nIf you've already settled this invoice, we appreciate your prompt response, and you can disregard this reminder.\r\n" +
    "For any questions or concerns, please feel free to reply to this email [mybusinessemail].\r\n" +
    "Thank you for your cooperation.\r\n" +
    "Best regards,\r\n" +
    "[mybusiness]\r\n" +
    "[mybusinessphone]");
    this.formEdit.markAsDirty();
  }

}
