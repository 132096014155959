<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to go to the instalments page</h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Instalments/Go to Instalments/gti01.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Go to the Clients “Invoices” page </h2>
    <h2>•	Scroll down to the “List of Invoices”, and click the three (3) dots beside the relevant invoice you want to open instalments under </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Instalments/Go to Instalments/gti02.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Under the menu, click “Open Instalment Accounts”</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Instalments/Go to Instalments/gti03.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	You will now be on the “Instalments” page for that Client, and the chosen invoice number </h2>
</div>
