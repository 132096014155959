import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UserGroupsPermissions } from 'src/app/_models/usergrouppermissions';
import { UsergroupspermissionsService } from 'src/app/_services/usergroups/usergroupspermissions.service';
import { DeleteusergroupsComponent } from '../deleteusergroups/deleteusergroups.component';

@Component({
  selector: 'app-listusergroups',
  templateUrl: './listusergroups.component.html',
  styleUrls: ['./listusergroups.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class ListusergroupsComponent implements OnInit {
  columnsToDisplay = ['name', 'refundstripe', 'updatestatusstrip', 'canusemessage','useimport', 'useapi', 'isadmin'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  constructor(public services: UsergroupspermissionsService, private dialog: MatDialog) { }

  ngOnInit(): void {

  }

  deleteAccount(id: any){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "75vw";
    dialogConfig.data = {
      id: id,
      deleteAccount: false
    };

    const dialogRef = this.dialog.open(DeleteusergroupsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

    });
  } 

}