<div class="bs-component mt-4">
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary rounded ">
    <div class="container-fluid">
      <a class="navbar-brand"><i class="fas fa-search fa-lg"></i></a>
      <a class="navbar-brand text-white font-weight-bold" > Search Features:</a>    
      <div class="form-group mt-3 ms-4">
        <select class="form-select" (change)="onChangeArchive($event)">
          <option value="1">Search all account payments non-archive</option>
          <option value="2">Search all account payments with archive</option>
        </select>
      </div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarColor01">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
          
          </li>
        </ul>
        <a class="navbar-brand text-white font-weight-bold" > </a> 
        <form class="d-flex">
          
        </form>
      </div>
    </div>
  </nav>
</div>
<div class="example-container mat-elevation-z8" *ngIf="(paymentService.payments$ | async) !== null">
    <table mat-table [dataSource]="paymentService.paymentsDataSource">
        
      <!-- Iteration Column -->
      <ng-container matColumnDef="Index">
        <th mat-header-cell *matHeaderCellDef> List No. </th>
        <td mat-cell *matCellDef="let account; let i = index ;"> {{i + 1}}. </td>
        <td mat-footer-cell *matFooterCellDef> Total</td>
      </ng-container>  
      <!-- Payment Method Column -->
      <ng-container matColumnDef="PaymentMethod">
        <th mat-header-cell *matHeaderCellDef> Item Payment Method </th>
        <td mat-cell *matCellDef="let account"> 
            <p *ngIf="account.paymentOption === 2"><span class="badge bg-primary fs-6">Store Credits</span></p>
            <p *ngIf="account.paymentOption === 1"><span class="badge bg-danger fs-6">Account Refunded</span></p>
            <p *ngIf="account.paymentOption === 0"><span class="badge bg-success fs-6">Account Paid</span></p>
        </td>
        <td mat-footer-cell *matFooterCellDef>  </td>
      </ng-container>
  
      <!-- Amount Column -->
      <ng-container matColumnDef="Amount">
        <th mat-header-cell *matHeaderCellDef> Amount </th>
        <td mat-cell *matCellDef="let account">
             <p>{{account.amount | currency}}</p> 
        </td>
        <td mat-footer-cell *matFooterCellDef> {{totalCost | currency}} </td>
      </ng-container>

     <!-- Date Column -->
     <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef> Date </th>
        <td mat-cell *matCellDef="let account"> <p>{{account.date | date: 'mediumDate'  }}</p> </td>
        <td mat-footer-cell *matFooterCellDef>  </td>
      </ng-container>

    <!-- Item Column -->
    <ng-container matColumnDef="PaymentType">
        <th mat-header-cell *matHeaderCellDef> Payment Type </th>
        <td mat-cell *matCellDef="let account"> <p><span class="badge bg-dark fs-6 text-light">{{account.paymentType}}</span></p></td>
        <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>


    <!-- Note Column -->
    <ng-container matColumnDef="Note">
        <th mat-header-cell *matHeaderCellDef> Note </th>
        <td mat-cell *matCellDef="let account"> {{account.note}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    
    <!-- Actions Column -->
    <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let account">
          <button mat-icon-button color="primary" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item  (click)="editAccount(account.id, account.clientID)">
              <mat-icon color="primary">account_box</mat-icon>
              <span>Edit Payment Account Profile</span>
            </button>
            <button mat-menu-item  (click)="archiveAccount(account.id, account.clientID, account.archive)">
              <mat-icon color="warn">delete</mat-icon>
              <span>Archive Payment Account</span>
            </button>
            <button mat-menu-item  (click)="deleteAccount(account.id, account.clientID)">
              <mat-icon color="warn">delete</mat-icon>
              <span>Delete Payment Account</span>
            </button>
          </mat-menu>   
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>
  
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
    </table>
  </div>
  <div class="card border-info mb-3 mt-4" id="borderinfo" >
    <div class="card-body">
      <p class="card-text text-center"><b>Default Paid Amount <i>(Is the default paid amount when the invoice was created)</i> :</b> <b class="text-success"> {{paymentService.defaultInvoicePayment$ | currency }} </b></p>                                  
      <p class="card-text text-center"><b>Total Paid Amount:</b> <b class="text-success"> {{paymentService.defaultInvoicePayment$ + paymentService.totalPayments$ | currency }} </b></p>                                  
    </div>
  </div>
  <div class="mt-4"></div>