<app-nav></app-nav>

<div id="carouselExampleDark" class="carousel carousel shadow-lg" style="height: 45vh;" data-bs-ride="carousel" *ngIf="(accountService.currentUser$ | async) === null && !registerMode">
    <div class="carousel-inner">
      <div class="carousel-item active" data-bs-interval="100000">
        <video class="img-fluid" autoplay loop muted>
          <source src="/assets/videos/mainvideo.mp4" type="video/mp4" />
        </video> 
        <div class="carousel-caption text-white ">
          <h1 class="text-uppercase font-weight-bold d-block d-sm-none">PAY VIA SMS</h1>
          <h1 class="text-uppercase font-weight-bold d-none d-sm-block d-lg-none">Billing company</h1>
          <h1 class="text-uppercase font-weight-bold font-weight-bold d-none d-xl-block">We're a billing company</h1>
          <p class="font-weight-bold mt-4 fs-3 d-none d-xl-block">We dispatch payment reminders through both SMS and Email</p>
          <button class="btn btn-success px-4 fs-5 mt-5 text-uppercase btn-lg font-weight-bold mb-4" (click)="registerTogger()"><i class='fas fa-registered'></i> Register Account Now!</button>
        </div>
      </div>
      <div class="carousel-item" data-bs-interval="100000">
        <video class="img-fluid" autoplay loop muted>
          <source src="/assets/videos/thirdvideo.mov" type="video/mp4" />
        </video>
        <div class="carousel-caption d-none d-md-block text-white">
          <h1 class="text-uppercase">Forward Payment & Pay Later</h1>
          <p>Set a structure for your clients to pay later, or pay it forward</p>
          <button class="btn btn-success px-4 fs-5 mt-5 text-uppercase btn-lg font-weight-bold mb-4" (click)="registerTogger()"><i class='fas fa-registered'></i> Register Account Now!</button>
        </div>
      </div>
    </div>
</div>
<div class="container mt-5 mainhome" *ngIf="(accountService.currentUser$ | async) === null">    
    <div style="text-align: center;" *ngIf="!registerMode" >
        <app-features></app-features>
        <div class="row">
          <div class="col-sm-12 col-md-3 py-5"></div>
          <div class="col-sm-12 col-md-6 py-5">
              <div class="card-body">
                  <h1 class="text-center text-primary mt-2 font-weight-bold">Register Account</h1>
                  <img src="/assets/images/register.jpg" class="img-thumbnail">                
                  <p class="lead">It's free to join ... Join today, just click on register to create an account! </p>
                  <div class="text-center">
                      <button (click)="registerTogger()" class="btn btn-outline-success btn-lg mr-4"><i class='fas fa-registered'></i> Register</button>
                      <button class="btn btn-outline-info btn-lg mr-2" routerLink="/features" ><i class='fas fa-info'></i> Learn more</button>
                  </div>
              </div>
          </div>
          <div class="col-sm-12 col-md-3 py-5"></div>
      </div>
    </div>
    <div *ngIf="registerMode">
        <div class="row justify-content-center">
            <div class="col-sm-12 col-md-4  py-5 bg-white text-black text-center border shadow-lg">
                <div class=" ">
                    <div class="card-body">
                        <img src="/assets/images/edit.jpg" class="img-thumbnail">
                        <h2 class="py-3">Registration</h2>
                        <p>
                           Join us today, and you will get to find out more about our SaSS Product.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-8 mt-2">
                <div class="card border mb-3 shadow-lg">
                    <div class="card-body">
                        <app-register (cancelRegister)="cancelRegisterModel($event)"></app-register>
                    </div>
                  </div>              
            </div>
        </div>
    </div>
</div>
<div *ngIf="(accountService.currentUser$ | async) !== null">   
   
    <app-homeuser></app-homeuser>
</div>

