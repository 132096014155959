import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { accountpayment } from 'src/app/_models/accountpayment';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { ClientaccountpaymentsService } from 'src/app/_services/clientaccountpayments/clientaccountpayments.service';
import { PaymentService } from 'src/app/_services/payment/payment.service';
import { ReceivableaccountspaymentsService } from 'src/app/_services/receivablesaccountspayments/receivableaccountspayments.service';
import { ClientlistaccountreceivableComponent } from '../clientlistaccountreceivable/clientlistaccountreceivable.component';

@Component({
  selector: 'app-archiveaccountclientreceivable',
  templateUrl: './archiveaccountclientreceivable.component.html',
  styleUrls: ['./archiveaccountclientreceivable.component.css']
})
export class ArchiveaccountclientreceivableComponent implements OnInit {
  accountID: number;
  invoiceID: number;
  clientID: number;
  archive: boolean = false;
  user?: User;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ClientlistaccountreceivableComponent>,
    private paymentService: PaymentService, private accountService: AccountService,
    private receivableaccountspaymentsService: ReceivableaccountspaymentsService, private toastr: ToastrService,
    private clientAccountPaymentService: ClientaccountpaymentsService) {}

    ngOnInit(): void {
      this.invoiceID = this.data.invoiceID;
      this.accountID = this.data.id;
      this.clientID = this.data.clientID;
      this.archive = this.data.archive;
      this.assignUser();
    }
  
    archiveAccount(type: boolean){
      const account = {
        id: this.accountID,
        archive: type
      };

      if (type === this.paymentService.showarchive)
      {
        this.toastr.error("Your account already has the same type!");
        return;
      }
      this.paymentService.ArchiveAccountPayment(account).subscribe(response => {
        if (response)
        {
          this.toastr.success("Account has been updated!");
          this.paymentService.paymentsDataSource = new MatTableDataSource<accountpayment>();
          this.paymentService.showarchive = type; 
          this.SetHubConnection();
        }    
      }, error => {
        console.log(error);
      });
    }

    private SetHubConnection()
    {
        // Get payments //
        this.paymentService.stopHubConnection();
        this.paymentService.stopHubArchiveConnection();
        this.paymentService.createHubConnection(this.user, +this.accountID);
        this.paymentService.createHubArchiveConnection(this.user, +this.accountID);

        // Get All Payments //
        this.receivableaccountspaymentsService.stopHubConnection();
        this.receivableaccountspaymentsService.createHubConnection(this.user, true);
  
        // Get Single Payments //
        this.clientAccountPaymentService.stopHubConnection();
        this.clientAccountPaymentService.createHubConnection(this.user, this.clientID, true );
    }
  
    assignUser(){
      this.accountService.currentUser$.pipe(take(1)).subscribe({
        next: user => {
          if (user) this.user = user;
        }
      });
    }

}
