<mat-dialog-content>
<div class="container" *ngIf="data">
    <div class="row">        
        <div class="col-12">            
            <form #editForm="ngForm" id="editForm" [formGroup]="formEdit" (ngSubmit)="formEdit.valid" autocomplete="off">
                <h2 class="text-center"><b>Time Zone Selected:</b> {{timeZone}}</h2> 
                <div class="form-group">
                    <label for="exampleFormControlSelect1">SELECT TIME ZONE:</label>
                    <select class="form-control" formControlName="changeText">
                      <option value="" selected disabled hidden>Choose here</option>
                      <option value="0">Australia Sydney Time</option>                                       
                    </select>
                  </div>  
            </form>   
        </div>              
        <div class="col-6">
            <button [disabled]='!formEdit.valid || !formEdit.dirty' class="btn btn-success btn-block" mat-button [mat-dialog-close]="data" (click)="onSubmit()">Confirm Changes</button>
        </div>
        <div class="col-6">
            <button class="btn btn-danger btn-block" mat-button mat-dialog-close>Cancel</button>
        </div>
    </div>
</div>
</mat-dialog-content>