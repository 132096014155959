import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { stringify } from 'querystring';
import { take } from 'rxjs/operators';
import { iStripeAccount } from 'src/app/_models/istripeaccount';
import { Pagination } from 'src/app/_models/pagination';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { HostpresenceService } from 'src/app/_services/hubsignalr/hostpresence.service';
import { StripeaccountService } from 'src/app/_services/stripeaccount/stripeaccount.service';
import { RefundmessagestripeclientComponent } from '../refundmessagestripeclient/refundmessagestripeclient.component';
import { TogglewindowService } from 'src/app/_services/togglewindow.service';


@Component({
  selector: 'app-stripeaccountslist',
  templateUrl: './stripeaccountslist.component.html',
  styleUrls: ['./stripeaccountslist.component.css'],
})
export class StripeaccountslistComponent implements AfterViewInit, OnDestroy {
  displayedColumns: string[] = ['invoiceNumber', 'name', 'firstName', 'lastName', 'Fees', 'amountPaid', 'amountRefunded', 'datePaid', 'ReadThisTransaction','Status', 'actions'];
  currentDate = new Date();
  timeLeft: number = 3;
  timeRefleshPage: number = 30;
  interval;
  user?: User;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(public services: StripeaccountService, private router: Router, private dialog: MatDialog, 
    public accountService: AccountService, private toastr: ToastrService, private presenceHub: HostpresenceService, public toggle: TogglewindowService) {   
   }

  ngOnDestroy(): void {
    this.presenceHub.pageActive[8] = false;
    this.services.stopHubConnection();
    this.services.stopHubNotificationNumberConnection();
    this.services.stopUpdateStripeAccountsHubConnection();
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
    this.presenceHub.pageActive[8] = true;
    this.assignUser();
    this.loadMembers();
    this.startTimer();
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  refundAccount(id: string){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "25%";
    dialogConfig.data = {
      id: id,
      refundAccount: false,
      amountRefunded: 50,
    };
    
    this.services.getCheckCanUserUseRefund().subscribe(response => {
      if (response === false)
      {
        const dialogRef = this.dialog.open(RefundmessagestripeclientComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          if (result.refundAccount)
          {
            this.services.stopHubConnection();           
            this.loadMembers();
          }
        });
        return;
      }      
      this.toastr.error('You dont permission to refund Stripe accounts!');
    }, err => {
      console.log(err);
    })

  }

  loadMembers(){
    this.services.stopHubConnection();
    this.services.createHubConnection(this.user, false);
  }

  loadInvoicePage(id: string){
    this.services.getCheckCanGoToInvoiceOnStripe().subscribe(response => {
      if (response === false)
      {
        this.router.navigate(['members/invoices/' + id ]);
        return;
      }
      this.toastr.error("You don't have permission to load invoice accounts!");
    }, err => {console.log(err);})
   
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.services.stripeDataSource$.filter = filterValue.trim().toLowerCase();

    if (this.services.stripeDataSource$.paginator) {
      this.services.stripeDataSource$.paginator.firstPage();
    }
  }

  getDateStatus(){
    for (let i = 0; i < this.services.stripeDataSource$.filteredData.length; i++)
    {
      const currentDate = new Date(this.services.stripeDataSource$.filteredData[i].datePaid);  
      currentDate.setDate(currentDate.getDate() + 3);
      this.services.stripeDataSource$.filteredData[i].dateStatus = currentDate;
    }
  }

  showAlltransactions(){
    this.services.showalltransactionsbtn = !this.services.showalltransactionsbtn;
    this.toastr.info("Please wait! We are getting your records now!");
    if (this.services.showalltransactionsbtn)
    {
      this.services.isitShowAllTransactionPage = false;
      this.services.namebtnshowtransactions = "Show Only Default Transactions"
    }
    else
    {
      this.services.isitShowAllTransactionPage = true;
      this.services.namebtnshowtransactions = "Show All History Transactions"
    }
  }

  ButtonUpdateStatusNotification(index: number){
    const account = {
      accountID: index,
    };
    
    this.services.getCheckCanUpdateStatusOnStripe().subscribe(response => {
      if (response === false)
      {
          this.services.updateStatusTransaction(account).subscribe(res => {
          this.toastr.success("Updated Account Successfully!");
          this.services.stopUpdateStripeAccountsHubConnection();
          this.services.stopUpdateStripeAccountsHubConnection();
          this.services.stopHubConnection();
          this.services.stopHubConnection();
          this.services.createHubUpdateStripeAccountConnection(this.user);           
        }, err => {
          console.log(err);
        })
        return;
      }
      this.toastr.error("You don't have access to update status on Stripe Accounts!");
    }, err => {console.log(err);})
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
 
        this.timeLeft = 3;
        this.presenceHub.pageActive[8] = true;
        if (this.services.stripeDataSource$ && this.services.resetTimerStripeTable === true)
        {
          this.services.resetTimerStripeTable = false;
          this.services.stripeDataSource$.paginator = this.paginator;
          this.services.stripeDataSource$.sort = this.sort;
          this.getDateStatus();
        }          
      }
    },1000)
  }

  updateTransactions(){
    this.services.updateAllTransactions().subscribe(response => {
      if (response)
      {
        this.toastr.success("All Stripe transactions accounts has been updated!")
        this.services.stopUpdateStripeAccountsHubConnection();
        this.services.stopUpdateStripeAccountsHubConnection();
        this.services.stopUpdateStripeAccountsHubConnection();
        this.services.stopUpdateStripeAccountsHubConnection();
        this.services.stopUpdateStripeAccountsHubConnection();
        this.services.stopHubConnection();
        this.services.stopHubConnection();
        this.services.stopHubConnection();
        this.services.stopHubConnection();
        this.services.stopHubConnection();
        this.services.createHubUpdateStripeAccountConnection(this.user); 
      }         
      else
        this.toastr.info("No need for updates!");;
    });
  }
}
