<div class="col-lg-12 col-md-12 col-sm-12">
<h2 class="text-center">How to delete a client account</h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Client/Images/Delete Client/dc01.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Under the “Clients List” section, Click the drop down arrow beside the Client that you wish to delete</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Client/Images/Delete Client/dc02.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Under the menu, click “Delete Client Account”</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Client/Images/Delete Client/dc03.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	If you wish to delete the “Client Account”, click “Delete”</h2>
    <h2>•	If you do not wish to delete the “Client”, click “Cancel”</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Client/Images/Delete Client/dc04.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	You have now deleted the “Client Account” and are back on the “Clients” page</h2>
</div>