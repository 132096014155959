import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Client } from 'src/app/_models/client';
import { ClientService } from 'src/app/_services/client/client.service';
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { EditclientComponent } from '../editclient/editclient.component';
import { DeleteclientComponent } from '../deleteclient/deleteclient.component';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ToggledefinitionService } from 'src/app/_services/toggledefinition.service';
import { AccountService } from 'src/app/_services/account/account.service';
import { ToastrService } from 'ngx-toastr';
import { HostpresenceService } from 'src/app/_services/hubsignalr/hostpresence.service';
import { User } from 'src/app/_models/user';
import { take } from 'rxjs/operators';
import { factor } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ArchiveclientComponent } from '../archiveclient/archiveclient.component';


@Component({
  selector: 'app-clientlist',
  templateUrl: './clientlist.component.html',
  styleUrls: ['./clientlist.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ClientlistComponent implements AfterViewInit, OnDestroy {
  displayedColumns: string[] = ['name', 'address', 'email','contactMobile', 'stripeConnected'];
  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  timeLeft: number = 1;
  interval;
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  user?: User;


  constructor(public clientService: ClientService, private dialog: MatDialog, public legends: ToggledefinitionService, 
    public accountService: AccountService, private toastr: ToastrService, private presenceHub: HostpresenceService, private router: Router) {
      this.assignUser();
  }

  ngOnDestroy(): void {
    this.clientService.stopHubConnection();
    this.clientService.stopArchiveHubConnection();
    this.presenceHub.pageActive[0] = false;
    this.clientService.showNonArchive$ = false;
    this.clientService.clientsDataSource = new MatTableDataSource<Client>(); 
    clearInterval(this.interval);
  }
    
  ngAfterViewInit(): void {
    this.presenceHub.pageActive[0] = true;
    this.clientService.showNonArchive$ = true;
    this.toastr.info("Please wait! We are getting your records now!");
    this.loadMembers();
  }


  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.clientService.clientsDataSource.filter = filterValue.trim().toLowerCase();

    if (this.clientService.clientsDataSource.paginator) {
      this.clientService.clientsDataSource.paginator.firstPage();
    }
  }

  loadMembers(){
    if (this.user)
    {
      this.clientService.stopHubConnection();
      this.clientService.createHubConnection(this.user);    
      this.startTimer();   
    }
    else
    {
      this.stopConnection();     
    }
  }

  editClient(id: any){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "90vw";
    dialogConfig.height = "auto";
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      id: id
    };

    this.clientService.getCheckCanEditClient(id).subscribe(response => {
      if (!response)
      {
        const dialogRef = this.dialog.open(EditclientComponent, dialogConfig);
        this.toastr.info("Active Account: Opened Client Account!");
        dialogRef.afterClosed().subscribe(result => {
          const index = this.clientService.clientsDataSource.data.findIndex(x => x.id === result.id); 
          //this.clientService.clientsDataSource.filteredData[index] = result;
          //this.clientService.clientsDataSource._updateChangeSubscription();
          this.clientService.cancelCanEditClient(id).subscribe(response => {
           if (response)
           {
              this.toastr.info("Active Account Closed!");
           } 
          }, err => {
            console.log(err);
          })
        }); 
      }
      else
      {
        this.toastr.error("Failed! Another user is actively using this account.");
      }
    }, err => {
      console.log(err);
    })  
  }

  deleteClient(id: any){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "75vw";
    dialogConfig.height = "auto";
    dialogConfig.data = {
      id: id,
      deleteClient: false
    };


    this.clientService.getCheckCanDeleteClient(id).subscribe(response => {
      if (!response)
      {
        const dialogRef = this.dialog.open(DeleteclientComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          if (result.deleteClient != null){
            if (result.deleteClient)
            {
              this.toastr.success("Your client account has been deleted!");
            }
            else
            {
              this.cancelCanEditClient(id, true);
            }
          }
          else
          {
            this.cancelCanEditClient(id, false);
          }
        })
    }
    else
    {
      this.toastr.error("Failed! Another user is actively using this account.");
    }

   }, err => {console.log(err)});
  } 

  archiveClient(id: any, archive: boolean){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "75vw";
    dialogConfig.height = "auto";
    dialogConfig.data = {
      id: id,
      deleteClient: false,
      archive: archive
    };


    this.clientService.getCheckCanArchiveClient(id).subscribe(response => {
      if (!response)
      {
        const dialogRef = this.dialog.open(ArchiveclientComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          this.cancelCanEditClient(id, false);
          if (result.deleteClient != null)
          {          
            if (result.deleteClient)
            {
              this.toastr.success("Your client account has been archived!");
            }
          }
        })
    }
    else
    {
      this.toastr.error("Failed! Another user is actively using this account.");
    }

   }, err => {console.log(err)});
  } 


  cancelCanEditClient(id: number, showMessage: boolean){
    this.clientService.cancelCanEditClient(id).subscribe(response => {
      if (showMessage)
      {
        return;
      }
      
      if (response)
      {
         this.toastr.info("Active Account Closed!");
      } 
     }, err => {
       console.log(err);
     })
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.clientService.clientsDataSource.paginator = this.paginator;
        this.clientService.clientsDataSource.sort = this.sort;
        this.timeLeft = 10;
        this.presenceHub.pageActive[0] = true;
      }
    },1000)
  }

  onChangeArchive(status){
    if (status.target.value === "1")
    {
      this.OnChangeConnection(true);
      this.clientService.createHubConnection(this.user);
    }
    else
    {
      this.OnChangeConnection(false);
      this.clientService.createArchiveHubConnection(this.user);
    }
  }

  openInvoices(id: number){
    this.clientService.getCheckOpenInvoiceAccount(id).subscribe(response => {
      if (response)
        this.router.navigate(['/members/invoices', id]);
      else
        this.toastr.error("You don't have permissions to open invoice accounts!");
    }, err => {
      console.log(err);
    })  
  }

  openStoreCredit(id: number){
    this.clientService.getCheckOpenStoreAccount(id).subscribe(response => {
      if (response)
        this.router.navigate(['/members/createstorecredit', id]);
      else
        this.toastr.error("You don't have permissions to enter into Store Accounts!");
      
    }, err => {
      console.log(err);
    })  
  }

  openPayaccountAccountsForOneClient(id: number){
    this.clientService.getCheckAccountPaymentsForSingleClientAccount(id).subscribe(response => {
      if (response)
        this.router.navigate(['/members/clientaccountpayments', id]);
      else
        this.toastr.error("You don't have permissions to enter into Account Payments!");
      
    }, err => {
      console.log(err);
    })  
  }

  private OnChangeConnection(showArchive: boolean)
  {
    this.clientService.showNonArchive$ = showArchive;
    this.clientService.clientsDataSource = new MatTableDataSource<Client>();
    this.clientService.stopHubConnection();
    this.clientService.stopArchiveHubConnection();
  }

  private stopConnection(){
    console.log('stop connection!');
    this.clientService.stopHubConnection();
    this.clientService.stopArchiveHubConnection();
    this.toastr.error("Error with asynchronization!");     
  }
}
