<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to create a new account payment</h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/Create Account Payments/cap01.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Go to the client’s “Account Payments” page, and scroll to the “Create Account Payment” section. </h2>
    <h2>•	Fill the form as needed </h2>
    <h2>•	Select the “Payment Type" and “Amount” – these are mandatory to fill </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/Create Account Payments/cap02.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	You will also need to select an option for “Account Type” and “Date” – these are also mandatory</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/Create Account Payments/cap03.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Please note that for “Payment Type” you will need to choose an item from the list as necessary</h2>
    <h2>•	The “Cash” payment type will be selected by default, and you can change this by clicking on it</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/Create Account Payments/cap04.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Click “Create Payment Account” to save the changes</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/Create Account Payments/cap05.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	The new “Payment Account” will now show in the “Payment List” below</h2>
</div>