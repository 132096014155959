import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientSubscriptionService } from 'src/app/_services/clientsubscription/client-subscription.service';

@Component({
  selector: 'app-clientsubscriptionsuccess',
  templateUrl: './clientsubscriptionsuccess.component.html',
  styleUrls: ['./clientsubscriptionsuccess.component.css']
})
export class ClientsubscriptionsuccessComponent implements OnInit {
  productID: string;
  status: string = "Processing";
  productDescription: string = " Your payment is being process!";
  statusAlert: string = "info";

  constructor(private services: ClientSubscriptionService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.status = "Processing";
    this.productDescription = "Your payment is being process!";
    this.statusAlert = "info";
    this.getParrams();
    this.getProduct();
  }

  getProduct(){
    this.services.getPurchaseCheckProduct(this.productID).subscribe(response => {
      if (response)
      {
        this.setPaymentStatus("Success! ", "Your payment was successful!", "success")
      }
      else
      {
        this.setPaymentStatus("Warning! ", "Your payment has been created already!", "warning")
      }
    }, error => {
      console.log(error);
      this.setPaymentStatus("Failed! ", "Something went wrong with the client connection & the server connection. Please contact us now so we can verify your payment!", "danger");
    });
  }

  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.productID = params.get("id");
    });
  }

  setPaymentStatus(status: string, productdescription: string, statusalert: string){
    this.status = status;
    this.productDescription = productdescription;
    this.statusAlert = statusalert;
  }

}
