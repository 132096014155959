import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homefeatures',
  templateUrl: './homefeatures.component.html',
  styleUrls: ['./homefeatures.component.css']
})
export class HomefeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
