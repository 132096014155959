
<app-nav></app-nav>
<div class="container">
  <div class="row">
      <div class="col-12 mt-4">
        <nav class="navbar navbar-light bg-primary shadow-lg mt-4">
            <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-user fa-2x" aria-hidden="true"></i> Import List</a>
        </nav>
      </div>
      <div class="col-lg-12 mt-4" *ngIf="conflictClientsEnable">
          <div class="bs-component">
              <table class="table table-hover">
                  <thead >
                  <tr class="table-primary">
                      <th scope="col">First Name</th>
                      <th scope="col">Middle Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Full Address</th>
                      <th scope="col">Bussiness Name</th>
                      <th scope="col">Contact BN</th>
                      <th scope="col">Contact HP</th>
                      <th scope="col">Mobile #</th>
                      <th scope="col">Email</th>
                  </tr>
                  </thead>
                  <tbody>

                  <tr class="table-danger" *ngFor="let client of clients; let element = index;">                        
                      <ng-container *ngIf="client.conflictImport;">
                          <td>{{client.firstName}}</td>
                          <td>{{client.middleName}}</td>
                          <td>{{client.lastName}}</td>
                          <td>{{client.address}}</td>
                          <td>{{client.bussinessName}}</td>
                          <td>{{client.contactBusinessNumber}}</td>
                          <td>{{client.contactHomePhone}}</td>
                          <td>{{client.contactMobile}}</td>
                          <td>{{client.email}}</td>
                      </ng-container>
                  </tr>      
                  </tbody>
              </table>
          </div>
      </div>
      <div class="col-lg-12">
          <div class="card mt-4 border-info">
              <div class="card-body">     
                <h2 class="text-center text-primary mt-2">Submit Import Data</h2>      
                <ng-container *ngIf="errorID">
                    <div class="alert alert-dismissible alert-danger">
                        <strong>Error!</strong> {{errorMessage}}. Please assume that each record is different.
                      </div>
                </ng-container>    
                <button class="btn btn-success btn-block" (click)="onSubmit()" [disabled]="enableSubmit===false">Submit Import List</button>
                <small class="form-text">Submit your request. Please fill in the emails and phones so you can count on forwarding your payment request!</small>
              </div>
          </div>
      </div>
      <div class="col-12 mt-4">
          <hr>
        <div class="card mt-4">
            <div class="card-body">
                <h2>Help</h2>
                <div class="bs-component">
                  <span class="badge badge-pill badge-info fs-6 mt-4 text-center"><i class="fa fa-info"></i> <b> Contact BN (Bussiness Name)</b></span>
                  <span class="badge badge-pill badge-info fs-6 ms-2 text-center"><i class="fa fa-info"></i> <b> Contact HP (Home Phone)</b></span> 
                </div>
            </div>
        </div>
       <div>

           
        </div>
        <nav class="navbar navbar-light bg-primary shadow-lg mt-4">
            <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-user fa-2x" aria-hidden="true"></i> Import List</a>
        </nav>
          <ng-container *ngIf="validationErrors">
              <div class="alert alert-dismissible alert-danger">
                  <strong>Error!</strong> Please make sure you have at least 10 digits on your contact mobile number, first name, and last name for each record! 
                  Check the areas mark with the colour orange in the column name.
                </div>
          </ng-container>
      </div>
      <div class="col-12">
   
          <div class="bs-component mt-4">
              <table class="table table-hover">
                  <thead >
                  <tr class="table-primary">
                      <th scope="col">#</th>
                      <th scope="col" class="text-warning">First Name</th>
                      <th scope="col">Middle Name</th>
                      <th scope="col" class="text-warning">Last Name</th>
                      <th scope="col">Full Address</th>
                      <th scope="col">Bussiness Name</th>
                      <th scope="col">Contact BN</th>
                      <th scope="col">Contact HP</th>
                      <th scope="col" class="text-warning">Mobile #</th>
                      <th scope="col">Email</th>
                      <th scope="col">ABN</th>
                      <th scope="col">Actions</th>
                  </tr>
                  </thead>
                  <tbody>

                  <tr class="" *ngFor="let import of usersFromImportFile; let element = index;">     
                      <ng-container *ngIf="import.conflictImport; else defaultImportTable">
                          <th scope="row" class="text-danger">{{element + 1}}</th>
                          <th scope="row" class="text-danger">{{import.firstName}}</th>
                          <th scope="row" class="text-danger">{{import.middleName}}</th>
                          <th scope="row" class="text-danger">{{import.lastName}}</th>
                          <th scope="row" class="text-danger">{{import.address}}</th>
                          <th scope="row" class="text-danger">{{import.bussinessName}}</th>
                          <th scope="row" class="text-danger">{{import.contactBusinessNumber}}</th>
                          <th scope="row" class="text-danger">{{import.contactHomePhone}}</th>
                          <th scope="row" class="text-danger">{{import.contactMobile}}</th>
                          <th scope="row" class="text-danger">{{import.email}}</th>
                          <th scope="row" class="text-danger">{{import.abn}}</th>
                          <td>
                              <button class="btn" class="btn btn-success" (click)="editImport(element)"><i class="fa fa-pencil"></i></button>   
                              <button class="btn" class="btn btn-danger mt-1 ms-1" (click)="deleteImport(element)"><i class="fa fa-trash"></i></button>    
                          </td>
                      </ng-container>
                      <ng-template #defaultImportTable>
                          <th scope="row">{{element + 1}}</th>
                          <th scope="row">{{import.firstName}}</th>
                          <th scope="row">{{import.middleName}}</th>
                          <th scope="row">{{import.lastName}}</th>
                          <th scope="row">{{import.address}}</th>
                          <th scope="row">{{import.bussinessName}}</th>
                          <th scope="row">{{import.contactBusinessNumber}}</th>
                          <th scope="row">{{import.contactHomePhone}}</th>
                          <th scope="row">{{import.contactMobile}}</th>
                          <th scope="row">{{import.email}}</th>
                          <th scope="row">{{import.abn}}</th>
                          <td>
                            <button mat-icon-button color="primary" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>  
                            <mat-menu #menu="matMenu">                            
                                <button mat-menu-item  (click)="editImport(element)">
                                  <mat-icon color="primary">account_box</mat-icon>
                                  <span>Edit Client Profile</span>
                                </button>
                                <button mat-menu-item  (click)="deleteImport(element)">
                                  <mat-icon color="warn">delete</mat-icon>
                                  <span>Delete Client</span>
                                </button>
                              </mat-menu> 
                          </td>
                      </ng-template>
                  </tr>      
                  </tbody>
              </table>
          </div>
      </div>
  </div>
</div>