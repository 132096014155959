<nav class="navbar navbar-light bg-primary shadow-lg">
    <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Edited Client Accounts</a>
  </nav>
<hr>
<form #editForm="ngForm" id="editForm" [formGroup]="formClient" (ngSubmit)="formClient.valid" autocomplete="off">
<div class="bs-component">
    <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
    <button type="button" class="close" data-dismiss="alert">x</button>
    <h4 class="alert-heading">Information!</h4>
    <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
    </div>
</div>
<div class="row">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
        <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> Personal Details:</h4>
        <app-text-input [formControl]='formClient.controls["firstname"]' [label]='"*First Name"' [smalltext]='""' [placeholder]='"First Name"'> </app-text-input>
        <app-text-input [formControl]='formClient.controls["lastname"]' [label]='"*Last Name"' [smalltext]='""' [placeholder]='"Last Name"'> </app-text-input>
        <app-text-input [formControl]='formClient.controls["email"]' [label]='"*Email"' [smalltext]='""' [placeholder]='"Email"'> </app-text-input>    
        <app-text-input [formControl]='formClient.controls["contactmobile"]' [type]='"number"'  [label]='"*Contact Mobile"' [smalltext]='""' [placeholder]='"Contact Mobile"'></app-text-input>
        <app-text-input [formControl]='formClient.controls["contacthomephone"]' [type]='"number"'  [label]='"Contact Home Phone"' [smalltext]='""' [placeholder]='"Contact Home Phone"'> </app-text-input>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
        <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> Business Details:</h4>   
        <app-text-input [formControl]='formClient.controls["contacthomephone"]' [type]='"number"'  [label]='"Contact Home Phone"' [smalltext]='""' [placeholder]='"Contact Home Phone"'> </app-text-input>
        <app-text-input [formControl]='formClient.controls["contactbussinessnumber"]' [type]='"number"'  [label]='"Contact Bussiness Number"' [smalltext]='""' [placeholder]='"Contact Business Number"'> </app-text-input>
        <app-text-input [formControl]='formClient.controls["bussinessname"]' [label]='"Bussiness Name"' [smalltext]='""' [placeholder]='"Bussiness Name"'></app-text-input>
        <app-text-input [formControl]='formClient.controls["abn"]' [label]='"ABN"' [smalltext]='""' [placeholder]='"ABN"'> </app-text-input>               
    </div>  
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">  
        <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> Address Details:</h4>   
        <app-text-input [formControl]='formClient.controls["address"]' [label]='"Address"' [smalltext]='""' [placeholder]='"Address"'> </app-text-input>  
        <app-text-input [formControl]='formClient.controls["unit"]' [label]='"Unit"' [smalltext]='""' [placeholder]='"Unit"'> </app-text-input>  
        <app-text-input [formControl]='formClient.controls["suburb"]' [label]='"Suburb"' [smalltext]='""' [placeholder]='"Suburb"'> </app-text-input>  
        <app-text-input [formControl]='formClient.controls["postcode"]' [label]='"Postcode"' [smalltext]='""' [placeholder]='"Postcode"'> </app-text-input>  
        <app-text-input [formControl]='formClient.controls["country"]' [label]='"Country"' [smalltext]='""' [placeholder]='"Country"'> </app-text-input>             
    </div>  
</div>
</form>
<div class="row">
<div class="col-6">
    <button [disabled]="!formClient.valid" mat-button [mat-dialog-close]="data" class="btn btn-success btn-block" form="editForm" (click)="onSubmit()">Confirm Edit Account</button>
</div>
<div class="col-6">
    <button class="btn btn-danger btn-block" mat-button mat-dialog-close>Close</button>
</div>
</div>             