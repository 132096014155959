<app-nav></app-nav>
<ng-container *ngIf="accountService.currentUser$ | async as user">
  <div class="container">
      <div class="row">
          <div class="col-12 mt-4">
            <nav class="navbar navbar-light bg-primary shadow-lg">
              <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i>
                <ng-container *ngIf="user.typeOfService; else showInvoiceTitle ">
                  All Accounts - Billing Accounts
                </ng-container>
                <ng-template #showInvoiceTitle>
                  All Accounts - Invoices
                </ng-template> 
              </a>
              <button type="button" class="btn btn-outline-light btn-lg mt-2 mt-md-0" (click)="toggle.toggleWindow(36)"><i class="{{toggle.$Icon[36]}}" aria-hidden="true\"></i> {{toggle.$ToggleText[36]}}</button>
            </nav>
          </div>     
      <div class="col-12">
        <div class="row">        
            <div class="col-12" [hidden]="!toggle.$Toggle[36]">
              <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded">
                <div class="card-body">
                  <fieldset class="form-group">           
                    <div class="row">
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
                        <img src="/assets/images/profile-account.jpg" alt="profile account" class="img-thumbnail rounded center">
                        <legend class="text-center center fs-3">How to create an 
                          <ng-container *ngIf="user.typeOfService; else showInvoiceHowtoCreate ">
                            <b>Billing Account</b>
                          </ng-container>
                          <ng-template #showInvoiceHowtoCreate>
                            <b>Invoice Account</b>
                          </ng-template>                     
                        </legend>
                        <div class="form-group">                      
                          <small id="fileHelp" class="form-text text-muted text-center fs-6">
                            In order to create an    <ng-container *ngIf="user.typeOfService; else showInvoiceHowtoCreateInfo ">
                              Billing 
                            </ng-container>
                            <ng-template #showInvoiceHowtoCreateInfo>
                              Invoice
                            </ng-template> account, you'll need an existing client. Click the button below for more information!
                          </small>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
                        <legend class="text-center fs-3 text-uppercase text-primary"><b>HOW TO USE THIS!</b></legend>
                        <div class="embed-responsive embed-responsive-1by1 sizer shadow-lg border">
                          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/cZAbsLaBwCE?si=2HhriWHRM9SfZowt" allowfullscreen></iframe>
                        </div>
                      </div>
                    </div>              
                    <button type="submit" class="btn btn-outline-primary btn-lg btn-block mt-4" routerLink="/members/turtorialhome"><i class="fas fa-info"></i> Click Here To Find Out More!</button>
                  </fieldset>
                </div>
              </div>
            </div>          
        </div>
    <div class="col-12 mt-4">
      <h1 class="text-center text-primary mt-2 font-weight-bold"><i class="fas fa-file-invoice fa-lg"></i> {{toggleInvoice.$titleInvoice}} </h1>
    </div>   
    <div class="bs-component">
      <nav class="navbar navbar-expand-lg navbar-dark bg-primary rounded ">
        <div class="container-fluid">
          <a class="navbar-brand"><i class="fas fa-tools fa-lg"></i></a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarColor01">
            <ul class="navbar-nav me-auto">
              <li class="nav-item">
                <button class="btn btn-outline-light my-2 my-sm-0 font-weight-bold" (click)="toggleButtonInvoices()">{{toggleInvoice.$showText}}</button>
              </li>
              <li class="nav-item">
                <button class="btn btn-outline-light my-2 my-sm-0 font-weight-bold ms-2" (click)="sendALLSMS()">Send ALL Initial EMAILS & SMS Notifications</button>
              </li>       
            </ul>
            <ul class="navbar-nav me-auto">
              <li class="nav-item">
                <button class="btn btn-danger my-2 my-sm-0 font-weight-bold ms-2" (click)="showOnlyArchiveAccounts()">Show Only Archives</button>
              </li>      
            </ul>
            <form class="d-flex" *ngIf="!showOnlyArchive">
              <input class="form-control me-sm-2 ms-2" type="search" (keyup)="applyFilter($event)" placeholder="Search" >
            </form>
          </div>
        </div>
      </nav>
    </div>
      <div class="mat-elevation-z8 mt-2" *ngIf="showOnlyArchive">
          <table mat-table [dataSource]="accountsDataSource" multiTemplateDataRows matSort>           
            <!-- Invoice Number Column -->
            <ng-container matColumnDef="invoiceNumber">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 
                <ng-container *ngIf="user.typeOfService; else showInvoiceNumber">
                  Billing No.
                </ng-container>
                <ng-template #showInvoiceNumber>
                  Invoice No.
                </ng-template> 
              </th>
              <td mat-cell *matCellDef="let account" >                   
                  <p class="text-primary"><b>{{account.invoiceNumber}}</b></p>
              </td>
            </ng-container>
        
            <!-- First Name Column -->
            <ng-container matColumnDef="SendInitialMessage">
              <th mat-header-cell *matHeaderCellDef class="text-right" mat-sort-header> Send Initial Message </th>
              <td mat-cell *matCellDef="let account"> 
                <button disabled *ngIf="account.sendInitialMessage === 0" class="btn btn-outline-primary" (click)="sendManualMessage(account.id)"><i class="fa fa-share"></i> Send Message</button>   
                <p *ngIf="account.sendInitialMessage === 1"><i class="fa fa-check" style="font-size:24px;color:green"></i></p>
                <p class="text-danger font-weight-bold" *ngIf="account.sendInitialMessage === 2"><i class="fa fa-remove" style="font-size:24px;color:red"></i> Unavailable</p>
                <p *ngIf="account.sendInitialMessage === 3" class="text-danger font-weight-bold">Message Not Setup</p>
                <p *ngIf="account.sendInitialMessage === 4" class="text-warning font-weight-bold">No Email to Send!</p>
                <p class="text-success font-weight-bold" *ngIf="account.sendInitialMessage === 5"><i class="fa fa-money" style="font-size:24px;color:green"></i> PAID</p>
              </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef class="text-right" mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let account"> 
                  <p class="font-weight-bold">{{account.name | titlecase }}</p> 
              </td>
            </ng-container>    

              <!-- Invoice Amount Column -->
              <ng-container matColumnDef="invoiceAmount">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice Amount</th>
                  <td mat-cell *matCellDef="let account">
                      <p class="font-weight-bold text-success">{{account.invoiceAmount | currency }}</p>
                  </td>
                </ng-container>

              <!-- Total Credit Amount Column -->
            <ng-container matColumnDef="totalCredit">
                <th mat-header-cell *matHeaderCellDef class="text-left" mat-sort-header> Total Credits</th>
                <td mat-cell *matCellDef="let account">
                    <p class="font-weight-bold text-primary">{{account.totalStoreCredit | currency }}</p>
                </td>
            </ng-container>

              <!-- Total Debit Amount Column -->
              <ng-container matColumnDef="totalrefunds">
                  <th mat-header-cell *matHeaderCellDef class="text-left" mat-sort-header> Total Refunds</th>
                  <td mat-cell *matCellDef="let account">
                      <p class="font-weight-bold text-danger">{{account.totalAccountRefunded | currency }}</p>
                  </td>
              </ng-container>

              <!-- Payment Amount Column -->
              <ng-container matColumnDef="paymentAmount">
                  <th mat-header-cell *matHeaderCellDef class="text-left" mat-sort-header> Total Paid Payments</th>
                  <td mat-cell *matCellDef="let account">
                      <p class="font-weight-bold text-success">{{account.paidAmount + account.totalPaidAccounts | currency }}</p>
                  </td>
              </ng-container>

                <!-- Due Date Payment Column -->
              <ng-container matColumnDef="dueDatePayment">
                  <th mat-header-cell *matHeaderCellDef class="text-left"> Due Date Payment</th>
                  <td mat-cell *matCellDef="let account">
                      <p class="">{{account.dueDate | date: 'mediumDate'  }}</p>
                  </td>
                </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="text-left">  </th>
                  <td mat-cell *matCellDef="let account">
                    <div class="wrap">
                      <button mat-mini-fab color="primary" [routerLink]="['/members/invoices/', account.clientID]">
                        <mat-icon>account_balance</mat-icon>
                      </button>  
                    </div>    
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            
              <mat-paginator [pageSizeOptions]="[10000]" showFirstLastButtons></mat-paginator>
        </div>
        <div class="mat-elevation-z8 mt-2" *ngIf="(payablesreceivables.allInvoices$ | async) && !showOnlyArchive">
          <table mat-table [dataSource]="payablesreceivables.accountsDataSource$" multiTemplateDataRows matSort>           
            <!-- Invoice Number Column -->
            <ng-container matColumnDef="invoiceNumber">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> 
                <ng-container *ngIf="user.typeOfService; else showInvoiceNumber">
                  Billing No.
                </ng-container>
                <ng-template #showInvoiceNumber>
                  Invoice No.
                </ng-template> 
              </th>
              <td mat-cell *matCellDef="let account" >                   
                  <p class="text-primary"><b>{{account.invoiceNumber}}</b></p>
              </td>
            </ng-container>
        
            <!-- First Name Column -->
            <ng-container matColumnDef="SendInitialMessage">
              <th mat-header-cell *matHeaderCellDef class="text-right" mat-sort-header> Send Initial Message </th>
              <td mat-cell *matCellDef="let account"> 
                <button *ngIf="account.sendInitialMessage === 0" class="btn btn-outline-primary" (click)="sendManualMessage(account.id)"><i class="fa fa-share"></i> Send Message</button>   
                <p *ngIf="account.sendInitialMessage === 1"><i class="fa fa-check" style="font-size:24px;color:green"></i></p>
                <p class="text-danger font-weight-bold" *ngIf="account.sendInitialMessage === 2"><i class="fa fa-remove" style="font-size:24px;color:red"></i> Unavailable</p>
                <p *ngIf="account.sendInitialMessage === 3" class="text-danger font-weight-bold">Message Not Setup</p>
                <p *ngIf="account.sendInitialMessage === 4" class="text-warning font-weight-bold">No Email to Send!</p>
                <p class="text-success font-weight-bold" *ngIf="account.sendInitialMessage === 5"><i class="fa fa-money" style="font-size:24px;color:green"></i> PAID</p>
              </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef class="text-right" mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let account"> 
                  <p class="font-weight-bold">{{account.name | titlecase }}</p> 
              </td>
            </ng-container>    

              <!-- Invoice Amount Column -->
              <ng-container matColumnDef="invoiceAmount">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice Amount</th>
                  <td mat-cell *matCellDef="let account">
                      <p class="font-weight-bold text-success">{{account.invoiceAmount | currency }}</p>
                  </td>
                </ng-container>

              <!-- Total Credit Amount Column -->
            <ng-container matColumnDef="totalCredit">
                <th mat-header-cell *matHeaderCellDef class="text-left" mat-sort-header> Total Credits</th>
                <td mat-cell *matCellDef="let account">
                    <p class="font-weight-bold text-primary">{{account.totalStoreCredit | currency }}</p>
                </td>
            </ng-container>

              <!-- Total Debit Amount Column -->
              <ng-container matColumnDef="totalrefunds">
                  <th mat-header-cell *matHeaderCellDef class="text-left" mat-sort-header> Total Refunds</th>
                  <td mat-cell *matCellDef="let account">
                      <p class="font-weight-bold text-danger">{{account.totalAccountRefunded | currency }}</p>
                  </td>
              </ng-container>

              <!-- Payment Amount Column -->
              <ng-container matColumnDef="paymentAmount">
                  <th mat-header-cell *matHeaderCellDef class="text-left" mat-sort-header> Total Paid Payments</th>
                  <td mat-cell *matCellDef="let account">
                      <p class="font-weight-bold text-success">{{account.paidAmount + account.totalPaidAccounts | currency }}</p>
                  </td>
              </ng-container>

                <!-- Due Date Payment Column -->
              <ng-container matColumnDef="dueDatePayment">
                  <th mat-header-cell *matHeaderCellDef class="text-left"> Due Date Payment</th>
                  <td mat-cell *matCellDef="let account">
                      <p class="">{{account.dueDate | date: 'mediumDate'  }}</p>
                  </td>
                </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="text-left">  </th>
                  <td mat-cell *matCellDef="let account">
                    <div class="wrap">
                      <button mat-mini-fab color="primary" [routerLink]="['/members/invoices/', account.clientID]">
                        <mat-icon>account_balance</mat-icon>
                      </button>  
                    </div>    
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            
              <mat-paginator [pageSizeOptions]="[10000]" showFirstLastButtons></mat-paginator>
        </div>
        <div class="mt-3"></div>    
      </div>
    </div>
  </div>
</ng-container>

