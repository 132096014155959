<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to edit an existing account payment</h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/Edit Account Client Payments/eacp01.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Go to the clients “Account Payments” page  </h2>
    <h2>•	Scroll down to the “Payment List” section  </h2>
    <h2>•	Click the three (3) dots beside the “Payment Account” that you wish to edit  </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/Edit Account Client Payments/eacp02.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Under the menu, click “Edit Payment Account Profile”</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/Edit Account Client Payments/eacp03.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Edit the Account Payment as necessary</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/Edit Account Client Payments/eacp04.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Click “Confirm Payment Account” to save the changes</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Payments/Edit Account Client Payments/eacp05.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Under the “Payment List” you can now see that the contents are updated</h2>
</div>