<app-nav></app-nav>
<div class="container mt-5">
    <div>
        <div class="row justify-content-center">
            <div class="col-sm-12 col-md-4 py-5 bg-white text-black text-center border shadow-lg rounded">
                <div class=" ">
                    <div class="card-body">
                        <img src="/assets/images/edit.jpg" class="img-thumbnail">
                        <h2 class="py-3">Trying to validate your email</h2>
                        <p>
                            Check your spam folder and click on the confirmation link.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-8 mt-4">
                <div class="card border-primary mb-3">
                    <div class="card-body">
                        <form [formGroup]="sendEmailForm" (ngSubmit)="sendEmailForm.valid" autocomplete="off">
                                <h1>Resend Your Email Confirmation</h1>
                                <div class="bs-component">
                                    <div class="alert alert-dismissible alert-primary">
                                      <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                                      <strong>Thankyou!</strong>  We now need to confirm your email. Please go and check your spam folder and click on confirmation link. 
                                    </div>
                                </div>
                                <div class="form-group has-success">
                                    <label class="form-label mt-4" for="inputValid">Email</label>
                                    <input type="text" value="correct value" class="form-control is-valid" formControlName="email" placeholder="Email" disabled>
                                    <div class="valid-feedback">Your email has been sent. </div>
                                </div>    
                                <button [disabled]="!sendEmailForm.valid" class="btn btn-success btn-block" form="editForm" (click)="resendEmail()" >Resend Email Link Confirmation</button>                                                               
                       </form>
                    </div>
                </div>              
            </div>
        </div>
    </div>
</div>