<app-nav></app-nav>
<div class="container mt-4">
    <div class="container-fluid">
        <div class="col-lg-8 col-sm-8 col-md-8 mt-2">
            <div class="card border mb-3 shadow-lg">
                <div class="card-body">
                    <app-policy></app-policy>
                </div>
              </div>              
        </div>    
    </div>
</div>
