<h4 class="text-center">Are you sure you want to Change services for this account?</h4>
<div class="bs-component">
    <div class="alert alert-dismissible alert-warning">
      <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
      <h4 class="alert-heading">Warning!</h4>
      <p class="mb-0">Please note that if you proceed with this action, your account will be logged out. You will need to log back in to continue using the service.</p>
    </div>
</div>
<div class="row">
    <div class="col-6">
    
        <button type="button" class="btn btn-danger btn-block" mat-button [mat-dialog-close]="data" (click)="changeServicesAccount()">
            <ng-container *ngIf="(showTypeService$ | async); else showinvoiceservice">
                Change My Services to Invoice Accounts         
            </ng-container>
            <ng-template #showinvoiceservice>
                Change My Service to Billing Accounts
            </ng-template>
        </button>
    </div>
    <div class="col-6">
        <button type="button" class="btn btn-primary btn-block" mat-button mat-dialog-close>Cancel</button>
    </div>
</div>