
<mat-dialog-content>
    <form #editForm="ngForm" id="formEdit" [formGroup]="formEmail" (ngSubmit)="formEmail.valid" autocomplete="off" class="mt-4">
        <div class="bs-component">
            <div class="alert alert-dismissible alert-primary shadow-lg rounded-3 text-center">
                <strong class="text-white fs-6">If you want to test out instalment initial date email looks like. Just enter some fields here and just click on submit when you are done!</strong> 
            </div>        
        </div>
        <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
            <button type="button" class="close" data-dismiss="alert">×</button>
            <h4 class="alert-heading">Information!</h4>
            <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
        </div>
    
        <div class="row">
            <div class="col-12">
                <h4 class="card-title bg-dark text-white text-center py-2"><i class="fas fa-user"></i> Test Fields Required For Initial!</h4>
            </div>
        </div>
    
        <div class="row mt-4">
            <div class="col-lg-6">
                <app-text-input [formControl]='formEmail.controls["Name"]' [label]='"*Full Name"' [smalltext]='"Contains Full Name"' [placeholder]='"Full Name"'> </app-text-input>
                <app-text-input [formControl]='formEmail.controls["EmailTo"]' [label]='"*Your Email"' [smalltext]='"Use Your Email To Test It"' [placeholder]='"Your Email"'> </app-text-input>
            </div>
            <div class="col-lg-6">
                <app-date-input [formControl]='formEmail.controls["dueDate"]' [label]='"*Due Date"' [smalltext]='"Final date for the payment"' [placeholder]='"Due Date"'> </app-date-input>
                <app-text-input [formControl]='formEmail.controls["InvoiceAmount"]' [label]='"*Invoice Amount"' [smalltext]='""' [placeholder]='"Invoice Amount"' [type]='"number"'> </app-text-input>
            </div>
        </div>
    
        <div class="row mt-4">
            <div class="col-lg-6">
                <button [disabled]="!formEmail.valid" mat-button [mat-dialog-close]="data" class="btn btn-success btn-block" form="editForm" (click)="onSubmit()">Send Email Message</button>
            </div>
            <div class="col-lg-6">
                <button class="btn btn-danger btn-block" mat-button mat-dialog-close>Close</button>
            </div>
        </div>
    </form>        
</mat-dialog-content>