import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgControlStatusGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Invoice } from 'src/app/_models/invoice';
import { Pagination } from 'src/app/_models/pagination';
import { InvoiceService } from 'src/app/_services/invoice/invoice.service';
import { DeleteinvoiceComponent } from '../deleteinvoice/deleteinvoice.component';
import { EditinvoiceComponent } from '../editinvoice/editinvoice.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToggledefinitionService } from 'src/app/_services/toggledefinition.service';
import { AccountService } from 'src/app/_services/account/account.service';
import { ToastrService } from 'ngx-toastr';
import { HostpresenceService } from 'src/app/_services/hubsignalr/hostpresence.service';
import { User } from 'src/app/_models/user';
import { take } from 'rxjs/operators';
import { InvoicearchiveComponent } from '../invoicearchive/invoicearchive.component';
import { DialogresetinitalmessageComponent } from '../dialogresetinitalmessage/dialogresetinitalmessage.component';


@Component({
  selector: 'app-invoicelist',
  templateUrl: './invoicelist.component.html',
  styleUrls: ['./invoicelist.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})


export class InvoicelistComponent implements AfterViewInit, OnDestroy  {
  invoices: Invoice[];
  clientID: string;
  allRowsExpanded: boolean = false;
  user?: User;
  timeLeft: number = 1;
  interval;
  showStatus: boolean;

  columnsToDisplay = ['InvoiceNumber', 'InvoiceAmount', 'Instalment', 'AddFees', 'Payments','InitialDate', 'DueDate', 'MessageInitialSent', 'MessageDueDateSent',
    'MessageReceiveSent', 'Actions'];
  expandedElement: Invoice | null;

  constructor(public invoiceService: InvoiceService, private route: ActivatedRoute, private dialog: MatDialog, public legends: ToggledefinitionService,
    public accountService: AccountService, private toastr: ToastrService, private presenceHub: HostpresenceService, private router: Router) { 
    this.getParrams(); 
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  ngOnDestroy(): void {
    this.presenceHub.pageActive[2] = false;
    this.invoiceService.showarchive = false;
    this.invoiceService.stopHubConnection();
    clearInterval(this.interval);
  }

  ngAfterViewInit() {
    this.showStatus = true;
    this.presenceHub.pageActive[2] = true;
    this.toastr.info("Please wait! We are getting your records now!");
    this.assignUser();
    this.loadMembers();
  }

  loadMembers(){
    const clientID: number = +this.clientID;
    this.invoiceService.showarchive = false;
    this.invoiceService.stopHubConnection();
    this.invoiceService.stopArchiveHubConnection();
    if (Number(clientID))
    {
      this.invoiceService.createHubConnection(this.user, +this.clientID);
      this.startTimer();
    }
  }

  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.clientID = params.get("id");
    });
  }

  editInvoice(id: any){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "100%";
    dialogConfig.height = "auto";
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      id: id,
    };
    
    this.invoiceService.getCheckCanEditInvoice(id, false).subscribe(response => {
      if (response)
      {
        this.router.navigate(['/members/editinvoice',id, this.clientID]);
      }
      else
      {
        this.toastr.error("Failed! Another account is in use of editing this account.");
      }
    }, err => {
      console.log(err);
    })

  }

  deleteInvoice(id: number, clientID: number){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "auto";
    dialogConfig.width = "75vw";
    
    dialogConfig.data = {
      id: id,
      clientID: clientID,
      deleteInvoice: false
    };
    
    this.invoiceService.getCheckCanDeleteInvoice(id, false).subscribe(response => {
      if (response)
      {
        const dialogRef = this.dialog.open(DeleteinvoiceComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          if (result.deleteInvoice!= null)
          {                
            if (result.deleteInvoice)
            {
              /* Reflesh the data source for invoice list */
              this.refleshDataSource();
            }    
          }
          else
          {
            this.invoiceService.cancelCanEditInvoice(id).subscribe(response => {
              if (response)
              {
                this.toastr.info("Active Account Closed");
              }
            }, err => {
              console.log(err);
            })
          }
        });   
      }
      else
      {
        this.toastr.error("Failed! Another account is in use of editing this account.");
      }
    }, err => console.log(err));
  }

  resetInitialMessage(id: number){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "auto";
    dialogConfig.width = "75vw";
    
    dialogConfig.data = {
      id: id,
    };
    
    const dialogRef = this.dialog.open(DialogresetinitalmessageComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

    });   
  }

  archiveInvoice(id: number, clientID: number, archive: boolean){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "auto";
    dialogConfig.width = "75vw";
    
    dialogConfig.data = {
      id: id,
      clientID: clientID,
      archive: archive,
    };
    
    this.invoiceService.getCheckCanArchiveInvoice(id, true).subscribe(response => {
      if (response)
      {
        const dialogRef = this.dialog.open(InvoicearchiveComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          this.invoiceService.cancelCanEditInvoice(id).subscribe(response => {
            if (response)
            {
              this.toastr.info("Active Account Closed");
            }
          }, err => {
            console.log(err);
          });
          
          if (result.archive!= null)
          {                
            if (result.archive)
            {
              /* Reflesh the data source for invoice list */
              this.refleshDataSource();
            }    
          }
        }); 
      }
    });    
  }

  sendManualMessage(id: number){
    console.log(id);
    const data = {
      id: id
    };
    
    this.invoiceService.sendInitialMessage(data).subscribe(res => {
      this.resetform();
    }, error => {
      console.log(error);  
    });
  }

  onChangeArchive(status){
    if (status.target.value === "1")
    {     
      this.OnChangeConnection(false);
      this.invoiceService.createHubConnection(this.user, +this.clientID);
    }
    else
    {
      this.OnChangeConnection(true);
      this.invoiceService.createArchiveHubConnection(this.user, +this.clientID);
    }
  }

  private OnChangeConnection(showArchive: boolean)
  {
    this.invoiceService.showarchive = showArchive;
    this.invoiceService.invoiceDataSource = new MatTableDataSource<Invoice>();
    this.invoiceService.stopHubConnection();
    this.invoiceService.stopArchiveHubConnection();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.invoiceService.invoiceDataSource.filter = filterValue.trim().toLowerCase();

    if (this.invoiceService.invoiceDataSource.paginator) {
      this.invoiceService.invoiceDataSource.paginator.firstPage();
    }
  }

  public toggle() {
    this.allRowsExpanded = !this.allRowsExpanded;
    this.expandedElement = null;
  }

  legendToggle(){
    this.invoiceService.showlegends = !this.invoiceService.showlegends;

    if (!this.invoiceService.showlegends)
    {
      this.invoiceService.showlegendsTxt = "Show Legends";
    }
    else
    {
      this.invoiceService.showlegendsTxt = "Hide Legends";
    }
  }

  sumTotalPayments(invoice: Invoice): string {
    const totalPayments = invoice.totalPaidAccounts + invoice.paidAmount;
    const specialPayments = invoice.totalStoreCredit + invoice.totalPaidAccounts + invoice.totalAccountRefunded + invoice.paidAmount;
    if (totalPayments >= invoice.invoiceAmount)
    {
      return "Account Paid";
    }
    return specialPayments >= invoice.invoiceAmount ? "Special" : "Not Paid";
  }

  refleshDataSource(){
    this.invoiceService.invoiceDataSource.paginator = this.invoiceService.paginator;
    this.invoiceService.invoiceDataSource._updateChangeSubscription();
  }

  openInstalmentAccount(accountID: number, invoiceAmount: number, amountPaid: number){
    this.invoiceService.getCheckCanOpenInstalmentAccount(accountID, true).subscribe(response => {
      if (response)
        this.router.navigate(['/members/createinstalments', accountID, invoiceAmount, amountPaid]);
      else
        this.toastr.error("You don't have permissions to enter in Instalment Accounts!");
    }, err => console.log(err));
  }

  openPaymentAccount(accountID: number, invoiceAmount: number){
      this.invoiceService.getCheckCanOpenPaymentAccount(accountID, true).subscribe(response => {
        if (response)
          this.router.navigate(['/members/accountclientreceivable', accountID, invoiceAmount]);
        else
          this.toastr.error("You don't have permissions to enter in Payment Accounts!");
      }, err => console.log(err));
  }

  resetform(){
    this.invoiceService.getinvoices(this.clientID).subscribe(
      response => {      
        this.invoiceService.invoiceDataSource = new MatTableDataSource<Invoice>(response);
        this.invoiceService.invoiceDataSource.paginator = this.invoiceService.paginator;
        this.invoiceService.invoiceDataSource._updateChangeSubscription();
        console.log(this.invoiceService.invoiceDataSource);
      }, error => {
        console.log(error);
      }
    )
  } 

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 3;
        this.presenceHub.pageActive[2] = true;
      }
    },1000)
  }
}