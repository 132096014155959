import { Component, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { HomeTask } from 'src/app/_models/hometask';
import { NotePatch } from 'src/app/_models/notepatch';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { HomeserviceService } from 'src/app/_services/home/homeservice.service';
import { StripeaccountService } from 'src/app/_services/stripeaccount/stripeaccount.service';

@Component({
  selector: 'app-homeuser',
  templateUrl: './homeuser.component.html',
  styleUrls: ['./homeuser.component.css']
})
export class HomeuserComponent implements OnInit {

  tasks: HomeTask;
  patches: NotePatch;
  user: User;
  subscription = new ReplaySubject<string>(1);
  subscription$ = this.subscription.asObservable();

  constructor(private services: HomeserviceService, private accountService: AccountService) { }


  ngOnInit(): void {
    this.assignUser();
    this.getHomeTasks();
    this.getPatchNotes(); 
    this.getCurrentSubscription();
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  private getHomeTasks(){
    this.services.getHomeTasks().subscribe(res => {
      this.tasks = res;
    }, err => {
      console.log(err);
    });
  }

  private getPatchNotes(){
    this.services.getPatches().subscribe(res => {
      console.log(res);
      this.patches = res;
    }, err => {
      console.log(err);
    })
  }

  private getCurrentSubscription(){
    this.services.getCurrentSubscripton().subscribe(response => {
      this.subscription.next(response.subscription);
    }, err => {
      console.log(err);
      this.subscription.next("Free Subscription");
    })
  }
}
