import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HubConnection } from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { PayablesReceivables } from 'src/app/_models/payablesreceivables';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { HostpresenceService } from 'src/app/_services/hubsignalr/hostpresence.service';
import { InvoiceService } from 'src/app/_services/invoice/invoice.service';
import { ToggleinvoicebuttonService } from 'src/app/_services/invoice/toggleinvoicebutton.service';
import { PayablesreceivablesService } from 'src/app/_services/payablesreceivablesaccounts/payablesreceivables.service';
import { environment } from 'src/environments/environment';
import { DialogsendingallsmsComponent } from './dialogsendingallsms/dialogsendingallsms.component';
import { ToastrService } from 'ngx-toastr';
import { TogglewindowService } from 'src/app/_services/togglewindow.service';

@Component({
  selector: 'app-accountsreceivable',
  templateUrl: './accountsreceivable.component.html',
  styleUrls: ['./accountsreceivable.component.css']
})
export class AccountsreceivableComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['invoiceNumber', 'SendInitialMessage', 'name', 'invoiceAmount', 'totalCredit', 'totalrefunds','paymentAmount', 'dueDatePayment', 'actions'];
  accounts: PayablesReceivables[];
  
  showText: string = "";
  user?: User;
  timeLeft: number = 1;
  interval;
  typeofservice: string = "";
  private invoicesSource = new BehaviorSubject<PayablesReceivables[]>([]);
  accountsDataSource: MatTableDataSource<PayablesReceivables> = new MatTableDataSource<PayablesReceivables>();
  allInvoices$ = this.invoicesSource.asObservable();
  showOnlyArchive: boolean = false;
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public payablesreceivables: PayablesreceivablesService, public invoiceService: InvoiceService, private dialog: MatDialog, 
    private presenceHub: HostpresenceService, public toggleInvoice: ToggleinvoicebuttonService,  public accountService: AccountService,
    private toastr: ToastrService, public toggle: TogglewindowService) 
  {
    
  }

  ngOnDestroy(): void {
    this.presenceHub.pageActive[5] = false;
    this.payablesreceivables.stopHubConnection();
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.assignUser();
    this.initializeDefaultSettings();
    this.loadMembers();
  }

  initializeDefaultSettings(){
    if (this.user.typeOfService)
    {
      this.typeofservice = "Billing Accounts";
    }
    else
    {
      this.typeofservice = "Invoices";
    }

    this.toggleInvoice.$titleInvoice = "All " + this.typeofservice + '(Non-Archive)';
    this.toggleInvoice.$showText = "Only Show Overdue " + this.typeofservice + ' (Non-Archive)';
    this.toggleInvoice.$toggleInvoicesBtn = true;
    this.presenceHub.pageActive[5] = true;
    this.toastr.info("Please wait! We are getting your records now!");
  }

  loadMembers(){
    this.payablesreceivables.stopHubConnection();
    if (this.user !== null)
    {
      if (this.user.token !== null)
      {
        this.payablesreceivables.createHubConnection(this.user, true);
        this.startTimer();
      }
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.payablesreceivables.accountsDataSource$.filter = filterValue.trim().toLowerCase();

    if (this.payablesreceivables.accountsDataSource$.paginator) {
      this.payablesreceivables.accountsDataSource$.paginator.firstPage();
    }
  }

  
  sendManualMessage(id: number){
    console.log(id);
    const data = {
      id: id
    };
    
    this.invoiceService.sendInitialMessage(data).subscribe(res => {
      this.loadMembers();     
    }, error => {
      console.log(error);  
    });
  }

  async sendALLSMS(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "35%";
    dialogConfig.data = {
      loadpage: 0,
    };

    const dialogRef = this.dialog.open(DialogsendingallsmsComponent, dialogConfig);

    dialogRef.afterClosed().toPromise().then(result => {
      console.log('The dialog was closed', result.loadpage);
      if (result.loadpage == 1)
      {
        this.loadMembers();        
      }
      return Promise.resolve(result);
    });
  } 

  toggleButtonInvoices(){
    this.showOnlyArchive = false;
    this.toggleInvoice.$toggleInvoicesBtn = !this.toggleInvoice.$toggleInvoicesBtn;
    this.toastr.info("Please wait! We are getting your records now!");

    if (this.toggleInvoice.$toggleInvoicesBtn)
    {
      this.toggleInvoice.$titleInvoice = "All " + this.typeofservice + ' (Non-Archive)';
      this.toggleInvoice.$showText = "Only Show Overdue " + this.typeofservice + ' (Non-Archive)';
      this.loadMembers();
    }
    else
    {
      this.toggleInvoice.$titleInvoice = "Only Showing Overdue " + this.typeofservice + ' (Non-Archive)';
      this.toggleInvoice.$showText = "Show All " + this.typeofservice + ' (Non-Archive)';
      this.loadMembers();
    }
  }

  getUnpaidInvoices(){
    this.payablesreceivables.getReceivablesPayablesUnpaid().subscribe(
      response => {
         this.accounts = response;
         console.log(this.accounts);
         this.payablesreceivables.accountsDataSource$ = new MatTableDataSource<PayablesReceivables>(this.accounts);
         this.payablesreceivables.accountsDataSource$.paginator = this.paginator;
         this.payablesreceivables.accountsDataSource$.sort = this.sort;
      }, error => {
        console.log(error);
      }
    )
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.payablesreceivables.accountsDataSource$.paginator = this.paginator;
        this.payablesreceivables.accountsDataSource$.sort = this.sort;
        this.timeLeft = 10;
        this.presenceHub.pageActive[5] = true;
      }
    },1000)
  }

  showOnlyArchiveAccounts(){
    this.toggleInvoice.$titleInvoice = "Showing Only Archive Accounts";
    this.showOnlyArchive = true;
    this.payablesreceivables.showOnlyArchiveAccounts().subscribe(response => {
      console.log(response);
      this.invoicesSource.next(response);
      this.accountsDataSource.data = response;
    }, err => {
      console.log(err);
    })
  }

  
}
