import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { TogglewindowService } from 'src/app/_services/togglewindow.service';
import { MemberingroupPermissions } from 'src/app/_models/memberingrouppermissions';
import { UsergroupspermissionsService } from 'src/app/_services/usergroups/usergroupspermissions.service';
import { WhiteSpacesValidator } from 'src/app/_validators/whitespaces.validator';

@Component({
  selector: 'app-creatememberingroup',
  templateUrl: './creatememberingroup.component.html',
  styleUrls: ['./creatememberingroup.component.css']
})
export class CreatememberingroupComponent implements OnInit {
  userMember: UntypedFormGroup;
  member: MemberingroupPermissions;
  @ViewChild('editForm') editForm: NgForm;  
  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any){
    if (this.editForm.dirty){
      $event.returnValue = true;
    }
  }
  @Output() resetForm = new EventEmitter();

  constructor(public services: UsergroupspermissionsService, public toggle: TogglewindowService,
    public fb: UntypedFormBuilder, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  createMember(){
    this.setGroupMember();
    this.services.createMemberinGroup(this.member).subscribe(response => {
      if (response === true)
      {
        this.services.getMembersFromGroup().subscribe(response => {
          console.log(response);
          this.services.membersdataSource = new MatTableDataSource(response);
          this.services.membersdataSource.paginator = this.services.memberPaginator;
          this.services.membersdataSource.sort = this.services.membersort;
          this.initializeForm();
        }, err => {
          console.log(err);
        })
      }
    }, err => {
      console.log(err);
    })
  }

  initializeForm(){
    this.userMember = this.fb.group({
      firstname:['', [Validators.required, WhiteSpacesValidator.cannotContainSpace]],
      lastname:['', [Validators.required, WhiteSpacesValidator.cannotContainSpace]],
      email:['', [Validators.required, Validators.email]],
      groupid:[this.services.getFirstOptionSelectedForGroups.toString(), [Validators.required, WhiteSpacesValidator.cannotContainSpaceAtFirstCharacter]],
    })
  } 

  setGroupMember(){
    this.member = {
      firstName: this.userMember.value.firstname,
      lastName: this.userMember.value.lastname,
      email: this.userMember.value.email,
      groupID: +this.userMember.value.groupid,      
    };
  }
}
