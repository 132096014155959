<app-nav></app-nav>
<div class="container mt-5">
    <div>
        <div class="row justify-content-center">
            <div class="col-sm-12 col-md-4  py-5 bg-white text-black text-center border shadow-lg rounded">
                <div class=" ">
                    <div class="card-body">
                        <img src="/assets/images/edit.jpg" class="img-thumbnail">
                        <h2 class="py-3">{{leftMessage}} </h2>
         
                        <p *ngIf="showConfirm">
                            You made it this far! Log out and log back in. 
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-8 mt-2">
                <div class="card border-primary mb-3">
                    <div class="card-body">
                      <h1>{{message}}</h1>
                      <ng-container *ngIf="showConfirm">
                        <div class="bs-component">
                            <div class="alert alert-dismissible alert-success">
                              <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                              <strong>Well done!</strong> You have successfully confirmed your account. Please click the link below to continue!
                            </div>
                        </div>
                        <button class="btn btn-primary" routerLink="/home">Click Here next!</button>
                    </ng-container>
                    </div>
                </div>              
            </div>
        </div>
    </div>
</div>