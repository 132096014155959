<app-nav></app-nav>
<div class="container mt-4">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <!-- Just an narbar title -->
        <nav class="navbar navbar-light bg-primary shadow-lg">
          <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Clients</a>       
          <button type="button" class="btn btn-outline-light btn-lg mt-2 mt-md-0" (click)="toggle.toggleWindow(30)"><i class="{{toggle.$Icon[30]}}" aria-hidden="true\"></i> {{toggle.$ToggleText[30]}}</button>
        </nav>        
      </div>
      <div class="col-12" [hidden]="!toggle.$Toggle[30]">
        <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded">
          <div class="card-body">
            <fieldset class="form-group">           
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
                  <img src="/assets/images/profile-account.jpg" alt="profile account" class="img-thumbnail rounded center">
                    <legend class="text-center fs-3">How to Use <b>Client Account</b></legend>
                  <div class="form-group">                      
                    <small id="fileHelp" class="form-text text-muted text-center fs-6">
                      Click the button below to learn more!
                    </small>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
                  <legend class="text-center fs-3 text-uppercase text-primary"><b>HOW TO USE THIS!</b></legend>
                  <div class="embed-responsive embed-responsive-1by1 sizer shadow-lg border mt-4">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/UkMOWT_laxE?si=IsU3tzbjOEgQBHtI" allowfullscreen></iframe>
                  </div>
                </div>
              </div>              
              <button type="submit" class="btn btn-outline-primary btn-lg btn-block mt-4" routerLink="/members/turtorialhome"><i class="fas fa-info"></i> Click Here To Find Out More!</button>
            </fieldset>
          </div>
        </div>
      </div>
      <div class="col-12 mt-2">
        <!-- Just an narbar title -->
        <nav class="navbar navbar-light bg-primary shadow-lg">
          <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Create Clients</a>
          <button type="button" class="btn btn-outline-light btn-lg mt-2 mt-md-0" (click)="toggle.toggleWindow(0)"><i class="{{toggle.$Icon[0]}}" aria-hidden="true\"></i> {{toggle.$ToggleText[0]}}</button>
        </nav>        
      </div>
      <div class="col-12">
        <div class="bs-component mt-4" *ngIf="showErrorClientStripeAccount">
          <div class="alert alert-dismissible alert-danger">
            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
            <h4 class="alert-heading">ERROR!</h4>
            <p class="mb-0">You will need to contact Invoicepager to setup your Stripe account before you can enter data.</p>
          </div>
        </div>
      </div> 
      <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12" [hidden]="!toggle.$Toggle[0]">
        <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded">
            <div class="card-body"> 
              <h2 class="text-center text-primary mt-2">Create Client Account</h2>
              <hr>
              <form #editForm="ngForm" id="editForm" [formGroup]="formClient" (ngSubmit)="formClient.valid" autocomplete="off">
                <div class="bs-component">
                  <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
                    <button type="button" class="close" data-dismiss="alert">×</button>
                    <h4 class="alert-heading">Information!</h4>
                    <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
                  </div>             
                </div>
                <ng-container *ngIf="clientExist" >
                  <div class="alert alert-dismissible alert-danger" *ngIf="editForm.dirty">
                    <button type="button" class="close" data-dismiss="alert">×</button>
                    <h4 class="alert-heading">Account Exist!</h4>
                    <p class="mb-0">First Name & and last Name & Mobile Contact Number already <b>Exist</b> in your account! Please ensure you check your client details. </p>
                  </div>
                </ng-container>
                <div class="bs-component">
                  <div class="alert alert-dismissible alert-danger" *ngIf="errorsServer">
                    <h4 class="alert-heading">Error!</h4>
                    <p class="mb-0">Your already have existing record of that invoice number! Please use another invoice number.</p>
                  </div>             
                </div>
                <div class="row">
                  
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> A business or person's full name (Required):</h4>
                    <app-text-input [formControl]='formClient.controls["name"]' [label]='"*Name"' [smalltext]='""' [placeholder]='"A business or persons name"'> </app-text-input>
                    <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> Personal Details:</h4>
                    <app-text-input [formControl]='formClient.controls["firstname"]' [label]='"First Name"' [smalltext]='""' [placeholder]='"First Name"'> </app-text-input>
                    <app-text-input [formControl]='formClient.controls["lastname"]' [label]='"Last Name"' [smalltext]='""' [placeholder]='"Last Name"'> </app-text-input>
                    <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> Address:</h4>
                    <app-text-input [formControl]='formClient.controls["address"]' [label]='"Address"' [smalltext]='""' [placeholder]='"Street address, P.O box"'> </app-text-input>
                    <app-text-input [formControl]='formClient.controls["addressunit"]' [label]='"Unit"' [smalltext]='""' [placeholder]='"Apt, Suite, Unit, Building, Floor"'> </app-text-input>  
                    <app-text-input [formControl]='formClient.controls["postcode"]' [label]='"Postcode"' [type]='"number"'  [smalltext]='""' [placeholder]='"Postcode"'> </app-text-input>  
                    <app-text-input [formControl]='formClient.controls["province"]' [label]='"City/Suburb"' [smalltext]='""' [placeholder]='"City, Suburb, Town, Village"'> </app-text-input>  
                    <app-text-input [formControl]='formClient.controls["state"]' [label]='"State/Territory"' [smalltext]='""' [placeholder]='"State / Territory"'> </app-text-input>  
                    <app-text-input [formControl]='formClient.controls["country"]' [label]='"Country"' [smalltext]='""' [placeholder]='"Country"'> </app-text-input>  
                  </div>
  
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i>  Contact Information:</h4>
                    <app-text-input [formControl]='formClient.controls["contactmobile"]' [label]='"Contact Australia Mobile (Use for Sending Sms)"' [type]='"number"' [smalltext]='""' [placeholder]='"Contact Australia Mobile"' [classInput]='"form-label fw-bold text-primary"'></app-text-input>
                    <app-text-input [formControl]='formClient.controls["email"]' [label]='"Email (Use for Sending Emails)"' [smalltext]='""' [placeholder]='"Email"' [classInput]='"form-label fw-bold text-primary"'> </app-text-input>    
                    <app-text-input [formControl]='formClient.controls["contacthomephone"]' [label]='"Contact Other"' [type]='"number"' [smalltext]='""' [placeholder]='"Contact Other"'> </app-text-input>
                    <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> Business Information:</h4>
                    <app-text-input [formControl]='formClient.controls["bussinessname"]' [label]='"Business Name"' [smalltext]='""' [placeholder]='"Business Name"'></app-text-input>
                    <app-text-input [formControl]='formClient.controls["abn"]' [label]='"ABN"' [smalltext]='""'  [type]='"number"' [placeholder]='"ABN"'> </app-text-input> 
                    <app-text-input [formControl]='formClient.controls["contactbussinessnumber"]' [label]='"Contact Business Number"' [type]='"number"' [smalltext]='""' [placeholder]='"Contact Business Number"'> </app-text-input>
                    <fieldset class="form-group">
                      <div class="card border-secondary mb-3">
                        <div class="card-body">
                          <h4 class ="card-title bg-dark text-white text-center"><i class="fas fa-star"></i> Accept Approval Credit Rating (Required):</h4>
                          <small>A credit rating is quantified assessment of the creditworthiness of a borrower in general terms or with repect to a financial obigations.</small>
                          <div class="mt-2"></div>
                          <app-radio-button [formControl]='formClient.controls["creditRatingApproved"]' [value]='"true"' [displayname]="'Accept'" [name]="'creditRatingApproved'"></app-radio-button>
                          <app-radio-button [formControl]='formClient.controls["creditRatingApproved"]' [value]='"false"' [displayname]="'Decline'" [name]="'creditRatingApproved'"></app-radio-button>
                        </div>
                      </div>                     
                    </fieldset>
                  </div>  
                </div>
              </form>
            </div>
            <div class="card-footer text-muted">
                <button [disabled]="!formClient.valid" class="btn btn-success btn-block" form="editForm" (click)="createClientAccount()" ><i class="fas fa-user"></i>  Create Client Account</button>
            </div>
          </div>
    </div>
    <div class="col d-none d-sm-block" *ngIf="toggle.$Toggle[0]">
      <div class="row">
        <div class="col-xl col-md col-lg col-sm-12">
          <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded">
            <div class="card-body">
              <fieldset class="form-group">
                <legend>Import File via Excel</legend>
                <div class="form-group">
                  <small id="fileHelp" class="form-text text-muted">Automate your data and integrate it into our cloud services</small>
                </div>
                <button type="submit" class="btn btn-primary ms-2 btn-block" (click)="uploadFile()"><i class="fas fa-upload"></i> Upload File Document</button>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl col-md col-lg col-sm-122">
          <div class="card mt-4 border-info shadow-lg p-3 mb-5 bg-white rounded">
            <div class="card-body">
              <fieldset class="form-group">
                <legend>Import Data via Xero API</legend>
                <div class="form-group">
                  <small id="fileHelp" class="form-text text-muted">Streamline data automation by leveraging Xero APIs.</small>
                </div>
                <button type="submit" class="btn btn-primary ms-2 btn-block" (click)="uploadAPI()"><i class="fas fa-upload fa-lg"></i> Upload Data Using Xero's API</button>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">   
        <app-clientlist></app-clientlist>
      </div>
    </div>
  </div>
  </div>
</div>


