import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorialhowtoeditstoreaccount',
  templateUrl: './tutorialhowtoeditstoreaccount.component.html',
  styleUrls: ['./tutorialhowtoeditstoreaccount.component.css']
})
export class TutorialhowtoeditstoreaccountComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
