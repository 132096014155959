<div class="col-lg-12 col-md-12 col-sm-12">
    <h2 class="text-center">How to delete an invoice account</h2>
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/Delete Invoice/di01.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	Click the three (3) dots beside the “Invoice Account” you wish to delete </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/Delete Invoice/di02.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>•	In the menu, click “Delete Invoice Account”</h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/Delete Invoice/di03.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>
        •	Click “Delete” to confirm, or “Cancel” if you wish to not delete the “Invoice Account"
    </h2>
</div>
<hr>
<div class="col-lg-12 col-md-12 col-sm-12">
    <img src="assets/images/Invoice/Delete Invoice/di04.png" class="img-fluid" alt="Responsive image">
</div>
<div class="col-lg-12 col-md-12 col-sm-12">
    <h2>
        •	As you can see, the “Invoice Account” is now deleted
    </h2>
</div>
