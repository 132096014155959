import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmEmail } from 'src/app/_models/confirmemail';
import { EmailconfigService } from 'src/app/_services/email/emailconfig.service';

@Component({
  selector: 'app-confirmemailpage',
  templateUrl: './confirmemailpage.component.html',
  styleUrls: ['./confirmemailpage.component.css']
})
export class ConfirmemailpageComponent implements OnInit {

  constructor(private route: ActivatedRoute, private services: EmailconfigService, private toastr: ToastrService) { }
  user: ConfirmEmail;
  message: string = "Confirmation Succeeded On Email Account!";
  leftMessage: string = "Congratulations";
  showConfirm: boolean = true;
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.initializatIonUser();
    this.getParrams();
    this.loadConfirmEmail();
  }

  getParrams(){
    this.route.queryParams.subscribe(parrams => {
      console.log(parrams);
      this.user.id = parrams.id;
      this.user.token = parrams.token;
    });

    console.log(this.user.id);
    console.log(this.user.token);
  }

  loadConfirmEmail(){
    this.services.loadConfirmEmail(this.user).subscribe(response => {
      if (response == true)
      {
        this.toastr.success("Confirm Account!");
      }
      else
      {
        this.toastr.error("Token Expired!");
        this.message = "Failed On Email Account!";
        this.leftMessage = "Failed To Confirm Email Account!";
        this.showConfirm = false;
      }     
    }, err => {
      console.log(err);
      this.showConfirm = false;
    })
  }

  initializatIonUser(){
    this.user = {
      id: "",
      token: "",
    };
  }

}
