import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import { MessagesService } from 'src/app/_services/message/messages.service';

@Component({
  selector: 'app-receiveemailmessage',
  templateUrl: './receiveemailmessage.component.html',
  styleUrls: ['./receiveemailmessage.component.css']
})
export class ReceiveemailmessageComponent implements OnInit {
  data: any;
  @ViewChild('editForm') editForm: NgForm; 
  formEdit: UntypedFormGroup;
  public Editor = ClassicEditor;

  constructor(public fb: UntypedFormBuilder, private service: MessagesService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.data = 1;
    this.initializeForm();
    this.onLoad();
  }

  onLoad(){
    this.service.getReceiveEmailMessage().subscribe(response => {
      this.data = response;
      this.formEdit.controls['changeText'].setValue(this.data.paymentReceiveEmailMessage);     
    }, error => {
      console.log(error);
    })
  }
  
  onSubmit(){
    const message = {
      PaymentReceiveEmailMessage: this.formEdit.controls['changeText'].value,
    }
 
    this.data.message = message.PaymentReceiveEmailMessage;
    this.service.updateReceiveEmaiLMessage(message).subscribe(response => {
      this.onLoadSettingMesaging();
      this.toastr.success("Updated Message!");
    }, error => {
     console.log(error); 
    })
  }

  initializeForm(){
    this.formEdit = this.fb.group({
      changeText:['', Validators.required],
    });  
  }

  public onReady( editor ) {
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );
  }

   generateText(){
    this.formEdit.controls['changeText'].setValue
    (
      "Dear [name],<br><br>" +
      "I hope this message finds you well. I am writing to express my sincere appreciation for the payment of [amountpaid] that you have made. Your timely and generous support is greatly valued." +
      " Thank you for choosing to work with me and for the trust that you have placed in me. I am committed to providing the highest level of service and am looking forward to our continued collaboration and to achieving great things together." +
      " <br><br>If you require any further information or assistance, please do not hesitate to contact us at [mybusinessemail] or [mybusinessphone]. We are always happy to help and provide any additional information that you may need. " +
      " Thank you once again for your support." +
      "<br><br>Yours sincerely," + 
      "<br>[mybusiness]" +
      "<br>[mybusinessemail]" +
      "<br>[mybusinessphone]"
    );
    this.formEdit.markAsDirty();
  }

  onLoadSettingMesaging(){
    this.service.getTextSettingMessaging().subscribe(response => {
      this.service.message = response;
      if (this.service.message == null){
        this.service.setMessageValues();
      }

      console.log(response);
    }, error => {
      console.log(error);
    })
  }



}
