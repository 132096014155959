import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessagesService } from 'src/app/_services/message/messages.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-changeinitialemailmessage',
  templateUrl: './changeinitialemailmessage.component.html',
  styleUrls: ['./changeinitialemailmessage.component.css']
})
export class ChangeinitialemailmessageComponent implements OnInit {
  data: any;
  formEdit: UntypedFormGroup;
  public Editor = ClassicEditor;

  constructor(public fb: UntypedFormBuilder, private service: MessagesService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.data = 1;
    this.initializeForm();
    this.onLoad();
  }

  
  initializeForm(){
    this.formEdit = this.fb.group({
      changeText:['', Validators.required],
    });  
  }

  public onReady( editor ) {
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );
  }

  onSubmit(){
    const message = {
      InitialEmailMessage: this.formEdit.controls['changeText'].value,
    }
 
    this.data.message = message.InitialEmailMessage;
    this.service.updateInitialEmailMessage(message).subscribe(response => {
      this.onLoadSettingMesaging();
      this.toastr.success("Updated Message!");
    }, error => {
     console.log(error); 
    })
  }

  onLoad(){
    this.service.getInitialEmaillMessage().subscribe(response => {
      this.data = response;
      this.formEdit.controls['changeText'].setValue(this.data.initialEmailMessage);     
    }, error => {
      console.log(error);
    })
  }

  generateText(){
    this.formEdit.controls['changeText'].setValue("Dear [name], <br><br> " +
    "I hope this email finds you well.I am writing to inform you that we have sent your bill from [mybusiness]. The invoice number is [invoicenumber] and the amount due is [dueamount], which is payable by [duedate]. <br><br> " +
    "To make a payment, please visit the Invoice Pager website and enter the following code: [code].  <br><br>[link] <br><br>" +
    "If you have any questions or concerns, please do not hesitate to contact us at [mybusinessemail] or [mybusinessphone].<br><br>" +
    "Thank you for your prompt attention to this matter. <br><br>" +
    "Yours sincerely,<br>" +
    "[mybusiness] <br>" +
    "Email: [mybusinessemail] <br> " +
    "Phone: [mybusinessphone]");
    this.formEdit.markAsDirty();
  }

  onLoadSettingMesaging(){
    this.service.getTextSettingMessaging().subscribe(response => {
      this.service.message = response;
      if (this.service.message == null){
        this.service.setMessageValues();
      }

      console.log(response);
    }, error => {
      console.log(error);
    })
  }

}
