import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HubConnection } from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectionSignalRHub } from 'src/app/_helper/connectionsignalrhub';
import { ClientAccountPayments } from 'src/app/_models/clientaccountpayments';
import { PaginatedResult } from 'src/app/_models/pagination';
import { User } from 'src/app/_models/user';
import { environment } from 'src/environments/environment';
import { HostpresenceService } from '../hubsignalr/hostpresence.service';

@Injectable({
  providedIn: 'root'
})
export class ClientaccountpaymentsService {
  private baseUrl =  environment.apiUrl;
  private paymentSource = new BehaviorSubject<ClientAccountPayments[]>([]);
  allPayments$ = this.paymentSource.asObservable();
  private hubConnection?: HubConnection;
  private huburl = environment.hubUrl;
  accountsDataSource$: MatTableDataSource<ClientAccountPayments> = new MatTableDataSource<ClientAccountPayments>();

  constructor(private http: HttpClient, private presenceHub: HostpresenceService) { }

  getClientAccountPayments(clientID: number){
    return this.http.get<ClientAccountPayments[]>(this.baseUrl + 'accountpayments/getallpaymentsforclient?clientID=' +
     clientID).pipe(
      map(response => {
        return response;
      })
    )
  }

  async createHubConnection(user: User, accountID: number,status: boolean) 
  {  
    if (ConnectionSignalRHub.CheckAccessHub(this.presenceHub.pageActive[7], status) === false)
      return;     

    this.hubConnection = ConnectionSignalRHub.GetConnectionHub(this.hubConnection, this.huburl, accountID, user, "allpaymentsoneclient");
    this.hubConnection.on('ReceiveAllOneClientpayments', payments => {
    if (payments !== null)
    {        
      console.log(payments);
      this.paymentSource.next(payments);
      this.accountsDataSource$.data = payments;
    }});
  }

  stopHubConnection(){
    if (this.hubConnection) {
      this.hubConnection?.stop();
    }
  }

  getArchiveAccountPayments(clientID: number){
    return this.http.get<ClientAccountPayments[]>(this.baseUrl + 'accountpayments/getallpaymentsnonachiveforclient?clientID=' +
     clientID).pipe(
      map(response => {
        return response;
      })
    )
  }
}
