import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectionSignalRHub } from 'src/app/_helper/connectionsignalrhub';
import { Invoice } from 'src/app/_models/invoice';
import { PaginatedResult } from 'src/app/_models/pagination';
import { User } from 'src/app/_models/user';
import { environment } from 'src/environments/environment';
import { HostpresenceService } from '../hubsignalr/hostpresence.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  baseUrl =  environment.apiUrl;
  paginatedResult: PaginatedResult<Invoice[]> = new PaginatedResult<Invoice[]>();
  public invoiceDataSource = new MatTableDataSource<Invoice>();
  private invoiceNumber = new BehaviorSubject<Invoice[]>([]);
  public invoicesNumber$ = this.invoiceNumber.asObservable();
  public showlegends: boolean = false;
  public showlegendsTxt: string = "Show Legends";  
  public lastInvoiceNumber: string = "";
  public invoiceNumberPlaceHolder: string = "Invoice_1";
  public showarchive: boolean = true;
  private invoicesSource = new BehaviorSubject<Invoice[]>([]);
  public invoices$ = this.invoicesSource.asObservable();
  private hubConnection?: HubConnection;
  private hubArchiveConnection?: HubConnection;
  private hubInvoiceNumberConnection?: HubConnection;
  huburl = environment.hubUrl;
  

  constructor(private http: HttpClient, private presenceHub: HostpresenceService, private toastr: ToastrService) { }

  createinvoice(invoicemodel: any){
    return  this.http.post(this.baseUrl + "invoices/createinvoice", invoicemodel).pipe(
      map((response: any) => {
        return response;
      })
    );    
  }    

  createCustominvoice(invoicemodel: any){
    return  this.http.post(this.baseUrl + "invoices/customcreateinvoice", invoicemodel).pipe(
      map((response: any) => {
        return response;
      })
    ); 
  }   

  resetInitialMessage(accountID: number){
    const account: any = {
      accountID: accountID
    };

    return  this.http.post(this.baseUrl + "invoices/resetinitialmessage", account).pipe(
      map((response: boolean) => {
        return response;
      })
    ); 
  }   

  async createHubConnection(user: User, accountID: number) 
  {      
    if (ConnectionSignalRHub.CheckAccessHub(this.presenceHub.pageActive[2], false) === false)
      return;

    this.hubConnection = ConnectionSignalRHub.GetConnectionHub(this.hubConnection, this.huburl, accountID, user, "invoice");

    this.hubConnection.on('ReceiveInvoiceAccounts', (invoices: Invoice[]) => {
      console.log(invoices);
      if (invoices !== null)
      { 
        this.CheckInvoiceSource(invoices, true);
      }
    })    
  }

  async createInvoiceNumberHubConnection (user: User)
  {      
    this.hubInvoiceNumberConnection = ConnectionSignalRHub.GetConnectionHub(this.hubConnection, this.huburl, null, user, "invoicenumber");
    this.hubInvoiceNumberConnection.on('ReceiveInvoiceNumberAccounts', (invoices: Invoice[]) => {
      if (invoices !== null)
      {         
        this.invoiceNumber.next(invoices);
        this.lastInvoiceNumber = invoices[invoices.length - 1].invoiceNumber;
        this.invoiceNumberPlaceHolder = this.RemoveCharactersInInvoiceNumber(this.lastInvoiceNumber);
      }
    })    
  }

  private CheckInvoiceSource(invoices: Invoice[], showArchive: boolean)
  {
    var invoiceLength: number = 0;
    const dataSourceLength: number = 0;
    invoices.forEach(invoice => {
      invoiceLength = invoiceLength + 1;
    });

    if (this.showarchive === showArchive)
    {
      return;
    } 
    else if (dataSourceLength === 0)
    {
      this.setInvoiceSource(invoices);  
    }    
    else if (invoiceLength === 0)
    {
      this.setInvoiceSource(invoices);
    }    
    else if (invoiceLength > dataSourceLength)
    {
      var invoice = invoices[invoiceLength - 1];      
      this.invoiceDataSource.data.push(invoice);
    }
    else if (invoiceLength < dataSourceLength)
    {
      var i: number = 0;
      var isItlastNumber: boolean = true;
      invoices.forEach(invoice => {              
        if (invoice.id != this.invoiceDataSource.data[i].id)
        {
          this.invoiceDataSource.data.splice(i, 1);
          isItlastNumber = false;          
        }
        i = i + 1;
      });
      
      if (isItlastNumber)
      {
        this.invoiceDataSource.data.splice(this.invoiceDataSource.data.length - 1, 1);
      }
    }
    else if (invoiceLength === dataSourceLength)
    {
      var i: number = 0;
      invoices.forEach(invoice => {
      if (invoice.accountPayment != this.invoiceDataSource.data[i].accountPayment)
      {
        this.setInvoiceSource(invoices);  
      }
      else if (invoice.totalAccountRefunded != this.invoiceDataSource.data[i].totalAccountRefunded)
      {
        this.setInvoiceSource(invoices);  
      }
      else if (invoice.totalPaidAccounts != this.invoiceDataSource.data[i].totalPaidAccounts)
      {
        this.setInvoiceSource(invoices);  
      }
      else if (invoice.comment != this.invoiceDataSource.data[i].comment)
      {
        this.setInvoiceSource(invoices);  
      }
      else if (invoice.totalStoreDebit != this.invoiceDataSource.data[i].totalStoreDebit)
      {
        this.setInvoiceSource(invoices);  
      }
      else if (invoice.totalStoreDebit != this.invoiceDataSource.data[i].totalStoreDebit)
      {
        this.setInvoiceSource(invoices);  
      }
      else if (invoice.dueDate != this.invoiceDataSource.data[i].dueDate)
      {
        this.setInvoiceSource(invoices);  
      }
      else if (invoice.hasDueDateMessageSent != this.invoiceDataSource.data[i].hasDueDateMessageSent)
      {
        this.setInvoiceSource(invoices);  
      }
      else if (invoice.hasReceiveMessageSent != this.invoiceDataSource.data[i].hasReceiveMessageSent)
      {
        this.setInvoiceSource(invoices);  
      }
      else if (invoice.initialDate != this.invoiceDataSource.data[i].initialDate)
      {
        this.setInvoiceSource(invoices);  
      }
      else if (invoice.invoiceAmount != this.invoiceDataSource.data[i].invoiceAmount)
      {
        this.setInvoiceSource(invoices);  
      }
      else if (invoice.invoiceNumber != this.invoiceDataSource.data[i].invoiceNumber)
      {
        this.setInvoiceSource(invoices);  
      }
      else if (invoice.paidAmount != this.invoiceDataSource.data[i].paidAmount)
      {
        this.setInvoiceSource(invoices);  
      }
      else if (invoice.sendInitialMessage != this.invoiceDataSource.data[i].sendInitialMessage)
      {
        this.setInvoiceSource(invoices);  
      }
      else if (invoice.totalStoreCredit != this.invoiceDataSource.data[i].totalStoreCredit)
      {
        this.setInvoiceSource(invoices);  
      }
      else if (invoice.archive != this.invoiceDataSource.data[i].archive)
      {
        this.setInvoiceSource(invoices);  
      }
      });
    }
  }

  async createArchiveHubConnection(user: User, accountID: number) 
  {      
    if (ConnectionSignalRHub.CheckAccessHub(this.presenceHub.pageActive[2], false) === false)
      return;

    this.hubArchiveConnection = ConnectionSignalRHub.GetConnectionHub(this.hubConnection, this.huburl, accountID, user, "invoicearchive");

    this.hubArchiveConnection.on('ReceiveArchiveInvoiceAccounts', (invoices: Invoice[]) => {
      if (invoices !== null)
      { 
        this.CheckInvoiceSource(invoices, false);
      }         
    })    
  }

  setInvoiceSource(invoices: Invoice[]){
    this.invoicesSource.next(invoices);
    this.invoiceDataSource.data = invoices;
  }

  stopHubConnection(){
    if (this.hubConnection) {
      this.hubConnection?.stop();
    }
  }

  stopArchiveHubConnection(){
    if (this.hubArchiveConnection) {
      this.hubArchiveConnection?.stop();
    }
  }

  stopInvoiceNumberConnection(){
    if (this.hubInvoiceNumberConnection) {
      this.hubInvoiceNumberConnection?.stop();
    }
  }

  getPrintInvoice(accountID?: string)
  {
    return this.http.get<any>(this.baseUrl + 'invoices/printinvoice?accountID=' + accountID).pipe(
      map(response => {
        return response.result;
      }));
  }

  getinvoices(accountID?: string){   
    return this.http.get<Invoice[]>(this.baseUrl + 'invoices/getinvoices?accountID=' + accountID).pipe(
      map(response => {
        if (response !== undefined)
        {
          this.getInvoiceChecking().subscribe(res => {
            console.log(res);
            //this.invoices = res;        
            //const sortInvoices = this.invoices.sort((a, b) => a.invoiceNumber.localeCompare(b.invoiceNumber));
            //if (this.invoices.length > 0)
           // {
           //   this.invoicesSource.next(response);
           //   this.lastInvoiceNumber = this.invoices[this.invoices.length - 1].invoiceNumber;
           // }
   
          }, err => {
            console.log(err);
          })
        }
        return response;
      })
    )
  }

  getInvoiceChecking(){
    return this.http.get<Invoice[]>(this.baseUrl + 'invoices/getallinvoicesforsort').pipe(
      map(invoices => {
        return invoices;
      })
    )
  }

  getinvoicesValidate (accountID: string): Promise<boolean>{ 
    return this.http.get<boolean>(this.baseUrl + 'invoices/getinvoicesFromValidation?accountID=' + accountID).toPromise();
  }

  getEditInvoice(accountID: number){
    return this.http.get<Invoice>(this.baseUrl + 'invoices/geteditinvoice?accountID=' + accountID).pipe(
      map(client => {
        return client;
      })
    )
  }

  
  getEditItemsInvoice(accountID: number){
    return this.http.get<any>(this.baseUrl + 'invoices/getcustomitemsforinvoice?accountID=' + accountID).pipe(
      map(items => {
        return items;
      })
    )
  }

  updateInvoice(invoice: Invoice){
    return this.http.put(this.baseUrl + 'invoices/updateeditinvoice', invoice).pipe(
      map((reponse: boolean) => {
        return reponse;
      })
    );
  }

  updateCustomInvoice(invoice: any){
    return this.http.put(this.baseUrl + 'invoices/customupdateinvoice', invoice).pipe(
      map((reponse: boolean) => {
        return reponse;
      })
    );
  }

  sendInitialMessage(data: any){
    return this.http.put(this.baseUrl + 'invoices/sendinitialmessage', data).pipe(
      map(() => {
        
      })
    );
  }

  deleteInvoice(accountID: number){
    return this.http.delete(this.baseUrl + 'invoices/deleteinvoice?accountID=' + accountID).pipe(
      map((query: boolean) => {       
        return query;
      })
    );
  }

  archiveInvoice(account: any){
    return this.http.put(this.baseUrl + 'invoices/archiveinvoice', account).pipe(
      map((query: boolean) => {
        return query;
      })
    );
  }

  getCheckCanEditInvoice(accountID: number, isaccountopening: boolean){
    return this.http.get<boolean>(this.baseUrl + 'invoices/Iseditactive?accountID=' + accountID + '&isaccountopening=' + isaccountopening).pipe(
      map((client => {
        return client;
      }))
    )
  }

  getCheckCanDeleteInvoice(accountID: number, isaccountopening: boolean){
    return this.http.get<boolean>(this.baseUrl + 'invoices/Iseditactive?accountID=' + accountID + '&isaccountopening=' + isaccountopening + '&isItDeleteFunction=true').pipe(
      map((client => {
        return client;
      }))
    )
  }

  getCheckCanArchiveInvoice(accountID: number, isaccountopening: boolean){
    return this.http.get<boolean>(this.baseUrl + 'invoices/Iseditactive?accountID=' + accountID + '&isaccountopening=' + isaccountopening + '&isItArchiveFunction=true').pipe(
      map((client => {
        return client;
      }))
    )
  }

  getCheckCanOpenInstalmentAccount(accountID: number, isaccountopening: boolean){
    return this.http.get<boolean>(this.baseUrl + 'invoices/canyouopenisntalmentaccounts?accountID=' + accountID + '&isaccountopening=' + isaccountopening).pipe(
      map(((account: boolean) => {
        return account;
      }))
    )
  }
  getCheckCanOpenPaymentAccount(accountID: number, isaccountopening: boolean){
    return this.http.get<boolean>(this.baseUrl + 'invoices/canyouopenpaymentaccounts?accountID=' + accountID + '&isaccountopening=' + isaccountopening).pipe(
      map(((account: boolean) => {
        return account;
      }))
    )
  }

  cancelCanEditInvoice(accountID: number){
    return this.http.get<boolean>(this.baseUrl + 'invoices/closeeditactive?accountID=' + accountID).pipe(
      map((client => {
        return client;
      }))
    )
  }

  onLoadStartUpGetLastInvoiceNumber() {
    return this.http.get<any>(this.baseUrl + 'invoices/getlastnumberfrominvoice').pipe(
      map(((account: any) => {
        return account;
      }))
    )
  }

  RemoveCharactersInInvoiceNumber(invoiceNumber: string): string
  {
    const indexLastNumber = invoiceNumber.length;
    console.log('lastindex ' + indexLastNumber);
    let number: number = 0;
    let startingIndexNumber: number = 0;
    for (let i = 0; i < indexLastNumber; i++)
    {
      const charLetter = invoiceNumber[i];
      if (Number(charLetter))
      {
        startingIndexNumber = i;
        break;
      }
    }

    if (startingIndexNumber === 0)
      return 'Invoice_1';

    const charactersInvoice = invoiceNumber.substring(0, startingIndexNumber); 
    let theRemainderWhole = invoiceNumber.substring(startingIndexNumber, indexLastNumber);
    if (Number(theRemainderWhole))
    {
      number = +theRemainderWhole + 1;
    }
    else
    {
      number = 1;
    }
    const newInvoiceNumber = charactersInvoice + number;
    return newInvoiceNumber.toString();    
  }
}
