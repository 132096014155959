import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorialshowtochecksentmessages',
  templateUrl: './tutorialshowtochecksentmessages.component.html',
  styleUrls: ['./tutorialshowtochecksentmessages.component.css']
})
export class TutorialshowtochecksentmessagesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
