import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TogglewindowService {
  public $Toggle: boolean[] = new Array(100).fill(true);
  public $ToggleText: string[] = new Array(100).fill("Minimize Window");
  public $Icon: string[] = new Array(100).fill("fa fa-window-minimize");

  constructor() { 
  }

  toggleWindow(value: number): void{
    this.$Toggle[value] = !this.$Toggle[value];
    if (this.$Toggle[value])
    {
      this.$ToggleText[value] = "Minimize Window";
      this.$Icon[value] = "fa fa-window-minimize";
    }
    else
    {
      this.$ToggleText[value] = "Maximize Window";
      this.$Icon[value] = "fa fa-window-maximize";
    }
  }
}
