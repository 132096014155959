import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { InstalmentService } from 'src/app/_services/instalment/instalment.service';

@Component({
  selector: 'app-archiveinstalment',
  templateUrl: './archiveinstalment.component.html',
  styleUrls: ['./archiveinstalment.component.css']
})
export class ArchiveinstalmentComponent implements OnInit {
  archive: boolean;
  invoiceAmount: number = 0;
  invoiceID: number;
  user?: User;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private instalmentService: InstalmentService, private accountService: AccountService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.archive = !this.data.archive;
    this.invoiceAmount = this.data.invoiceAmount;
    this.invoiceID = this.data.invoiceID;
    this.assignUser();
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  deleteAccount(archiveType: boolean){
    const archive = {
      accountID: this.data.id,
      archive: archiveType
    };

    this.instalmentService.archiveInstalment(archive).subscribe(response => {
      if (response)
      {
        this.instalmentService.stopHubConnection();
        this.instalmentService.stopHubArchiveConnection();
        this.instalmentService.createHubConnection(this.user, this.data.invoiceID);
        this.instalmentService.createHubArchiveConnection(this.user, this.data.invoiceID);
        this.ReloadCreateFormForTotalingCostInstalments();
        this.onLoadLastestDate();
        this.toastr.success("Account has been updated!");
      }
    }, err => {
      console.log(err);
    });
  }

  private ReloadCreateFormForTotalingCostInstalments(): void
  {
    this.instalmentService.gettotalcostforInstalments(this.invoiceID).subscribe(response => {
      const addCost = +response;
      if (addCost >= this.invoiceAmount)
      {
        this.instalmentService.showCreateFormSource.next(true);
        return;
      }
      this.instalmentService.showCreateFormSource.next(false);
    }, err => {
      console.log(err);
    })
  }

  private onLoadLastestDate(){
    this.instalmentService.getlastestdateinstalments(this.invoiceID).subscribe(response => {  
      this.instalmentService.lastestInstalmentDate = response;   
    }, error => {
      console.log(error);
    });    
  }

}
