import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { ClientAccountPayments } from 'src/app/_models/clientaccountpayments';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { ClientaccountpaymentsService } from 'src/app/_services/clientaccountpayments/clientaccountpayments.service';
import { HostpresenceService } from 'src/app/_services/hubsignalr/hostpresence.service';
import { TogglewindowService } from 'src/app/_services/togglewindow.service';

@Component({
  selector: 'app-clientaccountpayments',
  templateUrl: './clientaccountpayments.component.html',
  styleUrls: ['./clientaccountpayments.component.css']
})
export class ClientaccountpaymentsComponent implements AfterViewInit, OnDestroy  {
  @Input() usersFromCreateListComponet: any;
  clientID: string;
  displayedColumns: string[] = ['InvoiceNumber', 'InvoiceAmount', 'AmountPaid', 'DatePaid', 'PaymentOption', 'PaymentType', 'Actions'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  timeLeft: number = 1;
  interval;
  user?: User;
  showText: string = "Show Only Archive Accounts";
  titleText: string = "Cash Flow Income (Non-Archive)";
  toggleCashflowBtn: boolean = false;
  buttonClass = "btn btn-danger my-2 my-sm-0 font-weight-bold";
  accountsDataSource: MatTableDataSource<ClientAccountPayments> = new MatTableDataSource<ClientAccountPayments>();
  
  clientAccountPayments: ClientAccountPayments[];

  constructor(public clientAccountPaymentService: ClientaccountpaymentsService, private route: ActivatedRoute, public toggle: TogglewindowService
    , public accountService: AccountService, private toastr: ToastrService, private presenceHub: HostpresenceService) { 
    this.getParrams();

  }
  ngOnDestroy(): void {
    this.presenceHub.pageActive[7] = false;
    this.clientAccountPaymentService.stopHubConnection();
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
    this.assignUser();
    this.presenceHub.pageActive[7] = true;
    this.loadMembers();
    this.toastr.info("Please wait! We are getting your records now!");
  }

  assignUser(){
    this.accountService.currentUser$.pipe(take(1)).subscribe({
      next: user => {
        if (user) this.user = user;
      }
    });
  }

  accounts: any = [];
  paymentsDataSource: MatTableDataSource<ClientAccountPayments>;


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.clientAccountPaymentService.accountsDataSource$.filter = filterValue.trim().toLowerCase();

    if (this.clientAccountPaymentService.accountsDataSource$.paginator) {
      this.clientAccountPaymentService.accountsDataSource$.paginator.firstPage();
    }
  }


  loadMembers(){
    var id = Number(this.clientID);
    this.clientAccountPaymentService.stopHubConnection();
    this.clientAccountPaymentService.createHubConnection(this.user, id, false);
    this.startTimer();
  }

  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.clientID = params.get("id");
    });
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.clientAccountPaymentService.accountsDataSource$.paginator = this.paginator;
        this.clientAccountPaymentService.accountsDataSource$.sort = this.sort;
        this.timeLeft = 3;
        this.presenceHub.pageActive[7] = true;
      }
    },1000)
  }

  
  toggleButtonPayments(){
    this.toggleCashflowBtn = !this.toggleCashflowBtn;
    this.toastr.info("Please wait! We are getting your records now!");

    if (this.toggleCashflowBtn)
    {
      this.titleText = "List of All Payments from One Client (Archive Only)";
      this.showText = "Show All Accounts except for Archive Accounts";
      this.buttonClass = "btn btn-success my-2 my-sm-0 font-weight-bold";
      this.showOnlyArchives();
    }
    else
    {
      this.titleText = "List of All Payments from One Client (Non Archive Accounts)";
      this.showText = "Show Archive Accounts Only";
      this.buttonClass = "btn btn-danger my-2 my-sm-0 font-weight-bold";
      this.loadMembers();
    }
  }

  private showOnlyArchives(){
    var id = Number(this.clientID);
    this.clientAccountPaymentService.getArchiveAccountPayments(id).subscribe(response => {
      console.log(response);
      if (response != null)
      {
        this.accountsDataSource.data = response;
      }

    }, err => {
      console.log(err);
    })
  }



}
