import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HubConnection } from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectionSignalRHub } from 'src/app/_helper/connectionsignalrhub';
import { AccountsReceivablespayments } from 'src/app/_models/accountsreceivablespayments';
import { PaginatedResult } from 'src/app/_models/pagination';
import { User } from 'src/app/_models/user';
import { environment } from 'src/environments/environment';
import { HostpresenceService } from '../hubsignalr/hostpresence.service';

@Injectable({
  providedIn: 'root'
})
export class ReceivableaccountspaymentsService {
  baseUrl =  environment.apiUrl;
  paginatedResult: PaginatedResult<AccountsReceivablespayments[]> = new PaginatedResult<AccountsReceivablespayments[]>();

  private paymentSource = new BehaviorSubject<AccountsReceivablespayments[]>([]);
  allPayments$ = this.paymentSource.asObservable();
  private hubConnection?: HubConnection;
  private huburl = environment.hubUrl;
  accountsDataSource$: MatTableDataSource<AccountsReceivablespayments> = new MatTableDataSource<AccountsReceivablespayments>();

  constructor(private http: HttpClient, private presenceHub: HostpresenceService) { }

  getReceivablesPayments(){
    return this.http.get<AccountsReceivablespayments[]>(this.baseUrl + 'accountspayablesandreceivablespayments/getpayments').pipe(
      map(response => {
        return response;
      })
    )
  }

  async createHubConnection(user: User, global: boolean) 
  {  
    const accountID: number = 0;

    if (ConnectionSignalRHub.CheckAccessHub(this.presenceHub.pageActive[6], global) === false)
      return;

    this.hubConnection = ConnectionSignalRHub.GetConnectionHub(this.hubConnection, this.huburl, accountID, user, "allpayments");

    this.hubConnection.on('ReceiveAllPayments', (payments: AccountsReceivablespayments[]) => {
      if (payments !== null)
      {      
        if (this.accountsDataSource$.data.length != payments.length)
        {
          this.SetPaymentAccount(payments);
        }
        else
        {
          var index: number = 0;
          payments.forEach(payment => {
            if (this.accountsDataSource$.data[index].accountPayment != payment.accountPayment)
            {
              this.SetPaymentAccount(payments);
            }
            else if (this.accountsDataSource$.data[index].date != payment.date)
            {
              this.SetPaymentAccount(payments);
            }
            else if (this.accountsDataSource$.data[index].date != payment.date)
            {
              this.SetPaymentAccount(payments);
            }
            else if (this.accountsDataSource$.data[index].firstName != payment.firstName)
            {
              this.SetPaymentAccount(payments);
            }
            else if (this.accountsDataSource$.data[index].lastName != payment.lastName)
            {
              this.SetPaymentAccount(payments);
            }
            else if (this.accountsDataSource$.data[index].invoiceNumber != payment.invoiceNumber)
            {
              this.SetPaymentAccount(payments);
            }
            else if (this.accountsDataSource$.data[index].paymentOption != payment.paymentOption)
            {
              this.SetPaymentAccount(payments);
            }
            else if (this.accountsDataSource$.data[index].paymentType != payment.paymentType)
            {
              this.SetPaymentAccount(payments);
            }
          });
        }   
      }         
    });    
  }

  SetPaymentAccount(payments: AccountsReceivablespayments[]){
    this.paymentSource.next(payments);
    this.accountsDataSource$.data = payments;  
  }

  stopHubConnection(){
    if (this.hubConnection) {
      this.hubConnection?.stop();
    }
  }

  getArchiveAccounts(){
    return this.http.get<AccountsReceivablespayments[]>(this.baseUrl + 'accountspayablesandreceivablespayments/getarchiveaccountsonlypayments').pipe(
      map(response => {
        return response;
      })
    )
  }
}
