<mat-dialog-content>
    <h4 class="bg-danger text-white text-center">All items associated with this account will be deleted!</h4>
    <h4 class="text-center">You have chosen <i>"I've already set up the account within the accounting software, saved the file or stored it elsewhere"</i> Are you sure you want to <b>DELETE</b> all items from this invoice account?</h4>    
<div class="row mt-4">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
        <button type="button" class="btn btn-danger btn-block" mat-button [mat-dialog-close]="data" (click)="updateEdit()"><i class='fas fa-trash'></i> Update Invoice & then Remove All items to this Invoice Account</button>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
        <button type="button" class="btn btn-primary btn-block" mat-button mat-dialog-close><i class='fas fa-close'></i> Cancel</button>
    </div>
</div>
</mat-dialog-content>