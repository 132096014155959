<nav class="navbar navbar-light bg-primary shadow-lg">
  <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Edit Account Payment</a>
</nav>
<mat-dialog-content>
<form #editForm="ngForm" id="editForm" [formGroup]="formAccount" (ngSubmit)="formAccount.valid" autocomplete="off">
  <div class="bs-component">
    <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
      <button type="button" class="close" data-dismiss="alert">×</button>
      <h4 class="alert-heading">Information!</h4>
      <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
      <fieldset class="form-group">
        <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-credit-card"></i> Payment Details:</h4>
        <div class="form-check">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" name="paymentoptionsRadios" [formControl]='formAccount.controls["option"]' value="accountreceivable" (change)="showPaymentForm(false)">
            <b class="text-success">Account Paid:</b><small> Account that's paid is done exchange for money</small>
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" name="paymentoptionsRadios" [formControl]='formAccount.controls["option"]' value="accountreturn" (change)="showPaymentForm(false)">
            <b class="text-danger">Accounts Refunded:</b> <small> Pack back (money), tpicall to a customer who is not satisfied with goods or services bought.</small>
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" name="paymentoptionsRadios" [formControl]='formAccount.controls["option"]' value="credit" (change)="showPaymentForm(true)">
            <b class="text-primary">Store Credits:</b> <small> A store credit is a document offered by a store to a store to a customer who returns an item not eligible for a refund.</small>
          </label>
        </div>
      </fieldset>
      
      <app-text-input [formControl]='formAccount.controls["accountmoney"]' [label]='"*Amount"' [smalltext]='""' [placeholder]='"Amount"' [type]='"number"'> </app-text-input>
      <app-date-input [formControl]='formAccount.controls["date"]' [label]='"*Date"' [smalltext]='""' [placeholder]='"Date"'> </app-date-input>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xm-12">
      <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-credit-card"></i> Note Information:</h4>
      <app-area-text [formControl]='formAccount.controls["description"]' [smalltext]='""' [label]='"Note"' [placeholder]='"Note"'></app-area-text>
      <div class="form-group" [hidden]="hidePaymentType">
        <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-credit-card"></i> Payment Type:</h4>
        <label>Payment Type</label>
        <select class="form-control" [formControl]='formAccount.controls["paymenttype"]'>
          <option value="Cash" selected>Cash</option>
          <option value="Cheque" >Cheque</option>
          <option value="CreditCard">Credit Card</option>
          <option value="CryptoCurrency">Cryptocurrency</option>
          <option value="DebitCard">Debit Card</option>
          <option value="EFTPOS">EFTPOS</option>
          <option value="ElectronicBankTransfer">Electronic Bank Transfer</option>
          <option value="MobilePayment">Mobile Payment</option>
          <option value="OnlinePayment">Online Payment</option>
        </select>
      </div>  
    </div>
  </div>
</form>
<div class="row">
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
    <button [disabled]="!formAccount.valid" mat-button [mat-dialog-close]="data"  class="btn btn-success btn-block btn-lg" form="editForm" (click)="onSubmit()"><i class='fas fa-check'></i>  Confirm Payment Account</button>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
    <button class="btn btn-danger btn-block btn-lg mt-4 mt-md-0" mat-button mat-dialog-close><i class='fas fa-close'></i> Cancel</button>
  </div>
</div>
</mat-dialog-content>