
<nav class="navbar navbar-light bg-primary shadow-lg">
  <a class="navbar-brand text-white font-weight-bold" ><i class="fa fa-users fa-2x" aria-hidden="true"></i> Edit Client Account</a>
</nav>
<mat-dialog-content>
<form #editForm="ngForm" id="editForm" [formGroup]="formClient" (ngSubmit)="formClient.valid" autocomplete="off">
<div class="bs-component">
    <div class="alert alert-dismissible alert-warning" *ngIf="editForm.dirty">
    <button type="button" class="close" data-dismiss="alert">x</button>
    <h4 class="alert-heading">Information!</h4>
    <p class="mb-0">You have made changes. Any unsaved changes will be lost!</p>
    </div>
</div>
<div class="row mt-2">
    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xm-12">
      <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> A business or person's details (required):</h4>
      <app-text-input [formControl]='formClient.controls["name"]' [label]='"*Name"' [smalltext]='""' [placeholder]='"A business or persons name"'> </app-text-input>
      <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> Personal Details:</h4>
      <app-text-input [formControl]='formClient.controls["firstname"]' [label]='"First Name"' [smalltext]='""' [placeholder]='"First Name"'> </app-text-input>
      <app-text-input [formControl]='formClient.controls["lastname"]' [label]='"Last Name"' [smalltext]='""' [placeholder]='"Last Name"'> </app-text-input>
      <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> Personal Contact Details:</h4>
      <app-text-input [formControl]='formClient.controls["contactmobile"]' [type]='"number"' [label]='"Contact Mobile (Use for Sending sms)"' [smalltext]='""' [placeholder]='"Contact Mobile"' [classInput]='"form-label fw-bold text-primary"'></app-text-input>
      <app-text-input [formControl]='formClient.controls["email"]' [label]='"Email (Use for Sending Emails)"' [smalltext]='""' [placeholder]='"Email"'  [classInput]='"form-label fw-bold text-primary"'> </app-text-input>    
      <app-text-input [formControl]='formClient.controls["contacthomephone"]' [label]='"Contact Other"' [type]='"number"'  [smalltext]='""' [placeholder]='"Contact Other"'> </app-text-input>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xm-12">     
        <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> Address Details:</h4>
        <app-text-input [formControl]='formClient.controls["address"]' [label]='"Address"' [smalltext]='""' [placeholder]='"Street address, P.O box"'> </app-text-input>
        <app-text-input [formControl]='formClient.controls["addressunit"]' [label]='"Unit"' [smalltext]='""' [placeholder]='"Apt, Suite, Unit, Building, Floor"'> </app-text-input>  
        <app-text-input [formControl]='formClient.controls["postcode"]' [label]='"Postcode"' [type]='"number"' [smalltext]='""' [placeholder]='"Postcode"'> </app-text-input>  
        <app-text-input [formControl]='formClient.controls["province"]' [label]='"City/Suburb"' [smalltext]='""' [placeholder]='"City, Suburb, Town, Village"'> </app-text-input>  
        <app-text-input [formControl]='formClient.controls["state"]' [label]='"State/Territory"' [smalltext]='""' [placeholder]='"State / Territory"'> </app-text-input>       
        <app-text-input [formControl]='formClient.controls["country"]' [label]='"Country"' [smalltext]='""' [placeholder]='"Country"'> </app-text-input>       
    </div>

    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xm-12">
        <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> Business Details:</h4>
        <app-text-input [formControl]='formClient.controls["bussinessname"]' [label]='"Business Name"' [smalltext]='""' [placeholder]='"Business Name"'></app-text-input>
        <app-text-input [formControl]='formClient.controls["contactbussinessnumber"]' [label]='"Contact Business Number"' [type]='"number"'  [smalltext]='""' [placeholder]='"Contact Business Number"'> </app-text-input>
        <app-text-input [formControl]='formClient.controls["abn"]' [label]='"ABN"' [smalltext]='""' [type]='"number"'  [placeholder]='"ABN"'> </app-text-input>                
        <fieldset class="form-group">
          <h4 class="card-title bg-dark text-white text-center"><i class="fas fa-user"></i> *Accept Approval Credit Rating (required):</h4>
          <div class="form-check">
            <label class="form-check-label">
              <input type="radio" class="form-check-input" name="RatingRadioOptions" [formControl]='formClient.controls["creditRatingApproved"]' value="true">
              Accept
            </label>
          </div>
          <div class="form-check">
            <label class="form-check-label">
              <input type="radio" class="form-check-input" name="RatingRadioOptions" [formControl]='formClient.controls["creditRatingApproved"]' value="false">
              Decline
            </label>
          </div>
      </fieldset>          
    </div>  
</div>
</form>

<div class="row">
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
      <button [disabled]="!formClient.valid" mat-button [mat-dialog-close]="data" class="btn btn-success btn-block btn-lg " form="editForm" (click)="onSubmit()"><i class='fas fa-check'></i>  Confirm Edit Account</button>
  </div>
  <div class="col-6 col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-0">
      <button class="btn btn-danger btn-block btn-lg" mat-button mat-dialog-close><i class='fas fa-close'></i>  Close</button>
  </div>
</div>  
</mat-dialog-content>           
