<body *ngIf="invoicePrint$ | async as invoice">
    <div id="editor" class="edit-mode-wrap" style="margin-top: none;">
        <style type="text/css">
        .is_logo {display:none;}
        </style><style type="text/css">* { margin:0; padding:0; }
body { background:#fff; font-family:Arial, Helvetica, sans-serif; font-size:12px; line-height:20px; }
#extra {text-align: right; font-size: 22px;  font-weight: 700}
.invoice-wrap { width: none; margin:0 auto; background:#FFF; color:#000 }
.invoice-inner { margin:0 15px; padding:20px 0 }
.invoice-address { border-top: 3px double #000000; margin: 25px 0; padding-top: 25px; }
.bussines-name { font-size:18px; font-weight:100 }
.invoice-name { font-size:22px; font-weight:700 }
.listing-table th { background-color: #e5e5e5; border-bottom: 1px solid #555555; border-top: 1px solid #555555; font-weight: bold; text-align:left; padding:6px 4px }
.listing-table td { border-bottom: 1px solid #555555; text-align:left; padding:5px 6px; vertical-align:top }
.total-table td { border-left: 1px solid #555555; }
.total-row { background-color: #e5e5e5; border-bottom: 1px solid #555555; border-top: 1px solid #555555; font-weight: bold; }
.row-items { margin:5px 0; display:block }
.notes-block { margin:50px 0 0 0 }
/*tables*/
table td { vertical-align:top}
.items-table { border:1px solid 1px solid #555555; border-collapse:collapse; width:100%}
.items-table td, .items-table th { border:1px solid #555555; padding:4px 5px ; text-align:left}
.items-table th { background:#f5f5f5;}
.totals-row .wide-cell { border:1px solid #fff; border-right:1px solid #555555; border-top:1px solid #555555}
</style>
<div class="invoice-wrap">
<div class="invoice-inner">
<table border="0" cellpadding="0" cellspacing="0" width="100%">
<tbody>
    <tr>
        <td align="left" class="is_logo" valign="top"><img class="editable-area" height="102" id="logo" src="/img/uploads/logos/default-logo.png" width="122"></td>
        <td align="right" valign="top">
        <div class="business_info">
        <table border="0" cellpadding="0" cellspacing="0" width="100%">
            <tbody>
                <tr>
                    <td><span class="editable-area" id="business_info"><p style="font-size: 14pt;">{{invoice.businessName}}</p>
ABN: <ng-container *ngIf="invoice.abn !== ''" >{{invoice.abn}}</ng-container><br>
{{invoice.businessAddress}}<br>
{{invoice.city}}, {{invoice.state}} <ng-container *ngIf="invoice.postcode != 0">{{invoice.postcode}} </ng-container>
<br>
{{invoice.businessPhoneNumber}}<br>
{{invoice.businessEmail}}<br>
{{invoice.website}}
</span></td>
                </tr>
            </tbody>
        </table>
        </div>
        </td>
        <td align="right" valign="top">
        <p class="editable-text" id="extra"><span style="font-size: 18pt;">{{title}}</span></p>
        </td>
    </tr>
</tbody>
</table>

<div class="invoice-address">
<table border="0" cellpadding="0" cellspacing="0" width="100%">
<tbody>
    <tr>
        <td align="left" valign="top" width="50%">
        <table border="0" cellpadding="0" cellspacing="0">
            <tbody>
                <tr>
                    <td style="" valign="top" width=""><strong><span class="editable-text" id="label_bill_to">Bill To</span></strong></td>
                    <td valign="top">
                    <div class="client_info">
                    <table border="0" cellpadding="0" cellspacing="0">
                        <tbody>
                            <tr>
                                <td style="padding-left:25px;"><span class="editable-area" id="client_info">{{invoice.clientName}}<br>
{{invoice.clientAddress}}<br>
{{invoice.clientCity}}, {{invoice.clientState}} {{invoice.clientPostCode}}</span></td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                    </td>
                </tr>
            </tbody>
        </table>
        </td>
        <td align="right" valign="top" width="50%">
        <table border="0" cellpadding="0" cellspacing="0">
            <tbody>
                <tr>
                    <td align="right"><strong><span class="editable-text" id="label_invoice_no">Invoice Number</span></strong></td>
                    <td align="left" style="padding-left:20px"><span class="editable-text" id="no">{{invoice.invoiceNumber}}</span></td>
                </tr>
                <tr>
                    <td align="right"><strong><span class="editable-text" id="label_date">Initial Date Created</span></strong></td>
                    <td align="left" style="padding-left:20px"><span class="editable-text" id="date">{{invoice.initialDate | date:'dd/MM/yyyy'}}</span></td>
                </tr>
                <!-- Fieldl-->
                <tr class="field1_row">
                    <td align="right"><strong><span class="editable-text" id="label_field1">Due Date</span></strong></td>
                    <td align="left" style="padding-left:20px;"><span class="editable-text" id="field1_value">{{invoice.dueDate | date:'dd/MM/yyyy'}}</span></td>
                </tr>
                <!-- /Fieldl--><!-- Field2-->
                <tr class="field2_row">
                    <td align="right"><strong><span class="editable-text" id="label_field2"></span></strong></td>
                    <td align="left" style="padding-left:20px;"><span class="editable-text" id="field2_value"></span></td>
                </tr>
                <!-- /Field2--><!-- Field3-->
                <tr class="field3_row">
                    <td align="right"><strong><span class="editable-text" id="label_field3"></span></strong></td>
                    <td align="left" style="padding-left:20px;"><span class="editable-text" id="field3_value"></span></td>
                </tr>
                <!-- /Field3--><!-- Field4-->
                <tr class="field4_row">
                    <td align="right"><strong><span class="editable-text" id="label_field4"></span></strong></td>
                    <td align="left" style="padding-left:20px;"><span class="editable-text" id="field4_value"></span></td>
                </tr>
                <!-- /Field4--><!-- Field5-->
                <tr class="field5_row">
                    <td align="right"><strong><span class="editable-text" id="label_field5"></span></strong></td>
                    <td align="left" style="padding-left:20px;"><span class="editable-text" id="field5_value"></span></td>
                </tr>
                <!-- /Field5--><!-- Field6-->
                <tr class="field6_row">
                    <td align="right"><strong><span class="editable-text" id="label_field6"></span></strong></td>
                    <td align="left" style="padding-left:20px;"><span class="editable-text" id="field6_value"></span></td>
                </tr>
                <!-- /Field6--><!-- Field7-->
                <tr class="field7_row">
                    <td align="right"><strong><span class="editable-text" id="label_field7"></span></strong></td>
                    <td align="left" style="padding-left:20px;"><span class="editable-text" id="field7_value"></span></td>
                </tr>
                <!-- /Field7--><!-- Field8-->
                <tr class="field8_row">
                    <td align="right"><strong><span class="editable-text" id="label_field8"></span></strong></td>
                    <td align="left" style="padding-left:20px;"><span class="editable-text" id="field8_value"></span></td>
                </tr>
                <!-- /Field8--><!-- Field9-->
                <tr class="field9_row">
                    <td align="right"><strong><span class="editable-text" id="label_field9"></span></strong></td>
                    <td align="left" style="padding-left:20px;"><span class="editable-text" id="field9_value"></span></td>
                </tr>
                <!-- /Field9--><!-- Field10-->
                <tr class="field10_row">
                    <td align="right"><strong><span class="editable-text" id="label_field10"></span></strong></td>
                    <td align="left" style="padding-left:20px;"><span class="editable-text" id="field9_value"></span></td>
                </tr>
                <!-- /Field9-->
            </tbody>
        </table>
        </td>
    </tr>
</tbody>
</table>
</div>

<div id="items-list"><table class="table table-bordered table-condensed table-striped items-table">
<thead>
    <tr>
        <th>Description</th>
        <th>Quantity</th>
        <th>Unit price</th>
        <th width="100">Amount</th>
    </tr>
</thead>
<tfoot>
    <ng-container *ngIf="invoice.tax > 0; else showtoalAmountNonGST">
        <tr class="totals-row">
            <td colspan="2" class="wide-cell"></td>
            <td><strong>Total without  GST</strong></td>
            <td coslpan="2">{{invoice.totalAmountWithoutGST | currency}}</td>
        </tr>
                        <tr class="totals-row">
                <td colspan="2" class="wide-cell"></td>
                <td><strong>GST</strong></td>
                <td coslpan="2">{{invoice.tax | currency}}</td>
            </tr>
                        <tr class="totals-row">
            <td colspan="2" class="wide-cell"></td>
            <td><strong>Total with GST</strong></td>
            <td coslpan="2">{{invoice.totalAmountWithGST | currency}}</td>
        </tr>
    </ng-container>
    <ng-template #showtoalAmountNonGST>
        <tr class="totals-row">
            <td colspan="2" class="wide-cell"></td>
            <td><strong>Total Amount</strong></td>
            <td coslpan="2">{{invoice.totalAmountWithoutGST | currency}}</td>
        </tr>
    </ng-template>
            
</tfoot>
<ng-container *ngFor="let item of invoice.invoiceItemsDto">
    <tbody>
       <tr>
        <td>{{item.description}}</td>
        <td>{{item.qty}}</td>
        <td>{{item.unitPrice | currency }}</td>
        <td>{{item.qty * item.unitPrice | currency }}</td>
       </tr>
    </tbody>
</ng-container>

</table></div>

<div class="notes-block">
<table cellpadding="0" cellspacing="0" width="100%">
<tbody>
    <tr>
        <td>
        <div class="editable-area" id="notes">
            <p class="font-weight-bold">Please Pay:</p>
            <p>Account Name: {{invoice.accountName}}</p>
            <p>Account BSB: {{invoice.bsb}}</p>
            <p>Account Number: {{invoice.accountNumber}}</p>
            <p>PayID: {{invoice.payID}}</p>
            <br>
            <p class="font-weight-bold">Thank you for your business!</p>
        </div>
        </td>
    </tr>
</tbody>
</table>
</div>
<br>
<br>
<br>
<br>
&nbsp;</div>
</div>
    </div>
<style>
body {
background: #EBEBEB;
}
.invoice-wrap {box-shadow: 0 0 4px rgba(0, 0, 0, 0.1); margin-bottom: 20px; }
#mobile-preview-close a {
position:fixed; left:20px; bottom:30px; 
background-color: #27c24c;
font-weight: 600;
outline: 0 !important;
line-height: 1.5;
border-radius: 3px;
font-size: 14px;
padding: 7px 10px;
border:1px solid #27c24c;
text-decoration:none;
}
#mobile-preview-close img {
width:20px;
height:auto;
}
#mobile-preview-close a:nth-child(2) {
left:190px;
background:#f5f5f5;
border:1px solid #9f9f9f;
color:#555 !important;
}
#mobile-preview-close a:nth-child(2) img {
height: auto;
position: relative;
top: 2px;
}
.invoice-wrap {padding: 20px;}


@media print {
#mobile-preview-close a {
display:none
}

body {
background: none;
}
.invoice-wrap {box-shadow: none; margin-bottom: 0px;}

}
</style>
<div class="container-fluid" *ngIf="!hideButton">
    <div class="row mt-4">
        <div class="col-4">
         
        </div>
        <div class="col">      
            <button type="button" class="btn btn-primary btn-block btn-lg mt-4" (click)="printInvoice()"><i class="fas fa-print"></i> Print Invoice</button>
        </div>
        <div class="col-4">   

        </div>
    </div>
</div>
</body>
<div class="container-fluid" *ngIf="error">
    <div class="row mt-4">
        <div class="col-2">

        </div>
        <div class="col">
            <div class="bs-component">
                <div class="alert alert-dismissible alert-warning">
                  <h4 class="alert-heading ms-2">Sorry!</h4>
                  <p class="mb-0 ms-2">You can't view this invoice. You must create a custom made invoice in order to view this invoice account.</p>
                </div>
                <button type="button" class="btn btn-outline-primary btn-block btn-lg mt-4" (click)="backClicked()">Go Back</button>
            </div>
        </div>
        <div class="col-2">   

        </div>
    </div>
</div>
