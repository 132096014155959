import { FormControl, ValidationErrors } from '@angular/forms';

export class DateValidatorTime {

   static DateInvalidTime(control: FormControl): ValidationErrors | null {
        let today : Date = new Date();
        today.setDate(today.getDate() - 1);

        if (new Date(control.value) < today)
           return { "dateinvalidtime": true };

        return null;
   }
}