import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToggleinvoicebuttonService {
  public $showText: string = "";
  public $titleInvoice: string = "";
  public $toggleInvoicesBtn: boolean = false;

  constructor() { }
}
