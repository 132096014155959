<app-nav></app-nav>
<section class="pt-4 mt-4">
    <div class="container px-lg-5">
        <!-- Page Features-->
        <div class="row gx-lg-5">
            <div class="col-lg-2 col-md-2 col-sm-12 col-xxl-12 mb-5">
            
            </div>  
            <div class="col-lg-8 col-md-8 col-sm-12 col-xxl-12 mb-5">
                <div class="card bg-white h-100 shadow-lg">
                    <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                        <img src="/assets/images/7176685.jpg" alt="SMS" class="img-thumbnail">
                        <h2 class="fs-4 fw-bold">What is Later Pay?</h2>
                        <p class="mb-0">
                            Introducing of Pay Later, a feature that allows you to purchase now and pay at a later date. With Pay Later, you can enjoy the convenience of shopping without worrying about immediate payment. Our flexible payment options allow you to spread the cost of your purchase over time, making it easier to manage your finances. Try Pay Later today and experience the freedom of shopping on your terms!
                        </p>
                    </div>
                </div>
            </div>           
            <div class="col-lg-2 col-md-2 col-sm-12 col-xxl-12 mb-5">
               
            </div>   
        </div>
    </div>
</section>