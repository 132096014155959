import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Member } from 'src/app/_models/member';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account/account.service';
import { MembersService } from 'src/app/_services/account/members.service';
import { PasswordValidator } from 'src/app/_validators/passwordvalidator';

@Component({
  selector: 'app-editchangepassword',
  templateUrl: './editchangepassword.component.html',
  styles: [
  ]
})
export class EditchangepasswordComponent implements OnInit {
  @ViewChild('editForm') editForm: NgForm;
  memberForm: UntypedFormGroup;
  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any){
    if (this.editForm.dirty){
      $event.returnValue = true;
    }
  }
  
  constructor(private accountService: AccountService, private memberService: MembersService, private toastr: ToastrService,
    public fb: UntypedFormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(){
    this.memberForm = this.fb.group({
      oldpassword: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8), PasswordValidator.PasswordValidated]],
      confirmpassword: ['', [Validators.required, PasswordValidator.matchValues('password')]],
    });
  }

  updatePassword(){
    this.accountService.updatepassword(this.memberForm.value).subscribe(response => {
      this.toastr.success("Updated Account Password!");
      this.router.navigate(['/members/editdetailsacount']);
    }, err => {
      console.log(err);
    })
  }

}
