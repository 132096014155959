import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../_services/account/account.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  formCode: UntypedFormGroup;
  username: string;
  constructor(public fb: UntypedFormBuilder, private service: AccountService, private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getParrams();
    this.initializeForm();
  }

  submitForm(){
    const code = {
      code: this.formCode.value.code,
      username: this.formCode.value.email,
      password: this.formCode.value.password
    };

    this.service.login2fa(code).subscribe(response => {
      this.router.navigateByUrl('/members');
    }, err => {
      console.log(err);
    })

  }

  initializeForm(){
    this.formCode = this.fb.group({
      code:['', [Validators.maxLength(255), Validators.required]],
      email:['', [Validators.maxLength(255), Validators.email, Validators.required]],
      password:['', [Validators.maxLength(255), Validators.required]],
    })
  }

  
  getParrams(){
    this.route.paramMap.subscribe(params => {
      this.username = params.get("id");
    });
  }

}
