<mat-dialog-content>
<div class="table-responsive">
    <table class="table table-hover mt-4 table-bordered table-sm">
        <thead>
        <tr class="table-primary">
            <th scope="col" style="width: 10%;"><b>Date Sent</b></th>
            <th scope="col" style="width: 10%;"><b>Message Type</b></th>
            <th scope="col" style="width: 3%;"><b>Receivement</b></th>
            <th scope="col" style="width: 50%;"><b>Message</b></th>
        </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let message of messageSent">
                <tr>
                    <td>{{message.dateSent | date }}</td>
                    <td *ngIf="message.typeMessage===0">Receivable </td>
                    <td *ngIf="message.typeMessage===1">Due Payment</td>
                    <td *ngIf="message.typeMessage===2">Payment Receive</td>
                    <td *ngIf="message.receiveType===0" style='font-size:20px; color: blue'><i class="fa fa-envelope"></i></td>
                    <td *ngIf="message.receiveType===1" style='font-size:36px; color: green;' ><i class="fa fa-mobile"></i></td>
                    <td *ngIf="message.receiveType===2" style='font-size:36px; color: green;' ><i class="fa fa-mobile"></i></td>
                    <td [innerHtml]>{{message.message }}</td>
                </tr>
            </ng-container>
        </tbody>
    </table>

    <div class="d-flex justify-content-center" *ngIf="pagination">
        <pagination 
            [boundaryLinks]="true"
            [totalItems]="pagination.totalItems"
            [itemsPerPage]="pagination.itemsPerPage"
            [(ngModel)]="pagination.currentPage"
            (pageChanged)="pageChanged($event)"
            previousText="&lsaquo;" 
            nextText="&rsaquo;" 
            firstText="&laquo;" 
            lastText="&raquo;">
        </pagination>
    </div>
</div>
</mat-dialog-content>