import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MessageSetting } from 'src/app/_models/messagesetting';
import { SettingsTimeManagement } from 'src/app/_models/settingstimemanagement';
import { MessagesService } from 'src/app/_services/message/messages.service';
import { ChangeduedatetimeComponent } from './changeduedatetime/changeduedatetime.component';
import { ChangeinitialemailmessageComponent } from './changeemailtext/changeinitialemailmessage/changeinitialemailmessage.component';
import { DuedateemailmessageComponent } from './changeemailtext/duedateemailmessage/duedateemailmessage.component';
import { ReceiveemailmessageComponent } from './changeemailtext/receiveemailmessage/receiveemailmessage.component';
import { ChangeinitialsmsmessageComponent } from './changeinitialsmsmessage/changeinitialsmsmessage.component';
import { DuedatesmsComponent } from './changesmsmessage/duedatesms/duedatesms.component';
import { ChangetimeformatComponent } from './changetimeformat/changetimeformat.component';
import { PaymentreceivesmsComponent } from './paymentreceivesms/paymentreceivesms.component';
import { ShowexampleinitalemailComponent } from './showexampleinitalemail/showexampleinitalemail.component';
import { ShowexamplereceiveemailComponent } from './showexamplereceiveemail/showexamplereceiveemail.component';
import { ShowexmapleduedateemailComponent } from './showexmapleduedateemail/showexmapleduedateemail.component';
import { ChangeinstalmentduedatesmsmessageComponent } from './changeinstalmentduedatesmsmessage/changeinstalmentduedatesmsmessage.component';
import { ChangeinstalmentduedateemailmessageComponent } from './changeinstalmentduedateemailmessage/changeinstalmentduedateemailmessage.component';
import { ShowexampleinstalmentduedateemailComponent } from './showexampleinstalmentduedateemail/showexampleinstalmentduedateemail.component';
import { InitialsmsinstalmentmessageComponent } from './initialsmsinstalmentmessage/initialsmsinstalmentmessage.component';
import { InitialemailinstalmentmessageComponent } from './initialemailinstalmentmessage/initialemailinstalmentmessage.component';
import { ShowexampleinstalmentinitalemailComponent } from './showexampleinstalmentinitalemail/showexampleinstalmentinitalemail.component';
import { TogglewindowService } from 'src/app/_services/togglewindow.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MessageComponent implements OnInit {
  timemanagement: SettingsTimeManagement;
  content: string;
  dueDateEmailMessage: string = "";
  paymentReceiveEmailMessage: string = "";
  instalmentDueDateEmailMessage: string = "";
  initalInstalmentEmailMessage: string = "";
  initialEmailMessage: string = "";

  constructor(public messageService: MessagesService, private dialog: MatDialog, private toastr: ToastrService,
    public toggle: TogglewindowService) 
  { 
    this.content = "<i>html response</i>";
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.onLoadSettingMesaging();
    this.onLoadTimeManagement();
  }

  onLoadSettingMesaging(){
    this.messageService.getTextSettingMessaging().subscribe(response => {
      this.messageService.message = response;
      
      if (this.messageService.message == null){
        this.messageService.setMessageValues();
      }
      else
      {
        console.log(response);
        this.SetupRemoveMessages();
      }

      console.log(response);
    }, error => {
      console.log(error);
    })
  }

  onLoadTimeManagement(){
    this.messageService.getTimeMagement().subscribe(response => {
      this.timemanagement = response;
      if (this.timemanagement == null){
        this.setTimeManagementValue();
      }      
      this.checkTimeZone();
    }, error => {
      console.log(error);
    })
  }

  setTimeManagementValue(){
    var timeformat = "10+ AEST";
    this.timemanagement = {
      dueTime: new Date,
      timeFormat: timeformat
    }
  }

  changeDueDateEmailText(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "75vh";
    dialogConfig.width = "75vw";
    dialogConfig.panelClass = 'custom-dialog-container';
    const dialogRef = this.dialog.open(DuedateemailmessageComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.email != null){
        this.messageService.message.dueDateEmailMessage = result.email;
        this.SetupRemoveMessages();
      }
    });
  }

  changeDueDateSMS(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "75vh";
    dialogConfig.width = "75vw";
    dialogConfig.panelClass = 'custom-dialog-container';
    const dialogRef = this.dialog.open(DuedatesmsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result.message != null){
        this.messageService.message.dueDateSMSMessage = result.message;
        this.SetupRemoveMessages();
      }
    });
  } 

  changeInstalmentDueDateSMS(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "75vh";
    dialogConfig.width = "75vw";
    dialogConfig.panelClass = 'custom-dialog-container';
    const dialogRef = this.dialog.open(ChangeinstalmentduedatesmsmessageComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result.message != null){
        this.messageService.message.dueDateInstalmentSMSMessage = result.message;
        this.SetupRemoveMessages();
      }
    });
  } 

  changeInstalmentDueDateEmail(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "75vh";
    dialogConfig.width = "75vw";
    dialogConfig.panelClass = 'custom-dialog-container';
    const dialogRef = this.dialog.open(ChangeinstalmentduedateemailmessageComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result.message != null){
        this.messageService.message.dueDateInstalmentEmailMessage = result.message;
        this.SetupRemoveMessages();
      }
    });
  } 

  changeReceiveDateEmailText(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "75vh";
    dialogConfig.width = "75vw";
    dialogConfig.panelClass = 'custom-dialog-container';
    const dialogRef = this.dialog.open(ReceiveemailmessageComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result.message != null){
        this.messageService.message.paymentReceiveEmailMessage = result.message;
        this.SetupRemoveMessages();
      }
    });
  }

  changeReceiveSMS(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "75vh";
    dialogConfig.width = "75vw";
    dialogConfig.panelClass = 'custom-dialog-container';
    const dialogRef = this.dialog.open(PaymentreceivesmsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result.message != null){
        this.messageService.message.receiveSMSMessage = result.message;
        this.SetupRemoveMessages();
      }
    });
  }
 

  changeInitialSMS(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "75vh";
    dialogConfig.width = "75vw";
    dialogConfig.panelClass = 'custom-dialog-container';
    const dialogRef = this.dialog.open(ChangeinitialsmsmessageComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result.message != null){
        this.messageService.message.initialSMSMessage = result.message;
        this.SetupRemoveMessages();
      }
    });
  }

  changeInitialEmailText(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "75vh";
    dialogConfig.width = "75vw";
    dialogConfig.panelClass = 'custom-dialog-container';
    const dialogRef = this.dialog.open(ChangeinitialemailmessageComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result.message != null){
        this.messageService.message.initialEmailMessage = result.message;
        this.SetupRemoveMessages();
      }
    });
  }

  changeInitialInstalmentEmailText(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "75vh";
    dialogConfig.width = "75vw";
    dialogConfig.panelClass = 'custom-dialog-container';
    const dialogRef = this.dialog.open(InitialemailinstalmentmessageComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result.message != null){
        this.messageService.message.initialInstalmentEmailMessage = result.message;
        this.SetupRemoveMessages();
      }
    });
  }

  changeInitialInstalmentSMS(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "75vh";
    dialogConfig.width = "75vw";
    dialogConfig.panelClass = 'custom-dialog-container';
    const dialogRef = this.dialog.open(InitialsmsinstalmentmessageComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result.message != null){
        this.messageService.message.initialInstalmentSMSmessage = result.message;
        this.SetupRemoveMessages();
      }
    });
  }


  changeDueTime(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "auto";
    dialogConfig.width = "75vw";
    dialogConfig.panelClass = 'custom-dialog-container';
    const dialogRef = this.dialog.open(ChangeduedatetimeComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result.message != null){
        this.timemanagement.dueTime = result.message;
      }
    });
  }

  checkTimeZone(){
    if (this.timemanagement.timeFormat === 'AESTUTCTEN') {
      this.timemanagement.timeFormat = '10+ AEST';
    }
  }

  changeTimeZone(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "75vh";
    dialogConfig.width = "75vw";
    dialogConfig.panelClass = 'custom-dialog-container';
    const dialogRef = this.dialog.open(ChangetimeformatComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.message != null){
        this.timemanagement.timeFormat = result.message;
      }
    });
  }

  openExampleDueDateEmail(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "auto";
    dialogConfig.width = "75vw";
    dialogConfig.panelClass = 'custom-dialog-container';
    const dialogRef = this.dialog.open(ShowexmapleduedateemailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.message != null){
        //this.timemanagement.timeFormat = result.message;
      }
    });
  }

  openExampleInstalmentDueDateEmail(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "auto";
    dialogConfig.width = "75vw";
    dialogConfig.panelClass = 'custom-dialog-container';
    const dialogRef = this.dialog.open(ShowexampleinstalmentduedateemailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.message != null){
        //this.timemanagement.timeFormat = result.message;
      }
    });
  }

  openExampleReceiveEmail(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "auto";
    dialogConfig.width = "75vw";
    dialogConfig.panelClass = 'custom-dialog-container';
    const dialogRef = this.dialog.open(ShowexamplereceiveemailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.message != null){
        //this.timemanagement.timeFormat = result.message;
      }
    });
  }

  openExampleInitialEmail(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "auto";
    dialogConfig.width = "75vw";
    dialogConfig.panelClass = 'custom-dialog-container';
    const dialogRef = this.dialog.open(ShowexampleinitalemailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.message != null){
        //this.timemanagement.timeFormat = result.message;
      }
    });
  }

  openExampleInstalmentInitialEmail(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "auto";
    dialogConfig.width = "75vw";
    dialogConfig.panelClass = 'custom-dialog-container';
    const dialogRef = this.dialog.open(ShowexampleinstalmentinitalemailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.message != null){
        //this.timemanagement.timeFormat = result.message;
      }
    });
  }

  private SetupRemoveMessages(){
    this.dueDateEmailMessage = this.messageService.removeHTMLTags(this.messageService.message.dueDateEmailMessage);
    this.initialEmailMessage = this.messageService.removeHTMLTags(this.messageService.message.initialEmailMessage);
    this.paymentReceiveEmailMessage = this.messageService.removeHTMLTags(this.messageService.message.paymentReceiveEmailMessage);
    this.instalmentDueDateEmailMessage = this.messageService.removeHTMLTags(this.messageService.message.dueDateInstalmentEmailMessage);
    this.initalInstalmentEmailMessage = this.messageService.removeHTMLTags(this.messageService.message.initialInstalmentEmailMessage);
  }

  getTimeVlaue(value: string): string{
    var time: number = +value;
    if (time === 0)
      return "12:00 AM"
    time = time / 100;
    if (time > 0 && time < 12)
      return time + ":00 AM";
    if (time === 12)
      return "12:00 PM"
    time = time - 12;
    return  time + ":00 PM"
  }
}
