<app-nav></app-nav>
<section class="pt-4 mt-4">
    <div class="container px-lg-5">
        <!-- Page Features-->
        <div class="row gx-lg-5">
            <div class="col-lg-2 col-md-2 col-sm-12 col-xxl-12 mb-5">
            
            </div>  
            <div class="col-lg-8 col-md-8 col-sm-12 col-xxl-12 mb-5">
                <div class="card bg-white h-100 shadow-lg">
                    <div class="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                        <img src="/assets/images/7176685.jpg" alt="SMS" class="img-thumbnail">
                        <h2 class="fs-4 fw-bold">What is Instalment Plan?</h2>
                        <p class="mb-0">
                            Using installment plans can be a great way to manage your finances and make purchases more affordable. Here are some reasons why you might want to consider using installment plans:
                        </p>  
                        <ol>
                            <li> Spread the cost of purchases over time: Instead of paying the full amount upfront, you can spread the cost of your purchase over several months or even years, making it easier to manage your budget.</li>
                            <li> Improve cash flow: By paying for purchases in installments, you can free up cash for other expenses and improve your overall cash flow.</li>
                            <li> Avoid credit card interest: Many installment plans offer 0% interest, which means you can avoid paying high credit card interest rates on your purchases.</li>
                            <li> Build credit history: Making regular payments on an installment plan can help you build a positive credit history, which can improve your credit score and make it easier to obtain loans or credit in the future.</li>
                            <li> Flexibility: Installment plans often offer flexible payment options, allowing you to choose a payment schedule that works best for you.</li>
                         </ol>
                        <p class="mb-0">
                            Overall, installment plans can be a convenient and affordable way to make purchases and manage your finances.
                        </p>                 
                    </div>
                </div>
            </div>           
            <div class="col-lg-2 col-md-2 col-sm-12 col-xxl-12 mb-5">
               
            </div>   
        </div>
    </div>
</section>