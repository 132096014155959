<app-nav></app-nav>
<div class="container mt-5">
    <div>
        <div class="row justify-content-center">
            <div class="col-sm-12 col-md-4  py-5 bg-white text-black text-center border shadow-lg rounded ">
                <div class=" ">
                    <div class="card-body">
                        <img src="/assets/images/edit.jpg" class="img-thumbnail">
                        <h2 class="py-3">To Reset Your Account</h2>
                        <p>
                            Enter Your Email Account and then click on Send Email.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-8 mt-2">
                <div class="card border-primary mb-3">
                    <div class="card-body">
                        <form [formGroup]="sendEmailForm" (ngSubmit)="sendEmailForm.valid" autocomplete="off">
                                <h1>Reset Your Password</h1>
                                <div class="bs-component" *ngIf="showSendEmail">
                                    <div class="alert alert-dismissible alert-success">
                                      <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                                      <strong>Email Sent!</strong>  Please check your spam folder.
                                    </div>
                                </div>
                                <div class="bs-component" *ngIf="!showSendEmail">
                                    <div class="alert alert-dismissible alert-primary">
                                      <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                                      <strong>Check Email Spam!</strong>  To reset your account. Please enter your email address below.
                                    </div>
                                </div>
                                <app-text-input [formControl]='sendEmailForm.controls["email"]' 
                                    [label]='"*Email"' 
                                    [smalltext]='"Please enter your email address here!"' 
                                    [placeholder]='"Email"'> 
                                </app-text-input>         
                                <div class="card-footer text-muted">
                                    <button [disabled]="!sendEmailForm.valid" class="btn btn-success btn-block" form="editForm" (click)="sendResetEmail()" >Send Email Reset </button>
                                </div>                                  
                       </form>
                    </div>
                </div>              
            </div>
        </div>
    </div>
</div>